import styled from 'styled-components/macro'

export const ContainerHeader = styled.div`
  height: 100%;
  width: 100%;
  min-height: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
