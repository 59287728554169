import { Icon, StyledHr, TextBold, TextSpan } from 'Components/DesignSystem/Typography'
import React from 'react'
import { ThemeProvider } from 'styled-components'
import { colorPalletes, myTheme } from 'theme'
import { ShareIconSvg } from '../ShareIconSvg'

import { isAndroid } from 'react-device-detect'

import splurvLogo512 from 'Logos/logo512.png'
import splurvLogo from 'Logos/splurv_logo.png'
import step1 from '../step_1_new.jpg'

export const InstallingSplurvPopup: React.FC = () => {
  if (isAndroid) {
    return (
      <ThemeProvider theme={{ ...myTheme, colors: colorPalletes['newDark'] }}>
        <div className="d-flex flex-column w-100 align-items-center pt-4 px-4">
          <TextSpan color="text" size="md" weight={400} className="align-self-start">
            Install Splurv
          </TextSpan>
          <StyledHr color="nearlyclear" weight={1} className="mb-3" />

          <div
            className="d-flex align-items-center justify-content-center w-100 flex-row"
            style={{
              padding: '1rem',
              // background: '#07161C',
              borderRadius: '10px',

              background: 'linear-gradient(-135deg, #071c23, #245d74)',
            }}>
            <img
              alt=""
              src={splurvLogo512}
              style={{ width: '64px', height: '64px', borderRadius: '8px' }}
              className="mr-4"
            />

            <div className="d-flex align-items-start flex-column">
              <TextBold size="lg" color="text" className="m-0 p-0">
                Splurv
              </TextBold>

              <TextSpan size="xs" color="text" className="mt-0">
                splurv.io
              </TextSpan>
            </div>
          </div>
          <TextSpan className="px-4 d-inline my-3" color="logoSecondary" size="xs">
            Here's something neat. This is our app. No App Stores needed. Add this page to your home
            screen and open it from there and magic awaits. Here's how:
          </TextSpan>
          <StyledHr weight={1} color="logoSecondary" className="my-1" />
          <ol>
            <TextSpan className="my-2" color="logoSecondary" size="xs">
              <li>
                If you see a popup that says "Install Splurv", click that and you've installed it!
                <br />
                <br />
                Otherwise, click the <Icon color="text" className="fas fa-ellipsis-vertical mx-2" />
                in the top right of your browser settings and then select <b>
                  {' '}
                  Add to Home Screen
                </b>{' '}
                (or it might say "Install Splurv")
              </li>
            </TextSpan>
            <TextSpan className="my-2" color="logoSecondary" size="xs">
              <li>
                Open Splurv from your home screen by finding the
                <img
                  alt=""
                  style={{ width: '32px', height: '32px' }}
                  src={splurvLogo}
                  className="mx-1"
                />{' '}
                icon.
              </li>
            </TextSpan>

            <TextSpan className="my-2" color="logoSecondary" size="xs">
              <li>
                Get push notifications, offline support, and everything else you'd expect from an
                app 🎉
              </li>
            </TextSpan>
          </ol>
        </div>
      </ThemeProvider>
    )
  }
  return (
    <ThemeProvider theme={{ ...myTheme, colors: colorPalletes['newDark'] }}>
      <div className="d-flex flex-column w-100 align-items-center pt-4 px-4">
        <TextSpan color="text" size="md" weight={400} className="align-self-start">
          Install Splurv
        </TextSpan>
        <StyledHr color="nearlyclear" weight={1} className="mb-3" />

        <div
          className="d-flex align-items-center justify-content-center w-100 flex-row"
          style={{
            padding: '1rem',
            // background: '#07161C',
            borderRadius: '10px',

            background: 'linear-gradient(-135deg, #071c23, #245d74)',
          }}>
          <img
            alt=""
            src={splurvLogo512}
            style={{ width: '64px', height: '64px', borderRadius: '8px' }}
            className="mr-4"
          />

          <div className="d-flex align-items-start flex-column">
            <TextBold size="lg" color="text" className="m-0 p-0">
              Splurv
            </TextBold>

            <TextSpan size="xs" color="text" className="mt-0">
              splurv.io
            </TextSpan>
          </div>
        </div>
        <TextSpan className="px-4 d-inline my-3" color="logoSecondary" size="xs">
          Here's something neat. This is our app. No App Stores needed. Add this page to your home
          screen and open it from there and magic awaits. Here's how:
        </TextSpan>
        <StyledHr weight={1} color="logoSecondary" className="my-1" />
        <ol className="p-0 pl-3">
          <TextSpan className="d-inline" color="logoSecondary" size="xs">
            <li>
              Click the <ShareIconSvg />
              {/* <img
                        alt=""
                        style={{
                          width: '30px',
                          height: '30px',
                          border: '1px solid #0F45D4',
                          borderRadius: '50%',
                          padding: '4px',
                        }}
                        src={shareIcon}
                        className="mx-1"
                      />{' '} */}
              below in your URL bar.
              <img
                alt=""
                src={step1}
                style={{ width: '100%', borderRadius: '8px' }}
                className="mr-4"
              />
            </li>
          </TextSpan>
          <TextSpan className="my-2" color="logoSecondary" size="xs">
            <li>
              Scroll and select <b> Add to Home Screen</b>
            </li>
          </TextSpan>
          <TextSpan className="my-2" color="logoSecondary" size="xs">
            <li>
              Open Splurv from your home screen by finding the
              <img
                alt=""
                style={{ width: '26px', height: '26px', borderRadius: '5px' }}
                src={splurvLogo512}
                className="mx-1"
              />{' '}
              icon.
            </li>
          </TextSpan>
          <TextSpan className="my-2" color="logoSecondary" size="xs">
            <li>
              Get push notifications, offline support, and everything else you'd expect from an app
              🎉
            </li>
          </TextSpan>
        </ol>
      </div>
    </ThemeProvider>
  )
}
