import { TextSpan } from 'Components/DesignSystem/Typography'
import React from 'react'
import styled from 'styled-components/macro'
import { ColorType } from 'theme'

import color from 'color'

export const TOGGLE_WIDTH = 64
export const TOGGLE_HEIGHT = 24

export const TOGGLE_SIDE_PADDING = 8

export const CIRCLE_COLOR_DARKEN_RATIO = 0.6

export const TOGGLE_CIRCLE_REDUCTION_PERCENTAGE = 0.778

const ToggleLabel = styled.label`
  position: relative;
  display: inline-block;
  width: ${TOGGLE_WIDTH}px;
  height: ${TOGGLE_HEIGHT}px;

  /* Hide default HTML checkbox */
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`

interface ToggleInputProps {
  centerColor: ColorType
  leftColor: ColorType
  rightColor: ColorType
}
const ToggleInput = styled.input<ToggleInputProps>`
  transition: all ease-in-out 0.4s;
  &.right + .slider {
    background-color: ${(props) => props.theme.colors[props.rightColor]};
  }
  &.left + .slider {
    background-color: ${(props) => props.theme.colors[props.leftColor]};
  }

  &:focus + .slider {
    box-shadow: ${(props) => `0 0 1px ${props.theme.colors.textsupertransparent}`};
  }
  &.left + .slider:before {
    transform: translateY(-0%) translateX(${TOGGLE_SIDE_PADDING}px);
    background-color: ${(props) =>
      color(props.theme.colors[props.leftColor]).darken(CIRCLE_COLOR_DARKEN_RATIO).toString()};
  }

  &.right + .slider:before {
    transition: all ease-in-out 0.4s;
    background-color: ${(props) =>
      color(props.theme.colors[props.rightColor]).darken(CIRCLE_COLOR_DARKEN_RATIO).toString()};
    -webkit-transform: translateX(
      ${TOGGLE_WIDTH - TOGGLE_SIDE_PADDING - TOGGLE_HEIGHT * TOGGLE_CIRCLE_REDUCTION_PERCENTAGE}px
    );
    -ms-transform: translateX(
      ${TOGGLE_WIDTH - TOGGLE_SIDE_PADDING - TOGGLE_HEIGHT * TOGGLE_CIRCLE_REDUCTION_PERCENTAGE}px
    );
    transform: translateX(
      ${TOGGLE_WIDTH - TOGGLE_SIDE_PADDING - TOGGLE_HEIGHT * TOGGLE_CIRCLE_REDUCTION_PERCENTAGE}px
    );
  }

  &.center + .slider:before {
    transition: all ease-in-out 0.4s;
    background-color: ${(props) =>
      color(props.theme.colors[props.centerColor]).darken(CIRCLE_COLOR_DARKEN_RATIO).toString()};
    -webkit-transform: translateX(
      ${(TOGGLE_WIDTH - TOGGLE_HEIGHT * TOGGLE_CIRCLE_REDUCTION_PERCENTAGE) / 2}px
    );
    -ms-transform: translateX(
      ${(TOGGLE_WIDTH - TOGGLE_HEIGHT * TOGGLE_CIRCLE_REDUCTION_PERCENTAGE) / 2}px
    );
    transform: translateX(
      ${(TOGGLE_WIDTH - TOGGLE_HEIGHT * TOGGLE_CIRCLE_REDUCTION_PERCENTAGE) / 2}px
    );
  }
`

const ToggleSpan = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.textsupertransparent};
  -webkit-transition: 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;

  &::before {
    position: absolute;
    content: '';
    transform: translateY(-50%);
    height: ${TOGGLE_HEIGHT * TOGGLE_CIRCLE_REDUCTION_PERCENTAGE}px;
    width: ${TOGGLE_HEIGHT * TOGGLE_CIRCLE_REDUCTION_PERCENTAGE}px;
    left: 0px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  /* Rounded sliders */
  &.round {
    border-radius: ${TOGGLE_HEIGHT * TOGGLE_CIRCLE_REDUCTION_PERCENTAGE}px;
  }

  &.round:before {
    border-radius: 50%;
  }
`

interface ThreeWayProps {
  leftText: string
  centerText: string
  leftColor?: ColorType

  label: string

  id: string

  rightText: string
  rightColor?: ColorType

  valueState: 'left' | 'center' | 'right'
  setValueState: (value: 'left' | 'center' | 'right') => void

  mode?: 'binary' | 'trinary'
}

interface LEDLightProps {
  color: ColorType
  active: boolean
}
export const LEDLight = styled.div<LEDLightProps>`
  width: 5px;
  height: 5px;
  border-radius: 50%;

  transition: background-color ease-in-out 0.3s;

  background-color: ${(props) =>
    !props.active ? props.theme.colors.nearlyclear : props.theme.colors[props.color]}; ;
`

const LEDLights: React.FC<{ valueState: 'left' | 'center' | 'right' }> = ({ valueState }) => {
  return (
    <div className="d-flex align-items-center w-100 justify-content-between px-2 mb-2">
      <LEDLight color={'yellow'} active={valueState === 'left'} />
      <LEDLight color={'text'} active={valueState === 'center'} />
      <LEDLight color={'mehred'} active={valueState === 'right'} />
    </div>
  )
}

export const ToggleSwitchThreeway: React.FC<ThreeWayProps> = ({
  leftText,
  rightText,
  centerText,
  valueState,
  label,
  id,
  setValueState,
  leftColor = 'orange',
  rightColor = 'logoPrimary',
  mode = 'trinary',
}) => {
  // NOTE: this component is cool but its usecase and required context is getting removed as of 3/14/23
  return (
    <div
      className="d-flex align-items-center flex-column p-1 my-1"
      onClick={(e) => {
        if (valueState === 'center') {
          setValueState('right')
          return
        }
        if (valueState === 'left') {
          if (mode === 'binary') {
            setValueState('right')
            return
          } else {
            setValueState('center')
            return
          }
        }
        if (valueState === 'right') {
          setValueState('left')
          return
        }
      }}>
      {label ? (
        <TextSpan size="xxs" color="textsupertransparent" className="mb-2">
          {label}
        </TextSpan>
      ) : null}
      <LEDLights valueState={valueState} />
      <ToggleLabel className="switch" htmlFor={id}>
        <ToggleInput
          id={id}
          className={valueState}
          type="checkbox"
          centerColor={'text'}
          leftColor={leftColor}
          rightColor={rightColor}
        />
        <ToggleSpan className="slider round" id={id} />
      </ToggleLabel>
      <TextSpan
        weight={500}
        size="xs"
        className="mt-1"
        color={valueState === 'left' ? leftColor : valueState === 'right' ? rightColor : 'text'}>
        {valueState === 'left' ? leftText : valueState === 'right' ? rightText : centerText}
      </TextSpan>
    </div>
  )
}
