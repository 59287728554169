export type PLAID_ENV_TYPE = 'sandbox' | 'development' | 'production'
export const createLinkToken = async ({
  userid,
  token,
  item_id,
  plaidEnv,
}: {
  userid: string
  token: string
  item_id?: string
  plaidEnv: PLAID_ENV_TYPE
}) => {
  return fetch(
    `${
      process.env[`REACT_APP_${process.env.REACT_APP_APP_ENV}_API_URL`]
    }/create_link_token?userid=${userid}&userDrivenPlaidEnvironment=${plaidEnv}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ item_id }),
    }
  )
}
