import { TextSpan } from 'Components/DesignSystem/Typography'
import { ToggleSwitchThreeway } from 'Components/Toggle/ToggleThreeWay'
import { CalculationType } from 'Providers/UserCustomSettingsProvider/UserCustomSettingsProvider'
import { Transaction } from 'plaid'
import React from 'react'

interface Props {
  t: Transaction
  calculationType: CalculationType
  setCalculationType: React.Dispatch<React.SetStateAction<CalculationType>>
}

interface DisplayProps {
  calculationType: CalculationType
  setCalculationType: React.Dispatch<React.SetStateAction<CalculationType>>
}
export const TransactionSpendingTypeDisplay: React.FC<DisplayProps> = ({
  calculationType,
  setCalculationType,
}) => {
  const valueStateFromCalculationType: 'center' | 'left' | 'right' =
    calculationType === 'fixed' ? 'right' : calculationType === 'variable' ? 'left' : 'center'

  return (
    <ToggleSwitchThreeway
      label=""
      leftText={'Variable'}
      leftColor="yellow"
      centerText={'Skip'}
      id={''}
      rightText={'Fixed'}
      rightColor="mehred"
      valueState={valueStateFromCalculationType}
      setValueState={function (value: 'center' | 'left' | 'right'): void {
        switch (value) {
          case 'left':
            setCalculationType('skip')
            return
          case 'center':
            setCalculationType('fixed')
            return
          case 'right':
            setCalculationType('variable')
            return
        }
      }}
    />
  )
}

export const TransactionSpendingTypeAssigner: React.FC<Props> = ({
  t,
  calculationType,
  setCalculationType,
}) => {
  const valueStateFromCalculationType: 'center' | 'left' | 'right' =
    calculationType === 'fixed' ? 'right' : calculationType === 'variable' ? 'left' : 'center'
  return (
    <div className="d-flex flex-row w-100 align-items-center">
      <TextSpan
        color="textsupertransparent"
        size="lg"
        className="mb-2"
        weight={200}
        style={{
          flex: 2,
        }}>
        Spending Type
      </TextSpan>
      <div>
        <ToggleSwitchThreeway
          label=""
          leftText={'Variable'}
          leftColor="yellow"
          centerText={'Skip'}
          id={''}
          rightText={'Fixed'}
          rightColor="mehred"
          valueState={valueStateFromCalculationType}
          setValueState={function (value: 'center' | 'left' | 'right'): void {
            switch (value) {
              case 'left':
                setCalculationType('skip')
                return
              case 'center':
                setCalculationType('fixed')
                return
              case 'right':
                setCalculationType('variable')
                return
            }
          }}
        />
      </div>
    </div>
  )
}
