import { add } from 'date-fns'
import { enumerateDaysBetweenDates } from '../../../Utilities/dateUtilities/dateUtilities'

export type ViewRangeModes = 'month' | '3months' | '6months' | 'year' | '5years'

const today = new Date()

export const dayRangeLookup: { [key in ViewRangeModes]: Date[] } = {
  month: enumerateDaysBetweenDates(today, add(today, { months: 1 })),
  '3months': enumerateDaysBetweenDates(today, add(today, { months: 3 })),
  '6months': enumerateDaysBetweenDates(today, add(today, { months: 6 })),
  year: enumerateDaysBetweenDates(today, add(today, { years: 1 })),
  '5years': enumerateDaysBetweenDates(today, add(today, { years: 5 })),
}

export const sliderValueLookup: { [index: number]: ViewRangeModes } = {
  0: 'month',
  25: '3months',
  50: '6months',
  75: 'year',
  100: '5years',
}
export const dayRangeSizeLookup: { [key in ViewRangeModes]: number } = {
  month: 3,
  '3months': 1.5,
  '6months': 2,
  year: 1,
  '5years': 0.5,
}
