import { animated, config, useSpring } from '@react-spring/web'
import { Icon, StyledHr, TextBold, TextSpan } from 'Components/DesignSystem/Typography'
import { useResizeObserver } from 'Hooks/useResizeObservers'
import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import {
  MysteryModeButtonTitlesFromMysteryDayType,
  colorFromMysteryDayType,
  iconFromMysteryDayType,
} from 'Utilities/utils'
import React from 'react'
import { myTheme } from 'theme'

import Color from 'color'
import { BodyText } from 'design-system/typography/Text/Text'

export const OnboardingMap: React.FC = () => {
  const { mysteryMode } = useMysteryDay()
  const { intro, name, subtitle, end } = MysteryModeButtonTitlesFromMysteryDayType(mysteryMode)

  const color = colorFromMysteryDayType(mysteryMode)
  const icon = iconFromMysteryDayType(mysteryMode)

  const unstarted = mysteryMode === 'default'
  const unstartedStyle: (index: number) => React.CSSProperties = (index) => ({
    opacity: unstarted ? 0 : 1,
    transition: `opacity 0.3s ease-in-out`,
    display: unstarted ? 'none' : '',
    transitionDelay: `${mysteryMode === 'default' ? 0 : index * 0.3}s`,
  })

  const [measurements, resizeRef] = useResizeObserver()

  const fullWidth = (measurements?.inline || 200) + 16
  const shorterWidth = fullWidth - 100

  const introBoxAnimatedStyle = useSpring({
    width: mysteryMode === 'default' ? fullWidth : shorterWidth,
    minWidth: mysteryMode === 'default' ? fullWidth : shorterWidth,
    config: mysteryMode === 'default' ? config.stiff : config.wobbly,
  })

  return (
    <div className="d-flex flex-column align-items-start w-100 px-2">
      <div
        ref={resizeRef}
        className="d-flex flex-row align-items-center justify-content-between mt-2 w-100">
        <div className="d-flex flex-column align-items-center" style={unstartedStyle(1)}>
          <Icon
            size="lg"
            className={`${'fas fa-flag'} ${mysteryMode === 'default' ? 'fa-beat-fade' : ''}`}
            color={'texttransparent'}
          />
          <TextSpan
            color={'texttransparent'}
            size="xxs"
            weight={500}
            style={{}}
            className="text-center">
            {mysteryMode === 'default' ? (
              <>
                pick <br />
                from goals
                <br />
              </>
            ) : (
              <>
                start & <br />
                enter details <br />
              </>
            )}
          </TextSpan>
        </div>
        <div className="d-flex flex-row align-items-center mx-1" style={unstartedStyle(2)}>
          <Icon size="lg" className={'fal fa-arrow-right'} color={'textsupertransparent'} />
        </div>

        <div className="d-flex flex-column align-items-center" style={unstartedStyle(3)}>
          <Icon size="lg" className={'fas fa-sack-dollar'} color={'texttransparent'} />
          <TextSpan
            color={'texttransparent'}
            size="xxs"
            weight={500}
            style={{}}
            className="text-center">
            enter <br />
            income
          </TextSpan>
        </div>

        <div className="d-flex flex-row align-items-center mx-1" style={unstartedStyle(4)}>
          <Icon size="lg" className={'fal fa-arrow-right'} color={'textsupertransparent'} />
        </div>

        <div className="d-flex flex-column align-items-center" style={unstartedStyle(5)}>
          <Icon size="lg" className={'fas fa-building-columns'} color={'texttransparent'} />
          <TextSpan
            color={'texttransparent'}
            size="xxs"
            weight={500}
            style={{}}
            className="text-center">
            link <br />
            accounts
          </TextSpan>
        </div>
        <div className="d-flex flex-row align-items-center mx-1" style={unstartedStyle(6)}>
          <Icon size="lg" className={'fal fa-arrow-right'} color={'textsupertransparent'} />
        </div>
        <div className="d-flex flex-column align-items-center" style={unstartedStyle(7)}>
          <Icon size="lg" className={'fas fa-flame'} color={'texttransparent'} />
          <TextSpan
            color={'texttransparent'}
            size="xxs"
            weight={500}
            style={{}}
            className="text-center">
            enter <br />
            expenses
          </TextSpan>
        </div>
      </div>
      <div
        className="d-flex flex-row align-items-center justify-content-between mt-1 w-100"
        style={{ marginLeft: '-.5rem' }}>
        <animated.div
          className="d-flex flex-column align-items-start p-3 mr-2 "
          style={{
            ...introBoxAnimatedStyle,
            boxShadow: 'inset hsl(243 19% 18% / .5) 0 0px 7px 5px',
            backgroundColor:
              mysteryMode === 'default' ? '' : Color(myTheme.colors[color]).alpha(0.05).toString(),
            transition: 'background-color 0.3s ease-in-out',
          }}>
          <TextBold className="my-0 mx-0" size="lg" color={color}>
            {name}
          </TextBold>
          <TextSpan color={color} size="xs" className="my-0">
            {subtitle}
          </TextSpan>
          <StyledHr className="my-1" />
          {typeof intro === 'string' ? <BodyText>{intro}</BodyText> : intro}
        </animated.div>
        <div
          className="d-flex flex-column align-items-end align-self-start"
          style={{ marginTop: '-.5rem' }}>
          <div className="d-flex flex-column align-items-end">
            <div className="d-flex flex-column align-items-center" style={unstartedStyle(8)}>
              <Icon size="lg" className={'far fa-arrow-down my-2'} color={'texttransparent'} />
            </div>

            <div className="d-flex flex-column align-items-center" style={unstartedStyle(9)}>
              <Icon size="lg" className={'fas fa-chart-line'} color={'texttransparent'} />
            </div>

            <div className="d-flex flex-column align-items-center" style={unstartedStyle(9)}>
              <TextSpan
                color={'texttransparent'}
                size="xxs"
                weight={500}
                style={{ textAlign: 'end' }}>
                follow <br />
                dashboard
              </TextSpan>
            </div>
          </div>
          <div className="d-flex align-items-end flex-column">
            <div className="d-flex flex-column align-items-center" style={unstartedStyle(10)}>
              <Icon
                size="lg"
                className={'fas fa-arrow-down my-2'}
                color={colorFromMysteryDayType(mysteryMode)}
              />
            </div>

            <div className="d-flex flex-column align-items-center" style={unstartedStyle(11)}>
              <Icon size="lg" className={icon} color={colorFromMysteryDayType(mysteryMode)} />
            </div>

            <div className="d-flex flex-column align-items-center" style={unstartedStyle(11)}>
              <TextSpan
                color={colorFromMysteryDayType(mysteryMode)}
                size="xxs"
                weight={500}
                style={{ textAlign: 'end' }}>
                {end}
              </TextSpan>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
