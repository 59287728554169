import { CollapseCardControlled } from 'Components/CollapseCard/CollapseCardControlled'
import { useMysteryModeFinalDate } from 'Components/DesignSystem/Layout/Header/hooks'
import { useLocalStorageSyncedState } from 'Hooks/useLocalStorageSyncedState'
import { CashflowRecapContent } from 'Pages/MysteryPage/CashFlowRecap/CashFlowRecapContent'
import { LabelText } from 'design-system/typography/Text/Text'
import { Transaction } from 'plaid'
import React from 'react'
import { StyledVr, TextSpan } from '../../Components/DesignSystem/Typography'
import { useMysteryDay } from '../../Providers/MysteryDayProvider/MysteryDayProvider'
import { numberToCurrency } from '../../Utilities/currencyFormater'
import { useGetDashboardValue } from './hooks'

export const SavingsCoastSummary = () => {
  const { mysteryMode } = useMysteryDay()

  const { currentSavings } = useGetDashboardValue()

  const linkedAccountsTransactions: Transaction[] = []
  // const linkedAccountsTransactions = linkedAccounts
  //   .map((la) => la.transactions)
  //   .flat()
  //   .filter((t) => t.amount > 0)
  //   .sort((ta, tb) => {
  //     const taDate = stringToDate(ta.date)
  //     const tbDate = stringToDate(tb.date)

  //     if (isBefore(taDate, tbDate)) return 1
  //     if (isBefore(tbDate, taDate)) return -1
  //     return 0
  //   })

  const savingsUsedSoFar = linkedAccountsTransactions.reduce((acc, t) => t.amount + acc, 0)

  let { yearsLeft, monthsLeft, finalDateToShow } = useMysteryModeFinalDate()

  const [isCollapsed, setIsCollapsed] = useLocalStorageSyncedState<boolean>(
    false,
    'debt-day-summary-collapsed'
  )

  // const totalInterestPaid = calculateTotalInterest(
  //   cleanCurrency(debtAmount),
  //   debtInterest / 100,
  //   totalMonthsLeft
  // )

  return (
    <CollapseCardControlled
      isCollapsed={isCollapsed}
      setIsCollapsed={setIsCollapsed}
      activeColor="softblue"
      passiveColor="softblue"
      header={
        <>
          <div className="d-flex align-items-center justify-content-around w-100">
            <StyledVr
              color={'cashgreen'}
              size="xl"
              className="mr-2"
              weight={1}
              style={{ opacity: 0.5 }}
            />
            <div className="d-flex flex-column align-items-center">
              <LabelText color="cashgreen">{numberToCurrency(savingsUsedSoFar)}</LabelText>
              <LabelText color="cashgreen">spent</LabelText>
            </div>
            <StyledVr
              color={'softblue'}
              size="xl"
              className="mx-2"
              weight={1}
              style={{ opacity: 0.5 }}
            />
            <div className="d-flex flex-column align-items-center">
              <LabelText color="softblue">{numberToCurrency(currentSavings)}</LabelText>
              <LabelText color="softblue">remaining</LabelText>
            </div>

            <StyledVr
              color={'softblue'}
              size="xl"
              className="mx-2"
              weight={1}
              style={{ opacity: 0.5 }}
            />

            <div className="d-flex flex-column align-items-center">
              <TextSpan size="xs" className="my-0" weight={500} color={'softblue'}>
                {finalDateToShow || ''}
              </TextSpan>
              <div
                className="d-flex align-items-center justify-content-between flex-row"
                style={{ marginTop: '-.25rem' }}>
                <TextSpan
                  style={{
                    transition: 'all ease-in-out .3s',

                    marginTop: '-.5rem',
                  }}
                  size="xxxs"
                  className="mr-1 my-0"
                  weight={400}
                  color="softblue">
                  <TextSpan
                    style={{
                      marginTop: '-.5rem',
                    }}
                    weight={400}
                    size="xs"
                    className="mr-1 my-0">
                    {yearsLeft}
                  </TextSpan>
                  year{yearsLeft > 1 ? 's' : ''}
                </TextSpan>
                <TextSpan
                  style={{
                    transition: 'all ease-in-out .3s',
                  }}
                  size="xxxs"
                  className="my-0"
                  weight={400}
                  color="softblue">
                  <TextSpan weight={400} size="xxs" className="mr-1 my-0">
                    {' '}
                    {monthsLeft}
                  </TextSpan>{' '}
                  month{monthsLeft > 1 ? 's' : ''}
                </TextSpan>
              </div>
            </div>
          </div>

          {/* <div className="d-flex align-items-center justify-content-between flex-column pr-2 pt-0 w-100">
            <div className="d-flex align-items-center justify-content-center flex-row pr-2 pl-1 pt-0 w-100">
              <TextSpan
                style={{
                  opacity: monthsLeft === Infinity ? 0 : 1,
                  transition: 'all ease-in-out .3s',
                }}
                size="xxs"
                className="my-0"
                weight={200}
                color="textsupertransparent">
                {mysteryMode === 'getOutOfDebt'
                  ? 'Debt free:'
                  : mysteryMode === 'buildCushion' && cushionSubMode === 'extendSavings'
                  ? 'Savings run out on'
                  : 'Goal Reached On'}
              </TextSpan>
              <TextSlim
                style={{
                  opacity: monthsLeft === Infinity ? 0 : 1,
                  transition: 'all ease-in-out .3s',
                }}
                className="ml-1 my-0"
                size="sm"
                weight={200}
                color="textsupertransparent">
                <TextSpan
                  size="sm"
                  className="my-0"
                  weight={800}
                  color={colorFromMysteryDayType(mysteryMode)}>
                  <TextSpan
                    size="md"
                    className="my-0"
                    weight={800}
                    color={colorFromMysteryDayType(mysteryMode)}>
                    {humanReadableSunnyDay || ''}
                  </TextSpan>
                </TextSpan>
              </TextSlim>
            </div>

            <TextSpan
              style={{
                opacity: monthsLeft === Infinity ? 0 : 1,
                transition: 'all ease-in-out .3s',
              }}
              size="xxs"
              className="my-0 "
              weight={200}
              color="textsupertransparent">
              {mysteryMode === 'getOutOfDebt' ? 'Debt' : 'Savings'} Left:
              <TextSpan
                size="xs"
                className="my-0 ml-1"
                weight={800}
                color={colorFromMysteryDayType(mysteryMode)}>
                {numberToCurrency(
                  Number(mysteryMode === 'getOutOfDebt' ? currentDebtAmount : currentSavings)
                )}
              </TextSpan>
            </TextSpan>
            <TextSlim
              style={{
                opacity: monthsLeft === Infinity ? 0 : 1,
                transition: 'all ease-in-out .3s',
              }}
              className="ml-1 my-0"
              size="xs"
              weight={200}
              color="textsupertransparent"></TextSlim>
            {mysteryMode !== 'getOutOfDebt' || !totalInterestPaid ? null : (
              <TextSpan size="xs" className="my-0 ml-1" weight={200} color="texttransparent">
                Total Interest Paid:
                <TextSpan color="softblue" className="ml-1" size="xs" weight={800}>
                  {numberToCurrency(totalInterestPaid)}
                </TextSpan>
              </TextSpan>
            )}
          </div> */}
        </>
      }
      body={<CashflowRecapContent mysteryMode={mysteryMode} />}
    />
  )
}
