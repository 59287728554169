import { useAuth0 } from '@auth0/auth0-react'
import { datadogRum } from '@datadog/browser-rum'
import React, { useCallback, useEffect, useState } from 'react'
import { PlaidLinkOnSuccessMetadata, PlaidLinkOptions, usePlaidLink } from 'react-plaid-link'
import styled from 'styled-components/macro'
import { colorPalletes } from 'theme'
import { ContainerDiv } from '../../Components/DesignSystem/Layout/Containers/Containers'
import {
  Icon,
  StyledHr,
  TextBold,
  TextSlim,
  TextSpan,
} from '../../Components/DesignSystem/Typography'
import { useData } from '../../Providers/APIDataProvider'
import { useFinancialData } from '../../Providers/FinancialDataProvider/FinancialDataProvider'
import { useMysteryDay } from '../../Providers/MysteryDayProvider/MysteryDayProvider'
import { useUIState } from '../../Providers/UIStateProvider'
import { createLinkToken } from '../../api/plaid'
import { blastConfetti } from '../WishlistPage/Confetti'
import { AppearingDiv, AppearingImg } from './components'

import { AlexOnlyContent } from 'Components/AlexOnlyContent/AlexOnlyContent'
import splurvLogo from 'Logos/splurv_logo_transparent.png'
import { IconButton } from 'design-system/buttons/IconButton/IconButton'
import { WideButton } from 'design-system/buttons/WideButton/WideButton'
import { BodyText, SubtitleText } from 'design-system/typography/Text/Text'

const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

export const AddNewCardPage: React.FC = () => {
  const { connectNewCreditCard } = useData()
  const {
    setAddCardPageOpen,
    setErrorPopupContent,
    setMessagePopupContent,
    setMessagePopupColors,
    addCardPageOpen,
  } = useUIState()

  const item_id = addCardPageOpen && addCardPageOpen.item_id ? addCardPageOpen.item_id : undefined

  const { getAccounts, getLiabilities } = useFinancialData()

  const { setConnectingNewCard } = useData()

  const { activeTheme, userDrivenPlaidEnv } = useUIState()

  const { unlockPage } = useMysteryDay()

  const [linkToken, _setLinkToken] = useState<string>('')
  const { getAccessTokenSilently, user } = useAuth0()

  const setLinkToken = useCallback(
    (token: string) => {
      _setLinkToken(token)
      localStorage.setItem('link_token', token)
    },
    [_setLinkToken]
  )

  const onSuccess = useCallback(
    async (public_token, metadata: PlaidLinkOnSuccessMetadata) => {
      setConnectingNewCard(true)
      if (item_id) {
        console.debug(
          'onSuccess(): We have an item_id in the AddNewCardPage, this means a linkUpdate, this means no need to call new card'
        )
      }
      blastConfetti({
        colors: [colorPalletes[activeTheme].logoPrimary, colorPalletes[activeTheme].text],
        duration: 1,
      })
      setTimeout(() => {
        setAddCardPageOpen(false)
      }, 250)
      datadogRum.addAction('bank-account-linked', { type: 'regular' })
      try {
        if (item_id) {
          setMessagePopupColors({
            bgColor: 'seconddark',
            fgColor: 'logoPrimary',
          })
          setMessagePopupContent(
            `Nice!🎉 <>We successfully updated ${metadata.accounts.length} accounts!<><><>🙈 If you see a large white space appear at the bottom of the app, exit the app and come back and it will go away. We're working on this!`
          )

          getAccounts({})
          getLiabilities({})
          return
        }
        await connectNewCreditCard(public_token, userDrivenPlaidEnv)
        setTimeout(() => {
          unlockPage(['/creditcards', '/spending'])
        }, 1000)
        // setMessagePopupColors({
        //   bgColor: 'seconddark',
        //   fgColor: 'primary',
        // })
        // setMessagePopupContent(
        //   `Nice!🎉 <>We successfully updated ${metadata.accounts.length} accounts!<><><>🙈 If you see a large white space appear at the bottom of the app, exit the app and come back and it will go away. We're working on this!`
        // )

        getAccounts({})
        getLiabilities({})
      } catch (err) {
        console.error('err from the onSuccess callback of the Plaid Link')
        console.error(err)
        setErrorPopupContent(`<>Error${JSON.stringify(err)}`)
      }
    },
    // eslint-disable-next-line
    [
      connectNewCreditCard,
      setAddCardPageOpen,
      getAccounts,
      getLiabilities,
      item_id,
      setErrorPopupContent,
      setMessagePopupColors,
      setMessagePopupContent,
      unlockPage,
    ]
  )

  const getPublicToken = useCallback(async () => {
    const token = await getAccessTokenSilently()
    const { sub } = user

    if (item_id) {
      console.debug(
        'getPublicToken(): We have an item_id in the AddNewCardPage so we are going to call createLinkToken and pass that in'
      )
    }

    if (!userDrivenPlaidEnv) {
      console.warn('userDrivenPlaidEnv is not set yet')
      return
    }

    return createLinkToken({ userid: sub, token, item_id, plaidEnv: userDrivenPlaidEnv })
      .then((result) => {
        if (result.status !== 200) {
          const { status, statusText } = result
          console.error('ERROR!!!! create_link_token returned non-200')
          setErrorPopupContent(`<>Error${JSON.stringify({ status, statusText })}`)
          return
        }
        result
          .json()
          .then((json) => {
            setLinkToken(json.link_token)
          })
          .catch((err) => {
            console.error('err from createLinkToken')
            console.error(err)
            setErrorPopupContent(`<>Error${JSON.stringify(err)}`)
            return
          })
      })
      .catch((err) => {
        console.error('err from createLinkToken')
        console.error(err)
        setErrorPopupContent(`<>Error${JSON.stringify(err)}`)
        throw Error(JSON.stringify({ err }))
      })
  }, [
    setLinkToken,
    user,
    getAccessTokenSilently,
    setErrorPopupContent,
    item_id,
    userDrivenPlaidEnv,
  ])

  // NOTE: *** PLAID CONNECTION POINT ***
  const config: PlaidLinkOptions = {
    token: linkToken,
    onSuccess,

    onLoad: () => {
      datadogRum.addAction('bank-account-link-load')
    },
    onEvent: (eventName, metadata) => {
      datadogRum.addAction('bank-account-link-event', {
        bankAccountLinkEvent: { eventName, metadata },
      })
      console.debug('PlaidLink - onEvent')
    },
    onExit: (err, metadata) => {
      // NOTE: We could add datadog conversion tracking here for users who bailed on Plaid Link midway through
      if (err?.error_code === 'ADDITION_LIMIT') {
        setMessagePopupContent(
          "Ooops 🙈<> That is embarrassing, we've actually hit the limit of how many bank accounts Plaid let's us connect. Trust us that we're working on this already and actively looking to bring this back. Since you're likely one of our early users, please reach out if this happened to you!<><>feedback@splurv.com"
        )
      }
      console.debug('PlaidLink - onExit')
      datadogRum.addAction('bank-account-link-aborted', { type: 'regular', err, metadata })
      console.debug({ err, metadata })
    },
  }

  // NOTE: *** PLAID CONNECTION POINT ***
  const { open } = usePlaidLink(config)

  useEffect(() => {
    if (!user) return
    if (!linkToken) getPublicToken()
  }, [getPublicToken, linkToken, user])
  if (item_id) {
    return (
      <ContainerDiv className="justify-content-between pb-4 w-100">
        <div className="d-flex flex-column w-100">
          <Header>
            <div className="d-flex flex-column w-100">
              <div className="d-flex align-items-center w-100">
                <AppearingDiv className="d-flex align-items-center">
                  <img alt="" style={{ maxHeight: '3rem' }} src={splurvLogo} />
                  <TextBold size="xl" color="second">
                    Splurv
                  </TextBold>
                </AppearingDiv>
                <Icon className="fas fa-plus mx-3" />
                <AppearingImg
                  alt=""
                  style={{ maxHeight: '2em' }}
                  src="https://plaid.com/assets/img/navbar/logo.svg"
                />
              </div>
              <div className="d-flex align-items-center justify-content-center flex-column w-100">
                <Icon className="fas fa-link my-2" size="lg" color="yellow" />
                <AppearingImg
                  alt=""
                  style={{ maxHeight: '3rem' }}
                  src={`data:image/png;base64, ${(addCardPageOpen && addCardPageOpen.logo) || ''}`}
                />
                <TextSlim
                  weight={200}
                  size="md"
                  style={{ color: (addCardPageOpen && addCardPageOpen.color) || '' }}>
                  {addCardPageOpen && addCardPageOpen.name}
                </TextSlim>
              </div>
            </div>
          </Header>

          <div className="d-flex flex-column w-100">
            <SubtitleText className="px-2 mb-0">Why do I need to re-link?</SubtitleText>
            <BodyText className="px-4 my-2">
              <a href="https://plaid.com/"> Plaid </a> is the service that allows us (Splurv) to be
              able to aggregate and analyze your financial data. We use Plaid to securely gather and
              aggregate data from financial institutions. Sometimes, often for security reasons,
              banks will require you to reauthenticate with Plaid. This is a good thing! It means
              that your bank is taking security seriously and we appreciate that.
            </BodyText>
            <BodyText className="px-4 my-2">
              Re-linking is no different than the initial setup, except we now know which bank it is
              that is asking for an updated connection so we can streamline the process a bit more.
            </BodyText>

            <BodyText className="px-4 my-2">
              <u>
                <a href="https://www.iubenda.com/privacy-policy/45091906">Splurv Privacy Policy</a>
              </u>
            </BodyText>
          </div>
        </div>

        <div className="d-flex flex-row justify-content-between align-items-center px-4">
          <IconButton
            color="texttransparent"
            iconClassName="fas fa-arrow-up"
            onClick={() => setAddCardPageOpen(false)}
            title="Back"
            size="medium"
          />
          <WideButton
            bgColor={'yellow'}
            onClick={async () => {
              // NOTE: *** PLAID CONNECTION POINT *** - this actually starts the Link flow
              datadogRum.addAction('bank-account-relink-started')
              open()
            }}
            active={true}
            text={'Re-link account'}
            appearance="primary"
            width="fit"
            icon={{
              className: 'fas fa-link',
              position: 'right',
            }}
            textSize="small"
          />
        </div>
      </ContainerDiv>
    )
  }
  return (
    <>
      <ContainerDiv className="justify-content-between py-4 w-100">
        <div className="d-flex flex-column justify-content-start">
          <Header>
            <AppearingDiv className="d-flex align-items-center">
              <img alt="" style={{ maxHeight: '4rem' }} src={splurvLogo} />
              <TextBold size="xl" color="logoSecond">
                Splurv
              </TextBold>
            </AppearingDiv>
            <Icon className="fal fa-plus" />
            <AppearingImg
              alt=""
              style={{ maxHeight: '3rem' }}
              src="https://asset.brandfetch.io/idly0-MZ4j/idXuSZXNqr.png"
            />
          </Header>
          <div className="d-flex flex-column justify-content-start">
            <BodyText className="px-4 my-2">
              <TextSpan color="second" weight={500}>
                <u>
                  <a
                    style={{ color: 'inherit' }}
                    href="https://www.iubenda.com/privacy-policy/45091906">
                    {' '}
                    Splurv{' '}
                  </a>
                </u>
              </TextSpan>{' '}
              uses{' '}
              <b>
                <TextSpan color="text" weight={500}>
                  <u>
                    <a style={{ color: '' }} href="https://plaid.com/">
                      {' '}
                      Plaid{' '}
                    </a>
                  </u>
                </TextSpan>
              </b>{' '}
              as a trusted third-party service to pull bank account balances and transactions.
            </BodyText>

            <BodyText className="px-4 my-2">
              Plaid is also used by other finance apps including{' '}
              {/* <img
                src={venmoLogo}
                style={{ maxHeight: '1.5rem', marginLeft: '0.5rem', marginRight: '0.5rem' }}
              />
              <img
                src={acornsLogo}
                style={{ maxHeight: '1.5rem', marginLeft: '0.5rem', marginRight: '0.5rem' }}
              />
              <img
                src={chimeLogo}
                style={{ maxHeight: '1.5rem', marginLeft: '0.5rem', marginRight: '0.5rem' }}
              />
              <img
                src={sofiLogo}
                style={{ maxHeight: '1.5rem', marginLeft: '0.5rem', marginRight: '0.5rem' }}
              /> */}
              <TextSpan weight={800} color="text">
                {/* <TextSpan weight={800} style={{ color: '#398DF7' }}> */}
                Venmo
              </TextSpan>
              ,{' '}
              <TextSpan weight={800} color="text">
                {/* <TextSpan weight={800} style={{ color: '#88C65A' }}> */}
                Acorns
              </TextSpan>
              , {/* <TextSpan weight={800} style={{ color: '#5EC27E' }}> */}
              <TextSpan weight={800} color="text">
                Chime
              </TextSpan>
              , and{' '}
              <TextSpan weight={800} color="text">
                {/* <TextSpan weight={800} style={{ color: '#469DBE' }}> */}
                SoFi
              </TextSpan>
              .
            </BodyText>
            <BodyText className="px-4 my-2">
              You can turn off Splurv's use of <b>Plaid</b> and unlink your accounts by simply
              deleting a connected account.
            </BodyText>
            <AlexOnlyContent>
              <StyledHr className="px-0" />
              <BodyText className="px-4 my-2">
                You are entering Plaid in the <b>{userDrivenPlaidEnv}</b> environment
              </BodyText>
            </AlexOnlyContent>
          </div>
        </div>

        <div className="d-flex flex-row justify-content-between align-items-center px-4">
          {/* <div className="d-flex flex-column align-items-center"> */}
          <IconButton
            color="texttransparent"
            iconClassName="fas fa-arrow-up"
            onClick={() => setAddCardPageOpen(false)}
            title="Back"
            size="medium"
          />
          <WideButton
            bgColor={'text'}
            active={true}
            appearance="primary"
            text="connect account"
            icon={{
              className: 'fas fa-building-columns',
              position: 'right',
            }}
            textSize="small"
            width="fit"
            onClick={async () => {
              // NOTE: *** PLAID CONNECTION POINT *** - this actually starts the Link flow
              datadogRum.addAction('bank-account-link-started')
              open()
            }}
          />
        </div>
      </ContainerDiv>
    </>
  )
}
