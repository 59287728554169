// This optional code is used to register a service worker.
// register() is not called by default.

// import { clientsClaim } from "workbox-core";

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
)

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void
  onUpdate?: (registration: ServiceWorkerRegistration) => void
  onError?: (error: any) => void
  onFailedUpdate?: (error: any) => void
  onSuccessUpdate?: () => void
  onRegistrationSuccess?: () => void
}

const swrDebug = (msg: any) =>
  console.debug(`%c 🧵 (ServiceWorkerRegistration) ${msg}`, 'background: #222; color: #407E9D')

export function register(config?: Config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return
    }

    window.addEventListener('load', () => {
      swrDebug('window.load called')
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config)

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          swrDebug(
            'This web app is being served cache-first by a service ' +
              'worker. To learn more, visit https://cra.link/PWA'
          )
        })
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config)
      }
    })
  }
}

let intervalVar: NodeJS.Timeout
function registerValidSW(swUrl: string, config?: Config) {
  swrDebug('Calling navigator.serviceWorker.register')
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      config?.onRegistrationSuccess && config.onRegistrationSuccess()
      swrDebug('registering interval to update app')
      intervalVar = setInterval(() => {
        swrDebug('checking for newer version...')
        registration
          .update()
          .then((res) => {
            config?.onSuccessUpdate && config.onSuccessUpdate()
          })
          .catch((err) => {
            config?.onFailedUpdate && config.onFailedUpdate(err)
          })
      }, 12000)

      registration.onupdatefound = (r) => {
        swrDebug('registration.onupdatefound called')
        const installingWorker = registration.installing
        if (installingWorker == null) {
          return
        }

        swrDebug(`scriptUrl: ${installingWorker.scriptURL}`)
        const isOneSignalServiceWorker = installingWorker.scriptURL.includes('OneSignalSDKWorker')
        swrDebug(`isOneSignalServiceWorker: ${String(isOneSignalServiceWorker)}`)
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            // NOTE: If we have a serviceWorker that is currently controlling the page
            if (
              navigator.serviceWorker.controller &&
              !navigator.serviceWorker.controller.scriptURL.includes('OneSignalSDKWorker')
            ) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              swrDebug(
                'New content is available and will be used when all ' +
                  'tabs for this page are closed. See https://cra.link/PWA.'
              )

              // Execute callback
              swrDebug(`navigator.serviceWorker: ${JSON.stringify(navigator.serviceWorker)}`)
              if (config && config.onUpdate && !isOneSignalServiceWorker) {
                swrDebug('calling Callback, this will trigger update prompt')
                config.onUpdate(registration)
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              swrDebug('Content is cached for offline use.')

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration)
              }
            }
          }
        }
      }
    })
    .catch((error) => {
      if (config && config.onError) {
        config.onError(error)
      }
      console.error('Error during service worker registration:', error)
    })
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type')
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload()
          })
        })
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config)
      }
    })
    .catch((err) => {
      swrDebug('No internet connection found. App is running in offline mode.')
      config?.onFailedUpdate && config.onFailedUpdate(err)
    })
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister()
        clearInterval(intervalVar)
      })
      .catch((error) => {
        console.error(error.message)
      })
  }
}
