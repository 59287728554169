import { useFinancialData } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import {
  getAccountTypeFromTypeSubtypeTuple,
  getAmountForMixedAccount,
} from 'Providers/FinancialDataProvider/helpers'
import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'

export const useGetMultipleTrackedAccountDetails = () => {
  const { linkedAccounts } = useMysteryDay()
  const { flatAccounts, flatManualAccounts } = useFinancialData()

  if (linkedAccounts) {
    return linkedAccounts.map((la) => {
      if (la.type === 'manual') {
        const manualAccountThatsBeingTracked = flatManualAccounts.find(
          (fma) => fma.account_id === la.accountId
        )
        if (manualAccountThatsBeingTracked) {
          return {
            name: manualAccountThatsBeingTracked.name,
            amount: Number(manualAccountThatsBeingTracked.amount),
            type: manualAccountThatsBeingTracked.type,
          }
        } else {
          return null
        }
      } else {
        const liveAccountThatsBeingTracked = flatAccounts.find(
          (fa) => fa.account_id === la.accountId
        )
        if (liveAccountThatsBeingTracked) {
          return {
            name: liveAccountThatsBeingTracked.name,
            amount: getAmountForMixedAccount(liveAccountThatsBeingTracked),
            type: getAccountTypeFromTypeSubtypeTuple([
              liveAccountThatsBeingTracked.type,
              liveAccountThatsBeingTracked.subtype,
            ]),
            accountId: liveAccountThatsBeingTracked.account_id,
          }
        } else {
          return null
        }
      }
    })
  }
  return []
}

export const useGetTrackedAccountDetails = () => {
  const { linkedAccount } = useMysteryDay()
  const { flatAccounts, flatManualAccounts } = useFinancialData()

  if (linkedAccount) {
    if (linkedAccount.type === 'manual') {
      const manualAccountThatsBeingTracked = flatManualAccounts.find(
        (fma) => fma.account_id === linkedAccount.accountId
      )
      if (manualAccountThatsBeingTracked) {
        return {
          name: manualAccountThatsBeingTracked.name,
          amount: Number(manualAccountThatsBeingTracked.amount),
          type: manualAccountThatsBeingTracked.type,
        }
      } else {
        return null
      }
    } else {
      const liveAccountThatsBeingTracked = flatAccounts.find(
        (fa) => fa.account_id === linkedAccount.accountId
      )
      if (liveAccountThatsBeingTracked) {
        return {
          name: liveAccountThatsBeingTracked.name,
          amount: getAmountForMixedAccount(liveAccountThatsBeingTracked),
          type: getAccountTypeFromTypeSubtypeTuple([
            liveAccountThatsBeingTracked.type,
            liveAccountThatsBeingTracked.subtype,
          ]),
        }
      } else {
        return null
      }
    }
  }
}
