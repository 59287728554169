import { useUIState } from 'Providers/UIStateProvider'
import dayjs from 'dayjs'
import { Row } from 'design-system/table/Row/Row'
import { Transaction } from 'plaid'
import React from 'react'
import { Icon, TextSpan } from '../../../Components/DesignSystem/Typography'
import { numberToCurrency } from '../../../Utilities/currencyFormater'
import {
  humanReadifyStringDate,
  humanReadifyStringDateShortSansYear,
} from '../../../Utilities/dateUtilities/dateUtilities'
import { FrequencyType, MoneyStream } from '../../../Utilities/interfaces'
import { nth } from '../../../Utilities/utils'
import { StreamDatePart, StreamPart } from './components'
import { useGetMatchingTransactionsForStreamName } from './helpers'

interface Props {
  stream: MoneyStream
  onStreamEdit: (stream: MoneyStream, mode: 'edit' | 'add', isPaycheck: boolean) => void
  isIndented?: boolean
}

const StreamDate = ({
  stream,
  transactionForThisMonth,
}: {
  stream: MoneyStream
  isDemo?: boolean

  transactionForThisMonth: Transaction | undefined
}) => {
  const { setMessagePopupContent } = useUIState()
  switch (stream.frequency) {
    case FrequencyType.onetime:
      return (
        <StreamDatePart>
          <div className="d-flex flex-column align-items-center">
            <div className="d-flex ">
              <TextSpan size="xxxs" className="mr-1 " style={{ marginBottom: '-.25rem' }}>
                {' '}
                one time
              </TextSpan>
            </div>
            <div className="d-flex ">
              <TextSpan size="xxxs" className="mr-1 my-0">
                {' '}
                <b>{humanReadifyStringDate(stream.futureDate)}</b>
              </TextSpan>
            </div>
          </div>
        </StreamDatePart>
      )

    case FrequencyType.monthly:
      if (transactionForThisMonth) {
        return (
          <div className="d-flex flex-column align-items-end">
            <div className="d-flex flex-row align-items-center justify-content-start">
              <Icon
                size="xxs"
                color={stream.type === 'bill' ? 'mehred' : 'cashgreen'}
                className="fas fa-check-circle mr-1"
              />
              <TextSpan
                color={stream.type === 'bill' ? 'mehred' : 'cashgreen'}
                size="xxs"
                className="my-0">
                {humanReadifyStringDateShortSansYear(transactionForThisMonth.date).toLowerCase()}
              </TextSpan>
            </div>
            {/* <TextSpan
              color={stream.type === 'bill' ? 'mehred' : 'cashgreen'}
              style={{ opacity: 0.5 }}
              size="xxs"
              className="my-0">
              {numberToCurrency(transactionForThisMonth.amount)}
            </TextSpan> */}
          </div>
        )
      }

      return (
        <div
          className="d-flex flex-row align-items-center justify-content-start"
          onClick={() => {
            setMessagePopupContent(
              'Bill Tracking<>When you reflect on your live bank transactions and mark them as bills, you will see which bills you have paid off this month here.<>To reflect on your transactions, head to the envelopes page and open your reflections if you have any'
            )
          }}>
          <Icon color="texttransparent" size="xxs" className="far fa-circle mr-1" />
          <TextSpan
            color="textsupertransparent"
            size="xxxs"
            className="d-flex align-items-center my-0">
            {stream.day}
            {nth(stream.day)}
          </TextSpan>
          <TextSpan size="xxxs" className="ml-1 my-0" weight={200}>
            of month
          </TextSpan>
        </div>
      )
    // return (
    //   <StreamDatePart>
    //     {stream.day}
    //     {nth(stream.day)}
    //     <TextSpan size="xxxs" className="ml-1">
    //       {' '}
    //       of month
    //     </TextSpan>
    //   </StreamDatePart>
    // )
    case FrequencyType.weekly:
      return <StreamDatePart>{dayjs().day(stream.day).format('ddd')}</StreamDatePart>
    case FrequencyType.anually:
      return (
        <StreamDatePart>
          <div className="d-flex flex-column align-items-center">
            <div className="d-flex ">
              <TextSpan size="xxxs" className="mr-1 " style={{ marginBottom: '-.25rem' }}>
                {' '}
                once a <b className="ml-1">year</b>
              </TextSpan>
            </div>
            <div className="d-flex ">
              <TextSpan size="xxxs" className="mr-1 my-0">
                {' '}
                <b>{humanReadifyStringDate(stream.nextDate)}</b>
              </TextSpan>
            </div>
          </div>
        </StreamDatePart>
      )
    case FrequencyType.semiannually:
      return (
        <StreamDatePart>
          <div className="d-flex flex-column align-items-center">
            <div className="d-flex ">
              <TextSpan size="xxxxs" className="mr-1 " style={{ marginBottom: '-.25rem' }}>
                {' '}
                twice a <b className="ml-1">year</b>
              </TextSpan>
            </div>
            <div className="d-flex ">
              <TextSpan size="xxxs" className="mr-1 my-0">
                {' '}
                <b>{humanReadifyStringDate(stream.nextDate)}</b>
              </TextSpan>
            </div>
          </div>
        </StreamDatePart>
      )
    case FrequencyType.quarterly:
      return (
        <StreamDatePart>
          <div className="d-flex flex-column align-items-center">
            <div className="d-flex ">
              <TextSpan size="xxxxs" className="mr-1 " style={{ marginBottom: '-.25rem' }}>
                {' '}
                4x <b className="ml-1">year</b>
              </TextSpan>
            </div>
            <div className="d-flex ">
              <TextSpan size="xxxs" className="mr-1 my-0">
                {' '}
                <b>{humanReadifyStringDate(stream.nextDate)}</b>
              </TextSpan>
            </div>
          </div>
        </StreamDatePart>
      )
    case FrequencyType.semimonthly:
      return (
        <StreamDatePart>
          <div className="d-flex flex-column align-items-center">
            <div className="d-flex ">
              <TextSpan size="xxxxs" className="mr-1 " style={{ marginBottom: '-.25rem' }}>
                {' '}
                twice a <b className="ml-1">month</b>
              </TextSpan>
            </div>
            <div className="d-flex ">
              <TextSpan size="xxxs" className="mr-1 my-0">
                {' '}
                <b>
                  {Number(stream.mostRecentDate?.split('-')?.[2])}
                  {nth(Number(stream.mostRecentDate?.split('-')?.[2]))}
                </b>{' '}
                <TextSpan className="mx-1">&</TextSpan>
                <b>
                  {Number(stream.secondMostRecentDate?.split('-')?.[2])}
                  {nth(Number(stream.secondMostRecentDate?.split('-')?.[2]))}
                </b>
              </TextSpan>
            </div>
          </div>
        </StreamDatePart>
      )
    case FrequencyType.biweekly:
      return <StreamDatePart>/2 weeks</StreamDatePart>
    case FrequencyType.daily:
      return <StreamDatePart>daily</StreamDatePart>
    default:
      return null
  }
}

export const StreamEntry: React.FC<Props> = ({ stream, onStreamEdit, isIndented }) => {
  // const isSpecialStream = stream.name === 'Credit Card Avg'
  // const streamAccountSourceName = stream.sources?.[0]?.accountIdOrName

  const { streamTransactionFromThisMonth } = useGetMatchingTransactionsForStreamName(stream.name)

  const iconClassname = () => {
    switch (true) {
      case stream.isSubscription:
        return 'fak fa-solid-repeat-circle-dollar'
      case stream.type === 'bill':
        return 'fak fa-regular-money-check-dollar-circle-minus'
      case stream.name === 'Paycheck' && stream.type === 'income':
        return 'far fa-sack-dollar'
      case stream.type === 'income':
        return 'far fa-sack-dollar'
      default:
        return 'far fa-sack-dollar'
    }
  }

  return (
    <Row
      rowIcon={iconClassname()}
      rowIconSize="sm"
      style={{
        opacity: stream.enabled ? 1 : 0.5,
      }}
      gridTemplateColumnString="minmax(0, 0.3fr) minmax(0, 1.75fr) minmax(0, 0.75fr) minmax(0, 1fr);"
      rowIconColor={stream.type === 'bill' ? 'mehred' : 'cashgreen'}
      firstColumn={
        <u
          onClick={() => {
            onStreamEdit(stream, 'edit', stream.name === 'Paycheck' ? true : false)
          }}
          style={{ overflow: 'hidden' }}>
          {stream.name}
        </u>
      }
      secondColumn={
        <TextSpan color={stream.type === 'bill' ? 'mehred' : 'cashgreen'}>
          {numberToCurrency(stream.amount, true)}
        </TextSpan>
      }
      thirdColumn={
        <StreamDate stream={stream} transactionForThisMonth={streamTransactionFromThisMonth} />
      }
    />
  )
}

interface SortingArrowProps {
  sortIsActive: boolean
  sortState: 'asc' | 'desc'
}
export const SortingArrow: React.FC<SortingArrowProps> = ({ sortIsActive, sortState }) => {
  return (
    <div
      className="d-flex flex-column align-items-center ml-1"
      style={{ opacity: sortIsActive ? 1 : 0.4 }}>
      <Icon
        className="fas fa-triangle"
        style={{
          fontSize: '7px',
          marginBottom: '2px',
        }}
        color={
          sortIsActive
            ? sortState === 'asc'
              ? 'cashgreen'
              : 'textsupertransparent'
            : 'textsupertransparent'
        }
      />
      <Icon
        className="fas fa-triangle"
        size="xxxxs"
        color={
          sortIsActive
            ? sortState === 'desc'
              ? 'mehred'
              : 'textsupertransparent'
            : 'textsupertransparent'
        }
        style={{
          transform: `rotate(180deg)`,
          fontSize: '7px',
        }}
      />
    </div>
  )
}

interface LegendProps {
  onNameClick: () => void
  onAmountClick: () => void
  onDateClick: () => void
  sortingOrder: number
  sortingStrategy: 'name' | 'date' | 'amount' | 'transfer'
}
export const LegendStreamEntry: React.FC<LegendProps> = ({
  onAmountClick,
  onNameClick,
  onDateClick,
  sortingOrder,
  sortingStrategy,
}) => {
  return (
    <Row
      rowIcon={''}
      rowIconSize="sm"
      rowIconColor={'mehred'}
      gridTemplateColumnString="minmax(0, 0.3fr) minmax(0, 1.75fr) minmax(0, 0.75fr) minmax(0, 1fr);"
      firstColumn={
        <StreamPart
          sortingOrder={sortingOrder}
          enabled={sortingStrategy === 'name'}
          isLegend={true}
          isCreditCard={false}
          onClick={() => onNameClick()}>
          name
          <SortingArrow
            sortIsActive={sortingStrategy === 'name'}
            sortState={sortingOrder === 1 ? 'asc' : 'desc'}
          />
        </StreamPart>
      }
      secondColumn={
        <StreamPart
          style={{
            overflow: 'visible',
          }}
          sortingOrder={sortingOrder}
          enabled={sortingStrategy === 'amount'}
          isLegend={true}
          onClick={() => onAmountClick()}>
          amount
          <SortingArrow
            sortIsActive={sortingStrategy === 'amount'}
            sortState={sortingOrder === 1 ? 'asc' : 'desc'}
          />
        </StreamPart>
      }
      thirdColumn={
        <StreamPart
          sortingOrder={sortingOrder}
          enabled={sortingStrategy === 'date'}
          isLegend={true}
          isCreditCard={false}
          onClick={() => onDateClick()}>
          date
          <SortingArrow
            sortIsActive={sortingStrategy === 'date'}
            sortState={sortingOrder === 1 ? 'asc' : 'desc'}
          />
        </StreamPart>
      }
    />
  )
}
