import React from 'react'
import { useHistory } from 'react-router-dom'
import { ContainerHeader } from '../../../../Pages/StreamPage/components'
import { StyledHeader } from './Header.components'
import { HeaderContent } from './HeaderContent'

export const PageHeader: React.FC = ({ children }) => {
  const history = useHistory()

  const { location } = history
  return (
    <StyledHeader>
      <div className="w-100 d-flex align-items-center justify-content-between">
        {!(location.pathname === '/') && (
          <>
            {children}
            <ContainerHeader>
              <HeaderContent />
            </ContainerHeader>
          </>
        )}
      </div>
    </StyledHeader>
  )
}
