import {
  Chart,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  ScriptableContext,
  TimeScale,
  TimeSeriesScale,
} from 'chart.js'
import 'chartjs-adapter-date-fns'
import annotationPlugin from 'chartjs-plugin-annotation'
import color from 'color'
import { useIsAlex } from 'Components/AlexOnlyContent/AlexOnlyContent'
import { MysteryDayType } from 'Providers/MysteryDayProvider/MysteryDayProvider.interfaces'
import { useUIState } from 'Providers/UIStateProvider'
import React from 'react'
import { Line } from 'react-chartjs-2'
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'
import { useMysteryDay } from '../../../../Providers/MysteryDayProvider/MysteryDayProvider'
import { colorPalletes, ColorType } from '../../../../theme'
import { ChartContainer } from '../components'
import { generateConfig } from './DatasetConfig'
import { getOptions } from './Options'

Chart.register(annotationPlugin)
Chart.register(TimeScale)
Chart.register(TimeSeriesScale)
Chart.register(LinearScale)
Chart.register(PointElement)
Chart.register(LineController)
Chart.register(LineElement)
Chart.register(Legend)
Chart.register(Filler)

export const annotationStyleConfig = {
  padding: {
    x: 12,
    y: 4,
  },

  font: {
    size: 10,
    weight: '500',
    family: 'Rubik',
  },
}

interface Props {
  pastLabels: Date[]
  pastData: (number | null)[]

  futureLabels: Date[]
  futureData: (number | null)[]

  mode: MysteryDayType
  goalAmount: number
  todaysBalance: number
  historicalTransactionsExist: boolean
}

export const ProjectionGraphComponent: React.FC<Props> = ({
  pastLabels,
  pastData,
  futureLabels,
  futureData,
  mode,
  goalAmount,
  todaysBalance,
  historicalTransactionsExist,
}) => {
  const pastColor: ColorType = 'cashgreen'
  const { activeTheme } = useUIState()
  const { mysteryMode, cushionSubMode } = useMysteryDay()
  const chartRef = React.useRef<ChartJSOrUndefined<'line', number[], Date> | undefined>(null)

  const firstDate = pastLabels[0]
  const lastDate = futureLabels[futureLabels.length - 1]

  const [minYValue, setMinYValue] = React.useState<number | undefined>(undefined)
  const [maxYValue, setMaxYValue] = React.useState<number | undefined>(undefined)
  const themeColor =
    mode === 'getOutOfDebt'
      ? colorPalletes[activeTheme].yellow
      : mode === 'getOrganized'
      ? colorPalletes[activeTheme].megapurple
      : mode === 'buildCushion'
      ? colorPalletes[activeTheme].softblue
      : colorPalletes[activeTheme].text

  const isAlex = useIsAlex()

  React.useEffect(() => {
    if (chartRef.current) {
      const minYValue = chartRef.current.scales['y'].min
      const maxYValue = chartRef.current.scales['y'].max
      setMinYValue(minYValue)
      setMaxYValue(maxYValue)
    }
  }, [setMinYValue, setMaxYValue])

  const datasetConfig = generateConfig({
    lastDate,
    mode,
    activeTheme,
  })

  const options = getOptions(mode, activeTheme)

  return (
    <ChartContainer>
      <Line
        redraw={false}
        ref={chartRef}
        options={{
          ...options,
          layout: {
            padding: {
              right: 20,
              left: 0,
              top: 0,
              bottom: 0,
            },
          },
          plugins: {
            ...options.plugins,
            annotation: {
              clip: false,
              annotations: historicalTransactionsExist
                ? [
                    {
                      type: 'line',
                      drawTime: 'afterDatasetsDraw',
                      xScaleID: 'x',
                      yScaleID: 'y',
                      xMin: new Date().getTime(),
                      xMax: new Date().getTime(),
                      value: new Date().getTime(),
                      yMax: goalAmount,
                      yMin(ctx, options) {
                        return todaysBalance
                      },
                      borderColor: color(colorPalletes[activeTheme]['yellow']).alpha(1).toString(),
                      borderWidth: 2,
                      display: mysteryMode === 'getOutOfDebt' ? true : false,
                    },
                    {
                      type: 'line',
                      drawTime: 'afterDatasetsDraw',
                      xScaleID: 'x',
                      yScaleID: 'y',
                      xMin: new Date().getTime(),
                      xMax: lastDate.getTime(),
                      value: lastDate.getTime(),
                      yMax: goalAmount,
                      yMin(ctx, options) {
                        return goalAmount
                      },
                      borderColor: color(colorPalletes[activeTheme]['yellow']).alpha(1).toString(),
                      display: true,
                      borderWidth: mysteryMode === 'getOutOfDebt' ? 2 : 0,
                      label: {
                        content:
                          mysteryMode === 'getOutOfDebt'
                            ? 'debt left'
                            : mysteryMode === 'buildCushion' && cushionSubMode === 'extendSavings'
                            ? // ? ['savings', 'left']
                              ''
                            : 'left', // Set the label content
                        display: true,
                        borderRadius: 10,
                        color: colorPalletes[activeTheme]['background'],
                        borderWidth: 0,
                        borderColor: color(colorPalletes[activeTheme]['background'])
                          .alpha(1)
                          .toString(),
                        ...annotationStyleConfig,
                        backgroundColor:
                          mysteryMode === 'getOutOfDebt'
                            ? colorPalletes[activeTheme].yellow
                            : colorPalletes[activeTheme].softblue,
                        position: 'end', // Adjust the label position as needed
                        xAdjust: 0,
                        yAdjust: 0,
                      },
                    },
                    {
                      type: 'line',
                      drawTime: 'afterDatasetsDraw',
                      xScaleID: 'x',
                      yScaleID: 'y',
                      xMin: firstDate.getTime(),
                      xMax: new Date().getTime(),
                      value: firstDate.getTime(),
                      yMin(ctx, options) {
                        return minYValue || 0
                      },
                      yMax(ctx, options) {
                        return minYValue || 0
                      },
                      borderColor: color(colorPalletes[activeTheme]['cashgreen'])
                        .alpha(1)
                        .toString(),
                      borderWidth: 2,
                      label: {
                        content:
                          mysteryMode === 'getOutOfDebt'
                            ? 'debt paid'
                            : mysteryMode === 'buildCushion' && cushionSubMode === 'extendSavings'
                            ? ['savings', 'used']
                            : 'left', // Set the label content
                        display: true,
                        borderRadius: 10,
                        color: colorPalletes[activeTheme]['background'],
                        borderWidth: 0,
                        borderColor: color(colorPalletes[activeTheme]['background'])
                          .alpha(1)
                          .toString(),
                        ...annotationStyleConfig,
                        backgroundColor: colorPalletes[activeTheme].cashgreen,
                        position: 'start', // Adjust the label position as needed
                        xAdjust: 10,
                        yAdjust: 0,
                      },
                    },
                    {
                      type: 'line',
                      drawTime: 'afterDatasetsDraw',
                      // scaleID: 'x',
                      xScaleID: 'x',
                      yScaleID: 'y',
                      xMin: new Date(2022, 7, 24).getTime(),
                      xMax: new Date(2022, 7, 24).getTime(),
                      value: new Date(2022, 7, 24).getTime(),
                      yMax: maxYValue || 0,
                      // yMin: minValue,
                      yMin(ctx, options) {
                        return minYValue || 0
                        // return getMinimumValueFromDataSet(data)
                      },
                      borderColor: color(colorPalletes[activeTheme]['orange']).alpha(1).toString(),
                      borderWidth: 1,
                      display: false,
                      label: {
                        content: '🚀', // Set the label content
                        display: isAlex,
                        borderRadius: 10,
                        color: colorPalletes[activeTheme]['black'],
                        borderWidth: 0,
                        borderColor: color(colorPalletes[activeTheme]['text']).alpha(1).toString(),
                        ...annotationStyleConfig,
                        font: {
                          size: 15,
                        },

                        backgroundColor: 'transparent',
                        position: 'end', // Adjust the label position as needed
                        xAdjust: 0,
                        yAdjust: -30,
                      },
                    },

                    {
                      type: 'line',
                      drawTime: 'afterDatasetsDraw',
                      // scaleID: 'x',
                      xScaleID: 'x',
                      yScaleID: 'y',
                      xMin: new Date().getTime(),
                      xMax: new Date().getTime(),
                      value: new Date().getTime(),
                      yMax: todaysBalance,
                      // yMin: minValue,
                      yMin(ctx, options) {
                        return minYValue || 0
                        // return getMinimumValueFromDataSet(data)
                      },
                      borderColor: color(colorPalletes[activeTheme]['cashgreen'])
                        .alpha(1)
                        .toString(),
                      borderWidth: 2,
                      label: {
                        content: 'today', // Set the label content
                        display: true,
                        borderRadius: 10,
                        color: colorPalletes[activeTheme]['black'],
                        borderWidth: 0,
                        borderColor: color(colorPalletes[activeTheme]['text']).alpha(1).toString(),
                        ...annotationStyleConfig,
                        backgroundColor: colorPalletes[activeTheme].seconddark,
                        position: 'start', // Adjust the label position as needed
                        xAdjust: 0,
                        yAdjust: 0,
                      },
                    },
                  ]
                : [],
            },
          },
        }}
        data={{
          labels: [...pastLabels, ...futureLabels],
          datasets: [
            {
              label: 'past data',
              ...datasetConfig,
              fill: 'start',
              // fill: {
              //   target: todaysBalance * -1,
              //   above: 'green',
              //   below: 'green',
              // },
              backgroundColor: (context: ScriptableContext<'line'>) => {
                // @ts-ignore
                const ctx = context.chart.ctx
                // @ts-ignore
                const chartArea = context.chart.chartArea
                const gradient = ctx.createLinearGradient(
                  chartArea?.left || 0,
                  chartArea?.top || 100,
                  chartArea?.right || 0,
                  chartArea?.bottom || 100
                )
                const bgColor = color(colorPalletes[activeTheme][pastColor]).alpha(0.1).toString()
                const bgColorSecond = color(colorPalletes[activeTheme][pastColor])
                  .alpha(0.2)
                  .toString()
                gradient.addColorStop(0, bgColorSecond)
                gradient.addColorStop(1, bgColor)
                return gradient
              },
              data: pastData,
            },
            {
              label: 'future data',
              ...datasetConfig,
              fill: mode === 'getOutOfDebt' ? 'end' : 'start',
              backgroundColor: (context: ScriptableContext<'line'>) => {
                // @ts-ignore
                const ctx = context.chart.ctx
                // @ts-ignore
                const chartArea = context.chart.chartArea
                const gradient = ctx.createLinearGradient(
                  chartArea?.left || 0,
                  chartArea?.top || 100,
                  chartArea?.right || 0,
                  chartArea?.bottom || 100
                )
                gradient.addColorStop(0, color(themeColor).alpha(0.2).toString())
                gradient.addColorStop(1, color(themeColor).alpha(0).toString())
                return gradient
              },
              data: futureData,
            },
          ],
        }}
      />
    </ChartContainer>
  )
}
