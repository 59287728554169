import { Pill } from 'Components/DesignSystem/Pill/Pill'
import { TextSpan } from 'Components/DesignSystem/Typography'
import { dataParameterType } from 'Hooks/useLocalStorageSyncedState'
import { useUserCustomSettings } from 'Providers/UserCustomSettingsProvider/UserCustomSettingsProvider'
import React from 'react'
import { getExistingCategories } from '../DecorateTransactionsPopup/DecorateTransactionsPopup'
import { FILTER_TYPE } from './helpers'
interface Props {
  activeCategoryFilter: string
  setActiveCategoryFilter: (v: dataParameterType<string>) => void
  activeTypeFilter: FILTER_TYPE
}
export const ActiveTransactionCategoryPicker: React.FC<Props> = ({
  activeCategoryFilter,
  setActiveCategoryFilter,
  activeTypeFilter,
}) => {
  const { settings } = useUserCustomSettings()

  const existingCategories = getExistingCategories(settings, activeTypeFilter)

  if (existingCategories?.length === 0) return null
  return (
    <div className="d-flex w-100 align-items-center justify-content-between my-2">
      <TextSpan
        style={{
          minWidth: '70px',
        }}
        size="xxs"
        weight={700}
        color={
          activeTypeFilter === 'variable'
            ? 'yellow'
            : activeTypeFilter === 'fixed'
            ? 'mehred'
            : 'textsupertransparent'
        }
        className="mr-2">
        {activeTypeFilter === 'variable'
          ? 'Envelope:'
          : activeTypeFilter === 'fixed'
          ? 'Bill:'
          : 'Category:'}
      </TextSpan>
      <div
        className="d-flex w-100 align-items-center justify-content-start"
        style={
          {
            overflowX: 'scroll',
            '-webkit-overflow-scrolling': 'touch',
          } as React.CSSProperties
        }>
        <div
          className="d-flex w-100 align-items-center justify-content-start"
          style={
            {
              overflowX: 'scroll',
              '-webkit-overflow-scrolling': 'touch',
            } as React.CSSProperties
          }>
          {existingCategories?.map((ec) => {
            return (
              <Pill
                onClick={() => {
                  activeCategoryFilter === ec
                    ? setActiveCategoryFilter('')
                    : setActiveCategoryFilter(ec)
                }}
                bgColor={
                  activeCategoryFilter === ec
                    ? activeTypeFilter === 'variable'
                      ? 'yellow'
                      : activeTypeFilter === 'fixed'
                      ? 'mehred'
                      : 'text'
                    : 'text'
                }
                text={ec}
                pillClassName={'my-2 ml-1 px-2 py-2 mx-2'}
              />
              //     borderRadius: '4px',
              //     whiteSpace: 'nowrap',

              //     opacity: activeCategoryFilter ? (activeCategoryFilter === ec ? 1 : 0.5) : 1,
              //   }}
              //   className="my-1 ml-1 px-3 py-1 mx-2"
              //   color={'background'}
              //   size="xs"
              //   weight={700}>
              //   {ec}
              // </TextSlim>
            )
          })}
        </div>
      </div>
    </div>
  )
}
