import { Icon, StyledVr, TextPopupSmall, TextSpan } from 'Components/DesignSystem/Typography'
import { generateBackgroundColor } from 'Pages/StreamPage/AddEditStream/BillResponsibilitySection/BillResponsibilitySection'
import { numberToCurrency } from 'Utilities/currencyFormater'
import React from 'react'
import { ColorType } from 'theme'
import { BANK_OF_AMERICA, CHIME, SOFI } from './BankLogos'

interface SpendingSignalBadgeProps {
  status: 'onpace' | 'stop' | 'slowdown'
}
const SpendingSignalBadge: React.FC<SpendingSignalBadgeProps> = ({ status }) => {
  switch (status) {
    case 'onpace':
      return (
        <TextPopupSmall className="my-0 p-1 px-2" size="xxs" color={'cashgreen'} weight={500}>
          <Icon className="fas fa-flag mr-1" size="xxs" color="cashgreen" />
          on pace
        </TextPopupSmall>
      )
    case 'stop':
      return (
        <TextPopupSmall className="my-0 p-1 px-2" size="xxs" color={'mehred'} weight={500}>
          <Icon className="fas fa-octagon-exclamation mr-1" size="xxs" color="mehred" />
          stop spending
        </TextPopupSmall>
      )
    case 'slowdown':
      return (
        <TextPopupSmall className="my-0 p-1 px-2" size="xxs" color={'yellow'} weight={500}>
          <Icon className="fas fa-triangle-exclamation mr-1" size="xxs" color="yellow" />
          slow down
        </TextPopupSmall>
      )
  }
}

interface SpendingSignalProps {
  balance: number
  nickname: string
  status: 'onpace' | 'stop' | 'slowdown'
  limit: number
  bankLogo: React.ReactElement
}
export const SpendingSignal: React.FC<SpendingSignalProps> = ({
  balance,
  limit,
  status,
  nickname,
  bankLogo,
}) => {
  const colorFromStatus: {
    [key in 'onpace' | 'stop' | 'slowdown']: ColorType
  } = {
    onpace: 'cashgreen',
    stop: 'mehred',
    slowdown: 'yellow',
  }
  return (
    <li className="my-2">
      <div className="d-flex align-items-center">
        {bankLogo}
        <div className="d-flex flex-column align-items-start ml-2">
          <div className="d-flex flex-row align-items-center">
            <TextSpan color={colorFromStatus[status]} size="md" weight={500} className="mr-2 my-0">
              {numberToCurrency(balance)}
            </TextSpan>
            <SpendingSignalBadge status={status} />
          </div>
          <div className="d-flex align-items-center flex-row">
            <TextSpan className="mt-0" size="xxs" color="text" weight={600}>
              <Icon className="fas fa-tag mr-1" color="text" size="xs" />
              {nickname}
            </TextSpan>
            <StyledVr color="nearlyclear" weight={1} size="xs" className="mx-2" />
            <TextSpan style={{}} size="xxs" color="text" weight={500}>
              <Icon
                size="xs"
                color={'text'}
                className={`fa-solid fa-chart-mixed-up-circle-dollar mr-1`}
              />
              {numberToCurrency(limit)}{' '}
              <TextSpan size="xxs" color="textsupertransparent" className="ml-2" weight={200}>
                {' '}
                /month
              </TextSpan>
            </TextSpan>
          </div>
        </div>
      </div>
    </li>
  )
}

export const SoFiLogo = () => {
  return (
    <div className={`d-flex flex-${'column'} align-items-center`}>
      <img
        alt="institution-logo"
        height={36}
        style={{
          borderRadius: '50%',
          padding: 0,
        }}
        src={`data:image/png;base64, ${SOFI}`}
      />
      <TextSpan
        size={'xxs'}
        weight={700}
        className={`d-flex align-items-center justify-content-center ${'my-1'}`}
        color={'textsupertransparent'}
        style={{
          fontFamily: 'monospace',
          color: '#00a9ce',
          backgroundColor: generateBackgroundColor('#00a9ce', 'dark'),
          padding: '.125rem .25rem',
          borderRadius: '4px',
          textAlign: 'center',
        }}>
        1234
      </TextSpan>
    </div>
  )
}

export const ChimeLogo = () => {
  return (
    <div className={`d-flex flex-${'column'} align-items-center`}>
      <img
        alt="institution-logo"
        height={36}
        style={{
          borderRadius: '50%',
          padding: 0,
        }}
        src={`data:image/png;base64, ${CHIME}`}
      />
      <TextSpan
        size={'xxs'}
        weight={700}
        className={`d-flex align-items-center justify-content-center ${'my-1'}`}
        color={'textsupertransparent'}
        style={{
          fontFamily: 'monospace',
          color: '#5ac27e',
          backgroundColor: generateBackgroundColor('#5ac27e', 'dark'),
          padding: '.125rem .25rem',
          borderRadius: '4px',
          textAlign: 'center',
        }}>
        1234
      </TextSpan>
    </div>
  )
}

export const BankOfAmericaLogo = () => {
  return (
    <div className={`d-flex flex-${'column'} align-items-center`}>
      <img
        alt="institution-logo"
        height={36}
        style={{
          borderRadius: '50%',
          padding: 0,
        }}
        src={`data:image/png;base64, ${BANK_OF_AMERICA}`}
      />
      <TextSpan
        size={'xxs'}
        weight={700}
        className={`d-flex align-items-center justify-content-center ${'my-1'}`}
        color={'textsupertransparent'}
        style={{
          fontFamily: 'monospace',
          color: '#e31837',
          backgroundColor: generateBackgroundColor('#e31837', 'dark'),
          padding: '.125rem .25rem',
          borderRadius: '4px',
          textAlign: 'center',
        }}>
        1234
      </TextSpan>
    </div>
  )
}
