import { datadogRum } from '@datadog/browser-rum'
import React, { useCallback, useEffect } from 'react'

import { PlaidLinkOnSuccessMetadata, usePlaidLink } from 'react-plaid-link'
import { useHistory } from 'react-router'
import { colorPalletes } from 'theme'
import { useData } from '../../Providers/APIDataProvider'
import { useFinancialData } from '../../Providers/FinancialDataProvider/FinancialDataProvider'
import { useMysteryDay } from '../../Providers/MysteryDayProvider/MysteryDayProvider'
import { useUIState } from '../../Providers/UIStateProvider'
import { blastConfetti } from '../WishlistPage/Confetti'

// TODO: We need to polish / cleanup this logic as it is currently

// a.) out of date with the fancier confetti / popup / autorefresh that happens in AddNewCardPage
// b.) it creates a weird random white space at the bottom of the app that I think is happening from a double render of routes?

export const AddNewCardPageOAuth: React.FC = () => {
  const { connectNewCreditCard } = useData()
  const {
    setAddCardPageOpen,
    setMessagePopupColors,
    setMessagePopupContent,
    userDrivenPlaidEnv,
  } = useUIState()
  const { activeTheme } = useUIState()
  const { push } = useHistory()

  const { unlockPage } = useMysteryDay()

  const { getAccounts, getLiabilities } = useFinancialData()

  const linkToken = localStorage.getItem('link_token')

  const onSuccess = useCallback(
    async (token, metadata: PlaidLinkOnSuccessMetadata) => {
      setAddCardPageOpen(false)

      setMessagePopupColors({
        bgColor: 'seconddark',
        fgColor: 'logoPrimary',
      })
      setMessagePopupContent(
        `Nice!🎉 <>We successfully updated ${metadata.accounts.length} accounts!<><><>🙈 If you see a large white space appear at the bottom of the app, exit the app and come back and it will go away. We're working on this!`
      )
      blastConfetti({
        colors: [colorPalletes[activeTheme].logoPrimary, colorPalletes[activeTheme].second],
        duration: 2,
      })
      await connectNewCreditCard(token, userDrivenPlaidEnv)
      push('/creditcards')
      getAccounts({})
      getLiabilities({})

      setTimeout(() => {
        unlockPage(['/creditcards', '/spending'])
      }, 1000)
      datadogRum.addAction('bank-account-linked', { type: 'oauth' })
    },
    // eslint-disable-next-line
    [
      connectNewCreditCard,
      setAddCardPageOpen,
      getAccounts,
      getLiabilities,
      setMessagePopupColors,
      push,
      setMessagePopupContent,
      unlockPage,
    ]
  )
  const config: Parameters<typeof usePlaidLink>[0] = {
    token: linkToken! || 'badtoken',
    receivedRedirectUri: window.location.href,
    onSuccess,

    onLoad: () => {
      datadogRum.addAction('bank-account-link-load')
    },
    onEvent: (eventName, metadata) => {
      datadogRum.addAction('bank-account-link-event', {
        bankAccountLinkEvent: { eventName, metadata },
      })
      console.debug('PlaidLink - onEvent')
    },
    onExit: (err, metadata) => {
      // NOTE: We could add datadog conversion tracking here for users who bailed on Plaid Link midway through
      console.debug('PlaidLink - onExit')
      datadogRum.addAction('bank-account-link-aborted', { type: 'oauth', err, metadata })
      console.debug({ err, metadata })
    },
  }
  const { open, ready } = usePlaidLink(config)

  // automatically re-initialize Link
  useEffect(() => {
    if (ready) {
      open()
    }
  }, [ready, open])
  return null
}
