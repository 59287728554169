import { AccountOverviewWidget } from 'Components/AccountOverviewWidget'
import { Icon, TextBold, TextSpan } from 'Components/DesignSystem/Typography'
import { useUIState } from 'Providers/UIStateProvider'
import React from 'react'
import { SettingsItem } from '../components'

export const ViewAccounts: React.FC = () => {
  const { setSettingsPageOpen, setAccountsPageOpen } = useUIState()

  return (
    <SettingsItem
      onClick={() => {
        setAccountsPageOpen(true)
        setSettingsPageOpen(false)
      }}>
      <div className="d-flex flex-column w-100 align-items-start">
        <div className="d-flex flex-row w-100 align-items-center">
          <Icon className={`fas fa-building-columns`} />
          <TextBold>Accounts</TextBold>
        </div>
        <TextSpan size="xxs" color="texttransparent">
          Connect accounts, set spending limits, see balances
        </TextSpan>
        <AccountOverviewWidget />
      </div>
    </SettingsItem>
  )
}
