import React from 'react'
import { useMysteryDay } from '../../Providers/MysteryDayProvider/MysteryDayProvider'
import { DashboardPageContent } from './DashboardPageContent'

export const DashboardPage: React.FC = () => {
  const { mysteryMode } = useMysteryDay()

  switch (mysteryMode) {
    case 'getOutOfDebt':
    case 'getOrganized':
    case 'buildCushion':
    case 'default':
    case 'saveToQuit':
      return <DashboardPageContent />
  }
}
