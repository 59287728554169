import styled from 'styled-components/macro'

export const SliderPanelContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0.5rem 0;
`

export const VerticalSliderMarker = styled.div<{ width: number }>`
  position: absolute;
  left: 0;
  width: ${(props) => `${props.width}px`};
  height: 10px;
  border-radius: 5px;
  background: ${(props) => props.theme.colors.second};
`
