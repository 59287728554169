import { stringToDate } from 'Utilities/dateUtilities/dateUtilities'
import { isAfter, isBefore, isSameDay, startOfMonth, startOfWeek, sub } from 'date-fns'
import dayjs from 'dayjs'
import { AccountBase, Transaction } from 'plaid'

export type FILTER_TYPE = 'fixed' | 'variable' | 'both'
export type TIME_FRAME = 'thisMonth' | 'lastMonth' | 'both'
export type SORTING_MODE = 'amount' | 'date' | 'name' | 'bank'
export type SORTING_ORDER = 1 | -1

export const transactionTimeFilterFunction = (t: Transaction, timeFrameFilter: TIME_FRAME) => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)

  const thisWeek = startOfWeek(today)
  thisWeek.setHours(0, 0, 0, 0)

  const thisMonth = startOfMonth(today)
  thisMonth.setHours(0, 0, 0, 0)

  const lastMonth = sub(startOfMonth(today), { months: 1 })
  lastMonth.setHours(0, 0, 0, 0)
  const transactionDate = stringToDate(t.date)
  transactionDate.setHours(0, 0, 0, 0)
  switch (timeFrameFilter) {
    case 'both':
      return true
    case 'thisMonth':
      return isAfter(transactionDate, thisMonth) || isSameDay(transactionDate, thisMonth)
    case 'lastMonth':
      return (
        (isAfter(transactionDate, lastMonth) || isSameDay(transactionDate, lastMonth)) &&
        isBefore(transactionDate, thisMonth)
      )
  }
}
export const sortFunctionGenerator = (
  sortingMode: SORTING_MODE,
  sortingOrder: SORTING_ORDER,
  accounts: AccountBase[]
) => (a: Transaction, b: Transaction) => {
  switch (sortingMode) {
    case 'bank':
      const account_id_A = a.account_id
      const accountForTransaction_A = accounts
        ?.map((a) => a.accounts)
        ?.flat()
        ?.find((a) => a?.account_id === account_id_A)

      const account_id_B = b.account_id
      const accountForTransaction_B = accounts
        ?.map((a) => a.accounts)
        ?.flat()
        ?.find((a) => a?.account_id === account_id_B)
      return (accountForTransaction_A?.name?.toLowerCase() || '') >
        (accountForTransaction_B?.name?.toLowerCase() || '')
        ? sortingOrder
        : -sortingOrder
    case 'amount':
      return a.amount > b.amount ? sortingOrder : -sortingOrder
    case 'date':
      const aDate = dayjs(a.date, 'YYYY-MM-DD').toDate()
      const bDate = dayjs(b.date, 'YYYY-MM-DD').toDate()
      return isBefore(aDate, bDate) ? sortingOrder : -sortingOrder
    case 'name':
      return a.name.toLowerCase() > b.name.toLowerCase() ? sortingOrder : -sortingOrder
    default:
      return 1
  }
}
