import 'chartjs-adapter-date-fns'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import React from 'react'
import { TextSlim } from '../../../../Components/DesignSystem/Typography'
import { myTheme } from '../../../../theme'
import { sliderValueLookup, ViewRangeModes } from '../helpers'

interface Props {
  viewRange: ViewRangeModes
  setViewRange: (mode: ViewRangeModes) => void
}

export const ProjectionSlider: React.FC<Props> = React.memo(({ viewRange, setViewRange }) => {
  const marks = {
    0: {
      label: (
        <TextSlim
          size="xs"
          className="mt-3"
          style={{ transform: 'rotate(-30deg)' }}
          weight={800}
          color={viewRange === 'month' ? 'second' : 'textsupertransparent'}>
          1 month
        </TextSlim>
      ),
    },
    25: {
      label: (
        <TextSlim
          size="xs"
          className="mt-3"
          style={{ transform: 'rotate(-30deg)' }}
          weight={800}
          color={viewRange === '3months' ? 'second' : 'textsupertransparent'}>
          3 months
        </TextSlim>
      ),
    },
    50: {
      label: (
        <TextSlim
          size="xs"
          style={{ transform: 'rotate(-30deg)' }}
          className="mt-3"
          weight={800}
          color={viewRange === '6months' ? 'second' : 'textsupertransparent'}>
          6 months
        </TextSlim>
      ),
    },
    75: {
      label: (
        <TextSlim
          size="xs"
          style={{ transform: 'rotate(-30deg)' }}
          className="mt-3"
          weight={800}
          color={viewRange === 'year' ? 'second' : 'textsupertransparent'}>
          1 year
        </TextSlim>
      ),
    },
    100: {
      label: (
        <TextSlim
          size="xs"
          style={{ transform: 'rotate(-30deg)' }}
          weight={800}
          className="mt-3"
          color={viewRange === '5years' ? 'second' : 'textsupertransparent'}>
          5 year
        </TextSlim>
      ),
    },
  }

  return (
    <div className="d-flex align-items-center w-100 mb-5">
      <Slider
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
        onChange={(value) => setViewRange(sliderValueLookup[value])}
        min={-10}
        handleStyle={{
          borderColor: myTheme['colors']['second'],
          backgroundColor: myTheme['colors']['second'],
          // width: '24px',
          // height: '24px',
          // height: 28,
          // width: 28,
          // marginLeft: -14,
          // marginTop: -9,
        }}
        dotStyle={{}}
        trackStyle={{
          backgroundColor: myTheme['colors']['second'],
        }}
        max={110}
        marks={marks}
        step={null}
        defaultValue={0}
      />
    </div>
  )
})
