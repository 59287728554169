import { MysteryPageType } from 'Providers/MysteryDayProvider/MysteryDayProvider.interfaces'
import React from 'react'
import { useMysteryDay } from '../../Providers/MysteryDayProvider/MysteryDayProvider'
import { NotImplementedPage } from './NotImplementedPage'
import { ContributionSettingPage } from './Pages/ContributionSettingPage'
import { CostOfLivingFixedPage } from './Pages/CostOfLivingFixedPage'
import { CostOfLivingVariablePage } from './Pages/CostOfLivingVariablePage'
import { CushionInputPage } from './Pages/CushionInputPage'
import { DebtInputPage } from './Pages/DebtInputPage'
import { ItemInputPage } from './Pages/ItemInputPage'
import { LaunchDayInputPage } from './Pages/LaunchDayInputPage'
import { ModeSelectionPage } from './Pages/ModeSelectionPage/ModeSelectionPage'
import { PaycheckOrIncomePage } from './Pages/PaycheckOrIncomePage'
import { SavingsTotalPage } from './Pages/SavingsTotalPage'
import { TotalCostOfLivingComplete } from './Pages/TotalCostOfLivingComplete/TotalCostOfLivingComplete'
import { TotalCostOfLivingIntro } from './Pages/TotalCostOfLivingIntro'
import { ProgressIndicator } from './ProgressIndicator/ProgressIndicator'
import { MysteryPageContainerPage } from './components'

export interface SubPageProps {}

interface Props {}

interface MysteryPageContentProps {
  page: MysteryPageType
}
const MysteryPageContent: React.FC<MysteryPageContentProps> = ({ page }) => {
  switch (page) {
    case 'mode-selection':
      return <ModeSelectionPage />
    case 'desired-time-off':
      return <LaunchDayInputPage />
    case 'desired-cushion':
      return <CushionInputPage />
    case 'savings-total':
      return <SavingsTotalPage />
    case 'cost-of-item':
      return <ItemInputPage />
    case 'debt-amount-input':
      return <DebtInputPage />
    case 'cost-of-living-fixed':
      return <CostOfLivingFixedPage />
    case 'cost-of-living-variable':
      return <CostOfLivingVariablePage />
    case 'total-cost-of-living-intro':
      return <TotalCostOfLivingIntro />
    case 'total-cost-of-living-complete':
      return <TotalCostOfLivingComplete />
    case 'paycheck-or-income':
      return <PaycheckOrIncomePage />
    case 'contribution-setting':
      return <ContributionSettingPage />
    default:
      return <NotImplementedPage />
  }
}

export const MysteryPage: React.FC<Props> = () => {
  const { page } = useMysteryDay()

  return (
    <MysteryPageContainerPage>
      <ProgressIndicator />
      <MysteryPageContent page={page} />
    </MysteryPageContainerPage>
  )
}
