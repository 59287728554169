import { useMysteryModeFinalDate } from 'Components/DesignSystem/Layout/Header/hooks'
import { Icon, StyledHr, TextSlim, TextSpan } from 'Components/DesignSystem/Typography'
import { useData } from 'Providers/APIDataProvider'
import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import {
  CushionSubModeType,
  MysteryDayType,
} from 'Providers/MysteryDayProvider/MysteryDayProvider.interfaces'
import { numberToCurrency } from 'Utilities/currencyFormater'
import { getMonthlyIncome } from 'Utilities/netWorth/netWorth'
import { colorFromMysteryDayType } from 'Utilities/utils'
import { CaptionText } from 'design-system/typography/Text/Text'
import React from 'react'
import { ContributionSelection } from './ContributionSelection'
import { RELAXED_RECOMMENDED_AGGRESIVE_SPREAD } from './TotalCostOfLivingComplete'

const MonthlyActionWord: React.FC<{ mysteryMode: MysteryDayType }> = ({ mysteryMode }) => {
  const { cushionSubMode } = useMysteryDay()
  switch (mysteryMode) {
    case 'getOutOfDebt':
      return <>monthly debt payments</>
    case 'getOrganized':
    case 'saveToQuit':
      return <>monthly savings contributions</>
    case 'buildCushion':
      switch (cushionSubMode) {
        case 'extendSavings':
          return <>spending rates</>
        case 'emergencyFund':
        case 'getOrganized':
        default:
          return <>monthly emergency fund savings</>
      }
    default:
      return <>monthly savings contributions</>
  }
}

const getGoalReachedWord = (mysteryMode: MysteryDayType, cushionSubMode: CushionSubModeType) => {
  switch (mysteryMode) {
    case 'getOutOfDebt':
      return 'Debt free on'
    case 'getOrganized':
      return 'Goal reached'
    case 'saveToQuit':
      return 'Launch on'
    case 'buildCushion':
      switch (cushionSubMode) {
        case 'extendSavings':
          return 'Money runs out'
        case 'emergencyFund':
          return '6 month fund by'
        case 'getOrganized':
        default:
          return 'Monthly Savings'
      }
    default:
      return ''
  }
}

const ContributionTitlePhrase: React.FC<{ mysteryMode: MysteryDayType }> = ({ mysteryMode }) => {
  const { cushionSubMode } = useMysteryDay()
  switch (mysteryMode) {
    case 'getOutOfDebt':
      return <>Monthly Debt Payments</>
    case 'getOrganized':
      return <>Monthly Saving Options </>
    case 'saveToQuit':
      return <>Monthly Saving Options</>
    case 'buildCushion':
      switch (cushionSubMode) {
        case 'extendSavings':
          return <>Monthly Spending Rate</>
        case 'emergencyFund':
        case 'getOrganized':
        default:
          return <>Monthly Savings</>
      }
    default:
      return <></>
  }
}

const getSubtext = (
  mysteryMode: MysteryDayType,
  cushionSubMode: CushionSubModeType,
  selection: 'low' | 'medium' | 'high'
) => {
  const isCoastMode = mysteryMode === 'buildCushion' && cushionSubMode === 'extendSavings'
  switch (selection) {
    case 'medium':
      if (isCoastMode) {
        return 'This is your current spending rate'
      }
      return 'We recommend this based on your current disposable income. This is likely the easiest place to start.'
    case 'low':
      if (isCoastMode) {
        return 'If you want to really extend your runway, cutting down your spending will help.'
      }
      return 'If you want a more relaxed approach that leaves with you more disposable income each month, this would be a good option. '
    case 'high':
      if (isCoastMode) {
        return 'If you want to really enjoy your coasting time, allowing higher spending is acceptable, although it shortens your runway.'
      }
      return 'If you want a more intense approach that has you reaching your goal sooner, this would be a good option. '
  }
}

export const ContributionRangePanel: React.FC = () => {
  const { streams, monthlyBurnRate } = useData()

  const lavishMonthlyBurnRate = monthlyBurnRate * (RELAXED_RECOMMENDED_AGGRESIVE_SPREAD + 1)
  const frugalMonthlyBurnRate = monthlyBurnRate * (1 - RELAXED_RECOMMENDED_AGGRESIVE_SPREAD)

  const [activeContributionSelection, setActiveContributionSelection] = React.useState<
    'low' | 'medium' | 'high'
  >('medium')

  const { finalDateToShow: currentOutOfMoneyDate } = useMysteryModeFinalDate()
  const { finalDateToShow: frugalOutOfMoneyDate } = useMysteryModeFinalDate(frugalMonthlyBurnRate)
  const { finalDateToShow: lavishOutOfMoneyDate } = useMysteryModeFinalDate(lavishMonthlyBurnRate)

  const { setMonthlyContribution, mysteryMode, cushionSubMode } = useMysteryDay()

  const monthlyIncome = getMonthlyIncome(streams)

  const baseRecommendedAmount = monthlyIncome - Math.abs(monthlyBurnRate)

  const coastMode = mysteryMode === 'buildCushion' && cushionSubMode === 'extendSavings'
  const notFeasible = baseRecommendedAmount < 0 && !coastMode

  const relaxedRecommendedAmount =
    baseRecommendedAmount * (1 - RELAXED_RECOMMENDED_AGGRESIVE_SPREAD)

  const aggressiveRecommendedAmount =
    baseRecommendedAmount * (1 + RELAXED_RECOMMENDED_AGGRESIVE_SPREAD)

  React.useEffect(() => {
    setMonthlyContribution(baseRecommendedAmount)
  }, [baseRecommendedAmount, setMonthlyContribution])

  const { finalDateToShow } = useMysteryModeFinalDate(baseRecommendedAmount)

  const {
    finalDateToShow: relaxedFinalDateToShow,
    // monthsLeft: relaxedMonthsLeft,
    // yearsLeft: relaxedYearsLeft,
  } = useMysteryModeFinalDate(relaxedRecommendedAmount)
  const {
    finalDateToShow: aggressiveFinalDateToShow,
    // monthsLeft: aggressiveMonthsLeft,
    // yearsLeft: aggressiveYearsLeft,
  } = useMysteryModeFinalDate(aggressiveRecommendedAmount)

  // const totalMonthsLeft = yearsLeft * 12 + monthsLeft
  // const totalRelaxedMonthsLeft = relaxedYearsLeft * 12 + relaxedMonthsLeft
  // const totalAggressiveMonthsLeft = aggressiveYearsLeft * 12 + aggressiveMonthsLeft

  // const totalInterestPaid = calculateTotalInterest(
  //   cleanCurrency(debtAmount),
  //   debtInterest / 100,
  //   totalMonthsLeft
  // )

  // const totalRelaxedInterestPaid = calculateTotalInterest(
  //   cleanCurrency(debtAmount),
  //   debtInterest / 100,
  //   totalRelaxedMonthsLeft
  // )

  // const totalAggressiveInterestPaid = calculateTotalInterest(
  //   cleanCurrency(debtAmount),
  //   debtInterest / 100,
  //   totalAggressiveMonthsLeft
  // )

  const mysteryColor = colorFromMysteryDayType(mysteryMode)

  const goalReachedWord = getGoalReachedWord(mysteryMode, cushionSubMode)

  return (
    <>
      <TextSpan size="md" weight={200} className="mt-3" color="textsupertransparent">
        <ContributionTitlePhrase mysteryMode={mysteryMode} />
      </TextSpan>

      <StyledHr className="mb-2" />

      {notFeasible ? (
        <>
          <div className="d-flex flex-column text-center d-inline">
            <Icon className="ml-1 fas fa-exclamation-triangle" color="text" />
            <TextSlim
              className="my-0 align-self-center text-center px-3 mb-2 d-inline"
              weight={300}
              style={{
                lineHeight: '1.75rem',
              }}
              size="xs"
              color="textsupertransparent">
              It appears that your{' '}
              <TextSpan weight={800} color="text" className="my-0">
                Monthly Cost of Living
              </TextSpan>
              exceeds your <b>Income </b>by{' '}
              <TextSpan weight={800} color="text" className="my-0">
                {numberToCurrency(baseRecommendedAmount)}
              </TextSpan>{' '}
              per month.
            </TextSlim>
            <TextSlim
              className="my-0 align-self-center text-center px-3 mb-2 d-inline"
              weight={300}
              style={{
                lineHeight: '1.75rem',
              }}
              size="xs"
              color="textsupertransparent">
              Unfortunately we cannot recommend monthly savings options or be able to show you an
              effective dashboard towards your goals if you have no disposable income to save.
            </TextSlim>
            <TextSlim
              className="my-0 align-self-center text-center px-3 mb-2 d-inline"
              weight={300}
              style={{
                lineHeight: '1.75rem',
              }}
              size="xs"
              color="text">
              Update your <b>Income</b> or <b>Monthly Cost of Living</b> such that you have
              disposable income.
            </TextSlim>
          </div>
        </>
      ) : (
        <>
          <CaptionText className="my-0 align-self-center text-center px-3 mb-2 d-inline">
            Here is an initial range of recommended <MonthlyActionWord mysteryMode={mysteryMode} />.
            We recommend setting aside this amount automatically each month into a separate,
            dedicated savings account.
          </CaptionText>
        </>
      )}
      {notFeasible ? null : (
        <div className="w-100 align-items-center justify-content-between d-flex w-100 my-0">
          <ContributionSelection
            selection={'low'}
            active={activeContributionSelection === 'low'}
            onClick={() => {
              setActiveContributionSelection('low')
              setMonthlyContribution(relaxedRecommendedAmount)
            }}
            amount={coastMode ? frugalMonthlyBurnRate : relaxedRecommendedAmount}
            date={coastMode ? frugalOutOfMoneyDate : relaxedFinalDateToShow}
            title={coastMode ? 'Frugal' : 'Relaxed'}
            goalWord={goalReachedWord}
            activeColor={mysteryColor}
          />
          <ContributionSelection
            selection={'medium'}
            active={activeContributionSelection === 'medium'}
            onClick={() => {
              setActiveContributionSelection('medium')
              setMonthlyContribution(baseRecommendedAmount)
            }}
            amount={coastMode ? monthlyBurnRate : baseRecommendedAmount}
            date={coastMode ? currentOutOfMoneyDate : finalDateToShow}
            title={coastMode ? 'Current' : 'Recommended'}
            goalWord={goalReachedWord}
            activeColor={mysteryColor}
          />
          <ContributionSelection
            selection={'high'}
            active={activeContributionSelection === 'high'}
            onClick={() => {
              setActiveContributionSelection('high')
              setMonthlyContribution(aggressiveRecommendedAmount)
            }}
            amount={coastMode ? lavishMonthlyBurnRate : aggressiveRecommendedAmount}
            date={coastMode ? lavishOutOfMoneyDate : aggressiveFinalDateToShow}
            title={coastMode ? 'Lavish' : 'Aggresive'}
            goalWord={goalReachedWord}
            activeColor={mysteryColor}
          />
        </div>
      )}

      {notFeasible ? null : (
        <>
          <CaptionText className="my-0 align-self-center text-center px-3 mb-2 d-inline mt-3">
            {getSubtext(mysteryMode, cushionSubMode, activeContributionSelection)}
          </CaptionText>
          <StyledHr weight={1} color="textsupertransparent" className="my-2" />

          <TextSlim
            className="my-0 align-self-center text-center px-3 mb-2 d-inline mt-1"
            weight={700}
            size="xxs"
            color="texttransparent">
            Use the dashboard to fine tune this amount later.
          </TextSlim>
        </>
      )}
    </>
  )
}
