import React from 'react'

export const useSetupSlider = () => {
  const sliderContainerRef = React.useRef<HTMLDivElement>(null)

  const [sliderWidth, setSliderWidth] = React.useState(0)
  React.useEffect(() => {
    if (sliderContainerRef && sliderContainerRef.current) {
      const width = sliderContainerRef.current?.clientWidth || 0

      const styles = window.getComputedStyle(sliderContainerRef.current)
      const paddingWidth = parseFloat(styles.paddingLeft) + parseFloat(styles.paddingRight)
      const marginWidth = parseFloat(styles.marginLeft) + parseFloat(styles.marginRight)
      const paddingAndMargin = paddingWidth + marginWidth
      setSliderWidth(width - paddingAndMargin)

      // HACK: This nonsense is to force the zIndex of the ACTUAL thumb div component (the styles we set below are for the thumbs child)
      const children = sliderContainerRef.current?.children
      if (children) {
        for (let i = 0; i < children.length; i++) {
          const child = children[i] as HTMLElement

          if (/-Slider/.test(child.className)) {
            const childChildren = child.children
            if (childChildren) {
              const thumbComponent = childChildren[1] as HTMLDivElement
              thumbComponent.style.zIndex = '100'
              thumbComponent.style.padding = '20px'
            }
          }
        }
      }
    }
  }, [sliderContainerRef])

  return { sliderContainerRef, sliderWidth }
}
