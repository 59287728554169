import React from 'react'

// import logo from 'Logos/splurv_logo_transparent.png'
import { datadogRum } from '@datadog/browser-rum'
import { Icon, StyledHr, StyledVr, TextSpan } from 'Components/DesignSystem/Typography'
import logo from 'Logos/splurv_logo_bright.png'
import { fireConfetti } from 'Pages/WishlistPage/Confetti'
import { useSplurvSnippets } from 'Providers/SplurvSnippetProvider/SplurvSnippetProvider'
import { useUIState } from 'Providers/UIStateProvider'
import { ColorType, FontSizeType, colorPalletes } from 'theme'
import { LogoContainer } from './SplurvSnippet.components'

interface Props {
  popupContent: React.ReactElement
  title: string
  color?: ColorType
  style?: React.CSSProperties
  logoSize?: number
}
export const SplurvSnippet: React.FC<Props> = ({
  popupContent,
  title,
  color = 'logoPrimary',
  style,
  logoSize,
}) => {
  const { setPopup, activeTheme } = useUIState()
  const { visitedSnippets, visitSnippet } = useSplurvSnippets()
  const firstTime = !visitedSnippets.includes(title)
  return (
    <div
      className="p-1"
      style={{
        ...style,
        opacity: firstTime ? 1 : 0.6,
      }}
      onClick={() => {
        if (!visitedSnippets.includes(title)) {
          datadogRum.addAction('splurv-snippet-clicked', {
            'splurv-snippet': {
              firstTime: true,
              title,
            },
          })
          visitSnippet(title)
          fireConfetti({
            duration: 0.3,
            colors: [
              colorPalletes[activeTheme].logoPrimary,
              colorPalletes[activeTheme].logoSecondary,
            ],
          })
          setPopup(
            <SplurvSnippetContent title={title} snippetNumber={visitedSnippets.length + 1}>
              <div className="px-4 pt-3">{popupContent}</div>
            </SplurvSnippetContent>
          )
        } else {
          datadogRum.addAction('splurv-snippet-clicked', {
            'splurv-snippet': {
              firstTime: false,
              title,
            },
          })
          setPopup(
            <SplurvSnippetContent title={title} snippetNumber={visitedSnippets.indexOf(title) + 1}>
              <div className="px-4 pt-3">{popupContent}</div>
            </SplurvSnippetContent>
          )
        }
      }}>
      <LogoContainer color={color} size={logoSize} firstTime={firstTime}>
        <img src={logo} alt="Splurv Logo" />
        <Icon className="fas fa-check-circle" size="xs" color="logoPrimary" />
      </LogoContainer>
    </div>
  )
}

interface SplurvSnippetContentProps {
  title: string
  snippetNumber: number
}
const SplurvSnippetContent: React.FC<SplurvSnippetContentProps> = ({
  title,
  children,
  snippetNumber,
}) => {
  return (
    <div
      className="d-flex flex-column align-items-start p-3 justify-content-start mt-0 w-100"
      style={{
        transition: 'opacity ease-in-out .3s',
      }}>
      <div className="d-flex flex-row w-100 justify-content-start align-items-center px-3">
        <div className="d-flex flex-row justify-content-start align-items-center">
          <LogoContainer size={20} color="logoPrimary">
            <img src={logo} alt="Splurv Logo" />
          </LogoContainer>
          <SplurvSnippetText size="xs" />
          <StyledVr weight={1} className="mx-2" size="sm" />
          <TextSpan color="textsupertransparent">#{snippetNumber}</TextSpan>
        </div>
      </div>
      <TextSpan weight={200} color="texttransparent" size="sm" className="mb-1 ml-1 px-3">
        {title}
      </TextSpan>
      <StyledHr
        color="nearlyclear"
        className="my-2 mx-4"
        weight={1}
        style={{
          maxWidth: 'calc(100% - 3rem)',
        }}
      />
      {children}
    </div>
  )
}

interface SplurvSnippetTextProps {
  size?: FontSizeType
}
export const SplurvSnippetText: React.FC<SplurvSnippetTextProps> = ({ size = 'sm' }) => {
  return (
    <>
      <TextSpan color="logoPrimary" size={size} className="mb-1 ml-1" weight={700}>
        Splurv
      </TextSpan>
      <TextSpan color="logoSecondary" size={size} className="mb-1 ml-1" weight={700}>
        Snippets
      </TextSpan>
    </>
  )
}
