import styled from 'styled-components/macro'

export const ModalContainer = styled.div<{ active: boolean }>`
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: ${(props) => (props.active ? 1 : 0)};
  transition: all ease-in-out 0.3s;
  pointer-events: ${(props) => (!props.active ? 'none' : '')};
  z-index: 7000;

  display: flex;
  flex-direction: column;
  align-items: center;

  position: absolute;
  top: 0;
`

export const Content = styled.div`
  width: calc(100vw - 2rem);
  height: calc(90%);
  top: 5%;
  min-height: calc(80%);
  max-height: calc(90%);
  z-index: 7000;
  margin: 0 0.5rem;
  position: absolute;
  border-width: 0px;
  border-style: solid;
  border-bottom: none;
  border-color: ${(props) => props.theme.colors.second};

  background: linear-gradient(135deg, #000c15, #071c23);

  background: linear-gradient(135deg, #b6d8e3, #c8fadc);
  background: ${(props) => props.theme.colors.background};
  background: transparent;
  display: flex;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  flex-direction: column;
  align-items: center;
`
