import { useGetTrackedAccountDetails } from 'Components/AccountTrackingCTA/hooks'
import { TextSpan } from 'Components/DesignSystem/Typography'
import { RELAXED_RECOMMENDED_AGGRESIVE_SPREAD } from 'Pages/MysteryPage/Pages/TotalCostOfLivingComplete/TotalCostOfLivingComplete'
import { isBefore } from 'date-fns'
import React from 'react'
import { GraphErrorBoundary } from '../../../Components/GraphErrorBoundary'
import { useData } from '../../../Providers/APIDataProvider'
import { useFinancialData } from '../../../Providers/FinancialDataProvider/FinancialDataProvider'
import { useMysteryDay } from '../../../Providers/MysteryDayProvider/MysteryDayProvider'
import { cleanCurrency } from '../../../Utilities/currencyFormater'
import { stringToDate } from '../../../Utilities/dateUtilities/dateUtilities'
import {
  MAX_YEARS_FOR_REPAYING_DEBT,
  calculateBurndownValues,
  calculateItemPayoffValues,
  calculateMinimumMonthlyPayment,
  calculatePrincipalValues,
} from '../helpers'
import { ProjectionGraphComponent } from './ProjectionGraphComponent/ProjectionGraphComponent'
import { useSeeIfGoalIsMet } from './helpers'

export const ProjectionGraph: React.FC = () => {
  const {
    debtAmount,
    debtInterest,
    monthlyContribution,
    startDate,
    itemCost,
    mysteryMode,
    desiredTimeOff,
    cushionSubMode,
    historicalTrackedTransactions,
  } = useMysteryDay()

  const { currentSavings } = useFinancialData()
  const goalIsMet = useSeeIfGoalIsMet()

  const trackedAccountDetails = useGetTrackedAccountDetails()

  const linkedAccountsTransactions = historicalTrackedTransactions.sort((ta, tb) => {
    const taDate = stringToDate(ta.date)
    const tbDate = stringToDate(tb.date)

    if (isBefore(taDate, tbDate)) return 1
    if (isBefore(tbDate, taDate)) return -1
    return 0
  })

  const currentBalance = trackedAccountDetails?.amount || 0

  const cleanDebt = currentBalance * -1 || cleanCurrency(debtAmount)
  const interestDecimal = debtInterest / 100
  const date = stringToDate(startDate)

  const { monthlyBurnRate } = useData()

  const savingsBurnDownValues = calculateBurndownValues(
    currentSavings,
    monthlyBurnRate || 100,
    date
  )

  const savingsBurnDownLabels = savingsBurnDownValues.map((ipv) => ipv.date)
  const savingsBurnDownData = savingsBurnDownValues.map((ipv) => ipv.balance)

  const minimumPaymentNeeded = calculateMinimumMonthlyPayment(
    cleanDebt,
    interestDecimal,
    MAX_YEARS_FOR_REPAYING_DEBT
  )

  let conservativeMonthlyDebtContribution =
    monthlyContribution * (1 - RELAXED_RECOMMENDED_AGGRESIVE_SPREAD)
  if (conservativeMonthlyDebtContribution < minimumPaymentNeeded)
    conservativeMonthlyDebtContribution = minimumPaymentNeeded

  const debtPayoffValues = calculatePrincipalValues(
    Math.abs(cleanDebt),
    interestDecimal,
    monthlyContribution < minimumPaymentNeeded ? minimumPaymentNeeded : monthlyContribution,
    date
  )

  const itemPaymentValues = calculateItemPayoffValues(
    cleanCurrency(itemCost),
    monthlyContribution,
    date,
    currentBalance || currentSavings
  )

  const totalRunwayDesired = monthlyBurnRate * desiredTimeOff
  const runwayFundValues = calculateItemPayoffValues(
    totalRunwayDesired,
    monthlyBurnRate,
    date,
    currentBalance || currentSavings
  )

  const historicalTransactionsForLinkedAccount =
    linkedAccountsTransactions?.filter((t) =>
      mysteryMode === 'getOutOfDebt' ? t.amount < 0 : true
    ) || []
  const paymentsSoFarLabels = historicalTransactionsForLinkedAccount.map((t) =>
    stringToDate(t.date)
  )

  let runningTotal = Math.abs(currentBalance)
  const historicalBalancesWithTransactions = historicalTransactionsForLinkedAccount
    .map((t) => {
      const newValue =
        mysteryMode === 'getOutOfDebt'
          ? Math.abs(runningTotal) + Math.abs(t.amount)
          : Math.abs(runningTotal) - t.amount
      runningTotal = newValue
      return newValue
    })
    .map((v) => (mysteryMode === 'getOutOfDebt' ? v * -1 : v))

  const debtPayoffLabels = debtPayoffValues.map((pv) => pv.date)
  const debtPayoffData = debtPayoffValues.map((pv) => pv.balance)

  const itemPayoffLabels = itemPaymentValues.map((ipv) => ipv.date)
  const itemPayoffData = itemPaymentValues.map((ipv) => ipv.balance)

  const runwayFundLabels = runwayFundValues.map((ipv) => ipv.date)
  const runwayFundData = runwayFundValues.map((ipv) => ipv.balance)

  const labels =
    mysteryMode === 'getOutOfDebt'
      ? debtPayoffLabels
      : mysteryMode === 'buildCushion' && cushionSubMode === 'extendSavings'
      ? savingsBurnDownLabels
      : mysteryMode === 'saveToQuit'
      ? runwayFundLabels
      : itemPayoffLabels

  const data =
    mysteryMode === 'getOutOfDebt'
      ? debtPayoffData
      : mysteryMode === 'buildCushion' && cushionSubMode === 'extendSavings'
      ? savingsBurnDownData
      : mysteryMode === 'saveToQuit'
      ? runwayFundData
      : itemPayoffData

  const nullPlaceholdersForFutureData = Array.from({
    length: paymentsSoFarLabels.length ? paymentsSoFarLabels.length + 1 : 0,
  }).map((d) => null)

  const todaysBalance = mysteryMode === 'getOutOfDebt' ? currentBalance * -1 : currentBalance

  if (goalIsMet) {
    return <TextSpan>You have met your goal!</TextSpan>
  }

  return (
    <GraphErrorBoundary>
      <ProjectionGraphComponent
        pastLabels={
          historicalBalancesWithTransactions.length > 0
            ? [...paymentsSoFarLabels.reverse(), new Date()]
            : []
        }
        futureLabels={labels}
        goalAmount={cleanCurrency(itemCost)}
        pastData={
          historicalBalancesWithTransactions.length > 0
            ? [...historicalBalancesWithTransactions.reverse(), todaysBalance]
            : []
        }
        futureData={[...nullPlaceholdersForFutureData, ...data]}
        mode={mysteryMode}
        todaysBalance={todaysBalance}
        historicalTransactionsExist={historicalTransactionsForLinkedAccount.length > 0}
      />
    </GraphErrorBoundary>
  )
}
