import { MysteryDayType } from 'Providers/MysteryDayProvider/MysteryDayProvider.interfaces'
import { dateToString } from 'Utilities/dateUtilities/dateUtilities'
import { colorFromMysteryDayType } from 'Utilities/utils'
import { ChartDataset, ScriptableContext } from 'chart.js'
import { AnyObject } from 'chart.js/dist/types/basic'
import { isBefore } from 'date-fns'
import { ColorType, colorPalletes } from 'theme'

type OverallChartConfig = ChartDataset<'line', number[]>
const pastColor: ColorType = 'cashgreen'

/**
 * This is the width of the line in the graph
 * @param ctx
 * @param options
 * @returns
 */
const borderWidth: number | ((ctx: ScriptableContext<'line'>, options: AnyObject) => number) = (
  ctx,
  options
) => {
  return 1
}

const borderColor: OverallChartConfig['borderColor'] = (ctx, options) => {
  return colorPalletes['newDark'].texttransparent
}

const getPointStyle: (lastDate: Date) => OverallChartConfig['pointStyle'] = (lastDate) => {
  return (datum) => {
    const datumDate = new Date(datum?.parsed?.x)
    if (dateToString(datumDate) === dateToString(lastDate)) {
      return 'star'
    }

    if (dateToString(datumDate) === dateToString(new Date())) {
      return 'circle'
    }

    if (isBefore(datumDate, new Date())) {
      return 'circle'
    }
    return 'circle'
  }
}

const getPointColor: (
  lastDate: Date,
  mode: MysteryDayType,
  activeTheme: keyof typeof colorPalletes
) => OverallChartConfig['pointBackgroundColor'] = (lastDate, mode, activeTheme) => {
  return (datum) => {
    const color = colorFromMysteryDayType(mode)
    const datumDate = new Date(datum?.parsed?.x)
    const isBeforeToday = isBefore(datumDate, new Date())

    const isToday = dateToString(new Date(datum?.parsed?.x)) === dateToString(new Date())

    if (isToday) {
      return colorPalletes[activeTheme]['seconddark']
    }
    if (isBeforeToday) {
      return colorPalletes[activeTheme][pastColor]
    }

    return colorPalletes[activeTheme][color]
  }
}

const getPointRadius: (lastDate: Date) => OverallChartConfig['pointRadius'] = (lastDate) => {
  return (datum) => {
    if (dateToString(new Date(datum?.parsed?.x)) === dateToString(lastDate)) {
      return 8
    }
    if (dateToString(new Date(datum?.parsed?.x)) === dateToString(new Date())) {
      return 4
      // return 0.25
    } else {
      return 1.5
    }
  }
}

type StaticChartConfig = Omit<OverallChartConfig, 'data'>
const _staticConfig: StaticChartConfig = {
  type: 'line',
  showLine: false,
  stepped: false,
  fill: {
    target: 'origin',
    above: 'transparent',
    below: 'transparent',
  },
}

interface ConfigParams {
  lastDate: Date
  mode: MysteryDayType
  activeTheme: keyof typeof colorPalletes
}
export const generateConfig = (params: ConfigParams): StaticChartConfig => {
  const { lastDate, mode, activeTheme } = params
  const dotColor = getPointColor(lastDate, mode, activeTheme)
  return {
    ..._staticConfig,
    pointStyle: getPointStyle(lastDate),
    pointBackgroundColor: dotColor,
    pointBorderColor: dotColor,
    pointRadius: getPointRadius(lastDate),
    borderWidth,
    borderColor,
  }
}
