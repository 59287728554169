import { useAuth0 } from '@auth0/auth0-react'
import { useAsyncLocalStorageState } from 'Hooks/useAsyncLocalStorageState'
import { useLocalStorageSyncedState } from 'Hooks/useLocalStorageSyncedState'
import React, { useEffect } from 'react'
import { formatCurrency } from '../../Utilities/currencyFormater'
import { LinkType } from '../../Utilities/navigationTabUtilities'
import { useFinancialData } from '../FinancialDataProvider/FinancialDataProvider'
import { mysteryDayProviderDefaultData } from './MysteryDayProvider.defaultData'
import {
  CushionSubModeType,
  DashboardViewModeType,
  FlexibleCalculationMethodType,
  HistoricalTrackedTransaction,
  HybridRegularAndInvestmentTransaction,
  MysteryDayContextState,
  MysteryDayType,
  MysteryPageType,
  SelectedAccountType,
  UserOnboardingSetupInterface,
} from './MysteryDayProvider.interfaces'

const MysteryDayContext = React.createContext<MysteryDayContextState>(mysteryDayProviderDefaultData)

export const useMysteryDay = () => React.useContext(MysteryDayContext)

/**
 * The Onboarding Managing Provider:
 * This manages everything related to the onboarding process.
 * This includes which mode the user is in, which page they are on, all the values they input
 * (debt amount, item cost, desired time off, saved for item, etc.)
 *
 * For now this also stores
 * a.) their monthly savings/debt contribution
 * b.) their monthly cost of living adjustment
 *
 * These last two values could probably be stored elsewhere as they are more global than related to just onboarding
 *
 * @returns
 */
export const MysteryDayProvider: React.FC = ({ children }) => {
  const { flexibleSpendPerMonth, flatManualAccounts } = useFinancialData()
  const { user } = useAuth0()

  const {
    data: userOnboardingSetup,
    issueRequest: getUserOnboardingSetup,
    loading: getUserOnboardingSetupLoading,
  } = useAsyncLocalStorageState<{ userOnboardingSetup: UserOnboardingSetupInterface }, {}, {}>({
    method: 'GET',
    route: 'user_onboarding_setup',
  })

  const {
    data: liveTransactionsForTrackedAccount,
    issueRequest: getLiveTransactionsForTrackedAccount,
    // loading: getLiveTransactionsForTrackedAccountLoading,
  } = useAsyncLocalStorageState<
    { data: HybridRegularAndInvestmentTransaction[] },
    {
      itemId: string
      accountId: string
      monthsOfTransactions: number
      accountType: 'regular' | 'investment'
    },
    {}
  >({
    method: 'GET',
    route: 'transactions_for_tracked_account',
  })

  const {
    issueRequest: saveUserOnboardingSetup,
    loading: saveUserOnboardingSetupLoading,
  } = useAsyncLocalStorageState<UserOnboardingSetupInterface, {}, UserOnboardingSetupInterface>({
    method: 'POST',
    route: 'user_onboarding_setup',
  })

  const {
    issueRequest: saveUserOnboardingLinkedAccount,
    loading: saveUserOnboardingLinkedAccountLoading,
  } = useAsyncLocalStorageState<{}, {}, SelectedAccountType>({
    method: 'POST',
    route: 'user_onboarding_setup/linked_account',
  })

  const {
    issueRequest: deleteUserOnboardingSetup,
    loading: deleteUserOnboardingSetupLoading,
  } = useAsyncLocalStorageState<{}, {}, {}>({
    method: 'DELETE',
    route: 'user_onboarding_setup',
  })

  const [
    historicalTrackedTransactions,
    setHistoricalTrackedTransactions,
    clearHistoricalTrackedTransactions,
  ] = useLocalStorageSyncedState<HistoricalTrackedTransaction[]>(
    mysteryDayProviderDefaultData.historicalTrackedTransactions,
    'historical-tracked-transactions'
  )

  const [page, _setPage, resetPage] = useLocalStorageSyncedState<MysteryPageType>(
    mysteryDayProviderDefaultData.page,
    'mystery-day-page'
  )

  const [bigWhy, setBigWhy, resetBigWhy] = useLocalStorageSyncedState<string>(
    mysteryDayProviderDefaultData.bigWhy,
    'mystery-day-big-why'
  )

  const [debtAmount, _setDebtAmount, resetDebtAmount] = useLocalStorageSyncedState<string>(
    mysteryDayProviderDefaultData.debtAmount,
    'mystery-day-debt-amount'
  )

  const [itemCost, _setItemCost, resetItemCost] = useLocalStorageSyncedState<string>(
    mysteryDayProviderDefaultData.itemCost,
    'mystery-day-item-cost'
  )

  const [
    itemExplanation,
    setItemExplanation,
    resetItemExplanation,
  ] = useLocalStorageSyncedState<string>(
    mysteryDayProviderDefaultData.itemExplanation,
    'mystery-day-item-explanation'
  )

  const [
    livingCostPercentageAdjustment,
    setLivingCostPercentageAdjustment,
    resetLivingCostPercentageAdjustment,
  ] = useLocalStorageSyncedState<number>(
    mysteryDayProviderDefaultData.livingCostPercentageAdjustment,
    'mystery-day-living-cost-percentage-adjustment'
  )

  const [itemName, setItemName, resetItemName] = useLocalStorageSyncedState<string>(
    mysteryDayProviderDefaultData.itemName,
    'mystery-day-item-name'
  )

  const [
    monthlyContribution,
    setMonthlyContribution,
    resetMonthlyContribution,
  ] = useLocalStorageSyncedState<number>(
    mysteryDayProviderDefaultData.monthlyContribution,
    'mystery-day-monthly-contribution'
  )

  const [
    desiredTimeOff,
    setDesiredTimeOff,
    resetDesiredTimeOff,
  ] = useLocalStorageSyncedState<number>(
    mysteryDayProviderDefaultData.desiredTimeOff,
    'mystery-day-desired-time-off'
  )

  const [startDate, setStartDate, resetStartDate] = useLocalStorageSyncedState<string>(
    mysteryDayProviderDefaultData.startDate,
    'mystery-day-start-date'
  )

  const [debtInterest, _setDebtInterest, resetDebtInterest] = useLocalStorageSyncedState<number>(
    mysteryDayProviderDefaultData.debtInterest,
    'mystery-day-debt-interest'
  )

  const [
    mysteryMode,
    setMysteryMode,
    resetMysteryMode,
  ] = useLocalStorageSyncedState<MysteryDayType>(
    mysteryDayProviderDefaultData.mysteryMode,
    'mystery-page-mode'
  )

  const [
    cushionSubMode,
    setCushionSubMode,
    resetCushionSubMode,
  ] = useLocalStorageSyncedState<CushionSubModeType>(
    mysteryDayProviderDefaultData.cushionSubMode,
    'mystery-page-cushion-sub-mode'
  )

  const [
    calculationMethod,
    setCalculationMethod,
    clearCalculationMethod,
  ] = useLocalStorageSyncedState<FlexibleCalculationMethodType>(
    null,
    'cost-of-living-variable-calc-method'
  )

  const [
    dashboardViewMode,
    setDashboardViewMode,
    clearDashboardViewMode,
  ] = useLocalStorageSyncedState<DashboardViewModeType>(
    mysteryDayProviderDefaultData.dashboardViewMode,
    'dashboard-view-mode'
  )

  const [linkedAccount, setLinkedAccount, clearLinkedAccount] = useLocalStorageSyncedState<
    MysteryDayContextState['linkedAccount']
  >(mysteryDayProviderDefaultData.linkedAccount, 'linked-account')

  const [linkedAccounts, setLinkedAccounts, clearLinkedAccounts] = useLocalStorageSyncedState<
    MysteryDayContextState['linkedAccounts']
  >(mysteryDayProviderDefaultData.linkedAccounts, 'linked-accounts')

  const addLinkedAccount = (account: SelectedAccountType) => {
    setLinkedAccounts((accounts) => [...accounts, account])
  }

  const [
    onboardingDone,
    setOnboardingDone,
    resetOnboardingDone,
  ] = useLocalStorageSyncedState<boolean>(
    mysteryDayProviderDefaultData.onboardingDone,
    'mystery-day-onboarding-done'
  )

  const [pagesUnlocked, setPagesUnlocked] = useLocalStorageSyncedState<LinkType[]>(
    mysteryDayProviderDefaultData.pagesUnlocked,
    'mystery-day-pages-unlocked'
  )

  const unlockPage = (newPages: LinkType[]) => {
    setPagesUnlocked((pages) => Array.from(new Set([...pages, ...newPages])))
  }

  const [
    onboardingPagesVisited,
    setOnboardingPagesVisited,
    resetOnboardingPagesVisited,
  ] = useLocalStorageSyncedState<MysteryPageType[]>(
    mysteryDayProviderDefaultData.onboardingPagesVisited,
    'mystery-day-onboarding-pages-visited'
  )

  const visitOnboardingPage = (page: MysteryPageType) => {
    setOnboardingPagesVisited((pages) => Array.from(new Set([...pages, page])))
  }

  const setPage = (page: MysteryPageType) => {
    visitOnboardingPage(page)
    _setPage(page)
  }

  const resetOnboarding = () => {
    resetPage()
    resetDebtAmount()
    resetItemCost()
    resetItemName()
    resetOnboardingPagesVisited()
    resetItemExplanation()
    resetDebtInterest()
    resetDesiredTimeOff()
    clearDashboardViewMode()
    resetLivingCostPercentageAdjustment()
    resetMysteryMode()
    resetCushionSubMode()
    resetMonthlyContribution()
    resetStartDate()
    clearCalculationMethod()
    clearHistoricalTrackedTransactions()
    resetOnboardingDone()
    resetBigWhy()
    clearLinkedAccount()
    clearLinkedAccounts()
  }

  const setDebtInterest = (interest: number) => {
    if (interest > 100) {
      _setDebtInterest(100)
      return
    }
    if (interest < 0) {
      _setDebtInterest(0)
      return
    } else {
      _setDebtInterest(interest)
      return
    }
  }

  const setDebtAmount = React.useCallback(
    (amount: string) => {
      const formattedValue = formatCurrency(amount, 10, 'positive')
      _setDebtAmount(formattedValue)
    },
    [_setDebtAmount]
  )

  const setItemCost = React.useCallback(
    (amount: string) => {
      const formattedValue = formatCurrency(amount, 10, 'positive')
      _setItemCost(formattedValue)
    },
    [_setItemCost]
  )

  React.useEffect(() => {
    if (user) {
      getUserOnboardingSetup({})
    }
  }, [getUserOnboardingSetup, user])

  React.useEffect(() => {
    if (userOnboardingSetup) {
      try {
        const {
          completed,
          debtAmount: upstreamDebtAmount,
          desiredTimeOff: upstreamDesiredTimeOff,
          itemCost: upstreamItemCost,
          itemName: upstreamItemName,
          itemExplanation: upstreamItemExplanation,
          linkedAccount: upstreamLinkedAccount,
          linkedAccounts: upstreamLinkedAccounts,
          mode,
          subMode,
        } = userOnboardingSetup?.userOnboardingSetup || {}
        setMysteryMode((mysteryMode) => mode || mysteryMode || 'default')
        setCushionSubMode((cushionSubMode) => subMode || cushionSubMode || null)
        setOnboardingDone((onboardingDone) => completed || onboardingDone || false)
        setLinkedAccount(
          (linkedAccount) =>
            upstreamLinkedAccount || linkedAccount || mysteryDayProviderDefaultData.linkedAccount
        )
        setLinkedAccounts(
          (linkedAccounts) =>
            upstreamLinkedAccounts || linkedAccounts || mysteryDayProviderDefaultData.linkedAccounts
        )
        setDesiredTimeOff((desiredTimeOff) => upstreamDesiredTimeOff || desiredTimeOff || 0)
        setItemCost(upstreamItemCost || itemCost || '')
        setItemName(upstreamItemName || itemName || '')
        setItemExplanation(upstreamItemExplanation || itemExplanation || '')
        setDebtAmount(String(upstreamDebtAmount) || String(debtAmount) || '')
      } catch (e) {
        console.error('...userOnboardingSetup found, but could not set values', e)
      }
    } else {
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userOnboardingSetup,
    setOnboardingDone,
    flexibleSpendPerMonth,
    setCushionSubMode,
    setMysteryMode,
    setLinkedAccount,
    setLinkedAccounts,
    setItemCost,
    setItemName,
    setItemExplanation,
    setDesiredTimeOff,
    setDebtAmount,
  ])

  useEffect(() => {
    if (monthlyContribution === 0) {
      setMonthlyContribution(100)
    }
  }, [monthlyContribution, setMonthlyContribution])

  useEffect(() => {
    if (linkedAccount?.type === 'live') {
      const transactions = liveTransactionsForTrackedAccount?.data
      if (transactions && transactions.length) {
        setHistoricalTrackedTransactions(transactions)
      }
    } else {
      const flatManualAccountThatIsBeingTracked = flatManualAccounts.find(
        (fma) => fma.account_id === linkedAccount?.accountId
      )
      if (flatManualAccountThatIsBeingTracked) {
        const transactions = flatManualAccountThatIsBeingTracked.transactions
        setHistoricalTrackedTransactions(
          transactions.map(({ account_id, amount, date, name }) => ({
            name,
            date,
            account_id,
            amount: Number(amount),
          }))
        )
      }
    }
  }, [
    linkedAccount,
    liveTransactionsForTrackedAccount,
    flatManualAccounts,
    setHistoricalTrackedTransactions,
  ])

  useEffect(() => {
    if (user) {
      if (linkedAccount && linkedAccount?.type === 'live') {
        const { accountId, itemId } = linkedAccount
        getLiveTransactionsForTrackedAccount({
          queryParamsConfig: {
            accountId,
            itemId,
            monthsOfTransactions: 24,
            accountType: 'regular',
          },
        })
      }
    }
  }, [linkedAccount, user, getLiveTransactionsForTrackedAccount])

  return (
    <MysteryDayContext.Provider
      value={{
        historicalTrackedTransactions,
        clearHistoricalTrackedTransactions,
        page,
        bigWhy,
        setBigWhy,
        setPage,
        mysteryMode,
        setMysteryMode,

        saveUserOnboardingSetup: (setup) =>
          saveUserOnboardingSetup({
            bodyParamsConfig: setup,
          }),
        saveUserOnboardingSetupLoading,
        getUserOnboardingSetupLoading,
        deleteUserOnboardingSetupLoading,

        saveUserOnboardingLinkedAccount: (setup: SelectedAccountType) =>
          saveUserOnboardingLinkedAccount({
            bodyParamsConfig: setup,
          }),
        saveUserOnboardingLinkedAccountLoading,

        deleteUserOnboardingSetup: async () => {
          setPage('mode-selection')
          await deleteUserOnboardingSetup({})
          await getUserOnboardingSetup({})
        },

        cushionSubMode,
        setCushionSubMode,
        debtAmount,
        setDebtAmount,
        debtInterest,
        setDebtInterest,
        monthlyContribution,
        setMonthlyContribution,
        onboardingDone,
        setOnboardingDone,

        desiredTimeOff,
        setDesiredTimeOff,
        resetOnboarding,
        calculationMethod,
        setCalculationMethod,
        itemCost,

        livingCostPercentageAdjustment,
        setLivingCostPercentageAdjustment,
        setItemCost,
        itemExplanation,
        setItemExplanation,
        itemName,
        dashboardViewMode,
        startDate,
        setStartDate,
        setDashboardViewMode,
        setItemName,

        pagesUnlocked,
        onboardingPagesVisited,

        unlockPage,
        visitOnboardingPage,

        linkedAccount,
        setLinkedAccount,

        linkedAccounts,
        addLinkedAccount,
        clearLinkedAccounts,
      }}>
      {children}
    </MysteryDayContext.Provider>
  )
}
