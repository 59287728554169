import { useLocalStorageSyncedState } from 'Hooks/useLocalStorageSyncedState'
import React from 'react'
import { ContainerDiv } from '../../Components/DesignSystem/Layout/Containers/Containers'
import { useMysteryDay } from '../../Providers/MysteryDayProvider/MysteryDayProvider'
import { MonthlyExpenseSlider } from '../MysteryPage/MonthlyExpenseSlider/MonthlyExpenseSlider'
import { SalarySavingsSlider } from '../MysteryPage/SalarySavingsSlider/SalarySavingsSlider'
import { DebtDaySummary } from './DebtDaySumary'
import { DreamDaySummary } from './DreamDaySummary'
import { GetOrganizedDashboard } from './GetOrganizedDashboard/GetOrganizedDashboard'
import { ProjectionGraph } from './GraphPage/ProjectionGraph'
import { LaunchDayProjectionGraph } from './LaunchDayGraphPage/LaunchDayProjectionGraph'
import { LaunchDaySummary } from './LaunchDaySummary'
import { RainyDayFundDashboard } from './RainyDayFundDashboard/RainyDayFundDashboard'
import { SavingsCoastSummary } from './SavingsCoastSummary'

export const DashboardPageContent: React.FC = () => {
  const { mysteryMode, monthlyContribution, cushionSubMode } = useMysteryDay()

  const [cashFlowRecapOpen, setCashFlowRecapOpen] = useLocalStorageSyncedState<boolean>(
    false,
    'cashflow-recap-open'
  )

  if (mysteryMode === 'saveToQuit') {
    return (
      <ContainerDiv className="pt-2">
        <LaunchDaySummary
          cashFlowRecapOpen={cashFlowRecapOpen}
          setCashFlowRecapOpen={setCashFlowRecapOpen}
        />
        {monthlyContribution === 0 ? (
          <div style={{ minHeight: '20vh', maxHeight: '20vh' }}></div>
        ) : (
          <LaunchDayProjectionGraph />
        )}

        <SalarySavingsSlider
          graphVersion
          slim
          narrowRangeBasedOnRecommendation
          setCashFlowRecapOpen={setCashFlowRecapOpen}
        />
      </ContainerDiv>
    )
  }

  return (
    <ContainerDiv className="pt-2">
      {mysteryMode === 'getOrganized' ? (
        <DreamDaySummary
          cashFlowRecapOpen={cashFlowRecapOpen}
          setCashFlowRecapOpen={setCashFlowRecapOpen}
        />
      ) : mysteryMode === 'getOutOfDebt' ? (
        <DebtDaySummary
          cashFlowRecapOpen={cashFlowRecapOpen}
          setCashFlowRecapOpen={setCashFlowRecapOpen}
        />
      ) : mysteryMode === 'buildCushion' &&
        (cushionSubMode === 'emergencyFund' || cushionSubMode === 'getOrganized') ? null : (
        <SavingsCoastSummary />
      )}
      {mysteryMode === 'buildCushion' && cushionSubMode === 'emergencyFund' ? (
        <RainyDayFundDashboard />
      ) : mysteryMode === 'buildCushion' && cushionSubMode === 'getOrganized' ? (
        <GetOrganizedDashboard />
      ) : (
        <ProjectionGraph />
      )}

      <div
        className="d-flex flex-column justify-content-end"
        style={{ flex: 1, minHeight: 'fit-content' }}>
        {mysteryMode === 'buildCushion' && cushionSubMode === 'extendSavings' ? (
          <MonthlyExpenseSlider />
        ) : mysteryMode === 'buildCushion' &&
          (cushionSubMode === 'getOrganized' || cushionSubMode === 'emergencyFund') ? null : (
          <SalarySavingsSlider
            graphVersion
            slim
            narrowRangeBasedOnRecommendation
            setCashFlowRecapOpen={setCashFlowRecapOpen}
          />
        )}
      </div>
    </ContainerDiv>
  )
}
