import { useLocalStorageSyncedState } from 'Hooks/useLocalStorageSyncedState'
import React from 'react'

const storeLocalStorageInMemory = () => {
  const localStorageData: { [key: string]: string } = {}

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i)
    if (key) {
      const value = localStorage.getItem(key)
      if (value) {
        localStorageData[key] = value
      }
    }
  }

  return localStorageData
}

const restoreLocalStorageFromMemory = (data: { [key: string]: string }) => {
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      localStorage.setItem(key, data[key])
    }
  }
}

export function formatBytes(bytes: number, decimals = 2) {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

function _getMaxSizeOfLocalStorage() {
  //   if (localStorage && !localStorage.getItem('size')) {
  const localStoreTemp = storeLocalStorageInMemory()
  localStorage.clear()
  var i = 0
  try {
    // Test up to 10 MB
    for (i = 250; i <= 10000; i += 250) {
      localStorage.setItem('test', new Array(i * 1024 + 1).join('a'))
    }
  } catch (e) {
    console.error(e)
    localStorage.removeItem('test')
    localStorage.setItem('size', String(i - 250))
    restoreLocalStorageFromMemory(localStoreTemp)
    return (i - 250) * 1024
  }
}

/**
 * "Javascript uses UTF16 internally, so because each character is stored as two bytes you need to multiply the number of characters by two to get the actual space used"
 * @returns
 */
const _computeSizeOfSplurvLocalStorage = () => {
  var _lsTotal = 0,
    _xLen,
    _x
  for (_x in localStorage) {
    if (!localStorage.hasOwnProperty(_x)) {
      continue
    }
    // if (!_x.includes(SPLURV_LOCAL_STORAGE_PREFIX)) {
    //   continue
    // }
    _xLen = (localStorage[_x].length + _x.length) * 2
    _lsTotal += _xLen
  }
  return _lsTotal
}

/**
 * @description This hook is used to get the total size of the local storage occupied by Splurv data
 */
export const useGetLocalStorageStats = () => {
  const [totalSize, setTotalSize] = useLocalStorageSyncedState<number>(0, 'totalLocalStorageSize')
  const [maxSize, setMaxSize] = useLocalStorageSyncedState<number>(0, 'maxSizeOfLocalStorage')

  const [calculatingSizes, setCalculatingSizes] = React.useState(false)

  const calculateSizes = React.useCallback(() => {
    setCalculatingSizes(true)
    setTimeout(() => {
      setTotalSize(_computeSizeOfSplurvLocalStorage())
      setMaxSize(_getMaxSizeOfLocalStorage() as number)
      setTimeout(() => {
        setCalculatingSizes(false)
      }, 1000)
    }, 1000)
  }, [setMaxSize, setTotalSize])

  return { totalSize, maxSize, calculatingSizes, calculateSizes }
}
