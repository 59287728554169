import {
  Chart,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  ScriptableContext,
  TimeScale,
  TimeSeriesScale,
} from 'chart.js'
import { isAfter, isSameDay } from 'date-fns'
import React from 'react'
import { Line } from 'react-chartjs-2'
import { useMysteryDay } from '../../../Providers/MysteryDayProvider/MysteryDayProvider'
import { numberToCurrency } from '../../../Utilities/currencyFormater'
import { ChartContainer } from './components'

import 'chartjs-adapter-date-fns'
import color from 'color'
import { enUS } from 'date-fns/locale'
import { MysteryDayType } from 'Providers/MysteryDayProvider/MysteryDayProvider.interfaces'
import { useUIState } from 'Providers/UIStateProvider'
import { colorPalletes, myTheme } from 'theme'
import { getOptions } from '../GraphPage/ProjectionGraphComponent/Options'
import { annotationStyleConfig } from '../GraphPage/ProjectionGraphComponent/ProjectionGraphComponent'

// import rocketimagesvg from './rocket-launch.svg'

// const RocketImage = new Image()

// RocketImage.src = rocketimagesvg
// RocketImage.width = 15
// RocketImage.height = 15

Chart.register(TimeScale)
Chart.register(TimeSeriesScale)
Chart.register(LinearScale)
Chart.register(PointElement)
Chart.register(LineController)
Chart.register(LineElement)
Chart.register(Legend)
Chart.register(Filler)

interface Props {
  launchDayLabels: Date[]
  launchDayData: (number | null)[]

  coastingLabels: Date[]
  coastingData: (number | null)[]

  mode: MysteryDayType
  goalAmount: number
  launchDayAmount: number
  launchDayDate: Date

  launchDayReached: boolean
}

export const LaunchDayGraphComponent: React.FC<Props> = ({
  launchDayData,
  launchDayLabels,
  coastingData,
  coastingLabels,
  launchDayDate,
  launchDayReached,
  launchDayAmount,
}) => {
  const { activeTheme } = useUIState()
  const { mysteryMode } = useMysteryDay()
  const launchThemeColor = colorPalletes[activeTheme].orange

  const lastDay = coastingLabels[coastingLabels.length - 1]

  const labels = [...launchDayLabels, ...coastingLabels]

  const options = getOptions(mysteryMode, activeTheme)
  // const labels = coastingLabels
  return (
    <ChartContainer
      style={{
        opacity: launchDayReached ? 0 : 1,
        transition: 'all ease-in-out .3s',
      }}>
      <Line
        redraw={false}
        options={{
          ...options,
          animations: {
            tension: {
              duration: 300,
              easing: 'linear',
              from: 1,
              to: 0,
              loop: true,
            },
          },
          // interaction: {
          //   mode: 'index',
          //   intersect: false,
          // },
          maintainAspectRatio: false,

          responsive: true,

          plugins: {
            legend: {
              display: false,
            },
            annotation: {
              clip: false,
              annotations: [
                {
                  type: 'line',
                  drawTime: 'afterDatasetsDraw',
                  xScaleID: 'x',
                  yScaleID: 'y',
                  xMin: launchDayDate.getTime(),
                  xMax: launchDayDate.getTime(),
                  value: launchDayDate.getTime(),
                  yMax: launchDayAmount,
                  yMin(ctx, options) {
                    return 0
                  },
                  borderColor: color(myTheme.colors['orange']).alpha(1).toString(),
                  borderWidth: 2,
                  label: {
                    content: ['launch', 'day'], // Set the label content
                    display: true,
                    borderRadius: 10,
                    color: myTheme.colors['background'],
                    borderWidth: 0,
                    borderColor: color(myTheme.colors['background']).alpha(1).toString(),
                    ...annotationStyleConfig,
                    backgroundColor: myTheme.colors.orange,
                    position: 'start', // Adjust the label position as needed
                    xAdjust: 0,
                    yAdjust: -0,
                  },
                },
                {
                  type: 'line',
                  drawTime: 'afterDatasetsDraw',
                  xScaleID: 'x',
                  yScaleID: 'y',
                  xMin: lastDay.getTime(),
                  xMax: lastDay.getTime(),
                  value: lastDay.getTime(),
                  yMax: launchDayAmount,
                  yMin(ctx, options) {
                    return 0
                  },
                  borderColor: color(myTheme.colors['softblue']).alpha(1).toString(),
                  borderWidth: 0,
                  label: {
                    content: ['time off'], // Set the label content
                    display: true,
                    borderRadius: 10,
                    color: myTheme.colors['background'],
                    borderWidth: 0,
                    borderColor: color(myTheme.colors['background']).alpha(1).toString(),
                    ...annotationStyleConfig,
                    backgroundColor: myTheme.colors.softblue,
                    position: 'end', // Adjust the label position as needed
                    xAdjust: 5,
                    yAdjust: 20,
                  },
                },
                // {
                //   type: 'line',
                //   drawTime: 'afterDatasetsDraw',
                //   xScaleID: 'x',
                //   yScaleID: 'y',
                //   xMin: firstDate.getTime(),
                //   xMax: new Date().getTime(),
                //   value: firstDate.getTime(),
                //   yMin(ctx, options) {
                //     return minYValue || 0
                //   },
                //   yMax(ctx, options) {
                //     return minYValue || 0
                //   },
                //   borderColor: color(myTheme.colors['cashgreen']).alpha(1).toString(),
                //   borderWidth: 2,
                //   label: {
                //     content: 'debt paid so far', // Set the label content
                //     display: true,
                //     borderRadius: 10,
                //     color: myTheme.colors['background'],
                //     borderWidth: 0,
                //     borderColor: color(myTheme.colors['background']).alpha(1).toString(),
                //     ...annotationStyleConfig,
                //     backgroundColor: myTheme.colors.cashgreen,
                //     position: 'start', // Adjust the label position as needed
                //     xAdjust: 10,
                //     yAdjust: 0,
                //   },
                // },
                // {
                //   type: 'line',
                //   drawTime: 'afterDatasetsDraw',
                //   // scaleID: 'x',
                //   xScaleID: 'x',
                //   yScaleID: 'y',
                //   xMin: new Date().getTime(),
                //   xMax: new Date().getTime(),
                //   value: new Date().getTime(),
                //   yMax: todaysBalance,
                //   // yMin: minValue,
                //   yMin(ctx, options) {
                //     return minYValue || 0
                //     // return getMinimumValueFromDataSet(data)
                //   },
                //   borderColor: color(myTheme.colors['cashgreen']).alpha(1).toString(),
                //   borderWidth: 2,
                //   label: {
                //     content: 'today', // Set the label content
                //     display: true,
                //     borderRadius: 10,
                //     color: myTheme.colors['black'],
                //     borderWidth: 0,
                //     borderColor: color(myTheme.colors['text']).alpha(1).toString(),
                //     ...annotationStyleConfig,
                //     backgroundColor: myTheme.colors.seconddark,
                //     position: 'end', // Adjust the label position as needed
                //     xAdjust: 0,
                //     yAdjust: 20,
                //   },
                // },
              ],
            },
          },

          scales: {
            x: {
              type: 'time',

              adapters: {
                date: {
                  locale: enUS,
                },
              },

              time: {
                unit: 'day',
                displayFormats: {
                  day: 'MMM dd yy',
                },
              },

              ticks: {
                source: 'data',
                autoSkip: true,
                autoSkipPadding: 16,
              },
              alignToPixels: true,

              grid: {
                display: true,
              },
            },
            y: {
              ticks: {
                // Include a dollar sign in the ticks
                callback: function (value: any, index: any, values: any) {
                  return numberToCurrency(value, true, true)
                },
                includeBounds: true,
                color: launchThemeColor,
              },
              beginAtZero: false,
              grid: {
                display: true,
              },
            },
          },
        }}
        data={{
          labels,

          datasets: [
            {
              stack: 'launchday',
              label: 'Launch Day + Subsequent Coasting Timeline',
              borderWidth: 2,

              pointRadius: (datum: any) => {
                const parsedXDate = new Date(datum?.parsed?.x || '')
                if (isAfter(parsedXDate, launchDayDate) || isSameDay(parsedXDate, launchDayDate)) {
                  return 4
                }
                return 1.5
              },

              pointBackgroundColor: (datum) => {
                const parsedXDate = new Date(datum?.parsed?.x || '')
                if (isAfter(parsedXDate, launchDayDate) || isSameDay(parsedXDate, launchDayDate)) {
                  return colorPalletes[activeTheme].orange
                } else {
                  return colorPalletes[activeTheme].orange
                }
                // return datum.parsed.x >= launchDayAmount ? RocketImage : 'circle'
              },

              pointBorderColor: (datum) => {
                const parsedXDate = new Date(datum?.parsed?.x || '')
                if (isAfter(parsedXDate, launchDayDate) || isSameDay(parsedXDate, launchDayDate)) {
                  return colorPalletes[activeTheme].orange
                } else {
                  return colorPalletes[activeTheme].orange
                }
              },

              pointStyle: (datum) => {
                const parsedXDate = new Date(datum?.parsed?.x || '')
                if (isAfter(parsedXDate, launchDayDate) || isSameDay(parsedXDate, launchDayDate)) {
                  return 'circle'
                } else {
                  return 'circle'
                }
              },

              type: 'line',
              showLine: false,

              stepped: true,

              tension: 0.25,

              segment: {
                borderColor: (datum: any) => {
                  const parsedXDate = new Date(datum?.parsed?.x || '')
                  if (
                    isAfter(parsedXDate, launchDayDate) ||
                    isSameDay(parsedXDate, launchDayDate)
                  ) {
                    return colorPalletes[activeTheme].logoPrimary
                  } else {
                    return colorPalletes[activeTheme].orange
                  }
                },
              } as any,

              fill: 'start',
              backgroundColor: (context: ScriptableContext<'line'>) => {
                // @ts-ignore
                const ctx = context.chart.ctx
                // @ts-ignore
                const chartArea = context.chart.chartArea
                const gradient = ctx.createLinearGradient(
                  chartArea?.left || 0,
                  chartArea?.top || 100,
                  chartArea?.right || 0,
                  chartArea?.bottom || 100
                )
                const bgColor = color(myTheme['colors']['orange']).alpha(0.1).toString()
                const bgColorSecond = color(myTheme['colors']['orange']).alpha(0.2).toString()
                gradient.addColorStop(0, bgColorSecond)
                gradient.addColorStop(1, bgColor)
                return gradient
              },

              data: launchDayData,
            },
            {
              stack: 'launchday',
              label: 'Launch Day + Subsequent Coasting Timeline',
              borderWidth: 2,

              // This is the radius of each data point

              pointRadius: (datum: any) => {
                const parsedXDate = new Date(datum?.parsed?.x || '')
                if (isAfter(parsedXDate, launchDayDate) || isSameDay(parsedXDate, launchDayDate)) {
                  return 1.5
                }
                return 1.5
              },

              fill: 'start',
              backgroundColor: (context: ScriptableContext<'line'>) => {
                // @ts-ignore
                const ctx = context.chart.ctx
                // @ts-ignore
                const chartArea = context.chart.chartArea
                const gradient = ctx.createLinearGradient(
                  chartArea?.left || 0,
                  chartArea?.top || 100,
                  chartArea?.right || 0,
                  chartArea?.bottom || 100
                )
                const bgColor = color(myTheme['colors']['softblue']).alpha(0.1).toString()
                const bgColorSecond = color(myTheme['colors']['softblue']).alpha(0.2).toString()
                gradient.addColorStop(0, bgColorSecond)
                gradient.addColorStop(1, bgColor)
                return gradient
              },

              pointBackgroundColor: (datum) => {
                const parsedXDate = new Date(datum?.parsed?.x || '')
                if (isAfter(parsedXDate, launchDayDate) || isSameDay(parsedXDate, launchDayDate)) {
                  return colorPalletes[activeTheme].softblue
                } else {
                  return colorPalletes[activeTheme].orange
                }
              },

              pointBorderColor: (datum) => {
                const parsedXDate = new Date(datum?.parsed?.x || '')
                if (isAfter(parsedXDate, launchDayDate) || isSameDay(parsedXDate, launchDayDate)) {
                  return colorPalletes[activeTheme].softblue
                } else {
                  return colorPalletes[activeTheme].orange
                }
              },

              pointStyle: (datum) => {
                return 'circle'
              },

              showLine: false,

              stepped: false,

              tension: 0.25,

              data: coastingData,
            },
          ],
        }}
      />
    </ChartContainer>
  )
}
