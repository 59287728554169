import { TextSpan } from 'Components/DesignSystem/Typography'
import { numberToCurrency } from 'Utilities/currencyFormater'
import { add, format } from 'date-fns'
import React from 'react'
import {
  Indicator,
  IndicatorContainer,
  IndicatorHolder,
  OuterIndicator,
} from './RainyDayStatusIndicator.components'

interface Props {
  mcol: number
  savings: number
}
export const RainyDayStatusIndicator: React.FC<Props> = ({ mcol, savings }) => {
  const monthsSavedUp = Math.floor(savings / mcol)
  const savingsLeftForLastMonth = savings - monthsSavedUp * mcol
  const percentageOfLastMonthSavedFor = savingsLeftForLastMonth / mcol
  const sixMonthsWorthOfLivingExpenses = mcol * 6

  return (
    // TODO:
    //   NOTE: We can animate clip-path on the entire container of the 6 circles to animate the circles filling up
    <IndicatorContainer>
      <progress max={sixMonthsWorthOfLivingExpenses} value={savings} />
      <div className="d-flex flex-row w-100 align-items-center justify-content-between">
        {Array.from(Array(6).keys()).map((index) => {
          const month = format(add(new Date(), { months: index }), 'MMM')
          const state =
            index + 1 <= monthsSavedUp
              ? 'complete'
              : index >= monthsSavedUp + 1
              ? 'empty'
              : 'partial'
          return (
            <IndicatorHolder>
              <TextSpan className="date" color="texttransparent" weight={500} size="xxxs">
                {month}
              </TextSpan>
              <Indicator
                key={index}
                state={state}
                percentageFilled={percentageOfLastMonthSavedFor}></Indicator>
              <TextSpan
                className="price"
                color={
                  state === 'complete'
                    ? 'cashgreen'
                    : state === 'partial'
                    ? 'softblue'
                    : 'texttransparent'
                }
                weight={500}
                size="xxxs">
                {numberToCurrency(mcol)}
              </TextSpan>
              <OuterIndicator state={state} percentageFilled={percentageOfLastMonthSavedFor} />
            </IndicatorHolder>
          )
        })}
      </div>
      {/* <progress
        max={sixMonthsWorthOfLivingExpenses}
        value={sixMonthsWorthOfLivingExpenses -savings}
        className="flip"
      /> */}
    </IndicatorContainer>
  )
}
