import { Icon, TextBold } from 'Components/DesignSystem/Typography'
import { SettingsItem } from 'Pages/SettingsPage/components'
import { useData } from 'Providers/APIDataProvider'
import { useFinancialData } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import React from 'react'

export const GetAllData: React.FC = () => {
  const {
    accountsData,
    getAccounts,
    getLiabilities,
    getManualAccounts,
    liabilitiesData,
  } = useFinancialData()

  const { getStreams, fetchingStreams } = useData()
  const loading =
    fetchingStreams || accountsData.status === 'loading' || liabilitiesData.status === 'loading'

  return (
    <SettingsItem
      onClick={() => {
        getLiabilities({})
        getAccounts({})
        getManualAccounts({})
        getStreams()
      }}>
      <Icon className={`fas fa-sync ${loading ? 'fa-spin' : ''}`} />
      <TextBold>Get Data</TextBold>
    </SettingsItem>
  )
}
