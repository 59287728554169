import { LinkType } from 'Utilities/navigationTabUtilities'

export const CONTENT_PADDING_HORIZONTAL = 16
export const TOP_CONTAINER_PADDING_FOR_NOTCH = 48
export const TOP_CONTAINER_PADDING_FOR_FOOTER = 24

const HEADER_SIZE = 48
const FOOTER_SIZE = 104

const CONTENT_PADDING_TOP = 16

export const TOTAL_VERTICAL_SPACE_OUTSIDE_CONTENT =
  HEADER_SIZE + FOOTER_SIZE + TOP_CONTAINER_PADDING_FOR_NOTCH + CONTENT_PADDING_TOP

export const getGridTemplateRows = () => {
  return `${HEADER_SIZE}px 1fr ${FOOTER_SIZE}px`
}

export const getGridTemplateAreas = () => {
  return `
      "header"
      "content"
      "footer"
      `
}

export const getPaddingForContent = (activeLink: LinkType) => {
  if (['/demo', '/'].includes(activeLink)) {
    return 0
  }
  return `${CONTENT_PADDING_TOP}px ${CONTENT_PADDING_HORIZONTAL}px 0`
}
