import { StyledHr } from 'Components/DesignSystem/Typography'
import { ForwardButton } from 'Pages/MysteryPage/MysteryDayForwardButton'
import { MysteryDayType } from 'Providers/MysteryDayProvider/MysteryDayProvider.interfaces'
import { WideButton } from 'design-system/buttons/WideButton/WideButton'
import React from 'react'
import { ContainerDiv } from '../../../../Components/DesignSystem/Layout/Containers/Containers'
import { useMysteryDay } from '../../../../Providers/MysteryDayProvider/MysteryDayProvider'
import {
  MysteryModeButtonTitlesFromMysteryDayType,
  colorFromMysteryDayType,
} from '../../../../Utilities/utils'
import { SubPageProps } from '../../MysteryPage'
import { ModeButtonContainer } from './ModeSelectionPage.components'
import { OnboardingMap } from './OnboardingMap'

export const ModeSelectionPage: React.FC<SubPageProps> = () => {
  const { setMysteryMode, mysteryMode } = useMysteryDay()
  const workingModes: MysteryDayType[] = [
    'getOutOfDebt',
    'buildCushion',
    'getOrganized',
    'saveToQuit',
  ]
  return (
    <ContainerDiv
      className="justify-content-between"
      style={{
        marginTop: '-1.5rem',
      }}>
      <div className="d-flex flex-column justify-content-start align-items-center py-2 w-100">
        <ModeButtonContainer>
          {workingModes.map((type) => {
            const { title } = MysteryModeButtonTitlesFromMysteryDayType(type)

            const color = colorFromMysteryDayType(type)
            return (
              <WideButton
                key={type}
                textSize="small"
                appearance="secondary"
                width="full"
                bgColor={mysteryMode === type ? color : 'textsupertransparent'}
                active={mysteryMode === type}
                onClick={() => {
                  if (mysteryMode === type) {
                    setMysteryMode('default')
                  } else {
                    setMysteryMode(type)
                  }
                }}
                text={title}
              />
            )
          })}
        </ModeButtonContainer>
        <div className="d-flex flex-column w-100 align-items-center ">
          <div className="d-flex flex-row w-100 align-items-center justify-content-between"></div>
          <StyledHr color="nearlyclear" weight={1} className="my-2" />
        </div>
        <OnboardingMap />
      </div>
      <div className="d-flex justify-content-end align-items-center w-100">
        <ForwardButton
          style={{
            width: '100%',
            borderWidth: mysteryMode === 'default' ? 0 : 2,
            padding: mysteryMode === 'default' ? 0 : '-2px',
          }}
          title={mysteryMode === 'default' ? 'Pick mode from above' : 'Start Onboarding'}
          disabledPopupTitle="Pick a mode to start onboarding"
          disabledPopupBody="Click on one of the 4 mode buttons above to get started"
          disabled={mysteryMode === 'default'}
        />
      </div>
    </ContainerDiv>
  )
}
