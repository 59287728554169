import { Pill } from 'Components/DesignSystem/Pill/Pill'
import { FIXED_ICON, VARIABLE_ICON } from 'Utilities/navigationTabUtilities'
import React from 'react'

interface Props {
  calculationType: 'fixed' | 'variable' | 'skip' | undefined
}

export const TransactionTypeBadge: React.FC<Props> = ({ calculationType }) => {
  if (calculationType) {
    return (
      <Pill
        iconClassName={`${
          calculationType === 'variable'
            ? VARIABLE_ICON
            : calculationType === 'fixed'
            ? FIXED_ICON
            : 'far fa-forward'
        }
          `}
        bgColor={
          calculationType === 'variable'
            ? 'yellow'
            : calculationType === 'fixed'
            ? 'mehred'
            : 'text'
        }
        text={
          calculationType === 'variable'
            ? 'variable'
            : calculationType === 'fixed'
            ? 'fixed'
            : 'skipped'
        }
      />
    )
  } else {
    return (
      <Pill
        iconClassName={`fas fa-rectangle-vertical-history fa-beat-fade`}
        bgColor={'logoPrimary'}
        text={'Reflection Needed'}
      />
    )
  }
}
