import { useAuth0 } from '@auth0/auth0-react'
import { usePWA } from 'Providers/PWAProvider/PWAProvider'
import { LightIndicator } from 'Providers/PWAProvider/PWAcomponents'
import { useSubscriptionStatus } from 'Providers/SubscriptionStatusProvider/SubscriptionStatusProvider'
import { useUIState } from 'Providers/UIStateProvider'
import React from 'react'
import { Avatar, TextSpan } from './DesignSystem/Typography'

export const AVATAR_SIZE = 48

interface Props {
  size?: number
}
export const UserAvatar: React.FC<Props> = ({ size }) => {
  const { user } = useAuth0()

  const { updateAvailable } = usePWA()

  const { setSettingsPageOpen } = useUIState()
  const { status, trialDaysLeft } = useSubscriptionStatus()
  return (
    <div className="position-relative d-flex flex-column align-items-center">
      <Avatar
        onClick={() => setSettingsPageOpen(true)}
        referrerPolicy="no-referrer"
        src={user?.picture || ''}
        size={size}
      />
      {status === 'inactive' ? (
        <TextSpan
          color={trialDaysLeft < 0 ? 'mehred' : 'orange'}
          size="xxxxs"
          style={{
            position: 'absolute',
            bottom: '-20px',
          }}>
          {trialDaysLeft > 0 ? `${trialDaysLeft}d left` : `trial over`}
        </TextSpan>
      ) : (
        <TextSpan
          color="logoPrimary"
          size="xxxxs"
          style={{
            position: 'absolute',
            bottom: '-20px',
          }}>
          premium
        </TextSpan>
      )}
      {updateAvailable ? (
        <LightIndicator
          placement="relative"
          color="logoPrimary"
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
          }}
        />
      ) : null}
    </div>
  )
}
