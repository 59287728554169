import { CollapseCardControlled } from 'Components/CollapseCard/CollapseCardControlled'
import { useUserCustomSettings } from 'Providers/UserCustomSettingsProvider/UserCustomSettingsProvider'
import { MoneyEvent } from 'Utilities/interfaces'
import { AccountBase } from 'plaid'
import React from 'react'
import { TextSpan } from '../../Components/DesignSystem/Typography'
import { useFinancialData } from '../../Providers/FinancialDataProvider/FinancialDataProvider'
import { getCreditCardAccounts } from '../../Providers/FinancialDataProvider/helpers'
import { numberToCurrency } from '../../Utilities/currencyFormater'
import { ConnectedSpendingAccountRow } from './ConnectedSpendingAccountRow'
import { SpendingRow } from './SpendingRow'

export interface SpendingRowProps {
  account: AccountBase
  index: number

  isOpenFromTetheredView?: boolean

  activelySelectedAccountId: string
  setActivelySelectedAccountId: (id: string) => void

  events: MoneyEvent[]
}
export const SpendingAccountRow: React.FC<SpendingRowProps> = (props) => {
  const { account } = props

  const { settings } = useUserCustomSettings()

  const accountMetadata = settings?.accountMetadata.find(
    (datum) => datum.account_id === account.account_id
  )

  const { flatAccounts, transactions } = useFinancialData()

  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(true)

  const connectedCreditCards =
    settings?.accountMetadata.find((datum) => datum.account_id === account.account_id)
      ?.tetheredCreditCards || []

  const discoveredLinkedCreditCardAccountIds = connectedCreditCards

  const allConnectedCreditCards = connectedCreditCards

  const cashAccountIsLinkedToCC = discoveredLinkedCreditCardAccountIds.length

  const creditCardAccounts = getCreditCardAccounts(flatAccounts).filter(
    (account) => !accountMetadata?.hidden
  )

  const connectedCreditCardAccounts = creditCardAccounts?.filter((c) =>
    discoveredLinkedCreditCardAccountIds.includes(c?.account_id)
  )
  const totalBalanceAcrossConnectedCreditCards = connectedCreditCardAccounts.reduce(
    (acc, curr) => acc + (curr?.balances?.current || 0),
    0
  )

  if (
    allConnectedCreditCards?.includes(account.account_id) &&
    props.activelySelectedAccountId !== account.account_id
  ) {
    return null
  }

  const pendingTransactionsFromConnectedCreditCardAccount =
    transactions
      ?.filter((t) => discoveredLinkedCreditCardAccountIds.includes(t.account_id))
      .filter((t) => t.pending)
      .reduce((acc, curr) => acc + curr.amount, 0) || 0

  const currentBalance = account.balances.available || account.balances.current || (0 as number)

  const finalBalance = cashAccountIsLinkedToCC
    ? (currentBalance || 0) -
      totalBalanceAcrossConnectedCreditCards -
      pendingTransactionsFromConnectedCreditCardAccount
    : currentBalance || 0

  if (cashAccountIsLinkedToCC) {
    return (
      <CollapseCardControlled
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        activeColor="textsupertransparent"
        passiveColor="textsupertransparent"
        skipBottomBorder
        body={
          <div className="pb-3 px-3 pl-5">
            <TextSpan
              weight={200}
              size="xxs"
              className="d-inline"
              color="textsupertransparent"
              style={{ display: 'inline' }}>
              This cash account{' '}
              {/* <AccountMaskAndLogo
                orientation="horizontal"
                logoSize={20}
                accountId={account.account_id}
              /> */}
              <TextSpan size="xxs" weight={500} color="cashgreen" className="mx-1">
                ({numberToCurrency(currentBalance, true, true)})
              </TextSpan>{' '}
              is also responsible for paying off{' '}
              <TextSpan color="mehred" weight={500}>
                {numberToCurrency(
                  totalBalanceAcrossConnectedCreditCards +
                    pendingTransactionsFromConnectedCreditCardAccount,
                  true,
                  true
                )}
              </TextSpan>{' '}
              in tethered credit cards listed below, thus has a more realistic balance of
              <TextSpan color="mehred" weight={500} className="mx-1">
                {numberToCurrency(finalBalance, true, true)}
              </TextSpan>{' '}
            </TextSpan>
            {connectedCreditCardAccounts.map((ccca) => (
              <ConnectedSpendingAccountRow
                activelySelectedAccountId={props.activelySelectedAccountId}
                setActivelySelectedAccountId={props.setActivelySelectedAccountId}
                key={ccca.account_id}
                account={ccca}
              />
            ))}
          </div>
        }
        header={<SpendingRow {...props} isOpenFromTetheredView={!isCollapsed} />}
      />
    )
  }
  return (
    <div
      style={{
        paddingRight: '72px',
      }}>
      <SpendingRow {...props} />
    </div>
  )
}
