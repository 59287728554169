import React from 'react'
import { CollapseCard } from '../../../Components/CollapseCard/CollapseCard'
import { useData } from '../../../Providers/APIDataProvider'
import { useFinancialData } from '../../../Providers/FinancialDataProvider/FinancialDataProvider'
import { useMysteryDay } from '../../../Providers/MysteryDayProvider/MysteryDayProvider'
import { cleanCurrency } from '../../../Utilities/currencyFormater'
import { getMonthlyIncome } from '../../../Utilities/netWorth/netWorth'
import { CashflowRecapContent } from './CashFlowRecapContent'
import { CashflowRecapHeader } from './CashFlowRecapHeader'

export const CashflowRecap = ({
  slim = false,
  graphVersion = false,
}: {
  slim?: boolean
  graphVersion?: boolean
}) => {
  const { debtAmount, mysteryMode, itemCost } = useMysteryDay()
  const { streams, monthlyBurnRate } = useData()
  const { currentSavings } = useFinancialData()

  const monthlyIncome = getMonthlyIncome(streams)

  const recommendedContribution = monthlyIncome - monthlyBurnRate

  if (!graphVersion) {
    return (
      <CashflowRecapHeader
        mysteryMode={mysteryMode}
        currentSavings={currentSavings}
        monthlyIncome={monthlyIncome}
        totalCost={monthlyBurnRate}
        itemCost={cleanCurrency(itemCost)}
        debtAmount={cleanCurrency(debtAmount)}
        recommendedContribution={recommendedContribution}
        slim={slim}
      />
    )
  }
  return (
    <CollapseCard
      elevated={false}
      header={
        <CashflowRecapHeader
          mysteryMode={mysteryMode}
          currentSavings={currentSavings}
          monthlyIncome={monthlyIncome}
          totalCost={monthlyBurnRate}
          itemCost={cleanCurrency(itemCost)}
          debtAmount={cleanCurrency(debtAmount)}
          recommendedContribution={recommendedContribution}
          slim={slim}
        />
      }
      body={<CashflowRecapContent mysteryMode={mysteryMode} />}
    />
  )
}
