import { TransitionFromAbove } from 'Components/Transitions/TransitionFromAbove'
import { IconButton } from 'design-system/buttons/IconButton/IconButton'
import { CaptionText, TitleText } from 'design-system/typography/Text/Text'
import React from 'react'
import { SlidingPageContainer, SlidingPageContent } from './SlidingPage.components'

export interface SlidingPageButton {
  title: string
  icon: string
  onClick: Function
  disabled?: boolean
}

interface Props {
  open: boolean
  title?: string
  subtitle?: string
  content: React.ReactElement
  primaryButton: SlidingPageButton | undefined
  deleteButton: SlidingPageButton | undefined
  secondaryButton: SlidingPageButton | undefined
}
export const SlidingPage: React.FC<Props> = ({
  content,
  open,
  primaryButton,
  secondaryButton,
  deleteButton,
  subtitle,
  title,
}) => {
  return (
    <TransitionFromAbove show={open}>
      <SlidingPageContainer>
        <div className="justify-content-start align-items-center py-0 w-100">
          <div className="d-flex flex-column w-100 align-items-start">
            {title ? <TitleText className="mb-0 ml-0">{title}</TitleText> : null}
            {subtitle ? (
              <CaptionText className="w-75 ml-0 my-1 mb-3">{subtitle}</CaptionText>
            ) : null}
          </div>
          <SlidingPageContent>{content}</SlidingPageContent>
        </div>
        <div className="d-flex flex-row w-100 justify-content-around mt-2">
          {secondaryButton ? (
            <IconButton
              onClick={() => secondaryButton.onClick()}
              title={secondaryButton.title}
              color={'textsupertransparent'}
              iconClassName={secondaryButton.icon}
            />
          ) : null}
          {deleteButton ? (
            <IconButton
              onClick={() => deleteButton.onClick()}
              title={deleteButton.title}
              color={'mehred'}
              iconClassName={'fas fa-trash'}
            />
          ) : null}

          {primaryButton ? (
            <IconButton
              onClick={() => primaryButton.onClick()}
              title={primaryButton.title}
              color={'text'}
              iconClassName={primaryButton.icon}
            />
          ) : null}
        </div>
      </SlidingPageContainer>
    </TransitionFromAbove>
  )
}
