import styled from 'styled-components/macro'

export const MeterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const IconHolder = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  i {
    font-size: 2.125rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  &:first-of-type {
    i {
      margin-left: 0;
    }
  }
  &:last-of-type {
    i {
      margin-right: 0;
    }
  }
`
