import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import { WideButton } from 'design-system/buttons/WideButton/WideButton'
import { CaptionText, SubtitleText } from 'design-system/typography/Text/Text'
import React from 'react'
import { ContainerDiv } from '../../../Components/DesignSystem/Layout/Containers/Containers'
import { TextSlim } from '../../../Components/DesignSystem/Typography'
import { BackButton } from '../MysteryDayBackButton'
import { ForwardButton } from '../MysteryDayForwardButton'
import { SubPageProps } from '../MysteryPage'

export const CushionInputPage: React.FC<SubPageProps> = () => {
  const { cushionSubMode, setCushionSubMode } = useMysteryDay()

  return (
    <ContainerDiv className="justify-content-start align-items-center py-3">
      <ContainerDiv className="justify-content-start align-items-center">
        <TextSlim size="lg" className="px-2" color="texttransparent">
          Let's get specific
        </TextSlim>
        <TextSlim size="sm" className="px-2 mb-2" color="texttransparent">
          Cushion can mean a lot. What resonates?{' '}
        </TextSlim>
        {/* 
        <CushionModeSelectionButton
          title="Help me feel organized"
          active={cushionSubMode === 'getOrganized'}
          onClick={() => {
            setCushionSubMode('getOrganized')
          }}
        /> */}

        <div className="d-flex flex-row w-100 align-items-start">
          {cushionSubMode === 'emergencyFund' ? (
            <div className="d-flex flex-column align-items-center">
              <SubtitleText>Rainy Day</SubtitleText>
              <CaptionText className="ml-1 px-3">
                Create more space between your expenses and your paycheck so that you can handle job
                uncertainty or job loss. Focus is on showing how lifestyle decisions can improve
                your financial grit and working towards not living paycheck to paycheck.
              </CaptionText>
            </div>
          ) : cushionSubMode === 'extendSavings' ? (
            <div className="d-flex flex-column align-items-center">
              <SubtitleText>Surprise Funds</SubtitleText>
              <CaptionText className="ml-1 px-3">
                Create sources of money to help weather the surpirse car repair, the unexpected
                medical bill, or the tax season surpirse. Focus is on building up separate savings
                accounts that are specifically reserved for unexpected emergencies so that you don't
                have to touch your main savings or take on credit.
              </CaptionText>
            </div>
          ) : cushionSubMode === 'getOrganized' ? (
            <div className="d-flex flex-column align-items-center">
              <SubtitleText>Get Organized</SubtitleText>
              <CaptionText className="ml-1 px-3">
                Keep things simple and just get an overview of your finances. Focus is on learning
                how to organize, understand, and see where your money is going.
              </CaptionText>
            </div>
          ) : (
            <div className="d-flex flex-column align-items-center">
              <SubtitleText>Pick an option</SubtitleText>
              <CaptionText className="ml-1 px-3">
                We will describe what each option will help you with.
              </CaptionText>
            </div>
          )}
          <div className="d-flex flex-column align-items-start">
            <WideButton
              active={cushionSubMode === 'emergencyFund'}
              onClick={() => {
                setCushionSubMode('emergencyFund')
              }}
              appearance="secondary"
              bgColor="softblue"
              className="mb-2"
              width="fit"
              text="Rainy Day Fund"
              textSize="small"
            />
            <WideButton
              active={cushionSubMode === 'extendSavings'}
              onClick={() => {
                setCushionSubMode('extendSavings')
              }}
              appearance="secondary"
              className="my-2"
              bgColor="softblue"
              width="fit"
              text="Surprise Funds"
              textSize="small"
            />

            <WideButton
              active={cushionSubMode === 'getOrganized'}
              onClick={() => {
                setCushionSubMode('getOrganized')
              }}
              appearance="secondary"
              className="my-2"
              bgColor="softblue"
              width="fit"
              text="Feel Organized"
              textSize="small"
            />
          </div>
        </div>

        {/* <Icon
          className="fal fa-arrow-down mt-3"
          color="textsupertransparent"
          size="xl"
          style={{
            marginBottom: '-.5rem',
            opacity: cushionSubMode === 'extendSavings' ? 1 : 0,
            transition: 'opacity .3s ease-in-out',
          }}
        /> */}

        {/* <div
          style={{
            opacity: cushionSubMode === 'extendSavings' ? 1 : 0,
            transition: 'opacity .3s ease-in-out',
            pointerEvents: cushionSubMode === 'extendSavings' ? 'all' : 'none',
          }}>
          <AccountTrackingCTA
            type={cushionSubMode === 'extendSavings' ? 'Savings' : 'Cash'}
            updateMode={false}
            title="Track With Live Savings Accounts"
            setupTitle="Live Savings Accounts"
          />
        </div> */}
      </ContainerDiv>

      <div className="d-flex justify-content-between align-items-center w-100">
        <BackButton />
        <ForwardButton
          disabledPopupTitle={!cushionSubMode ? 'You forgot to choose' : 'Support is coming soon!'}
          disabledPopupBody={
            !cushionSubMode
              ? 'You forgot to choose'
              : 'We are actively developing features for these.'
          }
          disabled={!cushionSubMode || cushionSubMode === 'extendSavings'}
          title={'Next'}
        />
      </div>
    </ContainerDiv>
  )
}
