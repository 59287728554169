import { MysteryPageType } from 'Providers/MysteryDayProvider/MysteryDayProvider.interfaces'
import { WideButton } from 'design-system/buttons/WideButton/WideButton'
import React from 'react'
import { useMysteryDay } from '../../Providers/MysteryDayProvider/MysteryDayProvider'
import { OnboardingLinkedList } from './OnboardingLinkedList'

export const BackButton: React.FC<{ lite?: boolean }> = ({ lite }) => {
  const { mysteryMode, page, setPage, resetOnboarding } = useMysteryDay()
  const onClick = () => {
    const currentPageLinkedListDatum = OnboardingLinkedList.find((datum) => {
      let pageToBeChecked: MysteryPageType
      if (typeof datum.page === 'function') {
        pageToBeChecked = datum.page(mysteryMode)
      } else {
        pageToBeChecked = datum.page
      }
      return pageToBeChecked === page
    })!
    const nextPageLinkedListDatum = currentPageLinkedListDatum.previousPage
    let previousPage: MysteryPageType | undefined

    if (typeof nextPageLinkedListDatum === 'function') {
      previousPage = nextPageLinkedListDatum(mysteryMode)
    } else {
      previousPage = nextPageLinkedListDatum
    }
    if (!previousPage) {
      return
    }

    if (previousPage === 'mode-selection') {
      resetOnboarding()
    } else {
      setPage(previousPage)
    }
  }
  return (
    <WideButton
      bgColor="text"
      appearance="secondary"
      active={true}
      width="fit"
      textSize="large"
      onClick={onClick}
      icon={{
        className: 'fas fa-arrow-left',
        position: 'left',
      }}
      text={lite ? '' : 'Back'}></WideButton>
  )
}
