import { dataParameterType } from 'Hooks/useLocalStorageSyncedState'
import { IconButton } from 'design-system/buttons/IconButton/IconButton'
import React from 'react'
import { ContainerCutout } from '../../Components/DesignSystem/Layout/Containers/Containers'
import { StyledHr, StyledVr } from '../../Components/DesignSystem/Typography'
import { ItemSizeFilter } from './WishlistDraggableList/WishlistDraggableList'

const styledHrHeight = 9
const labelVerticalMargins = 16
export const streamPageHeaderSize = 60 + styledHrHeight + labelVerticalMargins

interface WishlistPageHeaderProps {
  openPage: () => void
  setActiveItemSizeFilter: (v: dataParameterType<ItemSizeFilter>) => void
  activeItemSizeFilter: ItemSizeFilter
}
export const WishlistPageHeader: React.FC<WishlistPageHeaderProps> = ({
  openPage,
  setActiveItemSizeFilter,
  activeItemSizeFilter,
}) => {
  return (
    <ContainerCutout size={streamPageHeaderSize}>
      <div className="d-flex align-items-center w-100 justify-content-around">
        <IconButton
          onClick={() => {
            setActiveItemSizeFilter('small')
          }}
          title={`small`}
          color={activeItemSizeFilter === 'small' ? 'text' : 'textsupertransparent'}
          iconClassName={'fas fa-coins'}
          buttonClassName="mr-3"
        />

        <IconButton
          onClick={() => {
            setActiveItemSizeFilter('medium')
          }}
          title={'medium'}
          color={activeItemSizeFilter === 'medium' ? 'text' : 'textsupertransparent'}
          iconClassName={'fas fa-money-bill-1'}
          buttonClassName="mr-3"
        />
        <IconButton
          onClick={() => {
            setActiveItemSizeFilter('large')
          }}
          title={'large'}
          color={activeItemSizeFilter === 'large' ? 'text' : 'textsupertransparent'}
          iconClassName={'fas fa-sack-dollar'}
          buttonClassName="mr-3"
        />
        <StyledVr size="xl" />
        <IconButton
          onClick={() => openPage()}
          title={'Add'}
          color={'text'}
          iconClassName={'fas fa-plus mr-2'}
        />

        {/* <IconButton
          onClick={() => setStreamToEdit(EmptyStream, 'add', false)}
          title="+ income"
          color="cashgreen"
          iconClassName="fak fa-solid-sack-dollar-circle-plus"
        />
        <StyledVr weight={1} size="xxl" />
        <IconButton
          onClick={() => setStreamToEdit(EmptyBill, 'add', false)}
          title="+ bill"
          color="mehred"
          iconClassName="far fa-money-check-edit-alt"
        />

        <StyledVr weight={1} size="xxl" />
        <IconButton
          onClick={() => setStreamToEdit(EmptySubscription, 'add', false)}
          title="+ subscription"
          color="mehred"
          iconClassName="fak fa-solid-repeat-circle-dollar "
        />
      </div> */}
      </div>

      <StyledHr weight={1} color="nearlyclear" className="my-1" />
    </ContainerCutout>
  )
}
