import { SplurvSnippet } from 'Components/SplurvSnippet/SplurvSnippet'
import React from 'react'
import { ContainerDiv } from '../../../Components/DesignSystem/Layout/Containers/Containers'
import { StyledHr, TextSlim, TextSpan } from '../../../Components/DesignSystem/Typography'
import { DesiredTimeOffSlider } from '../DesiredTimeOffSlider/DesiredTimeOffSlider'
import { BackButton } from '../MysteryDayBackButton'
import { ForwardButton } from '../MysteryDayForwardButton'
import { SubPageProps } from '../MysteryPage'

export const LaunchDayInputPage: React.FC<SubPageProps> = () => {
  return (
    <ContainerDiv className="justify-content-between align-items-center py-3">
      <ContainerDiv className="justify-content-start align-items-center">
        <div className="d-flex flex-row">
          <TextSlim size="lg" className="px-2" color="texttransparent">
            Desired Sabbatical
          </TextSlim>
          <SplurvSnippet
            title="Sabbatical Launching"
            logoSize={26}
            popupContent={
              <>
                <TextSpan size="xxs" color="texttransparent">
                  Fun fact, Splurv got built on a successful sabbatical taken so this mode is near
                  and dear to our hearts 🚀 ❤️
                </TextSpan>
                <TextSpan size="xxs" color="texttransparent">
                  A few things that we've learned:
                </TextSpan>
                <TextSpan color="textsupertransparent" size="xxs">
                  <ul className="pl-1">
                    <li>
                      When factoring in your anticipated expenses following departure from earning
                      an income, don't expect to be able to reduce your expenses drastically just
                      because you don't have an income anymore. Lifstyle creep - the slow inflation
                      of our standard of living - is difficult to bring back down.
                    </li>
                    <li>
                      Allow yourself several months - 3 to 6 - to get used to your new lifestyle of
                      being unemployed and untethered. The initial disorientation of leaving the
                      routine of a 9 to 5 can be jarring.
                    </li>
                    <li>
                      Allow yourself several months - 3 to 6 - to find a job upon your return to
                      industry. Market trends may have changed, hiring freezes may have taken over,
                      and your tolerance for the corporate world may have diminished.
                    </li>
                  </ul>
                </TextSpan>
              </>
            }
          />
        </div>
        <TextSlim size="xs" className="px-4 mb-5" color="textsupertransparent">
          Use the slider to set your desired time off
        </TextSlim>

        <StyledHr className="my-1" color="orange" weight={1} />
        <DesiredTimeOffSlider />
        <StyledHr className="my-1" color="orange" weight={1} />

        <TextSlim size="sm" className="px-2 mb-2" color="texttransparent"></TextSlim>
      </ContainerDiv>

      <div className="d-flex justify-content-between align-items-center w-100">
        <BackButton />
        <ForwardButton title={'Next'} />
      </div>
    </ContainerDiv>
  )
}
