import styled from 'styled-components/macro'

export const StyledHeader = styled.header`
  grid-area: header;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.text};

  a {
    color: ${(props) => props.theme.colors.text};
  }
`
