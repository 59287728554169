import color from 'color'
import styled from 'styled-components/macro'

export const BudgetTableContainer = styled.div`
  display: flex;
  flex-direction: column;
`

interface BudgetTableRowProps {
  loading?: boolean
}

export const BudgetTableRowHeader = styled.div<BudgetTableRowProps>`
  width: 100%;
  align-items: center;
  display: grid;
  opacity: ${(props) => (props.loading ? 0.5 : 1)};
  border-bottom: ${(props) => `solid 1px ${color(props.theme.colors.nearlyclear).toString()}`};
  margin: 0.25rem 0.25rem;
  padding: 0.25rem 0;
  transition: background-color 0.5s ease-in-out;
  grid-template-columns: minmax(0, 0.5fr) minmax(0, 0.5fr) minmax(0, 12px) minmax(0, 1fr);
  * {
    display: flex;
    justify-content: flex-start;
    padding: 0 0.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: flex-start;
    display: inline-block;
    display: flex;
    text-align: left;
  }
  *:first-child {
    justify-content: flex-start;
  }
  *:last-child {
    text-align: end;
  }
`

export const BudgetTableRow = styled.div<BudgetTableRowProps>`
  width: 100%;
  align-items: center;
  display: grid;
  opacity: ${(props) => (props.loading ? 0.5 : 1)};
  border-bottom: ${(props) => `solid 1px ${color(props.theme.colors.nearlyclear).toString()}`};
  /* margin: 0rem 0.25rem; */
  margin: 0rem 0rem;
  padding: 0.5rem 0;
  padding: 0.5rem 0rem;
  height: 56px;
  min-height: 56px;
  transition: background-color 0.5s ease-in-out;
  grid-template-columns:
    minmax(0, 0.35fr) minmax(0, 1fr) minmax(0, 0.5fr) minmax(0, 0.5fr) minmax(0, 12px)
    minmax(0, 0.65fr);
  * {
    display: flex;
    justify-content: flex-start;
    padding: 0 0.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: flex-start;
    display: inline-block;
    display: flex;
    text-align: left;
  }
  *:first-child {
    justify-content: flex-start;
  }
  *:last-child {
    text-align: end;
    align-items: center;
  }
`
