import { TextSpan } from 'Components/DesignSystem/Typography'
import React from 'react'

import generatedGitInfo from 'generatedGitInfo.json'
import logo from 'Logos/splurv_logo_transparent_1024.png'

export const SplurvLegalInfo: React.FC = () => {
  return (
    <div
      className="d-flex flex-column px-2"
      style={{
        boxShadow: 'rgb(0 0 0 / 30%) 0px 0px 4px 2px',
        borderRadius: '8px',
      }}>
      <TextSpan
        style={{ zIndex: 50 }}
        color="textsupertransparent"
        size="md"
        weight={400}
        alignSelf="flex-start"
        textAlign="left">
        <img alt="splurv-logo" className="mr-2" style={{ maxHeight: '32px' }} src={logo} />
        SplurvLLC
      </TextSpan>
      <TextSpan
        style={{ zIndex: 50 }}
        className="my-0"
        color="textsupertransparent"
        size="xxs"
        weight={200}
        alignSelf="flex-start"
        textAlign="left">
        <TextSpan weight={700} color="textsupertransparent" size="xxs" className="mr-1">
          version:{' '}
        </TextSpan>
        {generatedGitInfo.gitCommitHash}
      </TextSpan>
      <TextSpan
        className="my-0"
        style={{ zIndex: 50 }}
        color="textsupertransparent"
        size="xxs"
        weight={200}
        alignSelf="flex-start"
        textAlign="left">
        <TextSpan weight={700} color="textsupertransparent" size="xxs" className="mr-1">
          released:{' '}
        </TextSpan>
        {generatedGitInfo.gitCommitDate}
      </TextSpan>
      {/* <TextSpan
        className="my-0"
        style={{ zIndex: 50 }}
        color="textsupertransparent"
        size="xxs"
        weight={200}
        alignSelf="flex-start"
        textAlign="left">
        <TextSpan weight={700} color="textsupertransparent" size="xxs" className="mr-1">
          branch:{' '}
        </TextSpan>
        {generatedGitInfo.gitBranch}
      </TextSpan> */}
      {/* <TextSpan
        className="my-0"
        style={{ zIndex: 50 }}
        color="textsupertransparent"
        size="xxs"
        weight={200}
        alignSelf="flex-start"
        textAlign="left">
        <TextSpan weight={700} color="textsupertransparent" size="xxs" className="mr-1">
          env:{' '}
        </TextSpan>
        {process.env.REACT_APP_APP_ENV}
      </TextSpan> */}

      {/* <TextSpan
        style={{ zIndex: 50 }}
        className="my-0"
        color="second"
        size="xxs"
        weight={200}
        alignSelf="flex-start"
        textAlign="center">
        <TextSpan weight={700} color="second" size="xxs" className="mr-1">
          release:{' '}
        </TextSpan>
        {generatedGitInfo.gitCommitMessage}
      </TextSpan> */}
    </div>
  )
}
