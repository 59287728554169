import { Icon, TextSlim, TextSpan } from 'Components/DesignSystem/Typography'
import { EmptyStatePage } from 'Components/EmptyStatePage/EmptyStatePage'
import { useLocalStorageSyncedState } from 'Hooks/useLocalStorageSyncedState'
import { CalendarPage } from 'Pages/CalendarPage/CalendarPage'
import { useFinancialData } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import { FIXED_ICON } from 'Utilities/navigationTabUtilities'
import {
  getMonthlyBills,
  getMonthlyIncome,
  getMonthlySubscriptions,
} from 'Utilities/netWorth/netWorth'
import React from 'react'
import { useHistory } from 'react-router'
import {
  ContainerDiv,
  ScrollingFull,
} from '../../Components/DesignSystem/Layout/Containers/Containers'
import {
  TransitionFromLeft,
  TransitionFromRight,
} from '../../Components/Transitions/TransitionFromAbove'
import { useData } from '../../Providers/APIDataProvider'
import { useStreamModifyingState } from '../../Providers/StreamModifyingProvider'
import { useUIState } from '../../Providers/UIStateProvider'
import { FrequencyType, MoneyStream } from '../../Utilities/interfaces'
import { CashFlowPage } from '../CashFlowPage/CashFlowPage'
import {
  SpendingReductionNotificationBanner,
  SpendingReductionNotificationBannerSize,
} from './SpendingReductionNotificationBanner/SpendingReductionNotificationBanner'
import { StreamPageContent } from './StreamPageContent'
import { StreamPageFooter } from './StreamPageFooter'
import { StreamPageHeader } from './StreamPageHeader'
import { StreamPageSubHeader } from './StreamPageSubHeader'
import { scrollingSectionCutoutSize } from './consts'

export type ScrollingModeTypes = 'name' | 'amount' | 'date' | 'transfer'

const _StreamPage: React.FC = () => {
  /* Provider State */
  const { streams, getStreams, fetchingStreams } = useData()
  const { setStreamToEdit } = useStreamModifyingState()

  const { onboardingDone } = useMysteryDay()
  const { push } = useHistory()

  const { monthlyContribution } = useMysteryDay()

  const { flexibleSpendPerMonth } = useFinancialData()

  const monthlyIncome = getMonthlyIncome(streams)
  const monthlyFixedExpenses = Math.abs(getMonthlyBills(streams) + getMonthlySubscriptions(streams))
  const monthlyVariableExpenses = flexibleSpendPerMonth

  const maxSavings = monthlyIncome - (monthlyFixedExpenses + monthlyVariableExpenses)

  const spendingReductionNeeded = monthlyContribution > maxSavings

  const { calendarPageOpen, cashFlowPageOpen } = useUIState()

  /* Local State */
  const [sortingMode, setSortingMode] = useLocalStorageSyncedState<ScrollingModeTypes>(
    'transfer',
    'fixed-page-sort-mode'
  )
  const [sortingOrder, setSortingOrder] = useLocalStorageSyncedState<number>(
    -1,
    'fixed-page-sort-order'
  )

  // Helper Functions
  const sortFunction = React.useCallback(
    (a: MoneyStream, b: MoneyStream) => {
      switch (sortingMode) {
        case 'amount':
          return a.amount > b.amount ? sortingOrder : -sortingOrder
        case 'date':
          if (a.frequency === FrequencyType.monthly && b.frequency === FrequencyType.monthly) {
            return a.day > b.day ? sortingOrder : -sortingOrder
          }
          if (a.frequency === FrequencyType.weekly && b.frequency === FrequencyType.weekly) {
            return a.day > b.day ? sortingOrder : -sortingOrder
          }
          return a.frequency > b.frequency ? sortingOrder : -sortingOrder

        case 'name':
          return a.name.toLowerCase() > b.name.toLowerCase() ? sortingOrder : -sortingOrder
        case 'transfer':
          return a.type === 'income' || a.transfer ? sortingOrder : -sortingOrder
      }
    },
    [sortingMode, sortingOrder]
  )

  if (!onboardingDone) {
    return (
      <EmptyStatePage
        subtitle={
          <TextSpan color="logoPrimary" size="lg" weight={200}>
            {/* <Icon className="fas fa-trophy-star mr-2" color="logoPrimary" size="lg" /> */}
            {/* <Icon className="fak fa-regular-trophy-star-pen mr-2" color="logoPrimary" size="lg" /> */}
            <Icon className={`${FIXED_ICON} mr-2`} color="logoPrimary" size="lg" />
            Fixed Bills & Income
          </TextSpan>
        }
        content={
          <div className="">
            <TextSlim className="my-0" color="texttransparent" weight={200} size="md">
              Once you finish onboarding, this is where you will see your fixed bills,
              subscriptions, and income. <br /> <br />
              You will be able to add/edit/remove items as well as see an overview of your Monthly
              Cost of Living
            </TextSlim>
          </div>
        }
        cta={
          <>
            <TextSpan
              onClick={() => {
                push('/mystery')
              }}
              className="mt-2"
              color="texttransparent"
              size="xs">
              <u>Finish onboarding</u>
            </TextSpan>
          </>
        }
      />
    )
  }

  return (
    <>
      <TransitionFromRight show={cashFlowPageOpen} size="inapp">
        <CashFlowPage />
      </TransitionFromRight>
      <TransitionFromLeft show={calendarPageOpen} size="inapp">
        <CalendarPage />
      </TransitionFromLeft>

      <ContainerDiv>
        <StreamPageHeader setStreamToEdit={setStreamToEdit} />
        <StreamPageSubHeader
          streams={streams}
          sortingMode={sortingMode}
          setSortingMode={setSortingMode}
          sortingOrder={sortingOrder}
          setSortingOrder={setSortingOrder}
        />
        <ScrollingFull
          cutout={
            scrollingSectionCutoutSize +
            (spendingReductionNeeded ? SpendingReductionNotificationBannerSize : 0)
          }>
          <StreamPageContent
            streams={streams}
            setStreamToEdit={setStreamToEdit}
            sortFunction={sortFunction}
            getStreams={() => getStreams()}
            loadingStreams={fetchingStreams}
          />
        </ScrollingFull>
        {/* <CalendarCashFlowWidget /> */}
        <SpendingReductionNotificationBanner actionSentence=" Reduce bills to lower your Fixed spending." />
        <StreamPageFooter />
      </ContainerDiv>
    </>
  )
}

export const StreamPage = React.memo(_StreamPage)
