import { Icon, TextSpan } from 'Components/DesignSystem/Typography'
import {
  ManualAccount,
  ManualTransaction,
} from 'Providers/FinancialDataProvider/FinancialDataProvider'
import { getColorFromAccountType } from 'Providers/FinancialDataProvider/helpers'
import { cleanCurrency, numberToCurrency } from 'Utilities/currencyFormater'
import { humanReadifyStringDate } from 'Utilities/dateUtilities/dateUtilities'
import React from 'react'
import { ManualTransactionRowDiv } from './ManualTransactionRow.components'

interface Props {
  transaction: ManualTransaction
  account: ManualAccount
  deleteManualTransaction: (manualTransactionId: string) => void
}
export const ManualTransactionRow: React.FC<Props> = ({
  transaction,
  account,
  deleteManualTransaction,
}) => {
  const { amount, date, name } = transaction
  const { type } = account
  return (
    <ManualTransactionRowDiv>
      <TextSpan size="xxs" color="texttransparent" weight={200}>
        {name}
      </TextSpan>
      <TextSpan weight={600} size="xxs" color={getColorFromAccountType(type)}>
        {numberToCurrency(cleanCurrency(amount), true, true)}
      </TextSpan>
      <TextSpan size="xxs" color="textsupertransparent">
        {humanReadifyStringDate(date)}
      </TextSpan>
      <div
        className="p-1"
        onClick={async () => {
          const answer = window.confirm('Are you sure you want to delete this transaction?')
          if (answer) {
            // Since we are deleting an existing ManualTransaction that has been hydrated from the backend,
            // then the _id property will be defined.
            await deleteManualTransaction(transaction._id!)
          }
        }}>
        <Icon className="fas fa-trash" size="lg" color="mehred" />
      </div>
    </ManualTransactionRowDiv>
  )
}
