import { AccountMaskAndLogo } from 'Components/AccountMaskAndLogo'
import { StyledHr, StyledVr, TextSpan } from 'Components/DesignSystem/Typography'
import { NicknameLabelTag } from 'Components/NicknameLabelTag/NicknameLabelTag'
import { AccountMetadata } from 'Providers/UserCustomSettingsProvider/UserCustomSettingsProvider'
import { numberToCurrency } from 'Utilities/currencyFormater'
import { humanReadifyStringDate } from 'Utilities/dateUtilities/dateUtilities'
import { AccountBase, Transaction } from 'plaid'
import React from 'react'
import styled from 'styled-components/macro'
import { ColorType } from 'theme'

interface Props {
  t: Transaction
  aM: AccountMetadata | undefined
  a: AccountBase | undefined
  color: ColorType
}

const TransactionTitleGridRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  /* grid-template-columns: minmax(0, 1.5fr) 20px minmax(0, 2fr); */
`

interface DisplayProps {
  amount: number
  date: string
  authorizedDate: string | null
  logoUrl: string | undefined | null
  mask: string | React.ReactElement
  nickname: string
  name: string
  category: string
  color: ColorType
  skip: boolean
}
export const TransactionSummaryDisplay: React.FC<DisplayProps> = ({
  amount,
  date,
  authorizedDate,
  nickname,
  mask,
  name,
  logoUrl,
  category,
  color,
  skip,
}) => {
  return (
    <div className="d-flex flex-column w-100 align-items-start justify-content-start mb-1 mt-0">
      <div className="d-flex flex-row align-items-start my-2">
        {typeof mask === 'string' ? <TextSpan className="mr-2">{mask}</TextSpan> : mask}
        <NicknameLabelTag nickname={nickname} />
      </div>
      <TransactionTitleGridRow>
        <TextSpan
          color={color}
          size="md"
          weight={700}
          className="mb-0"
          style={{
            textDecoration: skip ? 'line-through' : '',
          }}>
          {numberToCurrency(amount, false, true)}
          {/* <CurrencyCounter value={amount} /> */}
        </TextSpan>
        <StyledVr color="textsupertransparent" className="mx-2" weight={1} size="sm" />

        {logoUrl ? (
          <img
            className="mr-1"
            alt="institution-logo"
            height={'20px'}
            style={{
              padding: 0,
              borderRadius: '4px',
            }}
            src={logoUrl}
          />
        ) : null}
        <div className="d-flex flex-row align-items-center">
          <TextSpan color="text" size="sm" weight={400} className="d-flex justify-content-center">
            {humanReadifyStringDate(date)}
          </TextSpan>
          <TextSpan
            color="textsupertransparent"
            size="xxxs"
            weight={400}
            className="d-flex justify-content-center ml-2">
            {humanReadifyStringDate(authorizedDate)}
          </TextSpan>
        </div>

        {/* <AccountMaskAndLogo accountId={a?.account_id} orientation="horizontal" /> */}
      </TransactionTitleGridRow>

      <div className="d-flex flex-row w-100 align-items-center justify-content-start ">
        <TextSpan
          color="texttransparent"
          size="xxs"
          weight={400}
          style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: 'calc(100vw - 6rem)',
          }}>
          <b className="mr-1">Name:</b>
          {name}
        </TextSpan>
      </div>

      <div className="d-flex flex-row w-100 align-items-center justify-content-start ">
        <TextSpan color="texttransparent" size="xxs" weight={400} className="my-0">
          <b className="mr-1">Category:</b> {category}
        </TextSpan>
      </div>
      <StyledHr className="my-2" />
    </div>
  )
}

export const TransactionSummary: React.FC<Props> = ({ t, a, aM, color }) => {
  console.log({ t })
  return (
    <TransactionSummaryDisplay
      name={t.name}
      amount={t.amount}
      date={t.date}
      logoUrl={t?.logo_url}
      nickname={aM?.name || ''}
      authorizedDate={t.authorized_date}
      mask={<AccountMaskAndLogo accountId={a?.account_id} orientation="horizontal" />}
      category={t.category?.join(', ') || ''}
      color={color}
      skip={false}
    />
  )
}
