import { FOOTER_ACTION_ROW_HEIGHT } from 'Components/FooterActionRow/FooterActionRow'
import { useLocalStorageSyncedState } from 'Hooks/useLocalStorageSyncedState'
import { variablePageHeaderSize } from 'Pages/VariablePage/VariablePageHeader'
import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import { useUIState } from 'Providers/UIStateProvider'
import { useUserCustomSettings } from 'Providers/UserCustomSettingsProvider/UserCustomSettingsProvider'
import { SPENDING_ICON } from 'Utilities/navigationTabUtilities'
import { IconButton } from 'design-system/buttons/IconButton/IconButton'
import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  ContainerCutout,
  ContainerDiv,
  ScrollingFull,
} from '../../Components/DesignSystem/Layout/Containers/Containers'
import {
  Icon,
  StyledHr,
  StyledVr,
  TextSlim,
  TextSpan,
} from '../../Components/DesignSystem/Typography'
import { EmptyStatePage } from '../../Components/EmptyStatePage/EmptyStatePage'
import { useFinancialData } from '../../Providers/FinancialDataProvider/FinancialDataProvider'
import { SpendingAccountsOverview } from './SpendingAccountsOverview'
import { SpendingPageFooter } from './SpendingPageFooter'

export const SpendingPage: React.FC = () => {
  const [
    activelySelectedAccountId,
    setActivelySelectedAccountId,
  ] = useLocalStorageSyncedState<string>('', 'spending-page-actively-selected-account')

  const {
    accountsData,
    transactionsData,
    flatManualAccounts,
    // flatAccounts,
    getTransactions,
    getAccounts,
    flatAccounts,
  } = useFinancialData()

  const { getSettings } = useUserCustomSettings()

  const {
    setShowTransactionOverlay,
    setAddCardPageOpen,
    setPresetTransactionFilterCategory,
    setPresetTransactionFilterReflectionNeeded,
    setPresetTransactionFilterType,
  } = useUIState()

  const emptyAccountData =
    !accountsData?.data?.accounts &&
    !transactionsData?.data?.transactions &&
    !flatManualAccounts.length

  // const accounts = accountsData?.data?.accounts || []

  const { push } = useHistory()

  const { onboardingDone } = useMysteryDay()

  // const { settings } = useUserCustomSettings()
  // const skippedAccountsFromCashCalcuation =
  //   settings?.accountMetadata?.filter((datum) => datum.hidden).map((datum) => datum.account_id) ||
  //   []

  // const cashAccounts = getCashAccounts(flatAccounts).filter(
  //   (account) => !skippedAccountsFromCashCalcuation.includes(account.account_id)
  // )

  // const creditCardAccounts = getCreditCardAccounts(flatAccounts).filter(
  //   (account) => !skippedAccountsFromCashCalcuation.includes(account.account_id)
  // )

  // const spendingAccounts = [...cashAccounts, ...creditCardAccounts]

  // const items = accountsData?.data?.items
  // const banksForSpendingAccounts = spendingAccounts
  //   .map((sa) => getBankItemFromAccount(accounts, items, sa.account_id))
  //   .filter((i) => i !== undefined) as ItemGetResponse[]

  // const itemIds = [
  //   ...new Set(banksForSpendingAccounts.map((item) => item?.item.item_id).filter((i) => !!i)),
  // ]

  // const { requestStatuses, issueRequest } = useRefreshAllAccounts({ itemIds })

  const requestsLoading =
    // Object.values(requestStatuses).some((v) => v.status === 'fetching') ||
    accountsData?.status === 'loading' || transactionsData?.status === 'loading'

  // const unreflectedTransactions = useGetUnreflectedTransactions()

  if (emptyAccountData || !onboardingDone) {
    return (
      <EmptyStatePage
        subtitle={
          <TextSpan color="logoPrimary" size="lg" weight={200}>
            <Icon className={`${SPENDING_ICON} mr-2`} color="logoPrimary" size="lg" />
            Spending Signals
          </TextSpan>
        }
        content={
          <>
            <TextSlim size="md" color="texttransparent" weight={300}>
              Once you finish onboarding and link your accounts, this page will break down your
              daily and monthly spending limits and give you clear signals as you approach over
              spending.
            </TextSlim>
          </>
        }
        cta={
          <>
            <TextSpan
              onClick={() => {
                push('/mystery')
              }}
              className="mt-2"
              color="texttransparent"
              size="xs">
              <u>Finish onboarding</u>
            </TextSpan>
          </>
        }
      />
    )
  }

  return (
    <ContainerDiv className="justify-content-between align-items-center w-100">
      <ContainerCutout size={variablePageHeaderSize}>
        <div className="d-flex align-items-center w-100 justify-content-between px-4">
          {/* <TextSpan weight={200} size="xxs" color="textsupertransparent">
          Click on the spending bars to see purchases this month.
        </TextSpan> */}

          <IconButton
            onClick={() => {
              setShowTransactionOverlay(true)

              setPresetTransactionFilterCategory('')
              setPresetTransactionFilterReflectionNeeded(false)
              setPresetTransactionFilterType('both')
            }}
            title={'see transactions'}
            color={'text'}
            orientation="vertical"
            iconClassName={'fas fa-list-ul'}
          />

          <StyledVr className="mx-2" />

          <IconButton
            disabled={requestsLoading}
            onClick={() => {
              // issueRequest()
              getAccounts({})
              getTransactions({})
              getSettings()
            }}
            title={'Refresh data'}
            color={'texttransparent'}
            orientation="vertical"
            iconClassName={`far fa-refresh ${requestsLoading ? 'fa-spin' : ''}`}
          />
        </div>

        <StyledHr weight={1} color="nearlyclear" className="my-1" />
      </ContainerCutout>

      <ScrollingFull cutout={82 + FOOTER_ACTION_ROW_HEIGHT + 18}>
        {flatAccounts.length ? (
          <SpendingAccountsOverview
            activelySelectedAccountId={activelySelectedAccountId}
            setActivelySelectedAccountId={setActivelySelectedAccountId}
          />
        ) : (
          <div className="d-flex flex-column px-4 py-3 align-items-center">
            <TextSpan color="text" size="md">
              No live accounts
            </TextSpan>
            <TextSpan color="texttransparent" size="xs">
              The Spending page allows you to track your spending throughout the month, reflect on
              transactions, and categorize things into Envelopes (groceries, takeout, etc) and Types
              (Fixed or Variable).
              <br />
            </TextSpan>
            <TextSpan color="texttransparent" size="xs" className="d-inline">
              This currently requires connecting{' '}
              <TextSpan
                weight={700}
                color="text"
                className="my-0"
                onClick={() => {
                  setAddCardPageOpen('')
                }}>
                <u>live bank accounts</u>
              </TextSpan>{' '}
              so that we can display your transactions and let you categorize them.
            </TextSpan>
          </div>
        )}
      </ScrollingFull>
      <StyledHr className="my-2" />

      <SpendingPageFooter />
      {/* <TransactionOverviewFooter activelySelectedAccountId={activelySelectedAccountId} /> */}
    </ContainerDiv>
  )
}
