import { AccountBase, AccountSubtype, AccountType } from 'plaid'
import { ColorType } from 'theme'
import { ManualAccount } from './FinancialDataProvider'

const _getAccounts = (
  accounts: AccountBase[],
  typeSubtypeTuples: [type: string, subtype: string][]
) => {
  return (
    accounts.filter((subaccount) =>
      typeSubtypeTuples.some(
        (tuple) => tuple[0] === subaccount.type && tuple[1] === subaccount.subtype
      )
    ) || []
  )
}

export const getTotalFromAcounts = (accounts: AccountBase[]): number => {
  const currentCash = accounts?.reduce(
    (acc, subaccount) => acc + (subaccount?.balances?.current || 0),
    0
  )
  return currentCash
}

const cashAccountTypeSubtypeTuples: [type: AccountType, subtype: AccountSubtype][] = [
  [AccountType.Depository, AccountSubtype.Checking],
]
const debtAccountTypeSubtypeTuples: [type: AccountType, subtype: AccountSubtype][] = [
  [AccountType.Loan, AccountSubtype.Loan],
  [AccountType.Loan, AccountSubtype.Student],
  [AccountType.Loan, AccountSubtype.Mortgage],
]

const creditCardAccountTypeSubtypeTuples: [type: AccountType, subtype: AccountSubtype][] = [
  [AccountType.Credit, AccountSubtype.CreditCard],
]

const savingsAccountTypeSubtypeTuples: [type: string, subtype: string][] = [
  [AccountType.Depository, AccountSubtype.Savings],
  [AccountType.Investment, AccountSubtype.Brokerage],
  [AccountType.Investment, AccountSubtype.Other],
  [AccountType.Investment, AccountSubtype._401k],
  [AccountType.Depository, AccountSubtype.CashManagement],
  [AccountType.Depository, AccountSubtype.Cd],
  [AccountType.Depository, AccountSubtype.MoneyMarket],
  [AccountType.Investment, AccountSubtype.Ira],
]

export type SimplifiedAccountTypes = 'Cash' | 'Savings' | 'Debt' | 'Credit Card' | 'Unknown'
export const SIMPLIFIED_ACCOUNT_TYPES: SimplifiedAccountTypes[] = [
  'Cash',
  'Savings',
  'Debt',
  'Credit Card',
]

/**
 * Use this function anywhere we are displaying both live and manual accounts and need to render the amount
 * @param a
 */
export const getAmountForMixedAccount = (a: ManualAccount | AccountBase) => {
  if ((a as AccountBase).mask) {
    const liveAccount: AccountBase = a as AccountBase
    return liveAccount.balances?.current !== null && liveAccount?.balances?.current !== undefined
      ? liveAccount?.balances?.current || 0
      : liveAccount.balances?.available || 0
  }
  if ((a as ManualAccount).amount) {
    const manualAccount: ManualAccount = a as ManualAccount
    return Number(manualAccount.amount)
  }
  return 0
}

export const getAccountTypeFromTypeSubtypeTuple = ([type, subtype]: [
  type: AccountType,
  subtype: AccountSubtype | null
]): SimplifiedAccountTypes => {
  if (cashAccountTypeSubtypeTuples.some((tuple) => tuple[0] === type && tuple[1] === subtype)) {
    return 'Cash'
  }
  if (savingsAccountTypeSubtypeTuples.some((tuple) => tuple[0] === type && tuple[1] === subtype)) {
    return 'Savings'
  }
  if (debtAccountTypeSubtypeTuples.some((tuple) => tuple[0] === type && tuple[1] === subtype)) {
    return 'Debt'
  }
  if (
    creditCardAccountTypeSubtypeTuples.some((tuple) => tuple[0] === type && tuple[1] === subtype)
  ) {
    return 'Credit Card'
  }

  return 'Unknown'
}

export const getIconFromAccountType = (type: SimplifiedAccountTypes) => {
  switch (type) {
    case 'Cash':
      return 'fas fa-envelope-open-dollar'
    // return 'fas fa-money-check-dollar'
    // return 'fas fa-money-bill'
    case 'Savings':
      return 'fas fa-piggy-bank'
    case 'Debt':
      return 'fas fa-anchor'
    case 'Credit Card':
      return 'fas fa-credit-card-front'
    case 'Unknown':
      return 'fas fa-square-question'
  }
}

export const getDisplayNameFromAccountType = (type: SimplifiedAccountTypes) => {
  switch (type) {
    case 'Cash':
      return 'Checking'
    case 'Savings':
      return 'Savings'
    case 'Debt':
      return 'Debt'
    case 'Credit Card':
      return 'Credit'
    case 'Unknown':
      return 'fas fa-square-question'
  }
}

export const getColorFromAccountType = (type: SimplifiedAccountTypes): ColorType => {
  switch (type) {
    case 'Cash':
      return 'cashgreen'
    case 'Savings':
      return 'softblue'
    case 'Debt':
      return 'mehred'
    case 'Credit Card':
      return 'orange'
    case 'Unknown':
      return 'textsupertransparent'
    default:
      return 'orange'
  }
}

export const getDebtAccounts = (accounts: AccountBase[]) =>
  _getAccounts(accounts, debtAccountTypeSubtypeTuples)

export const getCashAccounts = (accounts: AccountBase[]) =>
  _getAccounts(accounts, cashAccountTypeSubtypeTuples)

export const getCreditCardAccounts = (accounts: AccountBase[]) =>
  _getAccounts(accounts, creditCardAccountTypeSubtypeTuples)

export const getSavingsAccounts = (accounts: AccountBase[]) =>
  _getAccounts(accounts, savingsAccountTypeSubtypeTuples)
