import { AccountMaskAndLogo } from 'Components/AccountMaskAndLogo'
import { cleanTransactionName } from 'Pages/CreditCardPage/AccountBreakdown'
import { TransactionMetadata } from 'Providers/UserCustomSettingsProvider/UserCustomSettingsProvider'
import { AccountBase, Transaction } from 'plaid'
import React from 'react'
import { TextSlim, TextSlimTight } from '../../../Components/DesignSystem/Typography'
import { transactionCountedTowardsSpending } from '../../../Utilities/creditCardSpending/helpers'
import { TransactionCategoryBadge } from './TransactionCategoryBadge'
import { TransactionReflectionNeededDot } from './TransactionReflectionNeededBadge'
import { TransactionTypeBadge } from './TransactionTypeBadge'
import { AdvancedTransactionRowDiv } from './components'

interface AdvancedTransactionRowProps {
  transactionName: string
  amount: string
  date: string
  account?: AccountBase
  t: Transaction
  onNumberClick: (t: Transaction) => void
  onNumberClickLoading?: boolean
  onNameClick: (t: Transaction, m: TransactionMetadata | undefined) => void
  transactionMetadata: TransactionMetadata | undefined
  transactionMetadataViewMode: 'category' | 'notes' | null
}
export const AdvancedTransactionRow: React.FC<AdvancedTransactionRowProps> = ({
  amount,
  transactionName,
  account,
  date,
  t,
  onNumberClickLoading,
  onNumberClick,
  onNameClick,
  transactionMetadata,
  transactionMetadataViewMode,
}) => {
  const transactionFiltered = !transactionCountedTowardsSpending(t)
  const pending = t.pending
  const { ignoreFromCalculations, calculationType, envelopeCategory, fixedStreamName } =
    transactionMetadata || {}

  const rowColor = transactionFiltered
    ? 'texttransparent'
    : pending
    ? 'textsupertransparent'
    : 'texttransparent'

  return (
    <AdvancedTransactionRowDiv>
      <div className="d-flex flex-column align-items-start p-0">
        <AccountMaskAndLogo
          accountId={account?.account_id}
          logoSize={24}
          maskSize="xxxs"
          maskStyle={{ padding: '.125rem' }}
        />
      </div>
      <div
        className="d-flex flex-column align-items-start"
        onClick={() => {
          onNameClick(t, transactionMetadata)
        }}>
        <TextSlimTight
          color={rowColor}
          size="xxs"
          weight={500}
          style={{
            display: 'flex',
            alignItems: 'center',
            textAlign: 'left',
            justifyContent: 'flex-start',
            fontStyle: pending ? 'italic' : 'inherit',
            whiteSpace: 'nowrap',
            textDecoration: 'underline',
          }}>
          <TransactionReflectionNeededDot m={transactionMetadata} />

          {t.logo_url ? (
            <img
              className="mr-1"
              alt="institution-logo"
              height={'20px'}
              style={{
                padding: 0,
                borderRadius: '4px',
              }}
              src={t.logo_url}
            />
          ) : null}
          {cleanTransactionName(transactionName)}
          {pending ? '*' : null}
        </TextSlimTight>

        <div className="d-flex w-100 align-items-center">
          <TransactionTypeBadge calculationType={calculationType} />
          <TransactionCategoryBadge
            className="ml-1"
            category={
              calculationType === 'fixed'
                ? fixedStreamName
                : calculationType === 'variable'
                ? envelopeCategory
                : undefined
            }
          />
        </div>

        {}
        <TextSlim
          className="my-1"
          tightness="tight"
          size="xxs"
          style={{
            textAlign: 'left',
            lineHeight: '.75rem',
          }}
          color={'textsupertransparent'}>
          {transactionMetadata?.notes}
        </TextSlim>
      </div>

      <TextSlimTight
        onClick={() => {
          onNumberClick(t)
        }}
        style={{
          textAlign: 'left',
          position: 'relative',
          alignSelf: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100%',
          textDecoration: ignoreFromCalculations ? 'line-through' : 'inherit',
        }}
        weight={700}
        size="xxs"
        color={onNumberClickLoading ? 'logoPrimary' : rowColor}>
        {amount}
      </TextSlimTight>
      <div className="d-flex flex-column align-items-start">
        <TextSlimTight className="my-0" color={'textsupertransparent'} size="xxs">
          {date.split(' ')?.[0]} {date.split(' ')?.[1]}
        </TextSlimTight>
        <TextSlimTight style={{ marginTop: '-.25rem' }} color={rowColor} size="xxs"></TextSlimTight>
      </div>
    </AdvancedTransactionRowDiv>
  )
}
