import { animated } from 'react-spring'
import styled from 'styled-components'

export const Card = styled(animated.div)`
  background-color: ${(props) => props.theme.colors.backgrounddark};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  min-width: calc(100vw - 2rem);
  border-radius: 20px;
  box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4), 0 10px 10px -10px rgba(50, 50, 73, 0.3);
`

export const CardBody = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`

export const Footer = styled.div`
  width: 100%;
`
