import { datadogRum } from '@datadog/browser-rum'
import { TextSpan } from 'Components/DesignSystem/Typography'
import { fireConfetti } from 'Pages/WishlistPage/Confetti'
import { useSubscriptionStatus } from 'Providers/SubscriptionStatusProvider/SubscriptionStatusProvider'
import { useUIState } from 'Providers/UIStateProvider'
import { WideButton } from 'design-system/buttons/WideButton/WideButton'
import { SubtitleText } from 'design-system/typography/Text/Text'
import React, { useEffect } from 'react'
import { colorPalletes } from 'theme'
import { ConfirmationEntry, SubscriptionEntry } from './SubscriptionEntry'
import { WhatAmIPayingFor } from './WhatAmIPayingFor'

interface Props {
  setStripeContentOpen: Function
}
export const SplurvOptions: React.FC<Props> = ({ setStripeContentOpen }) => {
  const { activeTheme, setActiveTheme } = useUIState()
  const { trialDaysLeft, status, subscription, deleteSubscription } = useSubscriptionStatus()

  useEffect(() => {
    if (status === 'inactive') {
      fireConfetti({
        colors: [colorPalletes[activeTheme].logoSecondary, colorPalletes[activeTheme].logoPrimary],
        duration: 0.1,
      })
    }
  }, [status, activeTheme])

  if (status === 'active') {
    return (
      <div className="flex-column align-items-start">
        <SubtitleText>Subscription Details</SubtitleText>

        <ConfirmationEntry
          manageMode
          nextRenewalDate={new Date((subscription?.periodEnd || 0) * 1000)}
          price={subscription?.price || 0}
          frequency={subscription?.interval || ''}
          priceColor={subscription?.interval === 'year' ? 'logoPrimary' : 'logoSecondary'}
        />
        <WideButton
          onClick={async () => {
            await deleteSubscription({ subscriptionId: subscription?.subscriptionId || '' })
            window.location.reload()
          }}
          appearance="secondary"
          bgColor="mehred"
          text="Cancel Subscription"
          active
          textSize="small"
          width="full"
        />
      </div>
    )
  }

  return (
    <div className="flex-column align-items-start">
      <SubtitleText>Subscription Options</SubtitleText>
      <div className="d-flex flex-row align-items-center justify-content-start w-100">
        <TextSpan size="sm" color={'text'}>
          {trialDaysLeft < 0 ? 0 : trialDaysLeft} days
        </TextSpan>
        <TextSpan size="xxs" color="texttransparent" className="ml-1">
          left in trial
        </TextSpan>
      </div>
      <WhatAmIPayingFor />

      <SubscriptionEntry
        onClick={() => {
          datadogRum.addAction('subscribe-button-clicked', {
            'subscribe-button': {
              type: 'monthly',
            },
          })
          setActiveTheme('newLight')
          setStripeContentOpen('standard-monthly')
        }}
        description="Get the entire app and be able to re-assess its value to you every month. We will send you a notification before you subscription renews to ensure you're staying intentional about your spending, even on us."
        priceColor="logoSecondary"
        frequency="month"
        price={6}
      />
      <SubscriptionEntry
        onClick={() => {
          datadogRum.addAction('subscribe-button-clicked', {
            'subscribe-button': {
              type: 'annual',
            },
          })
          setActiveTheme('newLight')
          setStripeContentOpen('standard-annual')
        }}
        description="Get the entire app for the whole year. Pay now and enjoy it for 12 months and save extra."
        priceColor="logoPrimary"
        frequency="year"
        price={48}
      />
      <SubscriptionEntry
        onClick={() => {
          datadogRum.addAction('subscribe-button-clicked', {
            'subscribe-button': {
              type: 'one-time-help-us-out',
            },
          })
          alert('this is coming soon, thanks for being excited to help us out!')
          // setActiveTheme('newLight')
          // setStripeContentOpen('standard-onetime-help')
        }}
        description="Help us get off the ground with a one-time payment. Money will go towards operating costs and project runway"
        priceColor="megapurple"
        frequency="onetime"
        ctaText="help us out"
        price={50}
      />
    </div>
  )
}
