import { AccountBase, InstitutionsGetByIdResponse } from 'plaid'
import { Icon, StyledVr, TextSpan } from '../../../Components/DesignSystem/Typography'
import { TransitionFromAbove } from '../../../Components/Transitions/TransitionFromAbove'
import {
  SimplifiedAccountTypes,
  getColorFromAccountType,
  getIconFromAccountType,
} from '../../../Providers/FinancialDataProvider/helpers'

import { AccountMaskAndLogo } from 'Components/AccountMaskAndLogo'
import { NicknameLabelTag } from 'Components/NicknameLabelTag/NicknameLabelTag'
import { SpendingLimitTag } from 'Components/SpendingLimitTag/SpendingLimitTag'
import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import { useUIState } from 'Providers/UIStateProvider'
import { useUserCustomSettings } from 'Providers/UserCustomSettingsProvider/UserCustomSettingsProvider'
import { numberToCurrency } from 'Utilities/currencyFormater'
import React from 'react'
import { colorPalletes } from 'theme'
import { StreamAmountPart } from '../../StreamPage/StreamEntry/components'
import { AccountOptionsRow } from '../AccountOptionsRow/AccountOptionsRow'
import { CardTetherSetupPage } from '../CardTetherSetupPage/CardTetherSetupPage'
import { AccountRowDiv } from './AccountRowComponents'

import { useGetSpendingAccount } from 'Pages/SpendingPage/hooks/useGetSpendingAccount'
import {
  MysteryModeButtonTitlesFromMysteryDayType,
  colorFromMysteryDayType,
  iconFromMysteryDayType,
} from 'Utilities/utils'
import { useHistory } from 'react-router'

export interface AccountRowProps {
  type: SimplifiedAccountTypes
  institution: InstitutionsGetByIdResponse | undefined
  nickname: string | undefined
  spendingLimitPerMonth: number | undefined
  amount: number
  mask?: string
  hidden?: boolean
  accountId: string
  bankName?: string
  itemId?: string
  account?: AccountBase
  accountName?: string
  openedRow: string | false
  setOpenedRow: (row: string | false) => void
}

export const AccountRow: React.FC<AccountRowProps> = ({
  type,
  amount,
  institution,
  mask,
  bankName,
  accountId,
  nickname,
  hidden,
  spendingLimitPerMonth,
  itemId,
  account,
  accountName,
  openedRow,
  setOpenedRow,
}) => {
  const color = getColorFromAccountType(type)
  const icon = getIconFromAccountType(type)
  const { tetheredBalance } = useGetSpendingAccount(accountId)
  const { push } = useHistory()

  const { mysteryMode } = useMysteryDay()

  const { linkedAccount, linkedAccounts } = useMysteryDay()

  const accountIsTiedToGoal = React.useMemo(() => {
    if (linkedAccount && linkedAccount.accountId === accountId) {
      return true
    }
    if (linkedAccounts && linkedAccounts.map((la) => la?.accountId).includes(accountId)) {
      return true
    }
    return false
  }, [accountId, linkedAccount, linkedAccounts])

  const { activeTheme } = useUIState()
  const { settings } = useUserCustomSettings()

  const connectedCreditCards =
    settings?.accountMetadata.find((datum) => datum.account_id === accountId)
      ?.tetheredCreditCards || []

  const connectedDebitCard = settings?.accountMetadata.find((datum) =>
    datum.tetheredCreditCards.includes(accountId)
  )

  const [selectedAccount, setSelectedAccount] = React.useState<AccountBase | null>(null)

  const closePage = React.useCallback(() => setSelectedAccount(null), [])

  const configStillNeeded =
    !nickname || !spendingLimitPerMonth || (type === 'Credit Card' && !connectedDebitCard)

  /**
   * linkedCreditAndDebitAccounts has the following shape
   *
   * {
   *  [debitAccountId1] : [creditAccount1, creditAccount2],
   *  [debitAccountId2] : [creditAccount3]
   * }
   */

  return (
    <>
      <TransitionFromAbove show={!!selectedAccount}>
        <CardTetherSetupPage account={selectedAccount} closePage={closePage} />
      </TransitionFromAbove>
      <div className="d-flex w-100" style={{ position: 'relative', opacity: hidden ? 0.3 : 1 }}>
        <AccountOptionsRow
          type={type}
          institution={institution}
          nickname={nickname}
          account={account}
          hidden={hidden}
          spendingLimitPerMonth={spendingLimitPerMonth}
          itemId={itemId}
          amount={amount}
          accountId={accountId}
          openedRow={openedRow}
          setOpenedRow={setOpenedRow}
          setSelectedAccount={setSelectedAccount}
        />
        <AccountRowDiv data-testid={`stream-${'subscriptions'}-entry-row`}>
          <div className="d-flex align-items-start">
            <div className="d-flex flex-column align-items-start">
              <AccountMaskAndLogo accountId={accountId} logoSize={36} />
            </div>
            <div className="d-flex align-items-start flex-column ml-2">
              <div className="d-flex align-items-center flex-row">
                <TextSpan size="xxs" className="my-0 text-nowrap" weight={500} color="text">
                  {institution?.institution.name}
                </TextSpan>
                <StyledVr weight={1} className="mx-1" color="texttransparent" size="xs" />

                <div className="d-flex flex-row align-items-center">
                  <Icon className={icon} color={color} size="xxs" />
                </div>
                {/* <TextSpan
                  color="texttransparent"
                  size="xxxs"
                  weight={200}
                  className="ml-1 my-0">
                  {account?.official_name || account?.name}
                </TextSpan> */}
              </div>

              {accountIsTiedToGoal ? (
                <div className="d-flex flex-row">
                  <TextSpan
                    color={colorFromMysteryDayType(mysteryMode)}
                    weight={200}
                    className="my-0 ml-0 mr-1"
                    size="xxs"
                    style={{}}>
                    Goal ={' '}
                    <TextSpan weight={700} className="my-0 ml-1">
                      {MysteryModeButtonTitlesFromMysteryDayType(mysteryMode).title}
                    </TextSpan>
                  </TextSpan>
                </div>
              ) : (
                <div
                  className="d-flex align-items-center flex-row my-1"
                  onClick={() => {
                    if (openedRow === accountId) {
                      setOpenedRow(false)
                    } else {
                      setOpenedRow(accountId)
                    }
                  }}>
                  <NicknameLabelTag nickname={nickname} />
                  <StyledVr className="mx-1 my-0" size="xxs" color="texttransparent" weight={1} />
                  <SpendingLimitTag limit={spendingLimitPerMonth} />
                </div>
              )}

              <StreamAmountPart isPositive={color === 'cashgreen' ? true : false}>
                <TextSpan size="xxs" className="my-0 mr-1" weight={200} color="texttransparent">
                  {type === 'Credit Card' ? 'Current' : 'Available'} Balance:
                </TextSpan>
                <TextSpan size="xxs" color={'text'} className="my-0">
                  {numberToCurrency(amount, true, true)}
                </TextSpan>
                {connectedCreditCards.length > 0 ? (
                  <>
                    <StyledVr />
                    <TextSpan size="xxs" className="my-0 mr-1" weight={200} color="orange">
                      Tethered:
                    </TextSpan>
                    <TextSpan size="xxs" color={'orange'} className="my-0">
                      {numberToCurrency(tetheredBalance, true, true)}
                    </TextSpan>
                  </>
                ) : null}
              </StreamAmountPart>
              {connectedCreditCards.length > 0 && type === 'Cash' ? (
                <div className="d-flex align-items-center flex-row">
                  <Icon
                    size="xs"
                    color="cashgreen"
                    style={
                      {
                        '--fa-primary-color': colorPalletes[activeTheme].orange,
                        '--fa-secondary-color': colorPalletes[activeTheme].cashgreen,
                        '--fa-secondary-opacity': 1,
                      } as React.CSSProperties
                    }
                    className="fa-duotone fa-link-horizontal mr-1"
                  />

                  <TextSpan color="texttransparent" size="xxs">
                    {' '}
                    {connectedCreditCards.length} credit cards tethered
                  </TextSpan>
                </div>
              ) : connectedDebitCard && type === 'Credit Card' ? (
                <div className="d-flex align-items-center flex-row">
                  <Icon
                    size="xs"
                    color="cashgreen"
                    style={
                      {
                        '--fa-primary-color': colorPalletes[activeTheme].orange,
                        '--fa-secondary-color': colorPalletes[activeTheme].cashgreen,
                        '--fa-secondary-opacity': 1,
                      } as React.CSSProperties
                    }
                    className="fa-duotone fa-link-horizontal mr-1"
                  />

                  <TextSpan color="texttransparent" size="xxs" className="mr-1" weight={200}>
                    {' '}
                    tethered to{' '}
                  </TextSpan>
                  <AccountMaskAndLogo
                    accountId={connectedDebitCard.account_id}
                    logoSize={16}
                    orientation="horizontal"
                  />
                </div>
              ) : type === 'Credit Card' && !accountIsTiedToGoal ? (
                <div className="d-flex align-items-center flex-row">
                  <Icon
                    size="xs"
                    color="texttransparent"
                    className="fa-duotone fa-link-horizontal mr-1 fa-beat-fade"
                    style={
                      {
                        transform: openedRow === accountId ? 'rotate(90deg)' : 'rotate(0deg)',
                        '--fa-beat-fade-opacity': 0.67,
                        '--fa-beat-scale': 1.27,
                      } as React.CSSProperties
                    }
                  />

                  <TextSpan color="texttransparent" size="xxs" className="mr-1">
                    {' '}
                    missing tethered account
                  </TextSpan>
                </div>
              ) : null}
            </div>
          </div>

          <Icon
            onClick={() => {
              if (accountIsTiedToGoal) push('/mystery')
              if (openedRow === accountId) {
                setOpenedRow(false)
              } else {
                setOpenedRow(accountId)
              }
            }}
            color={accountIsTiedToGoal ? colorFromMysteryDayType(mysteryMode) : 'texttransparent'}
            size="xl"
            style={
              {
                transform: openedRow === accountId ? 'rotate(90deg)' : 'rotate(0deg)',
                '--fa-beat-fade-opacity': 0.67,
                '--fa-beat-scale': 1.27,
              } as React.CSSProperties
            }
            className={
              accountIsTiedToGoal
                ? iconFromMysteryDayType(mysteryMode)
                : openedRow === accountId
                ? 'fas fa-times'
                : `fas fa-gear ${configStillNeeded ? '' : ''}`
            }
          />
        </AccountRowDiv>
      </div>
    </>
  )
}
