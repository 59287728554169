import { useAuth0 } from '@auth0/auth0-react'
import { datadogRum } from '@datadog/browser-rum'
import { Content, Main, MainIntro } from 'Components/DesignSystem/Layout/Containers/Containers'
import { Footer } from 'Components/DesignSystem/Layout/Footer/Footer'
import { PageHeader } from 'Components/DesignSystem/Layout/Header/Header'
import { ErrorMessage } from 'Components/ErrorMessage/ErrorMessage'
import { PopupMessage } from 'Components/PopupMessage/PopupMessage'
import { AddManualAccountPage } from 'Pages/AddManualAccountPage/AddManualAccountPage'
import { AddNewCardPage } from 'Pages/AddNewCardPage/AddNewCardPage'
import { AddNewCardPageOAuth } from 'Pages/AddNewCardPageOAuth/AddNewCardPageOAuth'
import { DashboardPage } from 'Pages/DashboardPage/DashboardPage'
import { IntroPage } from 'Pages/IntroPage/IntroPage'
import { LoginLoadingComponent } from 'Pages/IntroPage/LoginLoadingComponent'
import { MysteryPage } from 'Pages/MysteryPage/MysteryPage'
import { SettingsPage } from 'Pages/SettingsPage/SettingsPage'
import { SpendingPage } from 'Pages/SpendingPage/SpendingPage'
import { AddEditPage } from 'Pages/StreamPage/AddEditStream/AddEditStream'
import { StreamPage } from 'Pages/StreamPage/StreamPage'
import { WishlistPage } from 'Pages/WishlistPage/WishlistPage'
import { useFinancialData } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import { UpdateAppButton } from 'Providers/PWAProvider/UpdateAppButton'
import { useUIState } from 'Providers/UIStateProvider'
import { useUserConfig } from 'Providers/UserConfigProvider/UserConfigProvider'
import { LinkType } from 'Utilities/navigationTabUtilities'
import { History } from 'history'
import React, { useEffect } from 'react'
import { Redirect, useHistory, withRouter } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { colorPalletes, myTheme } from 'theme'
import {
  TransitionFromAbove,
  TransitionFromLeft,
  TransitionFromRight,
} from '../Components/Transitions/TransitionFromAbove'
import { Story } from './Story/Story'
import { AppDarkOverlay } from './components'

import OneSignal from 'react-onesignal'

import { Icon } from 'Components/DesignSystem/Typography'
import { PopupComponent } from 'Components/PopupComponent/PopupComponent'
import { SPLURV_LOCAL_STORAGE_PREFIX } from 'Hooks/useLocalStorageSync'
import { SystemOverviewPage } from 'Pages/IntroPage/SystemOverviewPage'
import { ToolOverviewPage } from 'Pages/IntroPage/ToolOverviewPage'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { TOP_CONTAINER_PADDING_FOR_NOTCH } from 'Components/DesignSystem/Layout/Containers/helpers'
import { PopupModal } from 'Components/PopupModal/PopupModal'
import { TrialPayWallComponent } from 'Components/TrialExpiredPage/TrialPayWallComponent'
import { CreditCardPage } from 'Pages/CreditCardPage/CreditCardPage'
import { AdvancedTransactionView } from 'Pages/SpendingPage/AdvancedTransactionView/AdvancedTransactionView'
import { StripeReturnPage } from 'Pages/StripeReturnPage/StripeReturnPage'
import { SubscriptionPage } from 'Pages/SubscriptionPage/SubscriptionPage'
import { VariablePage } from 'Pages/VariablePage/VariablePage'
import qs from 'qs'

function App() {
  const { location } = useHistory<History>()
  const { user, isAuthenticated, isLoading } = useAuth0()

  const { onboardingDone, unlockPage } = useMysteryDay()
  const {
    showStoryPage,
    showSystemPage,
    setHasSeenAppBefore,
    showToolPage,
    setShowToolPage,
    setShowSystemPage,
  } = useUserConfig()
  const { flatAccounts } = useFinancialData()

  useEffect(() => {
    const initOneSignal = async () => {
      try {
        /*
        All  that init does from the NPM package under the hood is
        load this script src 'https://cdn.onesignal.com/sdks/OneSignalSDK.js' and append it to the doc

        The script itself, 
        
        */
        await OneSignal.init({
          appId: String(process.env.REACT_APP_ONE_SIGNAL_APP_ID),
        })

        await OneSignal.setExternalUserId(user.sub)
        await OneSignal.registerForPushNotifications()
        console.debug('🐤 We have PushNotifications supposedly setup!')
      } catch (err) {
        console.error('Error setting up OneSignal', err)
      }
    }

    if (user) {
      initOneSignal()
    }
  }, [user])

  const {
    activeTheme,
    messagePopupContent,
    popup,
    settingsPageOpen,
    addManualAccountPageOpen,
    addCardPageOpen,
    accountsPageOpen,
    showTransactionsOverlay,
    setShowTransactionOverlay,
    setPresetTransactionFilterCategory,
    setPresetTransactionFilterReflectionNeeded,
    setPresetTransactionFilterType,
    presetTransactionFilterCategory,
    presetTransactionFilterType,
    presetTransactionFilterReflectionNeeded,
  } = useUIState()

  const resetPresetFilters = React.useCallback(() => {
    setPresetTransactionFilterReflectionNeeded(false)
    setPresetTransactionFilterType('both')
    setPresetTransactionFilterCategory('')
  }, [
    setPresetTransactionFilterCategory,
    setPresetTransactionFilterReflectionNeeded,
    setPresetTransactionFilterType,
  ])

  useEffect(() => {
    if (flatAccounts.length) {
      unlockPage(['/creditcards', '/spending'])
    }
    // eslint-disable-next-line
  }, [flatAccounts.length])

  useEffect(() => {
    if (user) {
      setHasSeenAppBefore(true)
    }
  }, [user, setHasSeenAppBefore])

  useEffect(() => {
    if (!setHasSeenAppBefore) {
      const isSupported = () =>
        'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window

      if (!isSupported()) {
        toast('Live Notifications Supported!', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          icon: () => <Icon className="fas fa-bell" color="second" />,
          type: 'info',
          progress: undefined,
          theme: 'dark',
        })
      } else {
        toast('Live Notifications Not Yet Supported...', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          icon: () => <Icon className="fas fa-bell" color="yellow" />,
          type: 'warning',
          progress: undefined,
          theme: 'dark',
        })
      }
    }
  }, [setHasSeenAppBefore])

  useEffect(() => {
    const standaloneDisplay = window.matchMedia('(display-mode: standalone)').matches
    // if we are in app downloaded mode....
    if (standaloneDisplay) {
      //  if the user already logged in...
      if (user) {
        datadogRum.setUser({
          name: user.name,
          email: user.email,
          userid: user.sub,
        })

        const inviteLocalStorage = localStorage.getItem(
          `${SPLURV_LOCAL_STORAGE_PREFIX}__invitedUser`
        )
        if (inviteLocalStorage) {
          datadogRum.addAction('invitedUser-insideMainApp', {
            invitedUser: inviteLocalStorage,
          })

          datadogRum.addRumGlobalContext('invitedUser-queryStorage', {
            invitedUser: inviteLocalStorage,
            location: 'inside-main-app-logged-in',
          })
        }
      } else {
        // if the user did not login...
        const inviteQueryParam = qs.parse(window.location.search, { ignoreQueryPrefix: true })
          .invitedUser
        if (inviteQueryParam) {
          localStorage.setItem(
            `${SPLURV_LOCAL_STORAGE_PREFIX}__invitedUser`,
            inviteQueryParam as string
          )

          datadogRum.addAction('invitedUser-beforeAppLogin', {
            invitedUser: inviteQueryParam,
          })

          datadogRum.addRumGlobalContext('invitedUser-queryStorage', {
            invitedUser: inviteQueryParam,
            location: 'inside-main-app-logged-out',
          })
        }
      }
    }
  }, [user])

  if (isLoading) {
    return (
      <ThemeProvider theme={{ ...myTheme, colors: colorPalletes[activeTheme] }}>
        <MainIntro>
          <Content activeLink={location.pathname as LinkType}>
            <LoginLoadingComponent loading={true} />
          </Content>
        </MainIntro>
      </ThemeProvider>
    )
  }

  if (!user || !isAuthenticated)
    return (
      <ThemeProvider theme={{ ...myTheme, colors: colorPalletes[activeTheme] }}>
        <MainIntro>
          <PopupMessage />

          <PopupComponent customColorPallete={activeTheme} />
          <ErrorMessage />
          <UpdateAppButton />
          <IntroPage />
        </MainIntro>
      </ThemeProvider>
    )

  return (
    <Switch>
      <ThemeProvider theme={{ ...myTheme, colors: colorPalletes[activeTheme] }}>
        <AppDarkOverlay active={!!messagePopupContent || !!popup} />

        {/* {process.env.NODE_ENV === 'production' ? null : <LocalHostNotchBanner />} */}
        <ToastContainer
          position="bottom-right"
          style={{
            marginTop: `${TOP_CONTAINER_PADDING_FOR_NOTCH}px`,
          }}
          autoClose={5000}
          hideProgressBar
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <Main>
          {/* Advanced Transaction Page */}
          <TransitionFromAbove show={showTransactionsOverlay}>
            <AdvancedTransactionView
              onClose={() => {
                setShowTransactionOverlay(false)
              }}
              resetPresetFilters={resetPresetFilters}
              presetFilters={{
                category: presetTransactionFilterCategory,
                type: presetTransactionFilterType,
                reflectionNeeded: presetTransactionFilterReflectionNeeded,
              }}
            />
          </TransitionFromAbove>

          {/* System Overview Page */}
          {/* TODO: Can likely delete this */}
          <TransitionFromAbove
            show={showSystemPage}
            zIndex={251}
            style={{
              background: 'linear-gradient(180deg,#c8fadc ,#b6d8e3 )',
            }}>
            <SystemOverviewPage
              closePage={() => {
                setShowSystemPage(false)
              }}
            />
          </TransitionFromAbove>

          {/* Tool Overview Page */}
          {/* TODO: Can likely delete this */}
          <TransitionFromAbove show={showToolPage} zIndex={251}>
            <ToolOverviewPage
              closePage={() => {
                setShowToolPage(false)
              }}
            />
          </TransitionFromAbove>

          {/* Settings Page */}
          <TransitionFromRight show={settingsPageOpen} zIndex={250}>
            <SettingsPage />
          </TransitionFromRight>

          <AddEditPage />

          {/* Show Accounts Page */}
          <TransitionFromLeft show={accountsPageOpen} size="fullscreen">
            <CreditCardPage />
          </TransitionFromLeft>

          {/* Create New Manual Account Page */}
          <TransitionFromAbove show={addManualAccountPageOpen !== false} style={{ zIndex: 6000 }}>
            <AddManualAccountPage />
          </TransitionFromAbove>

          <SubscriptionPage />
          <StripeReturnPage />

          {/* Connect New Plaid Account Page */}
          <TransitionFromAbove show={addCardPageOpen !== false} style={{ zIndex: 6000 }}>
            <AddNewCardPage />
          </TransitionFromAbove>

          <TransitionFromAbove show={showStoryPage} iconColor="second">
            <Story />
          </TransitionFromAbove>

          <ErrorMessage />
          <PopupMessage />
          <PopupComponent customColorPallete={activeTheme} />
          <PopupModal customColorPallete={activeTheme} />

          {location.pathname !== '/demo' && location.pathname !== '/' && isAuthenticated && (
            <PageHeader />
          )}

          <Content activeLink={location.pathname as LinkType}>
            {/* Redirect from first loading app */}
            <Route exact path="/">
              {isAuthenticated || user ? <Redirect to="/mystery" /> : <Redirect to="/demo" />}
            </Route>

            {/* Fixed Page */}
            <Route path="/fixed">
              <TrialPayWallComponent>
                <StreamPage />
              </TrialPayWallComponent>
            </Route>

            {/* Spending Page */}
            <Route path="/spending">
              <TrialPayWallComponent>
                <SpendingPage />
              </TrialPayWallComponent>
            </Route>

            {/* Add Card Page From Oauth Redirect */}
            <Route exact path="/creditcards/newauthpage">
              <AddNewCardPageOAuth />
            </Route>

            {/* Wishlist Page */}
            <Route path="/wishlist">
              <TrialPayWallComponent>
                <WishlistPage />
              </TrialPayWallComponent>
            </Route>

            {/* Variable Page */}
            <Route path="/variable">
              <TrialPayWallComponent>
                <VariablePage />{' '}
              </TrialPayWallComponent>
            </Route>

            {/* Onboarding / Dashboard Page */}
            <Route path="/mystery">{onboardingDone ? <DashboardPage /> : <MysteryPage />}</Route>
          </Content>

          <Footer />
        </Main>
      </ThemeProvider>
    </Switch>
  )
}

export default withRouter(App)
