import { StyledVr, TextSpan } from 'Components/DesignSystem/Typography'
import { IconButton } from 'design-system/buttons/IconButton/IconButton'
import React from 'react'
import { formatBytes } from './useGetLocalStorageStats'

interface Props {
  totalSize: number
  maxSize: number
  calculatingSizes: boolean
  onClick: () => void
}
export const StorageUtilizationBadge: React.FC<Props> = ({
  calculatingSizes,
  maxSize,
  totalSize,
  onClick,
}) => {
  if (totalSize === 0 && maxSize === 0 && !calculatingSizes) {
    return (
      <TextSpan size="xxs" color="textsupertransparent" className="ml-1">
        Click to compute
      </TextSpan>
    )
  }
  return (
    <div className="d-flex align-items-center">
      <TextSpan size="xxs" color="softblue" weight={700}>
        {formatBytes(totalSize)}
      </TextSpan>
      <TextSpan size="xxs" color="softblue" className="mx-1">
        /
      </TextSpan>
      <TextSpan size="xxs" color="textsupertransparent">
        {formatBytes(maxSize)}
      </TextSpan>
      <StyledVr size="xxs" className="mx-1" color="textsupertransparent" weight={1} />
      <TextSpan size="xxs" color="softblue" className="ml-1" weight={700}>
        {Math.round((totalSize / maxSize) * 100)}% utilized
      </TextSpan>
      <StyledVr size="xxs" className="mx-1" color="textsupertransparent" weight={1} />
      <IconButton
        orientation="horizontal"
        color="softblue"
        title="Recompute"
        size="small"
        iconClassName={`fas fa-sync-alt ${calculatingSizes ? 'fa-spin' : ''}`}
        onClick={onClick}
      />
    </div>
  )
}
