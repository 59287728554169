import { dataParameterType, useLocalStorageSyncedState } from 'Hooks/useLocalStorageSyncedState'
import React, { useEffect } from 'react'

export interface LinkedCreditAndDebitCards {
  [cashAccountAccountIdOrName: string]: string[]
}

interface ContextState {
  hasSeenAppBefore: boolean
  setHasSeenAppBefore: (hasSeenAppBefore: boolean) => void

  hasSeenRainyDayOverview: boolean
  setHasSeenRainyDayOverview: (hasSeen: boolean) => void

  showToolPage: boolean
  setShowToolPage: (val: boolean) => void

  showLoginPage: boolean
  setShowLoginPage: (val: boolean) => void

  hasSeenHowToUse: boolean
  setHasSeenHowToUse: (val: boolean) => void

  demoMode: boolean
  setDemoMode: (bool: boolean) => void

  centsMode: boolean
  setCentsMode: (bool: boolean) => void

  showStoryPage: boolean
  setShowStoryPage: (val: boolean) => void
  appOverviewPageSeen: boolean
  setAppOverviewPageSeen: (val: boolean) => void
  systemPageSeen: boolean
  setSystemPageSeen: (val: boolean) => void
  showSystemPage: boolean
  setShowSystemPage: (val: boolean) => void

  skipReflectionIntro: boolean
  setSkipReflectionIntro: (v: dataParameterType<boolean>) => void
}

/**
 * for skipped accounts, we use account_id as documented here by Plaid
 * 
 * account_id

JSON
string
Plaid’s unique identifier for the account. This value will not change unless Plaid can't reconcile the account with the data returned by the financial institution. This may occur, for example, when the name of the account changes. If this happens a new account_id will be assigned to the account.
The account_id can also change if the access_token is deleted and the same credentials that were used to generate that access_token are used to generate a new access_token on a later date. In that case, the new account_id will be different from the old account_id.
If an account with a specific account_id disappears instead of changing, the account is likely closed. Closed accounts are not returned by the Plaid API.
Like all Plaid identifiers, the account_id is case sensitive.
 */
const defaultData: ContextState = {
  hasSeenRainyDayOverview: false,
  setHasSeenRainyDayOverview: (hasSeen: boolean) => {},
  hasSeenAppBefore: false,
  hasSeenHowToUse: false,
  setHasSeenHowToUse: () => {},
  setHasSeenAppBefore: () => {},

  demoMode: false,
  setDemoMode: () => {},

  centsMode: false,
  setCentsMode: () => {},

  showStoryPage: false,
  setShowStoryPage: (val: boolean) => {},
  appOverviewPageSeen: false,
  setAppOverviewPageSeen: (val: boolean) => {},
  systemPageSeen: false,
  setSystemPageSeen: (val: boolean) => {},
  showSystemPage: false,
  setShowSystemPage: (val: boolean) => {},

  showToolPage: false,
  setShowToolPage: (val: boolean) => {},

  showLoginPage: false,
  setShowLoginPage: (val: boolean) => {},

  skipReflectionIntro: false,
  setSkipReflectionIntro: (v: dataParameterType<boolean>) => {},
}

const UserConfigContext = React.createContext<ContextState>(defaultData)

export const USER_CONFIG_STORAGE_KEY = 'SPLURV_DATA__userConfig'

export const useUserConfig = () => React.useContext(UserConfigContext)

/**
 * This is locally saved data for user configuration (skipped transactions, linked credit card accounts, state on whether user has seen app before, skipped accounts, etc)
 * @param param0
 * @returns
 */
export const UserConfigProvider: React.FC = ({ children }) => {
  const [hasSeenAppBefore, _setHasSeenAppBefore] = React.useState<boolean>(false)
  const [hasSeenHowToUse, setHasSeenHowToUse] = useLocalStorageSyncedState<boolean>(
    false,
    'user-config-has-seen-how-to-use'
  )

  const [hasSeenRainyDayOverview, setHasSeenRainyDayOverview] = useLocalStorageSyncedState<boolean>(
    false,
    'user-config-has-seen-rainy-day-overview'
  )

  const [skipReflectionIntro, setSkipReflectionIntro] = useLocalStorageSyncedState<boolean>(
    false,
    'user-config-skip-reflection-intro'
  )

  const [showStoryPage, setShowStoryPage] = useLocalStorageSyncedState<boolean>(
    false,
    'mystery-page-show-story-page'
  )
  const [appOverviewPageSeen, setAppOverviewPageSeen] = useLocalStorageSyncedState<boolean>(
    false,
    'mystery-page-story-page-seen'
  )
  const [systemPageSeen, setSystemPageSeen] = useLocalStorageSyncedState<boolean>(
    false,
    'mystery-page-system-page-seen'
  )
  const [showSystemPage, setShowSystemPage] = useLocalStorageSyncedState<boolean>(
    false,
    'mission-page-show-system-page'
  )

  const [showToolPage, setShowToolPage] = useLocalStorageSyncedState<boolean>(
    false,
    'mission-page-show-tool-page'
  )

  const [showLoginPage, setShowLoginPage] = useLocalStorageSyncedState<boolean>(
    false,
    'mission-page-show-login-page'
  )

  const [demoMode, setDemoMode] = useLocalStorageSyncedState<boolean>(false, 'app-demo-mode')

  const [centsMode, setCentsMode] = useLocalStorageSyncedState<boolean>(false, 'app-cents-mode')

  const setHasSeenAppBefore = (hasSeenAppBefore: boolean) => {
    _setHasSeenAppBefore(hasSeenAppBefore)
    localStorage.setItem(
      USER_CONFIG_STORAGE_KEY,
      JSON.stringify({
        hasSeenAppBefore,
      })
    )
  }

  useEffect(() => {
    const savedConfig = localStorage.getItem(USER_CONFIG_STORAGE_KEY)
    if (savedConfig) {
      const parsedConfig = JSON.parse(savedConfig)
      _setHasSeenAppBefore(parsedConfig.hasSeenAppBefore)
      // _setCentsMode(parsedConfig.centsMode)
    }
  }, [])

  return (
    <UserConfigContext.Provider
      value={{
        hasSeenRainyDayOverview,
        setHasSeenRainyDayOverview,
        demoMode,
        centsMode,
        hasSeenHowToUse,
        setHasSeenHowToUse,
        setDemoMode,
        setCentsMode,
        hasSeenAppBefore,
        setHasSeenAppBefore,
        showStoryPage,
        skipReflectionIntro,
        setSkipReflectionIntro,
        setShowStoryPage,
        appOverviewPageSeen,
        setAppOverviewPageSeen,
        systemPageSeen,
        setSystemPageSeen,
        showSystemPage,
        setShowSystemPage,

        showToolPage,
        setShowToolPage,

        showLoginPage,
        setShowLoginPage,
      }}>
      {children}
    </UserConfigContext.Provider>
  )
}
