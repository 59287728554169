/**
 * Wishlist Rehaul Notes
 *
 * - DB Script to add new column of 'priority' to each wishlist item
 *  - This needs to be bulk updated for all active users
 *  - Based on the create date of each wishlist item so need to sort by date, assign increasing priority, save again

 * - POST request for updating priority of all wishlist items on user drag-and-drop reorder
 *  - Do I need to update each item's priority on every drag and drop?
 *  - How do I handle the async status for that?
 * 
 * - Allow user to adjust the range values for small / medium / large purchases based on amount
 * OR
 * - Have user get to determine what is small / medium / large for each item and not base this on the amount?
 * 
 * - The drag-and-drop animation needs to be made smoothly
 * 
 * - New Wishlist Item row component with potential podium styling / iconography
 *
 */
import { datadogRum } from '@datadog/browser-rum'
import { Icon, TextSlim, TextSpan } from 'Components/DesignSystem/Typography'
import { EmptyStatePage } from 'Components/EmptyStatePage/EmptyStatePage'
import { FOOTER_ACTION_ROW_HEIGHT } from 'Components/FooterActionRow/FooterActionRow'
import { useLocalStorageSyncedState } from 'Hooks/useLocalStorageSyncedState'
import { useData } from 'Providers/APIDataProvider'
import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import { IMPULSE_ICON } from 'Utilities/navigationTabUtilities'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router'
import { Transition } from 'react-spring/renderprops'
import {
  ContainerDiv,
  ScrollingFull,
} from '../../Components/DesignSystem/Layout/Containers/Containers'
import { cleanCurrency } from '../../Utilities/currencyFormater'
import { AddWishlistPage } from './NewWishlistPage/AddWishlistPage/AddWishlistPage'
import {
  ItemSizeFilter,
  WishlistDraggableList,
} from './WishlistDraggableList/WishlistDraggableList'
import { filterFunction } from './WishlistDraggableList/helpers'
import { WishlistPageFooter } from './WishlistPageFooter'
import { WishlistPageHeader } from './WishlistPageHeader'
import { WishlistItem, useWishlist } from './WishlistProvider/WishlistProvider'

const scrollingSectionCutoutSize = 85 + FOOTER_ACTION_ROW_HEIGHT

export type ActiveItemStatusFilter = 'active' | 'purchased' | 'resisted'

export const WishlistPage: React.FC = () => {
  const [itemToEdit, setItemToEdit] = React.useState<null | WishlistItem>(null)

  const { onboardingDone } = useMysteryDay()

  const { push } = useHistory()

  const {
    wishlistItems,
    refreshWishlistItems,
    refreshWishlistOrderLists,
    saveWishlistItem,
    wishlistOrderLists,
    updateWishlistOrderLists,
    loading,
    deleteWishlistItem,
  } = useWishlist()

  const [
    activeItemSizeFilter,
    setActiveItemSizeFilter,
  ] = useLocalStorageSyncedState<ItemSizeFilter>('medium', 'active-wishlist-item-size-filter')

  const [
    activeItemStatusFilter,
    setActiveItemStatusFilter,
  ] = useLocalStorageSyncedState<ActiveItemStatusFilter>(
    'active',
    'active-wishlist-item-status-filter'
  )

  const { monthlyBurnRate } = useData()

  const deleteItem = useCallback(
    async (name: string) => {
      await deleteWishlistItem(name)
      await refreshWishlistItems()
      await refreshWishlistOrderLists()
    },
    [deleteWishlistItem, refreshWishlistItems, refreshWishlistOrderLists]
  )

  const saveItem = useCallback(
    async (item: WishlistItem) => {
      const cleanPrice = cleanCurrency(item.price)
      await saveWishlistItem({ ...item, price: cleanPrice, _id: item._id })
      await refreshWishlistItems()
      await refreshWishlistOrderLists()
      datadogRum.addAction('add-wishlist-item', {})
      setActiveItemSizeFilter(cleanPrice < 100 ? 'small' : cleanPrice > 750 ? 'large' : 'medium')
      setItemToEdit(null)
    },
    [refreshWishlistItems, saveWishlistItem, setActiveItemSizeFilter, refreshWishlistOrderLists]
  )

  const closePage = useCallback(() => {
    setItemToEdit(null)
  }, [])

  const openPage = useCallback(() => {
    setItemToEdit({
      date: '',
      name: '',
      price: '',
      reminderDate: '',
      reason: '',
      purchasedDate: '',
      resistedDate: '',
      priority: 0,
      desire: 1,
      timeFactor: false,
      newnessFactor: false,
    })
  }, [])

  const editItem = useCallback((item) => {
    setItemToEdit(item)
  }, [])

  const items = React.useMemo(() => wishlistItems || [], [wishlistItems])
  const totalSaved = items?.reduce((acc, item) => acc + cleanCurrency(item.price), 0) || 0

  const monthsOfBurnSaved = totalSaved / monthlyBurnRate

  const activeWishlistItems = React.useMemo(
    () =>
      items?.filter(filterFunction(activeItemSizeFilter)).filter((item) => {
        if (activeItemStatusFilter === 'active') {
          return !item.purchasedDate && !item.resistedDate
        }
        if (activeItemStatusFilter === 'purchased') {
          return !!item.purchasedDate
        }
        if (activeItemStatusFilter === 'resisted') {
          return !!item.resistedDate
        }
        return true
      }) || [],
    [items, activeItemSizeFilter, activeItemStatusFilter]
  )

  const updateOrderList = React.useCallback(
    async (newList: string[]) => {
      await updateWishlistOrderLists({
        type: activeItemSizeFilter,
        newList,
      })
      await refreshWishlistOrderLists()
    },
    [activeItemSizeFilter, updateWishlistOrderLists, refreshWishlistOrderLists]
  )

  const activeWishlistOrderList = React.useMemo(
    () =>
      (wishlistOrderLists || {})[activeItemSizeFilter]?.filter((id) =>
        activeWishlistItems.map((item) => item._id).includes(id)
      ) || [],
    [activeItemSizeFilter, wishlistOrderLists, activeWishlistItems]
  )

  if (!onboardingDone)
    return (
      <EmptyStatePage
        subtitle={
          <TextSpan color="logoPrimary" size="lg" weight={200}>
            <Icon className={`${IMPULSE_ICON} mr-2`} color="logoPrimary" size="lg" />
            Impulse Buy Resisting
          </TextSpan>
        }
        content={
          <>
            <TextSlim size="md" color="texttransparent" weight={300}>
              Once you finish onboarding, this page will provide a gamified experience for resisting
              the impulses of consumerism and help nudge habits away from buying.
            </TextSlim>
          </>
        }
        cta={
          <>
            <TextSpan
              onClick={() => {
                push('/mystery')
              }}
              className="mt-2"
              color="texttransparent"
              size="xs">
              <u>Finish onboarding</u>
            </TextSpan>
          </>
        }
      />
    )

  return (
    <>
      <Transition
        items={itemToEdit}
        from={{ opacity: 1, transform: 'translate3d(0,100%,0)' }}
        enter={{ opacity: 1, transform: 'translate3d(0%,0,0)' }}
        leave={{ opacity: 1, transform: 'translate3d(0,100%,0)' }}>
        {(show) =>
          show &&
          ((props) => (
            <AddWishlistPage
              style={props}
              itemToEdit={itemToEdit}
              closePage={closePage}
              saveItem={saveItem}
              deleteItem={deleteItem}
              refreshItems={refreshWishlistItems}
            />
          ))
        }
      </Transition>

      <ContainerDiv style={{}}>
        {/* {items.length ? (
          <> */}
        <WishlistPageHeader
          openPage={openPage}
          activeItemSizeFilter={activeItemSizeFilter}
          setActiveItemSizeFilter={setActiveItemSizeFilter}
        />
        {/* </>
        ) : null} */}
        <ScrollingFull
          className="pt-2"
          cutout={items.length ? scrollingSectionCutoutSize : scrollingSectionCutoutSize}>
          <WishlistDraggableList
            activeItemSizeFilter={activeItemSizeFilter}
            editItem={editItem}
            wishlistOrderList={activeWishlistOrderList}
            items={activeWishlistItems || []}
            loading={loading}
            updateOrderList={updateOrderList}
            activeItemStatusFilter={activeItemStatusFilter}
          />
        </ScrollingFull>
        {/* <PurchasedItems /> */}
        <WishlistPageFooter
          openPage={openPage}
          setActiveItemStatusFilter={setActiveItemStatusFilter}
          activeItemStatusFilter={activeItemStatusFilter}
          totalResisted={totalSaved}
          refreshItems={() => {
            refreshWishlistItems()
            refreshWishlistOrderLists()
          }}
          monthsOfBurnSaved={monthsOfBurnSaved}
        />
      </ContainerDiv>
    </>
  )
}
