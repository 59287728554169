import { dateToString } from '../../Utilities/dateUtilities/dateUtilities'
import { LinkType } from '../../Utilities/navigationTabUtilities'
import {
  CushionSubModeType,
  DashboardViewModeType,
  FlexibleCalculationMethodType,
  MysteryDayContextState,
  MysteryPageType,
  SelectedAccountType,
  UserOnboardingSetupInterface,
} from './MysteryDayProvider.interfaces'

export const mysteryDayProviderDefaultData: MysteryDayContextState = {
  historicalTrackedTransactions: [],
  clearHistoricalTrackedTransactions: () => {},
  saveUserOnboardingSetup: (setup: UserOnboardingSetupInterface) => Promise.resolve(),
  deleteUserOnboardingSetup: () => Promise.resolve(),

  saveUserOnboardingLinkedAccount: (setup: SelectedAccountType) => Promise.resolve(),
  saveUserOnboardingLinkedAccountLoading: false,

  saveUserOnboardingSetupLoading: false,

  getUserOnboardingSetupLoading: false,
  deleteUserOnboardingSetupLoading: false,
  page: 'mode-selection',
  setPage: () => {},
  mysteryMode: 'default',
  setMysteryMode: () => {},

  cushionSubMode: null,
  setCushionSubMode: (mode: CushionSubModeType) => {},

  debtAmount: '0',
  setDebtAmount: () => {},

  itemCost: '',
  setItemCost: () => {},

  itemExplanation: '',
  setItemExplanation: (v: string) => {},

  itemName: '',
  setItemName: (v: string) => {},

  debtInterest: 0,
  setDebtInterest: () => {},

  monthlyContribution: 300,
  setMonthlyContribution: () => {},

  desiredTimeOff: 12,
  setDesiredTimeOff: () => {},

  onboardingDone: false,
  setOnboardingDone: () => {},

  resetOnboarding: () => {},

  livingCostPercentageAdjustment: 1,
  setLivingCostPercentageAdjustment: (percentage: number) => {},

  dashboardViewMode: 'standard',
  setDashboardViewMode: (mode: DashboardViewModeType) => {},

  startDate: dateToString(new Date()),
  setStartDate: (date: string) => {},

  calculationMethod: null,
  setCalculationMethod: (method: FlexibleCalculationMethodType) => {},

  pagesUnlocked: ['/mystery'],
  onboardingPagesVisited: [],
  unlockPage: (page: LinkType[]) => {},
  visitOnboardingPage: (page: MysteryPageType) => {},

  bigWhy: '',
  setBigWhy: (why: string) => {},

  linkedAccount: null,
  linkedAccounts: [],
  addLinkedAccount: (account: SelectedAccountType) => {},
  clearLinkedAccounts: () => {},
  setLinkedAccount: (linkedAccount) => {},
}
