import { ContainerCutout } from 'Components/DesignSystem/Layout/Containers/Containers'
import { Icon, StyledVr, TextSpan } from 'Components/DesignSystem/Typography'
import React from 'react'
import { ColorType } from 'theme'

type ActionItemBase = {
  title: string
  subtitle: string
  color: ColorType
  onClick: () => void
  inactive?: boolean
}

interface ActionItemWithContent extends ActionItemBase {
  iconContent: React.ReactElement
  type: 'content'
}

interface ActionItemSimple extends ActionItemBase {
  iconClassName: string
  type: 'simple'
}

export type ActionItem = ActionItemWithContent | ActionItemSimple

interface Props {
  items: ActionItem[]
}

export const FOOTER_ACTION_ROW_HEIGHT = 108
export const FOOTER_ACTION_ROW_HEIGHT_INNER = 92
export const FooterActionRow: React.FC<Props> = ({ items }) => {
  return (
    <ContainerCutout
      size={FOOTER_ACTION_ROW_HEIGHT_INNER}
      className="justify-content-center align-items-center py-1 px-0 my-2 flex-row w-100">
      <div
        onClick={() => {}}
        className="d-flex align-items-center w-100 justify-content-start p-2 w-100"
        style={{
          boxShadow: 'rgba(38, 37, 55, 0.5) 0px 0px 7px 5px inset',
          // boxShadow: 'rgb(0 0 0 / 30%) 0px 0px 4px 2px',
          borderRadius: '8px',
        }}>
        {items.map((item, index) => {
          return (
            <div
              className="d-flex flex-row align-items-center w-100"
              key={`${item.title}-${index}`}>
              <div
                onClick={() => {
                  item.onClick()
                }}
                className="d-flex flex-column align-items-center "
                style={{
                  padding: '0 .25rem',
                  flex: 1,
                  opacity: item.inactive ? 0.3 : 1,
                }}>
                <TextSpan className="mb-1" color={item.color} weight={700} size="xxs">
                  {item.title}
                </TextSpan>
                <div>
                  <div className="d-flex flex-row align-items-center ">
                    {item.type === 'simple' ? (
                      <Icon className={item.iconClassName} color={item.color} size="lg" />
                    ) : (
                      <>{item.iconContent}</>
                    )}
                  </div>
                </div>
                <TextSpan size="xs" color={item.color}>
                  {item.subtitle}
                </TextSpan>
              </div>
              {index !== items.length - 1 ? <StyledVr /> : null}
            </div>
          )
        })}
      </div>
    </ContainerCutout>
  )
}
