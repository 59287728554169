import {
  MysteryDayType,
  MysteryPageType,
} from 'Providers/MysteryDayProvider/MysteryDayProvider.interfaces'
import React from 'react'
import { Icon, TextSpan } from '../../../Components/DesignSystem/Typography'
import { useMysteryDay } from '../../../Providers/MysteryDayProvider/MysteryDayProvider'
import { colorFromMysteryDayType } from '../../../Utilities/utils'
import { OnboardingLinkedList } from '../OnboardingLinkedList'
import { ProgressCircle, ProgressIcon, ProgressIndicatorDiv } from './components'

const getNumberOfTotalPages = (mysteryMode: MysteryDayType) => {
  return 5
}

const getNumberFromPage = (page: MysteryPageType, mysteryMode: MysteryDayType) => {
  const linkedListPage = OnboardingLinkedList.find((datum) => {
    let pageToBeChecked: MysteryPageType
    if (typeof datum.page === 'function') {
      pageToBeChecked = datum.page(mysteryMode)
    } else {
      pageToBeChecked = datum.page
    }
    return pageToBeChecked === page
  })!
  return linkedListPage.index
}

const getPageFromNumber = (number: number, mysteryMode: MysteryDayType) => {
  const linkedListPage = OnboardingLinkedList.find((datum) => datum.index === number)!
  return linkedListPage.page
}

const getIconFromPageName = (page: MysteryPageType, mysteryMode: MysteryDayType) => {
  const linkedListPage = OnboardingLinkedList.find((datum) => {
    let pageToBeChecked: MysteryPageType
    if (typeof datum.page === 'function') {
      pageToBeChecked = datum.page(mysteryMode)
    } else {
      pageToBeChecked = datum.page
    }
    return pageToBeChecked === page
  })!
  return linkedListPage.icon
}

export const ProgressIndicator: React.FC = () => {
  const { page, mysteryMode, setPage, onboardingPagesVisited } = useMysteryDay()

  const numberOfPages = getNumberOfTotalPages(mysteryMode)
  const nums = Array.from(Array(numberOfPages).keys())
  const activePageNumber = getNumberFromPage(page, mysteryMode)

  // if (page === 'mode-selection' || page === 'total-cost-of-living-complete') {
  //   return null
  // }

  if (page === 'mode-selection') {
    return null
  }

  const iconDot = getIconFromPageName(page, mysteryMode)
  let iconClassName
  if (typeof iconDot === 'function') {
    iconClassName = iconDot(mysteryMode)
  } else {
    iconClassName = iconDot
  }

  return (
    <ProgressIndicatorDiv
      style={{
        transition: 'opacity 0.3s ease-in-out',
        opacity: page === 'total-cost-of-living-complete' ? 0 : 1,
      }}>
      <ProgressIcon
        pageNumber={activePageNumber > nums.length ? nums.length : activePageNumber}
        numberOfPages={numberOfPages}>
        <TextSpan color={colorFromMysteryDayType(mysteryMode)} weight={700} size="xxxs" style={{}}>
          {activePageNumber}/{numberOfPages}
        </TextSpan>
        <Icon
          size="lg"
          // className={iconFromMysteryDayType(mysteryMode)}
          className={iconClassName}
          color={colorFromMysteryDayType(mysteryMode)}
        />
      </ProgressIcon>
      {nums.map((num) => {
        const currentPageNumber = getNumberFromPage(page, mysteryMode)
        const dotPageType = getPageFromNumber(num + 1, mysteryMode)
        let dotPage: MysteryPageType
        if (typeof dotPageType === 'function') {
          dotPage = dotPageType(mysteryMode)
        } else {
          dotPage = dotPageType
        }
        const iconDot = getIconFromPageName(dotPage, mysteryMode)
        let iconClassName
        if (typeof iconDot === 'function') {
          iconClassName = iconDot(mysteryMode)
        } else {
          iconClassName = iconDot
        }

        const visited = onboardingPagesVisited.includes(dotPage)
        // if (num === nums.length - 1 && page === 'total-cost-of-living-complete') {
        //   return null
        // }
        return (
          <ProgressCircle
            onClick={() => {
              if (num < currentPageNumber || visited) {
                setPage(dotPage)
              }
            }}
            key={num}
            className={iconClassName}
            active={activePageNumber === num + 1}
            visited={visited}
            completed={activePageNumber >= num + 1}
            mysteryDayType={mysteryMode}
          />
        )
      })}
    </ProgressIndicatorDiv>
  )
}
