import { TextSpan } from 'Components/DesignSystem/Typography'
import React from 'react'
import { ColorType, FontSizeType } from 'theme'
import { numberToCurrency } from 'Utilities/currencyFormater'

interface Props {
  title: string
  amount: number
  color?: ColorType
  size?: FontSizeType
  titleSize?: FontSizeType
}
export const VerticalStackedMoneyDisplay: React.FC<Props> = ({
  title,
  amount,
  color = 'mehred',
  size = 'md',
}) => {
  return (
    <div className="d-flex flex-column align-items-center">
      <TextSpan size="xs" className="px-2 my-0">
        {title}:
      </TextSpan>
      <TextSpan size={size} weight={600} color={color} className="px-2 my-0">
        {numberToCurrency(amount, true, true)}
      </TextSpan>
    </div>
  )
}

export const VerticalStackedNumberDisplay: React.FC<Props & { unit: string }> = ({
  title,
  amount,
  color = 'mehred',
  size = 'md',
  unit,
}) => {
  return (
    <div className="d-flex flex-column align-items-center">
      <TextSpan size="xs" className="px-2 my-0">
        {title}:
      </TextSpan>
      <TextSpan size={size} weight={600} color={color} className="px-2 my-0">
        {amount}
        <TextSpan size={'xs'} weight={600} color={color} className="px-2 my-0">
          {unit}
        </TextSpan>
      </TextSpan>
    </div>
  )
}
