import { WEEKS_PER_MONTH } from '../constants'
import { FrequencyType, MoneyStream } from '../interfaces'

const getMonthlyAverage = (
  streams: MoneyStream[],
  type: 'income' | 'bill',
  filterOnIsSubscription?: boolean
) =>
  Math.floor(
    streams
      .filter((s) => s.enabled)
      .filter((s) => s.type === type)
      .filter((s) =>
        filterOnIsSubscription !== undefined
          ? s.isSubscription === filterOnIsSubscription || s.isSubscription === undefined
          : true
      )
      .reduce((acc, curr) => {
        const amount = curr.amount
        switch (curr.frequency) {
          case FrequencyType.onetime:
            return acc + 0
          case FrequencyType.anually:
            return acc + amount / 12
          case FrequencyType.semiannually:
            return acc + amount / 6
          case FrequencyType.quarterly:
            return acc + amount / 4
          case FrequencyType.monthly:
            return acc + amount
          case FrequencyType.weekly:
            return acc + amount * WEEKS_PER_MONTH
          case FrequencyType.semimonthly:
            return acc + amount * 2
          case FrequencyType.biweekly:
            return acc + (amount * 26) / 12
          default:
            return acc
        }
      }, 0)
  )

export const getMonthlyIncome = (streams: MoneyStream[]) => getMonthlyAverage(streams, 'income')
export const getMonthlyBills = (streams: MoneyStream[]) => getMonthlyAverage(streams, 'bill', false)
export const getMonthlySubscriptions = (streams: MoneyStream[]) =>
  getMonthlyAverage(streams, 'bill', true)
