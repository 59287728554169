import { transactionTimeFilterFunction } from 'Pages/SpendingPage/AdvancedTransactionView/helpers'
import { useFinancialData } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import { useUserCustomSettings } from 'Providers/UserCustomSettingsProvider/UserCustomSettingsProvider'

export const useCategoryBudgetHelpers = () => {
  const { transactions } = useFinancialData()

  const { settings } = useUserCustomSettings()

  const getTotalOfTargetCategories = () => {
    if (settings && settings.categoryMetadata) {
      const total = Object.values(settings.categoryMetadata).reduce(
        (acc, { limit }) => acc + limit,
        0
      )
      return total
    } else {
      return 0
    }
  }

  const getCategoryTotal = (category: string) => {
    const filteredTransactions = transactions
      ?.filter((t) => {
        const transactionMetadata =
          settings?.transactionsMetadata?.[t.transaction_id] ||
          settings?.transactionsMetadata?.[t.pending_transaction_id || '']
        return (
          transactionMetadata?.envelopeCategory === category &&
          transactionMetadata.calculationType === 'variable'
        )
      })
      .filter((t) => transactionTimeFilterFunction(t, 'thisMonth'))

    const transactionTotal = filteredTransactions.reduce((acc, t) => acc + t.amount, 0)
    return transactionTotal
  }

  const getCategoryCount = (category: string) => {
    const transactionsLength = transactions
      ?.filter((t) => {
        const transactionMetadata =
          settings?.transactionsMetadata?.[t.transaction_id] ||
          settings?.transactionsMetadata?.[t.pending_transaction_id || '']
        return (
          transactionMetadata?.envelopeCategory === category &&
          transactionMetadata.calculationType === 'variable'
        )
      })
      .filter((t) => transactionTimeFilterFunction(t, 'thisMonth')).length
    return transactionsLength
  }

  const getPlaidCategoryTotal = (category: string) => {
    const transactionTotal = transactions
      ?.filter((t) => {
        return t.category?.[0] === category
      })
      .filter((t) => transactionTimeFilterFunction(t, 'thisMonth'))
      .reduce((acc, t) => acc + t.amount, 0)
    return transactionTotal
  }

  const getCustomVariableSpendTotal = () => {
    const transactionTotal = transactions
      ?.filter((t) => {
        const transactionMetadata =
          settings?.transactionsMetadata[t.transaction_id] ||
          settings?.transactionsMetadata[t.pending_transaction_id || '']
        return transactionMetadata?.calculationType === 'variable'
      })
      .filter((t) => transactionTimeFilterFunction(t, 'thisMonth'))
      .reduce((acc, t) => acc + t.amount, 0)
    return transactionTotal
  }

  const getAnticipatedCategories = () => {
    return Object.values(settings?.categoryMetadata || {}).filter((cm) => cm.kind === 'regular')
  }

  const getIrregularCategories = () => {
    return Object.values(settings?.categoryMetadata || {}).filter((cm) => cm.kind === 'irregular')
  }

  const getAnticipatedTotalBudgeted = () => {
    return getAnticipatedCategories().reduce((acc, cm) => acc + cm.limit, 0)
  }

  const getIrregularTotalBudgeted = () => {
    return getIrregularCategories().reduce((acc, cm) => acc + cm.limit, 0)
  }

  // const getIrregularTotalBudgeted = () => {
  //   return Object.values(settings?.categoryMetadata || {}).filter(cm => cm.kind === 'regular').reduce((acc, cm) => acc + cm.limit, 0)
  // }

  const getAnticipatedTotalSpent = () => {
    const anticipatedCategories = Object.entries(settings?.categoryMetadata || {})
      .filter(([key, cm]) => cm.kind === 'regular')
      .map(([key, cm]) => key)
    const anticipatedSpendingTransactions = transactions
      ?.filter((t) => {
        const transactionMetadata =
          settings?.transactionsMetadata[t.transaction_id] ||
          settings?.transactionsMetadata[t.pending_transaction_id || '']

        return (
          anticipatedCategories.includes(transactionMetadata?.envelopeCategory || '') &&
          transactionMetadata?.calculationType === 'variable'
        )
      })
      .filter((t) => transactionTimeFilterFunction(t, 'thisMonth'))
    return anticipatedSpendingTransactions.reduce((acc, t) => acc + t.amount, 0)
  }

  const getIrregularTotalSpent = () => {
    const irregularCategories = Object.entries(settings?.categoryMetadata || {})
      .filter(([key, cm]) => cm.kind === 'irregular')
      .map(([key, cm]) => key)
    const irregularSpendingTransactions = transactions
      ?.filter((t) => {
        const transactionMetadata =
          settings?.transactionsMetadata[t.transaction_id] ||
          settings?.transactionsMetadata[t.pending_transaction_id || '']

        return (
          irregularCategories.includes(transactionMetadata?.envelopeCategory || '') &&
          transactionMetadata?.calculationType === 'variable'
        )
      })
      .filter((t) => transactionTimeFilterFunction(t, 'thisMonth'))
    return irregularSpendingTransactions.reduce((acc, t) => acc + t.amount, 0)
  }

  const getCustomFixedSpendTotal = () => {
    const transactionTotal = transactions
      ?.filter((t) => {
        const transactionMetadata =
          settings?.transactionsMetadata[t.transaction_id] ||
          settings?.transactionsMetadata[t.pending_transaction_id || '']
        return transactionMetadata?.calculationType === 'fixed'
      })
      .filter((t) => transactionTimeFilterFunction(t, 'thisMonth'))
      .reduce((acc, t) => acc + t.amount, 0)
    return transactionTotal
  }

  const getUncategorizedData = () => {
    const uncategorizedTransactions = transactions
      ?.filter((t) => transactionTimeFilterFunction(t, 'thisMonth'))
      ?.filter((t) => {
        const transactionMetadata =
          settings?.transactionsMetadata[t.transaction_id] ||
          settings?.transactionsMetadata[t.pending_transaction_id || '']
        return transactionMetadata?.calculationType === 'fixed'
          ? !transactionMetadata.fixedStreamName
          : transactionMetadata?.calculationType === 'variable'
          ? !transactionMetadata.envelopeCategory
          : transactionMetadata?.calculationType === 'skip'
          ? false
          : true
      })
    // .filter((t) => transactionTimeFilterFunction(t, 'thisMonth'))

    const total = uncategorizedTransactions.reduce((acc, t) => acc + t.amount, 0)
    return {
      total,
      length: uncategorizedTransactions.length,
      transactions: uncategorizedTransactions,
    }
  }

  const getPlaidVariableSpendTotal = () => {
    const transactionTotal = transactions
      .filter((t) => transactionTimeFilterFunction(t, 'thisMonth'))
      .reduce((acc, t) => acc + t.amount, 0)
    return transactionTotal
  }

  return {
    getCategoryTotal,
    getCustomVariableSpendTotal,
    getPlaidVariableSpendTotal,
    getPlaidCategoryTotal,
    getCategoryCount,
    getTotalOfTargetCategories,
    getCustomFixedSpendTotal,
    getUncategorizedData,
    getAnticipatedTotalBudgeted,
    getIrregularTotalBudgeted,
    getIrregularTotalSpent,
    getAnticipatedTotalSpent,
  }
}
