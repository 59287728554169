import { Icon } from 'Components/DesignSystem/Typography'
import { ItemText } from 'design-system/typography/Text/Text'
import React from 'react'

interface NicknameLabelTagProps {
  nickname: string | undefined
}
export const NicknameLabelTag: React.FC<NicknameLabelTagProps> = ({ nickname }) => {
  if (!nickname) {
    return (
      <ItemText>
        <Icon
          style={
            {
              '--fa-beat-fade-opacity': 0.67,
              '--fa-beat-scale': 1.27,
            } as React.CSSProperties
          }
          className="fas fa-tag mr-1 fa-beat-fade"
          color="texttransparent"
          size="xxs"
        />
        missing nickname
      </ItemText>
    )
  }
  return (
    <ItemText>
      <Icon className="fas fa-tag mr-1" color="textsupertransparent" size="xxs" />
      {nickname ? <>"{nickname}"</> : 'Missing nickanem'}
    </ItemText>
  )
}
