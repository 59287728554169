import { fireConfetti } from 'Pages/WishlistPage/Confetti'
import { useUIState } from 'Providers/UIStateProvider'
import React, { useEffect } from 'react'
import { Icon, TextPopupSmall, TextSpan } from '../../../Components/DesignSystem/Typography'
import { useMysteryDay } from '../../../Providers/MysteryDayProvider/MysteryDayProvider'

export const BigWhyModule: React.FC = () => {
  const { bigWhy, setBigWhy } = useMysteryDay()

  const [popupSeen, setPopupSeen] = React.useState(false)
  const { setMessagePopupContent, messagePopupContent } = useUIState()

  useEffect(() => {
    if (popupSeen && !messagePopupContent) {
      setPopupSeen(false)
      setTimeout(() => {
        const answer = prompt(
          `No single system or tool or AI will ever overcome our humanity and our emotions. What's your big why? 65 characters max`
        )
        if (answer) {
          setBigWhy(answer.slice(0, 65))
          fireConfetti({ colors: ['#F4F4F6'] })
          setPopupSeen(false)
        }
      }, 500)
    }
  }, [messagePopupContent, popupSeen, setBigWhy])

  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-100 my-2">
      <div className="d-flex flex-row justify-content-center align-items-center">
        <TextPopupSmall
          color={bigWhy ? 'logoPrimary' : 'text'}
          size="sm"
          className="p-2 mr-4"
          onClick={() => {
            if (bigWhy) {
              setMessagePopupContent(
                `You can do this<><> "${bigWhy}"<><>That's wonderful. Best of luck on your travels.`
              )
              setPopupSeen(false)
            } else {
              setMessagePopupContent(`The Big Mantra<>Welcome, you have arrived at the culmination of your journey. Here's the truth:.<>
            No single system or app or AI will ever overcome our humanity and our emotions. We are irrational, beautiful human creatures with emotions. <><>Saying no to things is hard, and always will be hard. Consumerism is strong, and will only get stronger. So what's your reason to spend less? What's your reason to change? Why is this time going to be different?<><>Money is time, and we're here to save you both. So when you tap deep into that inner-self, what is it that you most want to do? Set your mantra and we'll keep it for you.
            `)
              setPopupSeen(true)
            }
          }}>
          {bigWhy ? (
            <>
              Best of Luck{' '}
              <Icon color="logoPrimary" className="far fa-circle-heart ml-2" size="md" />
            </>
          ) : (
            <>
              Set your Big Why mantra
              <Icon color="text" className="far fa-earth-americas ml-2" size="md" />
            </>
          )}
        </TextPopupSmall>
      </div>
      {bigWhy ? (
        <TextSpan
          size="xs"
          color="logoPrimary"
          style={{
            fontStyle: 'italic',
          }}>
          Mantra: "{bigWhy}"
        </TextSpan>
      ) : null}
    </div>
  )
}
