import { Icon, TextPopupSmall, TextSpan } from 'Components/DesignSystem/Typography'
import { usePWA } from 'Providers/PWAProvider/PWAProvider'
import React from 'react'
import { SettingsItem } from '../components'

export const UpdateAvailable: React.FC = () => {
  const { updateAvailable } = usePWA()
  return (
    <SettingsItem
      onClick={() => {
        if (updateAvailable) {
          window.location.reload()
        }
      }}
      style={{
        opacity: updateAvailable ? 1 : 0.6,
        transition: 'opacity .3s ease-in-out',
      }}>
      <div className="d-flex flex-column w-100 align-items-start">
        <div className="d-flex flex-row w-100 align-items-center">
          <Icon
            className={`fas fa-star mr-1 ${updateAvailable ? 'fa-beat-fade' : ''}`}
            color={updateAvailable ? 'logoPrimary' : 'textsupertransparent'}
          />
          Update Available
          {updateAvailable ? (
            <TextPopupSmall className="ml-2" color="logoPrimary">
              Click to update
            </TextPopupSmall>
          ) : null}
        </div>
        <TextSpan size="xxs" color="texttransparent">
          Stay up to date with our latest version without re-downloading
        </TextSpan>
      </div>
    </SettingsItem>
  )
}
