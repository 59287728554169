import { useUIState } from 'Providers/UIStateProvider'
import {
  BodyText,
  CaptionText,
  ItemText,
  SubtitleText,
  SubtleText,
} from 'design-system/typography/Text/Text'
import React from 'react'

export const WhatAmIPayingFor = () => {
  const { setPopup } = useUIState()
  return (
    <div
      className="p-2 text-center align-items-center justify-content-center"
      onClick={() => {
        setPopup(
          <div className="d-flex flex-column p-4 ">
            <SubtitleText>Where the money goes</SubtitleText>
            <BodyText className="mt-4">
              The irony of charging for a finance app designed to lower spending is not lost on us.
              We have pivoted between being a non-profit and operating off donations, being a
              for-profit and letting customers pick what they want to pay, and several other
              approaches. We want to free people from financial overwhelm, period. Here is where the
              subscription payments go and why Splurv and its mission can't exist without them.
            </BodyText>
            <ul className="pl-2">
              <li>
                <ItemText>Plaid:</ItemText> <br />
                <CaptionText>
                  Plaid is the service that allows us to connect and aggregate bank account
                  information. Their pricing model is per user which means we have a minimum we need
                  to charge just to cover costs.
                </CaptionText>
              </li>
              <li>
                <ItemText>Servers / Databases / Services:</ItemText>
                <br />
                <CaptionText>
                  Running software is not free, and even with zero active development, simply
                  keeping the app running has a cost.
                </CaptionText>
              </li>
              <li>
                <ItemText>Human Engineers:</ItemText>
                <br />{' '}
                <CaptionText>
                  We would like to keep improving Splurv, and building features you request. The
                  math is simple: If enough people see and believe in our mission, find value in
                  Splurv, and pay for it, we can keep working on it. If not, our time will have to
                  go elsewhere.
                </CaptionText>
              </li>
            </ul>
          </div>
        )
      }}>
      <SubtleText>
        <u>What am I paying for?</u>
      </SubtleText>
    </div>
  )
}
