import { add, differenceInDays, isAfter, isBefore, startOfMonth } from 'date-fns'
import dayjs from 'dayjs'
import {
  formatDate,
  getNextDateOnDayOfMonthFollowing,
  getNextDateOnDayOfWeekFollowing,
  monthsOutGivenDays,
  weeksOutGivenDays,
} from '../dateUtilities/dateUtilities'
import { BankItem, FrequencyType, MoneyEvent, MoneyStream } from '../interfaces'
import { getAmountForEvent } from './helpers'

interface StreamsToEventsReturnInterface {
  events: MoneyEvent[]
  mapOfEvents: { [index: string]: MoneyEvent[] }
  cacheHitKey: string
}

const generateSetOfEventsFromDestinationsAndSources = ({
  stream,
  date,
  amount,
}: {
  stream: MoneyStream
  date: Date
  amount: number
}): MoneyEvent[] => {
  const destinations = stream.destinations
  const sources = stream.sources

  if (destinations && destinations.length) {
    const setOfEvents =
      destinations
        ?.filter((d) => d.portionAllocated !== 0)
        ?.map((d) => {
          const event: MoneyEvent = {
            amount: d.portionAllocated,
            name: `${stream.name}-${d.accountIdOrName}`,
            type: stream.type,
            date,
            destinationAccount: d.accountIdOrName,
          }
          return event
        }) || []
    return setOfEvents
  }
  if (sources && sources.length) {
    const setOfEvents =
      sources?.map((s) => {
        const event: MoneyEvent = {
          amount: amount,
          name: `${stream.name}-${s.accountIdOrName}`,
          type: stream.type,
          date,
          sourceAccount: s.accountIdOrName,
        }
        return event
      }) || []
    return setOfEvents
  } else {
    const event: MoneyEvent = {
      amount,
      name: stream.name,
      type: stream.type,
      date,
    }
    return [event]
  }
}

type StreamsToEventsCacheInterface = {
  [index: string]: StreamsToEventsReturnInterface
}
const streamsToEventsCache: StreamsToEventsCacheInterface = {}

export const streamsToEvents = (
  streams: MoneyStream[],
  _daysOut: number,
  startDate: Date,
  plaidAccounts: BankItem[] = [],
  flexibleSpendPerMonth: number = 0,
  paycheckCashContributionOverride: number | null = null,
  overallExpenseAdjustmentPercentage: number = 1
): StreamsToEventsReturnInterface => {
  let endDateOfRange = dayjs().add(_daysOut, 'days').toDate()

  const cacheHitKey = 'test'
  const now = performance.now()
  // const cacheHitKeyNew = hash({
  //   streams,
  //   paycheckCashContributionOverride,
  //   flexibleSpendPerMonth,
  //   startDate,
  //   overallExpenseAdjustmentPercentage,
  //   _daysOut,
  // })
  // const endOfCacheKeyGeneration = performance.now()
  // const diff = endOfCacheKeyGeneration - now
  // console.debug(`generating the cache key ${cacheHitKeyNew} took ${diff}ms`)

  // const cacheHitKey = cacheHitKeyNew

  // const potentialCacheHit = streamsToEventsCache[cacheHitKey]

  // if (potentialCacheHit) {
  //   console.debug(
  //     'We have a cache hit! streamsToEvents has previously been called with the same params'
  //   )
  //   return potentialCacheHit
  // }

  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const startingBalanceDate = startDate

  const difference = Math.abs(differenceInDays(today, startingBalanceDate))
  /* TODO: The mess that is startDate, currentBalanace, and daysOut
  /* FIXME:
  /* HACK:
  startDate vs. dayjs() (aka today) and ho endDateOfRange + daysOut get used

  Because we don't have live data of how much a user has in their checking account NOW,
  (since we're not syncing with checking accounts bank data from Plaid), we need users to manually
  enter how much they have in their checking account

  However, how much they have now might be different than tomorrow which might be different than
  last week as they spend money, pay off credit cards, and get pay checks to their account.
  So, how do we project how much they have at any point in time? How do we know how much a user has NOW?
  We ask them. And when they enter how cash they have (which is presumably how much they had at the time
  of entering this value), we mark that value as a "source of truth" data point. The user said, I have 
  X amount of cash on day Y and we assume this to be concrete and accurate. From that point forward, any 
  days value is calculated by starting at that initial point on day Y, and adding up all the things the user
  has said will occurr and when - paychecks, bills, venmos, etc. With this mechanism, if a user adds to Splurv
  all the things that happen accuraately - both real time updates of paychecks/bills with more accurate values as 
  well as one time Venmos, bonuses, etc, that weren't previously thought of in advance, the value that Splurv projects 
  should always be in sync with reality. If it ever is not, the user can always just say hold up, I'm going to reset this 
  and say NOW I have a X1 amount of money on day Y1. Then, our calculations going forward start at this new source of truth, Y1

  What does this mean for this "simple" streamsToEvents function?

  Well, we need to know where to start adding up events FROM, and how far to go before we END

  We can easily start from whenever the most recent balance has been entered, easy. Y1

  How far do we go? Well, we can have a setting that dictates how many days out we want the projection.
  So say the user says 2 years. I want to see where I will be in 2 years. Said another way, I want to know where 
  I will be 2 years from NOW. So they set their calculation to be 730 days (2 years). But above we said that we start
  calculating dates FROM the most recent starting balance. If this was 6 months ago, and we start there, 730 days of calculations
  will NOT take us to 2 years from now. They will take us to 2 years from that starting balance. To have an accurate picture of where 
  a user will be X years from now, we must calculate X years + N days between now and startingPoint (Y) of events

  Ideal Option: We hook up bank accounts with Plaid and always have an updated picture of their current cash pile
  This removes the need for "currentBalance" (which is all over the app)

  // Update (as of 11/11/2021) - we now pull account data from plaid so this no longer a problem


  */
  let daysOut = _daysOut + difference

  const weeksOut = weeksOutGivenDays(daysOut)
  const monthsOut = monthsOutGivenDays(daysOut)

  const mapOfEvents: { [index: string]: MoneyEvent[] } = {}

  const insertEventToMap = (event: MoneyEvent) => {
    const dateString = formatDate(event.date)
    if (mapOfEvents[dateString]?.length) {
      mapOfEvents[dateString].push(event)
    } else {
      mapOfEvents[dateString] = [event]
    }
  }

  const events: MoneyEvent[] = []

  for (let i = 0; i < streams.length; i++) {
    const stream = streams[i]
    const streamEndDate = stream.endDate
    if (streamEndDate) {
      daysOut = differenceInDays(dayjs(streamEndDate).toDate(), dayjs(startDate).toDate())
      endDateOfRange = dayjs().add(daysOut, 'days').toDate()
    }
    if (!stream.enabled) continue

    switch (stream.frequency) {
      case FrequencyType.onetime:
        {
          const { futureDate } = stream
          const date = dayjs(futureDate).toDate()
          // TODO: do this across the rest of the dates in this file
          date.setHours(0, 0, 0, 0)
          const amount = getAmountForEvent(
            stream,
            date,
            paycheckCashContributionOverride,
            null,
            overallExpenseAdjustmentPercentage
          )

          const streamEvents = generateSetOfEventsFromDestinationsAndSources({
            stream,
            date,
            amount,
          })
          events.push(...streamEvents)
          streamEvents.forEach((se) => insertEventToMap(se))
        }
        break
      case FrequencyType.daily:
        {
          const nextEventDate = dayjs(startDate).toDate()
          for (let i = 0; i <= daysOut; i++) {
            const date = add(nextEventDate, { days: i })
            const amount = getAmountForEvent(
              stream,
              date,
              paycheckCashContributionOverride,
              null,
              overallExpenseAdjustmentPercentage
            )

            const streamEvents = generateSetOfEventsFromDestinationsAndSources({
              stream,
              date,
              amount,
            })
            events.push(...streamEvents)
            streamEvents.forEach((se) => insertEventToMap(se))
          }
        }
        break
      case FrequencyType.monthly:
        {
          const { day } = stream
          const nextEventDate = getNextDateOnDayOfMonthFollowing(day, startDate).toDate()
          if (isBefore(endDateOfRange, nextEventDate)) {
            break
          }

          for (let i = 0; i <= monthsOut; i++) {
            const date = add(nextEventDate, { months: i })

            if (isAfter(date, endDateOfRange)) {
              continue
            }

            const amount = getAmountForEvent(
              stream,
              date,
              paycheckCashContributionOverride,
              null,
              overallExpenseAdjustmentPercentage
            )

            const streamEvents = generateSetOfEventsFromDestinationsAndSources({
              stream,
              date,
              amount,
            })
            events.push(...streamEvents)
            streamEvents.forEach((se) => insertEventToMap(se))
          }
        }
        break
      case FrequencyType.semimonthly:
        {
          const { mostRecentDate, secondMostRecentDate } = stream
          const mostRecentDateDayOfMonth = Number(mostRecentDate.split('-')[2])
          const secondMostRecentDateDayOfMOnth = Number(secondMostRecentDate.split('-')[2])
          const nextEventDate = getNextDateOnDayOfMonthFollowing(
            mostRecentDateDayOfMonth,
            startDate
          ).toDate()
          const secondNextEventDate = getNextDateOnDayOfMonthFollowing(
            secondMostRecentDateDayOfMOnth,
            startDate
          ).toDate()
          if (isBefore(endDateOfRange, nextEventDate)) {
            break
          }

          for (let i = 0; i <= monthsOut; i++) {
            const dateA = add(nextEventDate, { months: i })
            const dateB = add(secondNextEventDate, { months: i })

            if (isAfter(dateA, endDateOfRange)) {
              continue
            }

            const amountA = getAmountForEvent(
              stream,
              dateA,
              paycheckCashContributionOverride,
              null,
              overallExpenseAdjustmentPercentage
            )
            const amountB = getAmountForEvent(
              stream,
              dateB,
              paycheckCashContributionOverride,
              null,
              overallExpenseAdjustmentPercentage
            )

            const streamEventsA = generateSetOfEventsFromDestinationsAndSources({
              stream,
              date: dateA,
              amount: amountA,
            })
            const streamEventsB = generateSetOfEventsFromDestinationsAndSources({
              stream,
              date: dateB,
              amount: amountB,
            })
            events.push(...streamEventsA)
            streamEventsA.forEach((se) => insertEventToMap(se))

            events.push(...streamEventsB)
            streamEventsB.forEach((se) => insertEventToMap(se))
          }
        }
        break
      case FrequencyType.weekly:
        {
          const { day } = stream
          const nextEventDate = getNextDateOnDayOfWeekFollowing(day, startDate).toDate()

          if (isBefore(endDateOfRange, nextEventDate)) {
            break
          }

          for (let i = 0; i <= weeksOut; i++) {
            const date = add(nextEventDate, { weeks: i })
            if (isAfter(date, endDateOfRange)) {
              continue
            }

            const amount = getAmountForEvent(
              stream,
              date,
              paycheckCashContributionOverride,
              null,
              overallExpenseAdjustmentPercentage
            )

            const streamEvents = generateSetOfEventsFromDestinationsAndSources({
              stream,
              date,
              amount,
            })
            events.push(...streamEvents)
            streamEvents.forEach((se) => insertEventToMap(se))
          }
        }
        break
      case FrequencyType.quarterly:
        {
          const { nextDate } = stream
          const quartersOut = Math.floor(monthsOut / 3)
          let nextEventDate = dayjs(nextDate).toDate()

          const startDateDate = dayjs(startDate).toDate()

          while (isBefore(nextEventDate, startDateDate)) {
            nextEventDate = add(nextEventDate, { months: 3 })
          }

          if (isBefore(endDateOfRange, nextEventDate)) {
            break
          }

          for (let i = 0; i <= quartersOut; i++) {
            const date = add(nextEventDate, { months: 3 * i })

            if (isAfter(date, endDateOfRange)) {
              continue
            }
            const amount = getAmountForEvent(
              stream,
              date,
              paycheckCashContributionOverride,
              null,
              overallExpenseAdjustmentPercentage
            )

            const streamEvents = generateSetOfEventsFromDestinationsAndSources({
              stream,
              date,
              amount,
            })
            events.push(...streamEvents)
            streamEvents.forEach((se) => insertEventToMap(se))
          }
        }
        break
      case FrequencyType.semiannually:
        {
          const { nextDate } = stream
          const numberOfEvents = Math.floor(monthsOut / 6)

          const startDateDate = dayjs(startDate).toDate()
          let nextEventDate = dayjs(nextDate).toDate()

          while (isBefore(nextEventDate, startDateDate)) {
            nextEventDate = add(nextEventDate, { months: 6 })
          }

          if (isBefore(endDateOfRange, nextEventDate)) {
            break
          }

          for (let i = 0; i <= numberOfEvents; i++) {
            const date = add(nextEventDate, { months: 6 * i })
            if (isAfter(date, endDateOfRange)) {
              continue
            }

            const amount = getAmountForEvent(
              stream,
              date,
              paycheckCashContributionOverride,
              null,
              overallExpenseAdjustmentPercentage
            )

            const streamEvents = generateSetOfEventsFromDestinationsAndSources({
              stream,
              date,
              amount,
            })
            events.push(...streamEvents)
            streamEvents.forEach((se) => insertEventToMap(se))
          }
        }
        break
      case FrequencyType.anually:
        {
          const { nextDate } = stream

          const startDateDate = dayjs(startDate).toDate()
          const originalNextEventDate = dayjs(nextDate).toDate()
          let nextEventDate = dayjs(nextDate).toDate()

          while (isBefore(nextEventDate, startDateDate)) {
            nextEventDate = add(nextEventDate, { years: 1 })
          }

          if (isBefore(endDateOfRange, nextEventDate)) {
            break
          }

          const daysOut = differenceInDays(endDateOfRange, nextEventDate)
          const monthsOut = monthsOutGivenDays(daysOut)
          const numberOfEvents = Math.ceil(monthsOut / 12)

          while (isBefore(nextEventDate, originalNextEventDate)) {
            nextEventDate = add(nextEventDate, { weeks: 2 })
          }

          for (let i = 0; i <= numberOfEvents; i++) {
            const date = add(nextEventDate, { years: i })
            if (isAfter(date, endDateOfRange)) {
              continue
            }
            const amount = getAmountForEvent(
              stream,
              date,
              paycheckCashContributionOverride,
              null,
              overallExpenseAdjustmentPercentage
            )

            const streamEvents = generateSetOfEventsFromDestinationsAndSources({
              stream,
              date,
              amount,
            })
            events.push(...streamEvents)
            streamEvents.forEach((se) => insertEventToMap(se))
          }
        }
        break
      case FrequencyType.biweekly:
        {
          const { nextDate } = stream

          const originalNextEventDate = dayjs(nextDate).toDate()
          let nextEventDate = dayjs(nextDate).toDate()
          const startDateDate = dayjs(startDate).toDate()

          if (isBefore(endDateOfRange, nextEventDate)) {
            break
          }

          while (isBefore(nextEventDate, startDateDate)) {
            nextEventDate = add(nextEventDate, { weeks: 2 })
          }

          const daysOut = differenceInDays(endDateOfRange, nextEventDate)
          const weeksOut = weeksOutGivenDays(daysOut)
          const numberOfEvents = Math.ceil(weeksOut / 2)

          while (isBefore(nextEventDate, originalNextEventDate)) {
            nextEventDate = add(nextEventDate, { weeks: 2 })
          }

          for (let i = 0; i <= numberOfEvents; i++) {
            const date = add(nextEventDate, { weeks: 2 * i })
            if (isAfter(date, endDateOfRange)) {
              continue
            }
            const amount = getAmountForEvent(
              stream,
              date,
              paycheckCashContributionOverride,
              null,
              overallExpenseAdjustmentPercentage
            )

            const streamEvents = generateSetOfEventsFromDestinationsAndSources({
              stream,
              date,
              amount,
            })
            events.push(...streamEvents)
            streamEvents.forEach((se) => insertEventToMap(se))
          }
        }
        break
    }
  }

  if (flexibleSpendPerMonth !== 0) {
    const flexibleSpendPerMonthEvents: MoneyEvent[] = []
    const monthlyStartDate = startOfMonth(new Date())
    for (let i = 1; i <= monthsOut; i++) {
      const nextEventDate = add(monthlyStartDate, { months: i })
      const flexibleSpendPerMonthEvent: MoneyEvent = {
        name: 'Flexible Spend Per Month Average',
        amount: -flexibleSpendPerMonth * overallExpenseAdjustmentPercentage,
        type: 'bill',
        date: nextEventDate,
      }
      flexibleSpendPerMonthEvents.push(flexibleSpendPerMonthEvent)
      const dateString = formatDate(flexibleSpendPerMonthEvent.date)
      if (mapOfEvents[dateString]?.length) {
        mapOfEvents[dateString].push(flexibleSpendPerMonthEvent)
      } else {
        mapOfEvents[dateString] = [flexibleSpendPerMonthEvent]
      }
    }

    events.push(...flexibleSpendPerMonthEvents)
  }

  const result: StreamsToEventsReturnInterface = { events, mapOfEvents, cacheHitKey }

  streamsToEventsCache[cacheHitKey] = result

  const endOfFunction = performance.now()
  const overallDiff = endOfFunction - now
  console.debug(`streamsToEents took ${overallDiff}ms`)

  return result
}
