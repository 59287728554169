import { Icon, TextBold, TextSpan } from 'Components/DesignSystem/Typography'
import React from 'react'
import { SettingsItem } from '../components'

export const PrivacyPolicy: React.FC = () => {
  return (
    <SettingsItem allowedPostTrial>
      <div className="d-flex flex-column w-100 align-items-start">
        <div className="d-flex flex-row w-100 align-items-center">
          <a href="https://www.iubenda.com/privacy-policy/45091906" className="d-inline-flex">
            <Icon color="text" className="fas fa-lock" />
            <TextBold color="text">Privacy Policy</TextBold>
          </a>
        </div>
        <TextSpan size="xxs" color="texttransparent">
          The boring legal stuff
        </TextSpan>
      </div>
    </SettingsItem>
  )
}
