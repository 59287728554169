import { useLocalStorageSyncedState } from 'Hooks/useLocalStorageSyncedState'
import { useUIState } from 'Providers/UIStateProvider'
import {
  TransactionMetadata,
  useUserCustomSettings,
} from 'Providers/UserCustomSettingsProvider/UserCustomSettingsProvider'
import { format } from 'date-fns'
import { Transaction } from 'plaid'
import React from 'react'
import { ScrollingFull } from '../../../Components/DesignSystem/Layout/Containers/Containers'
import { StyledHr, TextSpan } from '../../../Components/DesignSystem/Typography'
import { useFinancialData } from '../../../Providers/FinancialDataProvider/FinancialDataProvider'
import { numberToCurrency } from '../../../Utilities/currencyFormater'
import {
  humanReadifyStringDateShortSansYear,
  stringToDate,
} from '../../../Utilities/dateUtilities/dateUtilities'
import { AdvancedTransactionRow } from '../AdvancedTransactionRow/AdvancedTransactionRow'
import { LegendPackedSlimTransactionRow } from '../AdvancedTransactionRow/AdvancedTransactionRowLegend'
import { ReflectionCard } from '../ReflectionReview/ReflectionCard/ReflectionCard'
import { AdvancedTransactionViewFilterPanel } from './AdvancedTransactionViewFilterPanel'
import { AdvancedTransactionViewHeader } from './AdvancedTransactionViewHeader'
import {
  FILTER_TYPE,
  SORTING_MODE,
  SORTING_ORDER,
  TIME_FRAME,
  sortFunctionGenerator,
  transactionTimeFilterFunction,
} from './helpers'

interface Props {
  onClose: () => void
  presetFilters?: {
    category: string
    type: FILTER_TYPE
    reflectionNeeded: boolean
  }
  resetPresetFilters?: () => void
}
interface TransactionDaySeparatorProps {
  t: Transaction
  transactions: Transaction[]
}

const TransactionDaySeparator: React.FC<TransactionDaySeparatorProps> = ({ t, transactions }) => {
  const monthHuman = format(stringToDate(t.date), 'MMM')

  const dayOfCurrentTransaction = t.date.split('-')[2]

  const transactionsInCurrentTransactionMonth = transactions.filter(
    (t) => t.date.split('-')[2] === dayOfCurrentTransaction
  )

  const currentTransactionIsFirstInMonth = transactionsInCurrentTransactionMonth.indexOf(t) === 0

  if (currentTransactionIsFirstInMonth) {
    return (
      <div className="w-100 d-flex align-items-center px-3">
        <StyledHr color="nearlyclear" weight={1} />
        <TextSpan
          className="mx-2 my-0"
          weight={200}
          color="textsupertransparent"
          size="xs"
          style={{
            minWidth: 'fit-content',
          }}>
          <TextSpan className="mr-1" weight={200}>
            {monthHuman}
          </TextSpan>{' '}
          {dayOfCurrentTransaction}
        </TextSpan>
        <StyledHr color="nearlyclear" weight={1} />
      </div>
    )
  }

  return null
}

const TransactionLetterSeparator: React.FC<TransactionDaySeparatorProps> = ({
  t,
  transactions,
}) => {
  const letterHuman = t.name[0]

  const transactionsInCurrentLetter = transactions.filter((t) => t.name[0] === letterHuman)

  const currentTransactionIsFirstInLetter = transactionsInCurrentLetter.indexOf(t) === 0

  if (currentTransactionIsFirstInLetter) {
    return (
      <div className="w-100 d-flex align-items-center px-3">
        <StyledHr color="nearlyclear" weight={1} />
        <TextSpan
          className="mx-2 my-0"
          weight={200}
          color="textsupertransparent"
          size="xs"
          style={{
            minWidth: 'fit-content',
          }}>
          {letterHuman}
        </TextSpan>
        <StyledHr color="nearlyclear" weight={1} />
      </div>
    )
  }

  return null
}

const TransactionAmountSeparator: React.FC<TransactionDaySeparatorProps> = ({
  t,
  transactions,
}) => {
  const amount = t.amount * -1
  const getRangeFromTransaction = (amount: number) => {
    switch (true) {
      case amount > ranges[0].min && amount < ranges[0].max:
        return ranges[0].human
      case amount > ranges[1].min && amount < ranges[1].max:
        return ranges[1].human
      case amount > ranges[2].min && amount < ranges[2].max:
        return ranges[2].human
      case amount > ranges[3].min && amount < ranges[3].max:
        return ranges[3].human
    }
  }
  const ranges = [
    {
      human: '< $50',
      min: -Infinity,
      max: 49.99,
    },
    {
      human: '$50 - $100',
      min: 50.0,
      max: 99.99,
    },
    {
      human: '$100 - $300',
      min: 100,
      max: 300,
    },
    {
      human: '$300+',
      min: 300,
      max: Infinity,
    },
  ]

  const transactionsInFirstQuarter = transactions.filter(
    (t) => amount > ranges[0].min && amount < ranges[0].max
  )
  const transactionsInSecondQuarter = transactions.filter(
    (t) => amount > ranges[1].min && amount < ranges[1].max
  )
  const transactionsInThirdQuarter = transactions.filter(
    (t) => amount > ranges[2].min && amount < ranges[2].max
  )
  const transactionsInFourthQuarter = transactions.filter(
    (t) => amount > ranges[3].min && amount < ranges[3].max
  )

  const currentTransactionIsFirstInFirstQuarter = transactionsInFirstQuarter.indexOf(t) === 0
  const currentTransactionIsFirstInSecondQuarter = transactionsInSecondQuarter.indexOf(t) === 0
  const currentTransactionIsFirstInThirdQuarter = transactionsInThirdQuarter.indexOf(t) === 0
  const currentTransactionIsFirstInFourthQuarter = transactionsInFourthQuarter.indexOf(t) === 0

  if (
    currentTransactionIsFirstInFirstQuarter ||
    currentTransactionIsFirstInSecondQuarter ||
    currentTransactionIsFirstInThirdQuarter ||
    currentTransactionIsFirstInFourthQuarter
  ) {
    return (
      <div className="w-100 d-flex align-items-center px-3">
        <StyledHr color="nearlyclear" weight={1} />
        <TextSpan
          className="mx-2 my-0"
          weight={200}
          color="textsupertransparent"
          size="xs"
          style={{
            minWidth: 'fit-content',
          }}>
          {getRangeFromTransaction(amount)}
        </TextSpan>
        <StyledHr color="nearlyclear" weight={1} />
      </div>
    )
  }

  return null
}

interface TransactionSeparatorProps {
  sortMode: SORTING_MODE
  t: Transaction
  ts: Transaction[]
}

const TransactionSeperator: React.FC<TransactionSeparatorProps> = ({ sortMode, t, ts }) => {
  switch (sortMode) {
    case 'amount':
      return <TransactionAmountSeparator t={t} transactions={ts} />
    case 'date':
      return <TransactionDaySeparator t={t} transactions={ts} />
    case 'name':
      return <TransactionLetterSeparator t={t} transactions={ts} />
    case 'bank':
      return null
  }
}

export const AdvancedTransactionView: React.FC<Props> = ({
  onClose,
  presetFilters,
  resetPresetFilters,
}) => {
  const {
    transactions,
    accountsData,
    transactionsData,
    getTransactions,
    flatAccounts,
  } = useFinancialData()
  const { settings } = useUserCustomSettings()
  const { setPopup } = useUIState()

  const [activeAccountIdFilter, setActiveAccountIdFilter] = useLocalStorageSyncedState<string>(
    '',
    'activeAccountIdFilter'
  )

  const [activeCategoryFilter, setActiveCategoryFilter] = useLocalStorageSyncedState<string>(
    '',
    'activeCategoryFilter'
  )

  const [activeTypeFilter, setActiveTypeFilter] = useLocalStorageSyncedState<FILTER_TYPE>(
    'both',
    'activeTypeFilter'
  )

  const [timeFrameFilter, setTimeFrameFilter] = useLocalStorageSyncedState<TIME_FRAME>(
    'thisMonth',
    'timeFrame'
  )

  const [reflectionNeededFilter, setReflectionNeededFilter] = useLocalStorageSyncedState<boolean>(
    true,
    'reflectionNeededFilter'
  )

  const [filterPanelOpen, setFilterPanelOpen] = useLocalStorageSyncedState<boolean>(
    false,
    'transaction-filter-panel-open'
  )

  const [sortingMode, setSortingMode] = useLocalStorageSyncedState<SORTING_MODE>(
    'date',
    'transaction-sort-mode'
  )
  const [sortingOrder, setSortingOrder] = useLocalStorageSyncedState<SORTING_ORDER>(
    1,
    'transaction-sort-order'
  )

  React.useEffect(() => {
    if (presetFilters) {
      if (presetFilters.category) setActiveCategoryFilter(presetFilters.category)
      if (presetFilters.reflectionNeeded) setReflectionNeededFilter(presetFilters.reflectionNeeded)
      setFilterPanelOpen(false)
    }
  }, [
    presetFilters,
    setTimeFrameFilter,
    setActiveCategoryFilter,
    setFilterPanelOpen,
    resetPresetFilters,
    setActiveTypeFilter,
    setReflectionNeededFilter,
  ])

  const _transactions = transactions.filter((t) => !t.pending)
  const pendingTransactions = transactions.filter((t) => t.pending) || []

  const accounts = accountsData?.data?.accounts

  const activeTransactions = [...pendingTransactions, ..._transactions]

  React.useEffect(() => {
    if (transactionsData.status === 'idle' && activeTransactions.length === 0) {
      getTransactions({})
    }
  }, [activeTransactions.length, getTransactions, transactionsData.status])

  const loading = transactionsData.status === 'loading'

  const activeFilteredTransactions = activeTransactions
    .sort(sortFunctionGenerator(sortingMode, sortingOrder, flatAccounts))
    .filter((t) => transactionTimeFilterFunction(t, timeFrameFilter))
    .filter((t) => {
      const transactionMetadata =
        settings?.transactionsMetadata?.[t.transaction_id] ||
        settings?.transactionsMetadata?.[t.pending_transaction_id || '']
      const reflectionNeeded =
        transactionMetadata?.calculationType === 'variable'
          ? !transactionMetadata?.envelopeCategory
          : transactionMetadata?.calculationType === 'fixed'
          ? !transactionMetadata.fixedStreamName
          : transactionMetadata?.calculationType === 'skip'
          ? false
          : true
      return reflectionNeededFilter ? reflectionNeeded : true
    })
    .filter((t) => (activeAccountIdFilter ? t.account_id === activeAccountIdFilter : true))
    .filter((t) => {
      const transactionMetadata =
        settings?.transactionsMetadata?.[t.transaction_id] ||
        settings?.transactionsMetadata?.[t.pending_transaction_id || '']
      const transactionEnvelope = transactionMetadata?.envelopeCategory
      const transactionBill = transactionMetadata?.fixedStreamName
      const skipped = transactionMetadata?.calculationType === 'skip'

      return activeCategoryFilter
        ? (transactionEnvelope === activeCategoryFilter ||
            transactionBill === activeCategoryFilter) &&
            !skipped
        : true
    })
    .filter((t) => {
      const transactionMetadata =
        settings?.transactionsMetadata?.[t.transaction_id] ||
        settings?.transactionsMetadata?.[t.pending_transaction_id || '']
      const transactionType = transactionMetadata?.calculationType

      return activeTypeFilter
        ? activeTypeFilter === 'both'
          ? true
          : transactionType === activeTypeFilter
        : true
    })

  const transactionsLength = activeFilteredTransactions.length
  const transactionsTotal = activeFilteredTransactions.reduce((acc, t) => acc + t.amount, 0)

  const onNameClick = React.useCallback(
    (t: Transaction, m: TransactionMetadata | undefined) => {
      const account_id = t.account_id
      const accountForTransaction = accounts
        ?.map((a) => a.accounts)
        ?.flat()
        ?.find((a) => a?.account_id === account_id)
      setPopup(
        <ReflectionCard
          location="solo"
          onBackButtonOnFirstCard={() => {}}
          key={t.transaction_id}
          a={accountForTransaction}
          t={t}
          currentIndex={0}
          totalTransactions={transactions.length}
          setCurrentIndex={() => {}}
          m={m as TransactionMetadata}
          style={undefined}
        />
      )
    },
    // eslint-disable-next-line
    [setPopup]
  )

  return (
    <div
      className="d-flex w-100 h-100 align-items-center justify-content-start flex-column"
      style={{
        opacity: loading ? 0.3 : 1,
        transition: 'opacity 0.3s ease-in-out',
      }}>
      <div className="d-flex w-100 align-items-center justify-content-between flex-column">
        <AdvancedTransactionViewHeader
          refreshTransactions={() => getTransactions({})}
          refreshTransactionsLoading={loading}
          onClose={onClose}
          selectedTransactionsLength={transactionsLength}
          selectedTransactionsTotal={transactionsTotal}
        />
        <AdvancedTransactionViewFilterPanel
          activeAccountIdFilter={activeAccountIdFilter}
          setActiveAccountIdFilter={setActiveAccountIdFilter}
          activeCategoryFilter={activeCategoryFilter}
          filterPanelOpen={filterPanelOpen}
          setFilterPanelOpen={setFilterPanelOpen}
          setActiveCategoryFilter={setActiveCategoryFilter}
          activeTypeFilter={activeTypeFilter}
          setActiveTypeFilter={setActiveTypeFilter}
          timeFrameFilter={timeFrameFilter}
          setTimeFrameFilter={setTimeFrameFilter}
          reflectionNeededFilter={reflectionNeededFilter}
          setReflectionNeededFilter={setReflectionNeededFilter}
        />
      </div>
      {activeTransactions.length === 0 && transactionsData.status === 'loading' ? (
        <TextSpan color="logoPrimary" className="px-4 mt-3 text-center" size="sm">
          Loading transactions...
        </TextSpan>
      ) : (
        <>
          <LegendPackedSlimTransactionRow
            onNameClick={function (): void {
              setSortingMode('name')
              setSortingOrder((order) => (order === 1 ? -1 : 1))
            }}
            onAmountClick={function (): void {
              setSortingMode('amount')
              setSortingOrder((order) => (order === 1 ? -1 : 1))
            }}
            onDateClick={function (): void {
              setSortingMode('date')
              setSortingOrder((order) => (order === 1 ? -1 : 1))
            }}
            onBankClick={function (): void {
              setSortingMode('bank')
              setSortingOrder((order) => (order === 1 ? -1 : 1))
            }}
            sortMode={sortingMode}
            sortOrder={sortingOrder}
          />
          <ScrollingFull cutout={filterPanelOpen ? 280 : 40} className="pb-2">
            {activeFilteredTransactions.map((transaction, index) => {
              const transactionMetadata =
                settings?.transactionsMetadata?.[transaction.transaction_id] ||
                settings?.transactionsMetadata?.[transaction.pending_transaction_id || '']
              const account_id = transaction.account_id
              const accountForTransaction = accounts
                ?.map((a) => a.accounts)
                ?.flat()
                ?.find((a) => a?.account_id === account_id)

              return (
                <>
                  <TransactionSeperator
                    t={transaction}
                    ts={activeFilteredTransactions}
                    sortMode={sortingMode}
                  />
                  <AdvancedTransactionRow
                    key={transaction.transaction_id}
                    account={accountForTransaction}
                    t={transaction}
                    transactionName={transaction.name}
                    // NOTE: We are negating the amount here because amounts moving OUT of an account are actually positive in Plaid
                    // To make this easier on the user, money coming out will be negative, money coming in will be positive
                    amount={numberToCurrency(transaction.amount * -1, true, true)}
                    date={humanReadifyStringDateShortSansYear(transaction.date)}
                    onNameClick={onNameClick}
                    onNumberClick={() => {}}
                    transactionMetadataViewMode={'notes'}
                    transactionMetadata={transactionMetadata}
                  />
                </>
              )
            })}
          </ScrollingFull>
        </>
      )}
      <StyledHr />
    </div>
  )
}
