import { SpringValue, animated } from '@react-spring/web'
import styled from 'styled-components/macro'
import { ColorType } from 'theme'

type ColorSetup = {
  fg: ColorType
  bg: ColorType
}

export const colorFromItemPodium = (priority: number): ColorSetup => {
  switch (priority) {
    case 0:
      return {
        fg: 'textsupertransparent',
        bg: 'background',
      }
    case 1:
      return {
        fg: 'textsupertransparent',
        bg: 'background',
      }
    case 2:
      return {
        fg: 'textsupertransparent',
        bg: 'background',
      }
    default:
      return {
        fg: 'textsupertransparent',
        bg: 'background',
      }
  }
}

export const DraggableListContainer = styled(animated.div)`
  position: absolute;
  width: 100%;
  height: 74px;
  transform-origin: 50% 50% 0px;
  border-radius: 5px;
  /* text-transform: uppercase; */

  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
`

interface ItemProps {
  priority: number
}
export const Item = styled.div<ItemProps>`
  position: absolute;

  margin: 0;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 0.5rem 1.5rem;

  min-height: 74px;
  max-height: 74px;

  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;

  border-bottom: solid 1px ${(props) => props.theme.colors.nearlyclear}; ;
`

export const ItemLeftFlex = styled(animated.div)<{ priority: SpringValue }>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`
