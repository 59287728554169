import styled from 'styled-components/macro'

export const SlidingPageContainer = styled.div`
  height: 100%;
  width: 100%;
  color: ${(props) => props.theme.colors.text};
  padding: 0rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0.75rem;
`

export const SlidingPageContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0.5rem;
  width: 100%;
  overflow-y: scroll;
  max-height: calc(100vh - 150px);
`
