import React from 'react'
import { Transition } from 'react-spring/renderprops'
import { defaultData, useUIState } from '../../Providers/UIStateProvider'
import { Icon, TextBold, TextSlim } from '../DesignSystem/Typography'
import { Content } from './PopupMessage.components'

export const PopupMessage: React.FC = () => {
  const {
    messagePopupContent,
    setMessagePopupContent,
    setMessagePopupColors,
    messagePopupColors,
  } = useUIState()
  const [title, ...msgs] = messagePopupContent.split('<>')

  // const firstClick = useRef<boolean>(true)

  // React.useEffect(() => {
  //   const clickClose = (event: MouseEvent) => {
  //     const specifiedElement = document.getElementById('popup-message')

  //     // @ts-ignore
  //     const isClickInside = specifiedElement?.contains(event?.target)
  //     if (!isClickInside && messagePopupContent && !firstClick.current) {
  //       console.log('outside click!!!!!!!!!')
  //       setMessagePopupContent('')
  //       firstClick.current = true
  //     } else {
  //       firstClick.current = false
  //     }
  //   }
  //   document.addEventListener('click', clickClose)

  //   return () => {
  //     document.removeEventListener('click', clickClose)
  //   }
  //   // eslint-disable-next-line
  // }, [messagePopupContent])

  const { fgColor, bgColor } = messagePopupColors
  return (
    <Transition
      items={!!messagePopupContent}
      from={{ opacity: 1, transform: 'translate3d(0,200%,0)' }}
      enter={{ opacity: 1, transform: 'translate3d(0,50%,0)' }}
      leave={{ opacity: 1, transform: 'translate3d(0,150%,0)' }}>
      {(show) =>
        show &&
        ((props) => (
          <Content
            id="popup-message"
            data-testid="floating-message-popup"
            style={props}
            fgColor={fgColor}
            bgColor={bgColor || 'background'}>
            <div
              data-testid="dismiss-popup-button"
              onClick={() => {
                setMessagePopupContent('')
                // firstClick.current = true
                setTimeout(() => {
                  setMessagePopupColors(defaultData.messagePopupColors)
                }, 600)
              }}
              className="d-flex align-items-center justify-content-around w-100">
              <TextBold color={fgColor} size="md" className="mt-3 mr-2">
                {title}
              </TextBold>
              <Icon
                size="xxl"
                color={fgColor}
                // style={{ position: 'absolute', right: '1.5rem', top: '.5rem' }}
                className="fas fa-chevron-down"
              />
            </div>
            <div className="d-flex flex-column px-4 msg-content">
              {msgs.map((msg) => (
                <TextSlim
                  key={msg}
                  color="textsupertransparent"
                  size="xs"
                  weight={200}
                  className="px-2">
                  {msg}
                </TextSlim>
              ))}
            </div>
          </Content>
        ))
      }
    </Transition>
  )
}
