import { useUIState } from 'Providers/UIStateProvider'
import { add } from 'date-fns'
import { SlidingPage, SlidingPageButton } from 'design-system/layout/SlidingPage/SlidingPage'
import React, { useCallback, useEffect } from 'react'
import { colorPalletes } from 'theme'
import { formatCurrency } from '../../../../Utilities'
import {
  dateToString,
  humanReadifyStringDateConcise,
} from '../../../../Utilities/dateUtilities/dateUtilities'
import { MoneyInputContainer } from '../../../StreamPage/AddEditStream/AddEditStream.components'
import { fireConfetti } from '../../Confetti'
import { WishlistItem } from '../../WishlistProvider/WishlistProvider'
import { DesireMeter } from '../DesireMeter/DesireMeter'
import { NewnessFactor } from '../NewnessFactor/NewnessFactor'
import { TimeFactor } from '../TimeFactor/TimeFactor'

interface NewWishlistPageProps {
  //   TODO: this is the type that comes from react-spring
  style: any
  itemToEdit: WishlistItem | null
  closePage: () => void
  deleteItem: (name: string) => Promise<void>
  refreshItems: () => Promise<void>
  saveItem: (item: WishlistItem) => Promise<void>
}
export const AddWishlistPage: React.FC<NewWishlistPageProps> = ({
  style,
  itemToEdit,
  closePage,
  saveItem,
  deleteItem,
  refreshItems,
}) => {
  const { activeTheme } = useUIState()
  const [name, setName] = React.useState<string>('')
  const [reason, setReason] = React.useState<string>('')
  const [price, _setPrice] = React.useState<string>('')
  const [desire, setDesire] = React.useState<number>(1)
  const [priority, setPriority] = React.useState<number>(1)
  const [timeFactor, setTimeFactor] = React.useState<boolean>(false)
  const [newnessFactor, setNewnessFactor] = React.useState<boolean>(false)
  const [reminderDate, setReminderDate] = React.useState<string>('')
  const [purchasedDate, setPurchasedDate] = React.useState<string>('')
  const [resistedDate, setResistedDate] = React.useState<string>('')
  const [date, setDate] = React.useState<string>('')

  const [addNewWishlistItemLoading, setAddNewWishlistItemLoading] = React.useState(false)
  const [deleteNewWishlistItemLoading, setDeleteNewWishlistItemLoading] = React.useState(false)
  // const [markAsPurchasedLoading, setMarkAsPurchasedLoading] = React.useState(false)
  // const [markAsResistedLoading, setMarkAsResistedLoading] = React.useState(false)

  const setPrice = useCallback((amount: string) => {
    const formattedValue = formatCurrency(amount, 10, 'positive')
    _setPrice(formattedValue)
  }, [])

  useEffect(() => {
    const today = new Date()
    const nextMonth = add(today, { days: 31 })
    const formatted = humanReadifyStringDateConcise(nextMonth)
    if (!itemToEdit?.reminderDate) {
      setReminderDate(formatted)
    } else {
      setReminderDate(itemToEdit.reminderDate)
    }
  }, [itemToEdit])

  useEffect(() => {
    if (itemToEdit) {
      setName(itemToEdit.name)
      _setPrice(((itemToEdit.price as unknown) as number).toLocaleString())
      setReason(itemToEdit.reason)
      setDesire(itemToEdit.desire)
      setTimeFactor(itemToEdit.timeFactor)
      setDate(itemToEdit.date || dateToString(new Date()))
      setPurchasedDate(itemToEdit.purchasedDate)
      setResistedDate(itemToEdit.resistedDate)
      const today = new Date()
      const nextMonth = add(today, { days: 31 })
      const formatted = humanReadifyStringDateConcise(nextMonth)
      if (!itemToEdit?.reminderDate) {
        setReminderDate(formatted)
      } else {
        setReminderDate(itemToEdit.reminderDate)
      }
      setPriority(itemToEdit.priority)
      setNewnessFactor(itemToEdit.newnessFactor)
    }
  }, [itemToEdit, setPrice, setReason, setName])

  const title = 'Add Desire'
  const subtitle =
    'Congrats on the new habit. Saying no to dopamine is hard, so we see you 👏 Tell us about this impulsive desire.'

  const secondaryButton: SlidingPageButton = {
    title: 'cancel',
    onClick: () => {
      closePage()
      setName('')
      setPrice('')
      setReason('')
    },
    icon: 'fas fa-times',
  }
  const primaryButton: SlidingPageButton = {
    title: addNewWishlistItemLoading ? 'saving...' : 'save',
    icon: itemToEdit?.name ? 'fas fa-check' : 'fas fa-plus',
    onClick: async () => {
      if (!name || !price || !reminderDate) {
        alert('Please fill out all fields!')
        return
      } else {
        try {
          setAddNewWishlistItemLoading(true)
          await saveItem({
            name,
            price,
            reminderDate,
            reason,
            desire,
            newnessFactor,
            timeFactor,
            priority,
            purchasedDate,
            resistedDate,
            date,
            _id: itemToEdit?._id,
          })

          fireConfetti({
            duration: 0.3,
            colors: [colorPalletes[activeTheme].text],
          })
          closePage()
          setName('')
          setPrice('')
          setReason('')
          setAddNewWishlistItemLoading(false)
        } catch (err) {
          setAddNewWishlistItemLoading(false)
          console.error('Error')
          console.error(err)
        }
      }
    },
  }

  const deleteButton: SlidingPageButton | undefined = itemToEdit?._id
    ? {
        title: deleteNewWishlistItemLoading ? 'deleting...' : 'delete',
        icon: 'fas fa-trash',
        onClick: async () => {
          setDeleteNewWishlistItemLoading(true)
          await deleteItem(itemToEdit?.name)
          closePage()
          setName('')
          setPrice('')
          setReason('')
          setDeleteNewWishlistItemLoading(false)
        },
      }
    : undefined

  return (
    <SlidingPage
      title={title}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      deleteButton={deleteButton}
      subtitle={subtitle}
      open={!!itemToEdit}
      content={
        <>
          <MoneyInputContainer icon="f02b" label="Impulse">
            <input
              data-testid="stream-name-input"
              value={name}
              placeholder="Mountain Bike"
              onChange={(e) => setName(e.currentTarget.value)}></input>
          </MoneyInputContainer>
          <MoneyInputContainer icon="f155" label="Price" style={{ minWidth: 'fit-content' }}>
            <input
              data-testid="stream-name-input"
              value={price}
              inputMode="decimal"
              placeholder={'5,000'}
              onChange={(e) => setPrice(e.currentTarget.value)}></input>
          </MoneyInputContainer>
          <DesireMeter readonly={false} desire={desire} setDesire={setDesire} />
          <TimeFactor readonly={false} timeFactor={timeFactor} setTimeFactor={setTimeFactor} />
          <NewnessFactor
            readonly={false}
            newnessFactor={newnessFactor}
            setNewnessFactor={setNewnessFactor}
          />
        </>
      }
    />
  )
}

//   return (
//       <div className="d-flex flex-column w-100 align-items-center">
//         <StyledHr className="my-2" weight={1} />

//         <TextSlim
//           style={{
//             opacity: itemToEdit?.name ? 0 : 1,
//           }}
//           className="my-2 text-center"
//           color="textsupertransparent"
//           weight={200}
//           size="xxs">
//           We'll remind you about this item in{' '}
//           <TextSpan weight={700} className="m-0" color="text">
//             30 days
//           </TextSpan>
//           . Well done!
//         </TextSlim>
//         <div className="d-flex align-items-center w-100 justify-content-between mt-2 flex-column">
//           <div className="d-flex align-items-center w-100 justify-content-around mb-3">
//             <IconButton
//               onClick={() => {
//                 closePage()
//                 setName('')
//                 setPrice('')
//                 setReason('')
//               }}
//               title={purchasedDate ? 'done' : 'cancel'}
//               color={'textsupertransparent'}
//               iconClassName={'fas fa-times'}
//             />
//             {itemToEdit?.name ? (
//               <IconButton
//                 disabled={deleteNewWishlistItemLoading}
//                 onClick={async () => {
//                   setDeleteNewWishlistItemLoading(true)
//                   await deleteItem(itemToEdit.name)
//                   closePage()
//                   setName('')
//                   setPrice('')
//                   setReason('')
//                   setDeleteNewWishlistItemLoading(false)
//                 }}
//                 title={deleteNewWishlistItemLoading ? 'deleting...' : 'delete'}
//                 color={'mehred'}
//                 iconClassName={'fas fa-trash'}
//               />
//             ) : null}
//             {purchasedDate ? (
//               <div className="d-flex flex-column">
//                 {itemToEdit?.name ? (
//                   <IconButton
//                     disabled={markAsPurchasedLoading}
//                     onClick={async () => {
//                       setMarkAsPurchasedLoading(true)
//                       await saveItem({
//                         name,
//                         price,
//                         reminderDate,
//                         reason,
//                         desire,
//                         newnessFactor,
//                         timeFactor,
//                         priority,
//                         purchasedDate: '',
//                         resistedDate: '',
//                         date,
//                         _id: itemToEdit?._id,
//                       })

//                       closePage()
//                       setName('')
//                       setPrice('')
//                       setReason('')
//                       setMarkAsPurchasedLoading(false)
//                     }}
//                     title={markAsPurchasedLoading ? 'unmarking...' : 'Unmark as purchased'}
//                     color={'orange'}
//                     iconClassName={'fas fa-sync'}
//                   />
//                 ) : null}
//                 <TextSpan size="xxs" color="orange">
//                   Purchased on: {humanReadifyStringDate(purchasedDate)}
//                 </TextSpan>
//               </div>
//             ) : resistedDate ? (
//               <div className="d-flex flex-column">
//                 {itemToEdit?.name ? (
//                   <IconButton
//                     disabled={markAsResistedLoading}
//                     onClick={async () => {
//                       setMarkAsResistedLoading(true)
//                       await saveItem({
//                         name,
//                         price,
//                         reminderDate,
//                         reason,
//                         desire,
//                         newnessFactor,
//                         timeFactor,
//                         priority,
//                         purchasedDate: '',
//                         resistedDate: '',
//                         date,
//                         _id: itemToEdit?._id,
//                       })

//                       closePage()
//                       setName('')
//                       setPrice('')
//                       setReason('')
//                       setMarkAsResistedLoading(false)
//                     }}
//                     title={markAsResistedLoading ? 'unmarking...' : 'Unmark as resisted'}
//                     color={'megapurple'}
//                     iconClassName={'fas fa-sync'}
//                   />
//                 ) : null}
//                 <TextSpan size="xxs" color="megapurple">
//                   Resisted on: {humanReadifyStringDate(resistedDate)}
//                 </TextSpan>
//               </div>
//             ) : (
//               <>
//                 <IconButton
//                   disabled={addNewWishlistItemLoading}
//                   onClick={async () => {
//                     if (!name || !price || !reminderDate) {
//                       alert('Please fill out all fields!')
//                       return
//                     } else {
//                       try {
//                         setAddNewWishlistItemLoading(true)
//                         await saveItem({
//                           name,
//                           price,
//                           reminderDate,
//                           reason,
//                           desire,
//                           newnessFactor,
//                           timeFactor,
//                           priority,
//                           purchasedDate,
//                           resistedDate,
//                           date,
//                           _id: itemToEdit?._id,
//                         })

//                         fireConfetti({
//                           duration: 0.3,
//                           colors: [colorPalletes[activeTheme].text],
//                         })
//                         closePage()
//                         setName('')
//                         setPrice('')
//                         setReason('')
//                         setAddNewWishlistItemLoading(false)
//                       } catch (err) {
//                         setAddNewWishlistItemLoading(false)
//                         console.error('Error')
//                         console.error(err)
//                       }
//                     }
//                   }}
//                   title={
//                     addNewWishlistItemLoading ? 'saving...' : itemToEdit?.name ? 'update' : 'add'
//                   }
//                   color={'text'}
//                   iconClassName={itemToEdit?.name ? 'fas fa-check' : 'fas fa-plus'}
//                 />
//               </>
//             )}
//           </div>

//           <div className="d-flex align-items-center w-100 justify-content-around">
//             {itemToEdit?.name && !purchasedDate && !resistedDate ? (
//               <IconButton
//                 disabled={markAsResistedLoading}
//                 onClick={async () => {
//                   if (!name || !price || !reminderDate) {
//                     alert('Please fill out all fields!')
//                     return
//                   } else {
//                     setMarkAsResistedLoading(true)
//                     await saveItem({
//                       name,
//                       price,
//                       reminderDate,
//                       reason,
//                       priority,
//                       desire,
//                       newnessFactor,
//                       timeFactor,
//                       resistedDate: dateToString(new Date()),
//                       purchasedDate,
//                       date,
//                       _id: itemToEdit?._id,
//                     })
//                     await refreshItems()

//                     fireConfetti({
//                       duration: 0.6,
//                       colors: [colorPalletes[activeTheme].megapurple],
//                     })
//                     closePage()
//                     setName('')
//                     setPrice('')
//                     setReason('')
//                     setMarkAsResistedLoading(false)
//                   }
//                 }}
//                 title={markAsResistedLoading ? 'marking...' : 'mark as resisted'}
//                 textSize="xxs"
//                 iconSize="xl"
//                 color={'megapurple'}
//                 iconClassName={IMPULSE_ICON}
//               />
//             ) : null}
//             {itemToEdit?.name && !purchasedDate && !resistedDate ? (
//               <IconButton
//                 disabled={markAsPurchasedLoading}
//                 onClick={async () => {
//                   if (!name || !price || !reminderDate) {
//                     alert('Please fill out all fields!')
//                     return
//                   } else {
//                     setMarkAsPurchasedLoading(true)
//                     await saveItem({
//                       name,
//                       price,
//                       reminderDate,
//                       reason,
//                       priority,
//                       desire,
//                       newnessFactor,
//                       timeFactor,
//                       purchasedDate: dateToString(new Date()),
//                       resistedDate,
//                       date,
//                       _id: itemToEdit?._id,
//                     })
//                     await refreshItems()
//                     closePage()
//                     setName('')
//                     setPrice('')
//                     setReason('')
//                     setMarkAsPurchasedLoading(false)
//                   }
//                 }}
//                 title={markAsPurchasedLoading ? 'marking...' : 'mark as purchased'}
//                 textSize="xxs"
//                 iconSize="xl"
//                 color={'orange'}
//                 iconClassName={'fas fa-shopping-cart'}
//               />
//             ) : null}
//           </div>
//         </div>
//       </div>
//     </AddWishlistItemDiv>
//   )
// }
