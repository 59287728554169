import { StyledVr } from 'Components/DesignSystem/Typography'
import { useFinancialData } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import { useUserCustomSettings } from 'Providers/UserCustomSettingsProvider/UserCustomSettingsProvider'
import { IconButton } from 'design-system/buttons/IconButton/IconButton'
import { AccountBase } from 'plaid'
import React from 'react'
import { AccountRowProps } from '../AccountRow/AccountRow'
import { AccountRowOverlayDiv } from '../AccountRow/AccountRowComponents'
import { TransitionedSlimRow } from '../CreditCardPage.components'

interface Props extends AccountRowProps {
  setSelectedAccount: (account: AccountBase | null) => void
}
export const AccountOptionsRow: React.FC<Props> = ({
  type,
  account,
  accountName,
  accountId,
  hidden,
  itemId,
  amount,
  openedRow,
  setOpenedRow,

  setSelectedAccount,
}) => {
  const {
    deleteAccountData,
    getAccounts,
    deleteManualAccountData,
    getManualAccounts,
  } = useFinancialData()

  const {
    saveAccountName,
    saveAccountSpendingLimit,
    saveAccountHidden,
    getSettings,
    settings,
  } = useUserCustomSettings()

  /**
   * linkedCreditAndDebitAccounts has the following shape
   *
   * {
   *  [debitAccountId1] : [creditAccount1, creditAccount2],
   *  [debitAccountId2] : [creditAccount3]
   * }
   */
  const connectedCreditCards =
    settings?.accountMetadata
      .map((accountMetadatum) => accountMetadatum.tetheredCreditCards)
      .flat() || []

  // const connectedDebitCards = Object.keys(linkedCreditAndDebitAccounts)

  const creditCardIsLinkedToCashAccount =
    type === 'Credit Card' &&
    connectedCreditCards.includes(account?.account_id || accountName || '')

  // const debitCardIsLinkedToSomeCreditCards =
  //   type === 'Cash' && connectedDebitCards.includes(account?.account_id || accountName || '')

  const namingAccount = false

  const saveLimit = async () => {
    const _value = window.prompt('Enter a spending limit for this account')

    let value = Number(_value)
    if (value < 0) {
      value = 0
    }
    if (isNaN(value)) {
      value = 0
    }

    await saveAccountSpendingLimit({
      account_id: accountId,
      spendingLimitPerMonth: value,
    })
    await getSettings()
    setOpenedRow(false)
  }

  const saveNickname = async () => {
    const answer = window.prompt('Enter a nickname for this account')
    await saveAccountName({
      account_id: accountId,
      name: answer || '',
    })
    await getSettings()
    setOpenedRow(false)
  }

  const deleteItem = async () => {
    if (itemId) {
      const answer = window.confirm('Are you sure you would like to delete this account?')
      if (answer) {
        await deleteAccountData?.issueRequest({
          queryParamsConfig: { item_id: itemId },
        })

        await getAccounts({})
        setOpenedRow(false)
      }
    } else if (accountName) {
      const answer = window.confirm('Are you sure you would like to delete this account?')
      if (answer) {
        await deleteManualAccountData?.issueRequest({
          bodyParamsConfig: { name: accountName },
        })
        await getManualAccounts({})
        setOpenedRow(false)
      }
    }
  }

  const skipAccount = async () => {
    if (hidden) {
      await saveAccountHidden({
        account_id: accountId,
        hidden: false,
      })
      await getSettings()
      setOpenedRow(false)
    } else {
      await saveAccountHidden({
        account_id: accountId,
        hidden: true,
      })
      await getSettings()
      setOpenedRow(false)
    }
  }

  const tetherAccount = async () => {
    if (creditCardIsLinkedToCashAccount) {
      setSelectedAccount(
        (account as AccountBase) || {
          amount: Number(amount),
          name: accountName,
          type,
        }
      )
    } else {
      setSelectedAccount(
        (account as AccountBase) || {
          amount: Number(amount),
          name: accountName,
          type,
        }
      )
    }

    setOpenedRow(false)
  }

  return (
    <TransitionedSlimRow
      minHeight={100}
      show={openedRow === accountId}
      close={() => setOpenedRow(false)}>
      <AccountRowOverlayDiv>
        <div
          className="d-flex flex-row align-items-center justify-content-around"
          style={{ flex: 1 }}>
          <IconButton
            onClick={() => saveNickname()}
            title={'nickname'}
            color={'text'}
            iconClassName={`fa fa-tag ${namingAccount ? 'fa-beat-fade' : ''}`}
            size={'small'}
          />
          <StyledVr />
          <IconButton
            onClick={() => saveLimit()}
            title={'limit'}
            color={'cashgreen'}
            iconClassName={'fa-solid fa-chart-mixed-up-circle-dollar '}
            size={'small'}
          />
          <StyledVr />
          <IconButton
            onClick={() => deleteItem()}
            title={'delete'}
            color={'mehred'}
            iconClassName={`fa fa-trash  ${
              deleteAccountData?.res.status === 'loading' ? 'fa-beat-fade' : ''
            }`}
            size={'small'}
          />

          <StyledVr />

          <IconButton
            onClick={() => skipAccount()}
            title={hidden ? 'enable' : 'disable'}
            color={'second'}
            iconClassName={`${!hidden ? 'fas fa-calculator' : 'fak fa-solid-calculator-slash'}  
              `}
            size={'small'}
          />

          {type === 'Credit Card' ? (
            <>
              <StyledVr />
              <IconButton
                onClick={() => tetherAccount()}
                title={creditCardIsLinkedToCashAccount ? 'untether' : 'tether'}
                color={'orange'}
                iconClassName="fa-duotone fa-link-horizontal px-2"
                size="small"
              />
            </>
          ) : null}
        </div>

        {/* <Icon
          onClick={() => {
            setOpenedRow(false)
          }}
          color={'textsupertransparent'}
          size="xxl"
          className={'far fa-chevron-circle-right ml-2'}
        /> */}
      </AccountRowOverlayDiv>
    </TransitionedSlimRow>
  )
}
