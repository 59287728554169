import { DataOrFeatureMissing } from 'Components/DataOrFeatureMissing/DataOrFeatureMissing'
import { ContainerDiv } from 'Components/DesignSystem/Layout/Containers/Containers'
import { Icon, StyledHr, TextPopupSmall, TextSlim } from 'Components/DesignSystem/Typography'
import { useLocalStorageSyncedState } from 'Hooks/useLocalStorageSyncedState'
import { TrajectoryChart } from 'Pages/CashFlowPage/Chart/Chart'
import { ProjectionSlider } from 'Pages/CashFlowPage/Chart/ProjectionSlider'
import { ViewRangeModes } from 'Pages/CashFlowPage/Chart/helpers'
import { useFinancialData } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import { useUIState } from 'Providers/UIStateProvider'
import { titleFromMysteryDayType } from 'Utilities/utils'
import { WideButton } from 'design-system/buttons/WideButton/WideButton'
import React from 'react'

export const CashFlowPage: React.FC = () => {
  const { setCashFlowPageOpen, setMessagePopupContent, setMessagePopupColors } = useUIState()

  const { mysteryMode } = useMysteryDay()

  const [viewRange, setViewRange] = useLocalStorageSyncedState<ViewRangeModes>(
    '3months',
    'cash-flow-chart-view-range-mode'
  )

  const title = titleFromMysteryDayType(mysteryMode)

  const { flatAccounts, flatManualAccounts } = useFinancialData()

  if (!flatAccounts.length && !flatManualAccounts.length) {
    return (
      <>
        <div onClick={() => setCashFlowPageOpen(false)} className="" style={{}}>
          <WideButton
            bgColor="text"
            active={true}
            appearance="primary"
            text=""
            textSize="small"
            width="fit">
            <Icon size="md" className="fal fa-arrow-right" />
          </WideButton>
        </div>
        <DataOrFeatureMissing
          title="Accounts Needed"
          body="To access your cash flow projections, you must add at least one type of account"
        />
      </>
    )
  }

  return (
    <div className="pb-3 d-flex flex-column justify-content-start align-items-start w-100 h-100">
      <ContainerDiv className="py-2">
        <div className="d-flex w-100 align-items-center pb-1">
          <TextSlim className="w-100" color="softblue" size="md">
            Cashflow
            <TextPopupSmall
              color={'softblue'}
              className="ml-1 px-2 py-1"
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                setMessagePopupColors({
                  fgColor: 'softblue',
                  bgColor: 'background',
                })
                setMessagePopupContent(
                  `Cash Flow<> Welcome to one of the more complicated yet powerful parts of the app!<>Here you can see how your cash is projected - not progress on your ${title} - to make sure you don't run out of money for day to day spending in the mean time. You can adjust your monthly contribution here and see if you'll still be able to afford upcoming bills and subscriptions. If you see a section of red where you cash is projected to run out, you can use the Calendar page to see exactly what upcoming expense will come up short`
                )
              }}>
              <Icon color={'softblue'} size="lg" className={'fas fa-circle-info'} />
            </TextPopupSmall>
          </TextSlim>
          <div onClick={() => setCashFlowPageOpen(false)} className="" style={{}}>
            <WideButton
              bgColor="text"
              active={true}
              appearance="primary"
              text=""
              textSize="small"
              width="fit">
              <Icon size="md" className="fal fa-arrow-right" />
            </WideButton>
          </div>
        </div>

        <StyledHr weight={1} color="softblue" style={{ opacity: 0.5 }} />
        <TrajectoryChart viewRange={viewRange} />
        <ProjectionSlider viewRange={viewRange} setViewRange={setViewRange} />
        {/* <SliderPanel /> */}

        {/* NOTE: 
        HA - have we found quite the challenge for ouselves

        Now that we have Account Allocation setup - we don't simply have a "paycheck.savingsContribution" value (
        which is what the salarysavingsslider was adjusting
        )
        instead now each income gets custom allocated to different accounts based on user input

        so, we cant have a single slider anymore for a users "savings contribution"
        
        */}
        {/* <div
          className="d-flex flex-column justify-content-end"
          style={{ flex: 1, minHeight: 'fit-content' }}>
          <SalarySavingsSlider
            slim
            perPaycheckValueMultiplier={perPaycheckValueMultiplier}
            onChange={(val: number) => {
              onSavingsChange(val / perPaycheckValueMultiplier)
            }}
          />
          <MonthlyExpenseSlider />
        </div> */}
      </ContainerDiv>
    </div>
  )
}
