import React from 'react'
import { config } from 'react-spring'
import { Transition } from 'react-spring/renderprops'
import { useUIState } from '../../Providers/UIStateProvider'
import { Icon, StyledHr, TextBold, TextSlim, TextSpan } from '../DesignSystem/Typography'
import { Content } from './ErrorMessage.components'
interface Props {}
export const ErrorMessage: React.FC<Props> = () => {
  const { errorPopupContent, setErrorPopupContent } = useUIState()
  const [title, ...msgs] = errorPopupContent.split('<>')
  return (
    <Transition
      items={!!errorPopupContent}
      config={config.stiff}
      from={{ opacity: 1, transform: 'translate3d(0,-100%,0)' }}
      enter={{ opacity: 1, transform: 'translate3d(0,0,0)' }}
      leave={{ opacity: 1, transform: 'translate3d(0,-110%,0)' }}>
      {(show) =>
        show &&
        ((props) => (
          <Content style={props}>
            <div
              onClick={() => {
                setErrorPopupContent('')
              }}
              className="d-flex align-items-center">
              <Icon
                size="xxl"
                color="text"
                style={{ position: 'absolute', right: '1.5rem', top: '3.5rem' }}
                className="fas fa-times"
              />
              <TextBold color="text" size="lg" className="mt-3">
                {title}{' '}
                {title && <Icon size="lg" color="text" className="far fa-exclamation-circle" />}
              </TextBold>
            </div>
            <div className="d-flex flex-column px-4 msg-content">
              <TextSlim color="text" className="px-4">
                That was embarassing.
              </TextSlim>
              <StyledHr color="textsupertransparent" className="my-2" weight={1} />
              <TextSlim color="text" className="px-4 ">
                {msgs.map((msg, index) => (
                  <TextSpan
                    key={msg}
                    color={index === msgs.length - 1 ? 'texttransparent' : 'text'}
                    style={{
                      maxWidth: '100vw',
                      overflowX: 'scroll',
                      overflowWrap: 'anywhere',
                    }}>
                    {msg}
                  </TextSpan>
                ))}
              </TextSlim>
            </div>
          </Content>
        ))
      }
    </Transition>
  )
}
