import React from 'react'
import { ColorType, FontSizeType } from 'theme'
import { Icon, TextSpan } from './DesignSystem/Typography'

interface Props {
  headerSize?: FontSizeType
  labelSize?: FontSizeType
  orientation?: 'horizontal' | 'vertical'
  iconSize?: FontSizeType
  itemAlignment?: 'center' | 'start'
  color?: ColorType
  extraText?: string
}
export const MCOLBadge: React.FC<Props> = ({
  headerSize = 'xs',
  labelSize = 'xxxs',
  iconSize = 'sm',
  itemAlignment = 'center',
  color = 'mehred',
  orientation = 'vertical',
  extraText,
}) => (
  <div
    className={`d-flex flex-${
      orientation === 'vertical' ? 'column' : 'row'
    } align-items-${itemAlignment} position-relative`}>
    <TextSpan size={headerSize} weight={800} className="" color={color} style={{}}>
      <Icon className="fas fa-flame mr-1" size={iconSize} color={color} />
      MCOL
    </TextSpan>
    <TextSpan
      size={labelSize}
      weight={400}
      color={color}
      style={{
        marginTop: orientation === 'vertical' ? '-.25rem' : 0,
        marginBottom: '0',
        marginLeft: orientation === 'horizontal' ? '.25rem' : 0,
      }}>
      Monthly Cost of Living {extraText}
    </TextSpan>
  </div>
)
