import { animated } from 'react-spring'
import styled from 'styled-components'

export const Card = styled(animated.div)`
  background-color: ${(props) => props.theme.colors.backgrounddark};
  width: 100%;
  height: 100%;
  min-width: calc(100vw - 2rem);
  border-radius: 20px;
  box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4), 0 10px 10px -10px rgba(50, 50, 73, 0.3);
`
