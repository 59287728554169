import { Icon, StyledVr, TextSpan } from 'Components/DesignSystem/Typography'
import { numberToCurrency } from 'Utilities/currencyFormater'
import { humanReadifyStringDate } from 'Utilities/dateUtilities/dateUtilities'
import { Transaction } from 'plaid'
import React from 'react'

interface Props {
  timeFrame: 'thisMonth' | 'lastMonth'
  t: Transaction | undefined
}
export const ExistingTransactionRow: React.FC<Props> = ({ timeFrame, t }) => {
  return (
    <div className="d-flex flex-row w-100 justify-content-start align-items-center">
      <Icon
        className={`fas fa-${t ? 'check-circle' : 'circle'} mx-2`}
        color={t ? 'mehred' : 'textsupertransparent'}
      />
      <TextSpan color="texttransparent" size="xs" className="mr-2">
        {timeFrame === 'thisMonth' ? 'This' : 'Last'} Month:{' '}
      </TextSpan>
      {t ? (
        <>
          <TextSpan size="xxxs" color="texttransparent">
            {' '}
            {t.name}
          </TextSpan>
          <StyledVr className="mx-1" color="nearlyclear" weight={1} size="xs" />
          <TextSpan size="xxxs" color="textsupertransparent">
            {' '}
            {humanReadifyStringDate(t.date)}
          </TextSpan>
          <StyledVr className="mx-1" color="nearlyclear" weight={1} size="xs" />
          <TextSpan size="xxxs" color="mehred">
            {' '}
            {numberToCurrency(t.amount)}
          </TextSpan>
        </>
      ) : null}
    </div>
  )
}
