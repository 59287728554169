import { StyledVr } from 'Components/DesignSystem/Typography'
import { useCategoryBudgetHelpers } from 'Pages/VariablePage/CategoryBudgetTable/helpers'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { colorPalletes } from 'theme'
import { useMysteryDay } from '../../../../Providers/MysteryDayProvider/MysteryDayProvider'
import { useUIState } from '../../../../Providers/UIStateProvider'
import { LinkType, iconFromLink, labelFromLink } from '../../../../Utilities/navigationTabUtilities'
import { LinkButton, LinkIcon, StyledFooter, StyledFooterContainer } from './components'

const links: LinkType[] = ['/mystery', '/spending', '/wishlist', '/fixed', '/variable']

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  introWalkThrough?: boolean
  style?: any
}

interface LinksContentProps {
  links: LinkType[]
  introWalkThrough?: boolean
}
const LinksContent: React.FC<LinksContentProps> = ({ links, introWalkThrough }) => {
  const { getUncategorizedData } = useCategoryBudgetHelpers()

  const { length } = getUncategorizedData()
  return (
    <>
      {links.map((link) => (
        <LinkButtonComponent
          notifications={link === '/variable' ? length : 0}
          key={link}
          link={link}
          introWalkThrough={introWalkThrough}
        />
      ))}
    </>
  )
}

const _Footer: React.FC<Props> = (props) => {
  return (
    <StyledFooterContainer
      introWalkThrough={props.introWalkThrough}
      {...props}
      {...(props?.style || {})}>
      <StyledFooter>
        <LinksContent links={links} introWalkThrough={props.introWalkThrough} />
      </StyledFooter>
    </StyledFooterContainer>
  )
}

const LinkButtonComponent = ({
  link,
  introWalkThrough,
  notifications,
}: {
  link: LinkType
  introWalkThrough?: boolean
  notifications: number
}) => {
  const { mysteryMode, onboardingDone, pagesUnlocked } = useMysteryDay()

  const { push } = useHistory()

  const { pathname } = useLocation()

  const { activeTheme } = useUIState()

  const nearlyClear = colorPalletes[activeTheme].nearlyclear

  const { setCashFlowPageOpen, setCalendarPageOpen } = useUIState()
  const linkButtonDisabled = !pagesUnlocked.includes(link) && !introWalkThrough

  const [linkButtonPressed, setLinkButtonPressed] = React.useState<boolean>(false)

  return (
    <>
      <LinkButton
        $notificationIcon="e237"
        data-testid={`nav-button-to-${labelFromLink(link, mysteryMode, onboardingDone)}`}
        onClick={(e) => {
          if (linkButtonDisabled) {
            if (introWalkThrough) {
              push(link)
              return
            }
          }
          if (link === '/fixed' && window.location.pathname === '/fixed') {
            setCashFlowPageOpen(false)
            setCalendarPageOpen(false)
          }
          push(link)
        }}
        key={link}
        mysterymode={mysteryMode}
        style={{
          backgroundColor: linkButtonPressed ? nearlyClear : 'transparent',
          transition: 'all ease-in-out .15s',
        }}
        disabled={linkButtonDisabled}
        $onboardingcomplete={onboardingDone}
        $notifications={notifications}
        activeClassName="link-active"
        onTouchStart={() => setLinkButtonPressed(true)}
        onTouchEnd={() => setLinkButtonPressed(false)}
        onTouchCancel={() => setLinkButtonPressed(false)}
        onDragLeave={() => setLinkButtonPressed(false)}
        to={link}
        link={link}>
        <LinkIcon
          className={iconFromLink(link, link === pathname, mysteryMode, onboardingDone)}
          style={
            {
              '--fa-beat-fade-opacity': 0.5,
              '--fa-beat-fade-scale': 1.25,
            } as React.CSSProperties
          }
        />
      </LinkButton>
      {link === '/wishlist' ? <StyledVr color="texttransparent" size="xl" /> : null}
    </>
  )
}

export const Footer = React.memo(_Footer)
