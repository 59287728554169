import { Icon, StyledHr, StyledVr, TextSpan } from 'Components/DesignSystem/Typography'
import logo from 'Logos/splurv_logo_transparent_1024.png'
import { humanReadifyStringDate } from 'Utilities/dateUtilities/dateUtilities'
import color from 'color'
import { WideButton } from 'design-system/buttons/WideButton/WideButton'
import { SubtleText } from 'design-system/typography/Text/Text'
import React from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { ColorType } from 'theme'

const EntryRow = styled.div<{ bgColor: ColorType }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 10px;
  width: 100%;
  padding: 16px 16px;
  background-color: ${(props) => props.theme.colors.backgrounddark};
  background: linear-gradient(
    -45deg,
    ${(props) => props.theme.colors.backgrounddark},
    ${(props) => color(props.theme.colors[props.bgColor]).darken(0.9).desaturate(0).toString()}
  );
  margin: 1rem 0;
`

interface ConfirmationEntryProps {
  price: number
  frequency: string
  priceColor: ColorType
  nextRenewalDate: Date
  manageMode: boolean
}
export const ConfirmationEntry: React.FC<ConfirmationEntryProps> = ({
  price,
  frequency,
  priceColor,
  nextRenewalDate,
  manageMode,
}) => {
  const { push } = useHistory()
  return (
    <EntryRow bgColor={priceColor}>
      <div className="d-flex flex-column align-items-start w-100">
        <div className="d-flex flex-row align-items-start w-100">
          <div className="d-flex flex-column align-items-start">
            <img alt="splurv-logo" src={logo} className="mr-2" style={{ maxWidth: '50px' }} />
          </div>

          <div className="d-flex flex-row align-items-center">
            <TextSpan size="xl" color={priceColor}>
              ${price}
            </TextSpan>
            <TextSpan size="xs" color="texttransparent" className="ml-1">
              per {frequency}
            </TextSpan>
            <StyledVr color="nearlyclear" className="mx-2" weight={1} />
            <Icon className={'fas fa-circle-check'} size="lg" color={priceColor} />
            <TextSpan size="xxs" color={priceColor} className="ml-1">
              purchased
            </TextSpan>
          </div>
        </div>
        <SubtleText className="mb-2">
          Upcoming Renewal:{' '}
          <TextSpan weight={700} color={priceColor}>
            {humanReadifyStringDate(nextRenewalDate)}
          </TextSpan>
        </SubtleText>
        {manageMode ? null : (
          <>
            <SubtleText className="mb-4">We're excited to have you! </SubtleText>
            <WideButton
              onClick={() => push('/spending')}
              text={'Go To App'}
              appearance="primary"
              bgColor={priceColor}
              active
              textSize="large"
              width="full"
            />
            <StyledHr color="nearlyclear" className="my-3" weight={1} />
            <WideButton
              onClick={() => push('/checkout')}
              text={'Manage Subscriptions'}
              appearance="primary"
              bgColor={'text'}
              active
              textSize="large"
              width="full"
            />
          </>
        )}
      </div>
    </EntryRow>
  )
}

interface SubscriptionEntryProps {
  price: number
  frequency: string
  priceColor: ColorType
  description: string
  onClick: Function
  ctaText?: string
}
export const SubscriptionEntry: React.FC<SubscriptionEntryProps> = ({
  price,
  frequency,
  priceColor,
  description,
  onClick,
  ctaText = 'subscribe',
}) => {
  return (
    <EntryRow bgColor={priceColor}>
      <div className="d-flex flex-column align-items-start">
        <div className="d-flex flex-row align-items-start">
          <div className="d-flex flex-column align-items-start">
            <img alt="splurv-logo" src={logo} className="mr-2" style={{ maxWidth: '50px' }} />
          </div>

          <div className="d-flex flex-row align-items-center">
            <TextSpan size="xl" color={priceColor}>
              ${price}
            </TextSpan>
            <TextSpan size="xs" color="texttransparent" className="ml-1">
              per {frequency}
            </TextSpan>
          </div>
        </div>
        <SubtleText className="mb-4">{description}</SubtleText>
        <WideButton
          onClick={() => onClick()}
          text={ctaText}
          appearance="primary"
          bgColor={priceColor}
          active
          textSize="large"
          width="full"
        />
      </div>
    </EntryRow>
  )
}
