/**
 *
 * @param amount The number we want to format into a pretty, human readable currency
 * @param round Should we round the amount to the nearest dollar?
 * @param includeLeadingSignSymbol Should we include a leading + or - sign?
 * @returns {string}
 */
export const numberToCurrency = (
  amount: number | null,
  _round: boolean = true,
  includeLeadingSignSymbol: boolean = false
): string => {
  if (amount === null) return ''
  const centsModeConfig = JSON.parse(localStorage.getItem('SPLURV_DATA__app-cents-mode') || 'false')
  const demoModeConfig = JSON.parse(localStorage.getItem('SPLURV_DATA__app-demo-mode') || 'false')
  if (demoModeConfig) {
    return '$000'
  }
  let round = _round
  if (centsModeConfig === true) {
    round = false
  }
  const curr = Number(amount)
  if (isNaN(curr)) return ''

  if (Math.sign(curr) >= 0) {
    if (round) {
      if (includeLeadingSignSymbol) {
        // rounding decimals + leading +
        return `$${Math.round(curr).toLocaleString()}`
      }
      // rounding decimals without leading +
      return `$${Math.round(curr).toLocaleString()}`
    }
    if (includeLeadingSignSymbol) {
      // not rounding + leading +
      return `$${curr.toLocaleString()}`
    }
    // not rounding without leading +
    return `$${curr.toLocaleString()}`
  }
  if (round) {
    if (includeLeadingSignSymbol) {
      // rounding decimals + leading -
      return `-$${Math.round(Math.abs(curr)).toLocaleString()}`
    }
    // rounding decimals without leading +
    return `$${Math.round(Math.abs(curr)).toLocaleString()}`
  }
  if (includeLeadingSignSymbol) {
    // not rounding + leading -
    return `-$${Math.abs(curr).toLocaleString()}`
  }
  // not rounding without leading -
  return `$${Math.abs(curr).toLocaleString()}`
}

/**
 * NOTE: Only used to format a users input into a currency input field
 *
 * This function is ONLY used when formatting actively typed in input by a user.
 * To create a better user experience when typing currency where the user can more easily
 * see the comma separator, the sign, etc, we need to do a lot of magic to convert the input
 * (which is always a string, it comes from html input) to a number, do validation, strip away
 * user entered commas, etc
 *
 * @param value This is the value actively being typed in by the user
 * @param maxLength Where do we want to limit typed input
 * @param sign Do we prepend a negative sign to the value
 * @returns {string}
 */
export const formatCurrency = (
  value: string,
  maxLength: number,
  sign?: 'positive' | 'negative'
): string => {
  if (typeof value !== 'string') return ''
  if (value === '' || !value.replace(/\s/g, '').length) {
    return ''
  }
  if (value === '.') {
    return value
  }

  if (/\.{2,}/.test(value)) {
    return value.split('.')[0] + '.'
    // return '.'
  }

  let valueWithoutCommasOrDollarSigns = value.replace(/,/g, '').replace(/\$/g, '')
  if (valueWithoutCommasOrDollarSigns === '') return ''
  if (valueWithoutCommasOrDollarSigns.length > maxLength) {
    valueWithoutCommasOrDollarSigns = valueWithoutCommasOrDollarSigns.slice(0, maxLength)
  }
  if (!/\d+\.*/.test(valueWithoutCommasOrDollarSigns)) return ''
  if (/\d+\.+/.test(valueWithoutCommasOrDollarSigns)) {
    if (valueWithoutCommasOrDollarSigns[valueWithoutCommasOrDollarSigns.length - 1] === '.') {
      const valueBeforeDecimal = valueWithoutCommasOrDollarSigns.split('.')[0]
      const decimal = valueWithoutCommasOrDollarSigns.split('.')[1]
      return Number(valueBeforeDecimal).toLocaleString() + '.' + decimal
      // return valueWithoutCommasOrDollarSigns
    }
    const valueBeforeDecimal = valueWithoutCommasOrDollarSigns.split('.')[0]
    const decimal = valueWithoutCommasOrDollarSigns.split('.')[1]

    if (decimal.length === 2) {
      return Number(valueBeforeDecimal).toLocaleString() + '.' + decimal
      // return parseFloat(valueWithoutCommasOrDollarSigns).toFixed(2)
    }
    if (decimal.length > 2) {
      return Number(valueBeforeDecimal).toLocaleString() + '.' + decimal.slice(0, 2)
    } else {
      return Number(valueBeforeDecimal).toLocaleString() + '.' + decimal
    }
  }
  if (Number.isNaN(Number(valueWithoutCommasOrDollarSigns))) return ''
  const decimal = valueWithoutCommasOrDollarSigns.split('.')[1]
  if (decimal === '0') {
    return valueWithoutCommasOrDollarSigns
  }

  let cleanValue = parseFloat(valueWithoutCommasOrDollarSigns)
  if (sign === 'negative') {
    cleanValue = cleanValue > 0 ? cleanValue * -1 : cleanValue
  }
  const valueWithCommas = cleanValue.toLocaleString()
  return '' + valueWithCommas
}

/**
 *
 * @param value This represents a human readable currency string from which we want
 * to extract the numerical value
 * @returns: {number}
 */
export const cleanCurrency = (value: string): number => {
  if (typeof value === 'number') return value
  const stringCleanOfCommas = value.replace(/,/g, '').replace(/\$/g, '')
  if (stringCleanOfCommas.includes('.')) {
    return parseFloat(stringCleanOfCommas) || 0
  } else {
    return Number(stringCleanOfCommas) || 0
  }
}
