/**
 * HOW THIS WORKS:
 * 
 * Plaid has a hiearchy of categories (in the form of an array) on each transaction
 * 
 * These categories can bet fetched from here
 * 
 * POST https://development.plaid.com/categories/get
 * 
 * and they look like this:
 * 
 *     [{
      "category_id": "12008008",
      "group": "place",
      "hierarchy": [
        "Community",
        "Education",
        "Computer Training"
      ]
    },...]
 * 
    THs indicates that a transaction could have a category such as: ['Community', 'Education', 'Computer Training']
    where 'Community' is the top most category, 'Education' is the next sub-category, and 'Computer Training' the next

    We then use `process_categories.ts` (a function we wrote) and `categories.ts` (a dump of the response from Plaid)
    to generate a map that looks like this
   
     {
         "Food and Drink": {
            "Bar": {
                "Wine Bar": 1,
                "Sports Bar": 1,
                "Hotel Lounge": 1
            },
            "Breweries": {},
            "Internet Cafes": {},
          },
          "Healthcare" : {},
          "Service" : {
             "Automotive": {}
          }
     },
     etc

     Which is a nested structure representing the Plaid categories and their sub-categories

     From this nested structure, we have chosen to recreate a similar structure representing
     which categories are fun and which are essential

     

 */

import { Transaction } from 'plaid'

interface CategoryBreakdownInterface {
  essential: string[]
  fun: string[]
  mixed: {
    fun: { [index: string]: string[] }
    essential: { [index: string]: string[] }
  }
}

const BreakDown: CategoryBreakdownInterface = {
  // Top level Plaid categories that are to be deemed essential.
  // Anything not in this list is deemed fun UNLESS it is found below in the mix.essential entry
  essential: ['Bank Fees', 'Cash Advance', 'Community', 'Healthcare', 'Interest', 'Payment', 'Tax'],

  // Top level Plaid categories that are to be deemed fun
  // Anything not in this list is deemed essential UNLESS it is found below in the mix.fun entry
  fun: ['Food and Drink', 'Recreation'],

  mixed: {
    essential: {
      // Anything transaction with a Transfer category is essential UNLESS it has a sub category of 'Third Party'
      Transfer: ['Third Party'],
    },
    fun: {
      // Any transacton with a Travel category is fun UNLESS it has a sub category of 'Gas Stations'
      Travel: ['Gas Stations'],

      // Any transacton with a Shops category is fun UNLESS it has a sub category of one of these four
      Shops: [
        'Pharmacies',
        'Supermarkets and Groceries',
        'Warehouses and Wholesale Stores',
        'Car Dealers and Leasing',
      ],
      // Meaning, 'Service' category is all fun except for these which are important
      Service: [
        'Automotive',
        'Cable',
        'Financial',
        'Funeral Services',
        'Internet Services',
        'Utilities',
        'Insurance',
      ],
    },
  },
}

const getTypeOfCategory = (categories: Transaction['category']) => {
  if (!categories) {
    return 'fun'
  }
  const categoryIsPartOfTopLevelFunCategories = BreakDown.fun.includes(categories[0])
  const categoryIsPartOfMixedFunCategories = Object.keys(BreakDown.mixed.fun).includes(
    categories[0]
  )
  const categoryIsPartOfMixedFunCategoriesExceptionList = BreakDown.mixed.fun[
    categories[0]
  ]?.includes(categories[1])

  const categoryIsPartOfMixedEssentialCategoriesExceptionList = Object.values(
    BreakDown.mixed.essential
  )
    .flat()
    .includes(categories[1])

  const isFunTransaction =
    categoryIsPartOfTopLevelFunCategories ||
    (categoryIsPartOfMixedFunCategories && !categoryIsPartOfMixedFunCategoriesExceptionList) ||
    categoryIsPartOfMixedEssentialCategoriesExceptionList

  if (isFunTransaction) return 'fun'
  else return 'important'
}

export const getCategoryOfTransaction = (transaction: Transaction) => {
  return getTypeOfCategory(transaction.category)
}
