import { AccountMaskAndLogoDisplay } from 'Components/AccountMaskAndLogo'
import { ScrollingFull } from 'Components/DesignSystem/Layout/Containers/Containers'
import { StyledHr, StyledVr, TextSlim, TextSpan } from 'Components/DesignSystem/Typography'
import { ScrollingItemPickerDisplay } from 'Pages/SpendingPage/DecorateTransactionsPopup/Assigners/Helpers'
import { useUserConfig } from 'Providers/UserConfigProvider/UserConfigProvider'
import { CalculationType } from 'Providers/UserCustomSettingsProvider/UserCustomSettingsProvider'
import { dateToString } from 'Utilities/dateUtilities/dateUtilities'
import { sub } from 'date-fns'
import { IconButton } from 'design-system/buttons/IconButton/IconButton'
import React from 'react'
import { TransactionSpendingTypeDisplay } from '../ReflectionCard/TransactionSpendingTypeAssigner'
import { TransactionSummaryDisplay } from '../ReflectionCard/TransactionSummary'
import { Card, CardBody, Footer } from './ReflectionCardIntro.components'
import { discoverPng } from './discoverPngSource'

interface Props {
  style: any
  onDismiss: () => void
}
export const ReflectionCardIntro: React.FC<Props> = ({ style, onDismiss }) => {
  const { skipReflectionIntro, setSkipReflectionIntro } = useUserConfig()
  const [calculationType, setCalculationType] = React.useState<CalculationType>('variable')
  return (
    <Card style={style}>
      <CardBody className="py-3 px-4">
        <TextSlim size="xs" color="texttransparent" className="text-center">
          Each day you will get to reflect on your spending through{' '}
          <TextSpan weight={600} color="logoPrimary">
            Reflection Cards.
          </TextSpan>{' '}
          They look like this
        </TextSlim>

        <ScrollingFull cutout={72 + 78}>
          <TextSlim size="xs" color="texttransparent" className="text-center my-0">
            <TextSpan color="text" weight={600}>
              Overview
            </TextSpan>{' '}
          </TextSlim>
          <StyledHr className="my-2" color="nearlyclear" />
          <TransactionSummaryDisplay
            skip={calculationType === 'skip'}
            name="Sample Transaction"
            logoUrl={null}
            nickname="Groceries"
            amount={42}
            category="Shops, Supermarkets and Groceries"
            color={
              calculationType === 'variable'
                ? 'yellow'
                : calculationType === 'fixed'
                ? 'mehred'
                : 'text'
            }
            authorizedDate={dateToString(sub(new Date(), { days: 3 }))}
            date={dateToString(new Date())}
            mask={
              <AccountMaskAndLogoDisplay
                bankLogoSize={20}
                mask="4444"
                bankLogoSrc={discoverPng}
                orientation="horizontal"
                institutionColor="orange"
                maskSize="xxs"
              />
            }
          />
          <TextSlim size="xs" color="texttransparent" className="text-center my-0">
            <TextSpan color="text" weight={600} className="my-0">
              Spending Type Toggle
            </TextSpan>
          </TextSlim>
          <div className="d-flex flex-row align-items-center">
            <TransactionSpendingTypeDisplay
              calculationType={calculationType}
              setCalculationType={setCalculationType}
            />
            <StyledVr color="nearlyclear" size="xxl" className="ml-3 mr-4" />
            <TextSpan color="texttransparent" size="xxs" className="ml-0 d-inline">
              Mark as{' '}
              <TextSpan
                className="my-0"
                color="yellow"
                weight={calculationType === 'variable' ? 700 : 200}>
                Variable
              </TextSpan>{' '}
              if every day spending, as{' '}
              <TextSpan
                color="mehred"
                className="my-0"
                weight={calculationType === 'fixed' ? 700 : 200}>
                Fixed
              </TextSpan>{' '}
              if recurring bill, and as{' '}
              <TextSpan
                className="my-0"
                color="text"
                weight={calculationType === 'skip' ? 700 : 200}>
                Skip
              </TextSpan>{' '}
              to ignore
            </TextSpan>
          </div>
          <StyledHr className="my-2" color="nearlyclear" />
          {calculationType !== 'skip' ? (
            <>
              <TextSlim size="xs" color="texttransparent" className="text-center">
                <TextSpan color="text" weight={600}>
                  Envelope / Bill Assignment
                </TextSpan>
              </TextSlim>
              <div className="d-flex flex-row align-items-center">
                <div style={{ flex: 1 }}>
                  <ScrollingItemPickerDisplay
                    containerHeight={0}
                    slim={true}
                    activeColor={calculationType === 'variable' ? 'yellow' : 'mehred'}
                    title={calculationType === 'variable' ? 'Envelope' : 'Bill'}
                    onCreateNewItemClick={() => {}}
                    items={
                      calculationType === 'variable'
                        ? [
                            {
                              title: 'Envelope 1',
                              activeColor: 'yellow',
                              isActive: false,
                              onClick: () => {},
                              metaData: '',
                            },
                            {
                              title: 'Envelope 2',
                              activeColor: 'yellow',
                              isActive: true,
                              onClick: () => {},
                              metaData: '',
                            },
                            {
                              title: 'Envelope 3',
                              activeColor: 'yellow',
                              isActive: false,
                              onClick: () => {},
                              metaData: '',
                            },
                          ]
                        : [
                            {
                              title: 'Bill 1',
                              activeColor: 'mehred',
                              isActive: false,
                              onClick: () => {},
                              metaData: '',
                            },
                            {
                              title: 'Bill 2',
                              activeColor: 'mehred',
                              isActive: false,
                              onClick: () => {},
                              metaData: '',
                            },
                            {
                              title: 'Bill 3',
                              activeColor: 'mehred',
                              isActive: true,
                              onClick: () => {},
                              metaData: '',
                            },
                          ]
                    }
                  />
                </div>
                <StyledVr color="nearlyclear" size="xxl" className="ml-3 mr-4" />
                <TextSpan color="texttransparent" size="xxs" className="ml-0 d-inline">
                  Categorize your spending every day to make sure you are aware of where your money
                  is going
                </TextSpan>
              </div>
            </>
          ) : (
            <TextSpan className="px-2" color="textsupertransparent" size="xs">
              When you skip transactions, we don't ask you details about them. This is useful for
              transactions like paycheck deposits and bank transfers that don't require further
              analysis
            </TextSpan>
          )}

          <StyledHr className="my-2" color="nearlyclear" />
          <TextSlim size="xs" color="texttransparent" className="text-center my-0">
            <TextSpan color="text" weight={600} className="my-0">
              Notes
            </TextSpan>
          </TextSlim>
          <div className="d-flex flex-column align-items-center">
            <TextSpan className="px-2" color="textsupertransparent" size="xs">
              You will have an optional notes field for recording things like:
            </TextSpan>
            <TextSpan className="px-2" color="textsupertransparent" size="xs">
              <ul className="pl-1">
                <li>Did this bring me joy?</li>
                <li>Was this an impulse buy?</li>
                <li>Is it justified by some life circumstance?</li>
              </ul>
            </TextSpan>
          </div>
        </ScrollingFull>
      </CardBody>

      <Footer className="px-3">
        <StyledHr className="my-2" color="nearlyclear" />
        <div className="d-flex flex-row w-100 align-items-center justify-content-between py-3">
          <IconButton
            title="Skip intro next time"
            onClick={() => {
              setSkipReflectionIntro((val) => !val)
            }}
            buttonClassName=""
            iconClassName={skipReflectionIntro ? 'fas fa-check-circle' : 'fal fa-circle'}
            color="text"
          />
          {/* <TextSpan color="textsupertransparent" size="xxs" className="p-2 text-center">
            Use these cards to reflect daily and gain a deeper understanding into your habits
          </TextSpan> */}
          <IconButton
            title="Start"
            onClick={() => {
              onDismiss()
            }}
            buttonClassName="ml-3"
            iconClassName="fas fa-chevron-circle-right"
            color="logoPrimary"
          />
        </div>
      </Footer>
    </Card>
  )
}
