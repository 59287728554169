import {
  add,
  differenceInDays,
  isAfter,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  sub,
} from 'date-fns'
import dayjs from 'dayjs'
import { Transaction } from 'plaid'
import { DAYS_PER_MONTH, WEEKS_PER_MONTH } from '../constants'
import { Account } from '../interfaces'
import { getCreditCardSpend } from './creditCardSpending'
import { CreditCardGroupingEnum } from './interfaces'

/**
 *
 * @param breakdownType: The method with which we are grouping the credit card transactions
 * and thus averaging to get an estimated monthly average
 *
 * today = x 30.5 days per month
 *
 * week = x 4.2 weeks per month
 *
 * year = / 12 months per year, etc
 *
 * options: today | thisWeek | thisMonth | thisStatement| lastTwoStatements | lastYear | alltime
 *
 * @param total The total spend within the above group that we are going to be averaging out
 * per month
 * @returns
 */
export const calculateMonthlyAverageGivenBreakdownType = (
  breakdownType: CreditCardGroupingEnum,
  total: number,
  oldestTransactionDate?: string // YYYY-MM-DD
) => {
  switch (breakdownType) {
    // See what spending [total] every day would be per month
    case CreditCardGroupingEnum.today:
      return total * DAYS_PER_MONTH

    //   See what spending [total] every week would be per month
    case CreditCardGroupingEnum.thisWeek:
      return total * WEEKS_PER_MONTH

    case CreditCardGroupingEnum.lastMonth:
      return total
    case CreditCardGroupingEnum.thisMonth:
      const daysSoFarThisMonth = differenceInDays(new Date(), startOfMonth(new Date())) || 1
      const multiplier = DAYS_PER_MONTH / daysSoFarThisMonth
      return total * multiplier
    case CreditCardGroupingEnum.thisStatement:
      return total

    // See what spending [total] every year comes to to per month
    case CreditCardGroupingEnum.thisYear:
      const monthsSoFarThisYear =
        differenceInDays(new Date(), startOfYear(new Date())) / DAYS_PER_MONTH
      return total / monthsSoFarThisYear
    case CreditCardGroupingEnum.lastYear:
      return total / 12

    // See what spending [total] every two statements comes out to per month
    case CreditCardGroupingEnum.lastTwoStatements:
      return total / 2

    // TODO: Currently the API returns data for the last 3 years so "alltime" is just 3 years
    //   However, this can be made way more accurate by factoring in the beginning of the cards spending
    case CreditCardGroupingEnum.alltime:
      if (oldestTransactionDate) {
        const alltimeDifferenceInDays = differenceInDays(
          new Date(),
          dayjs(oldestTransactionDate).toDate()
        )
        return total / (alltimeDifferenceInDays / DAYS_PER_MONTH)
        // const alltimeDifferenceInMonths = differenceInMonths(
        //   new Date(),
        //   dayjs(oldestTransactionDate).toDate()
        // )
        // return total / alltimeDifferenceInMonths
      }
      return total / 36
  }
}

/**
 * Given a Plaid transaction, determine if it should be counted towards spending
 *
 * This method is used in a few places. It is used in:
 *
 * getTransactionSpendingTotal: given a set of transactions, group those transactions (and total them) based on a series of time ranges.
 * this method is used in two spots:
 *      1.) getGlobalCreditCardSpend: given a set of transactions, group those transactions (and total them) based on a series of time ranges + some old credit card logic
 *      2.) getCreditCardSpend: used to see how much is spent on a credit card for current and future statement events.
 *       IMPORTANT. This is tested and covered
 *
 * @param t
 * @returns
 */
export const transactionCountedTowardsSpending = (t: Transaction): boolean => {
  // return true

  // NOTE: We are adding a by-pass here to return fine grained control back to the user
  // on what transactions are to be skipped and not skipped
  const isPaymentTransaction = transactionCountedTowardsPaymentsMade(t)
  const isTransferTransaction = transactionCountedTowardsInternalTransfers(t)
  return !isPaymentTransaction && !isTransferTransaction
}

export const transactionCountedTowardsIncome = (t: Transaction): boolean => {
  const isIncomeTransaction = t && t.amount && t.amount < 0
  return !!isIncomeTransaction
}

/**
 * Given a Plaid transaction, determine if it should be counted towards payments made
 * Payments made is used to calculate a more accurate future statement prediction
 * @param t
 * @returns
 */
export const transactionCountedTowardsPaymentsMade = (t: Transaction): boolean => {
  const isPaymentTransaction =
    t.category?.includes('BankFees') ||
    (t.category?.includes('Transfer') && t.category.includes('Credit')) ||
    (t.category?.includes('Transfer') && t.category.includes('Payroll')) ||
    (t.category?.includes('Transfer') && t.category.includes('Deposit')) ||
    (t.category?.includes('Service') && t.category.includes('Electric')) ||
    (t.category?.includes('Service') && t.category.includes('Financial')) ||
    (t.category?.includes('Payment') && t.category.includes('Credit Card'))

  const isBillpay = t.category?.includes('Billpay')

  if (isBillpay) {
    return false
  }

  if (isPaymentTransaction) {
    return true
  }

  return false
}

export const transactionCountedTowardsInternalTransfers = (t: Transaction): boolean => {
  const isInternalTransfer =
    (t.category?.includes('Transfer') && t.category.includes('Wire')) ||
    (t.category?.includes('Transfer') && t.category.includes('Withdrawal')) ||
    (t.category?.includes('Transfer') && t.category.includes('Debit')) ||
    // NOTE: Need to not include this for tests to pass but removing venmo for now to not count my current rent payments
    (t.category?.includes('Transfer') &&
      t.category.includes('Third Party') &&
      t.category.includes('Venmo'))

  return !!isInternalTransfer
}

/**
 * This method is used as part of calculating the current statement balance and future statement balance
 * @param card
 * @returns
 */
//  TRACKME: PROJECTED STATEMENT CALCULATION
export const getCurrentPaymentsMade = (card: Account) => {
  return Math.abs(
    card.transactions
      .filter((t) => {
        const transactionDate = dayjs(t.date).toDate()
        const lastStatementIssueDate = add(dayjs(card.lastStatementIssueDate).toDate(), { days: 1 })
        return isAfter(transactionDate, lastStatementIssueDate)
      })
      .filter(transactionCountedTowardsPaymentsMade)
      .reduce((total, transaction) => total + transaction.amount, 0)
  )
}

export const getPendingSpendingTotal = (card: Account) => {
  return Math.abs(
    card.transactions
      .filter((t) => {
        const transactionDate = dayjs(t.date).toDate()
        const lastStatementIssueDate = add(dayjs(card.lastStatementIssueDate).toDate(), { days: 1 })
        return isAfter(transactionDate, lastStatementIssueDate)
      })
      .filter((t) => t.pending)
      .reduce((total, transaction) => total + transaction.amount, 0)
  )
}

export const getCurrentPendingTotal = (card: Account) => {
  return Math.abs(
    card.transactions
      .filter((t) => {
        const transactionDate = dayjs(t.date).toDate()
        const lastStatementIssueDate = add(dayjs(card.lastStatementIssueDate).toDate(), { days: 1 })
        return isAfter(transactionDate, lastStatementIssueDate)
      })
      .filter(transactionCountedTowardsPaymentsMade)
      .reduce((total, transaction) => total + transaction.amount, 0)
  )
}

/**
 *TRACKME:
 * @param card
 * @returns
 */

//  TRACKME: PROJECTED STATEMENT CALCULATION
export const GetProjectedStatement = (card: Account) => {
  // This previous balance is a direct number we get from the underlying Liability from Plaid
  const previousBalance = card.statementBalance

  // How much has been spent in this statement is a custom calculation that we do
  const currentStatementSpending = getCreditCardSpend(card).thisStatement.total
  const currentPaymentsMade = getCurrentPaymentsMade(card)

  let projectedStatement = Math.abs(
    currentStatementSpending * -1 + Math.abs(currentPaymentsMade) + -1 * previousBalance
  )
  if (currentPaymentsMade === 0) {
    projectedStatement = projectedStatement - previousBalance
  }
  return projectedStatement
}

export const getTotalPendingAmount = (card: Account) => {
  const previousBalance = card.statementBalance

  const currentStatementSpending = getCreditCardSpend(card).thisStatement.total
  const currentPaymentsMade = getCurrentPaymentsMade(card)

  let projectedStatement = Math.abs(
    currentStatementSpending * -1 + Math.abs(currentPaymentsMade) + -1 * previousBalance
  )
  if (currentPaymentsMade === 0) {
    projectedStatement = projectedStatement - previousBalance
  }
  return projectedStatement
}

export const getStartDateGivenAverageMethod = (
  method: CreditCardGroupingEnum,
  lastStatementIssueDate: string,
  oldestTransactionDate: string
): Date => {
  switch (method) {
    case CreditCardGroupingEnum.today:
      return new Date()
    case CreditCardGroupingEnum.thisWeek:
      return startOfDay(startOfWeek(new Date()))
    case CreditCardGroupingEnum.thisMonth:
      return startOfDay(startOfMonth(new Date()))
    case CreditCardGroupingEnum.lastMonth:
      return startOfDay(sub(startOfMonth(new Date()), { months: 1 }))
    case CreditCardGroupingEnum.thisYear:
      return startOfYear(new Date())
    case CreditCardGroupingEnum.lastYear:
      return sub(startOfYear(new Date()), { years: 1 })
    case CreditCardGroupingEnum.alltime:
      if (oldestTransactionDate) {
        return dayjs(oldestTransactionDate).toDate()
      }
      return sub(new Date(), { years: 3 })
    case CreditCardGroupingEnum.lastTwoStatements:
      const lastStatementIssueDateDate = sub(startOfDay(dayjs(lastStatementIssueDate).toDate()), {
        months: 1,
      })
      return lastStatementIssueDateDate
    case CreditCardGroupingEnum.thisStatement:
      const currentStatementIssueDateDate = startOfDay(dayjs(lastStatementIssueDate).toDate())
      return currentStatementIssueDateDate
  }
}
