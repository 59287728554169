import { AccountMaskAndLogo } from 'Components/AccountMaskAndLogo'
import { Icon, TextSpan } from 'Components/DesignSystem/Typography'
import { getItemIdForAccountId } from 'Pages/CreditCardPage/dataHelpers'
import { useFinancialData } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import {
  getAccountTypeFromTypeSubtypeTuple,
  getCashAccounts,
  getColorFromAccountType,
  getCreditCardAccounts,
  getIconFromAccountType,
} from 'Providers/FinancialDataProvider/helpers'
import { numberToCurrency } from 'Utilities/currencyFormater'
import React from 'react'

interface AccountPreviewSelectionProps {
  activelySelectedAccount: {
    accountId: string
    itemId: string
  } | null
  setActivelySelectedAccount: React.Dispatch<
    React.SetStateAction<{
      accountId: string
      itemId: string
    } | null>
  >
}
export const AccountPreviewSelection: React.FC<AccountPreviewSelectionProps> = ({
  activelySelectedAccount,
  setActivelySelectedAccount,
}) => {
  const { accountsData, flatAccounts } = useFinancialData()

  // const { linkedAccount, setLinkedAccount } = useMysteryDay()

  const creditCardAccounts = getCreditCardAccounts(flatAccounts)
  const cashAccounts = getCashAccounts(flatAccounts)

  const accounts = accountsData?.data?.accounts || []
  return (
    <div
      className="d-flex flex-column px-3 py-2"
      style={{
        minHeight: '200px',
        maxHeight: '200px',
        height: '200px',
        overflow: 'auto',

        boxShadow: 'inset hsl(243 19% 18% / .5) 0 0px 7px 5px',
        border: 'solid 1px #6362752e',
      }}>
      {[...cashAccounts, ...creditCardAccounts].map((account) => {
        const accountType = getAccountTypeFromTypeSubtypeTuple([account.type, account.subtype])
        const icon = getIconFromAccountType(accountType)
        const color = getColorFromAccountType(accountType)
        const accountChosen = account.account_id === activelySelectedAccount?.accountId
        return (
          <div
            onClick={() => {
              setActivelySelectedAccount({
                accountId: account.account_id,
                itemId: getItemIdForAccountId(accounts, account.account_id) || 'no-item-id-found',
              })
            }}
            key={account.account_id}
            style={{ opacity: accountChosen ? 1 : 0.5 }}
            className="d-flex my-0 w-100 align-items-center justify-content-between">
            <Icon
              size="md"
              color={color}
              className={`${accountChosen ? 'fas' : 'far'} fa-circle mr-2`}
            />
            <AccountMaskAndLogo logoSize={18} accountId={account.account_id} />
            <TextSpan size="xs">{account.name}</TextSpan>

            <Icon size="sm" color={color} className={`${icon} mr-2`} />
            <TextSpan weight={600} color={color} size="sm">
              {numberToCurrency(account.balances.current || account.balances.current)}
            </TextSpan>
          </div>
        )
      })}
    </div>
  )
}
