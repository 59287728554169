import { TextPopupSmall, TextSpan } from 'Components/DesignSystem/Typography'
import { localTokenStoragePath, useGetAccessToken } from 'Hooks/useGetAccessToken'
import { SettingsItem } from 'Pages/SettingsPage/components'
import { humanReadifyStringDateWithTime } from 'Utilities/dateUtilities/dateUtilities'
import jwt_decode from 'jwt-decode'
import React from 'react'

export const TokenManagement: React.FC = () => {
  let humanDateExpiration: string = ''
  const token = localStorage.getItem(localTokenStoragePath)
  let decodedToken: any
  if (token) {
    decodedToken = jwt_decode(token) as any
    // this is a NumericDate value which is seconds (not ms) since epoch
    // https://stackoverflow.com/questions/39926104/what-format-is-the-exp-expiration-time-claim-in-a-jwt
    const exp = decodedToken.exp
    const date = new Date(exp * 1000)
    const humanDate = humanReadifyStringDateWithTime(date)
    humanDateExpiration = humanDate
  }

  const { getAccessToken } = useGetAccessToken('SettingsPage')
  return (
    <SettingsItem>
      <div className="d-flex align-items-center">
        <TextPopupSmall
          size="xxs"
          onClick={() => {
            alert(JSON.stringify(decodedToken, null, 2))
          }}>
          Token Expiry: {humanDateExpiration}
        </TextPopupSmall>
        <TextSpan
          weight={700}
          color="logoPrimary"
          className="ml-2"
          onClick={() => {
            getAccessToken()
          }}>
          Get Token
        </TextSpan>
      </div>
    </SettingsItem>
  )
}
