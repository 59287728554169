import { useFinancialData } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import { cleanCurrency } from 'Utilities/currencyFormater'

export const useSeeIfGoalIsMet = () => {
  const { itemCost, mysteryMode } = useMysteryDay()

  const { currentSavings } = useFinancialData()

  switch (mysteryMode) {
    case 'getOrganized':
      const goal = cleanCurrency(itemCost)
      if (currentSavings > Number(goal)) {
        return true
      } else {
        return false
      }
    default:
      return false
  }
}
