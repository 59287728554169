const newColorsLight = {
  background: '#ffffff',
  backgrounddark: '#E2E3DB',
  nearlyclear: '#F3F5EC',
  text: '#484A54',
  texttransparent: '#969696',
  textsupertransparent: '#818181',
  logoSecond: '#3B6B7D',
  logoSecondary: '#b6d8e3',
  logoPrimary: '#5BB174',
  seconddark: '#4F8493',
  black: '#2D2E30',
  cashgreen: '#5BB174',
  mehred: '#FF645C',
  yellow: '#909020',
  orange: '#CE8500',
  megapurple: '#B041B9',
  softblue: '#64BEB3',
  second: '#466A7B',
}

export const newColorsDark = {
  background: '#2D3142',
  backgrounddark: '#1B1F2F',
  nearlyclear: '#3B3B3D',

  text: '#F4F4F6',
  texttransparent: '#FBFCFE99',
  textsupertransparent: '#C7C9D1',

  logoSecond: '#1F758C',
  logoSecondary: '#b6d8e3',
  logoPrimary: '#83FFB3',

  seconddark: '#73A9B7',

  black: 'black',
  cashgreen: '#85FFA7',

  mehred: '#EA6A5B',
  yellow: '#BB9F06',
  orange: '#FFA500',
  megapurple: '#F8A4FF',
  softblue: '#6CCFF6',

  second: '#466A7B',
}

export const colorPalletes = {
  newLight: newColorsLight,
  newDark: newColorsDark,
}

export const myTheme = {
  buttons: {
    borderRadius: '8px',
  },

  sizes: {
    banner: '3rem',
    large: '2rem',
    paragraph: '1rem',
  },
  colors: newColorsDark,
  fonts: {
    family: {
      primary: 'PhosphateSolid',
      secondary: 'Rubik, sans-serif',
    },
    weights: {
      100: 100,
      200: 200,
      300: 300,
      400: 400,
      500: 500,
      600: 600,
      700: 700,
      800: 800,
      900: 900,
    },
    sizes: {
      xxxxs: '.575rem',
      xxxs: '.625rem',
      xxs: '.75rem',
      xs: '.875rem',
      sm: '1rem',
      smm: '1.125rem',
      md: '1.25rem',
      lg: '1.5rem',
      xl: '2rem',
      xxl: '2.5rem',
      xxxl: '3.5rem',
      xxxxl: '4.5rem',
      xxxxxl: '5.5rem',
    },
  },
  footer: {
    buttonDiameter: 4, // in REM (left as number for JS calculations)
    buttonDiameterSmall: 3.5, // in REM (left as number for JS calculations)
    buttonBorderRadius: '3px', // in REM (left as number for JS calculations)
    indicatorWidth: 3, // in REM (left as number for JS calculations)
    indicatorHeight: '3px', // in REM (left as number for JS calculations)
  },
}

export type ThemeType = typeof myTheme

export type ColorType = keyof typeof myTheme.colors
export type FontSizeType = keyof typeof myTheme.fonts.sizes
export type FontWeightType = keyof typeof myTheme.fonts.weights
export type FontFamilyType = keyof typeof myTheme.fonts.family
