import { MysteryDayType } from 'Providers/MysteryDayProvider/MysteryDayProvider.interfaces'
import { iconFromMysteryDayType } from './utils'

export type LinkType =
  | '/fixed'
  | '/creditcards'
  | '/spending'
  | '/mystery'
  | '/wishlist'
  | '/variable'

export const FIXED_ICON = 'fas fa-calendar-clock'
export const VARIABLE_ICON = 'fas fa-envelope-open-dollar'
export const SPENDING_ICON = 'fas fa-wallet'
export const IMPULSE_ICON = 'fas fa-medal'
export const WIZARDRY_ICON = 'fa-duotone fa-hat-wizard'

export const ACCOUNTS_ICON = 'far fa-building-columns'
export const INCOME_ICON = 'far fa-sack-dollar'
export const EXPENSES_ICON = 'fas fa-flame'

export const iconFromLink = (
  link: LinkType,
  isActive = false,
  mysteryLinkType?: MysteryDayType,
  onboardingComplete?: boolean
) => {
  switch (link) {
    case '/fixed':
      return FIXED_ICON
    case '/creditcards':
      return 'far fa-building-columns'
    case '/spending':
      return SPENDING_ICON
    case '/wishlist':
      return IMPULSE_ICON
    case '/variable':
      return VARIABLE_ICON
    case '/mystery':
      return iconFromMysteryDayType(mysteryLinkType, isActive, onboardingComplete)
  }
}

export const labelFromLink = (
  link: LinkType,
  mysteryLinkType?: MysteryDayType,
  onboardingComplete?: boolean
) => {
  switch (link) {
    case '/fixed':
      return 'bills'
    case '/creditcards':
      return 'accounts'
    case '/spending':
      return 'spending'
    case '/wishlist':
      return 'desires'
    case '/variable':
      return 'envelopes'
    case '/mystery':
      if (onboardingComplete) {
        return 'progress'
      }
      return 'onboarding'
  }
}
