import { WideButton } from 'design-system/buttons/WideButton/WideButton'
import React from 'react'
import CountUp from 'react-countup'
import { Icon, TextSlim, TextSpan } from '../../Components/DesignSystem/Typography'

interface LoadingButtonProps {
  operation: (params: any) => Promise<void>
  loading: boolean
  title?: string
}
export const LoadingButton: React.FC<LoadingButtonProps> = ({ operation, loading, title }) => {
  return (
    <WideButton
      active={true}
      appearance="secondary"
      bgColor="textsupertransparent"
      text=""
      textSize="small"
      width="fit"
      className="ml-1"
      style={{ padding: '.125rem .5rem' }}
      onClick={async () => {
        operation({})
      }}>
      <TextSlim style={{ minWidth: '80px' }} color="textsupertransparent" size="xs" weight={700}>
        {loading ? (
          <TextSpan color="textsupertransparent" size="sm" weight={700}>
            <CountUp style={{ padding: 0, margin: 0 }} end={30} duration={30} /> / 30s
          </TextSpan>
        ) : (
          <TextSpan color="textsupertransparent" size="sm" weight={700}>
            Refresh
          </TextSpan>
        )}
      </TextSlim>
      <Icon
        size="md"
        color="textsupertransparent"
        className={`fas fa-sync ${loading ? 'fa-spin' : ''}`}
      />
    </WideButton>
  )
}
