import { TextBold, TextSlim } from 'Components/DesignSystem/Typography'
import { deleteAllLocalData } from 'Providers/APIDataProvider'
import React from 'react'
import { ThemeProvider } from 'styled-components'
import { myTheme } from 'theme'
import { Container } from './ErrorBoundary.components'

interface State {
  hasError: boolean
  error: any
  errorInfo: any
  showError: boolean
}

export class ErrorBoundary extends React.Component<{}, State> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false, error: '', errorInfo: '', showError: false }
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.error('error', error)
    console.error('errorInfo', errorInfo)
    this.setState({ error, errorInfo })
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ThemeProvider theme={myTheme}>
          <Container data-testid="error-boundary-container">
            <TextBold size="xl" color="second">
              Oh my - we goofed.{' '}
            </TextBold>
            <TextBold size="xl" color="second">
              <span aria-labelledby="emoji">🙈</span>
            </TextBold>
            <TextSlim size="sm" weight={400} color="second">
              Mistakes happen but this was a rough one. Our entire app crashed in an unrecoverable
              way.
            </TextSlim>
            <TextSlim size="sm" weight={400} color="second">
              You can try refreshing by clicking{' '}
              <button
                onClick={() => {
                  window.location.pathname = '/fixed'
                  deleteAllLocalData()
                }}>
                <TextSlim size="sm" weight={800} color="second">
                  <u>here</u>
                </TextSlim>
              </button>{' '}
              but whatever caused this will likely happen again.{' '}
            </TextSlim>
            <TextSlim size="sm" weight={400} color="second">
              {' '}
              If you can tell us what steps lead to this, email <b>feedback@splurv.com</b> and we'd
              be happy to buy you a<span aria-labelledby="emoji"> ☕️</span>. Seriously, help us get
              better!
            </TextSlim>

            <TextSlim onClick={() => this.setState({ showError: !this.state.showError })}>
              Click to {this.state.showError ? 'hide' : 'show'} error
            </TextSlim>

            {this.state.showError ? (
              <div style={{ maxHeight: '50vh', overflowY: 'scroll' }}>
                <TextSlim>
                  <code>{String(this.state.error)}</code>
                  <br />
                  <br />
                  <code>{JSON.stringify(this.state.errorInfo)}</code>
                </TextSlim>
              </div>
            ) : null}
          </Container>
        </ThemeProvider>
      )
    }

    return this.props.children
  }
}
