import styled from 'styled-components/macro'

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  max-width: calc(100%);
`
