import { BankLogo } from 'Components/BankLogo'
import { useUIState } from 'Providers/UIStateProvider'
import React from 'react'
import { useFinancialData } from '../Providers/FinancialDataProvider/FinancialDataProvider'
import { ContainerCutout } from './DesignSystem/Layout/Containers/Containers'
import { Icon, StyledHr, TextSlim } from './DesignSystem/Typography'

export const useBanksInNeedOfLogin = () => {
  const { accountsData } = useFinancialData()

  const data = accountsData.data

  const banks = data?.items || []

  const banksInNeedOfLogin = banks.filter(
    (bank) => bank.item.error?.error_code === 'ITEM_LOGIN_REQUIRED'
  )

  return banksInNeedOfLogin
}

export const BanksInNeedOfLoginWidget: React.FC = () => {
  const banksInNeedOfLogin = useBanksInNeedOfLogin()
  const { accountsData } = useFinancialData()

  const { setAddCardPageOpen } = useUIState()
  const data = accountsData.data

  const institutions = data?.institutions || []

  if (banksInNeedOfLogin.length === 0) {
    return null
  }

  return (
    <ContainerCutout
      size={accountOverviewWidgetSize}
      className="justify-content-start p-1 w-100"
      style={{
        boxShadow: 'rgb(0 0 0 / 30%) 0px 0px 4px 2px',
        borderRadius: '8px',
        marginTop: `${accountOverviewWidgetMarginBottom}px`,
        marginBottom: `${accountOverviewWidgetMarginBottom}px`,
        overflow: 'scroll',
        maxHeight: accountOverviewWidgetSize,
      }}>
      <div className="d-flex align-items-center flex-column w-100 justify-content-around">
        <TextSlim weight={400} size="xs" color={'textsupertransparent'}>
          Banks in need of login:
        </TextSlim>
        <StyledHr className="mb-2" />
        {banksInNeedOfLogin.map((bank) => {
          const institution = institutions.find(
            (i) => i.institution.institution_id === bank.item.institution_id
          )
          const { logo, name, primary_color } = institution?.institution || {}
          return (
            <div key={bank.item.item_id} className="my-1">
              <div
                onClick={() => {
                  setAddCardPageOpen({
                    item_id: bank.item.item_id,
                    logo: logo || '',
                    name: name || '',
                    color: primary_color || '',
                  })
                }}
                style={{ minWidth: 'fit-content' }}
                className="d-flex align-items-center">
                <BankLogo logo={logo} />
                <TextSlim className="ml-2" weight={400} size="xs" color={'textsupertransparent'}>
                  {name}
                </TextSlim>
                <TextSlim weight={800} size="xs" color="yellow" className="ml-2">
                  <u>RELINK </u>
                  <Icon className="fas fa-link mx-1" color="yellow" size="sm" />
                </TextSlim>
              </div>
            </div>
          )
        })}
      </div>
    </ContainerCutout>
  )
}

const accountOverviewWidgetSize = 82
const accountOverviewWidgetMarginBottom = 8
