import { Icon } from 'Components/DesignSystem/Typography'
import React from 'react'
import { ColorType } from 'theme'
import { RowContainer, RowContainerPart } from './Row.components'

interface Props {
  rowIcon: string
  rowIconColor: ColorType
  rowIconSize: 'sm' | 'lg'
  rowBottomColor?: ColorType
  gridTemplateColumnString: string
  firstColumn: React.ReactNode
  secondColumn: React.ReactNode
  thirdColumn: React.ReactNode
  style?: React.CSSProperties
}
export const Row: React.FC<Props> = ({
  rowIcon,
  firstColumn,
  secondColumn,
  thirdColumn,
  rowIconColor,
  rowIconSize,
  gridTemplateColumnString,
  rowBottomColor,
  style,
}) => {
  return (
    <RowContainer
      gridTemplateColumnString={gridTemplateColumnString}
      borderColor={rowBottomColor}
      style={style}>
      <RowContainerPart>
        <Icon className={rowIcon} size={rowIconSize} color={rowIconColor} />
      </RowContainerPart>
      <RowContainerPart className="justify-content-start">{firstColumn}</RowContainerPart>
      <RowContainerPart>{secondColumn}</RowContainerPart>
      <RowContainerPart className="justify-content-start">{thirdColumn}</RowContainerPart>
    </RowContainer>
  )
}
