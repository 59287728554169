import { Icon, TextBold, TextPopupSmall, TextSpan } from 'Components/DesignSystem/Typography'
import { useUserConfig } from 'Providers/UserConfigProvider/UserConfigProvider'
import React from 'react'
import { SettingsItem } from '../components'

export const CentsModeToggle: React.FC = () => {
  const { centsMode, setCentsMode } = useUserConfig()
  return (
    <SettingsItem onClick={() => setCentsMode(!centsMode)}>
      <div className="d-flex flex-column w-100 align-items-start">
        <div className="d-flex flex-row w-100 align-items-center">
          <Icon
            color={centsMode ? 'text' : 'softblue'}
            className={`fa-duotone fa-${centsMode ? 'coins' : 'square-dollar'}`}
          />
          <TextBold color={centsMode ? 'text' : 'softblue'}>
            Currency Display
            {/* {centsMode ? 'Show Cents' : 'Round Dollars'} */}
          </TextBold>
          <TextPopupSmall className="p-1" color={centsMode ? 'text' : 'softblue'}>
            {centsMode ? 'Exact: $24.76' : 'Round: $25'}
          </TextPopupSmall>
        </div>
        <TextSpan size="xxs" color="texttransparent">
          {centsMode
            ? 'Show exact amount when displaying currencies'
            : 'Round to closest dollar when displaying currencies'}
        </TextSpan>
      </div>
    </SettingsItem>
  )
}
