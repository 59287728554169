import React from 'react'
import { Icon, TextSpan } from '../../../Components/DesignSystem/Typography'
import { numberToCurrency } from '../../../Utilities/currencyFormater'
import { ColorType } from '../../../theme'
import { StreamAmountPart } from './components'

import { CollapseCard } from 'Components/CollapseCard/CollapseCard'
import { Row } from 'design-system/table/Row/Row'

interface CollapsibleStreamEntryProps {
  count: number
  total: number
  color: ColorType
  defaultOpen?: boolean
  colorAlpha: ColorType
  ref?: React.ForwardedRef<HTMLDivElement>
  title: string
  icon: string
  content: React.ReactNode
}

const _CollapsibleStreamEntry: React.ForwardRefRenderFunction<
  HTMLDivElement,
  CollapsibleStreamEntryProps
> = ({ count, total, content, title, icon, color, colorAlpha, defaultOpen }, ref) => {
  if (count === 0) return null

  return (
    <CollapseCard
      persistCollapseState
      id={`fixed-page-${title}`}
      header={(toggle, isCollapsed) => (
        <Row
          style={{
            minHeight: '62px',
            height: '62px',
          }}
          rowIcon={icon}
          rowIconSize="lg"
          rowIconColor={color}
          rowBottomColor={color}
          firstColumn={
            <TextSpan color="textsupertransparent" weight={600} onClick={() => toggle()}>
              {title.toLowerCase()} ({count})
            </TextSpan>
          }
          secondColumn={
            <StreamAmountPart isPositive={color === 'cashgreen' ? true : false}>
              {numberToCurrency(total, true)}
            </StreamAmountPart>
          }
          thirdColumn={
            <TextSpan
              size="xxs"
              weight={700}
              className="p-1"
              color={'texttransparent'}
              onClick={() => toggle()}>
              <Icon
                color={color}
                style={{
                  transition: 'all ease-in-out 0.3s',
                  transform: `${isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)'}`,
                }}
                size="xl"
                className={'far fa-chevron-circle-down'}
              />
            </TextSpan>
          }
          gridTemplateColumnString="minmax(0, 0.5fr) minmax(0, 1.75fr) minmax(0, 0.75fr) minmax(0, 1fr);"
        />
      )}
      body={content}
    />
  )
}

export const CollapsibleStreamEntry = React.memo(React.forwardRef(_CollapsibleStreamEntry))
