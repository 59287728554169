import { useIsAlex } from 'Components/AlexOnlyContent/AlexOnlyContent'
import { StyledHr } from 'Components/DesignSystem/Typography'
import {
  TrialPaywallContent,
  TrialPaywallSubtitle,
} from 'Components/TrialExpiredPage/TrialPayWallComponent'
import { useSubscriptionStatus } from 'Providers/SubscriptionStatusProvider/SubscriptionStatusProvider'
import { useUIState } from 'Providers/UIStateProvider'
import { WideButton } from 'design-system/buttons/WideButton/WideButton'
import React, { MouseEventHandler } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components/macro'
import { SettingsItemErrorBoundary } from './SettingsItems/SettingsItemErrorBoundary/SettingsItemErrorBoundary'

interface Props {
  onClick?: MouseEventHandler<HTMLDivElement>
  style?: React.CSSProperties
  className?: string
  allowedPostTrial?: boolean
}
export const SettingsItem: React.FC<Props> = ({
  children,
  onClick,
  style,
  className,
  allowedPostTrial,
}) => {
  const { status, trialDaysLeft, daysOnApp } = useSubscriptionStatus()
  const { push } = useHistory()
  const isAlex = useIsAlex()
  const { setPopup, setSettingsPageOpen } = useUIState()

  const pageOpen = status === 'active' || trialDaysLeft >= 0 || isAlex
  return (
    <SettingsItemErrorBoundary>
      <SettingsItemDiv
        style={{ ...style, opacity: pageOpen || allowedPostTrial ? 1 : 0.25 }}
        className={className}
        onClick={
          pageOpen || allowedPostTrial
            ? onClick
            : () => {
                setPopup(
                  <div
                    className="d-flex flex-column align-items-start p-3 justify-content-start mt-0 w-100"
                    style={{
                      transition: 'opacity ease-in-out .3s',
                    }}>
                    <TrialPaywallSubtitle />
                    <TrialPaywallContent daysOnApp={daysOnApp} />

                    <div className="d-flex flex-column align-items-center w-100 px-3">
                      <StyledHr className="mb-4" weight={1} color="nearlyclear" />
                      <WideButton
                        onClick={() => {
                          setPopup(null)
                          setSettingsPageOpen(false)
                          push('/checkout')
                        }}
                        active
                        appearance="primary"
                        bgColor="logoPrimary"
                        text="view subscriptions"
                        width="fit"
                        textSize="small"
                      />
                    </div>
                  </div>
                )
              }
        }>
        {children}
      </SettingsItemDiv>
    </SettingsItemErrorBoundary>
  )
}

export const SettingsItemDiv = styled.div`
  width: 100%;
  padding: 1rem 0.5rem;
  &:first-of-type {
    border-top: ${(props) => `solid 1px ${props.theme.colors.textsupertransparent}`};
  }
  border-bottom: ${(props) => `solid 1px ${props.theme.colors.textsupertransparent}`};
  display: flex;
  align-items: center;
  justify-content: start;
`
