import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'

/**
 *  A HOC  that wraps any content that only the user alex.tzinov* can use
 * @param children the underlying component to render
 * @returns
 */
export const AlexOnlyContent: React.FC = ({ children }) => {
  const isAlex = useIsAlex()
  return isAlex ? <>{children}</> : null
}

export const useIsAlex = () => {
  const { user } = useAuth0()
  const isAlex =
    user?.email?.includes('alex.tzinov') ||
    user?.email.includes('franceserussell') ||
    user?.email.includes('rtzinov') ||
    user?.email.includes('alex+092923@gmail.com')
  return isAlex
}
