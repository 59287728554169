import { useIsAlex } from 'Components/AlexOnlyContent/AlexOnlyContent'
import { Icon, TextSpan } from 'Components/DesignSystem/Typography'
import { useSubscriptionStatus } from 'Providers/SubscriptionStatusProvider/SubscriptionStatusProvider'
import { WideButton } from 'design-system/buttons/WideButton/WideButton'
import { BodyText } from 'design-system/typography/Text/Text'
import React from 'react'
import { useHistory } from 'react-router'
import { TrialExpiredPage } from './TrialExpiredPage'

export const TrialPaywallSubtitle = () => (
  <TextSpan color="logoPrimary" size="lg" weight={200}>
    <Icon className={`fas fa-face-frown-slight mr-2`} color="logoPrimary" size="lg" />
    Trial Expired
  </TextSpan>
)

export const TrialPaywallContent = ({ daysOnApp }: { daysOnApp: number }) => (
  <>
    <BodyText color="texttransparent">
      <TextSpan weight={600} color="logoPrimary">
        {daysOnApp}
      </TextSpan>{' '}
      days ago you gave Splurv a chance. It seems like a month later, you're still checking us out.
      We don't love this moment of asking for money. Trust us, the irony of a finance app asking you
      to add yet another subscription to your bills is not lost on us.
    </BodyText>
    <BodyText>
      If you can take your habits learned and don't need the app, don't subscribe! We believe in cut
      throat intentionality when it comes to picking where we spend our money, and this extends to
      Splurv as well. If you can't justify the subscription, all good.
    </BodyText>

    <BodyText color="texttransparent">
      To purchase a monthly or yearly subscription, click the button below to join the party and
      keep using Splurv.
    </BodyText>
  </>
)

export const TrialPayWallComponent: React.FC = ({ children }) => {
  const { status, daysOnApp, trialDaysLeft } = useSubscriptionStatus()
  const { push } = useHistory()

  const isAlex = useIsAlex()

  const pageOpen = status === 'active' || trialDaysLeft >= 0 || isAlex

  if (pageOpen) return <>{children}</>
  return (
    <TrialExpiredPage
      subtitle={<TrialPaywallSubtitle />}
      content={<TrialPaywallContent daysOnApp={daysOnApp} />}
      cta={
        <WideButton
          onClick={() => {
            push('/checkout')
          }}
          active
          appearance="primary"
          bgColor="logoPrimary"
          text="view subscriptions"
          width="fit"
          textSize="small"
        />
      }
    />
  )
}
