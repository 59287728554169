import { AVATAR_SIZE } from 'Components/UserAvatar'
import color from 'color'
import React from 'react'
import styled from 'styled-components/macro'
import { ColorType, FontFamilyType, FontSizeType, FontWeightType } from '../../theme'

export const CustomUl = styled.ul`
  padding-left: 2rem;
`

export const CustomLi = styled.li`
  font-size: ${(props) => props.theme.fonts.sizes.sm};
  font-weight: ${(props) => props.theme.fonts.weights['300']};
  color: ${(props) => props.theme.colors.text};
  font-family: ${(props) => props.theme.fonts.family.secondary};
  transition: all ease-in-out 0.3s;
`

type TightnessType = 'spaced' | 'tight' | 'supertight' | 'superspaced'

interface TextProps {
  size?: FontSizeType
  textAlign?: 'left' | 'center' | 'right'
  alignSelf?: 'flex-start' | 'center' | 'flex-end'
  fontFamily?: FontFamilyType
  weight?: FontWeightType
  tightness?: TightnessType
  color?: ColorType
  inactive?: boolean
}

const tightnessToSpacingValue = (tightness: TightnessType | undefined) => {
  switch (tightness) {
    case 'superspaced':
      return '2px'
    case 'spaced':
      return '1px'
    case 'tight':
      return '-.5px'
    case 'supertight':
      return '-1px'
    default:
      return '0px'
  }
}

export const TextSlim = styled.p<TextProps>`
  align-self: ${(props) => props?.alignSelf || ''};
  text-align: ${(props) => props?.textAlign || ''};
  font-size: ${(props) => props.theme.fonts.sizes[props?.size || 'sm']};
  font-weight: ${(props) => props.theme.fonts.weights[props?.weight || 300]};
  font-family: ${(props) => props.theme.fonts.family[props?.fontFamily || 'secondary']};
  color: ${(props) => props.theme.colors[props?.color || 'text']};
  font-family: ${(props) => props.theme.fonts.family.secondary};
  transition: color ease-in-out 0.3s;
  margin: 0.25rem 0;

  line-height: unset;
  letter-spacing: ${(props) => tightnessToSpacingValue(props.tightness)};
`

export const TextSlimTight = styled.p<TextProps>`
  align-self: ${(props) => props?.alignSelf || ''};
  text-align: ${(props) => props?.textAlign || ''};
  font-size: ${(props) => props.theme.fonts.sizes[props?.size || 'sm']};
  font-weight: ${(props) => props.theme.fonts.weights[props?.weight || 200]};
  color: ${(props) => props.theme.colors[props?.color || 'text']};
  font-family: ${(props) => props.theme.fonts.family.secondary};
  transition: color ease-in-out 0.3s;
  margin: 0 0;

  line-height: unset;
  letter-spacing: ${(props) => tightnessToSpacingValue(props.tightness)};
`

export const TextSpan = styled.span<TextProps>`
  align-self: ${(props) => props?.alignSelf || ''};
  text-align: ${(props) => props?.textAlign || ''};
  font-size: ${(props) => (props.size ? props.theme.fonts.sizes[props.size] : 'inherit')};
  font-weight: ${(props) => (props.weight ? props.theme.fonts.weights[props.weight] : 'inherit')};
  color: ${(props) => (props.color ? props.theme.colors[props.color] : 'inherit')};
  font-family: ${(props) => props.theme.fonts.family[props?.fontFamily || 'secondary']};
  transition: all ease-in-out 0.3s;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  display: inline-flex;
  align-items: center;
  /* line-height: unset; */
  letter-spacing: ${(props) => tightnessToSpacingValue(props.tightness)};
`
export const TextPopup = styled.span<TextProps>`
  align-self: ${(props) => props?.alignSelf || ''};
  max-width: fit-content;
  text-align: ${(props) => props?.textAlign || ''};
  font-size: ${(props) => props.theme.fonts.sizes[props?.size || 'sm']};
  font-weight: 800;
  color: ${(props) => props.theme.colors[props?.color || 'seconddark']};
  background: ${(props) =>
    color(props.theme.colors[props?.color || 'seconddark'])
      .alpha(0.2)
      .toString()};
  padding: 0.25rem 0.375rem;
  border-radius: 8px;
  font-family: ${(props) => props.theme.fonts.family.secondary};
  transition: all ease-in-out 0.3s;

  box-shadow: rgb(0 0 0 / 30%) 0px 0px 4px 2px;
  margin: 0.25rem 0;
  display: inline-flex;
  align-items: center;
  line-height: unset;
  letter-spacing: ${(props) => tightnessToSpacingValue(props.tightness)};
  i {
    text-decoration: none;
  }
`

export const TextPopupSmall = styled.span<TextProps>`
  align-self: ${(props) => props?.alignSelf || ''};
  text-align: ${(props) => props?.textAlign || ''};
  font-size: ${(props) => props.theme.fonts.sizes[props?.size || 'xs']};
  font-weight: ${(props) => props.theme.fonts.weights[props?.weight || 800]};
  color: ${(props) => props.theme.colors[props?.color || 'seconddark']};
  background: ${(props) =>
    color(props.theme.colors[props?.color || 'seconddark'])
      .alpha(0.2)
      .toString()};
  padding: 0.125rem 0.25rem;
  border-radius: 4px;
  font-family: ${(props) => props.theme.fonts.family.secondary};
  transition: all ease-in-out 0.3s;
  margin: 0.25rem 0;
  box-shadow: rgb(0 0 0 / 30%) 0px 0px 4px 1px;
  display: inline-flex;
  align-items: center;
  line-height: unset;
  letter-spacing: ${(props) => tightnessToSpacingValue(props.tightness)};
  i {
    text-decoration: none;
  }
`

interface StyledHrProps {
  weight?: 0.5 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
  color?: ColorType
  borderStyle?: 'solid' | 'dashed' | 'dotted'
}
export const StyledHr = styled.hr<StyledHrProps>`
  border-color: ${(props) => props.theme.colors[props?.color || 'nearlyclear']};

  border-width: 0;
  border-bottom-width: ${(props) => `${props.weight || 2}px`};
  border-style: ${(props) => props?.borderStyle || 'solid'};
  background: transparent;
  transition: all ease-in-out 0.3s;

  width: 100%;
  margin: 0;
`

interface StyledVrProps {
  weight?: 0.5 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
  size?: FontSizeType
  color?: ColorType
  borderStyle?: 'solid' | 'dashed' | 'dotted'
}
export const StyledVr = styled.div<StyledVrProps>`
  background: ${(props) => props.theme.colors[props?.color || 'nearlyclear']};
  height: ${(props) => props.theme.fonts.sizes[props?.size || 'lg']};
  width: ${(props) => `${props.weight || 2}px`};

  transition: all ease-in-out 0.3s;
  margin: 0;
`

export const TextBold = styled.p<TextProps>`
  font-size: ${(props) => props.theme.fonts.sizes[props?.size || 'sm']};
  font-family: ${(props) => props.theme.fonts.family[props?.fontFamily || 'primary']};
  color: ${(props) => props.theme.colors[props?.color || 'text']};
  margin: 0.5rem;
  align-self: ${(props) => props?.alignSelf || ''};
  text-align: ${(props) => props?.textAlign || ''};

  transition: all ease-in-out 0.3s;
`

export const TextBoldFancy = styled.p<TextProps>`
  font-size: ${(props) => props.theme.fonts.sizes[props?.size || 'sm']};
  font-family: ${(props) => props.theme.fonts.family.primary};
  color: ${(props) => props.theme.colors[props?.color || 'text']};
  margin: 0.5rem;

  text-shadow: 1px 1px 10px #b6f8bd, 1px 1px 10px white;
`

export const LoadingIndicator = ({ text }: { text: string }) => {
  return (
    <LoadingIndicatorDiv>
      <TextBold>{text}</TextBold>
    </LoadingIndicatorDiv>
  )
}

const LoadingIndicatorDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @keyframes grow {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      transform: scale(0.75);
    }
  }

  p {
    font-size: ${(props) => props.theme.fonts.sizes['lg']};
    align-self: center;
    line-height: 3rem;
    text-align: center;
    font-family: ${(props) => props.theme.fonts.family.primary};
    color: ${(props) => props.theme.colors['second']};
    margin: 0.5rem;
    width: 100%;
  }

  img {
    animation-name: grow;
    transform: scale(0.75);
    max-width: 30vw;
    animation-timing-function: ease-in-out;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-delay: 0s;
  }
`

export const Icon = styled.i<TextProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => props.theme.fonts.sizes[props?.size || 'sm']};
  transition: all ease-in-out 0.3s;
  opacity: ${(props) => (props.inactive ? 0.3 : 1)};
  color: ${(props) => props.theme.colors[props?.color || 'text']};
  &.left-border {
    padding-left: 0.5rem;
    border-left: ${(props) => `solid 1px ${props.theme.colors.texttransparent}`};
  }

  &.inactive {
    opacity: 0.2;
  }

  &.loading {
    animation-name: spinnericon;
    animation-timing-function: ease-in-out;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-delay: 0s;
    @keyframes spinnericon {
      0% {
        opacity: 1;
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(359deg);
      }
    }
  }

  &.icon-default {
    transition: opacity 0.3s, transform 0.3s;
  }

  &.icon-hide {
    transition: opacity 0.3s, transform 0.3s;
    transform: rotate(-180deg) scale(0.5);
    opacity: 0;
    pointer-events: none;
  }

  &.icon-appear {
    transition: opacity 0.3s, transform 0.3s;
    transform: rotate(180) scale(1);
    opacity: 1;
    pointer-events: all;
  }
`

export const Avatar = styled.img<{ size?: number }>`
  width: ${(props) => (props?.size ? `${props.size}px` : `${AVATAR_SIZE}px`)};
  height: ${(props) => (props?.size ? `${props.size}px` : `${AVATAR_SIZE}px`)};
  min-height: ${(props) => (props?.size ? `${props.size}px` : `${AVATAR_SIZE}px`)};
  max-height: ${(props) => (props?.size ? `${props.size}px` : `${AVATAR_SIZE}px`)};
  min-width: ${(props) => (props?.size ? `${props.size}px` : `${AVATAR_SIZE}px`)};
  max-width: ${(props) => (props?.size ? `${props.size}px` : `${AVATAR_SIZE}px`)};
  transition: all ease-in-out 0.3s;
  z-index: 20;
  border-radius: 1.5rem;
  box-shadow: rgb(0 0 0 / 30%) 0px 0px 4px 2px;
`
