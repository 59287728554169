import { AccountMaskAndLogo } from 'Components/AccountMaskAndLogo'
import { CollapseCardControlled } from 'Components/CollapseCard/CollapseCardControlled'
import { Pill } from 'Components/DesignSystem/Pill/Pill'
import { Icon, StyledHr, StyledVr, TextSpan } from 'Components/DesignSystem/Typography'
import { ToggleSwitch } from 'Components/Toggle/Toggle'
import { ToggleSwitchThreeway } from 'Components/Toggle/ToggleThreeWay'
import { dataParameterType } from 'Hooks/useLocalStorageSyncedState'
import { useFinancialData } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import React from 'react'
import { TransactionCategoryBadge } from '../AdvancedTransactionRow/TransactionCategoryBadge'
import { TransactionDateRangeBadge } from '../AdvancedTransactionRow/TransactionDateRangeBadge'
import { TransactionReflectionNeededBadge } from '../AdvancedTransactionRow/TransactionReflectionNeededBadge'
import { TransactionTypeBadge } from '../AdvancedTransactionRow/TransactionTypeBadge'
import { ActiveTransactionAccountPicker } from './ActiveTransactionAccountPicker'
import { ActiveTransactionCategoryPicker } from './ActiveTransactionCategoryPicker'
import { FILTER_TYPE, TIME_FRAME } from './helpers'

interface Props {
  filterPanelOpen: boolean
  setFilterPanelOpen: (v: dataParameterType<boolean>) => void

  activeAccountIdFilter: string
  setActiveAccountIdFilter: (v: dataParameterType<string>) => void

  activeCategoryFilter: string
  setActiveCategoryFilter: (v: dataParameterType<string>) => void

  activeTypeFilter: FILTER_TYPE
  setActiveTypeFilter: (v: dataParameterType<FILTER_TYPE>) => void

  timeFrameFilter: TIME_FRAME
  setTimeFrameFilter: (v: dataParameterType<TIME_FRAME>) => void

  reflectionNeededFilter: boolean
  setReflectionNeededFilter: (v: dataParameterType<boolean>) => void
}
export const AdvancedTransactionViewFilterPanel: React.FC<Props> = ({
  filterPanelOpen,
  setFilterPanelOpen,

  activeAccountIdFilter,
  setActiveAccountIdFilter,

  activeCategoryFilter,
  setActiveCategoryFilter,

  activeTypeFilter,
  setActiveTypeFilter,

  timeFrameFilter,
  setTimeFrameFilter,

  reflectionNeededFilter,
  setReflectionNeededFilter,
}) => {
  const setValueStateForMonths = React.useCallback(
    (value: 'left' | 'center' | 'right') => {
      switch (value) {
        case 'left':
          setTimeFrameFilter('thisMonth')
          return
        case 'center':
          setTimeFrameFilter('both')
          return
        case 'right':
          setTimeFrameFilter('lastMonth')
          return
      }
    },
    [setTimeFrameFilter]
  )

  const setValueStateForActiveTypeFilter = React.useCallback(
    (value: 'left' | 'center' | 'right') => {
      switch (value) {
        case 'left':
          setActiveTypeFilter('variable')
          return
        case 'center':
          setActiveTypeFilter('both')
          return
        case 'right':
          setActiveTypeFilter('fixed')
          return
      }
    },
    [setActiveTypeFilter]
  )

  const { flatAccounts } = useFinancialData()

  return (
    <CollapseCardControlled
      isCollapsed={!filterPanelOpen}
      activeColor="textsupertransparent"
      passiveColor="textsupertransparent"
      setIsCollapsed={setFilterPanelOpen}
      header={
        <div className="d-flex w-100 align-items-center justify-content-start flex-column">
          <div className="d-flex w-100 align-items-center justify-content-start">
            <TextSpan weight={200} color="textsupertransparent" size="xs" className="mr-2">
              Filters:
            </TextSpan>
            <div
              className="d-flex w-100 align-items-center justify-content-start py-2"
              style={
                {
                  maxWidth: 'calc(100vw - 60px - 32px - 60px)',
                  overflowX: 'scroll',
                  '-webkit-overflow-scrolling': 'touch',
                } as React.CSSProperties
              }>
              <div style={{ opacity: 0, width: 0 }}>
                <Pill
                  bgColor="textsupertransparent"
                  text="hello"
                  iconClassName="fas fa-shopping-cart"
                />
              </div>
              {activeAccountIdFilter ? (
                <div
                  className="position-relative mx-2"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setActiveAccountIdFilter('')
                  }}>
                  {activeAccountIdFilter ? (
                    <AccountMaskAndLogo
                      orientation="horizontal"
                      accountId={activeAccountIdFilter}
                      logoSize={26}
                      maskSize="xxs"
                    />
                  ) : null}
                  <Icon
                    className="fas fa-circle-xmark"
                    color="texttransparent"
                    style={{
                      position: 'absolute',
                      top: '-8px',
                      right: '-8px',
                    }}
                  />
                </div>
              ) : null}
              {activeCategoryFilter ? (
                <div
                  className="position-relative mx-2"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setActiveCategoryFilter('')
                  }}>
                  <TransactionCategoryBadge
                    bgColor={
                      activeTypeFilter === 'variable'
                        ? 'yellow'
                        : activeTypeFilter === 'fixed'
                        ? 'mehred'
                        : 'text'
                    }
                    category={activeCategoryFilter}
                  />
                  <Icon
                    className="fas fa-circle-xmark"
                    color="texttransparent"
                    style={{
                      position: 'absolute',
                      top: '-8px',
                      right: '-8px',
                    }}
                  />
                </div>
              ) : null}

              {activeTypeFilter !== 'both' ? (
                <div
                  className="position-relative mx-2"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setActiveTypeFilter('both')
                  }}>
                  <TransactionTypeBadge calculationType={activeTypeFilter} />
                  <Icon
                    className="fas fa-circle-xmark"
                    color="texttransparent"
                    style={{
                      position: 'absolute',
                      top: '-8px',
                      right: '-8px',
                    }}
                  />
                </div>
              ) : null}
              {timeFrameFilter !== 'both' ? (
                <div
                  className="position-relative mx-2"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setTimeFrameFilter('both')
                  }}>
                  <TransactionDateRangeBadge timeFrameFilter={timeFrameFilter} />

                  <Icon
                    className="fas fa-circle-xmark"
                    color="texttransparent"
                    style={{
                      position: 'absolute',
                      top: '-8px',
                      right: '-8px',
                    }}
                  />
                </div>
              ) : null}
              {reflectionNeededFilter ? (
                <div
                  className="position-relative mx-2"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setReflectionNeededFilter(false)
                  }}>
                  <TransactionReflectionNeededBadge reflectionNeeded={reflectionNeededFilter} />

                  <Icon
                    className="fas fa-circle-xmark"
                    color="texttransparent"
                    style={{
                      position: 'absolute',
                      top: '-8px',
                      right: '-8px',
                    }}
                  />
                </div>
              ) : null}
            </div>
          </div>
          <StyledHr
            color="nearlyclear"
            weight={1}
            style={{
              opacity: filterPanelOpen ? 1 : 0,
            }}
          />
        </div>
      }
      body={
        <div className="d-flex w-100 align-items-start flex-column justify-content-between">
          <div className="d-flex w-100 align-items-center flex-row justify-content-between">
            <div
              className="d-flex flex-column justify-content-between align-items-center "
              style={
                {
                  // flex: 1,
                }
              }>
              <ToggleSwitchThreeway
                label="month"
                leftText="current"
                id="month-selector"
                centerText="both "
                rightText="previous"
                valueState={
                  timeFrameFilter === 'both'
                    ? 'center'
                    : timeFrameFilter === 'lastMonth'
                    ? 'right'
                    : 'left'
                }
                setValueState={setValueStateForMonths}
              />
            </div>
            <StyledVr />

            <div
              className="d-flex flex-column justify-content-between align-items-center"
              style={
                {
                  // flex: 1,
                }
              }>
              <ToggleSwitch
                label="reflection needed"
                enabled={reflectionNeededFilter}
                color={'logoPrimary'}
                toggleEnabled={() => setReflectionNeededFilter((v) => !v)}
                enabledText={'yes'}
                disabledText={'no'}
              />
            </div>
            <StyledVr />

            <div
              className="d-flex flex-column justify-content-between align-items-center"
              style={
                {
                  // flex: 1,
                }
              }>
              <ToggleSwitchThreeway
                leftText="variable"
                label={'type'}
                id="active-type-selector"
                centerText="both"
                leftColor="yellow"
                rightColor="mehred"
                rightText="fixed"
                valueState={
                  activeTypeFilter === 'both'
                    ? 'center'
                    : activeTypeFilter === 'fixed'
                    ? 'right'
                    : 'left'
                }
                setValueState={setValueStateForActiveTypeFilter}
              />
            </div>
          </div>

          <ActiveTransactionAccountPicker
            accounts={flatAccounts}
            activeAccountIdFilter={activeAccountIdFilter}
            setActiveAccountIdFilter={setActiveAccountIdFilter}
          />
          <ActiveTransactionCategoryPicker
            activeCategoryFilter={activeCategoryFilter}
            setActiveCategoryFilter={setActiveCategoryFilter}
            activeTypeFilter={activeTypeFilter}
          />
        </div>
      }
    />
  )
}
