import { useAuth0 } from '@auth0/auth0-react'
import { TextPopupSmall, TextSpan } from 'Components/DesignSystem/Typography'
import { SettingsItem } from 'Pages/SettingsPage/components'
import React from 'react'

export const UserLoggedInStatus: React.FC = () => {
  const { user, isAuthenticated } = useAuth0()
  return (
    <SettingsItem>
      <div className="d-flex align-items-center">
        <TextPopupSmall
          onClick={() => {
            alert(JSON.stringify(user, null, 2))
          }}>
          User
        </TextPopupSmall>
        <TextSpan>
          Logged In:{' '}
          <TextSpan weight={700} color={isAuthenticated ? 'logoPrimary' : 'mehred'}>
            {isAuthenticated ? 'true' : 'false'}
          </TextSpan>
        </TextSpan>
      </div>
    </SettingsItem>
  )
}
