import { MysteryDayType } from 'Providers/MysteryDayProvider/MysteryDayProvider.interfaces'
import color from 'color'
import styled from 'styled-components'
import { colorFromMysteryDayType } from '../../../Utilities/utils'

const ProgressCircleSizeValue = 16
const ProgressCircleSize = `${ProgressCircleSizeValue}px`

export const ProgressIndicatorDiv = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: 0 1rem;
  align-items: center;
`

interface ProgressIconProps {
  pageNumber: number
  numberOfPages: number
}
export const ProgressIcon = styled.div<ProgressIconProps>`
  span {
    position: absolute;
    top: -1.5rem;
  }
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* transform: ${(props) =>
    `translateX(calc( (( (100vw - 2rem) / ${props.numberOfPages})))* ${props.pageNumber})`}; */
  transform: ${(props) =>
    `translateX(calc( (( (100vw - 2rem) / ${props.numberOfPages})) * ${
      props.pageNumber - 1
    } - 6px))`};
  transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.3s;
`

interface ProgressCircleProps {
  completed: boolean
  active: boolean
  mysteryDayType: MysteryDayType
  visited?: boolean
}
export const ProgressCircle = styled.i<ProgressCircleProps>`
  display: block;
  width: ${ProgressCircleSize};
  min-width: ${ProgressCircleSize};
  height: ${ProgressCircleSize};
  min-height: ${ProgressCircleSize};
  opacity: ${(props) => (props.active ? 0 : 1)};
  transition: opacity ease-in-out 0.3s;

  color: ${(props) =>
    props.completed
      ? props.theme.colors[colorFromMysteryDayType(props.mysteryDayType)]
      : props.visited
      ? color(props.theme.colors[colorFromMysteryDayType(props.mysteryDayType)])
          .alpha(0.5)
          .toString()
      : props.theme.colors.textsupertransparent};
`
