import { FooterActionRow } from 'Components/FooterActionRow/FooterActionRow'
import { numberToCurrency } from 'Utilities/currencyFormater'
import { VARIABLE_ICON } from 'Utilities/navigationTabUtilities'
import React from 'react'
import { useCategoryBudgetHelpers } from './CategoryBudgetTable/helpers'

interface VariablePageFooterProps {}
export const VariablePageFooter: React.FC<VariablePageFooterProps> = () => {
  const { getTotalOfTargetCategories, getCustomVariableSpendTotal } = useCategoryBudgetHelpers()

  const variableSpendTotal = getCustomVariableSpendTotal()

  const variableSpendTotalLimit = getTotalOfTargetCategories()
  const variableSpentLeftover = variableSpendTotalLimit - variableSpendTotal

  // const { push } = useHistory()
  return (
    <FooterActionRow
      items={[
        {
          color: 'texttransparent',
          title: 'Total Budgeted',
          type: 'simple',
          subtitle: numberToCurrency(variableSpendTotalLimit, true, true),
          iconClassName: 'fas fa-envelope-open-dollar',
          onClick: () => {},
        },
        {
          color: 'yellow',
          title: 'Total Spent',
          type: 'simple',
          subtitle: numberToCurrency(variableSpendTotal, true, true),
          iconClassName: VARIABLE_ICON,

          onClick: () => {},
        },
        {
          color: variableSpentLeftover > 0 ? 'cashgreen' : 'mehred',
          title: variableSpentLeftover > 0 ? 'Left' : 'Over',
          type: 'simple',
          subtitle: numberToCurrency(variableSpentLeftover, true, true),
          iconClassName: variableSpentLeftover > 0 ? 'fas fa-face-smile' : 'fas fa-bell',
          onClick: () => {},
        },
      ]}
    />
  )
}
