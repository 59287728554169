import color from 'color'
import styled from 'styled-components/macro'
import { ColorType, FontSizeType } from 'theme'

interface StyledButtonProps {
  size?: FontSizeType
  colorPrimary?: ColorType
  colorSecondary?: ColorType
  jumbo?: boolean
  inactive?: boolean
}

export const StyledButtonButton = styled.button<StyledButtonProps>`
  &.inactive {
    opacity: 0.5;
  }

  transition: all ease-in-out 0.3s;

  border: ${(props) =>
    `solid 1px ${color(props.theme.colors[props.colorPrimary || 'text'])
      .darken(0.5)
      .toString()}`};
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;

  position: relative;

  i {
    margin-left: 0.25rem;
  }

  :hover,
  :active {
  }
  :disabled {
    opacity: 0.3;
  }

  position: relative;
`
