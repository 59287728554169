import {
  Icon,
  StyledHr,
  StyledVr,
  TextPopupSmall,
  TextSpan,
} from 'Components/DesignSystem/Typography'
import { useData } from 'Providers/APIDataProvider'
import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import { MysteryDayType } from 'Providers/MysteryDayProvider/MysteryDayProvider.interfaces'
import { useUIState } from 'Providers/UIStateProvider'
import { numberToCurrency } from 'Utilities/currencyFormater'
import { getMonthlyIncome } from 'Utilities/netWorth/netWorth'
import React from 'react'
import { colorPalletes } from 'theme'
import {
  VerticalStackedMoneyDisplay,
  VerticalStackedNumberDisplay,
} from './CashFlowRecap.components'

interface CashflowRecapHeaderProps {
  mysteryMode: MysteryDayType
  currentSavings: number
  monthlyIncome: number
  totalCost: number
  itemCost: number
  debtAmount: number
  recommendedContribution: number
  slim: boolean
}
export const CashflowRecapHeader: React.FC<CashflowRecapHeaderProps> = ({
  mysteryMode,
  currentSavings,
  monthlyIncome,
  totalCost,
  itemCost,
  debtAmount,
  recommendedContribution,
  slim,
}) => {
  const titleSize = 'sm'
  const valueSize = 'sm'
  const className = 'd-flex flex-column align-items-center'

  const { desiredTimeOff, cushionSubMode } = useMysteryDay()

  const { streams } = useData()
  const income = getMonthlyIncome(streams)
  const { activeTheme } = useUIState()

  const monthsWithSavings = Math.floor(currentSavings / totalCost)

  const { setMessagePopupColors, setMessagePopupContent } = useUIState()

  const percentageOfPaycheckSaveable = ((income - totalCost) / income) * 100

  switch (mysteryMode) {
    case 'buildCushion':
      return (
        <div className="d-flex w-100 flex-column justify-content-between px-2">
          <div className="d-flex w-100 justify-content-between align-items-center">
            {cushionSubMode === 'extendSavings' ? null : (
              <>
                <VerticalStackedMoneyDisplay
                  size={valueSize}
                  title="Income"
                  color="cashgreen"
                  amount={monthlyIncome}
                />
                <StyledVr />
              </>
            )}
            <VerticalStackedMoneyDisplay
              size={valueSize}
              title="Savings"
              color="cashgreen"
              amount={currentSavings}
            />
            <StyledVr />
            <VerticalStackedMoneyDisplay
              size={valueSize}
              title="Expenses"
              color="mehred"
              amount={totalCost}
            />
          </div>
          {!slim && mysteryMode === 'buildCushion' ? (
            <div
              style={{
                borderTop: `solid 1px ${colorPalletes[activeTheme].nearlyclear}`,
              }}
              className="d-flex w-100 flex-column justify-content-between align-items-center">
              <TextSpan size={titleSize} color="texttransparent" weight={700}>
                Current Financial Cushion
              </TextSpan>
              <TextSpan
                size={recommendedContribution < 0 ? 'sm' : 'md'}
                weight={700}
                color="softblue"
                className="ml-1 my-0 display-inline">
                {monthsWithSavings} months
                <TextSpan
                  size="xs"
                  weight={200}
                  color="texttransparent"
                  className="ml-1 my-0 display-inline">
                  of expenses covered by savings
                </TextSpan>{' '}
              </TextSpan>{' '}
              {percentageOfPaycheckSaveable < 0 ? null : (
                <TextSpan
                  size={recommendedContribution < 0 ? 'sm' : 'md'}
                  weight={700}
                  color="softblue"
                  className="ml-1 my-0 display-inline">
                  {Math.abs(percentageOfPaycheckSaveable).toFixed()}%
                  <TextSpan
                    size="xs"
                    weight={200}
                    color="texttransparent"
                    className="ml-1 my-0 display-inline">
                    of income that is available to save
                    <TextPopupSmall
                      className="ml-2 px-2 py-1"
                      color={'softblue'}
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()

                        setMessagePopupColors({
                          fgColor: 'softblue',
                          bgColor: 'background',
                        })
                        setMessagePopupContent(
                          'Why is this % important?<>One of the most empowering yet simple measures of our financial independence health is what percentage of our paycheck we are saving each month.<><>🦄 One of the magic moments in our own personal financial journeys came when we learned this reality, and thus focusing on (and increasing) this percentage has been immensely important.<><>To learn more, simply look up "The Shockingly Simple Math Behind Early Retirement", an article on the Mr. Money Moustache blog'
                        )
                      }}>
                      <Icon
                        color={'softblue'}
                        style={
                          {
                            minWidth: '24px',
                            minHeight: '24px',

                            '--fa-beat-fade-opacity': 0.67,
                            '--fa-beat-scale': 1.07,
                          } as React.CSSProperties
                        }
                        size={'lg'}
                        className={'fas fa-circle-info'}
                      />
                    </TextPopupSmall>
                  </TextSpan>
                </TextSpan>
              )}
              <StyledHr weight={1} color="nearlyclear" />
            </div>
          ) : null}
        </div>
      )

    case 'saveToQuit':
      const currentRunway = Math.floor(currentSavings / totalCost)
      return (
        <div className="d-flex w-100 flex-column justify-content-between px-2">
          <div className="d-flex w-100 justify-content-between align-items-center">
            <VerticalStackedMoneyDisplay
              size={valueSize}
              title="Income"
              color="cashgreen"
              amount={monthlyIncome}
            />
            <StyledVr />
            <VerticalStackedMoneyDisplay
              size={valueSize}
              title="Savings"
              color="cashgreen"
              amount={currentSavings}
            />
            <StyledVr />
            <VerticalStackedMoneyDisplay
              size={valueSize}
              title="Expenses"
              color="mehred"
              amount={totalCost}
            />
          </div>
          {slim ? null : (
            <div
              style={{
                borderTop: `solid 2px ${colorPalletes[activeTheme].nearlyclear}`,
              }}
              className="d-flex w-100 flex-row justify-content-between align-items-center pt-2">
              <VerticalStackedNumberDisplay
                color="second"
                title="Desired Time Off"
                unit="months"
                amount={desiredTimeOff}
              />
              <StyledVr />
              <VerticalStackedNumberDisplay
                color="second"
                title="Current Savings Runway"
                unit="months"
                amount={currentRunway}
              />
            </div>
          )}
        </div>
      )
    case 'getOutOfDebt':
      return (
        <div className="d-flex w-100 flex-column justify-content-between px-2">
          <div className="d-flex w-100 justify-content-between align-items-center">
            <VerticalStackedMoneyDisplay
              size={valueSize}
              title="Income"
              color="cashgreen"
              amount={monthlyIncome}
            />
            <StyledVr />
            <VerticalStackedMoneyDisplay
              size={valueSize}
              title="Expenses"
              color="mehred"
              amount={totalCost}
            />
            <StyledVr />
            <VerticalStackedMoneyDisplay
              size={valueSize}
              title="Debt"
              color="yellow"
              amount={debtAmount}
            />
          </div>

          {slim ? null : (
            <div
              style={{
                borderTop: `solid 2px ${colorPalletes[activeTheme].nearlyclear}`,
              }}
              className="d-flex w-100 flex-column justify-content-between align-items-center">
              <TextSpan size={titleSize} color="texttransparent">
                Initial Recommended Monthly Payment
              </TextSpan>
              <TextSpan
                size={recommendedContribution < 0 ? 'sm' : 'md'}
                weight={700}
                color="second"
                className="ml-1 my-0">
                {recommendedContribution < 0
                  ? 'Negative cash flow, cannot recommend'
                  : numberToCurrency(recommendedContribution)}
              </TextSpan>{' '}
            </div>
          )}
        </div>
      )

    default:
      return (
        <div className="d-flex w-100 flex-column justify-content-between px-2">
          <div className="d-flex w-100 justify-content-between align-items-center">
            <TextSpan className={className} size={titleSize} color="texttransparent">
              Income
              <TextSpan size={valueSize} weight={700} color="logoPrimary" className="ml-1 my-0">
                {numberToCurrency(monthlyIncome)}
              </TextSpan>{' '}
            </TextSpan>

            <StyledVr />
            <TextSpan className={className} size={titleSize} color="texttransparent">
              Expenses:
              <TextSpan size={valueSize} weight={700} color="mehred" className="ml-1 my-0">
                {numberToCurrency(totalCost)}
              </TextSpan>{' '}
            </TextSpan>
            <>
              <StyledVr />
              <TextSpan className={className} size={titleSize} color="texttransparent">
                {mysteryMode === 'getOrganized' ? 'Amount:' : ''}
                <TextSpan
                  size={valueSize}
                  weight={700}
                  color={mysteryMode === 'getOrganized' ? 'megapurple' : 'text'}
                  className="ml-1 my-0">
                  {numberToCurrency(mysteryMode === 'getOrganized' ? itemCost : 0)}
                </TextSpan>{' '}
              </TextSpan>
            </>
          </div>

          {!slim ? (
            <div
              style={{
                borderTop: `solid 1px ${colorPalletes[activeTheme].nearlyclear}`,
              }}
              className="d-flex w-100 flex-column justify-content-between align-items-center">
              <TextSpan size={titleSize} color="texttransparent">
                Initial Recommended Monthly Payment
              </TextSpan>
              <TextSpan
                size={recommendedContribution < 0 ? 'sm' : 'md'}
                weight={700}
                color="second"
                className="ml-1 my-0">
                {recommendedContribution < 0
                  ? 'Negative cash flow, cannot recommend'
                  : numberToCurrency(recommendedContribution)}
              </TextSpan>{' '}
            </div>
          ) : null}
        </div>
      )
  }
}
