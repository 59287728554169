import { Transaction } from 'plaid'

type MomentStringYYYY_MM_DD = string // 2020-01-01
type MomentDayOfMonth = number // 13
type MomentDayOfWeek = number // 02 (Monday)
export type EventType = 'income' | 'bill' | 'purchase' | 'creditcard'
export enum FrequencyType {
  'onetime', // once
  'daily', // every day
  'weekly', // every week
  'monthly', // every month
  'biweekly', // every 2 weeks
  'semiannually', // every 6 months
  'quarterly', // every quarter
  'anually', // every year
  'semimonthly', // twice a month
}

interface PlaidItemResponseInterface {
  item: {
    available_products: string[]
    billed_products: string[]
    consent_expiration_time: any
    error: any
    institution_id: string
    item_id: string
    webhook: string
  }
  request_id: string
  status: {
    last_webhook: {
      code_sent: string
      sent_at: string
    }
    transactions: {
      last_failed_update: any
      last_successful_update: string
    }
  }
}

export interface MoneyEvent {
  amount: number | string
  date: Date
  type: EventType
  name: string
  target?: 'cash' | 'savings'
  /**
   * the AccountId (or name) of where this MoneyEvent's amount is GOING TO
   * TODO: Should NOT be undefined when finishing this feature
   */
  destinationAccount?: string | null

  /**
   * the AccountId (or name) of where this MoneyEvent's amount is COMING FROM
   * TODO: Should NOT be undefined when finishing this feature
   */
  sourceAccount?: string | null
  bankLogo?: string
}

interface MoneyEventOverride {
  amount: number | string
  date: Date
  note: string
  name: string
}

export interface UpcomingEvent {
  date: string
  paidOff: boolean
}

export type SourceDestinationType = {
  accountIdOrName: string
  portionAllocated: number
}
interface BaseStream {
  name: string
  enabled: boolean
  amount: number
  transfer?: boolean
  source?: 'cash' | 'savings'
  destination?: 'cash' | 'savings'

  /**
   * The map of AccountIds (or names) that correspond to
   * destination accounts for where this money is GOING TO
   */
  destinations: SourceDestinationType[]

  /**
   * The map of AccountIds (or names) that correspond to
   * source accounts for where this money is COMING FROM
   */
  sources: SourceDestinationType[]
  type: EventType
  frequency: FrequencyType
  _id?: string
  userid: string
  isSubscription?: boolean
  savingsContribution?: number
  overrides?: MoneyEventOverride[]

  startDate?: string
  endDate?: string

  upcomingEvents?: UpcomingEvent[]
}

export interface OneTimeStream extends BaseStream {
  frequency: FrequencyType.onetime
  futureDate: MomentStringYYYY_MM_DD
  type: 'income' | 'purchase' | 'bill'
}

export interface DailyStream extends BaseStream {
  frequency: FrequencyType.daily
  type: 'income' | 'bill'
}

export interface WeeklyMonthlyStream extends BaseStream {
  frequency: FrequencyType.weekly | FrequencyType.monthly
  day: MomentDayOfMonth | MomentDayOfWeek
  type: 'income' | 'bill'
}

interface CreditCardStream extends BaseStream {
  futureDate: MomentStringYYYY_MM_DD
  frequency: FrequencyType.onetime
  creditCardGoal: number | string
  transactions: Transaction[]
  itemId: string
  type: 'creditcard'
  primary: boolean
}

export interface BiweeklyQuarterlySemiannuallyAnnuallyStream extends BaseStream {
  frequency:
    | FrequencyType.biweekly
    | FrequencyType.quarterly
    | FrequencyType.semiannually
    | FrequencyType.anually
  nextDate: MomentStringYYYY_MM_DD
  type: 'income' | 'bill'
}

export interface SemimonthlyStream extends BaseStream {
  frequency: FrequencyType.semimonthly
  mostRecentDate: MomentStringYYYY_MM_DD
  secondMostRecentDate: MomentStringYYYY_MM_DD
  type: 'income' | 'bill'
}

export type MoneyStream =
  | OneTimeStream
  | DailyStream
  | WeeklyMonthlyStream
  | CreditCardStream
  | BiweeklyQuarterlySemiannuallyAnnuallyStream
  | SemimonthlyStream

export interface Account {
  dueDate: string
  statementBalance: number
  accountName: string
  bankLogo: string
  primaryColor: string
  transactions: Transaction[]
  itemId: string
  mask: string
  limit: number | null
  balance: number
  lastUpdatedAt: string
  lastStatementIssueDate: string
}

export interface BankItem {
  name: string
  logo: string
  primary_color: string
  liabilities: Account[]
  item: PlaidItemResponseInterface
}
