import { CollapseCardControlled } from 'Components/CollapseCard/CollapseCardControlled'
import { ScrollingFull } from 'Components/DesignSystem/Layout/Containers/Containers'
import { Icon, TextSpan } from 'Components/DesignSystem/Typography'
import { SPLURV_LOCAL_STORAGE_PREFIX } from 'Hooks/useLocalStorageSync'
import React, { useEffect } from 'react'

import { useLocalStorageSyncedState } from 'Hooks/useLocalStorageSyncedState'
import { useUserConfig } from 'Providers/UserConfigProvider/UserConfigProvider'
import { FontSizeType } from 'theme'
import systemImage1 from './system_image_one.png'

type PanelType = 'pay-yourself' | 'simplify-spend' | 'have-why' | 'mcol'

export const MethodSteps: React.FC = () => {
  const [openPanel, setOpenPanel] = React.useState<PanelType | null>(null)

  const { setSystemPageSeen } = useUserConfig()

  // const [panelsSeen, setPanelsSeen] = React.useState<PanelType[]>([])
  const [panelsSeen, setPanelsSeen] = useLocalStorageSyncedState<PanelType[]>(
    [],
    'method-page-panels-seen'
  )

  const allPanelsSeen = panelsSeen.length === 4

  useEffect(() => {
    if (allPanelsSeen) {
      setSystemPageSeen(true)
    }
  }, [allPanelsSeen, setSystemPageSeen])

  const numberLogoSize: FontSizeType = 'lg'
  const textSize: FontSizeType = 'md'

  return (
    <ScrollingFull cutout={250}>
      <div className="flex-column d-flex w-100 align-items-start mt-2">
        <CollapseCardControlled
          activeColor="text"
          passiveColor="text"
          isCollapsed={openPanel !== 'mcol'}
          setIsCollapsed={() => {
            setOpenPanel((openPanel) => (openPanel === 'mcol' ? null : 'mcol'))
            setPanelsSeen((panelsSeen) => {
              const newArray: PanelType[] = [...panelsSeen, 'mcol']
              const newSet = new Set(newArray)
              const finalArray = Array.from(newSet)
              localStorage.setItem(
                `${SPLURV_LOCAL_STORAGE_PREFIX}_method_panels_seen`,
                JSON.stringify(finalArray)
              )
              return finalArray
            })
          }}
          skipBottomBorder
          beenVisited={panelsSeen.includes('mcol')}
          header={
            <div className="flex-row d-flex w-100 align-items-center my-0">
              <Icon
                style={{ minWidth: '28px' }}
                className="fas fa-flame mr-2"
                size={numberLogoSize}
                color="text"
              />
              <TextSpan color="text" size={textSize}>
                MCOL Awareness
              </TextSpan>
            </div>
          }
          body={
            <div className="flex-column d-flex w-100 align-items-start py-2">
              <TextSpan color="text" size="sm" className="mb-1 mt-2" style={{ display: 'inline' }}>
                <b>Monthly Cost Of Living</b>
              </TextSpan>
              <TextSpan color="text" size="sm" className="mb-1">
                Get detailed awareness of your Variable Spending + Fixed Expenses to understand what
                your lifestyle is costing you. Your MCOL has the most direct impact on your
                financial health and it is where you can make the most impact.
              </TextSpan>
            </div>
          }
        />
        <CollapseCardControlled
          skipBottomBorder
          isCollapsed={openPanel !== 'pay-yourself'}
          setIsCollapsed={() => {
            setOpenPanel((openPanel) => (openPanel === 'pay-yourself' ? null : 'pay-yourself'))
            setPanelsSeen((panelsSeen) => {
              const newArray: PanelType[] = [...panelsSeen, 'pay-yourself']
              const newSet = new Set(newArray)
              const finalArray = Array.from(newSet)
              localStorage.setItem(
                `${SPLURV_LOCAL_STORAGE_PREFIX}_method_panels_seen`,
                JSON.stringify(finalArray)
              )
              return finalArray
            })
          }}
          activeColor="text"
          passiveColor="text"
          beenVisited={panelsSeen.includes('pay-yourself')}
          header={
            <div className="flex-row d-flex w-100 align-items-center my-2">
              <Icon
                style={{ minWidth: '28px' }}
                className="fas fa-piggy-bank mr-2"
                size={numberLogoSize}
                color="text"
              />
              <TextSpan color="text" size={textSize}>
                Automatic Savings
              </TextSpan>
            </div>
          }
          body={
            <div className="flex-column d-flex w-100 align-items-start py-2">
              <img
                src={systemImage1}
                style={{
                  maxWidth: 'calc(80vw)',
                  background: '#071c23',
                  padding: '1rem',
                  alignSelf: 'center',
                  borderRadius: '8px',
                }}
                alt="test"
              />
              <TextSpan color="text" size="sm" className="mb-1 mt-2" style={{ display: 'inline' }}>
                <b>Direct deposit + Automation</b>
              </TextSpan>
              <TextSpan color="text" size="sm">
                Knowing your MCOL, update direct deposit to put a reasonable savings portion into a
                separate account on each paycheck. Make saving frictionless by removing yourself
                from the process and living off the rest.
              </TextSpan>
            </div>
          }
        />
        <CollapseCardControlled
          activeColor="text"
          passiveColor="text"
          isCollapsed={openPanel !== 'simplify-spend'}
          setIsCollapsed={() => {
            setOpenPanel((openPanel) => (openPanel === 'simplify-spend' ? null : 'simplify-spend'))
            setPanelsSeen((panelsSeen) => {
              const newArray: PanelType[] = [...panelsSeen, 'simplify-spend']
              const newSet = new Set(newArray)
              const finalArray = Array.from(newSet)
              localStorage.setItem(
                `${SPLURV_LOCAL_STORAGE_PREFIX}_method_panels_seen`,
                JSON.stringify(finalArray)
              )
              return finalArray
            })
          }}
          skipBottomBorder
          beenVisited={panelsSeen.includes('simplify-spend')}
          header={
            <div className="flex-row d-flex w-100 align-items-center my-2">
              <Icon
                className="fas fa-hand-holding-dollar mr-2"
                size={numberLogoSize}
                style={{ minWidth: '28px' }}
                color="text"
              />
              <TextSpan color="text" size={textSize}>
                Disciplined Spending
              </TextSpan>
            </div>
          }
          body={
            <div className="flex-column d-flex w-100 align-items-center py-2">
              <TextSpan color="text" size="sm" className="mb-1" style={{ display: 'inline' }}>
                <b>Debit cards + Envelope system.</b> <br />
                Stop carrying credit cards which make spending easy, convenient, and mindless . Use{' '}
                <b>debit</b> cards which force you to be <b>intentional, aware, and disciplined</b>{' '}
                with your spending. Leave credit cards locked away at home for online subscriptions
                only to remove temptation to overspend but maintain credit score. Save first to
                remove friction from saving. Spend with debit card to add friction to spending.
              </TextSpan>
            </div>
          }
        />

        <CollapseCardControlled
          skipBottomBorder
          passiveColor="text"
          beenVisited={panelsSeen.includes('have-why')}
          isCollapsed={openPanel !== 'have-why'}
          setIsCollapsed={() => {
            setOpenPanel((openPanel) => (openPanel === 'have-why' ? null : 'have-why'))
            setPanelsSeen((panelsSeen) => {
              const newArray: PanelType[] = [...panelsSeen, 'have-why']
              const newSet = new Set(newArray)
              const finalArray = Array.from(newSet)
              localStorage.setItem(
                `${SPLURV_LOCAL_STORAGE_PREFIX}_method_panels_seen`,
                JSON.stringify(finalArray)
              )
              return finalArray
            })
          }}
          header={
            <div className="flex-row d-flex w-100 align-items-center my-2">
              <Icon
                style={{ minWidth: '28px' }}
                className="fas fa-house-heart mr-2"
                size={numberLogoSize}
                color="text"
              />
              <TextSpan color="text" size={textSize}>
                Purpose Focus
              </TextSpan>
            </div>
          }
          body={
            <div className="flex-column d-flex w-100 align-items-start py-2">
              <TextSpan color="text" size="sm">
                <TextSpan color="text" size="sm" style={{ textDecoration: 'line-through' }}>
                  time is money
                </TextSpan>
                <TextSpan weight={700} className="ml-2" color="text" size="sm">
                  money is time.
                </TextSpan>
              </TextSpan>

              <div className="d-flex flex-row align-items-center w-100 justify-content-around my-3">
                <Icon color="text" size="xxl" className="fa-regular fa-hourglass-start" />
                <Icon
                  color="text"
                  size="xxl"
                  className="fa-regular fa-hourglass-half"
                  style={{ opacity: 0.75 }}
                />
                <Icon
                  color="text"
                  size="xxl"
                  className="fa-regular fa-hourglass-end"
                  style={{ opacity: 0.5 }}
                />
                <Icon
                  color="text"
                  size="xxl"
                  className="fa-regular fa-hourglass"
                  style={{ opacity: 0.25 }}
                />
              </div>
              <TextSpan color="text" size="sm">
                Time is the elusive and finite resource that once gone, is gone forever. Remember
                why it is that you want to change your money habits - unshackling from debt, leaving
                a draining job, spending time with family, pursing a passion - and remember this
                every time you spend.
              </TextSpan>
            </div>
          }
        />
      </div>
    </ScrollingFull>
  )
}
