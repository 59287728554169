import { Icon, StyledVr, TextSpan } from 'Components/DesignSystem/Typography'
import React from 'react'
import { ColorType } from 'theme'
import { IconHolder, MeterContainer } from './DesireMeter.components'

interface Props {
  desire: number
  setDesire: (desire: number) => void
  readonly: boolean
}

const desireToIconMapping: { [index: number]: string } = {
  1: 'face-smile',
  2: 'face-smiling-hands',
  3: 'face-scream',
  4: 'face-spiral-eyes',
  5: 'face-explode',
}

const desireToTextMapping: { [index: number]: React.ReactElement } = {
  1: (
    <>
      You want it, but are <b>happy to wait</b>.
    </>
  ),
  2: (
    <>
      You <b>really</b> want it, bummed to wait
    </>
  ),
  3: (
    <>
      You are <b>in love</b>, you need it now
    </>
  ),
  4: <>This is all you can think about</>,
  5: (
    <>
      The thought of not having it <b>hurts</b> your soul
    </>
  ),
}

const desireToColorMapping: { [index: number]: ColorType } = {
  1: 'mehred',
  2: 'orange',
  3: 'cashgreen',
  4: 'softblue',
  5: 'megapurple',
}

const desireLevels = Array.from({ length: 6 }, (_, i) => i + 1)
export const DesireMeter: React.FC<Props> = ({ desire, setDesire, readonly }) => {
  const color = desireToColorMapping[desire]
  const text = desireToTextMapping[desire]
  return (
    <div
      className="d-flex flex-column align-items-start px-2 w-100"
      style={{ opacity: readonly ? 0.6 : 1 }}>
      {readonly ? (
        <TextSpan color="textsupertransparent" size="xxs" className="mb-3" weight={700}>
          Emotional desire at the time of entry:
        </TextSpan>
      ) : (
        <TextSpan color="textsupertransparent" size="xxs" className="mb-3" weight={700}>
          How strong is the current desire for this impulse?
        </TextSpan>
      )}
      <MeterContainer>
        {desireLevels.map((level) => {
          const icon = desireToIconMapping[level]
          const color = desireToColorMapping[level]
          return (
            <IconHolder onClick={() => (readonly ? {} : setDesire(level))}>
              <Icon
                style={{ opacity: desire === level ? 1 : 0.3 }}
                className={`far fa-${icon}`}
                color={desire === level ? color : 'textsupertransparent'}
              />
            </IconHolder>
          )
        })}
      </MeterContainer>
      <div className="d-flex flex-row align-items-center justify-content-between my-3 w-100">
        <TextSpan
          color={color}
          size="md"
          className="my-0"
          weight={300}
          style={{
            minWidth: '33px',
          }}>
          {desire}
          /5
        </TextSpan>
        <StyledVr className="mx-2" color={color} />
        <TextSpan
          color={color}
          size="xxs"
          className="my-0 pr-1 d-inline"
          weight={300}
          style={{
            wordWrap: 'break-word',
            flex: 1,
          }}>
          {text}
        </TextSpan>
      </div>
    </div>
  )
}
