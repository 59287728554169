import { TextSlim } from 'Components/DesignSystem/Typography'
import React from 'react'
import { ThemeProvider } from 'styled-components'
import { myTheme } from 'theme'
import { SettingsItemDiv } from '../../components'

interface State {
  hasError: boolean
  error: any
  errorInfo: any
  showError: boolean
}

export class SettingsItemErrorBoundary extends React.Component<{}, State> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false, error: '', errorInfo: '', showError: false }
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.error('error', error)
    console.error('errorInfo', errorInfo)
    this.setState({ error, errorInfo })
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ThemeProvider theme={myTheme}>
          <SettingsItemDiv>
            <TextSlim>This setting is not currently available</TextSlim>
          </SettingsItemDiv>
        </ThemeProvider>
      )
    }

    return this.props.children
  }
}
