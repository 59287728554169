import React from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useAsyncLocalStorageState } from 'Hooks/useAsyncLocalStorageState'
import { defaultAsyncHookResponse } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import { useUserConfig } from 'Providers/UserConfigProvider/UserConfigProvider'
import { AsyncHookResponse, useAsyncFetch } from '../../Hooks/useAsyncFetch'
import { SPLURV_LOCAL_STORAGE_PREFIX, useLocalStorageSync } from '../../Hooks/useLocalStorageSync'

export interface TransactionMetadata {
  notes: string
  regret: boolean
  impulse: boolean
  recurring: boolean
  valueAlignment: boolean
  ignoreFromCalculations: boolean

  envelopeCategory: string
  fixedStreamName: string
  calculationType: 'skip' | 'fixed' | 'variable'
}

export interface AccountMetadata {
  account_id: string
  name: string
  spendingLimitPerMonth: number
  hidden: boolean
  tetheredCreditCards: string[]
}

export type CategoryKind = 'regular' | 'irregular'

export interface UserCustomSettings {
  userid: string
  linkedCreditAndDebitAccounts: { [index: string]: string[] }

  categoryMetadata: {
    [category: string]: {
      limit: number
      kind: CategoryKind
    }
  }

  transactionsMetadata: {
    [transactionId: string]: TransactionMetadata
  }
  accountMetadata: AccountMetadata[]
}

export type CalculationType = 'skip' | 'fixed' | 'variable'

export interface UpdateTransactionMetadataParams {
  transactionId: string
  notes: string
  regret: boolean | undefined
  impulse: boolean | undefined
  recurring: boolean | undefined
  valueAlignment: boolean | undefined
  ignoreFromCalculations: boolean | undefined
  envelopeCategory: string | undefined
  calculationType: CalculationType
  fixedStreamName: string | undefined
}

interface UserCustomSettingsContextState {
  settings: UserCustomSettings | null

  getSettings: () => Promise<any>

  updateTransactionMetadataLoading: boolean
  getSettingsLoading: boolean

  saveAccountName: (params: { account_id: string; name: string }) => Promise<any>
  saveAccountHidden: (params: { account_id: string; hidden: boolean }) => Promise<any>
  saveTetheredCreditCard: (params: {
    account_id: string
    tetheredCreditCard: string
  }) => Promise<any>
  saveTransactionMetadata: (params: UpdateTransactionMetadataParams) => Promise<any>

  saveAccountSpendingLimit: (params: {
    account_id: string
    spendingLimitPerMonth: number
  }) => Promise<any>

  saveCategorySpendingLimit: (params: {
    category: string
    limit: number
    kind: CategoryKind
  }) => Promise<any>

  deleteCategory: (params: { category: string }) => Promise<any>
  deleteCategoryLoading: boolean
  saveCategorySpendingLimitLoading: boolean
}

const defaultData: UserCustomSettingsContextState = {
  settings: null,

  getSettings: () => Promise.resolve(),

  updateTransactionMetadataLoading: false,
  getSettingsLoading: false,

  saveTransactionMetadata: (params: UpdateTransactionMetadataParams) => Promise.resolve(),
  saveAccountName: (params: { account_id: string; name: string }) => Promise.resolve(),
  saveAccountHidden: (params: { account_id: string; hidden: boolean }) => Promise.resolve(),
  saveTetheredCreditCard: (params: { account_id: string; tetheredCreditCard: string }) =>
    Promise.resolve(),
  saveAccountSpendingLimit: (params: { account_id: string; spendingLimitPerMonth: number }) =>
    Promise.resolve(),

  saveCategorySpendingLimit: (params: { category: string; limit: number }) => Promise.resolve(),
  deleteCategory: (params: { category: string }) => Promise.resolve(),
  deleteCategoryLoading: false,
  saveCategorySpendingLimitLoading: false,
}

const UserCustomSettingsContext = React.createContext<UserCustomSettingsContextState>(defaultData)

export const useUserCustomSettings = () => React.useContext(UserCustomSettingsContext)

/**
 * This provider is responsible for all data related to connected Plaid accounts
 * This includes accountData, liabilitiesData, transactionsData, and manualAccounts
 *
 * From these we also have derived data such as currentCash, currentDebt, currentSavings, currentCredit, netWorth, currentVariableExpenseAverage, and flexibleSpendPerMonth
 * @param
 * @returns
 */
export const UserCustomSettingsProvider: React.FC = ({ children }) => {
  // USER CUSTOM SETTINGS >>>>>>
  const localStorageKeyForUserCustomSettings = `${SPLURV_LOCAL_STORAGE_PREFIX}__${'GET'}-${'usercustomsettings'}`
  const localStorageDataForUserCustomSettings = localStorage.getItem(
    localStorageKeyForUserCustomSettings
  )
  const dataForUserCustomSettings = JSON.parse(
    localStorageDataForUserCustomSettings || JSON.stringify(defaultAsyncHookResponse)
  )

  const { hasSeenAppBefore } = useUserConfig()
  const { user } = useAuth0()

  const { issueRequest, res } = useAsyncFetch<UserCustomSettings, {}, {}>({
    method: 'GET',
    route: 'usercustomsettings',
    cachedData: dataForUserCustomSettings,
  })

  const [userCustomSettingsData, setUserCustomSettingsData] = React.useState<
    AsyncHookResponse<UserCustomSettings>
  >(defaultAsyncHookResponse)

  useLocalStorageSync({
    method: 'GET',
    path: 'usercustomsettings',
    setter: setUserCustomSettingsData,
    data: res,
  })

  const saveAccountNameData = useAsyncFetch<
    UserCustomSettings,
    {},
    { account_id: string; name: string }
  >({
    method: 'POST',
    route: 'usercustomsettings/account_name',
  })

  const saveAccountSpendingLimit = useAsyncFetch<
    UserCustomSettings,
    {},
    { account_id: string; spendingLimitPerMonth: number }
  >({
    method: 'POST',
    route: 'usercustomsettings/account_spending_limit',
  })

  // const saveCategorySpendingLimit = useAsyncFetch<
  //   UserCustomSettings,
  //   {},
  //   { category: string; limit: number }
  // >({
  //   method: 'POST',
  //   route: 'usercustomsettings/category_metadata_limit',
  // })

  const {
    issueRequest: saveCategorySpendingLimit,
    loading: saveCategorySpendingLimitLoading,
  } = useAsyncLocalStorageState<
    UserCustomSettings,
    {},
    {
      category: string
      limit: number
    }
  >({
    method: 'POST',
    route: 'usercustomsettings/category_metadata_limit',
  })

  const {
    issueRequest: deleteCategory,
    loading: deleteCategoryLoading,
  } = useAsyncLocalStorageState<
    UserCustomSettings,
    {
      category: string
    },
    {}
  >({
    method: 'DELETE',
    route: 'usercustomsettings/category_metadata_limit',
  })

  const saveAccountVisibility = useAsyncFetch<
    UserCustomSettings,
    {},
    { account_id: string; hidden: boolean }
  >({
    method: 'POST',
    route: 'usercustomsettings/account_hidden',
  })

  const {
    issueRequest: updateTransactionMetadata,
    loading: updateTransactionMetadataLoading,
  } = useAsyncLocalStorageState<any, {}, UpdateTransactionMetadataParams>({
    method: 'POST',
    route: 'usercustomsettings/transaction_metadata',
  })

  const {
    issueRequest: saveCreditCardTether,
    // loading: saveCreditCardTetherLoading,
  } = useAsyncLocalStorageState<
    UserCustomSettings,
    {},
    { account_id: string; tetheredCreditCard: string }
  >({
    method: 'POST',
    route: 'usercustomsettings/account_tethered_credit_card',
  })

  React.useEffect(() => {
    if (hasSeenAppBefore && user) {
      issueRequest({})
    }
  }, [issueRequest, hasSeenAppBefore, user])

  return (
    <UserCustomSettingsContext.Provider
      value={{
        getSettings: () => issueRequest({}),
        saveTetheredCreditCard: (params) =>
          saveCreditCardTether({
            bodyParamsConfig: params,
          }),
        getSettingsLoading: userCustomSettingsData.status === 'loading',
        settings: userCustomSettingsData.data,
        updateTransactionMetadataLoading,
        saveTransactionMetadata: (params) =>
          updateTransactionMetadata({ bodyParamsConfig: params }),
        saveAccountName: (params) =>
          saveAccountNameData.issueRequest({
            bodyParamsConfig: params,
          }),
        saveAccountHidden: (params) =>
          saveAccountVisibility.issueRequest({
            bodyParamsConfig: params,
          }),
        saveAccountSpendingLimit: (params) =>
          saveAccountSpendingLimit.issueRequest({
            bodyParamsConfig: params,
          }),

        saveCategorySpendingLimit: (params) =>
          saveCategorySpendingLimit({
            bodyParamsConfig: params,
          }),
        deleteCategory: (params) =>
          deleteCategory({
            queryParamsConfig: params,
          }),
        deleteCategoryLoading,
        saveCategorySpendingLimitLoading,
      }}>
      {children}
    </UserCustomSettingsContext.Provider>
  )
}
