import { TextSlimTight } from 'Components/DesignSystem/Typography'
import { useUIState } from 'Providers/UIStateProvider'
import { TransactionMetadata } from 'Providers/UserCustomSettingsProvider/UserCustomSettingsProvider'
import color from 'color'
import React from 'react'
import { colorPalletes } from 'theme'

interface Props {
  reflectionNeeded: boolean
}
export const TransactionReflectionNeededBadge: React.FC<Props> = ({ reflectionNeeded }) => {
  const { activeTheme } = useUIState()
  if (reflectionNeeded) {
    return (
      <TextSlimTight
        size="xxs"
        style={{
          textAlign: 'left',
          justifyContent: 'flex-start',
          whiteSpace: 'nowrap',
          lineHeight: '.75rem',
          margin: '.25rem 0',
          padding: '.25rem .5rem',
          borderRadius: '4px',
          textTransform: 'uppercase',
          color: color(colorPalletes[activeTheme]['logoPrimary']).darken(0.6).toString(),
          backgroundColor: color(colorPalletes[activeTheme]['logoPrimary']).toString(),
        }}
        weight={700}>
        reflection needed
      </TextSlimTight>
    )
  } else {
    return null
  }
}

interface TransactionReflectionNeededDotProps {
  m: TransactionMetadata | undefined
}
export const TransactionReflectionNeededDot: React.FC<TransactionReflectionNeededDotProps> = ({
  m,
}) => {
  const { activeTheme } = useUIState()
  const reflectionNeeded =
    !m?.envelopeCategory && !m?.fixedStreamName && m?.calculationType !== 'skip'
  if (reflectionNeeded) {
    return (
      <div
        style={{
          minHeight: '4px',
          marginRight: '4px',
          padding: 0,
          maxHeight: '4px',
          height: '4px',
          width: '4px',
          minWidth: '4px',
          maxWidth: '4px',
          borderRadius: '50%',
          background: colorPalletes[activeTheme]['logoPrimary'],
        }}
      />
    )
  }
  return null
}
