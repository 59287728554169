import color from 'color'
import styled from 'styled-components/macro'

interface CircleProps {
  status?: 'good' | 'bad' | 'neutral'
  isToday?: boolean
  isPast?: boolean
  isMultiple?: boolean
  day: number
}
export const CalendarCircle = styled.div<CircleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  font-size: 10px;
  font-family: ${(props) => props.theme.fonts.family.secondary};
  font-weight: 400;
  color: ${(props) => `${props.theme.colors.texttransparent}`};

  background-color: ${(props) =>
    props.status === 'good' || props.status === 'neutral'
      ? color(props.theme.colors.cashgreen).alpha(0.05).toString()
      : color(props.theme.colors.mehred).alpha(0.2).toString()};
  opacity: ${(props) => (props.isPast ? 0.5 : 1)};
  border-radius: 50%;
  border: ${(props) =>
    props.isMultiple
      ? 'transparent'
      : // : props.status === 'bad'
      props.isToday
      ? `solid 2px ${props.theme.colors.text}`
      : 'solid 2px rgb(73, 85, 90)'};
  background-clip: content-box;
  position: relative;

  i {
    font-size: 1.25rem;
  }

  ::after {
    display: block;
    content: ${(props) => `'${props.day}'`};
    position: absolute;
    top: -10px;
    right: -5px;
    color: ${(props) => props.theme.colors.text};
    font-weight: ${(props) => (props.isToday ? '800' : '100')};
    font-size: 0.675rem;
  }
`

export const CustomMoneySackIcon = styled.i`
  --fa-primary-color: ${(props) => props.theme.colors.second};
  --fa-secondary-color: ${(props) => props.theme.colors.cashgreen};
  font-size: 1.25rem !important;
`

export const PieCircleContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .piecontent {
    position: absolute;
  }
`

export const CalendarSquare = styled.div<{
  isNegativeBalance: boolean
  isNegativeDelta: boolean
  isPast: boolean
  hasEvents: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  min-height: 48px;
  opacity: ${(props) => (props.isPast ? 0.5 : 1)};
  div.innerChild {
    position: relative;
  }

  span.total {
    position: absolute;
    font-style: italic;
    top: -1.25rem;
    &.green {
      color: ${(props) => props.theme.colors.cashgreen};
    }
    &.red {
      color: ${(props) => props.theme.colors.mehred};
    }
    &.orange {
      color: ${(props) => props.theme.colors.orange};
    }
    &.purple {
      color: ${(props) => props.theme.colors.megapurple};
    }
  }
  span.delta {
    position: absolute;
    bottom: -1rem;
    color: ${(props) =>
      props.isNegativeDelta ? props.theme.colors.mehred : props.theme.colors.cashgreen};
    font-weight: 600;
  }

  &::after {
    position: absolute;

    content: '\\f06a';

    font-family: 'Font Awesome 5 Pro';
    font-weight: 200;
    font-size: ${(props) => props.theme.fonts.sizes['xxs']};
    top: ${(props) => (props.hasEvents ? '-2rem' : '-1rem')};
    display: ${(props) => (props.isNegativeBalance ? 'block' : 'none')};
    color: ${(props) => props.theme.colors.mehred};
  }
`

interface ContentProps {
  startDayOfWeek: number
}
export const ContentDiv = styled.div<ContentProps>`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
  max-width: calc(100vw - 2rem);
  min-width: calc(100vw - 2rem);
  grid-row-gap: 24px;

  @media (min-height: 750px) {
    grid-row-gap: 40px;
  }
  * {
    display: flex;
    align-items: center;
  }

  &.cal {
    div:nth-child(1) {
      grid-column: ${(props) => props.startDayOfWeek + 1};
    }
  }
`

interface DayOfWeekLabelProps {
  isWeekend?: boolean
}
export const DayOfWeekLabel = styled.label<DayOfWeekLabelProps>`
  font-family: ${(props) => props.theme.fonts.family.secondary};
  color: ${(props) =>
    props.isWeekend ? props.theme.colors.textsupertransparent : props.theme.colors.text};
  font-weight: ${(props) => (props.isWeekend ? 800 : 200)};
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-right: 8px; */
  margin-bottom: -24px;
  text-transform: capitalize;
`
