import { useAuth0 } from '@auth0/auth0-react'
import { useGetAccessToken } from 'Hooks/useGetAccessToken'
import React, { useEffect } from 'react'

import { LoginLoadingComponent } from './LoginLoadingComponent'

interface Props {
  loadingToken: boolean
}

export const FancyLoginStuff: React.FC<Props> = ({ loadingToken }) => {
  const { forceGetNewRefreshToken } = useGetAccessToken(
    'FancyLoginStuff -> forceGetNewRefreshToken'
  )
  const { isAuthenticated, user } = useAuth0()

  useEffect(() => {
    if ((!isAuthenticated || !user) && !loadingToken) {
      forceGetNewRefreshToken()
    }
  }, [isAuthenticated, loadingToken, forceGetNewRefreshToken, user])
  return <LoginLoadingComponent loading={loadingToken} />
}
