import { Icon, TextPopupSmall, TextSlim } from 'Components/DesignSystem/Typography'
import { useFinancialData } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import { useUIState } from 'Providers/UIStateProvider'
import { WideButton } from 'design-system/buttons/WideButton/WideButton'
import React from 'react'

export const CreditCardErrorPage: React.FC = () => {
  const { setAddCardPageOpen, setMessagePopupContent } = useUIState()
  const {
    getAccounts,
    accountsData,

    getLiabilities,
    getManualAccounts,
  } = useFinancialData()

  return (
    <div className="d-flex w-100 flex-column">
      <TextSlim size="md">It Appears We Made an Oof 🙈</TextSlim>
      <TextSlim size="sm">Please try refreshing again to reload your accounts</TextSlim>
      <div className="d-flex flex-row align-items-center width-100 mt-3">
        <WideButton
          bgColor={'logoPrimary'}
          onClick={() => {
            setAddCardPageOpen('')
          }}
          text="Link New Account"
          width="fit"
          textSize="small"
          appearance="secondary"
          active
          icon={{
            position: 'right',
            className: 'fak fa-light-building-columns-circle-dollar',
          }}
        />
        <WideButton
          bgColor={'second'}
          onClick={() => {
            getAccounts({})
            getLiabilities({})
            getManualAccounts({})
          }}
          text="Refresh Accounts"
          width="fit"
          textSize="small"
          appearance="secondary"
          active
          icon={{
            position: 'right',
            className: 'fas fa-sync',
          }}
        />
      </div>
      <TextPopupSmall
        color="mehred"
        className="mb-5 mx-1 p-3"
        onClick={() => {
          setMessagePopupContent(
            `<>Error Fetching Transactions'<>${JSON.stringify(accountsData.error, null, 2)}`
          )
        }}>
        Error Fetching Accounts <Icon color="mehred" className="fas fa-exclamation-triangle" />
      </TextPopupSmall>
    </div>
  )
}
