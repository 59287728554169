import { AppDarkOverlay } from 'App/components'
import {
  Icon,
  StyledHr,
  StyledVr,
  TextBold,
  TextBoldFancy,
  TextSlim,
  TextSpan,
} from 'Components/DesignSystem/Typography'
import { PopupComponent } from 'Components/PopupComponent/PopupComponent'
import { useUIState } from 'Providers/UIStateProvider'
import React from 'react'
import {
  InstallButton,
  PageContainer,
  ScreenshotCarousel,
  ScreenshotSlot,
  WelcomeContainer,
} from '../PWAcomponents'

// import splurvLogo512 from 'Logos/splurv_logo_transparent_1024.png'
import splurvLogo512 from 'Logos/logo512old.png'
import desiresDemo from './screenshots/desktop/desires.png'
import graphDemo from './screenshots/desktop/graph.png'
import spendingDemo from './screenshots/desktop/spending.png'

import { LatestGitInfo } from 'Components/LatestGitInfo/LatestGitInfo'
// import onboarding from './screenshots/onboarding.png'

import { isMobile } from 'Utilities/utils'
import styled, { ThemeProvider } from 'styled-components'
import { FontSizeType, colorPalletes, myTheme } from 'theme'
import { InstallingSplurvPopup } from './InstallingSplurvPopup'
import graph from './screenshots/new/graph-new.png'
import impulse from './screenshots/new/impulse-new.png'
import main from './screenshots/new/main-new.png'
import mission from './screenshots/new/mission-new.png'
import onboarding from './screenshots/new/onboarding-new.png'
import reflection from './screenshots/new/reflection-new.png'
import spending from './screenshots/new/spending-new.png'

export const AppLandingPage: React.FC = () => {
  const { setPopup, popup } = useUIState()

  const mobileDevice = isMobile()

  const titleSize: FontSizeType = mobileDevice ? 'sm' : 'xl'
  const bodySize: FontSizeType = mobileDevice ? 'xs' : 'md'

  // const [landingMode, setLandingMode] = React.useState<'app' | 'signup'>('signup')

  const [isDesktop, setIsDesktop] = React.useState(false)

  // React.useEffect(() => {
  //   const inviteQueryParam = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  //     .invitedUser
  //   if (inviteQueryParam) {
  //     setLandingMode('app')
  //     localStorage.setItem(
  //       `${SPLURV_LOCAL_STORAGE_PREFIX}__invitedUser`,
  //       inviteQueryParam as string
  //     )

  //     datadogRum.addAction('invitedUser-atDownloadPage', {
  //       invitedUser: inviteQueryParam,
  //     })
  //     datadogRum.addRumGlobalContext('invitedUser-queryStorage', {
  //       invitedUser: inviteQueryParam,
  //       location: 'app-landing-page',
  //     })
  //   }
  // }, [])

  React.useEffect(() => {
    const updateIsDesktop = () => {
      const mobileWidth = window.matchMedia('(max-width: 768px)')
      setIsDesktop(!mobileWidth.matches && !mobileDevice)
    }

    const mobileWidth = window.matchMedia('(max-width: 768px)')

    mobileWidth.addEventListener('change', updateIsDesktop)

    updateIsDesktop()

    return () => {
      mobileWidth.removeEventListener('change', updateIsDesktop)
    }
  }, [mobileDevice])

  const [showVersionInfo, setShowVersionInfo] = React.useState(false)
  if (window.location.pathname.includes('newauthpage')) {
    return (
      <WelcomeContainer data-testid="welcome-to-splurv-message">
        <PageContainer>
          <img
            alt=""
            src={splurvLogo512}
            style={{ width: '64px', height: '64px', borderRadius: '8px' }}
          />
          <TextBoldFancy size="xxxl" color="second" className="mt-3">
            Splurv
          </TextBoldFancy>
          <TextSlim weight={700} color="second" size="sm" className="mt-4">
            The money app about time.
          </TextSlim>
          <TextSlim color="second" size="md" className="mt-2">
            <b>Are you here a second time?</b>
          </TextSlim>
          <TextSlim color="second" size="sm">
            You were probably connecting a bank account and went through a few redirects and ended
            up here again. We'll save you the long technical explanation of what's happening, but
            worry not, this is a known issue we are trying to solve.
          </TextSlim>

          <TextSlim color="second" size="sm">
            Don't add the app a second time, but instead just go back to the app you previously
            added as a bookmark to your homescreen and the Plaid linking process will finish.
          </TextSlim>

          <TextSlim color="second" size="sm">
            Thanks for the patience while we try to blaze a path forward in the industry where apps
            can be made without the app store.
          </TextSlim>

          <StyledHr color="textsupertransparent" className="my-2" style={{ opacity: 0.2 }} />
        </PageContainer>
      </WelcomeContainer>
    )
  }

  if (isDesktop) {
    return (
      <WelcomeContainer
        data-testid="welcome-to-splurv-message"
        pauseScroll={!!popup}
        style={{
          justifyContent: 'center',
        }}
        // appMode={landingMode === 'app'}>
        appMode={false}>
        <div className="d-flex flex-row align-items-center w-100 justify-content-around">
          <div className="d-flex align-items-center flex-column">
            <img
              alt=""
              src={splurvLogo512}
              style={{
                width: '160px',
                height: '160px',
                borderRadius: '10px',
              }}
              className="mb-2"
            />
            <TextBold
              onClick={() => {
                setShowVersionInfo((vi) => !vi)
              }}
              size="xxxxl"
              // color={landingMode === 'signup' ? 'text' : 'logoSecond'}
              color={'logoPrimary'}
              className="my-3 p-0">
              Splurv
            </TextBold>
            <TextSpan color="logoPrimary" className="my-3">
              Desktop app coming soon
            </TextSpan>

            <StyledHr color="second" className="my-3" />

            <TextSpan
              style={{ display: 'inline' }}
              weight={300}
              color="logoSecondary"
              size={bodySize}
              className="align-self-start mb-5 text-center">
              Our vision is as simple as it is grand: <br />
              <TextSpan
                color="logoSecondary"
                className="text-center"
                weight={400}
                size="lg"
                style={{ maxWidth: '250px' }}>
                To free people from financial overwhelm and empower them to write their own story.
              </TextSpan>
            </TextSpan>
          </div>

          <div className="d-flex flex-column align-items-center ">
            <div className="d-flex flex-row align-items-center justify-content-end">
              <img
                src={graphDemo}
                alt="graph-demo"
                style={{
                  maxHeight: '70vh',
                }}
              />
              <img
                src={spendingDemo}
                alt="spending-demo"
                style={{
                  maxHeight: '70vh',
                }}
              />
              <img
                src={desiresDemo}
                alt="desires-demo"
                style={{
                  maxHeight: '70vh',
                }}
              />
            </div>
            <TextSpan color="logoPrimary" size="md" weight={700}>
              To get our mobile app, open app.splurv.io on a mobile device.
            </TextSpan>
          </div>
        </div>
        <div style={{ height: '100px' }} />
        <FooterContainer>
          <TextSlim weight={600} color="texttransparent" size={'xs'}>
            © 2024 Splurv. All rights reserved
          </TextSlim>
          <StyledVr color="texttransparent" className="mx-2 styled-vr" weight={0.5} size="xs" />
          <TextSlim weight={300} color="texttransparent" size={'xs'}>
            <a href="https://www.iubenda.com/privacy-policy/45091906">Privacy Policy</a>
          </TextSlim>
          <StyledVr color="texttransparent" className="mx-2 styled-vr" weight={0.5} size="xs" />
          <TextSlim weight={300} color="texttransparent" size={'xs'}>
            <a href="https://www.iubenda.com/privacy-policy/45091906/cookie-policy">
              Cookie Policy
            </a>
          </TextSlim>
          <StyledVr color="texttransparent" className="mx-2 styled-vr" weight={0.5} size="xs" />
          <TextSlim weight={300} color="texttransparent" size={'xs'}>
            <a href="https://www.iubenda.com/terms-and-conditions/45091906">Terms and Conditions</a>
          </TextSlim>
        </FooterContainer>
      </WelcomeContainer>
    )
  }

  return (
    <WelcomeContainer data-testid="welcome-to-splurv-message" pauseScroll={!!popup} appMode={false}>
      <PageContainer pauseScroll={!!popup}>
        <AppDarkOverlay active={!!popup} />
        <PopupComponent customColorPallete="newDark" />
        <section>
          <div className="d-flex align-items-center w-100 flex-row">
            <img
              alt=""
              src={splurvLogo512}
              style={{
                width: '64px',
                height: '64px',
                borderRadius: '10px',
              }}
              className="mr-4"
            />

            <div className="d-flex align-items-start flex-column">
              <TextBold
                onClick={() => {
                  setShowVersionInfo((vi) => !vi)
                }}
                size="xxl"
                // color={landingMode === 'signup' ? 'text' : 'second'}
                color={'text'}
                className="m-0 p-0">
                Splurv
              </TextBold>
              <div className="d-flex align-items-center flex-row">
                <TextSpan
                  size="xs"
                  // color={landingMode === 'signup' ? 'textsupertransparent' : 'second'}
                  color={'textsupertransparent'}
                  className="mt-2">
                  {/* {landingMode === 'signup' ? 'splurv.io' : 'app.splurv.io'} */}
                  {'app.splurv.io'}
                </TextSpan>
                {/* show this for personal usage */}
                <div
                  className="d-flex flex-row align-items-center"
                  style={{
                    opacity: showVersionInfo ? 1 : 0,
                    display: showVersionInfo ? 'block' : 'none',

                    transition: 'all .3s ease-in-out',
                  }}>
                  <StyledVr color="second" className="mx-2" />
                  <TextSpan size="xxxxs" color="second" className="mt-2">
                    <LatestGitInfo />
                  </TextSpan>
                </div>
              </div>
            </div>
          </div>
          <InstallButton
            // color={landingMode === 'signup' ? 'logoPrimary' : 'second'}
            color={'logoPrimary'}
            className="mt-4"
            onClick={async () => {
              // if (landingMode === 'signup') {
              //   setPopup(<SplurvBetaSignupPopup />)
              // } else {
              setPopup(<InstallingSplurvPopup />)
              // }
            }}>
            <Icon
              // className={landingMode === 'signup' ? 'fad fa-cauldron' : 'fas fa-arrow-down'}
              className={'fas fa-arrow-down'}
              size="lg"
              // color={landingMode === 'signup' ? 'logoPrimary' : 'second'}
              color={'logoPrimary'}
            />
            <TextSpan
              size="md"
              // color={landingMode === 'signup' ? 'logoPrimary' : 'second'}
              color={'logoPrimary'}
              className="ml-2"
              weight={400}>
              {/* {landingMode === 'signup' ? 'join beta' : 'download app'} */}
              download app
            </TextSpan>
          </InstallButton>

          {/* <ScreenshotCarousel appMode={landingMode === 'app'}> */}
          <ScreenshotCarousel appMode={false}>
            <ScreenshotSlot src={main} />
            {/* {landingMode === 'signup' ? ( */}
            <>
              <ScreenshotSlot src={onboarding} />
              <ScreenshotSlot src={graph} />
              <ScreenshotSlot src={spending} />
              <ScreenshotSlot src={impulse} />
              <ScreenshotSlot src={reflection} />
              <ScreenshotSlot src={mission} />
            </>
            {/* ) : (
              <></>
            )} */}
          </ScreenshotCarousel>
          {/* {landingMode === 'signup' ? ( */}
          <>
            <StyledHr color="logoSecondary" className="my-3" />
            <ThemeProvider theme={{ ...myTheme, colors: colorPalletes['newDark'] }}>
              <TextSpan
                style={{ display: 'inline' }}
                weight={500}
                color="logoSecondary"
                size={mobileDevice ? 'md' : 'lg'}
                className={`align-self-start px-${mobileDevice ? 3 : 5} text-center my-${
                  mobileDevice ? 1 : 2
                }`}>
                Splurv is a personal finance app that brings{' '}
                <TextSpan weight={700} className="my-0" color="logoPrimary">
                  time
                </TextSpan>{' '}
                and{' '}
                <TextSpan weight={700} className="my-0" color="logoPrimary">
                  purpose
                </TextSpan>{' '}
                into the equation when making decisions about <b>money</b>. <br />
              </TextSpan>
              <TextSlim
                weight={700}
                color="logoSecondary"
                size={titleSize}
                className="align-self-start mt-2">
                Our Approach
              </TextSlim>
              <TextSpan
                style={{ display: 'inline' }}
                weight={300}
                color="logoSecondary"
                size={bodySize}
                className="align-self-start">
                <b>Mindful awareness of consumerism.</b> If saving was as easy as <i>just</i>{' '}
                spending less, everyone would do it. As emotional, irrational creatures, we need
                more than numbers and shame to change. Splurv focuses on the emotional states we're
                in when we desire new things - or have made regrettable purchases - and invites
                reflection on our habits in order to learn from them.
              </TextSpan>
              <TextSlim
                weight={700}
                color="logoSecondary"
                size={titleSize}
                className="align-self-start mt-2">
                Our Mission
              </TextSlim>
              <TextSpan
                style={{ display: 'inline' }}
                weight={300}
                color="logoSecondary"
                size={bodySize}
                className="align-self-start">
                Bring agency and confidence to people's spending habits by focusing on factors more
                powerful than just money: purpose, time, and emotion. We believe that through
                loosening the shackles on our jobs - by trading materialism and consumerism for
                simplicity and saving - we can live with more intention and balance.
              </TextSpan>
              <TextSlim
                weight={700}
                color="logoSecondary"
                size={titleSize}
                className="align-self-start mt-2">
                Our Vision
              </TextSlim>
              <TextSpan
                style={{ display: 'inline' }}
                weight={300}
                color="logoSecondary"
                size={bodySize}
                className="align-self-start mb-5">
                Our vision is as simple as it is grand: <br />
                <TextSpan color="logoSecondary" weight={800} size="sm">
                  To free people from financial overwhelm and empower them to write their own story.
                </TextSpan>
              </TextSpan>
              <div className="d-flex items-center mb-4">
                <iframe
                  title="pwa-store-badge"
                  src="https://static.store.app/widget/badge/index.html?sitePath=app-splurv-io&theme=dark"
                  style={{
                    width: '250px',
                    height: '122px',
                    border: 'none',
                    borderRadius: '12px',
                  }}></iframe>
              </div>
              <div
                style={{
                  height: '200px',
                }}
              />
            </ThemeProvider>
          </>
          {/* ) : (
            <>
              <TextBold size="md" color="second">
                Welcome
              </TextBold>
              <TextSpan color="second" className="text-center">
                Click download to get the app.
              </TextSpan>
            </>
          )} */}

          {/* <TextSlim
            weight={700}
            color="logoSecondary"
            size="sm"
            className="align-self-start mt-2">
            You're not on the App Store?
          </TextSlim>
          <TextSpan
            style={{ display: 'inline', marginBottom: '5rem' }}
            weight={300}
            color="logoSecondary"
            size="xs"
            className="align-self-start ">
            Aside from technical and business hurdles - long install times, difficulty getting
            update approvals, and 15-30% fees that are charged by Apple and Google App stores - we
            wanted to lower the barrier to entry <i>for you.</i> This <i>is the app.</i> Almost. If
            you click Install and follow the 'Add to Home Screen' prompts, you'll get our app. Sound
            like wizardry? Welcome to <b>Splurv</b> 🎉 🦄
          </TextSpan> */}
        </section>

        <FooterContainer>
          <TextSlim weight={600} color="texttransparent" size={'xs'}>
            © 2024 Splurv. All rights reserved
          </TextSlim>
          <StyledVr color="texttransparent" className="mx-2 styled-vr" weight={0.5} size="xs" />
          <TextSlim weight={300} color="texttransparent" size={'xs'}>
            <a href="https://www.iubenda.com/privacy-policy/45091906">Privacy Policy</a>
          </TextSlim>
          <StyledVr color="texttransparent" className="mx-2 styled-vr" weight={0.5} size="xs" />
          <TextSlim weight={300} color="texttransparent" size={'xs'}>
            <a href="https://www.iubenda.com/privacy-policy/45091906/cookie-policy">
              Cookie Policy
            </a>
          </TextSlim>
          <StyledVr color="texttransparent" className="mx-2 styled-vr" weight={0.5} size="xs" />
          <TextSlim weight={300} color="texttransparent" size={'xs'}>
            <a href="https://www.iubenda.com/terms-and-conditions/45091906">Terms and Conditions</a>
          </TextSlim>
        </FooterContainer>
        <div style={{ height: '50px' }} />
      </PageContainer>
    </WelcomeContainer>
  )
}

export const FooterContainer = styled.div`
  align-self: center;
  text-align: center;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  .styled-vr {
    display: none;
  }

  a {
    transition: all ease-in-out 0.3s;
  }

  a:hover {
    color: #83ffb3;
    transition: all ease-in-out 0.3s;
  }

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;

    .styled-vr {
      display: block;
    }
  }
`
