import { TextPopupSmall, TextSpan } from 'Components/DesignSystem/Typography'
import { LightIndicator } from 'Providers/PWAProvider/PWAcomponents'
import React from 'react'
import { SettingsItem } from './components'
import { useGetNotificationsEnabledStatus } from './useGetNotificationsEnabledStatus'

export const AppExternalMetadata: React.FC = () => {
  const domainEnvKey = `REACT_APP_${process.env.REACT_APP_APP_ENV}_AUTH0_DOMAIN`
  const domain = String(process.env[domainEnvKey])
  const baseRoute = process.env[`REACT_APP_${process.env.REACT_APP_APP_ENV}_API_URL`]

  const audience = String(process.env[`REACT_APP_${process.env.REACT_APP_APP_ENV}_AUTH0_AUDIENCE`])

  const { isPushEnabled } = useGetNotificationsEnabledStatus()
  return (
    <SettingsItem className="d-flex flex-column">
      <TextSpan
        style={{ zIndex: 50 }}
        className="my-0"
        color="textsupertransparent"
        size="xxs"
        weight={200}
        alignSelf="flex-start"
        textAlign="left">
        <TextSpan weight={700} color="textsupertransparent" size="xxs" className="mr-1">
          👨‍💻 Auth0 Domain
        </TextSpan>
        {domain}
      </TextSpan>
      <TextSpan
        style={{ zIndex: 50 }}
        className="my-0"
        color="textsupertransparent"
        size="xxs"
        weight={200}
        alignSelf="flex-start"
        textAlign="left">
        <TextSpan weight={700} color="textsupertransparent" size="xxs" className="mr-1">
          👨‍💻 Auth0 Audience
        </TextSpan>
        {audience}
      </TextSpan>
      <TextSpan
        style={{ zIndex: 50 }}
        className="my-0"
        color="textsupertransparent"
        size="xxs"
        weight={200}
        alignSelf="flex-start"
        textAlign="left">
        <TextSpan weight={700} color="textsupertransparent" size="xxs" className="mr-1">
          🔥 API Endpoint
        </TextSpan>
        {baseRoute}
      </TextSpan>
      <TextSpan
        style={{ zIndex: 50 }}
        className="my-0"
        color="textsupertransparent"
        size="xxs"
        weight={200}
        alignSelf="flex-start"
        textAlign="left">
        <TextSpan weight={700} color="textsupertransparent" size="xxs" className="mr-1">
          🐤 OneSignal
        </TextSpan>
        {process.env['REACT_APP_ONE_SIGNAL_APP_ID']}
      </TextSpan>
      <TextSpan
        style={{ zIndex: 50 }}
        className="my-0"
        color="textsupertransparent"
        size="xxs"
        weight={200}
        alignSelf="flex-start"
        textAlign="left">
        <TextSpan weight={700} color="textsupertransparent" size="xxs" className="mr-1">
          🐤 Push Notifications Enabled
        </TextSpan>
        {isPushEnabled ? (
          <>
            <LightIndicator color={'cashgreen'} placement="relative" />{' '}
            <TextPopupSmall size="xxxs" color="cashgreen" className="ml-1">
              true
            </TextPopupSmall>
          </>
        ) : (
          <>
            <LightIndicator color={'mehred'} placement="relative" />{' '}
            <TextPopupSmall size="xxxs" color="mehred" className="ml-1">
              OFF
            </TextPopupSmall>
          </>
        )}
      </TextSpan>
    </SettingsItem>
  )
}
