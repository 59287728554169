import { MCOLBadge } from 'Components/MCOLBadge'
import { TransitionFromAbove } from 'Components/Transitions/TransitionFromAbove'
import { PlaidWizardryPage } from 'Pages/PlaidWizardryPage/PlaidWizardryPage'
import { useFinancialData } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import { useUIState } from 'Providers/UIStateProvider'
import { FIXED_ICON, WIZARDRY_ICON } from 'Utilities/navigationTabUtilities'
import { IconButton } from 'design-system/buttons/IconButton/IconButton'
import React, { useState } from 'react'
import { ContainerDiv } from '../../../Components/DesignSystem/Layout/Containers/Containers'
import { StyledHr, StyledVr, TextSlim, TextSpan } from '../../../Components/DesignSystem/Typography'
import { useData } from '../../../Providers/APIDataProvider'
import { useStreamModifyingState } from '../../../Providers/StreamModifyingProvider'
import { numberToCurrency } from '../../../Utilities/currencyFormater'
import { getMonthlyBills, getMonthlySubscriptions } from '../../../Utilities/netWorth/netWorth'
import { StreamEntry } from '../../StreamPage/StreamEntry/StreamEntry'
import { EmptyBill } from '../../StreamPage/consts'
import { BackButton } from '../MysteryDayBackButton'
import { ForwardButton } from '../MysteryDayForwardButton'
import { SubPageProps } from '../MysteryPage'

export const CostOfLivingFixedPage: React.FC<SubPageProps> = () => {
  const { streams } = useData()
  const { setMessagePopupContent } = useUIState()

  const { flatAccounts } = useFinancialData()

  const { setStreamToEdit } = useStreamModifyingState()
  const billStreams = streams
    .filter((s) => s.type === 'bill')
    .sort((sa, sb) => (sa.amount > sb.amount ? 1 : -1))

  const monthlyBills = numberToCurrency(
    Math.abs(getMonthlyBills(streams) + getMonthlySubscriptions(streams)),
    false,
    false
  )

  const [fixedWizardryPageOpen, setFixedWizardryPageOpen] = useState(false)
  return (
    <>
      <TransitionFromAbove show={fixedWizardryPageOpen}>
        <PlaidWizardryPage
          mode="fixed"
          closePage={() => {
            setFixedWizardryPageOpen(false)
          }}
        />
      </TransitionFromAbove>
      <ContainerDiv className="justify-content-between align-items-center py-3">
        <ContainerDiv className="justify-content-start align-items-center">
          <div className="d-flex flex-row align-items-center position-relative">
            <MCOLBadge />
            <StyledVr color="texttransparent" className="mx-2" />
            {/* <Icon className="fas fa-arrow-right mx-2" size="sm" color="mehred" /> */}
            <TextSlim size="lg" weight={200} color="texttransparent">
              Fixed
            </TextSlim>
          </div>

          <TextSlim size="xs" className="px-4 d-inline" color="textsupertransparent">
            Car payment, rent, insurance premiums, gym memberships, online subscriptions make up
            your{' '}
            <TextSpan color="texttransparent" weight={500}>
              fixed
            </TextSpan>{' '}
            expenses. These repeat on a regular cadence and are often essential expenses.
          </TextSlim>
          {/* {billStreams.length ? (
          <TextSlim size="sm" className="px-2" color="texttransparent">
            To access later, see <ContextualLinkCTA to="/fixed" />
          </TextSlim>
        ) : null} */}

          <section className="w-100">
            {!billStreams?.length ? (
              <>
                <StyledHr className="mt-2 mb-1" color="nearlyclear" weight={1} />
                <div
                  data-testid="no-streams-container"
                  className="px-2 w-100 h-100 d-flex align-items-center justify-content-around flex-row">
                  <IconButton
                    color="mehred"
                    iconClassName={`${FIXED_ICON} mx-2`}
                    title="add first expense"
                    onClick={() => {
                      setStreamToEdit(EmptyBill, 'add')
                    }}
                  />
                  <TextSpan color="textsupertransparent">or</TextSpan>
                  <IconButton
                    color="softblue"
                    disabled={!flatAccounts.length}
                    onDisabledClick={() => {
                      setMessagePopupContent(
                        'Live Account Needed<>We can do fancy magic for you and detect your fixed expenses, but to do that, we need you to connect your real checking and credit card accounts.<>If you want to stick with adding your data manually vs. connecting to Plaid, you can add your recurring expenses here one by one<>If you want fancy magic to happen, go back to the previous page and connect a live bank account'
                      )
                    }}
                    iconClassName={`${WIZARDRY_ICON} mx-2 fa-fade`}
                    title="have us detect them"
                    onClick={() => {
                      setFixedWizardryPageOpen(true)
                    }}
                  />
                </div>
                <StyledHr className="mt-1 mb-1" color="nearlyclear" weight={1} />
              </>
            ) : (
              <div
                style={{
                  boxShadow: 'inset hsl(243 19% 18% / .5) 0 0px 7px 5px',
                  border: 'solid 1px #6362752e',

                  maxHeight: '120px',
                  overflowY: 'scroll',
                }}
                className="px-2">
                {billStreams.map((stream) => {
                  return (
                    <StreamEntry key={stream.name} stream={stream} onStreamEdit={setStreamToEdit} />
                  )
                })}
              </div>
            )}
            {/* HACK: Fixed Height*/}

            {billStreams?.length ? (
              <>
                <StyledHr className="my-2" color="nearlyclear" weight={1} />
                <div
                  data-testid="no-streams-container"
                  className="px-2 w-100 d-flex align-items-center justify-content-around flex-row">
                  <IconButton
                    color="mehred"
                    iconClassName={`${FIXED_ICON} mx-2`}
                    title="add another"
                    onClick={() => {
                      setStreamToEdit(EmptyBill, 'add')
                    }}
                  />
                  <TextSpan color="textsupertransparent">or</TextSpan>
                  <IconButton
                    color="softblue"
                    disabled={!flatAccounts.length}
                    onDisabledClick={() => {
                      setMessagePopupContent(
                        'Live Account Needed<>We can do fancy magic for you and detect your fixed expenses, but to do that, we need you to connect your real checking and credit card accounts.<>If you want to stick with adding your data manually vs. connecting to Plaid, you can add your recurring expenses here one by one<>If you want fancy magic to happen, go back to the previous page and connect a live bank account'
                      )
                    }}
                    iconClassName={`${WIZARDRY_ICON} mx-2 fa-fade`}
                    title="detect more"
                    onClick={() => {
                      setFixedWizardryPageOpen(true)
                    }}
                  />
                </div>
                <StyledHr className="my-1" color="nearlyclear" weight={1} />
              </>
            ) : // <div
            //   data-testid="no-streams-container"
            //   className="px-2 w-100 h-100 d-flex align-items-center justify-content-center flex-row">
            //   <div
            //     onClick={() => {
            //       setStreamToEdit(EmptyBill, 'add')
            //     }}
            //     className="d-flex flex-column align-items-center justify-content-start">
            //     <TextSpan className="my-1" color="mehred" weight={500} size="md">
            //       <Icon size="sm" color={'mehred'} className="fas fa-plus-circle mx-2" />
            //       add another
            //     </TextSpan>
            //   </div>
            //   <StyledVr size="lg" className="mx-2" color="nearlyclear" />
            //   <div
            //     onClick={() => {
            //       setFixedWizardryPageOpen(true)
            //     }}
            //     className="d-flex flex-column align-items-center justify-content-start">
            //     <TextSpan className="my-1" color="softblue" weight={500} size="md">
            //       <Icon size="sm" color={'softblue'} className="fa-duotone fa-hat-wizard mx-2" />
            //       detect more
            //     </TextSpan>
            //   </div>
            // </div>

            null}
          </section>
        </ContainerDiv>

        <div className="d-flex justify-content-between align-items-center w-100 flex-column">
          <TextSpan weight={700} size="sm" color="mehred" className="mb-3">
            <TextSpan color="texttransparent" size="sm" className="mr-2">
              Fixed Expenses =
            </TextSpan>{' '}
            {monthlyBills} / m
          </TextSpan>
          <div className="d-flex justify-content-between align-items-center w-100">
            <BackButton />
            <ForwardButton
              disabledPopupTitle="Missing Fixed Expenses"
              disabledPopupBody="Please add at least one fixed expense to continue."
              disabled={!billStreams.length}
              title={'Next'}
            />
            {/* <ForwardButton title={'Next'} /> */}
          </div>
        </div>
      </ContainerDiv>
    </>
  )
}
