import styled from 'styled-components'

import color from 'color'
import { ColorType } from 'theme'

const SPLURV_SNIPPET_SIZE = 34

export const LogoContainer = styled.div<{ size?: number; color: ColorType; firstTime?: boolean }>`
  position: relative;
  height: ${(props) => (props.size ? props.size * 1.3255 : SPLURV_SNIPPET_SIZE * 1.3255)}px;
  width: ${(props) => (props.size ? props.size * 1.3255 : SPLURV_SNIPPET_SIZE * 1.3255)}px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${(props) => color(props.theme.colors.second).alpha(0.5).toString()};

  border-width: 1px;
  border-color: ${(props) => props.theme.colors[props.color]};
  border-style: solid;

  -webkit-animation: ${(props) =>
    props.firstTime ? 'glowing 1s ease-in-out infinite alternate' : ''};
  animation: ${(props) => (props.firstTime ? 'glowing 1s ease-in-out infinite alternate' : '')};
  i {
    position: absolute;
    visibility: ${(props) => (props.firstTime ? 'hidden' : 'visible')};
    top: -6px;
    right: -6px;
  }
  @keyframes glowing {
    from {
      border-color: ${(props) => props.theme.colors[props.color]};
      border-width: 1px;
    }
    to {
      border-color: ${(props) => props.theme.colors.logoPrimary};
      border-width: 2px;
    }
  }

  border-radius: 50%;
  img {
    max-height: ${(props) => (props.size ? props.size : SPLURV_SNIPPET_SIZE)}px;
  }
`
