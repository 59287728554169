import { AccountTrackingCTA } from 'Components/AccountTrackingCTA/AccountTrackingCTA'
import { Icon, TextSlim, TextSpan } from 'Components/DesignSystem/Typography'
import { MoneyInputContainer } from 'Pages/StreamPage/AddEditStream/AddEditStream.components'
import { useData } from 'Providers/APIDataProvider'
import { useFinancialData } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import { MysteryDayType } from 'Providers/MysteryDayProvider/MysteryDayProvider.interfaces'
import { numberToCurrency } from 'Utilities/currencyFormater'
import { getMonthlyBills, getMonthlySubscriptions } from 'Utilities/netWorth/netWorth'
import { CaptionText } from 'design-system/typography/Text/Text'
import React from 'react'
import { useHistory } from 'react-router'
import { DesiredTimeOffSlider } from '../DesiredTimeOffSlider/DesiredTimeOffSlider'
import { InterestRateSlider } from '../InterestRateSlider'
import { SavingsAccountBreakdown } from '../Pages/SavingsTotalPage'

interface CashflowRecapContentProps {
  mysteryMode: MysteryDayType
}

type ActivelySelectedTab = 'income' | 'expenses' | 'debt' | 'item' | 'savings' | 'launchday'

const OptionContent: React.FC<{
  activelySelectedTab: ActivelySelectedTab
  mysteryMode: MysteryDayType
}> = ({ activelySelectedTab, mysteryMode }) => {
  const { streams, monthlyBurnRate } = useData()
  const { push } = useHistory()
  const { flexibleSpendPerMonth } = useFinancialData()

  const monthlyBills = Math.abs(getMonthlyBills(streams) + getMonthlySubscriptions(streams))

  const { setDebtAmount, debtAmount, itemCost, linkedAccount, setItemCost } = useMysteryDay()
  if (activelySelectedTab === 'debt') {
    return (
      <div className="d-flex flex-row w-100 align-items-center">
        <div className="d-flex flex-column w-100 align-items-start justify-content-between">
          <TextSlim size="xs" className="px-4 my-2" color="textsupertransparent">
            You can edit your debt details here without going back through onboarding.
          </TextSlim>
          {linkedAccount ? null : (
            // <AccountTrackingCTA type="Debt" updateMode />
            <div className="d-flex flex-column w-100 align-items-start justify-content-between mt-3">
              <MoneyInputContainer
                size="sm"
                color="yellow"
                tight
                className="mb-3 ml-5"
                icon="f155"
                label={'Amount'}>
                <input
                  data-testid="stream-amount-input"
                  inputMode="decimal"
                  value={debtAmount}
                  placeholder={'5,000'}
                  onChange={(e) => setDebtAmount(e.currentTarget.value)}></input>
              </MoneyInputContainer>

              <InterestRateSlider slim />
            </div>
          )}
        </div>
        <AccountTrackingCTA type="Debt" updateMode />
      </div>
    )
  }
  if (activelySelectedTab === 'launchday') {
    return (
      <div className="d-flex flex-column w-100 align-items-start justify-content-between">
        <TextSlim size="sm" className="px-2 my-2">
          You can edit your desired time off here without going back through onboarding
        </TextSlim>
        <div className="d-flex flex-column w-100 align-items-start justify-content-between">
          <DesiredTimeOffSlider />
        </div>
      </div>
    )
  }
  if (activelySelectedTab === 'item') {
    return (
      <div className="d-flex flex-row w-100 align-items-start justify-content-between">
        <div className="d-flex flex-column align-items-start justify-content-between">
          <TextSlim size="xxs" className="px-2 my-2" color="textsupertransparent">
            You can edit what your saving for here
          </TextSlim>
          <div className="d-flex flex-column align-items-start justify-content-between ml-3 mt-4 mb-4">
            <MoneyInputContainer
              size="sm"
              color="megapurple"
              tight
              className="mb-5"
              icon="f155"
              label={'Amount'}>
              <input
                data-testid="stream-amount-input"
                inputMode="decimal"
                value={itemCost}
                placeholder={'5,000'}
                onChange={(e) => setItemCost(e.currentTarget.value)}></input>
            </MoneyInputContainer>
          </div>
        </div>
        <div className="d-flex flex-grow">
          <AccountTrackingCTA type="Savings" updateMode />
        </div>
      </div>
    )
  }
  if (activelySelectedTab === 'expenses') {
    return (
      <div className="d-flex flex-row w-100 align-items-center">
        <div className="d-flex flex-column w-100 align-items-center justify-content-between my-2">
          <TextSlim size="xs" className="px-2 my-2" color="texttransparent">
            <Icon color="texttransparent" className="fas fa-receipt" /> Expense Breakdown
          </TextSlim>
          <div
            className="d-flex flex-column align-items-center"
            onClick={() => {
              push('/fixed')
            }}>
            <TextSpan size="xxs" className="px-2 my-0" color="textsupertransparent">
              Monthly Cost of Living
            </TextSpan>
            <TextSpan size={'md'} weight={600} color={'mehred'} className="px-2 my-0">
              <Icon className="fas fa-flame mr-1" color="mehred" />
              {numberToCurrency(monthlyBurnRate, true, true)}
            </TextSpan>
          </div>
          <div className="d-flex flex-row w-100 align-items-center justify-content-around">
            <div
              className="d-flex flex-column align-items-center"
              onClick={() => {
                push('/fixed')
              }}>
              <CaptionText className="px-2 my-0">Fixed:</CaptionText>
              <TextSpan size={'sm'} weight={600} color={'mehred'} className="px-2 my-0">
                <Icon className="fas fa-pencil mr-1" color="mehred" />
                {numberToCurrency(monthlyBills, true, true)}
              </TextSpan>
            </div>
            {/* <VerticalStackedMoneyDisplay title={'Fixed'} amount={monthlyBills} /> */}

            <Icon className="fas fa-plus mr-1" color="textsupertransparent" />

            <div
              className="d-flex flex-column align-items-center"
              onClick={() => {
                push('/variable')
              }}>
              <CaptionText className="px-2 my-0">Variable:</CaptionText>
              <TextSpan size={'sm'} weight={600} color={'yellow'} className="px-2 my-0">
                <Icon className="fas fa-pencil mr-1" color="yellow" />
                {numberToCurrency(flexibleSpendPerMonth, true, true)}
              </TextSpan>
            </div>
          </div>
        </div>

        <TextSlim size="xs" className="px-4 my-2" color="textsupertransparent">
          You can edit your fixed or variable expenses by clicking each.
        </TextSlim>
      </div>
    )
  }

  if (activelySelectedTab === 'savings') {
    return (
      <div className="d-flex flex-column w-100 align-items-center justify-content-between">
        {/* <VerticalStackedMoneyDisplay title={'Income'} color="cashgreen" amount={monthlyIncome} /> */}

        <SavingsAccountBreakdown />
      </div>
    )
  } else {
    return null
  }
}

export const CashflowRecapContent: React.FC<CashflowRecapContentProps> = ({ mysteryMode }) => {
  const [activelySelectedTab, setActivelySelectedTab] = React.useState<ActivelySelectedTab>(
    mysteryMode === 'getOrganized'
      ? 'expenses'
      : mysteryMode === 'saveToQuit'
      ? 'expenses'
      : 'expenses'
  )

  const OptionRow = () => {
    switch (mysteryMode) {
      case 'getOutOfDebt':
        return (
          <div className="d-flex w-100 align-items-center justify-content-around px-2 mt-2">
            <TextSpan
              onClick={() => {
                setActivelySelectedTab('expenses')
              }}
              style={{
                opacity: activelySelectedTab === 'expenses' ? 1 : 0.3,
                transition: 'all ease-in-out .3',
              }}
              size={'sm'}
              weight={700}
              color="mehred"
              className="ml-1 my-0">
              <u>Expenses</u>
            </TextSpan>
            <TextSpan
              onClick={() => {
                setActivelySelectedTab('debt')
              }}
              style={{
                opacity: activelySelectedTab === 'debt' ? 1 : 0.3,
                transition: 'all ease-in-out .3',
              }}
              size={'sm'}
              weight={700}
              color="yellow"
              className="ml-1 my-0">
              <u>Debt</u>
            </TextSpan>
          </div>
        )
      case 'buildCushion':
        return (
          <div className="d-flex w-100 align-items-center justify-content-around px-2 mt-2">
            <TextSpan
              onClick={() => {
                setActivelySelectedTab('savings')
              }}
              style={{
                opacity: activelySelectedTab === 'debt' ? 1 : 0.3,
                transition: 'all ease-in-out .3',
              }}
              size={'sm'}
              weight={700}
              color="cashgreen"
              className="ml-1 my-0">
              <u>Savings</u>
            </TextSpan>
            <TextSpan
              onClick={() => {
                setActivelySelectedTab('expenses')
              }}
              style={{
                opacity: activelySelectedTab === 'expenses' ? 1 : 0.3,
                transition: 'all ease-in-out .3',
              }}
              size={'sm'}
              weight={700}
              color="mehred"
              className="ml-1 my-0">
              <u>Expenses</u>
            </TextSpan>
          </div>
        )
      case 'getOrganized':
        return (
          <div className="d-flex w-100 align-items-center justify-content-around px-2 mt-2">
            <TextSpan
              onClick={() => {
                setActivelySelectedTab('expenses')
              }}
              style={{
                opacity: activelySelectedTab === 'expenses' ? 1 : 0.3,
                transition: 'all ease-in-out .3',
              }}
              size={'sm'}
              weight={700}
              color="mehred"
              className="ml-1 my-0">
              <u>Expenses</u>
            </TextSpan>
            <TextSpan
              onClick={() => {
                setActivelySelectedTab('item')
              }}
              style={{
                opacity: activelySelectedTab === 'item' ? 1 : 0.3,
                transition: 'all ease-in-out .3',
              }}
              size={'sm'}
              weight={700}
              color="megapurple"
              className="ml-1 my-0">
              <u>Item</u>
            </TextSpan>
          </div>
        )
      case 'saveToQuit':
        return (
          <div className="d-flex w-100 align-items-center justify-content-around px-2 mt-2">
            <TextSpan
              onClick={() => {
                setActivelySelectedTab('expenses')
              }}
              style={{
                opacity: activelySelectedTab === 'expenses' ? 1 : 0.3,
                transition: 'all ease-in-out .3',
              }}
              size={'sm'}
              weight={700}
              color="mehred"
              className="ml-1 my-0">
              <u>Expenses</u>
            </TextSpan>
            <TextSpan
              onClick={() => {
                setActivelySelectedTab('launchday')
              }}
              style={{
                opacity: activelySelectedTab === 'launchday' ? 1 : 0.3,
                transition: 'all ease-in-out .3',
              }}
              size={'sm'}
              weight={700}
              color="orange"
              className="ml-1 my-0">
              <u>Launch</u>
            </TextSpan>
          </div>
        )
      default:
        return null
    }
  }

  return (
    <>
      <OptionRow />
      <OptionContent mysteryMode={mysteryMode} activelySelectedTab={activelySelectedTab} />
    </>
  )
}
