import color from 'color'
import styled from 'styled-components/macro'
import { ColorType } from 'theme'

interface Props {
  progressColor: ColorType
  skipUnderline?: boolean
}

export const ProgressMeterContainer = styled.div<Props>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  justify-content: flex-start;
  padding: 0 0.25rem;
  margin-left: 0.5rem;
`

export const ProgressMeter = styled.progress<Props>`
  position: relative;
  background: ${(props) => color(props.theme.colors[props.progressColor]).alpha(0.2).toString()};
  margin: 0;
  width: 100%;
  height: 1rem;
  border-radius: 8px;
  -webkit-appearance: none;

  ::-webkit-progress-bar {
    background-color: transparent;
    border-radius: 8px;
    border-width: 0px;
    border-style: solid;
    border-color: ${(props) =>
      color(props.theme.colors[props.progressColor]).alpha(0.2).toString()};
    height: 1rem;
  }

  ::-webkit-progress-value {
    height: 1rem;
    background-color: ${(props) => props.theme.colors[props.progressColor]};
    transition: width 0.5s ease-in-out, background-color 0.5s ease-in-out;

    /* background-image: -webkit-linear-gradient(
        -45deg,
        transparent 33%,
        rgba(0, 0, 0, 0.1) 33%,
        rgba(0, 0, 0, 0.1) 66%,
        transparent 66%
      ),
      -webkit-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.25)),
      -webkit-linear-gradient(left, #09c, #f44); */

    border-radius: 8px;
    /* border-top-right-radius: 2px;
    border-bottom-right-radius: 2px; */
    background-size: 35px 36px, 100% 100%, 100% 100%;
  }
`
