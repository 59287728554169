import color from 'color'
import React from 'react'
import { animated, config, Transition } from 'react-spring/renderprops'
import styled from 'styled-components/macro'

export const PackedSlimTransactionOverlayDiv = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  background: ${(props) => props.theme.colors.background};
  border-bottom: ${(props) =>
    `solid 1px ${color(props.theme.colors.backgrounddark).alpha(0.1).toString()}`};

  margin: 0.25rem 0;
  padding: 0.5rem 1rem 0.5rem 0.25rem;
  min-height: 54px;
  max-height: 54px;
`

export const PackedSlimTransactionRowDiv = styled.div`
  width: 100%;
  align-items: center;
  display: grid;

  border-bottom: ${(props) =>
    `solid 1px ${color(props.theme.colors.backgrounddark).alpha(0.1).toString()}`};
  margin: 0.25rem 0;
  padding: 0.5rem 0;
  min-height: 54px;
  max-height: 54px;
  grid-template-columns: minmax(0, 0.25fr) minmax(0, 0.8fr) minmax(0, 0.55fr) minmax(0, 0.4fr) minmax(
      0,
      0.4fr
    );
  * {
    display: flex;
    justify-content: center;
    padding: 0 0.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    display: inline-block;
    display: flex;
    text-align: center;
  }
  *:first-child {
    justify-content: flex-start;
  }
  *:last-child {
    text-align: end;
  }
`

interface Props {
  show: boolean
  minHeight?: number
  close: () => void
}
export const TransitionedSlimRow: React.FC<Props> = ({ show, close, children, minHeight = 54 }) => {
  return (
    <Transition
      items={show}
      config={{ ...config.stiff, clamp: true }}
      from={{ opacity: 1, transform: 'translate3d(-100%,0,0)' }}
      enter={{ opacity: 1, transform: 'translate3d(0%,0,0)' }}
      leave={{ opacity: 1, transform: 'translate3d(-100%,0,0)' }}>
      {(show) =>
        show &&
        ((props) => (
          <animated.div
            style={{
              ...props,
              minHeight: `${minHeight}px`,
              position: 'absolute',
              width: 'calc(100% - 52px)',
              zIndex: 200,
            }}>
            {children}
          </animated.div>
        ))
      }
    </Transition>
  )
}
