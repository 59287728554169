import { TextSlim } from 'Components/DesignSystem/Typography'
import React, { useRef } from 'react'

import { config, useSprings } from '@react-spring/web'
import { useUIState } from 'Providers/UIStateProvider'
import swap from 'lodash-move'
import clamp from 'lodash.clamp'
import { useDrag } from 'react-use-gesture'
import { colorPalletes } from 'theme'
import { WishlistItemComponent } from '../WishlistItemComponent/WishlistItemComponent'
import {
  DraggableListContainer,
  colorFromItemPodium,
} from '../WishlistItemComponent/WishlistItemComponent.components'
import { WishlistItem } from '../WishlistProvider/WishlistProvider'

// NOTE: This item essentially corresponds to how spaced out the items should be
// This 90 comes from how big one of the item rows appears in dev tools + the desired padding
const itemVerticalSpacer = 82

const sortWishlistItemsBasedOnOrderListAndReturn = (
  wishlistItems: WishlistItem[],
  orderList: string[]
) => {
  return wishlistItems
    .map((item, index) => {
      const itemId = item._id!
      const indexOfItemInOrderList = orderList.indexOf(itemId)

      if (indexOfItemInOrderList < 0) {
        return { index, id: itemId }
      } else {
        return { index: indexOfItemInOrderList, id: itemId }
      }
    })
    .sort((itemA, itemB) => itemA.index - itemB.index)
}

const generateSprings = (
  order: { index: number; id: string }[],
  active = false,
  originalIndex = 0,
  y = 0,
  curRow = 0,
  immediatelyRun = false,
  activeTheme: keyof typeof colorPalletes
) => (index: number) => {
  const itemIndex = order.map((o) => o.index).indexOf(index)
  return active && index === originalIndex
    ? {
        y: originalIndex * itemVerticalSpacer + y,
        scale: 1.02, // how big to make the item scale up when pressed for move
        zIndex: 1,
        shadow: 15,
        borderWidth: 10,
        '--itemIndex': curRow,
        immediate: (key: string) =>
          immediatelyRun ||
          key === 'y' ||
          key === 'zIndex' ||
          key === 'backgroundColor' ||
          key === 'color' ||
          key === '--itemIndex',
        // immediate: true,
        backgroundColor: colorPalletes[activeTheme][colorFromItemPodium(curRow).bg],
        // color: colorPalletes[activeTheme][colorFromItemPodium(curRow).fg],
        color: colorPalletes[activeTheme]['text'],
        config: config.stiff,
      }
    : {
        y: itemIndex * itemVerticalSpacer,
        scale: 1,
        zIndex: 0,
        shadow: 1,
        borderWidth: 10,
        '--itemIndex': itemIndex,
        immediate: (key: string) =>
          immediatelyRun || key === 'color' || key === 'backgroundColor' || key === '--itemIndex',
        backgroundColor: colorPalletes[activeTheme][colorFromItemPodium(itemIndex).bg],
        // color: colorPalletes[activeTheme][colorFromItemPodium(itemIndex).fg],

        color: colorPalletes[activeTheme]['text'],
        config: config.stiff,
      }
}

export type ItemSizeFilter = 'small' | 'medium' | 'large'

interface WishlistDraggableListProps {
  editItem: (item: WishlistItem) => void
  items: WishlistItem[]
  wishlistOrderList: string[]
  activeItemSizeFilter: ItemSizeFilter
  updateOrderList: (newList: string[]) => Promise<void>
  loading: boolean
  activeItemStatusFilter: 'active' | 'purchased' | 'resisted'
}

export const WishlistDraggableList: React.FC<WishlistDraggableListProps> = ({
  editItem,
  items,
  wishlistOrderList,
  updateOrderList,
  loading,
  activeItemStatusFilter,
}) => {
  const { activeTheme } = useUIState()

  const oldProperSortedList = React.useRef<{ index: number; id: string }[]>([])

  const properSortedListOfWishlistItems = React.useMemo(
    () => sortWishlistItemsBasedOnOrderListAndReturn(items, wishlistOrderList),
    [items, wishlistOrderList]
  )

  items.sort((itemA, itemB) => {
    const positionOfItemAInSortList = wishlistOrderList.indexOf(itemA._id!)
    const positionOfItemBInSortList = wishlistOrderList.indexOf(itemB._id!)
    return positionOfItemAInSortList - positionOfItemBInSortList
  })
  const order = useRef<{ index: number; id: string }[]>(properSortedListOfWishlistItems) // Store indicies as a local ref, this represents the item order
  const [springs, api] = useSprings(
    items.length,
    generateSprings(order.current, false, 0, 0, 0, false, activeTheme)
  ) // Create springs, each corresponds to an item, controlling its transform, scale, etc.

  React.useLayoutEffect(() => {
    const properSortedListOfWishlistItems = sortWishlistItemsBasedOnOrderListAndReturn(
      items,
      wishlistOrderList
    )
    order.current = properSortedListOfWishlistItems
    api.start(generateSprings(properSortedListOfWishlistItems, false, 0, 0, 0, true, activeTheme)) // Feed springs new style data, they'll animate the view without causing a single render
  }, [api, wishlistOrderList, items, activeTheme])

  React.useEffect(() => {
    oldProperSortedList.current = properSortedListOfWishlistItems
  }, [properSortedListOfWishlistItems])

  /**
   * // TESTED: Manually
   * Appears that this function works and does the correct thing every time
   * movement.y is how far the elemenet got dragged from its original spot
   * // NOTE: As this function and stuff is dragged, no React state is being updated!!!
   * Only when the user lets go of the drag (!active) do we update the Ref
   */
  const bind = useDrag(({ args, active, movement: [, y] }) => {
    /* What is the original index (aka position in the list) of the item that is being grabbed */
    const originalIndex = args[0].index // From where did the item start

    /* This is the newly detected index depending on the Y offset position */
    const curRow = clamp(Math.round((originalIndex * 100 + y) / 100), 0, items.length - 1)

    /* This is the new version of order.current that includes our updated order of items */
    const newOrder = swap(order.current, originalIndex, curRow)

    /* This is what actually creates the animation on every dragged pixel  */
    api.start(generateSprings(newOrder, active, originalIndex, y, curRow, false, activeTheme)) // Feed springs new style data, they'll animate the view without causing a single render
    if (!active) order.current = newOrder
    if (curRow !== originalIndex && !active) {
      const newOrderIdList = newOrder.map((o) => o.id)
      updateOrderList(newOrderIdList)
    }
  })

  if (!items.length) {
    return (
      <TextSlim className="my-5 px-4" color="textsupertransparent" weight={200} size="xl">
        Resist your first impulse and start changing spending habits.
      </TextSlim>
    )
  }

  return (
    <div
      className="d-flex flex-column h-100 align-items-center justify-content-start"
      style={{
        position: 'relative',
        padding: '0 .5rem',
      }}>
      <div
        className="w-100"
        style={{
          position: 'relative',
        }}>
        {springs.map(({ zIndex, y, scale, borderWidth, backgroundColor, color, ...rest }, i) => {
          let indexOfItemInOrderList = wishlistOrderList.indexOf(items[i]._id!)
          if (indexOfItemInOrderList < 0) indexOfItemInOrderList = i
          const __itemIndex = rest['--itemIndex']
          return (
            <DraggableListContainer
              key={i}
              style={{
                pointerEvents: loading ? 'none' : undefined,
                zIndex,
                y,
                scale,
                backgroundColor,
                color,
                // transition: 'color ease-in-out .3s, background-color ease-in-out .3s',
                // @ts-ignore
                '--itemIndex': __itemIndex,
              }}
              children={
                <WishlistItemComponent
                  onClick={(item) => editItem(item)}
                  key={items[i].name}
                  animatedColor={color}
                  animatedIndex={__itemIndex}
                  inactive={activeItemStatusFilter !== 'active'}
                  loading={loading}
                  item={{ ...items[i], priority: indexOfItemInOrderList }}
                  dragHandler={bind({ index: i, _id: items[i]._id })}
                  style={{
                    pointerEvents: loading ? 'none' : undefined,
                    borderWidth: `${borderWidth}px`,
                    // @ts-ignore
                    '--itemIndex': __itemIndex,
                  }}
                />
              }
            />
          )
        })}
      </div>
    </div>
  )
}
