import { Icon, TextBold, TextSpan } from 'Components/DesignSystem/Typography'
import React from 'react'
import { StorageUtilizationBadge } from '../StorageUtilizationBadge'
import { SettingsItem } from '../components'
import { useGetLocalStorageStats } from '../useGetLocalStorageStats'

export const StorageSizeCalculator: React.FC = () => {
  const { totalSize, maxSize, calculateSizes, calculatingSizes } = useGetLocalStorageStats()
  return (
    <SettingsItem
      onClick={() => {
        calculateSizes()
        // const answer = window.confirm('Are you sure you want to clear local cache?')
        // if (answer) deleteAllLocalData()
      }}>
      <div className="d-flex flex-column align-items-start">
        <div className="d-flex align-items-center">
          <Icon
            color="softblue"
            className={`fas fa-database ${calculatingSizes ? 'fa-spin' : ''}`}
          />
          <TextBold color="softblue">Compute Local Storage</TextBold>
        </div>
        <StorageUtilizationBadge
          maxSize={maxSize}
          totalSize={totalSize}
          calculatingSizes={calculatingSizes}
          onClick={() => calculateSizes()}
        />

        <TextSpan size="xxs" color="texttransparent">
          Understand how much space we're taking up
        </TextSpan>
      </div>
    </SettingsItem>
  )
}
