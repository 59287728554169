import { useFinancialData } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import { getCreditCardAccounts } from 'Providers/FinancialDataProvider/helpers'
import { useUserCustomSettings } from 'Providers/UserCustomSettingsProvider/UserCustomSettingsProvider'
import { getTransactionSpendingTotal } from 'Utilities/creditCardSpending/creditCardSpending'
import { AccountBase, AccountSubtype } from 'plaid'

export const useGetSpendingAccount = (a: AccountBase | string) => {
  let account: AccountBase
  const { flatAccounts, transactions } = useFinancialData()
  /* In this case, we have an account_id passed in, we should look up the account */
  if (typeof a === 'string') {
    const _account = flatAccounts.find((account) => account.account_id === a)
    if (!_account) {
      console.error("Couldn't find account with id: ", a)
      throw new Error(`Couldn't find account with id: ${a}`)
    }
    account = _account
  } else {
    account = a
  }

  const { settings } = useUserCustomSettings()
  const skippedAccountsFromCashCalcuation =
    settings?.accountMetadata?.filter((datum) => datum.hidden).map((datum) => datum.account_id) ||
    []

  const connectedCreditCards =
    settings?.accountMetadata.find((datum) => datum.account_id === account.account_id)
      ?.tetheredCreditCards || []

  const discoveredLinkedCreditCardAccountIds = connectedCreditCards

  const cashAccountIsLinkedToCC = discoveredLinkedCreditCardAccountIds.length

  const creditCardAccounts = getCreditCardAccounts(flatAccounts).filter(
    (account) => !skippedAccountsFromCashCalcuation.includes(account.account_id)
  )

  const connectedCreditCardAccounts = creditCardAccounts?.filter((c) =>
    discoveredLinkedCreditCardAccountIds.includes(c?.account_id)
  )
  const totalBalanceAcrossConnectedCreditCards = connectedCreditCardAccounts.reduce(
    (acc, curr) => acc + (curr?.balances?.current || 0),
    0
  )
  const accountType = account.subtype === AccountSubtype.CreditCard ? 'Credit Card' : 'Cash'
  const currentBalance =
    accountType === 'Credit Card'
      ? account.balances.current
      : account.balances.available || account.balances.current || (0 as number)

  const spendingTotal = getTransactionSpendingTotal(
    transactions
      .filter((t) => t.account_id === account.account_id)
      .filter(
        (t) => settings?.transactionsMetadata?.[t.transaction_id]?.ignoreFromCalculations !== true
      ),
    undefined,
    'all',
    false
  ).thisMonth

  const spendingAllTransactions = getTransactionSpendingTotal(
    transactions
      .filter((t) => t.account_id === account.account_id)
      .filter(
        (t) => settings?.transactionsMetadata?.[t.transaction_id]?.ignoreFromCalculations !== true
      ),
    undefined,
    'all',
    false
  ).thisMonth

  const tetheredSpendingTotal = getTransactionSpendingTotal(
    transactions
      ?.filter((t) => discoveredLinkedCreditCardAccountIds.includes(t.account_id))
      .filter(
        (t) => settings?.transactionsMetadata?.[t.transaction_id]?.ignoreFromCalculations !== true
      ),
    undefined,
    'all',
    false
  ).thisMonth

  const tetheredSpendingTotalAllTransactions = getTransactionSpendingTotal(
    transactions?.filter((t) => discoveredLinkedCreditCardAccountIds.includes(t.account_id)),
    undefined,
    'all',
    false
  ).thisMonth

  const pendingTransactionsFromConnectedCreditCardAccount =
    transactions
      ?.filter((t) => discoveredLinkedCreditCardAccountIds.includes(t.account_id))
      .filter((t) => t.pending)
      .reduce((acc, curr) => acc + curr.amount, 0) || 0

  const finalBalance = cashAccountIsLinkedToCC
    ? (currentBalance || 0) -
      totalBalanceAcrossConnectedCreditCards -
      pendingTransactionsFromConnectedCreditCardAccount
    : currentBalance || 0

  return {
    originalBalance: currentBalance,
    tetheredBalance: finalBalance,
    thisMonthAccountSpending: spendingTotal.total,
    thisMonthAccountTetheredSpending: tetheredSpendingTotal.total,
    thisMonthTransactions: spendingTotal.transactions,
    thisMonthAllTransactions: spendingAllTransactions.transactions,
    thisMonthTetheredTransactions: tetheredSpendingTotal.transactions,
    thisMonthTetheredAllTransactions: tetheredSpendingTotalAllTransactions.transactions,
  }
}
