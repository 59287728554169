import { AccountMaskAndLogo } from 'Components/AccountMaskAndLogo'
import { CurrencyCounter } from 'Components/AnimatedCounters/CurrencyCounter'
import { CollapseCardControlled } from 'Components/CollapseCard/CollapseCardControlled'
import { TextSpan } from 'Components/DesignSystem/Typography'
import { GraphErrorBoundary } from 'Components/GraphErrorBoundary'
import { useLocalStorageSyncedState } from 'Hooks/useLocalStorageSyncedState'
import { DAYS_OUT, EventMapInterface, useData } from 'Providers/APIDataProvider'
import {
  ManualAccount,
  useFinancialData,
} from 'Providers/FinancialDataProvider/FinancialDataProvider'
import {
  getAmountForMixedAccount,
  getCashAccounts,
  getCreditCardAccounts,
  getSavingsAccounts,
} from 'Providers/FinancialDataProvider/helpers'
import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import { LinkedCreditAndDebitCards } from 'Providers/UserConfigProvider/UserConfigProvider'
import { useUserCustomSettings } from 'Providers/UserCustomSettingsProvider/UserCustomSettingsProvider'
import { enumerateDaysBetweenDates } from 'Utilities/dateUtilities/dateUtilities'
import { valueForDay } from 'Utilities/eventsToRunningBalance/eventsToRunningBalance'
import { MoneyStream } from 'Utilities/interfaces'
import { streamsToEvents } from 'Utilities/streamsToEvents/streamsToEvents'
import { add } from 'date-fns'
import { AccountBase } from 'plaid'
import React, { useEffect } from 'react'
import { ColorType } from 'theme'
import { GRAPH_COLORS, ProjectionGraph } from './ProjectionGraph'
import { TrajectoryContainer } from './components'
import { ViewRangeModes } from './helpers'

const getRunningBalancesForAccounts = (
  eventMap: EventMapInterface,
  accounts: (AccountBase | ManualAccount)[],
  creditCardAccounts: (AccountBase | ManualAccount)[],
  streams: MoneyStream[],
  skippedAccountsFromCashCalcuation: string[],
  linkedDebitCreditCards: LinkedCreditAndDebitCards
) => {
  const dateRange = enumerateDaysBetweenDates(new Date(), add(new Date(), { days: DAYS_OUT }))
  const destinationsFoundInStreams = streams
    .map((s) => s.destinations?.map((d) => d.accountIdOrName) || [])
    .flat()

  const sourcesFoundInStreams = streams
    .map((s) => s.sources?.map((s) => s.accountIdOrName) || [])
    .flat()

  const accountsWithMoneyGoingToThemOrFromThem = accounts
    .filter(
      (a) => destinationsFoundInStreams.includes(a.name) || sourcesFoundInStreams.includes(a.name)
    )
    .filter((account) =>
      (account as AccountBase)?.account_id
        ? !skippedAccountsFromCashCalcuation.includes((account as AccountBase).account_id)
        : true
    )

  return accountsWithMoneyGoingToThemOrFromThem
    .map((account, index) => {
      const accountSpecificEventMapStartValue: EventMapInterface = {}
      const accountSpecificEventMap = Object.entries(eventMap)
        .filter(([date, events]) =>
          events.some(
            (event) =>
              event.destinationAccount === account.name || event.sourceAccount === account.name
          )
        )
        .reduce((acc, curr) => {
          const [date, events] = curr
          const accountSpecificEvents = events.filter(
            (e) => e.destinationAccount === account.name || e.sourceAccount === account.name
          )
          acc[date] = accountSpecificEvents
          return acc
        }, accountSpecificEventMapStartValue)

      const discoveredLinkedCreditCardAccountIds =
        linkedDebitCreditCards[
          (account as AccountBase)?.account_id || (account as ManualAccount).name
        ] || []

      const connectedCreditCardAccounts = creditCardAccounts?.filter((c) =>
        discoveredLinkedCreditCardAccountIds.includes(c?.account_id)
      )

      const totalBalanceAcrossConnectedCreditCards = connectedCreditCardAccounts.reduce(
        (acc, curr) => acc + getAmountForMixedAccount(curr) || 0,
        0
      )

      const startBalance =
        getAmountForMixedAccount(account) - totalBalanceAcrossConnectedCreditCards
      const { dateToRunningTotalMap, firstMonthAverage, lastMonthAverage } = valueForDay(
        DAYS_OUT,
        accountSpecificEventMap,
        startBalance,
        0,
        dateRange
      )
      return {
        dateToRunningTotalMap,
        name: account.name,
        color: GRAPH_COLORS[index],
        direction: lastMonthAverage < firstMonthAverage ? 'negative' : 'positive',
      }
    })
    .reduce((acc, curr) => {
      acc[curr.name] = {
        color: curr.color,
        map: curr.dateToRunningTotalMap,
        name: curr.name,
        direction: curr.direction as 'negative' | 'positive',
      }
      return acc
    }, {} as { [index: string]: ActiveMapType })
}

export type ActiveMapType = {
  color: ColorType
  name: string
  map: { [index: string]: number }
  direction: 'positive' | 'negative'
}

interface ActiveMapSelectorProps {
  activeAccount: string
  setActiveAccount: (v: string) => void
}
export const ActiveMapSelector: React.FC<ActiveMapSelectorProps> = ({
  activeAccount,
  setActiveAccount,
}) => {
  // TODO: Cleanup all the casting that is done here to merge ManualAccount and AccountBase type of accounts
  const { flatAccounts, flatManualAccounts } = useFinancialData()
  const { streams } = useData()

  const activeChosenAccount = [...flatAccounts, ...flatManualAccounts].find(
    (a) => a.name === activeAccount
  )

  const destinationsFoundInStreams = streams
    .map((s) => s.destinations?.map((d) => d.accountIdOrName) || [])
    .flat()

  const sourcesFoundInStreams = streams
    .map((s) => s.sources?.map((s) => s.accountIdOrName) || [])
    .flat()

  const cashSavingsAccountsLive = [
    ...getCashAccounts(flatAccounts),
    ...getSavingsAccounts(flatAccounts),
  ]
  const cashSavingsAccountsManual = [
    ...flatManualAccounts.filter((fma) => fma.type === 'Cash'),
    ...flatManualAccounts.filter((fma) => fma.type === 'Savings'),
  ]

  const { settings } = useUserCustomSettings()
  const skippedAccountsFromCashCalcuation =
    settings?.accountMetadata?.filter((datum) => datum.hidden).map((datum) => datum.account_id) ||
    []

  const [open, setOpen] = React.useState<boolean>(false)

  const accountsForAllocation = [...cashSavingsAccountsLive, ...cashSavingsAccountsManual]
    .filter(
      (a) => destinationsFoundInStreams.includes(a.name) || sourcesFoundInStreams.includes(a.name)
    )
    .filter((account) =>
      (account as AccountBase)?.account_id
        ? !skippedAccountsFromCashCalcuation.includes((account as AccountBase).account_id)
        : true
    )

  if (!activeChosenAccount) {
    return (
      <CollapseCardControlled
        isCollapsed={!open}
        setIsCollapsed={setOpen}
        header={
          <TextSpan size="md" className="mx-1">
            Select an account
          </TextSpan>
        }
        body={
          <div className="d-flex flex-column align-items-start justify-content-start w-100">
            {accountsForAllocation.map((a) => {
              return (
                <div
                  onClick={() => {
                    setActiveAccount(a.name)
                    setOpen(false)
                  }}
                  className="d-flex flex-row align-items-center justify-content-between w-100 mt-2">
                  <AccountMaskAndLogo accountId={(a as AccountBase)?.account_id} />
                  <TextSpan className="mx-1">
                    <u>{a.name}</u>
                  </TextSpan>
                  <TextSpan color="cashgreen" className="mx-1">
                    <CurrencyCounter value={getAmountForMixedAccount(a)} />
                  </TextSpan>
                </div>
              )
            })}
          </div>
        }
      />
    )
  }

  const connectedCreditCards =
    settings?.accountMetadata.find((datum) => datum.account_id === activeAccount)
      ?.tetheredCreditCards || []

  const discoveredLinkedCreditCardAccountIds = connectedCreditCards

  const creditCardAccounts = [
    ...getCreditCardAccounts(flatAccounts),
    ...flatManualAccounts.filter((fma) => fma.type === 'Credit Card'),
  ]

  const connectedCreditCardAccounts = creditCardAccounts?.filter((c) =>
    discoveredLinkedCreditCardAccountIds.includes(c?.account_id)
  )

  const totalBalanceAcrossConnectedCreditCards = connectedCreditCardAccounts.reduce(
    (acc, curr) => acc + getAmountForMixedAccount(curr) || 0,
    0
  )

  const balance =
    getAmountForMixedAccount(activeChosenAccount) - totalBalanceAcrossConnectedCreditCards

  return (
    <CollapseCardControlled
      isCollapsed={!open}
      setIsCollapsed={setOpen}
      header={
        <div className="d-flex flex-row align-items-center justify-content-between w-100 mt-2 py-2">
          <AccountMaskAndLogo accountId={activeAccount} />
          <TextSpan size="md" className="mx-1">
            {activeAccount}
          </TextSpan>
          <TextSpan color={balance >= 0 ? 'cashgreen' : 'mehred'} className="mx-1" size="md">
            <CurrencyCounter value={balance} />
          </TextSpan>
        </div>
      }
      body={
        <div className="d-flex flex-column align-items-start justify-content-start w-100">
          {accountsForAllocation.map((a) => {
            return (
              <div
                onClick={() => {
                  setActiveAccount(a.name)
                  setOpen(false)
                }}
                className="d-flex flex-row align-items-center justify-content-between w-100 mt-2">
                <AccountMaskAndLogo accountId={(a as AccountBase)?.account_id} />
                <TextSpan className="mx-1">
                  <u>{a.name}</u>
                </TextSpan>
                <TextSpan color="cashgreen" className="mx-1">
                  <CurrencyCounter value={getAmountForMixedAccount(a)} />
                </TextSpan>
              </div>
            )
          })}
        </div>
      }
    />
  )
}

interface Props {
  viewRange: ViewRangeModes
}
export const TrajectoryChart: React.FC<Props> = ({ viewRange }) => {
  const { streams } = useData()

  const { livingCostPercentageAdjustment } = useMysteryDay()

  const { flatAccounts, flatManualAccounts, flexibleSpendPerMonth } = useFinancialData()

  const { settings } = useUserCustomSettings()
  const skippedAccountsFromCashCalcuation = React.useMemo(
    () =>
      settings?.accountMetadata?.filter((datum) => datum.hidden).map((datum) => datum.account_id) ||
      [],
    [settings?.accountMetadata]
  )

  const destinationsFoundInStreams = React.useMemo(
    () => streams.map((s) => s.destinations?.map((d) => d.accountIdOrName) || []).flat() || [],
    [streams]
  )

  const sourcesFoundInStreams = React.useMemo(
    () => streams.map((s) => s.sources?.map((s) => s.accountIdOrName) || []).flat() || [],
    [streams]
  )

  const liveAccountsWithMoneyGoingToThemOrFromThem = React.useMemo(
    () =>
      flatAccounts
        .filter(
          (a) =>
            destinationsFoundInStreams.includes(a.name) || sourcesFoundInStreams.includes(a.name)
        )
        .filter((account) => !skippedAccountsFromCashCalcuation.includes(account.account_id)),
    [
      flatAccounts,
      destinationsFoundInStreams,
      sourcesFoundInStreams,
      skippedAccountsFromCashCalcuation,
    ]
  )

  const manualAccountsWithMoneyGoingToThemOrFromThem = React.useMemo(
    () =>
      flatManualAccounts
        .filter(
          (a) =>
            destinationsFoundInStreams.includes(a.name) || sourcesFoundInStreams.includes(a.name)
        )
        .filter((account) => !skippedAccountsFromCashCalcuation.includes(account.account_id)),
    [
      flatManualAccounts,
      destinationsFoundInStreams,
      sourcesFoundInStreams,
      skippedAccountsFromCashCalcuation,
    ]
  )

  const accountsWithMoneyGoingToThemOrFromThem = React.useMemo(
    () => [
      ...liveAccountsWithMoneyGoingToThemOrFromThem,
      ...manualAccountsWithMoneyGoingToThemOrFromThem,
    ],
    [liveAccountsWithMoneyGoingToThemOrFromThem, manualAccountsWithMoneyGoingToThemOrFromThem]
  )

  const creditCardAccounts = [
    ...getCreditCardAccounts(flatAccounts),
    ...flatManualAccounts.filter((fma) => fma.type === 'Credit Card'),
  ]

  /* NEW */
  const startDate = new Date()
  // FIXME: This needs to pull from the newly derived current balance from accounts in the other provider
  // const startBalance = newBalance?.grandTotal || 0

  const events = streamsToEvents(
    streams,
    DAYS_OUT,
    startDate,
    [],
    flexibleSpendPerMonth,
    livingCostPercentageAdjustment
  )

  const eventMap = events.mapOfEvents

  /* NEW */

  const maps = React.useMemo(
    () =>
      getRunningBalancesForAccounts(
        eventMap,
        accountsWithMoneyGoingToThemOrFromThem,
        creditCardAccounts,
        streams,
        skippedAccountsFromCashCalcuation,
        {}
      ),
    // eslint-disable-next-line
    [eventMap, streams, skippedAccountsFromCashCalcuation]
  )

  const [activeAccount, setActiveAccount] = useLocalStorageSyncedState<string>(
    accountsWithMoneyGoingToThemOrFromThem?.[0]?.name || '',
    'cash-flow-chart-active-account'
  )

  useEffect(() => {
    setActiveAccount(accountsWithMoneyGoingToThemOrFromThem?.[0]?.name || '')
    // eslint-disable-next-line
  }, [accountsWithMoneyGoingToThemOrFromThem])

  const activeMap = maps[activeAccount]

  return (
    <TrajectoryContainer>
      {/* <ProjectionSlider viewRange={viewRange} setViewRange={setViewRange} /> */}
      <ActiveMapSelector activeAccount={activeAccount} setActiveAccount={setActiveAccount} />
      <GraphErrorBoundary>
        {activeMap ? <ProjectionGraph viewRange={viewRange} activeMap={activeMap} /> : null}
      </GraphErrorBoundary>
    </TrajectoryContainer>
  )
}
