import styled from 'styled-components/macro'
import {
  CONTENT_PADDING_HORIZONTAL,
  TOP_CONTAINER_PADDING_FOR_NOTCH,
} from '../DesignSystem/Layout/Containers/helpers'

export const TransitionedPageContainer = styled.div`
  padding-top: ${() => `${TOP_CONTAINER_PADDING_FOR_NOTCH}px`};
  padding-bottom: 0;
  padding-right: ${() => `${CONTENT_PADDING_HORIZONTAL}px`};
  padding-left: ${() => `${CONTENT_PADDING_HORIZONTAL}px`};
  min-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  top: 0;
  left: 0;
  max-height: 100vh;
  overflow-y: hidden;

  background: ${(props) => props.theme.colors.background};
  height: 100%;
  width: 100%;
  z-index: 100;
`
