import { add, format, sub } from 'date-fns'
import { WideButton } from 'design-system/buttons/WideButton/WideButton'
import React, { useState } from 'react'
import { DataOrFeatureMissing } from '../../Components/DataOrFeatureMissing/DataOrFeatureMissing'
import {
  ContainerCutout,
  ContainerDiv,
  ScrollingFull,
} from '../../Components/DesignSystem/Layout/Containers/Containers'
import {
  Icon,
  LoadingIndicator,
  StyledHr,
  TextBold,
  TextPopupSmall,
  TextSlim,
} from '../../Components/DesignSystem/Typography'
import { useData } from '../../Providers/APIDataProvider'
import { useUIState } from '../../Providers/UIStateProvider'
import { Calendar } from './Calendar/Calendar'

const trajectoryModeSubHeaderSize = 49
const scrollingSectionCutoutSize = trajectoryModeSubHeaderSize

export const CalendarPage: React.FC = () => {
  const { streams, fetchingStreams } = useData()

  const [monthYear, setMonthYear] = useState<Date>(new Date())

  const { setCalendarPageOpen, setMessagePopupContent, setMessagePopupColors } = useUIState()

  if (fetchingStreams && streams.length === 0) {
    return <LoadingIndicator text="Rendering fancy charts..." />
  }

  if (streams.length === 0) {
    return (
      <DataOrFeatureMissing
        title={'No Fixed Data'}
        body="To see a projection of your financial trajectory, you need to add some income sources and bills."
      />
    )
  }

  const CalendarPageHeader = () => {
    return (
      <div className="d-flex w-100 align-items-center">
        <div onClick={() => setCalendarPageOpen(false)} className="" style={{}}>
          <WideButton
            bgColor="texttransparent"
            active={true}
            text=""
            appearance="primary"
            textSize="small"
            width="fit"
            icon={{
              position: 'right',
              className: 'fas fa-arrow-left',
            }}
          />
        </div>
        <TextSlim className="w-100" color="orange" size="xl">
          Calendar
          <TextPopupSmall
            color={'orange'}
            className="ml-2 px-2 py-1"
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              setMessagePopupColors({
                fgColor: 'orange',
                bgColor: 'background',
              })
              setMessagePopupContent(
                `Calendar<> Welcome to the calendar! In tandem with the Cash Flow page, you can use the Calendar to stay ahead of upcoming expenses and bills and see what you have upcoming.`
              )
            }}>
            <Icon color={'orange'} size="xl" className={'fas fa-circle-info'} />
          </TextPopupSmall>
        </TextSlim>
      </div>
    )
  }

  const CalendarMonthArrows = () => {
    return (
      <ContainerCutout
        size={trajectoryModeSubHeaderSize}
        className="d-flex align-items-start flex-column justify-content-between w-100">
        <div className="d-flex flex-row align-items-center justify-content-around w-100 mt-2">
          <Icon
            size="xxl"
            onClick={() => setMonthYear(sub(monthYear, { months: 1 }))}
            className="fal fa-chevron-left"
          />
          <TextBold size="lg">{format(monthYear, 'MMM yyyy')}</TextBold>
          <Icon
            size="xxl"
            onClick={() => setMonthYear(add(monthYear, { months: 1 }))}
            className="fal fa-chevron-right"
          />
        </div>
      </ContainerCutout>
    )
  }

  return (
    <div className="d-flex flex-column justify-content-start align-items-start">
      <ContainerDiv>
        <CalendarPageHeader />
        <StyledHr weight={1} color="orange" style={{ opacity: 0.5 }} />
        <CalendarMonthArrows />
        <ScrollingFull cutout={scrollingSectionCutoutSize}>
          <Calendar monthYear={monthYear} />
        </ScrollingFull>
      </ContainerDiv>
    </div>
  )
}
