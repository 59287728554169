import { FooterActionRow } from 'Components/FooterActionRow/FooterActionRow'
import { useCategoryBudgetHelpers } from 'Pages/VariablePage/CategoryBudgetTable/helpers'
import { useData } from 'Providers/APIDataProvider'
import { numberToCurrency } from 'Utilities/currencyFormater'
import { VARIABLE_ICON } from 'Utilities/navigationTabUtilities'
import React from 'react'

interface Props {}
export const SpendingPageFooter: React.FC<Props> = () => {
  const {
    getCustomVariableSpendTotal,
    getCustomFixedSpendTotal,
    getUncategorizedData,
  } = useCategoryBudgetHelpers()

  const { monthlyBurnRate } = useData()

  const variableSpendTotal = getCustomVariableSpendTotal()
  const fixedSpendTotal = getCustomFixedSpendTotal()
  const { length } = getUncategorizedData()

  // React.useEffect(() => {
  //   if (length) {
  //     setModalContent(<ReflectionCarousel transactions={transactions} />)
  //     // setModalContent(<ReflectionCardDeck transactions={transactions} />)
  //   } else {
  //     setModalContent(null)
  //   }
  //   // eslint-disable-next-line
  // }, [length])

  React.useEffect(() => {
    // @ts-ignore
    if (navigator && navigator.setAppBadge) {
      // @ts-ignore
      navigator.setAppBadge(length)
    }
  }, [length])
  return (
    <FooterActionRow
      items={[
        {
          color: 'mehred',
          title: 'Monthly Cost of Living',
          type: 'simple',
          iconClassName: 'fas fa-flame',
          onClick: () => {
            // setPresetTransactionFilterCategory('')
            // setPresetTransactionFilterReflectionNeeded(false)
            // setPresetTransactionFilterType('fixed')
            // setShowTransactionOverlay(true)
          },
          subtitle: numberToCurrency(monthlyBurnRate, true, false),
        },
        {
          color: 'yellow',
          title: 'Spent So Far This Month',
          type: 'simple',
          iconClassName: VARIABLE_ICON,
          onClick: () => {
            // setPresetTransactionFilterCategory('')
            // setPresetTransactionFilterReflectionNeeded(false)
            // setPresetTransactionFilterType('variable')
            // setShowTransactionOverlay(true)
          },
          subtitle: numberToCurrency(variableSpendTotal + fixedSpendTotal, true, false),
        },
        // {
        //   color: 'logoSecondary',
        //   inactive: length === 0,
        //   title: 'Uncategorized',
        //   type: 'simple',
        //   iconClassName: 'fas fa-note',
        //   onClick: () => {
        //     if (length) {
        //       setModalContent(<ReflectionCarousel />)
        //     }
        //     // setPresetTransactionFilterCategory('')
        //     // setPresetTransactionFilterType('both')
        //     // setPresetTransactionFilterReflectionNeeded(true)
        //     // setShowTransactionOverlay(true)
        //   },
        //   subtitle: String(length),
        // },
      ]}
    />
  )
}
