import { useUIState } from 'Providers/UIStateProvider'
import React from 'react'
import { colorPalletes } from 'theme'
import { Icon, TextSlim } from './DesignSystem/Typography'

export const BankLogo: React.FC<{
  logo?: string | null
  size?: number
  institutionColor?: string | null
  institutionName?: string | null
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined
}> = ({ logo, size = 24, onClick, institutionColor, institutionName }) => {
  const { activeTheme } = useUIState()
  if (logo) {
    return (
      <img
        onClick={onClick}
        alt="institution-logo"
        height={size}
        style={{
          borderRadius: '50%',
          padding: 0,
        }}
        src={`data:image/png;base64, ${logo}`}
      />
    )
  } else {
    return (
      <TextSlim
        onClick={onClick}
        style={{
          height: `${size}px`,
          width: `${size}px`,
          minHeight: `${size}px`,
          minWidth: `${size}px`,
          maxHeight: `${size}px`,
          maxWidth: `${size}px`,
          alignItems: 'center',
          justifyContent: 'center',
          display: 'inline-flex',
          fontWeight: 300,
          textTransform: 'lowercase',
          fontSize: '10px',
          backgroundColor: institutionColor ? institutionColor : colorPalletes[activeTheme].second,
          color: colorPalletes[activeTheme].text,
          margin: 0,

          borderRadius: '50%',
        }}>
        {institutionName ? (
          size > 30 ? (
            institutionName.slice(0, 5)
          ) : (
            institutionName.slice(0, 1)
          )
        ) : (
          <Icon className="fas fa-pencil" size="xxs" color="text" />
        )}
      </TextSlim>
    )
  }
}
