import React from 'react'
import { TextSpan } from '../DesignSystem/Typography'
import { DataOrFeatureMissingContainer } from './DataOrFeatureMissing.components'

interface Props {
  title: string
  body: string
  cta?: React.ReactNode
}

/**
 * Use this a consistent visual indicator for a feature that is not yet accessible
 * @param
 * @returns
 */
export const DataOrFeatureMissing: React.FC<Props> = ({ title, body, cta }) => {
  return (
    <DataOrFeatureMissingContainer>
      <TextSpan
        size="sm"
        className="mb-0"
        weight={600}
        color="backgrounddark"
        style={{ alignSelf: 'flex-start' }}>
        {title}
        {cta && <div className="ml-2">{cta}</div>}
      </TextSpan>
      <TextSpan size="xs" color="backgrounddark" weight={200}>
        {body}
      </TextSpan>
    </DataOrFeatureMissingContainer>
  )
}
