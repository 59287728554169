import { useAuth0 } from '@auth0/auth0-react'
import { Icon, StyledHr, StyledVr, TextBold, TextSpan } from 'Components/DesignSystem/Typography'
import React from 'react'
import { useHistory } from 'react-router'

import { SplurvSnippet, SplurvSnippetText } from 'Components/SplurvSnippet/SplurvSnippet'
import { WelcomeToSplurv } from 'Components/WelcomeToSplurv/WelcomeToSplurv'
import { useUIState } from 'Providers/UIStateProvider'
import { IconButton } from 'design-system/buttons/IconButton/IconButton'
import { BodyText } from 'design-system/typography/Text/Text'
import { colorPalletes } from 'theme'
import { StyledButton } from './Button/Button'

// const renderer: CountdownRendererFn = (params) => {
//   const { seconds, completed } = params

//   if (completed) {
//     return (
//       <TextSpan
//         color="secondBright"
//         className="mx-2"
//         style={{
//           lineHeight: '2rem',
//         }}>
//         <b>now 🎉</b>
//       </TextSpan>
//     )
//   }

//   return (
//     <TextSpan
//       color="text"
//       className="mx-1"
//       style={{
//         lineHeight: '2rem',
//       }}>
//       in{' '}
//       <TextSpan color="secondBright" weight={800} className="mx-1">
//         {seconds}
//       </TextSpan>{' '}
//       seconds
//     </TextSpan>
//   )
// }

interface DemoWalkthroughIntroProps {
  closePage: () => void
}
export const PreLoginPage: React.FC<DemoWalkthroughIntroProps> = ({ closePage }) => {
  const { loginWithRedirect } = useAuth0()
  const { push } = useHistory()

  const { activeTheme } = useUIState()

  // useEffect(() => {
  //   const login = async () => {
  //     loginWithRedirect({
  //       redirectUri: `${window.location.origin}/mystery`,
  //     })
  //     setTimeout(() => {
  //       push('/mystery')
  //     }, 2000)
  //   }

  //   // const timeout = setTimeout(() => {
  //   //   login()
  //   // }, 650000)

  //   // return () => {
  //   //   clearTimeout(timeout)
  //   // }
  // })
  return (
    <div className="d-flex flex-column align-items-center justify-content-start w-100 h-100 px-2">
      <WelcomeToSplurv />
      <br /> <StyledHr className="my-2" color="second" weight={1} style={{ opacity: 0.3 }} />
      <BodyText className="px-4 my-2">
        You can access{' '}
        <TextSpan weight={700} className="mx-0 my-0">
          The Tool
        </TextSpan>{' '}
        and{' '}
        <TextSpan weight={700} className="mx-0 my-0">
          The System
        </TextSpan>{' '}
        later in the app from the side bar
      </BodyText>
      {/* <TextSpan
        color="text"
        style={{
          lineHeight: '2rem',
        }}>
        Taking you to login <Countdown renderer={renderer} date={Date.now() + 600000} />
      </TextSpan> */}
      <StyledHr weight={1} color="nearlyclear" className="my-1" />
      <BodyText className="px-4 my-2">
        Be on the look out for
        <SplurvSnippetText size="xxs" /> like this one throughout the app that you can click on for
        more information.
      </BodyText>
      <SplurvSnippet
        title="Your first snippet!"
        popupContent={
          <div className="d-flex flex-column">
            <BodyText className="my-2 px-4">
              Splurv Snippets will help explain deeper parts of the app, aspects of our approach,
              insights into how we think about personal finance, and tips we've learned along the
              way.
            </BodyText>

            <BodyText className="my-2 px-4">
              Also, each new snippet you open will reward you with some confetti because why not.
            </BodyText>

            <BodyText className="my-2 px-4">That's all for now. We'll see you in the app</BodyText>

            <StyledHr className="my-2" />

            <StyledButton
              onClick={() => {
                loginWithRedirect({
                  redirectUri: `${window.location.origin}/mystery`,
                })
                setTimeout(() => {
                  push('/mystery')
                }, 2000)
              }}
              size="sm"
              colorPrimary={'second'}
              style={{
                color: colorPalletes[activeTheme].logoSecond,
                background: colorPalletes[activeTheme].text,
                // background: systemPageSeen ? 'linear-gradient(135deg, #b6d8e3, #c8fadc)' : 'lightgray',
                // background: 'linear-gradient(135deg, #b6d8e3, #c8fadc)',
                backgroundPosition: '40% 100%',
                borderWidth: '0px',
                borderColor: colorPalletes[activeTheme].logoPrimary,
                borderRadius: '12px',
                boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 3px 3px',
              }}
              className="mt-1 mb-5 px-3 py-3 w-100 d-flex align-items-center justify-content-start">
              <Icon className="fa-brands fa-facebook mr-2" color="logoSecond" size="lg" />
              <Icon className="fa-brands fa-google mx-0" color="logoSecond" size="lg" />
              <StyledVr className="ml-2 mr-3" color="logoSecond" style={{ opacity: 0.5 }} />
              <TextBold color="logoSecond" weight={700} size="md">
                signup & start
              </TextBold>
            </StyledButton>
          </div>
        }
      />
      {/* <SubtitleText className="text-center"> */}
      <TextSpan color="logoPrimary" className="px-3 d-inline" weight={500} size="sm">
        Click the one above to get started.
      </TextSpan>
      {/* </SubtitleText> */}
      <StyledHr weight={1} color="nearlyclear" className="my-5" />
      <IconButton
        onClick={() => {
          closePage()
        }}
        title={'back'}
        color={'text'}
        iconClassName={'fas fa-chevron-up'}
        size="large"
      />
    </div>
  )
}
