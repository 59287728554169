import { calculateNumberOfPeriods } from 'Pages/DashboardPage/helpers'
import { useData } from 'Providers/APIDataProvider'
import { useFinancialData } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import { cleanCurrency } from 'Utilities/currencyFormater'
import { humanReadifyStringDate } from 'Utilities/dateUtilities/dateUtilities'
import { add } from 'date-fns'

export const useMysteryModeFinalDate = (
  overrideContribution?: number,
  overridePrincipal?: number
) => {
  const { monthlyBurnRate } = useData()
  const {
    mysteryMode,
    debtAmount,
    debtInterest,
    monthlyContribution,
    itemCost,
    desiredTimeOff,
    cushionSubMode,
  } = useMysteryDay()
  const { currentSavings } = useFinancialData()

  switch (mysteryMode) {
    case 'buildCushion': {
      switch (cushionSubMode) {
        case 'extendSavings': {
          let { yearsLeft, monthsLeft } = calculateNumberOfPeriods(
            currentSavings,
            overrideContribution ? overrideContribution : monthlyBurnRate
          )

          const wontBePaidOff = monthsLeft === Infinity

          const sunnyDay = wontBePaidOff
            ? null
            : add(new Date(), { months: monthsLeft, years: yearsLeft })
          const finalDateToShow = wontBePaidOff ? null : humanReadifyStringDate(sunnyDay)
          return { finalDateToShow, yearsLeft, monthsLeft }
        }
        case 'emergencyFund':
        default:
          const sixMonthFund = monthlyBurnRate * 6
          let { yearsLeft, monthsLeft } = calculateNumberOfPeriods(
            sixMonthFund,
            overrideContribution ? overrideContribution : monthlyContribution
          )

          const wontBePaidOff = monthsLeft === Infinity

          const sunnyDay = wontBePaidOff
            ? null
            : add(new Date(), { months: monthsLeft, years: yearsLeft })
          const finalDateToShow = wontBePaidOff ? null : humanReadifyStringDate(sunnyDay)
          return { finalDateToShow, yearsLeft, monthsLeft }
      }
    }
    case 'saveToQuit': {
      const totalRunwayNeeded = monthlyBurnRate * desiredTimeOff

      const remainingRunwayNeeded = totalRunwayNeeded - currentSavings

      let { yearsLeft, monthsLeft } = calculateNumberOfPeriods(
        remainingRunwayNeeded,
        overrideContribution ? overrideContribution : monthlyContribution
      )

      // if (mysteryMode === 'coastOnSavings') {
      //   const totalMonthsLeft = Math.floor(currentSavings / totalCost)
      //   yearsLeft = Math.floor(totalMonthsLeft / 12)
      //   monthsLeft = totalMonthsLeft - yearsLeft * 12
      // }

      const wontBePaidOff = monthsLeft === Infinity

      const sunnyDay = wontBePaidOff
        ? null
        : add(new Date(), { months: monthsLeft, years: yearsLeft })
      const finalDateToShow = wontBePaidOff ? null : humanReadifyStringDate(sunnyDay)
      return { finalDateToShow, monthsLeft, yearsLeft }
    }
    case 'getOrganized':
      let { yearsLeft, monthsLeft } = calculateNumberOfPeriods(
        overridePrincipal ? overridePrincipal : cleanCurrency(itemCost) - currentSavings,
        overrideContribution ? overrideContribution : monthlyContribution,
        debtInterest / 100
      )

      const wontBePaidOff = monthsLeft === Infinity

      const sunnyDay = wontBePaidOff
        ? null
        : add(new Date(), { months: monthsLeft, years: yearsLeft })
      const finalDateToShow = wontBePaidOff ? null : humanReadifyStringDate(sunnyDay)
      return { finalDateToShow, yearsLeft, monthsLeft }
    case 'getOutOfDebt': {
      let { yearsLeft, monthsLeft } = calculateNumberOfPeriods(
        overridePrincipal ? overridePrincipal : cleanCurrency(debtAmount),
        overrideContribution ? overrideContribution : monthlyContribution,
        debtInterest / 100
      )

      const wontBePaidOff = monthsLeft === Infinity

      const sunnyDay = wontBePaidOff
        ? null
        : add(new Date(), { months: monthsLeft, years: yearsLeft })
      const finalDateToShow = wontBePaidOff ? null : humanReadifyStringDate(sunnyDay)
      return { finalDateToShow, yearsLeft, monthsLeft }
    }
    default: {
      return { finalDateToShow: null, yearsLeft: 0, monthsLeft: 0 }
    }
  }
}
