// NOTE: We might come back to this feature when we bring back the calendar page
// one day

import { CurrencyCounter } from 'Components/AnimatedCounters/CurrencyCounter'
import { CollapseCard } from 'Components/CollapseCard/CollapseCard'
import { Icon, StyledHr, TextPopupSmall, TextSlim } from 'Components/DesignSystem/Typography'
import { ToggleSwitch } from 'Components/Toggle/Toggle'
import { DAYS_OUT } from 'Providers/APIDataProvider'
import { useUIState } from 'Providers/UIStateProvider'
import { humanReadifyStringDate } from 'Utilities/dateUtilities/dateUtilities'
import { FrequencyType, MoneyStream } from 'Utilities/interfaces'
import { streamsToEvents } from 'Utilities/streamsToEvents/streamsToEvents'
import { format } from 'date-fns'
import React from 'react'

// eslint-disable-next-line

const FUTURE_EVENTS_TO_SHOW = 3

interface Props {
  activeStream: MoneyStream
  payDate?: string | null
  toggleUpcomingEventAsPaid: Function
}
export const UpcomingEvents: React.FC<Props> = ({
  activeStream,
  payDate,
  toggleUpcomingEventAsPaid,
}) => {
  // const activeStream = constructActiveStream()

  const { setMessagePopupContent } = useUIState()
  const { upcomingEvents } = activeStream
  if (!payDate) return null
  if (payDate === '' || payDate === ' ') return null
  if (activeStream.frequency === FrequencyType.semimonthly) return null
  if (activeStream.frequency === FrequencyType.onetime) return null
  if (true) return null

  const startDate = new Date()

  startDate.setHours(0, 0, 0, 0)
  const eventsFromCurrentStream = streamsToEvents([activeStream], DAYS_OUT, startDate, [])

  return (
    <CollapseCard
      header={
        <TextSlim size="md" color="textsupertransparent" className="my-3">
          Upcoming Events
          <TextPopupSmall
            color={'textsupertransparent'}
            className="ml-2 p-2"
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              setMessagePopupContent(
                'Upcoming Events<>You can mark future events as paid to help with cash flow accuracy'
              )
            }}>
            <Icon color={'textsupertransparent'} size="lg" className="fas fa-circle-info" />
          </TextPopupSmall>
        </TextSlim>
      }
      body={
        <div className="d-flex flex-column w-100 justify-content-between">
          {eventsFromCurrentStream.events.slice(0, FUTURE_EVENTS_TO_SHOW).map((e, index) => {
            return (
              <div className="d-flex flex-column my-2 align-items-center">
                <div
                  data-testid={`upcoming-event-${e.date.toString()}`}
                  key={`${e.date.toString()}-${e.name}`}
                  className="d-flex w-100 justify-content-between">
                  <TextSlim style={{ flex: 1 }} color="second" weight={500}>
                    <CurrencyCounter value={Number(e.amount)} />
                  </TextSlim>
                  <TextSlim style={{ flex: 1 }} color="textsupertransparent">
                    {humanReadifyStringDate(e.date || new Date())}
                  </TextSlim>
                  <div style={{ flex: 1 }}>
                    <ToggleSwitch
                      label=""
                      // isMini={true}
                      enabledText="paid"
                      disabledText="unpaid"
                      enabled={
                        !!upcomingEvents?.find((ue) => ue.date === format(e.date, 'yyyy-MM-dd'))
                          ?.paidOff
                      }
                      toggleEnabled={() => toggleUpcomingEventAsPaid(format(e.date, 'yyyy-MM-dd'))}
                    />
                  </div>
                </div>
                {index !== FUTURE_EVENTS_TO_SHOW - 1 ? (
                  <StyledHr color="nearlyclear" weight={1} className="mt-3" />
                ) : null}
              </div>
            )
          })}
        </div>
      }
    />
  )
}
