import { CurrencyCounter } from 'Components/AnimatedCounters/CurrencyCounter'
import { Icon, TextSpan } from 'Components/DesignSystem/Typography'
import { calculateMinimumMonthlyPayment } from 'Pages/DashboardPage/helpers'
import { useData } from 'Providers/APIDataProvider'
import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import { MysteryDayType } from 'Providers/MysteryDayProvider/MysteryDayProvider.interfaces'
import { useUIState } from 'Providers/UIStateProvider'
import { cleanCurrency, numberToCurrency } from 'Utilities/currencyFormater'
import {
  getMonthlyBills,
  getMonthlyIncome,
  getMonthlySubscriptions,
} from 'Utilities/netWorth/netWorth'
import color from 'color'
import React, { useState } from 'react'
import Slider from 'react-input-slider'
import { ColorType, colorPalletes } from 'theme'
import { SliderPanelContainer } from '../components'
import { SalarySavingsHeader } from './SalarySavingsHeader'
import {
  SavingsRangeMarkerTick,
  SimpleMessageBox,
  SlidingMessageBox,
  SlidingMessageBoxContainer,
} from './SalarySavingsSlider.components'
import { useGetSliderWidths, useSetupSlider } from './hooks'

const getSavingsActionWord = (mysteryMode: MysteryDayType) => {
  switch (mysteryMode) {
    case 'getOutOfDebt':
      return 'allocate'
    case 'buildCushion':
    case 'getOrganized':
    case 'saveToQuit':
    case 'default':
      return 'save'
  }
}

export const MAX_YEARS_FOR_REPAYING_DEBT = 100

interface Props {
  slim?: boolean
  graphVersion?: boolean
  narrowRangeBasedOnRecommendation?: boolean
  onChange?: (val: number) => void
  perPaycheckValueMultiplier?: number
  setCashFlowRecapOpen: (bool: boolean) => void
}
export const SalarySavingsSlider: React.FC<Props> = ({
  slim = false,
  graphVersion = false,
  narrowRangeBasedOnRecommendation,
  perPaycheckValueMultiplier,
  onChange,
  setCashFlowRecapOpen,
}) => {
  const { activeTheme } = useUIState()

  const {
    debtAmount,
    debtInterest,
    monthlyContribution,
    setMonthlyContribution,
    mysteryMode,
  } = useMysteryDay()

  const { sliderContainerRef, sliderWidth, thumbComponentRef } = useSetupSlider()

  const slidingMessageBoxRef = React.useRef<HTMLDivElement | null>(null)

  const debtAmountClean = cleanCurrency(debtAmount)

  const minimumPaymentNeeded = calculateMinimumMonthlyPayment(
    debtAmountClean,
    debtInterest / 100,
    MAX_YEARS_FOR_REPAYING_DEBT
  )

  const { streams } = useData()

  const monthlyIncome = getMonthlyIncome(streams)
  const monthlyFixedExpenses = Math.abs(getMonthlyBills(streams) + getMonthlySubscriptions(streams))

  const {
    maxSavingsMarkerOffset,
    maxSavings,
    // maxSavingsJustFixed,
    maxSavingsPreVariableExpensesOffset,
    // minSavingsOffset,
  } = useGetSliderWidths({
    sliderContainerRef,
    sliderWidth,
  })

  const xmax = Math.floor(monthlyIncome)

  const numberOfIncrements = 200
  const xstep = Math.abs(Math.floor(xmax / numberOfIncrements))

  const colorForContribution: ColorType =
    monthlyContribution > monthlyIncome - monthlyFixedExpenses
      ? 'mehred'
      : monthlyContribution > maxSavings
      ? 'yellow'
      : 'seconddark'

  const [slidingMessageBoxLeftOffset, setSlidingMessageBoxLeftOffset] = useState<number>(0)
  // eslint-disable-next-line
  const [messageBoxAtLowerLimit, setMessageBoxAtLowerLimit] = useState<boolean>(false)
  // const [messageBoxAtUpperLimit, setMessageBoxAtUpperLimit] = useState<boolean>(false)
  // eslint-disable-next-line
  const [lowerLimitOfThumbOffset, setLowerLimitOfThumbOffset] = useState<number>(0)
  // const [upperLimitOfThumbOffset, setUpperLimitOfThumbOffset] = useState<number>(0)

  React.useEffect(() => {
    if (thumbComponentRef) {
      // const screenWidth = window.innerWidth
      const leftOffsetOfThumbComponentRef = thumbComponentRef.getBoundingClientRect().x
      // const rightOffsetOfThumbComponentRef = thumbComponentRef.getBoundingClientRect().x
      if (slidingMessageBoxRef.current) {
        setSlidingMessageBoxLeftOffset(leftOffsetOfThumbComponentRef)
        // const leftOffsetOfSlidingMessageBox = slidingMessageBoxRef.current.getBoundingClientRect().x
        // const rightOffsetOfSlidingMessageBox = slidingMessageBoxRef.current.getBoundingClientRect()
        //   .right
        // if (leftOffsetOfSlidingMessageBox <= 16) {
        //   if (lowerLimitOfThumbOffset) {
        //     if (leftOffsetOfThumbComponentRef <= lowerLimitOfThumbOffset) {
        //       // setMessageBoxAtLowerLimit(true)
        //     } else {
        //       setMessageBoxAtLowerLimit(false)
        //       setSlidingMessageBoxLeftOffset(leftOffsetOfThumbComponentRef)
        //     }
        //   } else {
        //     setLowerLimitOfThumbOffset(leftOffsetOfThumbComponentRef)
        //     setSlidingMessageBoxLeftOffset(leftOffsetOfThumbComponentRef)
        //   }
        // } else {
        //   setMessageBoxAtLowerLimit(false)
        //   setSlidingMessageBoxLeftOffset(leftOffsetOfThumbComponentRef)
        // }

        // console.log({
        //   leftOffsetOfSlidingMessageBox,
        //   leftOffsetOfThumbComponentRef,
        //   lowerLimitOfThumbOffset,
        //   rightOffsetOfSlidingMessageBox,
        //   screenWidth,
        // })
      }
    }
  }, [thumbComponentRef, monthlyContribution, slidingMessageBoxRef, lowerLimitOfThumbOffset])

  const setContributionValue = (value: number) => {
    if (value <= 0) {
      setMonthlyContribution(100)
      return
    }
    if (value < minimumPaymentNeeded && graphVersion) {
      setMonthlyContribution(Math.floor(minimumPaymentNeeded))
    } else {
      setMonthlyContribution(Math.floor(value))
    }
    if (onChange) {
      onChange(value)
    }
  }

  const onContributionChange = (e: { x: number; y: number }) => {
    if (e.x < minimumPaymentNeeded && graphVersion) {
      setContributionValue(minimumPaymentNeeded)
    } else {
      if (e.x > maxSavings) {
        setContributionValue(e.x)
        // setContributionValue(maxSavings)
      } else {
        setContributionValue(e.x)
      }
    }
  }

  return (
    <SliderPanelContainer>
      <SalarySavingsHeader
        colorForContribution={colorForContribution}
        monthlyContribution={monthlyContribution}
        monthlyIncome={monthlyIncome}
        perPaycheckValueMultiplier={perPaycheckValueMultiplier}
        setContributionValue={setContributionValue}
        xmax={xmax}
      />

      <SlidingMessageBoxContainer>
        <SimpleMessageBox shouldShow={colorForContribution === 'seconddark'}>
          <div className="d-flex flex-column align-items-start">
            <div className="d-flex flex-row justify-content-start">
              <div className="d-flex flex-row">
                <Icon className={`fas fa-party-horn mr-1`} size="xs" />
                <TextSpan size="xs" className="my-0" weight={500}>
                  Great Savings Contribution
                </TextSpan>
              </div>
            </div>
            <TextSpan className="my-0" size="xxs">
              You are good to go to automate this savings contribution each month
            </TextSpan>
          </div>
        </SimpleMessageBox>
        <SlidingMessageBox
          bottomIcon="f2ec"
          shouldShow={colorForContribution !== 'seconddark'}
          messageColor={colorForContribution}
          left={slidingMessageBoxLeftOffset}
          ref={slidingMessageBoxRef}
          messageBoxAtLowerLimit={messageBoxAtLowerLimit}>
          <div className="d-flex flex-column align-items-start">
            <div className="d-flex flex-row justify-content-start">
              <div className="d-flex flex-row">
                <Icon
                  className={`fas ${
                    colorForContribution === 'yellow' ? 'fa-circle-info' : 'fa-exclamation-triangle'
                  } mr-1`}
                  size="xs"
                />
                <TextSpan size="xs" className="my-0" weight={500}>
                  Lower Spending Needed:
                </TextSpan>
              </div>
              <TextSpan weight={700} className="m-0 ml-2">
                <CurrencyCounter value={Math.abs(maxSavings - monthlyContribution)} />
                {/* {numberToCurrency(Math.abs(maxSavings - monthlyContribution))} */}
              </TextSpan>{' '}
            </div>
            <TextSpan className="my-0 d-inline " size="xxs">
              You can't currently {getSavingsActionWord(mysteryMode)} this much each month. To{' '}
              {getSavingsActionWord(mysteryMode)} this much, you must{' '}
              <b
                onClick={() => {
                  setCashFlowRecapOpen(true)
                }}>
                <u>reduce your expenses</u>
              </b>
            </TextSpan>
          </div>
        </SlidingMessageBox>
      </SlidingMessageBoxContainer>
      <div className="w-100 d-flex align-items-center justify-content-between flex-row mt-5">
        <div
          className="d-flex align-items-center justify-content-center flex-column py-2 px-2"
          onClick={() => {
            const newContributionValue =
              monthlyContribution - Math.floor(Number(xmax || 1) / numberOfIncrements)
            if (newContributionValue < 0) {
              setContributionValue(0)
            } else {
              setContributionValue(newContributionValue)
            }
          }}>
          <Icon className="fal fa-minus-circle" size="xl" color="seconddark" />
        </div>

        <div
          ref={sliderContainerRef}
          style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 'calc(100% - 6rem)',
          }}>
          <Slider
            x={monthlyContribution}
            onChange={onContributionChange}
            xmin={0}
            // @ts-ignore
            xmax={xmax}
            xstep={xstep}
            axis="x"
            styles={{
              track: {
                transition: 'all ease-in-out .3s',
                backgroundColor: colorPalletes[activeTheme].nearlyclear,
                width: 'calc(100%)',
                height: 10,
              },
              active: {
                transition: 'background-color ease-in-out .3s',
                // backgroundColor: color(colorPalletes[activeTheme].seconddark).alpha(0.6).toString(),
                backgroundColor: color(colorPalletes[activeTheme][colorForContribution])
                  .alpha(0.6)
                  .toString(),
                // backgroundColor: colorPalletes[activeTheme].nearlyclear,
                opacity: 1,
              },

              thumb: {
                width: 28,
                height: 28,
                opacity: 1,
                transition: 'all ease-in-out .3s',
                zIndex: 20,
                boxShadow: 'rgb(0 0 0 / 30%) 0px 0px 4px 2px',

                borderColor: colorPalletes[activeTheme][colorForContribution],
                borderWidth: '2px',
                borderStyle: 'solid',
                backgroundColor: color(colorPalletes[activeTheme][colorForContribution])
                  .alpha(1)
                  .toString(),
              },
            }}></Slider>

          <SavingsRangeMarkerTick
            width={maxSavingsMarkerOffset}
            color="yellow"
            icon="f07a"
            content=""
          />
          <SavingsRangeMarkerTick
            width={maxSavingsPreVariableExpensesOffset}
            color="mehred"
            icon="f1da"
            content=""
          />
        </div>

        <div
          className="d-flex align-items-center justify-content-center flex-column py-2 px-2"
          onClick={() => {
            const newContributionValue =
              monthlyContribution + Math.floor(Number(xmax || 1) / numberOfIncrements)
            if (newContributionValue > Number(xmax || 1)) {
              setContributionValue(Number(xmax))
            } else {
              setContributionValue(newContributionValue)
              // if (newContributionValue > maxSavings) {
              //   // setContributionValue(maxSavings)
              // } else {
              // }
            }
          }}>
          <Icon className="fal fa-plus-circle" size="xl" color="seconddark" />
        </div>
      </div>
      <div
        style={{ position: 'relative' }}
        className="w-100 d-flex align-items-start justify-content-between px-5 ">
        <TextSpan
          style={{ alignSelf: 'flex-start', paddingLeft: '0rem' }}
          color="seconddark"
          weight={600}
          className="mt-0"
          size="xxs">
          $0
        </TextSpan>
        <TextSpan color="seconddark" className="mt-0" weight={600} size="xxs">
          {numberToCurrency(xmax)}
        </TextSpan>
      </div>
    </SliderPanelContainer>
  )
}
