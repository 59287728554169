import { CurrencyCounter } from 'Components/AnimatedCounters/CurrencyCounter'
import { NumberCounter } from 'Components/AnimatedCounters/NumberCounter'
import { Icon, StyledHr, StyledVr, TextSpan } from 'Components/DesignSystem/Typography'
import { SavingsContributionBadge } from 'Components/SavingsContributionBadge'
import React from 'react'
import { ColorType } from 'theme'

interface Props {
  colorForContribution: ColorType
  monthlyContribution: number
  monthlyIncome: number
  perPaycheckValueMultiplier?: number
  setContributionValue: (val: number) => void
  xmax: number
}
export const SalarySavingsHeader: React.FC<Props> = ({
  colorForContribution,
  monthlyContribution,
  monthlyIncome,
  perPaycheckValueMultiplier,
  setContributionValue,
  xmax,
}) => {
  return (
    <div className="w-100 d-flex align-items-center flex-column justify-content-between mt-3 mb-5">
      <StyledHr color={colorForContribution} weight={1} className="my-1" />
      <div className="d-flex flex-column w-100 justify-content-center align-items-center mb-1">
        <SavingsContributionBadge
          labelSize="sm"
          orientation="horizontal"
          color={colorForContribution}
        />
        <TextSpan className="mr-1 my-0 ml-2" size={'md'} weight={800} color={colorForContribution}>
          <Icon
            onClick={() => {
              const _value = prompt('Enter an exact savings contribution')
              const value = Number(_value)
              if (value > xmax) {
                setContributionValue(xmax)
                return
              }
              if (value < 0) {
                setContributionValue(0)
                return
              }
              if (isNaN(value)) {
                setContributionValue(0)
                return
              }

              setContributionValue(value)
            }}
            className="fas fa-pencil mr-1"
            color={colorForContribution}
          />
          <CurrencyCounter value={monthlyContribution} />
          <TextSpan className="ml-1" size="xxs" weight={300} color={colorForContribution}>
            {' '}
            / mo
          </TextSpan>

          <StyledVr size="xxxs" color="textsupertransparent" weight={1} className="mx-1" />
          <TextSpan className="my-0" size={'xs'} weight={800} color={colorForContribution}>
            <NumberCounter value={(monthlyContribution / monthlyIncome) * 100} />%
          </TextSpan>

          <TextSpan className="ml-1" size="xxs" weight={300} color={colorForContribution}>
            {' '}
            of paycheck
          </TextSpan>

          {perPaycheckValueMultiplier ? (
            <>
              <TextSpan
                className="ml-1"
                size="xs"
                weight={300}
                color={colorForContribution}></TextSpan>
              <TextSpan className="my-0" size={'xs'} weight={800} color={colorForContribution}>
                <TextSpan
                  className="my-0"
                  size={'sm'}
                  weight={800}
                  color={colorForContribution}
                  style={{ minWidth: '250px' }}>
                  (
                  <CurrencyCounter value={monthlyContribution / perPaycheckValueMultiplier} />
                </TextSpan>
                {/* {numberToCurrency(monthlyContribution / perPaycheckValueMultiplier)} */}
                <TextSpan className="ml-1" size="xxs" weight={300} color={colorForContribution}>
                  {' '}
                  per paycheck
                </TextSpan>
              </TextSpan>{' '}
              <TextSpan className="my-0" size={'xs'} weight={800} color={colorForContribution}>
                )
              </TextSpan>
            </>
          ) : null}
        </TextSpan>{' '}
      </div>
    </div>
  )
}
