import { AccountBase, Transaction } from 'plaid'
import React, { useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useAsyncLocalStorageState } from 'Hooks/useAsyncLocalStorageState'
import { useLocalStorageSyncedState } from 'Hooks/useLocalStorageSyncedState'
import { useUserCustomSettings } from 'Providers/UserCustomSettingsProvider/UserCustomSettingsProvider'
import { AsyncHookResponse, useAsyncFetch } from '../../Hooks/useAsyncFetch'
import { SPLURV_LOCAL_STORAGE_PREFIX, useLocalStorageSync } from '../../Hooks/useLocalStorageSync'
import { getGlobalCreditCardSpend } from '../../Utilities/creditCardSpending/creditCardSpending'
import { cleanCurrency } from '../../Utilities/currencyFormater'
import {
  SimplifiedAccountTypes,
  getCashAccounts,
  getCreditCardAccounts,
  getDebtAccounts,
  getSavingsAccounts,
  getTotalFromAcounts,
} from './helpers'
import {
  DeleteAccountsData,
  DeleteAccountsParams,
  GetAccountsData,
  GetLiabilitiesData,
  GetTransactionsData,
} from './interfaces'

export const defaultAsyncHookResponse: AsyncHookResponse<any> = {
  data: null,
  error: null,
  status: 'idle',
}

export interface ManualTransaction {
  name: string
  amount: string
  date: string
  account_id: string
  _id?: string
}

export interface ManualAccount {
  name: string
  amount: string
  account_id: string
  type: SimplifiedAccountTypes
  transactions: ManualTransaction[]
  _id?: string
}
interface FinancialDataContextState {
  deleteAccountData: {
    res: AsyncHookResponse<DeleteAccountsData>
    issueRequest: ({
      queryParamsConfig,
    }: {
      queryParamsConfig: DeleteAccountsParams
    }) => Promise<void>
  } | null
  getAccounts: (params: any) => Promise<any>
  accountsData: AsyncHookResponse<GetAccountsData>

  getManualAccounts: (params: any) => Promise<any>
  getManualAccountsLoading: boolean
  manualAccounts: ManualAccount[]

  saveManualTransaction: (params: {
    manualTransaction: Omit<ManualTransaction, 'amount'> & { amount: number }
  }) => Promise<any>
  saveManualTransactionLoading: boolean

  deleteManualTransaction: (params: {
    manualTransactionId: string
    accountId: string
  }) => Promise<any>
  deleteManualTransactionLoading: boolean

  saveManualAccountData: {
    res: AsyncHookResponse<ManualAccount>
    issueRequest: ({
      bodyParamsConfig,
    }: {
      bodyParamsConfig: {
        name: string
        amount: number
        account_id: string
        type: SimplifiedAccountTypes
        transactions: ManualTransaction[]
        _id?: string
      }
    }) => Promise<void>
  } | null

  deleteManualAccountData: {
    res: AsyncHookResponse<null>
    issueRequest: ({ bodyParamsConfig }: { bodyParamsConfig: { name: string } }) => Promise<void>
  } | null

  getTransactions: (params: any) => Promise<any>
  transactionsData: AsyncHookResponse<GetTransactionsData>

  liabilitiesData: AsyncHookResponse<GetLiabilitiesData>
  getLiabilities: (params: any) => Promise<any>

  currentCash: number
  currentDebt: number
  currentSavings: number
  currentCredit: number
  netWorth: number
  currentVariableExpenseAverage: ReturnType<typeof getGlobalCreditCardSpend> | null
  flexibleSpendPerMonth: number

  transactions: Transaction[]
  flatAccounts: AccountBase[]

  flatManualAccounts: ManualAccount[]
  getAccountBreakdown: (
    type: 'cash' | 'savings' | 'debt',
    skipSkippedAccounts: boolean
  ) => {
    accounts: AccountBase[]
    total: number
  }
}

const defaultData: FinancialDataContextState = {
  accountsData: defaultAsyncHookResponse,
  transactionsData: defaultAsyncHookResponse,
  liabilitiesData: defaultAsyncHookResponse,

  getManualAccounts: () => Promise.resolve(),
  getManualAccountsLoading: false,
  manualAccounts: [],

  saveManualTransaction: (params: {
    manualTransaction: Omit<ManualTransaction, 'amount'> & { amount: number }
  }) => Promise.resolve(),
  saveManualTransactionLoading: false,

  deleteManualTransaction: (params: { manualTransactionId: string; accountId: string }) =>
    Promise.resolve(),
  deleteManualTransactionLoading: false,

  saveManualAccountData: null,
  deleteManualAccountData: null,
  getTransactions: () => Promise.resolve(),
  getAccounts: () => Promise.resolve(),
  getLiabilities: () => Promise.resolve(),
  deleteAccountData: null,
  currentCash: 0,
  currentSavings: 0,
  currentDebt: 0,
  currentCredit: 0,
  netWorth: 0,
  flatAccounts: [],
  flatManualAccounts: [],
  transactions: [],
  currentVariableExpenseAverage: null,
  flexibleSpendPerMonth: 0,
  // HACK
  getAccountBreakdown: () => {
    return {
      accounts: [],
      total: 0,
    }
  },
}

const FinancialDataContext = React.createContext<FinancialDataContextState>(defaultData)

export const useFinancialData = () => React.useContext(FinancialDataContext)

/**
 * This provider is responsible for all data related to connected Plaid accounts
 * This includes accountData, liabilitiesData, transactionsData, and manualAccounts
 *
 * From these we also have derived data such as currentCash, currentDebt, currentSavings, currentCredit, netWorth, currentVariableExpenseAverage, and flexibleSpendPerMonth
 * @param
 * @returns
 */
export const FinancialDataProvider: React.FC = ({ children }) => {
  const { settings } = useUserCustomSettings()

  const { user, isAuthenticated } = useAuth0()

  const skippedAccountsFromCashCalcuation =
    settings?.accountMetadata?.filter((datum) => datum.hidden).map((datum) => datum.account_id) ||
    []
  const [currentCash, setCurrentCash] = React.useState<number>(0)
  const [currentDebt, setCurrentDebt] = React.useState<number>(0)
  const [currentCredit, setCurrentCredit] = React.useState<number>(0)
  const [flexibleSpendPerMonth, setFlexibleSpendPerMonth] = useLocalStorageSyncedState<number>(
    0,
    'mystery-day-flexible-spend-per-month'
  )
  const [currentSavings, setCurrentSavings] = React.useState<number>(0)
  const [
    currentVariableExpenseAverage,
    setCurrentVariableExpenseAverage,
  ] = React.useState<ReturnType<typeof getGlobalCreditCardSpend> | null>(null)

  const {
    issueRequest: getManualAccounts,
    loading: getManualAccountsLoading,
    idle: getManualAccountsIdle,
    data: getManualAccountData,
  } = useAsyncLocalStorageState<ManualAccount[], {}, {}>({
    method: 'GET',
    route: 'manual-accounts',
  })

  const {
    issueRequest: saveManualTransaction,
    loading: saveManualTransactionLoading,
  } = useAsyncLocalStorageState<ManualAccount, { manualTransaction: ManualTransaction }, {}>({
    method: 'POST',
    route: 'manual-accounts-manual-transaction',
  })

  const {
    issueRequest: deleteManualTransaction,
    loading: deleteManualTransactionLoading,
  } = useAsyncLocalStorageState<ManualAccount, { manualTransactionId: string }, {}>({
    method: 'DELETE',
    route: 'manual-accounts-manual-transaction',
  })

  const saveManualAccountData = useAsyncFetch<ManualAccount, ManualAccount>({
    method: 'POST',
    route: 'manual-accounts',
  })

  const deleteManualAccountData = useAsyncFetch<null, { name: string }, {}>({
    method: 'DELETE',
    route: 'manual-accounts',
  })
  // <<<<< MANUAL ACCOUNTS

  // ACCOUNTS >>>>>>
  const localStorageKeyForAccounts = `${SPLURV_LOCAL_STORAGE_PREFIX}__${'GET'}-${'accounts'}`
  const localStorageDataForAccounts = localStorage.getItem(localStorageKeyForAccounts)
  const dataForAccounts = JSON.parse(
    localStorageDataForAccounts || JSON.stringify(defaultAsyncHookResponse)
  )

  const plaidAccounts = useAsyncFetch<GetAccountsData, {}, {}>({
    method: 'GET',
    route: 'accounts',
    cachedData: dataForAccounts,
  })
  const [accountsData, setAccountsData] = React.useState<AsyncHookResponse<GetAccountsData>>(
    defaultAsyncHookResponse
  )
  useLocalStorageSync({
    method: 'GET',
    path: 'accounts',
    setter: setAccountsData,
    data: plaidAccounts.res,
  })
  // <<<<< ACCOUNTS

  // TRANSACTIONS >>>>>>

  const localStorageKeyForTransactions = `${SPLURV_LOCAL_STORAGE_PREFIX}__${'GET'}-${'transactions'}`
  const localStorageDataForTransactions = localStorage.getItem(localStorageKeyForTransactions)
  const dataForTransctions = JSON.parse(
    localStorageDataForTransactions || JSON.stringify(defaultAsyncHookResponse)
  )
  const plaidTransactions = useAsyncFetch<GetTransactionsData, {}, {}>({
    method: 'GET',
    route: 'transactions',
    cachedData: dataForTransctions,
  })

  const [transactionsData, setTransactionsData] = React.useState<
    AsyncHookResponse<GetTransactionsData>
  >(defaultAsyncHookResponse)

  useLocalStorageSync({
    method: 'GET',
    path: 'transactions',
    setter: setTransactionsData,
    data: plaidTransactions.res,
  })
  // <<<<< TRANSACTIONS

  // LIABILITIES >>>>>>
  const localStorageKeyForLiabilities = `${SPLURV_LOCAL_STORAGE_PREFIX}__${'GET'}-${'liabilities'}`
  const localStorageDataForLiabilities = localStorage.getItem(localStorageKeyForLiabilities)
  const dataForLiabilities = JSON.parse(
    localStorageDataForLiabilities || JSON.stringify(defaultAsyncHookResponse)
  )

  const plaidLiabilities = useAsyncFetch<GetLiabilitiesData, {}, {}>({
    method: 'GET',
    route: 'liabilities',
    cachedData: dataForLiabilities,
  })
  const [liabilitiesData, setLiabilitiesData] = React.useState<
    AsyncHookResponse<GetLiabilitiesData>
  >(defaultAsyncHookResponse)
  useLocalStorageSync({
    method: 'GET',
    path: 'liabilities',
    setter: setLiabilitiesData,
    data: plaidLiabilities.res,
  })
  // <<<<< LIABILITIES

  /**
   * NOTE: The first time fetch for liabilities for a newly connected account is a very long time.
   * Upwards of 30+ seconds. Keep this in mind for loading states in the app
   */

  // const plaidRecurringTransactions = useAsyncFetch<GetLiabilitiesData, {}, {}>({
  //   method: 'GET',
  //   route: 'recurring_transactions',
  // })

  const deleteAccountData = useAsyncFetch<DeleteAccountsData, DeleteAccountsParams, {}>({
    method: 'DELETE',
    route: 'accounts',
  })

  const [transactions, setTransactions] = React.useState<Transaction[]>([])
  const [flatAccounts, setFlatAccounts] = React.useState<AccountBase[]>([])
  const [flatManualAccounts, setFlatManualAccounts] = React.useState<ManualAccount[]>([])

  useEffect(() => {
    if (settings && settings.categoryMetadata) {
      const total = Object.values(settings.categoryMetadata).reduce(
        (acc, { limit }) => acc + limit,
        0
      )
      setFlexibleSpendPerMonth(total)
    } else {
      setFlexibleSpendPerMonth(0)
    }
  }, [settings, setFlexibleSpendPerMonth])

  useEffect(() => {
    const transactions = plaidTransactions?.res?.data?.transactions || []
    const accounts = plaidAccounts?.res?.data?.accounts || []
    /////////// If we have Plaid Accounts ////////
    if (accounts.length) {
      const accountsFlat = accounts.map((a) => a.accounts).flat()
      // .filter((account) => !skippedAccountsFromCashCalcuation.includes(account.account_id))
      setFlatAccounts(accountsFlat)

      const cashAccounts = getCashAccounts(accountsFlat).filter(
        (account) => !skippedAccountsFromCashCalcuation.includes(account.account_id)
      )
      const savingsAccounts = getSavingsAccounts(accountsFlat).filter(
        (account) => !skippedAccountsFromCashCalcuation.includes(account.account_id)
      )
      const debtAccounts = getDebtAccounts(accountsFlat).filter(
        (account) => !skippedAccountsFromCashCalcuation.includes(account.account_id)
      )

      const creditCardAccounts = getCreditCardAccounts(accountsFlat).filter(
        (account) => !skippedAccountsFromCashCalcuation.includes(account.account_id)
      )

      let currentCash = getTotalFromAcounts(cashAccounts)
      let currentSavings = getTotalFromAcounts(savingsAccounts)
      let currentDebt = getTotalFromAcounts(debtAccounts)
      let currentCreditCards = getTotalFromAcounts(creditCardAccounts)

      if (getManualAccountData?.length) {
        const manual_accounts = getManualAccountData

        setFlatManualAccounts(manual_accounts)

        currentCash += manual_accounts
          .filter((ma) => ma.type === 'Cash')
          .reduce((acc, ma) => acc + cleanCurrency(ma.amount), 0)

        currentSavings += manual_accounts
          .filter((ma) => ma.type === 'Savings')
          .reduce((acc, ma) => acc + cleanCurrency(ma.amount), 0)

        currentDebt += manual_accounts
          .filter((ma) => ma.type === 'Debt')
          .reduce((acc, ma) => acc + cleanCurrency(ma.amount), 0)

        currentCreditCards += manual_accounts
          .filter((ma) => ma.type === 'Credit Card')
          .reduce((acc, ma) => acc + cleanCurrency(ma.amount), 0)
      }

      setCurrentCash(currentCash)
      setCurrentCredit(currentCreditCards)
      setCurrentDebt(currentDebt)
      setCurrentSavings(currentSavings)
    }

    /////////// If we have Plaid Transactions ////////
    if (transactions?.length) {
      const filteredTransactions = transactions?.filter(
        (t) => !skippedAccountsFromCashCalcuation.includes(t.account_id)
      )
      setTransactions(filteredTransactions)

      const extraFilteredTransactions = filteredTransactions
      const globalSpend = getGlobalCreditCardSpend(extraFilteredTransactions)
      setCurrentVariableExpenseAverage(globalSpend)

      // NOTE: Removing this because for users who don't have an effective
      // transaction filtering setup to get an accurate live variable spend, setting this
      // here throws off (and always resets) their manually entered value
      // const flexibleSpendPerMonth = Math.floor(
      //   ((globalSpend?.thisMonth.total || 0) + (globalSpend?.lastMonth.total || 0)) / 2
      // )
      // setFlexibleSpendPerMonth(flexibleSpendPerMonth)
    }

    /////////// If we have ONLY Manual Accounts and no Plaid Accounts  ////////
    if (getManualAccountData?.length && !accounts.length) {
      setFlatManualAccounts(getManualAccountData)

      const manual_accounts = getManualAccountData

      const currentCash = manual_accounts
        .filter((ma) => ma.type === 'Cash')
        .reduce((acc, ma) => acc + cleanCurrency(ma.amount), 0)

      const currentSavings = manual_accounts
        .filter((ma) => ma.type === 'Savings')
        .reduce((acc, ma) => acc + cleanCurrency(ma.amount), 0)

      const currentDebt = manual_accounts
        .filter((ma) => ma.type === 'Debt')
        .reduce((acc, ma) => acc + cleanCurrency(ma.amount), 0)

      const currentCreditCards = manual_accounts
        .filter((ma) => ma.type === 'Credit Card')
        .reduce((acc, ma) => acc + cleanCurrency(ma.amount), 0)

      setCurrentCash(currentCash)
      setCurrentCredit(currentCreditCards)
      setCurrentDebt(currentDebt)
      setCurrentSavings(currentSavings)
    }
    //////////////////// If we do not have Manual Accounts (ie: last one just got deleted)
    if (!getManualAccountData?.length) {
      setFlatManualAccounts([])
    }

    // HACK: TODO: The problem here is that our useLocalStorageSync hook needs to be wrapped in a useCallback so it doesnt change every time
    // It was meant to be a 1 to 1 wrapper for React.useState() but useState() is memoized correctly
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    plaidAccounts?.res?.data?.accounts,
    // eslint-disable-next-line
    plaidTransactions?.res?.data?.transactions,
    // eslint-disable-next-line
    getManualAccountData,
  ])

  const getAccountBreakdown = (type: 'cash' | 'savings' | 'debt', skipSkippedAccounts: boolean) => {
    return {
      accounts: [],
      total: 0,
    }
  }

  useEffect(() => {
    if (user && isAuthenticated) {
      if (
        !flatAccounts.length &&
        plaidAccounts.res.status !== 'loading' &&
        plaidAccounts.res.status === 'idle'
      ) {
        plaidAccounts.issueRequest({})
      }
    }
  }, [user, isAuthenticated, flatAccounts.length, plaidAccounts])

  useEffect(() => {
    if (user && isAuthenticated) {
      if (!flatManualAccounts.length && !getManualAccountsLoading && getManualAccountsIdle) {
        getManualAccounts({})
      }
    }
  }, [
    user,
    isAuthenticated,
    flatManualAccounts.length,
    getManualAccountsIdle,
    getManualAccountsLoading,
    getManualAccounts,
  ])

  return (
    <FinancialDataContext.Provider
      value={{
        currentCash,
        currentCredit,
        transactionsData,
        accountsData,
        currentVariableExpenseAverage,
        flatManualAccounts,
        saveManualTransaction: (params) =>
          saveManualTransaction({
            bodyParamsConfig: params,
          }),
        saveManualTransactionLoading,

        deleteManualTransaction: (params) =>
          deleteManualTransaction({
            bodyParamsConfig: params,
          }),
        deleteManualTransactionLoading,
        liabilitiesData,
        flexibleSpendPerMonth,
        /**
         * NOTE:
         *  If no transaction history is ready when /transactions/get is called, it will return a PRODUCT_NOT_READY error.
         */
        getTransactions: plaidTransactions.issueRequest,

        manualAccounts: getManualAccountData || [],
        getManualAccounts: getManualAccounts,
        getManualAccountsLoading: getManualAccountsLoading,
        saveManualAccountData,
        deleteManualAccountData,
        flatAccounts,
        transactions,
        getAccounts: plaidAccounts.issueRequest,
        getLiabilities: plaidLiabilities.issueRequest,
        getAccountBreakdown,
        currentDebt,
        currentSavings,
        netWorth: currentCash - currentDebt,
        deleteAccountData,
      }}>
      {children}
    </FinancialDataContext.Provider>
  )
}
