import React, { useState } from 'react'
import { ColorType, FontSizeType } from 'theme'
import { StyledButtonButton } from './Button.components'
interface StyledButtonProps {
  size?: FontSizeType
  colorPrimary?: ColorType
  colorSecondary?: ColorType
  jumbo?: boolean
  inactive?: boolean
}

export const StyledButton: React.FC<
  StyledButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = (props) => {
  const [pressed, setPressed] = useState<boolean>(false)
  return (
    <StyledButtonButton
      {...props}
      colorPrimary={props.colorPrimary || 'logoPrimary'}
      colorSecondary={props.colorSecondary || 'logoPrimary'}
      onTouchStart={() => setPressed(true)}
      onTouchEnd={() => setPressed(false)}
      className={
        pressed
          ? `pressed ${props.className}`
          : props.inactive
          ? `inactive ${props.className}`
          : props.className
      }
    />
  )
}
