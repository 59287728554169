import { Icon, StyledVr, TextSpan } from 'Components/DesignSystem/Typography'
import { useUIState } from 'Providers/UIStateProvider'
import React from 'react'

import PlaidLogo from 'Logos/plaid-white-logo.png'

interface Props {
  onManualAccountAddClick?: () => void
  onLiveAccountAddClick?: () => void
}
export const NewAccountCTA: React.FC<Props> = ({
  onLiveAccountAddClick,
  onManualAccountAddClick,
}) => {
  const { setAddCardPageOpen, setAddManualAccountPageOpen } = useUIState()
  return (
    <div className="d-flex flex-row align-items-center justify-content-center mt-3">
      <div
        onClick={() => {
          setAddCardPageOpen('')
          onLiveAccountAddClick && onLiveAccountAddClick()
        }}
        className="d-flex flex-column align-items-center justify-content-center">
        <div className="d-flex flex-row">
          <Icon size="xs" color={'logoSecondary'} className="fas fa-plus mr-1" />
          <div
            style={{
              position: 'relative',
            }}>
            <Icon size="xl" color={'logoSecondary'} className="far fa-building-columns" />
            <img
              alt="plaid-logo"
              src={PlaidLogo}
              style={{
                maxWidth: '18px',

                position: 'absolute',
                bottom: '-12px',
                right: '-18px',
              }}
            />
          </div>
        </div>
        <TextSpan className="mt-3" color="logoSecondary" weight={500} size="xxs">
          link live account
        </TextSpan>
      </div>
      <StyledVr weight={1} className="mx-4" size="xl" color="textsupertransparent" />
      <div
        onClick={() => {
          setAddManualAccountPageOpen({
            name: '',
            amount: '',
            account_id: '',
            type: 'Savings',
            transactions: [],
          })
          onManualAccountAddClick && onManualAccountAddClick()
        }}
        className="d-flex flex-column align-items-center justify-content-center">
        <div className="d-flex flex-row">
          <Icon size="xs" color={'text'} className="fas fa-plus mr-1" />
          <div
            style={{
              position: 'relative',
            }}>
            <Icon size="xl" color={'text'} className="far fa-building-columns" />
            <Icon
              size="sm"
              color={'text'}
              className="fas fa-pencil"
              style={{
                maxWidth: '18px',

                position: 'absolute',
                bottom: '-12px',
                right: '-18px',
              }}
            />
          </div>
        </div>
        <TextSpan className="mt-3" color="text" weight={500} size="xxs">
          add manual account
        </TextSpan>
      </div>
    </div>
  )
}
