import { AccountMaskAndLogo } from 'Components/AccountMaskAndLogo'
import { AccountBase } from 'plaid'
import React from 'react'
import { TextSpan } from '../../Components/DesignSystem/Typography'
import { useFinancialData } from '../../Providers/FinancialDataProvider/FinancialDataProvider'
import { numberToCurrency } from '../../Utilities/currencyFormater'

interface Props {
  account: AccountBase
  setActivelySelectedAccountId: (id: string) => void
  activelySelectedAccountId: string
}
export const ConnectedSpendingAccountRow: React.FC<Props> = ({
  account,
  activelySelectedAccountId,
  setActivelySelectedAccountId,
}) => {
  const { transactions } = useFinancialData()

  const pendingTransactionsFromConnectedCreditCardAccount =
    transactions
      ?.filter((t) => account.account_id === t.account_id)
      .filter((t) => t.pending)
      .reduce((acc, curr) => acc + curr.amount, 0) || 0
  const pendingChargesOnThisCard = pendingTransactionsFromConnectedCreditCardAccount
  return (
    <div className="d-flex w-100 align-items-center ml-0 my-1">
      <div
        style={{ display: 'inline-flex' }}
        onClick={(e) => {
          e.stopPropagation()

          setActivelySelectedAccountId(
            activelySelectedAccountId === account.account_id ? '' : account.account_id
          )
        }}>
        <AccountMaskAndLogo orientation="horizontal" accountId={account.account_id} logoSize={32} />
      </div>
      <TextSpan
        color="texttransparent"
        className="my-0 mx-2"
        size="xs"
        style={{ display: 'inline' }}>
        =
      </TextSpan>
      <TextSpan
        color="texttransparent"
        className="my-0 mx-1"
        size="xs"
        style={{ display: 'inline' }}>
        <TextSpan size="sm" color="mehred" weight={500} className="mr-1">
          {numberToCurrency(account?.balances?.current || 0)}
        </TextSpan>
        {pendingChargesOnThisCard ? (
          <TextSpan size="xs" style={{ display: 'inline' }}>
            +
            <TextSpan size="xs" color="mehred" className="mx-1" weight={500}>
              {numberToCurrency(pendingChargesOnThisCard || 0, true, true)}
            </TextSpan>
            (pending)
          </TextSpan>
        ) : null}
      </TextSpan>
    </div>
  )
}
