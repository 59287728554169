import { Icon, TextBold, TextPopupSmall, TextSpan } from 'Components/DesignSystem/Typography'
import { ComplexityModeType, useUIState } from 'Providers/UIStateProvider'
import React from 'react'
import { ColorType } from 'theme'
import { SettingsItem } from '../components'

export const ComplexityToggle: React.FC = () => {
  const { complexityMode, setComplexityMode } = useUIState()
  const toggleComplexityMode = () =>
    complexityMode === 'simple'
      ? setComplexityMode('complex')
      : complexityMode === 'complex'
      ? setComplexityMode('advanced')
      : setComplexityMode('simple')

  const iconFromComplexityMode = (mode: ComplexityModeType): string => {
    switch (mode) {
      case 'simple':
        return 'fas fa-lightbulb'
      case 'complex':
        return 'fas fa-cogs'
      case 'advanced':
        return 'fas fa-flask'
    }
  }

  const colorFromComplexityMode = (mode: ComplexityModeType): ColorType => {
    switch (mode) {
      case 'simple':
        return 'cashgreen'
      case 'complex':
        return 'yellow'
      case 'advanced':
        return 'mehred'
    }
  }

  return (
    <SettingsItem onClick={() => toggleComplexityMode()}>
      <div className="d-flex flex-column w-100 align-items-start">
        <div className="d-flex flex-row w-100 align-items-center">
          <Icon className={`fas fa-dial`} />
          <TextBold>App Complexity Level: </TextBold>
          <TextPopupSmall className="p-1" color={colorFromComplexityMode(complexityMode)}>
            {complexityMode}

            <Icon
              color={colorFromComplexityMode(complexityMode)}
              className={`${iconFromComplexityMode(complexityMode)} ml-1`}
            />
          </TextPopupSmall>
        </div>
        <TextSpan size="xxs" color="texttransparent">
          Explore deeper layers of the app & see more details
        </TextSpan>
      </div>
    </SettingsItem>
  )
}
