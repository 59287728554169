import { InvestmentTransaction } from 'plaid'
import { LinkType } from '../../Utilities/navigationTabUtilities'

export type FlexibleCalculationMethodType = 'manual' | 'average' | null

/**
 * Here is the deal with this nonsense now:
 *
 * I as Alex would like to be able to live track my savings before they run out
 * I can only imagine I'm not the only user who is interested in being able to do this
 * I would like to be able to see my historical withdrawals from savings as transactions made in the past.
 * I have my savings both in regular savings accounts (Marcus, SoFi)
 * and investment accounts (Wealthfront)
 *
 * For Investment accounts, all the withdrawals are actually Plaid.InvestmentTransactions
 * for Regular Savings, all the transactions are Plaid.Transactions
 *
 * In order to be display these all the same on the UI, I need the intersection of attributes from each
 * Luckily, the most important ones - amount and date - are the same.
 *
 * This Hybrid interface Transaction however can ONLY be used for the purposes of the Dashboard historical graph
 * to show on what date in the past a transaction was made and for how much. That is it. Graph should require no other information.
 *
 * Other parts of Splurv require richer transaction data - merchant, categories, pending, etc - and these will pull from the
 * regular Transactions endpoint. For this reason, for now, we require two different endpoints
 *
 * One, to enrich the Dashboard for all Tracked Accounts, is this hybrid that can pull both regular Transactions and Investment Transactions (to capture the withdrawals)
 * Two, to provide richer spending analysis, is the regular one that simply pulls Transactions only.
 *
 * These two endpoints are similar in the following ways
 * - They both take an accountId and itemId to be able to pull transactions per account
 * - They both pull the full 2 year max so we can capture the most amount of data
 * - They both filter out attributes from the payload that we don't need to save on cache space on the UI
 */
export interface HybridRegularAndInvestmentTransaction {
  account_id: InvestmentTransaction['account_id']
  name: InvestmentTransaction['name']
  amount: InvestmentTransaction['amount']
  date: InvestmentTransaction['date']
  transaction_id: InvestmentTransaction['transaction_id']
}

/**
 * If a user has linked a manual account to be used for live dashboard progress,
 * then we will be able to get that ManualAccounts balance and transactions from the
 * Manual Account that exists from the API request
 */
type LinkedManualAccount = {
  type: 'manual'
  accountId: string
}

/**
 * If a user has linked a live bank account to be used for live dashboard progress,
 * then we will be able to get the Balance from the AccountsData data store and
 * we will be able to get the Transactions from a TransactionsForAccount request that will only fire
 * on the dashboard.
 */
type LinkedLiveAccount = {
  type: 'live'
  accountId: string
  itemId: string
}

export type SelectedAccountType = LinkedManualAccount | LinkedLiveAccount

// export interface SelectedAccountType {
//   itemId: string
//   accountId: string
//   transactions: HybridRegularAndInvestmentTransaction[]
// }

/**
 * This is the shape of the data that is stored in the backend that
 * comes back from the API call to get the user's onboarding setup
 */
export interface UserOnboardingSetupInterface {
  mode: MysteryDayType | null
  subMode: CushionSubModeType | null
  variableSpend: number
  completed: boolean
  linkedAccount: SelectedAccountType | null
  linkedAccounts: SelectedAccountType[]

  debtAmount: number
  desiredTimeOff: number

  itemName: string
  itemCost: string
  itemExplanation: string
}

export type MysteryDayType =
  | 'default'
  | 'getOutOfDebt'
  | 'buildCushion'
  | 'getOrganized'
  | 'saveToQuit'

export type CushionSubModeType = null | 'getOrganized' | 'extendSavings' | 'emergencyFund'

export const cushionSubModeToTitle = {
  getOrganized: `Organization`,
  emergencyFund: `Emergency`,
  extendSavings: `Coast`,
}

export type MysteryPageType =
  | 'mode-selection' // First Page of the Mystery Wizard
  // Input Pages
  | 'debt-amount-input'
  | 'cost-of-item'
  | 'desired-time-off'
  | 'desired-cushion'
  // MCOL
  | 'total-cost-of-living-intro'
  | 'cost-of-living-fixed'
  | 'cost-of-living-variable'
  | 'total-cost-of-living-complete'
  //
  | 'savings-total'
  | 'paycheck-or-income'
  // Last Page, the default once user has completed this process
  | 'contribution-setting'

export type DashboardViewModeType = 'standard' | 'graph'

export type HistoricalTrackedTransaction = Pick<
  HybridRegularAndInvestmentTransaction,
  'amount' | 'date' | 'name' | 'account_id'
>

export interface MysteryDayContextState {
  historicalTrackedTransactions: HistoricalTrackedTransaction[]
  clearHistoricalTrackedTransactions: () => void

  saveUserOnboardingSetup: (setup: UserOnboardingSetupInterface) => Promise<void>
  deleteUserOnboardingSetup: () => Promise<void>

  saveUserOnboardingLinkedAccount: (setup: SelectedAccountType) => Promise<void>
  saveUserOnboardingLinkedAccountLoading: boolean

  saveUserOnboardingSetupLoading: boolean
  getUserOnboardingSetupLoading: boolean
  deleteUserOnboardingSetupLoading: boolean

  page: MysteryPageType
  setPage: (page: MysteryPageType) => void
  mysteryMode: MysteryDayType
  setMysteryMode: (mysterymode: MysteryDayType) => void

  cushionSubMode: CushionSubModeType
  setCushionSubMode: (mode: CushionSubModeType) => void

  debtAmount: string
  setDebtAmount: (amount: string) => void

  itemCost: string
  setItemCost: (amount: string) => void

  itemExplanation: string
  setItemExplanation: (v: string) => void

  itemName: string
  setItemName: (v: string) => void

  monthlyContribution: number
  setMonthlyContribution: (value: number) => void

  debtInterest: number
  setDebtInterest: (amount: number) => void

  // months
  desiredTimeOff: number
  setDesiredTimeOff: (amount: number) => void

  startDate: string // 'yyyy-mm-dd'
  setStartDate: (date: string) => void

  livingCostPercentageAdjustment: number
  setLivingCostPercentageAdjustment: (percentage: number) => void

  onboardingDone: boolean
  setOnboardingDone: (value: boolean) => void

  resetOnboarding: () => void

  dashboardViewMode: DashboardViewModeType
  setDashboardViewMode: (mode: DashboardViewModeType) => void

  calculationMethod: FlexibleCalculationMethodType
  setCalculationMethod: (method: FlexibleCalculationMethodType) => void

  pagesUnlocked: LinkType[]
  onboardingPagesVisited: MysteryPageType[]

  unlockPage: (page: LinkType[]) => void
  visitOnboardingPage: (page: MysteryPageType) => void

  bigWhy: string
  setBigWhy: (why: string) => void

  linkedAccount: SelectedAccountType | null

  linkedAccounts: SelectedAccountType[]
  addLinkedAccount: (account: SelectedAccountType) => void

  // addTransactionsToLinkedAccount: (
  //   account: Pick<SelectedAccountType, 'itemId' | 'accountId'>,
  //   transactions: HybridRegularAndInvestmentTransaction[]
  // ) => void

  // removeLinkedAccount: (account: Pick<SelectedAccountType, 'itemId' | 'accountId'>) => void

  clearLinkedAccounts: () => void

  // T | ((prevState: T) => T)

  setLinkedAccount: (
    t: SelectedAccountType | null | ((t: SelectedAccountType | null) => SelectedAccountType)
  ) => void
}
