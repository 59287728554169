import { Icon, TextSpan } from 'Components/DesignSystem/Typography'
import { humanReadifyStringDate, stringToDate } from 'Utilities/dateUtilities/dateUtilities'
import React from 'react'

export const PurchasedOnBadge: React.FC<{
  purchasedDate: string
}> = ({ purchasedDate }) => {
  if (!purchasedDate) return null
  return (
    <div className="d-flex align-items-start justify-content-start">
      {/* <StyledVr /> */}
      <TextSpan
        className="ml-0"
        style={{
          marginTop: '-.125rem',
          marginBottom: '0',
          transition: 'none',
        }}
        color="orange"
        size="xxxxs">
        Purchased on:{' '}
        <TextSpan
          weight={700}
          color="orange"
          className="ml-1"
          size="xxxs"
          style={{
            transition: 'none',
          }}>
          {humanReadifyStringDate(stringToDate(purchasedDate))}
        </TextSpan>
      </TextSpan>
    </div>
  )
}

export const ResistedOnBadge: React.FC<{
  resistedDate: string
}> = ({ resistedDate }) => {
  if (!resistedDate) return null
  return (
    <div className="d-flex align-items-start justify-content-start">
      {/* <StyledVr /> */}
      <TextSpan
        className="ml-0"
        style={{
          marginTop: '-.125rem',
          marginBottom: '0',
          transition: 'none',
        }}
        color="megapurple"
        size="xxxxs">
        Resisted on:{' '}
        <TextSpan
          weight={700}
          size="xxxs"
          color="megapurple"
          className="ml-1"
          style={{
            transition: 'none',
          }}>
          {humanReadifyStringDate(stringToDate(resistedDate))}
        </TextSpan>
      </TextSpan>
    </div>
  )
}

export const DaysLeftToWaitBadge: React.FC<{
  daysLeftToWait: number
}> = ({ daysLeftToWait }) => {
  if (!daysLeftToWait) return null
  if (daysLeftToWait < 0)
    return (
      <div className="d-flex align-items-start justify-content-start my-1 flex-column">
        <div className="d-flex align-items-start justify-content-start">
          {/* <StyledVr /> */}
          <TextSpan
            className="ml-0"
            style={{
              marginTop: '0',
              marginBottom: '0',
              transition: 'none',
            }}
            color="texttransparent"
            size="xxxs">
            30 days successfully waited
            <Icon className="fas fa-party-horn ml-1" color="logoPrimary" size="xxs" />
          </TextSpan>
        </div>
        {/* <div className="d-flex align-items-start justify-content-start my-0">
          <TextSpan
            className="ml-0"
            style={{
              marginTop: '0',
              marginBottom: '0',
              transition: 'none',
              textTransform: 'none',
              textDecoration: 'underline',
            }}
            color="texttransparent"
            size="xxxxs">
            Mark as{' '}
            <TextSpan className="my-0 mx-1" color="orange">
              Purchased
            </TextSpan>{' '}
            or{' '}
            <TextSpan className="my-0 mx-1" color="megapurple">
              Resisted{' '}
            </TextSpan>
          </TextSpan>
        </div> */}
      </div>
    )
  return (
    <div className="d-flex align-items-start justify-content-start">
      {/* <StyledVr /> */}
      <TextSpan
        className="ml-0"
        style={{
          marginTop: '-.125rem',
          marginBottom: '0',
          transition: 'none',
        }}
        color="texttransparent"
        size="xxxxs">
        Days left to wait:
        <TextSpan
          weight={700}
          size="xxxs"
          color="text"
          className="ml-1"
          style={{
            transition: 'none',
          }}>
          {daysLeftToWait}
        </TextSpan>
      </TextSpan>
    </div>
  )
}
