import { Icon, TextSlim, TextSpan } from 'Components/DesignSystem/Typography'
import { EmptyStatePage } from 'Components/EmptyStatePage/EmptyStatePage'
import { useUIState } from 'Providers/UIStateProvider'
import React from 'react'
import { useHistory } from 'react-router'

export const CreditCardEmptyStatePage: React.FC = () => {
  const { push } = useHistory()
  const { setAccountsPageOpen, setSettingsPageOpen } = useUIState()

  return (
    <EmptyStatePage
      subtitle={
        <TextSpan color="logoPrimary" size="lg" weight={200}>
          <Icon className="far fa-building-columns mr-2" color="logoPrimary" size="lg" />
          Accounts
        </TextSpan>
      }
      content={
        <div className="">
          <TextSlim className="my-0" color="texttransparent" weight={200} size="md">
            Once you finish onboarding, this is where all your connected bank accounts will be
            displayed. <br /> <br />
            From this page you can connect more accounts, delete old ones, and setup Credit Card /
            Checking Account Tethering, Nicknames, and Spending Limits.
          </TextSlim>
        </div>
      }
      cta={
        <div className="d-flex flex-row w-100 justify-content-around align-items-center mb-5">
          <TextSpan
            onClick={() => {
              setAccountsPageOpen(false)
              setSettingsPageOpen(true)
            }}
            className="mt-2"
            color="texttransparent"
            size="xs">
            <u>Back</u>
          </TextSpan>
          <TextSpan
            onClick={() => {
              push('/mystery')
              setAccountsPageOpen(false)
            }}
            className="mt-2"
            color="texttransparent"
            size="xs">
            <u>Finish onboarding</u>
          </TextSpan>
        </div>
      }
    />
  )
}
