import { Icon, StyledHr, TextSlim } from 'Components/DesignSystem/Typography'
import { WelcomeToSplurv } from 'Components/WelcomeToSplurv/WelcomeToSplurv'
import React from 'react'

const LargeTextLoader: React.FC<{ loading: boolean }> = ({ loading }) => {
  if (!loading) {
    return null
  }

  return (
    <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100 px-4">
      <TextSlim color="logoSecond" size="sm">
        Doing the fancy login stuff...
      </TextSlim>
      <StyledHr color="logoSecond" weight={1} className="my-0" style={{ opacity: 0.2 }} />
      <div
        className="d-flex align-items-center w-100 justify-content-center my-2 "
        style={{ minHeight: '48px' }}>
        <Icon
          color="logoSecond"
          size="xxl"
          className={`fa-duotone fa-unicorn mx-2 fa-fade`}
          style={
            {
              '--fa-animation-delay': 0,
              '--fa-animation-duration': '3s',
            } as React.CSSProperties
          }
        />
        <Icon
          color="logoSecond"
          size="xxl"
          className={`fa-duotone fa-narwhal mx-2 fa-fade`}
          style={
            {
              '--fa-animation-delay': '500ms',
              '--fa-animation-duration': '3s',
            } as React.CSSProperties
          }
        />
        <Icon
          color="logoSecond"
          size="xxl"
          className={`fa-duotone fa-cauldron  mx-2 fa-fade`}
          style={
            {
              '--fa-animation-delay': '1000ms',
              '--fa-animation-duration': '3s',
            } as React.CSSProperties
          }
        />
      </div>
      <StyledHr color="logoSecond" weight={1} className="my-0" style={{ opacity: 0.2 }} />
    </div>
  )
}

export const LoginLoadingComponent: React.FC<{ loading: boolean }> = ({ loading }) => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100 px-4">
      <WelcomeToSplurv />
      <LargeTextLoader loading={loading} />
    </div>
  )
}
