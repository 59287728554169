import { FOOTER_ACTION_ROW_HEIGHT } from 'Components/FooterActionRow/FooterActionRow'
import { FrequencyType, MoneyStream } from '../../Utilities/interfaces'
import { streamPageHeaderSize } from './StreamPageHeader'
import { streamPageSubHeaderSize } from './StreamPageSubHeader'

export const EmptyStream: MoneyStream = {
  name: '',
  amount: 0,
  enabled: true,
  type: 'income',
  day: 1,
  frequency: FrequencyType.monthly,
} as MoneyStream

export const EmptyBill: MoneyStream = {
  name: '',
  amount: 0,
  enabled: true,
  type: 'bill',
  day: 1,
  frequency: FrequencyType.monthly,
} as MoneyStream

export const EmptySubscription: MoneyStream = {
  name: '',
  amount: 0,
  enabled: true,
  isSubscription: true,
  type: 'bill',
  frequency: FrequencyType.monthly,
} as MoneyStream

// Constants
export const scrollingSectionCutoutSize =
  streamPageHeaderSize + streamPageSubHeaderSize + FOOTER_ACTION_ROW_HEIGHT
