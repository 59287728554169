import { animated } from '@react-spring/web'
import styled from 'styled-components/macro'
import { ColorType } from 'theme'

export const Button = styled(animated.button)`
  position: fixed;
  top: 2rem;
  right: 1rem;
  height: 3rem;
  padding: 1.5rem;
  border-radius: 0px;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background: ${(props) => props.theme.colors.second};
  border: 2px solid ${(props) => props.theme.colors.second};
  color: ${(props) => props.theme.colors.text};
  font-family: ${(props) => props.theme.fonts.family.secondary};
  box-shadow: rgb(0 0 0 / 30%) 0px 0px 4px 2px;
  font-weight: 800;
  font-size: 1rem;
  z-index: 1000;
  animation: border-updater infinite 2s alternate;

  @keyframes border-updater {
    from {
      border-radius: 0px;
    }
    to {
      border-radius: 1.5rem;
    }
  }
`

export const WelcomeContainer = styled.div<{ pauseScroll?: boolean; appMode?: boolean }>`
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  position: fixed;
  overflow: hidden;
  overflow-y: ${(props) => (props.pauseScroll ? 'hidden' : 'scroll')};

  top: 0;
  left: 0;
  /* NOTE: This top padding here is for the notch on iPhones */
  /* In this same commit we are getting rid of the padding from index.css on body */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  text-align: left;

  background: ${(props) =>
    props.appMode
      ? `linear-gradient(135deg, #ffffff, #b6d8e3)`
      : `linear-gradient(135deg, #071c23, #245d74)`};

  section {
    width: 100%;
    padding: 3rem 1.5rem 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    text-align: left;
  }
`

export const PageContainer = styled.div<{ pauseScroll?: boolean }>`
  margin: 0 auto;

  width: 100%;
  max-width: 100vw;
  @media (min-width: 768px) {
    width: 1000px;
    max-width: 1000px;
  }
`

const screenshotHeight = window.innerHeight * 0.57
const ratio = 0.46
const screenshotWidth = screenshotHeight * ratio

export const ScreenshotCarousel = styled.div<{ appMode?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  justify-content: ${(props) => (props.appMode ? 'center' : 'flex-start')};
  overflow: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* height: ${(props) => `${screenshotHeight}px`};
  min-height: ${(props) => `${screenshotHeight}px`};
  max-height: ${(props) => `${screenshotHeight}px`}; */

  height: ${(props) => `${screenshotHeight}px`};
  min-height: ${(props) => `${screenshotHeight}px`};
  max-height: ${(props) => `${screenshotHeight}px`};
`

export const ScreenshotSlot = styled.img`
  object-fit: cover;
  margin: 0 0.25rem;

  min-width: 120px;
  width: 120px;
  max-width: 120px;

  min-width: ${(props) => `${screenshotWidth}px`};
  width: ${(props) => `${screenshotWidth}px`};
  max-width: ${(props) => `${screenshotWidth}px`};

  height: ${(props) => `${screenshotHeight}px`};
  min-height: ${(props) => `${screenshotHeight}px`};
  max-height: ${(props) => `${screenshotHeight}px`};
  border-radius: 12px;
`

export const InstallButton = styled.button<{ color: ColorType }>`
  width: 80%;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 4px solid ${(props) => props.theme.colors[props.color]};
  color: 2px solid ${(props) => props.theme.colors[props.color]};
  border-radius: 3rem;
`

export const LightIndicator = styled.div<{
  color?: ColorType
  placement: 'inapp' | 'demo' | 'relative'
}>`
  position: ${(props) => (props.placement === 'relative' ? 'unset' : 'fixed')};
  right: ${(props) => (props.placement === 'relative' ? 'unset' : 'inapp' ? '3.25rem' : '4.5rem')};
  top: ${(props) => (props.placement === 'relative' ? 'unset' : 'inapp' ? '2.25rem' : '3.5rem')};
  font-family: Raleway, sans-serif;
  font-weight: 800;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 0.25rem;

  background: ${(props) => props.theme.colors[props?.color || 'textsupertransparent']};
  box-shadow: ${(props) =>
    `0 0 15px 0 ${props.theme.colors[props?.color || 'textsupertransparent']}`};
  transition: all 0.5s;
  display: flex;
  align-items: center;
  z-index: 500;
  label {
    color: ${(props) => props.theme.colors[props?.color || 'textsupertransparent']};
    margin-left: 0.65rem;
    font-size: ${(props) => props.theme.fonts.sizes.xxxs};
  }
`
