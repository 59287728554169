import { format } from 'date-fns'
import isSameDay from 'date-fns/isSameDay'
import dayjs from 'dayjs'
import { MoneyStream } from '../interfaces'

// TEST ME
export const getAmountForEvent = (
  s: MoneyStream,
  d: Date,
  paycheckCashContributionOverride: number | null,
  creditCardAverageOverride?: number | null,
  overallExpenseAdjustmentPercentage?: number | null
) => {
  const potentialOverride = s?.overrides
    // NOTE: sort to get the most recent one on top
    ?.sort((oa, ob) => (oa.date > ob.date ? -1 : 1))
    ?.find((o) => {
      const overrideDate = dayjs(o.date).toDate()
      return isSameDay(overrideDate, d)
    })

  if (s?.upcomingEvents?.find((ue) => ue.date === format(d, 'yyyy-MM-dd'))?.paidOff) {
    return 0
  }

  if (s.name === 'Paycheck') {
    if (s.savingsContribution) {
      if (potentialOverride) {
        return Number(potentialOverride.amount) - Number(s.savingsContribution)
      }
      if (paycheckCashContributionOverride) {
        return paycheckCashContributionOverride
      }
      return s.amount - s.savingsContribution
    }
    if (potentialOverride) {
      return Number(potentialOverride.amount) - Number(s.savingsContribution || 0)
    }
    if (paycheckCashContributionOverride) {
      return paycheckCashContributionOverride
    }
  }

  if (s.transfer) {
    return Number(s.amount)
  }

  if (s.type === 'bill') {
    if (overallExpenseAdjustmentPercentage) {
      return s.amount * overallExpenseAdjustmentPercentage
    }
  }

  if (s.name === 'Credit Card') {
    if (creditCardAverageOverride) {
      return s.amount * (creditCardAverageOverride / 100)
    }
  }

  if (potentialOverride) {
    return Number(potentialOverride.amount)
  }
  return s.amount
}
