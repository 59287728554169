import { TextSpan } from 'Components/DesignSystem/Typography'
import generatedGitInfo from 'generatedGitInfo.json'
import React from 'react'
export const LatestGitInfo = () => {
  return (
    <TextSpan
      style={{ zIndex: 50 }}
      className="my-0"
      color="second"
      size="xxxxs"
      weight={200}
      alignSelf="center"
      textAlign="center">
      <TextSpan weight={700} color="second" size="xxxxs" className="mr-1">
        version:{' '}
      </TextSpan>
      {generatedGitInfo.gitCommitMessage}

      {/* <TextSpan weight={700} color="second" size="xxxxs" className="mr-1 ml-1">
        ({generatedGitInfo.gitCommitTime})
      </TextSpan> */}
    </TextSpan>
  )
}
