import { MainIntro } from 'Components/DesignSystem/Layout/Containers/Containers'
import { Icon, StyledHr, StyledVr, TextSlim, TextSpan } from 'Components/DesignSystem/Typography'
import { fireConfetti } from 'Pages/WishlistPage/Confetti'
import { useUIState } from 'Providers/UIStateProvider'
import { useUserConfig } from 'Providers/UserConfigProvider/UserConfigProvider'
import React from 'react'
import { colorPalletes } from 'theme'

export const Story: React.FC = () => {
  const { setShowStoryPage, setAppOverviewPageSeen } = useUserConfig()
  const { activeTheme } = useUIState()
  return (
    <MainIntro
      className="pt-5 px-3"
      style={{
        background:
          activeTheme === 'newDark'
            ? 'linear-gradient(180deg, #363942, #2D3142)'
            : 'linear-gradient(180deg, #F3F5EC, #EDF7FC)',
        color: 'text',
      }}>
      <div className="d-flex w-100 align-items-center justify-content-between mt-3">
        <div className="d-flex w-100 align-items-start justify-content-between flex-column">
          <TextSlim size="xxl" color="text">
            Our Story
          </TextSlim>
        </div>

        <StyledVr size="xxl" className="mx-1" weight={1} />
        <div
          onClick={() => {
            setShowStoryPage(false)
            setAppOverviewPageSeen(true)

            setTimeout(() => {
              fireConfetti({
                colors: [colorPalletes[activeTheme].second],
                duration: 0.3,
              })
            }, 50)
          }}
          className="d-flex align-items-center justify-content-between flex-column ml-3"
          style={{ flex: 1 }}>
          <Icon
            style={{
              textShadow: 'rgb(99 99 99) 0 0px 9px',
              bottom: '4rem',
              alignSelf: 'center',
              zIndex: 5000,
              width: '2rem',
              height: '2rem',
            }}
            className="fas fa-chevron-up"
            color={'text'}
            size="xxl"
          />
          <TextSpan
            tightness="superspaced"
            className="my-0"
            size="xs"
            color={'text'}
            style={{
              textTransform: 'uppercase',
            }}>
            done
          </TextSpan>
        </div>
      </div>
      <StyledHr color="text" style={{ opacity: 0.5 }} />
      <TextSlim
        className="mt-3"
        size="md"
        color="text"
        style={{
          lineHeight: '2.25rem',
        }}>
        <b>Splurv</b> happened because we felt lost with personal finance. We spent 3 years
        tinkering, experimenting, reading, learning, building, shaming ourselves, rewarding
        ourselves, and everything in between to get closer to a state of financial simplicity.
      </TextSlim>
      <StyledHr color="nearlyclear" weight={1} className="my-1" />
      <TextSlim
        className="mt-2"
        size="md"
        color="text"
        style={{
          lineHeight: '2.25rem',
        }}>
        We believe that <b>time</b> is the only currency that matters, and that with the right
        framing, personal finance can be empowering and simple at the same time.
      </TextSlim>
      <TextSlim
        className="mt-4"
        size="md"
        color="text"
        style={{
          lineHeight: '2.25rem',
        }}>
        <b>Splurv</b> is that new framing.
      </TextSlim>
    </MainIntro>
  )
}
