import React, { useCallback, useEffect, useRef } from 'react'
import { Transition, config } from 'react-spring/renderprops'
import { ThemeProvider } from 'styled-components/macro'
import { colorPalletes, myTheme } from 'theme'
import { useUIState } from '../../Providers/UIStateProvider'
import { Content, ModalContainer } from './PopupModal.components'

interface Props {
  customColorPallete?: keyof typeof colorPalletes
}
export const PopupModal: React.FC<Props> = ({ customColorPallete }) => {
  const { modalContent, setModalContent } = useUIState()

  const modalContainerRef = useRef<HTMLDivElement | null>(null)
  const modalRef = useRef<HTMLDivElement | null>(null)

  // const [exitPath, setExitPath] = React.useState<'above' | 'below'>('above')

  const clickListener = useCallback(
    (event: MouseEvent) => {
      if (modalRef.current) {
        const { top, bottom } = modalRef.current.getBoundingClientRect()
        const { clientY } = event

        if (clientY < top) {
          setModalContent(null)
        } else if (clientY > bottom) {
          setModalContent(null)
        } else {
        }
      }
    },
    [modalRef, setModalContent]
  )

  useEffect(() => {
    if (modalContainerRef.current) {
      modalContainerRef.current.addEventListener('click', clickListener)

      return () => {
        // eslint-disable-next-line
        modalContainerRef.current?.removeEventListener('click', clickListener)
      }
    } else {
    }
  }, [modalContainerRef, clickListener])

  // const firstClick = useRef<boolean>(true)

  // React.useEffect(() => {
  //   const clickClose = (event: MouseEvent) => {
  //     const specifiedElement = document.getElementById('popup-message')

  //     // @ts-ignore
  //     const isClickInside = specifiedElement?.contains(event?.target)
  //     if (!isClickInside && messagePopupContent && !firstClick.current) {
  //       console.log('outside click!!!!!!!!!')
  //       setMessagePopupContent('')
  //       firstClick.current = true
  //     } else {
  //       firstClick.current = false
  //     }
  //   }
  //   document.addEventListener('click', clickClose)

  //   return () => {
  //     document.removeEventListener('click', clickClose)
  //   }
  //   // eslint-disable-next-line
  // }, [messagePopupContent])

  return (
    <ModalContainer active={!!modalContent} ref={modalContainerRef}>
      <Transition
        items={!!modalContent}
        config={{
          ...config.stiff,
        }}
        from={{ opacity: 0, transform: 'translate3d(100%, 0, 0)' }}
        enter={{ opacity: 1, transform: 'translate3d(0,0,0)' }}
        leave={{ opacity: 0, transform: 'translate3d(100%,0,0)' }}>
        {/* from={{ opacity: 1, transform: 'translate3d(0,100%,0)' }}
      enter={{ opacity: 1, transform: 'translate3d(0,50%,0)' }}
      leave={{ opacity: 1, transform: 'translate3d(0,100%,0)' }}> */}
        {(show) =>
          show &&
          ((props) => (
            <ThemeProvider
              theme={{ ...myTheme, colors: colorPalletes[customColorPallete || 'newLight'] }}>
              <Content
                ref={modalRef}
                id="popup-message"
                data-testid="floating-message-popup"
                style={props}>
                {/* <div
                  style={{
                    position: 'absolute',
                    top: '1rem',
                    right: '1rem',
                  }}
                  data-testid="dismiss-popup-button"
                  onClick={() => {
                    setModalContent(null)
                  }}
                  className="d-flex align-items-center justify-content-end w-100">
                  <Icon
                    size="xxl"
                    color="text"
                    style={{ opacity: !!modalContent ? 1 : 0 }}
                    // style={{ position: 'absolute', right: '1.5rem', top: '.5rem' }}
                    className="fas fa-times"
                  />
                </div> */}
                {modalContent}
              </Content>
            </ThemeProvider>
          ))
        }
      </Transition>
    </ModalContainer>
  )
}
