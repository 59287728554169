import { AccountMaskAndLogo } from 'Components/AccountMaskAndLogo'
import { Icon, TextSpan } from 'Components/DesignSystem/Typography'
import { NicknameLabelTag } from 'Components/NicknameLabelTag/NicknameLabelTag'
import { SpendingLimitTag } from 'Components/SpendingLimitTag/SpendingLimitTag'
import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import { useUIState } from 'Providers/UIStateProvider'
import { useUserCustomSettings } from 'Providers/UserCustomSettingsProvider/UserCustomSettingsProvider'
import { DAYS_PER_MONTH } from 'Utilities/constants'
import { numberToCurrency } from 'Utilities/currencyFormater'
import { differenceInDays, endOfMonth } from 'date-fns'
import React from 'react'
import { ProgressWidget } from './ProgressWidget/ProgressWidget'
import { SpendingRowProps } from './SpendingAccountRow'
import { useGetSpendingAccount } from './hooks/useGetSpendingAccount'

import color from 'color'

import { CurrencyCounter } from 'Components/AnimatedCounters/CurrencyCounter'
import { ComplexityLevelGate } from 'Components/ComplexityLevelGate/ComplexityLevelGate'
import {
  MysteryModeButtonTitlesFromMysteryDayType,
  colorFromMysteryDayType,
  iconFromMysteryDayType,
} from 'Utilities/utils'
import { useHistory } from 'react-router'
import { colorPalletes } from 'theme'

export const SpendingRow: React.FC<SpendingRowProps> = ({
  account: passedInAccount,
  activelySelectedAccountId,
  setActivelySelectedAccountId,
  events,
  isOpenFromTetheredView,
  index,
}) => {
  const { settings } = useUserCustomSettings()
  const { activeTheme, complexityMode, setAccountsPageOpen } = useUIState()

  const { push } = useHistory()

  const { linkedAccount, linkedAccounts, mysteryMode } = useMysteryDay()

  const connectedCreditCards =
    settings?.accountMetadata.find((datum) => datum.account_id === passedInAccount.account_id)
      ?.tetheredCreditCards || []

  const allConnectedCreditCards =
    settings?.accountMetadata.map((datum) => datum.tetheredCreditCards)?.flat() || []

  const accountIsTiedToGoal = React.useMemo(() => {
    if (linkedAccount && linkedAccount.accountId === passedInAccount.account_id) {
      return true
    }
    if (
      linkedAccounts &&
      linkedAccounts.map((la) => la?.accountId).includes(passedInAccount.account_id)
    ) {
      return true
    }
    return false
  }, [passedInAccount, linkedAccount, linkedAccounts])

  const nextPayDateForThisAccount = events.find(
    (e) => e.destinationAccount === passedInAccount.name
  )

  const {
    tetheredBalance,
    originalBalance,
    thisMonthAccountSpending,
    thisMonthAccountTetheredSpending,
  } = useGetSpendingAccount(passedInAccount)
  // const { institutionColor  = useGetInstitutionMetadata(passedInAccount)

  // const nextPayDate = nextPayDateForThisAccount?.date || new Date()
  // const daysUntilPayDate = differenceInDays(nextPayDate, new Date())
  const daysUntilEndOfMonth = differenceInDays(endOfMonth(new Date()), new Date())
  const daysSinceStartOfMonth = Math.floor(DAYS_PER_MONTH - daysUntilEndOfMonth)
  // const nextPayAmount = numberToCurrency(Number(nextPayDateForThisAccount?.amount || 0), true, true)

  // const accountType = passedInAccount.subtype === AccountSubtype.CreditCard ? 'Credit Card' : 'Cash'

  if (
    allConnectedCreditCards?.includes(passedInAccount.account_id) &&
    activelySelectedAccountId !== passedInAccount.account_id
  ) {
    return null
  }

  const accountMetadata = settings?.accountMetadata
  const foundAccount = accountMetadata?.find((am) => am.account_id === passedInAccount.account_id)

  const nickname = foundAccount?.name
  const spendingLimitPerMonth = foundAccount?.spendingLimitPerMonth

  const dynamicThisMonthAccountSpendingBasedOnTethering = isOpenFromTetheredView
    ? thisMonthAccountTetheredSpending + thisMonthAccountSpending
    : thisMonthAccountSpending

  const totalAllowedSpendPerMonth =
    spendingLimitPerMonth || Number(nextPayDateForThisAccount?.amount || 0)

  const percentageIntoMonth = Math.floor((daysSinceStartOfMonth / DAYS_PER_MONTH) * 100)
  const percentageOfSpendingLimitSpent = Math.floor(
    (dynamicThisMonthAccountSpendingBasedOnTethering / totalAllowedSpendPerMonth) * 100
  )

  const spendingIsProblematic = percentageOfSpendingLimitSpent > percentageIntoMonth

  return (
    <div
      key={passedInAccount.account_id}
      style={{
        opacity: activelySelectedAccountId
          ? activelySelectedAccountId === passedInAccount.account_id
            ? 1
            : 0.3
          : 1,
      }}
      className="flex-column w-100 py-2 mt-2 mb-1">
      <div className="d-flex w-100 align-items-start">
        <div>
          <AccountMaskAndLogo accountId={passedInAccount.account_id} logoSize={36} />
        </div>

        <>
          {accountIsTiedToGoal ? (
            <div className="d-flex flex-row align-items-center">
              <div className="d-flex flex-column align-items-start ml-2">
                <TextSpan
                  size="xxs"
                  className="my-0 mr-1"
                  weight={200}
                  color="textsupertransparent">
                  Current Balance:
                </TextSpan>
                <TextSpan size="xxs" color={'text'} className="my-0">
                  {numberToCurrency(passedInAccount.balances.current, true)}
                </TextSpan>
                <div className="d-flex flex-row">
                  <TextSpan
                    color={colorFromMysteryDayType(mysteryMode)}
                    weight={200}
                    className="my-0 ml-0 mr-1"
                    size="xxs"
                    style={{}}>
                    Goal ={' '}
                    <TextSpan weight={700} className="my-0 ml-1">
                      {MysteryModeButtonTitlesFromMysteryDayType(mysteryMode).title}
                    </TextSpan>
                  </TextSpan>
                </div>
              </div>
              <Icon
                onClick={() => {
                  push('/mystery')
                }}
                color={colorFromMysteryDayType(mysteryMode)}
                size="xl"
                className={iconFromMysteryDayType(mysteryMode)}
              />
            </div>
          ) : complexityMode === 'simple' ? (
            <div className="d-flex flex-column align-items-start w-100 justify-content-start mb-0 ml-2">
              <div className="d-flex flex-row align-items-center">
                <div className="d-flex flex-row align-items-center">
                  {/* <TextSpan
                        size="xxs"
                        className="my-0 mr-1"
                        weight={200}
                        color="textsupertransparent">
                        {accountType === 'Credit Card'
                          ? // ? 'Current Account Balance'
                            'Current Balance'
                          : // : 'Available Account Balance'}
                            'Available Balance'}
                        :
                      </TextSpan> */}

                  <div className="d-flex flex-row align-items-start">
                    <ComplexityLevelGate
                      minComplexityLevel={connectedCreditCards.length ? 'complex' : 'simple'}>
                      <TextSpan
                        size="md"
                        color={originalBalance! > 0 ? 'cashgreen' : 'mehred'}
                        weight={700}
                        className=""
                        style={{
                          margin: '0',
                          padding: '2px 4px',
                          borderRadius: '3px',
                          // background: color(colorPalletes[activeTheme].text)
                          //   .saturate(2)
                          //   .toString(),
                        }}>
                        {numberToCurrency(originalBalance, true, true)}
                      </TextSpan>
                    </ComplexityLevelGate>

                    {connectedCreditCards.length > 0 ? (
                      <div className="d-flex flex-column align-items-start">
                        {/* <TextSpan
                          size="xxs"
                          className="my-0 mr-1"
                          weight={200}
                          color="orangeTransparent">
                          Tethered:
                        </TextSpan> */}
                        <TextSpan
                          size="md"
                          color={tetheredBalance! > 0 ? 'cashgreen' : 'mehred'}
                          weight={700}
                          className="my-0 ml-0"
                          style={{
                            margin: '8px',
                            padding: '2px 4px',
                            borderRadius: '3px',
                            // background: color(colorPalletes[activeTheme].orange)
                            //   .darken(0.2)
                            //   .toString(),
                          }}>
                          {numberToCurrency(tetheredBalance, true, true)}
                        </TextSpan>
                        {/* <TextSpan color="orange" size="xxxs" className="m-0" style={{}}>
                              tethered
                            </TextSpan> */}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row align-items-center w-100 justify-content-start my-1">
                <div
                  onClick={() => {
                    if (!nickname) {
                      setAccountsPageOpen(true)
                    }
                  }}>
                  <NicknameLabelTag nickname={nickname} />
                </div>
                {/* <StyledVr className="mx-1 my-0" size="xxs" color="texttransparent" weight={1} /> */}
                {/* <div
                  onClick={() => {
                    if (!spendingLimitPerMonth) {
                      setAccountsPageOpen(true)
                    }
                  }}>
                  <SpendingLimitTag
                    limit={spendingLimitPerMonth}
                    status={
                      dynamicThisMonthAccountSpendingBasedOnTethering > (spendingLimitPerMonth || 0)
                        ? 'bad'
                        : spendingIsProblematic
                        ? 'warn'
                        : 'good'
                    }
                  />
                </div> */}
              </div>
            </div>
          ) : (
            <ProgressWidget
              postComponent={
                <ComplexityLevelGate minComplexityLevel="complex">
                  <div className="d-flex flex-row align-items-start w-100 justify-content-between mb-0 mt-1">
                    <div className="d-flex flex-column align-items-start">
                      <div className="d-flex flex-column align-items-end justify-content-end">
                        <div className="d-flex flex-row align-items-center">
                          <TextSpan
                            size="xxs"
                            className="my-0 mr-1"
                            weight={200}
                            color="textsupertransparent">
                            Spent so far:
                          </TextSpan>
                          <TextSpan
                            size="xxs"
                            color={
                              dynamicThisMonthAccountSpendingBasedOnTethering >
                              (spendingLimitPerMonth || 0)
                                ? 'mehred'
                                : spendingIsProblematic
                                ? 'yellow'
                                : 'cashgreen'
                            }
                            className="my-0">
                            <CurrencyCounter
                              value={dynamicThisMonthAccountSpendingBasedOnTethering}
                            />
                          </TextSpan>
                        </div>
                        {/* {connectedCreditCards.length > 0 ? (
                            <div className="d-flex flex-row align-items-center">
                              <StyledVr
                                className="mx-1"
                                color="textsupertransparent"
                                size="xxs"
                                weight={1}
                              />
                              <TextSpan
                                size="xxs"
                                className="my-0 mr-1"
                                weight={200}
                                color="orangeTransparent">
                                Tethered:
                              </TextSpan>
                              <TextSpan size="xxs" color={'orange'} className="my-0">
                                {numberToCurrency(thisMonthAccountTetheredSpending, true, true)}
                              </TextSpan>
                            </div>
                          ) : null} */}
                      </div>
                    </div>
                    <TextSpan
                      size="xxs"
                      className="my-0 mr-0"
                      weight={500}
                      style={{
                        margin: '8px',
                        padding: '2px 4px',
                        borderRadius: '3px',
                        background: color(
                          colorPalletes[activeTheme][
                            dynamicThisMonthAccountSpendingBasedOnTethering >
                            (spendingLimitPerMonth || 0)
                              ? 'mehred'
                              : spendingIsProblematic
                              ? 'yellow'
                              : 'cashgreen'
                          ]
                        )
                          .alpha(0.2)
                          .toString(),
                      }}
                      color="text">
                      {numberToCurrency(
                        (spendingLimitPerMonth || 0) -
                          dynamicThisMonthAccountSpendingBasedOnTethering,
                        true,
                        true
                      )}{' '}
                      <TextSpan size="xxs" weight={200} className="ml-1">
                        left
                      </TextSpan>
                    </TextSpan>
                  </div>
                </ComplexityLevelGate>
              }
              preComponent={
                <div className="d-flex flex-column align-items-start w-100 justify-content-start mb-0">
                  <div className="d-flex flex-row align-items-center">
                    <div className="d-flex flex-row align-items-center">
                      {/* <TextSpan
                            size="xxs"
                            className="my-0 mr-1"
                            weight={200}
                            color="textsupertransparent">
                            {accountType === 'Credit Card'
                              ? // ? 'Current Account Balance'
                                'Current Balance'
                              : // : 'Available Account Balance'}
                                'Available Balance'}
                            :
                          </TextSpan> */}

                      <div className="d-flex flex-row align-items-start">
                        {/* <ComplexityLevelGate
                          minComplexityLevel={connectedCreditCards.length ? 'complex' : 'simple'}>
                          <TextSpan
                            size="md"
                            color={originalBalance! > 0 ? 'cashgreen' : 'mehred'}
                            weight={700}
                            className=""
                            style={{
                              margin: '0',
                              padding: '2px 4px',
                              borderRadius: '3px',
                              // background: color(colorPalletes[activeTheme].text)
                              //   .saturate(2)
                              //   .toString(),
                            }}>
                            {numberToCurrency(originalBalance, true, true)}
                          </TextSpan>
                        </ComplexityLevelGate> */}

                        {connectedCreditCards.length > 0 ? (
                          <div className="d-flex flex-column align-items-start">
                            {/* <TextSpan
                              size="xxs"
                              className="my-0 mr-1"
                              weight={200}
                              color="orangeTransparent">
                              Tethered:
                            </TextSpan> */}
                            <TextSpan
                              size="md"
                              color={tetheredBalance! > 0 ? 'cashgreen' : 'mehred'}
                              weight={700}
                              className="my-0 ml-0"
                              style={{
                                margin: '8px',
                                padding: '2px 4px',
                                borderRadius: '3px',
                                // background: color(colorPalletes[activeTheme].orange)
                                //   .darken(0.2)
                                //   .toString(),
                              }}>
                              {numberToCurrency(tetheredBalance, true, true)}
                            </TextSpan>
                            {/* <TextSpan color="orange" size="xxxs" className="m-0" style={{}}>
                                  tethered
                                </TextSpan> */}
                          </div>
                        ) : (
                          <div className="d-flex flex-column align-items-start">
                            {/* <TextSpan
                              size="xxs"
                              className="my-0 mr-1"
                              weight={200}
                              color="orangeTransparent">
                              Tethered:
                            </TextSpan> */}
                            <TextSpan
                              size="md"
                              color={originalBalance! > 0 ? 'cashgreen' : 'mehred'}
                              weight={700}
                              className="my-0 ml-0"
                              style={{
                                margin: '8px',
                                padding: '2px 4px',
                                borderRadius: '3px',
                                // background: color(colorPalletes[activeTheme].orange)
                                //   .darken(0.2)
                                //   .toString(),
                              }}>
                              {numberToCurrency(originalBalance, true, true)}
                            </TextSpan>
                            {/* <TextSpan color="orange" size="xxxs" className="m-0" style={{}}>
                                  tethered
                                </TextSpan> */}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center w-100 justify-content-between my-1">
                    <NicknameLabelTag nickname={nickname} />
                    {/* <StyledVr className="mx-1 my-0" size="xxs" color="texttransparent" weight={1} /> */}
                    <SpendingLimitTag
                      limit={spendingLimitPerMonth}
                      status={
                        dynamicThisMonthAccountSpendingBasedOnTethering >
                        (spendingLimitPerMonth || 0)
                          ? 'bad'
                          : spendingIsProblematic
                          ? 'warn'
                          : 'good'
                      }
                    />
                  </div>
                </div>
              }
              maxValue={spendingLimitPerMonth || 0}
              value={dynamicThisMonthAccountSpendingBasedOnTethering}
              progressColor={
                dynamicThisMonthAccountSpendingBasedOnTethering > (spendingLimitPerMonth || 0)
                  ? 'mehred'
                  : spendingIsProblematic
                  ? 'yellow'
                  : 'cashgreen'
              }
            />
          )}
        </>
      </div>
    </div>
  )
}
