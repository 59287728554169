import { isBefore, isSameDay, startOfMonth, startOfWeek, startOfYear, sub } from 'date-fns'
import isAfter from 'date-fns/isAfter'
import dayjs from 'dayjs'
import { Transaction } from 'plaid'
import { defaultCreditCardSpendData } from '../constants'
import { Account } from '../interfaces'
import { getCategoryOfTransaction } from './categoryHelper/category_helper'
import {
  calculateMonthlyAverageGivenBreakdownType,
  transactionCountedTowardsSpending,
} from './helpers'
import {
  CreditCardGroupingEnum,
  CreditCardSpendCacheInterface,
  CreditCardSpendingBreakdown,
  TransactionGrouping,
  TransactionGroupingTrasaction,
} from './interfaces'
import { sum } from './utils'

type CreditCardTransactionFilterType = 'all' | 'fun' | 'essential'

const toFixedNumber = (num: number, digits: number) => {
  var pow = Math.pow(10, digits)
  return Math.round(num * pow) / pow
}

const CreditCardSpendCache: CreditCardSpendCacheInterface = {}

interface AccountTransactionMappingInterface {
  [index: string]: {
    accountName: string
    previousBalance: number
    lastStatementIssueDate: string
    projectedStatement: number
  }
}

/**
 * Plaid:
 *
 * " [Transaction.amount] The settled value of the transaction, denominated in the transactions's currency, as stated in iso_currency_code or unofficial_currency_code.
 * Positive values when money moves out of the account;
 * negative values when money moves in.
 * For example,
 * debit card purchases are positive;
 * credit card payments, direct deposits, and refunds are negative.""
 * @param transactions
 * @param accountTransactionMapping
 * @param filterType
 * @param filterForSpending
 * @returns
 */
export const getTransactionSpendingTotal = (
  transactions: Transaction[],
  accountTransactionMapping?: AccountTransactionMappingInterface,
  filterType: CreditCardTransactionFilterType = 'all',
  filterForSpending: boolean = true
) => {
  const transactionGrouping: TransactionGrouping = {
    today: [],
    thisWeek: [],
    lastMonth: [],
    thisMonth: [],
    thisStatement: [],
    lastTwoStatements: [],
    thisYear: [],
    lastYear: [],
    alltime: [],
  }
  const transactionGroupingTransactions: TransactionGroupingTrasaction = {
    today: [],
    thisWeek: [],
    lastMonth: [],
    thisMonth: [],
    thisStatement: [],
    lastTwoStatements: [],
    thisYear: [],
    lastYear: [],
    alltime: [],
  }

  const today = new Date()
  today.setHours(0, 0, 0, 0)

  const thisWeek = startOfWeek(today)
  thisWeek.setHours(0, 0, 0, 0)

  const thisMonth = startOfMonth(today)
  thisMonth.setHours(0, 0, 0, 0)

  const lastMonth = sub(startOfMonth(today), { months: 1 })
  lastMonth.setHours(0, 0, 0, 0)

  const thisYear = startOfYear(today)
  thisYear.setHours(0, 0, 0, 0)

  const lastYear = sub(startOfYear(today), { years: 1 })
  lastYear.setHours(0, 0, 0, 0)

  const oldestTransaction = transactions[transactions.length - 1]
  const oldestTransactionDate = oldestTransaction?.date

  transactions
    .filter(filterForSpending ? transactionCountedTowardsSpending : () => true)
    .filter(filterForSpending ? (t) => t.amount > 0 : () => true)
    .filter((t) => (filterType === 'all' ? true : getCategoryOfTransaction(t) === filterType))
    .forEach((t) => {
      // NOTE: remove statement breakdowns since they are not as useful and require more data than just transactions to work
      const transactionDate = dayjs(t.date).toDate()
      transactionDate.setHours(0, 0, 0, 0)

      // Today
      if (isAfter(transactionDate, today) || isSameDay(transactionDate, today)) {
        transactionGroupingTransactions.today.push(t)
        transactionGrouping.today.push(t.amount)
      }

      //  This Week
      if (isAfter(transactionDate, thisWeek) || isSameDay(transactionDate, thisWeek)) {
        transactionGroupingTransactions.thisWeek.push(t)
        transactionGrouping.thisWeek.push(t.amount)
      }

      // This Month
      if (isAfter(transactionDate, thisMonth) || isSameDay(transactionDate, thisMonth)) {
        transactionGroupingTransactions.thisMonth.push(t)
        transactionGrouping.thisMonth.push(t.amount)
      }

      // Last Month
      if (
        (isAfter(transactionDate, lastMonth) || isSameDay(transactionDate, lastMonth)) &&
        isBefore(transactionDate, thisMonth)
      ) {
        transactionGroupingTransactions.lastMonth.push(t)
        transactionGrouping.lastMonth.push(t.amount)
      }

      // This Statement

      if (accountTransactionMapping) {
        const accountId = t.account_id
        const lastStatementIssueDate =
          accountTransactionMapping[accountId]?.lastStatementIssueDate ||
          dayjs(new Date()).format('YYYY-MM-DD')

        const thisStatement = dayjs(lastStatementIssueDate).toDate()
        thisStatement.setHours(0, 0, 0, 0)

        const previousStatementDate = dayjs(thisStatement).subtract(1, 'month').toDate()
        previousStatementDate.setHours(0, 0, 0, 0)
        if (isAfter(transactionDate, thisStatement)) {
          transactionGroupingTransactions.thisStatement.push(t)
          transactionGrouping.thisStatement.push(t.amount)
        }

        // Last Two Statements
        if (
          isAfter(transactionDate, previousStatementDate) ||
          isSameDay(transactionDate, previousStatementDate)
        ) {
          transactionGroupingTransactions.lastTwoStatements.push(t)
          transactionGrouping.lastTwoStatements.push(t.amount)
        }
      }

      // This Year
      if (isAfter(transactionDate, thisYear) || isSameDay(transactionDate, thisYear)) {
        transactionGroupingTransactions.thisYear.push(t)
        transactionGrouping.thisYear.push(t.amount)
      }

      // Last Year
      if (
        (isAfter(transactionDate, lastYear) || isSameDay(transactionDate, lastYear)) &&
        isBefore(transactionDate, thisYear)
      ) {
        transactionGroupingTransactions.lastYear.push(t)
        transactionGrouping.lastYear.push(t.amount)
      }

      transactionGroupingTransactions.alltime.push(t)
      transactionGrouping.alltime.push(t.amount)
    })

  const totals = Object.values(CreditCardGroupingEnum).reduce((acc, curr) => {
    acc[curr] = toFixedNumber(sum(transactionGrouping[curr]), 2)
    return acc
  }, {} as { [key in CreditCardGroupingEnum]: number })

  const transactionCounts = Object.values(CreditCardGroupingEnum).reduce((acc, curr) => {
    acc[curr] = transactionGrouping[curr].length
    return acc
  }, {} as { [key in CreditCardGroupingEnum]: number })

  const monthlyAverages = Object.values(CreditCardGroupingEnum).reduce((acc, curr) => {
    acc[curr] = toFixedNumber(
      calculateMonthlyAverageGivenBreakdownType(curr, totals[curr], oldestTransactionDate),
      2
    )
    return acc
  }, {} as { [key in CreditCardGroupingEnum]: number })

  // const currentStatementSpending = totals.thisStatement

  // let projectedStatement = Math.abs(
  //   currentStatementSpending * -1 + Math.abs(currentPaymentsMade) + -1 * previousBalance
  // )
  // if (currentPaymentsMade === 0) {
  //   projectedStatement = projectedStatement - previousBalance
  // }

  const result = {
    ...Object.values(CreditCardGroupingEnum).reduce((acc, curr) => {
      acc[curr] = {
        total: totals[curr],
        monthlyAverage: monthlyAverages[curr],
        transactionCount: transactionCounts[curr],
        transactions: transactionGroupingTransactions[curr],
      }
      return acc
    }, {} as CreditCardSpendingBreakdown),
    // projectedStatement,
  }
  return result
}

export const getGlobalCreditCardSpend = (
  transactions: Transaction[],
  filterType: CreditCardTransactionFilterType = 'all'
) => {
  // const cacheHitKey = `${creditCards.map((c) => c.lastUpdatedAt).join('-')}-${filterType}`
  // if (CreditCardSpendCache[cacheHitKey]?.alltime?.transactions?.length) {
  //   return CreditCardSpendCache[cacheHitKey]
  // }

  const result = getTransactionSpendingTotal(transactions, {}, filterType)

  // CreditCardSpendCache[cacheHitKey] = { ...result }
  return result
}

/**
 * @param card
 * @param filterType
 * @returns
 */
// TESTED
// Tested and dialed in and working
//  TRACKME: PROJECTED STATEMENT CALCULATION
export const getCreditCardSpend = (
  card: Account | null,
  filterType: CreditCardTransactionFilterType = 'all'
): CreditCardSpendingBreakdown => {
  if (!card) {
    return defaultCreditCardSpendData
  }

  const cacheHitKey = `${card.lastUpdatedAt}-${filterType}`

  if (CreditCardSpendCache[cacheHitKey]) {
    return CreditCardSpendCache[cacheHitKey]
  }

  const answer = getTransactionSpendingTotal(
    card.transactions,
    {
      [card.itemId]: {
        accountName: card.accountName,
        previousBalance: card.balance,
        lastStatementIssueDate: card.lastStatementIssueDate,
        projectedStatement: 1,
      },
    },
    filterType
  )

  const result = answer

  CreditCardSpendCache[cacheHitKey] = result

  return result
}

/**
 *
 * @param transactions
 * @param creditCards
 * @param filterType
 *
 *
 * {
 *   "Jan 2020" : {
 *     "USBank" : 2000,
 *     "Chase" : 1400,
 *     "Amex": 0
 *   },
 *
 *   "Feb 2020": {
 *   },
 *
 *   "Mar 2020": {
 *   },
 * }
 *
 */
