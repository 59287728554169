import color from 'color'
import styled from 'styled-components/macro'
export const DataOrFeatureMissingContainer = styled.div`
  border-radius: 8px;
  padding: 0.5rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: ${(props) =>
    `solid 1px ${color(props.theme.colors.backgrounddark).darken(0.1).toString()}`};
  color: ${(props) => `solid 1px ${color(props.theme.colors.backgrounddark).darken(1).toString()}`};
`
