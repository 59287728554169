import { TextPopup, TextSpan } from 'Components/DesignSystem/Typography'
import React from 'react'

interface Props {
  timeFactor: boolean
  setTimeFactor: (timeFactor: boolean) => void
  readonly: boolean
}

export const TimeFactor: React.FC<Props> = ({ timeFactor, setTimeFactor, readonly }) => {
  return (
    <div
      className="d-flex flex-column align-items-start px-2 w-100"
      style={{
        opacity: readonly ? 0.6 : 1,
      }}>
      <TextSpan color="textsupertransparent" size="xxs" className="mb-1" weight={700}>
        {readonly
          ? 'Timeline Influence (limited time sale, upcoming trip, etc'
          : 'Is this desire pressured by a timeline? Eg: limited time sale, upcoming trip, etc'}
      </TextSpan>
      <div className="d-flex flex-row w-100 justify-content-around">
        <TextPopup
          weight={300}
          className="px-3"
          onClick={() => setTimeFactor(false)}
          color={!timeFactor ? 'mehred' : 'textsupertransparent'}>
          NO
        </TextPopup>
        <TextPopup
          onClick={() => setTimeFactor(true)}
          className="px-3"
          weight={300}
          color={timeFactor ? 'cashgreen' : 'textsupertransparent'}>
          YES
        </TextPopup>
      </div>
    </div>
  )
}
