import { Transaction } from 'plaid'

interface CreditCardSpendingDatum {
  total: number
  transactionCount: number
  monthlyAverage: number
  transactions: Transaction[]
}

export enum CreditCardGroupingEnum {
  today = 'today',
  thisWeek = 'thisWeek',
  lastMonth = 'lastMonth',
  thisMonth = 'thisMonth',
  thisStatement = 'thisStatement',
  lastTwoStatements = 'lastTwoStatements',
  thisYear = 'thisYear',
  lastYear = 'lastYear',
  alltime = 'alltime',
}

export type CreditCardSpendingBreakdown = {
  [key in CreditCardGroupingEnum]: CreditCardSpendingDatum
}

export type TransactionGrouping = {
  [key in CreditCardGroupingEnum]: number[]
}

export type TransactionGroupingTrasaction = {
  [key in CreditCardGroupingEnum]: Transaction[]
}

export interface CreditCardSpendCacheInterface {
  [index: string]: CreditCardSpendingBreakdown
}
