import { useUIState } from 'Providers/UIStateProvider'
import React from 'react'
import Slider from 'react-input-slider'
import { Icon, TextSpan } from '../../Components/DesignSystem/Typography'
import { useMysteryDay } from '../../Providers/MysteryDayProvider/MysteryDayProvider'
import { colorPalletes } from '../../theme'
import { useSetupSlider } from './MonthlyExpenseSlider/hooks'
import { SliderPanelContainer } from './components'

interface Props {
  slim?: boolean
  disabled?: boolean
}
export const InterestRateSlider: React.FC<Props> = ({ slim = false, disabled = false }) => {
  const { debtInterest, setDebtInterest } = useMysteryDay()
  const { activeTheme } = useUIState()

  // const sliderContainerRef = useRef<HTMLDivElement>(null)

  const { sliderContainerRef } = useSetupSlider()

  // 60 months = 5 years
  const xmax = 30
  const xstep = 0.1

  const onContributionChange = (e: { x: number; y: number }) => {
    setDebtInterest(Number(e.x.toFixed(1)))
  }

  return (
    <SliderPanelContainer>
      <div className="w-100 d-flex align-items-center justify-content-between flex-column">
        <TextSpan className="mr-1 my-0" size="sm" weight={600} color={'textsupertransparent'}>
          {debtInterest.toFixed(1)}%
        </TextSpan>{' '}
      </div>
      <div className="w-100 d-flex align-items-center justify-content-between flex-row">
        <Icon
          className={`fal fa-minus-circle mr-${slim ? 0 : 5}`}
          size={slim ? 'md' : 'xxl'}
          color="texttransparent"
          onClick={() => {
            if (disabled) return
            const newInterstRate = Number((debtInterest - xstep).toFixed(1))
            setDebtInterest(newInterstRate)
          }}
        />

        <div
          ref={sliderContainerRef}
          style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 'calc(100% - 6rem)',
          }}>
          <Slider
            disabled={disabled}
            x={debtInterest}
            onChange={onContributionChange}
            xmin={0}
            xmax={xmax}
            xstep={xstep}
            axis="x"
            styles={{
              track: {
                transition: 'all ease-in-out .3s',
                backgroundColor: colorPalletes[activeTheme].nearlyclear,
                width: 'calc(100%)',
                height: 10,
              },
              active: {
                transition: 'background-color ease-in-out .3s',
                backgroundColor: colorPalletes[activeTheme].textsupertransparent,
              },

              thumb: {
                width: slim ? 20 : 44,
                height: slim ? 20 : 44,
                opacity: 1,
                transition: 'all ease-in-out .3s',
                zIndex: 10,

                boxShadow: 'rgb(0 0 0 / 30%) 0px 0px 4px 2px',
                backgroundColor: '#757578',
                // backgroundColor: slim
                //   ? colorPalletes[activeTheme].textsupertransparent
                //   : colorPalletes[activeTheme].textsupertransparent,
              },
            }}
          />
        </div>

        <Icon
          className={`fal fa-plus-circle ml-${slim ? 0 : 5}`}
          size={slim ? 'md' : 'xxl'}
          color="texttransparent"
          onClick={() => {
            if (disabled) return
            const newInterstRate = Number((debtInterest + xstep).toFixed(1))
            if (newInterstRate > 30) {
              setDebtInterest(30)
            } else {
              setDebtInterest(newInterstRate)
            }
          }}
        />
      </div>
      <div
        style={{ position: 'relative' }}
        className="w-100 d-flex align-items-start justify-content-between px-5 ">
        <TextSpan
          style={{ alignSelf: 'flex-start', paddingLeft: '0rem' }}
          color="texttransparent"
          weight={600}
          className="mt-0"
          size="xxs">
          0%
        </TextSpan>
        <TextSpan color="texttransparent" className="mt-0" weight={600} size="xxs">
          {xmax}%
        </TextSpan>
      </div>
      <TextSpan
        weight={700}
        size="xxs"
        color="textsupertransparent"
        style={{
          marginLeft: '2rem',
        }}
        className="align-self-start">
        Interest rate
      </TextSpan>
    </SliderPanelContainer>
  )
}
