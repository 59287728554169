import { TextSpan } from 'Components/DesignSystem/Typography'
import React from 'react'
import styled from 'styled-components/macro'
import { ColorType } from 'theme'
import {
  CIRCLE_COLOR_DARKEN_RATIO,
  LEDLight,
  TOGGLE_CIRCLE_REDUCTION_PERCENTAGE,
  TOGGLE_HEIGHT,
  TOGGLE_SIDE_PADDING,
  TOGGLE_WIDTH,
} from './ToggleThreeWay'

import color from 'color'

interface Props {
  enabled: boolean
  toggleEnabled: () => void
  enabledText: string
  disabledText: string
  isMini?: boolean
  color?: ColorType
  label: string
}

const ToggleLabel = styled.label<{ isMini?: boolean }>`
  position: relative;
  display: inline-block;

  width: ${TOGGLE_WIDTH}px;
  height: ${TOGGLE_HEIGHT}px;

  /* Hide default HTML checkbox */
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`

const ToggleInput = styled.input<{ isMini?: boolean; color?: ColorType }>`
  &:checked + .slider {
    background-color: ${(props) => props.theme.colors[props.color || 'second']};
  }

  &:focus + .slider {
    box-shadow: ${(props) => `0 0 1px ${props.theme.colors[props.color || 'second']}`};
  }

  &:not(:checked) + .slider:before {
    transform: translateY(-0%) translateX(${TOGGLE_SIDE_PADDING}px);

    background-color: ${(props) =>
      color(props.theme.colors['text']).darken(CIRCLE_COLOR_DARKEN_RATIO).toString()};
  }

  &:checked + .slider:before {
    transition: all ease-in-out 0.4s;
    background-color: ${(props) =>
      color(props.theme.colors[props.color || 'second'])
        .darken(CIRCLE_COLOR_DARKEN_RATIO)
        .toString()};
    -webkit-transform: translateX(
      ${TOGGLE_WIDTH - TOGGLE_SIDE_PADDING - TOGGLE_HEIGHT * TOGGLE_CIRCLE_REDUCTION_PERCENTAGE}px
    );
    -ms-transform: translateX(
      ${TOGGLE_WIDTH - TOGGLE_SIDE_PADDING - TOGGLE_HEIGHT * TOGGLE_CIRCLE_REDUCTION_PERCENTAGE}px
    );
    transform: translateX(
      ${TOGGLE_WIDTH - TOGGLE_SIDE_PADDING - TOGGLE_HEIGHT * TOGGLE_CIRCLE_REDUCTION_PERCENTAGE}px
    );
  }
`

const ToggleSpan = styled.span<{ isMini?: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.textsupertransparent};
  -webkit-transition: 0.4s;
  transition: 0.4s;

  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    position: absolute;
    content: '';

    transform: translateY(-50%);

    height: ${TOGGLE_HEIGHT * TOGGLE_CIRCLE_REDUCTION_PERCENTAGE}px;
    width: ${TOGGLE_HEIGHT * TOGGLE_CIRCLE_REDUCTION_PERCENTAGE}px;
    left: 0;
    /* bottom: 4px; */
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  /* Rounded sliders */
  &.round {
    border-radius: ${TOGGLE_HEIGHT * TOGGLE_CIRCLE_REDUCTION_PERCENTAGE}px;
  }

  &.round:before {
    border-radius: 50%;
  }
`

const LEDLights: React.FC<{ enabled: boolean }> = ({ enabled }) => {
  return (
    <div className="d-flex align-items-center w-100 justify-content-between px-2 mb-2">
      <LEDLight color={'textsupertransparent'} active={!enabled} />
      <LEDLight color={'second'} active={enabled} />
    </div>
  )
}

export const ToggleSwitch: React.FC<Props> = ({
  enabled,
  toggleEnabled,
  enabledText,
  disabledText,
  isMini = false,
  color = 'second',
  label,
}) => {
  return (
    <div className="d-flex align-items-center flex-column my-1 p-1" onClick={() => {}}>
      <TextSpan size="xxs" color="textsupertransparent" className="flex-grow mb-1">
        {label}
      </TextSpan>

      <LEDLights enabled={enabled} />
      <ToggleLabel isMini={isMini} className="switch">
        <ToggleInput
          color={color}
          isMini={isMini}
          checked={enabled}
          onChange={(e) => {
            toggleEnabled()
          }}
          type="checkbox"
        />
        <ToggleSpan isMini={isMini} className="slider round" />
      </ToggleLabel>
      <TextSpan
        onClick={() => {
          toggleEnabled()
        }}
        weight={500}
        size="xs"
        className="my-1"
        color={enabled ? color : 'textsupertransparent'}>
        {enabled ? enabledText : disabledText}
      </TextSpan>
    </div>
  )
}
