import { FooterActionRow } from 'Components/FooterActionRow/FooterActionRow'
import { dataParameterType } from 'Hooks/useLocalStorageSyncedState'
import { cleanCurrency, numberToCurrency } from 'Utilities/currencyFormater'
import { IMPULSE_ICON } from 'Utilities/navigationTabUtilities'
import React from 'react'
import { Icon, StyledVr, TextSpan } from '../../Components/DesignSystem/Typography'
import { ActiveItemStatusFilter } from './WishlistPage'
import { useWishlist } from './WishlistProvider/WishlistProvider'

export const impulsePageFooterSize = 92

export const WishlistPageFooter: React.FC<{
  totalResisted: number
  monthsOfBurnSaved: number
  refreshItems: () => void
  openPage: () => void
  setActiveItemStatusFilter: (v: dataParameterType<ActiveItemStatusFilter>) => void
  activeItemStatusFilter: ActiveItemStatusFilter
}> = ({
  totalResisted,
  openPage,
  refreshItems,
  setActiveItemStatusFilter,
  activeItemStatusFilter,
}) => {
  const { wishlistItems } = useWishlist()

  const itemsPurchased = wishlistItems?.filter((w) => !!w.purchasedDate)
  const totalOfItemsPurchased =
    itemsPurchased?.reduce((acc, curr) => acc + cleanCurrency(curr.price), 0) || 0

  const itemsResisted = wishlistItems?.filter((w) => !!w.resistedDate)
  const totalOfItemsResisted =
    itemsResisted?.reduce((acc, curr) => acc + cleanCurrency(curr.price), 0) || 0

  const itemsWaiting = wishlistItems?.filter((w) => !w.resistedDate && !w.purchasedDate)
  const totalOfItemsWaiting =
    itemsWaiting?.reduce((acc, curr) => acc + cleanCurrency(curr.price), 0) || 0

  return (
    <FooterActionRow
      items={[
        {
          color: 'megapurple',
          title: 'Resisted',
          type: 'content',
          inactive: activeItemStatusFilter !== 'resisted',
          iconContent: (
            <div className="d-flex flex-row align-items-center " style={{}}>
              <Icon className={IMPULSE_ICON} color={'megapurple'} size="sm" />
              <StyledVr weight={1} size="xxs" className="ml-1 mr-1" color={'megapurple'} />
              <TextSpan className="my-0" color={'megapurple'} weight={700} size="sm">
                {itemsResisted?.length}
              </TextSpan>
            </div>
          ),
          onClick: () => {
            setActiveItemStatusFilter((sizeFilter) => {
              if (sizeFilter === 'resisted') {
                return 'active'
              }
              return 'resisted'
            })
          },
          subtitle: numberToCurrency(totalOfItemsResisted, true, false),
        },
        {
          color: 'orange',
          title: 'Purchased',
          inactive: activeItemStatusFilter !== 'purchased',
          type: 'content',
          iconContent: (
            <div
              className="d-flex flex-row align-items-center "
              style={{
                opacity: activeItemStatusFilter === 'purchased' ? 1 : 0.3,
              }}>
              <Icon className={'fas fa-cart-shopping'} color={'orange'} size="sm" />
              <StyledVr weight={1} size="xxs" className="ml-1 mr-1" color={'orange'} />
              <TextSpan className="my-0" color={'orange'} weight={700} size="sm">
                {itemsPurchased?.length}
              </TextSpan>
            </div>
          ),
          onClick: () => {
            setActiveItemStatusFilter((sizeFilter) => {
              if (sizeFilter === 'purchased') {
                return 'active'
              }
              return 'purchased'
            })
          },
          subtitle: numberToCurrency(totalOfItemsPurchased, true, false),
        },
        {
          color: 'text',
          title: 'Still Resisting',
          inactive: activeItemStatusFilter !== 'active',
          type: 'content',
          iconContent: (
            <div
              className="d-flex flex-row align-items-center"
              style={{
                opacity: activeItemStatusFilter === 'active' ? 1 : 0.3,
              }}>
              <Icon className={'fas fa-traffic-light'} color={'text'} size="sm" />
              <StyledVr weight={1} size="xxs" className="ml-1 mr-1" color={'text'} />
              <TextSpan className="my-0" color={'text'} weight={700} size="sm">
                {itemsWaiting?.length}
              </TextSpan>
            </div>
          ),
          onClick: () => {
            setActiveItemStatusFilter('active')
          },
          subtitle: numberToCurrency(totalOfItemsWaiting, true, false),
        },
      ]}
    />
  )
}
