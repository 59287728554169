import { AccountMaskAndLogo } from 'Components/AccountMaskAndLogo'
import { ScrollingHalf } from 'Components/DesignSystem/Layout/Containers/Containers'
import { useGetInstitutionMetadata } from 'Pages/SpendingPage/hooks/useGetInstitutionMetadata'
import { useUIState } from 'Providers/UIStateProvider'
import { useUserCustomSettings } from 'Providers/UserCustomSettingsProvider/UserCustomSettingsProvider'
import { IconButton } from 'design-system/buttons/IconButton/IconButton'
import { AccountBase, InstitutionsGetByIdResponse, ItemGetResponse } from 'plaid'
import React from 'react'
import { colorPalletes } from 'theme'
import { Icon, StyledHr, TextSlim, TextSpan } from '../../../Components/DesignSystem/Typography'
import { useFinancialData } from '../../../Providers/FinancialDataProvider/FinancialDataProvider'
import {
  getAmountForMixedAccount,
  getCashAccounts,
} from '../../../Providers/FinancialDataProvider/helpers'
import { numberToCurrency } from '../../../Utilities/currencyFormater'
import { CheckingAccountSelectionRow } from './CheckingAccountSelectionRow'

export const bankLogoForBank = (
  bank: ItemGetResponse | undefined,
  institutions: InstitutionsGetByIdResponse[] | undefined
) => {
  if (!bank || !institutions) return ''
  const institutionId = bank?.item.institution_id
  const institution = institutions?.find((i) => i.institution.institution_id === institutionId)
  const logo = institution?.institution.logo
  return logo
}

interface CardTetherSetupPageProps {
  account: AccountBase | null
  closePage: () => void
}
export const CardTetherSetupPage: React.FC<CardTetherSetupPageProps> = ({
  account: creditCardAccount,
  closePage,
}) => {
  const { activeTheme } = useUIState()
  const { flatAccounts } = useFinancialData()

  const { saveTetheredCreditCard, getSettings } = useUserCustomSettings()

  const accounts = [...getCashAccounts(flatAccounts)]

  const [chosenAccount, setChosenAccount] = React.useState<string>('')
  const [chosenAccountHydrated, setChosenAccountHyrdated] = React.useState<AccountBase | null>(null)

  const { institutionName: creditCardInstitutionName } = useGetInstitutionMetadata(
    creditCardAccount || ''
  )
  const { institutionName: chosenAccountInstitutionName } = useGetInstitutionMetadata(
    chosenAccount || ''
  )

  // useEffect(() => {
  // const connectedCreditCards =
  //   settings?.accountMetadata.map((datum) => datum.tetheredCreditCards).flat()
  //     ?.tetheredCreditCards || []

  //   const debitCardAccountLinkedToChosenCreditCard = Object.entries(
  //     linkedCreditAndDebitAccounts
  //   ).find(([debit, ccs]) => {
  //     return ccs.includes((creditCardAccount as AccountBase)?.account_id || '')
  //   })
  //   if (debitCardAccountLinkedToChosenCreditCard) {
  //     setChosenAccount(debitCardAccountLinkedToChosenCreditCard[0])
  //   }
  // }, [linkedCreditAndDebitAccounts, creditCardAccount])

  if (!creditCardAccount) return null

  return (
    <div className="w-100 h-100 d-flex flex-column">
      <div className="d-flex flex-row align-items-center justify-content-between">
        <div className="d-flex flex-row align-items-center justify-content-start">
          <Icon
            size="xl"
            color="cashgreen"
            style={
              {
                '--fa-primary-color': colorPalletes[activeTheme].orange,
                '--fa-secondary-color': colorPalletes[activeTheme].cashgreen,
                '--fa-secondary-opacity': 1,
              } as React.CSSProperties
            }
            className="fa-duotone fa-link-horizontal ml-2 px-2"
          />
          <TextSpan size="md" color="orange" className="mr-1">
            Credit
          </TextSpan>
          <TextSpan size="md" color="cashgreen">
            Tethering
          </TextSpan>
        </div>
        <Icon
          className="fas fa-times ml-2"
          size="xl"
          onClick={() => {
            closePage()
          }}
        />
      </div>
      {/* <TextSlim size="xs" color="texttransparent" className="mt-2 px-3">
        At Splurv we believe in eliminating credit cards as much as possible and using a debit card
        envelope system instead. To instill responsible and transparent spending habits <i>with</i>{' '}
        credit cards, we have something called{' '}
        <TextSpan className="my-0" color="text" weight={500}>
          Credit Tethering.
        </TextSpan>
      </TextSlim> */}

      <TextSlim size="xs" color="texttransparent" className="mt-2 px-3">
        Here you assign a specific{' '}
        <TextSpan weight={700} color="cashgreen" className="my-0">
          {' '}
          checking account
        </TextSpan>{' '}
        to be responsible for paying off a specific set of{' '}
        <TextSpan weight={700} color="orange" className="my-0">
          credit cards
        </TextSpan>{' '}
        in order to prevent over spending on credit. On the "Spending" page we'll show you a more
        accurate representation of your current balance if you tether your credit cards.
      </TextSlim>
      <div className="d-flex w-100 align-items-center justify-content-between flex-column px-3 mt-2">
        <div className="d-flex  w-100 align-items-center justify-content-start my-0">
          <TextSpan
            style={{
              whiteSpace: 'nowrap',
            }}
            className="mr-1"
            size="xxs"
            color={chosenAccount ? 'orange' : 'text'}>
            Selected Credit Card
          </TextSpan>
          <StyledHr color={chosenAccount ? 'orange' : 'text'} weight={2} className="my-1" />
        </div>
        <div className="d-flex  w-100 align-items-center justify-content-between my-1">
          <div className="d-flex  w-100 align-items-center justify-content-start my-1">
            <AccountMaskAndLogo accountId={creditCardAccount.account_id} />

            <div className="d-flex align-items-start flex-column ml-2">
              <div className="d-flex align-items-start flex-column">
                <TextSpan size="xs" className="my-0" weight={500} color="text">
                  {creditCardInstitutionName}
                </TextSpan>
                <TextSpan color="textsupertransparent" size="xxs" weight={200} className=" my-0">
                  {creditCardAccount?.official_name || creditCardAccount?.name}
                </TextSpan>
              </div>
            </div>
          </div>
          <TextSpan weight={600} color="cashgreen">
            {numberToCurrency(getAmountForMixedAccount(creditCardAccount))}
          </TextSpan>
        </div>

        <StyledHr color={chosenAccount ? 'orange' : 'text'} weight={2} className="my-1" />
      </div>

      <TextSlim size="sm" color="texttransparent" className="mt-2 px-3 text-center">
        Choose from the following accounts to assign:
      </TextSlim>
      <ScrollingHalf className="px-3">
        {accounts.map((checkingAccount) => (
          <CheckingAccountSelectionRow
            checkingAccount={checkingAccount}
            setChosenAccount={setChosenAccount}
            setChosenAccountHydrated={setChosenAccountHyrdated}
            chosenAccount={chosenAccount}
          />
        ))}
      </ScrollingHalf>

      <div className="w-100 d-flex align-items-center justify-content-between mt-3 px-3">
        <div
          style={{ opacity: chosenAccount ? 1 : 0 }}
          className="d-flex flex-row align-items-center justify-content-start w-100 mr-3">
          <div className="d-flex   align-items-start justify-content-start my-0">
            <AccountMaskAndLogo accountId={creditCardAccount?.account_id} />
            <div className="d-flex align-items-start flex-column ml-1">
              <div className="d-flex align-items-start flex-column">
                <TextSpan size="xxs" className="my-0" weight={500} color="orange">
                  {creditCardInstitutionName}
                </TextSpan>

                <TextSpan
                  weight={600}
                  color="orange"
                  size="xxs"
                  className="my-0"
                  style={{ opacity: 0.6 }}>
                  {numberToCurrency(
                    getAmountForMixedAccount(
                      creditCardAccount || (({ amount: 0 } as unknown) as AccountBase)
                    )
                  )}
                </TextSpan>
              </div>
            </div>
          </div>

          <Icon
            size="lg"
            color="cashgreen"
            style={
              {
                '--fa-primary-color': colorPalletes[activeTheme].orange,
                '--fa-secondary-color': colorPalletes[activeTheme].cashgreen,
                '--fa-secondary-opacity': 1,
              } as React.CSSProperties
            }
            className="fa-duotone fa-link-horizontal mx-2"
          />

          <div className="d-flex  w-100 align-items-start justify-content-start my-0">
            <AccountMaskAndLogo accountId={chosenAccountHydrated?.account_id} />
            <div className="d-flex align-items-start flex-column ml-1">
              <div className="d-flex align-items-start flex-column">
                <TextSpan size="xxs" className="my-0" weight={500} color="cashgreen">
                  {chosenAccountInstitutionName}
                </TextSpan>

                <TextSpan
                  weight={600}
                  color="cashgreen"
                  size="xxs"
                  className="my-0"
                  style={{
                    opacity: 0.6,
                  }}>
                  {numberToCurrency(
                    getAmountForMixedAccount(
                      chosenAccountHydrated || (({ amount: 0 } as unknown) as AccountBase)
                    )
                  )}
                </TextSpan>
              </div>
            </div>
          </div>
        </div>

        <IconButton
          title="save"
          disabled={!chosenAccount}
          iconClassName="fas fa-check"
          color="cashgreen"
          onDisabledClick={() => {
            alert('Please tether a checking account to this credit card')
          }}
          onClick={async () => {
            await saveTetheredCreditCard({
              account_id: chosenAccount,
              tetheredCreditCard: (creditCardAccount as AccountBase)?.account_id,
            })
            await getSettings()
            closePage()
          }}
        />
      </div>
    </div>
  )
}
