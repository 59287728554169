import { Auth0Provider } from '@auth0/auth0-react'
import React from 'react'

const domainEnvKey = `REACT_APP_${process.env.REACT_APP_APP_ENV}_AUTH0_DOMAIN`
const domain = String(process.env[domainEnvKey])
const clientId = String(process.env[`REACT_APP_${process.env.REACT_APP_APP_ENV}_AUTH0_CLIENT_ID`])
const audience = String(process.env[`REACT_APP_${process.env.REACT_APP_APP_ENV}_AUTH0_AUDIENCE`])

export const Auth0ProviderWithHistory: React.FC = ({ children }) => {
  const onRedirectCallback = (appState: any) => {
    // IOS-NATIVE: The query paremter originally passed in from the WebKKView that bypasses the PWA landing page (for the PWA route)
    // gets lost on the redirect and user gets dropped at deadend PWA marketing CTA
    // NOTE: This might be why queryParams are not coming back from Auth0
    window.history.replaceState({}, document.title, window.location.pathname) // <= THIS
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      useRefreshTokens={true}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
      authorizeTimeoutInSeconds={60 * 3600 * 24}
      maxAge={60 * 3600 * 24}
      cacheLocation={'localstorage'}>
      {children}
    </Auth0Provider>
  )
}
