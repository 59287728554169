import { defaultAsyncHookResponse } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import React from 'react'
import { AsyncHookResponse, MethodType, useAsyncFetch } from './useAsyncFetch'
import { useLocalStorageSync } from './useLocalStorageSync'

interface Params<QueryParamsShape, BodyParamsShape> {
  method: MethodType
  route: string
  queryParams?: QueryParamsShape
  bodyParams?: BodyParamsShape
}

export function useAsyncLocalStorageState<
  ResponseShape,
  QueryParamsShape extends Object,
  BodyParamsShape extends Object
>(params: Params<QueryParamsShape, BodyParamsShape>) {
  const { method, route } = params

  /* The local state where we will be storing data (including async data like status) for api request */
  const [data, setData] = React.useState<AsyncHookResponse<ResponseShape>>(defaultAsyncHookResponse)

  /* Get the request sending function (issueRequest) as well as the res variable that will be populated w/ data */
  const { issueRequest, res } = useAsyncFetch<ResponseShape, QueryParamsShape, BodyParamsShape>({
    method,
    route,
  })

  /* Setup subscription so that as res (data from async request) updates, we update it and write to storage*/
  /* This hook is also responsible for reading from localStorage for data that is cached*/
  useLocalStorageSync({
    data: res,
    method,
    path: route,
    setter: setData,
  })

  return {
    data: data.data,
    loading: data.status === 'loading',
    error: data.error,
    issueRequest,
    idle: data.status === 'idle',
  }
}
