export const MAX_YEARS_FOR_REPAYING_DEBT = 100

export const calculateTotalInterest = (
  principal: number,
  interestRate: number,
  periods: number
) => {
  const r = interestRate / 12 // Convert yearly interest rate to monthly
  const monthlyPayment = (r * principal) / (1 - Math.pow(1 + r, -periods)) // Calculate the monthly payment
  const totalPayments = monthlyPayment * periods // Calculate the total payments over the life of the loan
  const totalInterest = totalPayments - principal // Calculate the total interest paid
  return totalInterest // Return the total interest paid rounded to 2 decimal places
}

export const calculateMinimumMonthlyPayment = (
  principal: number,
  interestRate: number,
  maxYears: number = 10
) => {
  const r = interestRate / 12 // Convert yearly interest rate to monthly
  const n = maxYears * 12 // Calculate the number of months in 50 years
  const minimumPayment = (r * principal) / (1 - Math.pow(1 + r, -n)) // Calculate the minimum monthly payment
  return minimumPayment
}

/**
 *
 * @param p The total amount of the loan
 * @param m  The monthly payment that will be made
 * @param r  The interest rate in a decimal (5% = .05)
 * @returns The number of periods it will take to pay off (in months)
 */
export const calculateNumberOfPeriods = (P: number, M: number, r?: number) => {
  if (!r || r === 0) {
    const numberOfMonthsToRepay = Math.ceil(P / M)
    const yearsLeft = Math.floor(numberOfMonthsToRepay / 12)
    const monthsLeft = Math.ceil(numberOfMonthsToRepay - yearsLeft * 12)
    return { yearsLeft, monthsLeft }
  }
  const monthlyRate = r / 12
  const numerator = monthlyRate * P
  const denominator = M - numerator
  const logDenominator = 1 + monthlyRate
  let numberOfMonthsToRepay = -Math.log(denominator / M) / Math.log(logDenominator)

  if (numberOfMonthsToRepay === Infinity || isNaN(numberOfMonthsToRepay)) {
    return { yearsLeft: Infinity, monthsLeft: Infinity }
  }

  const yearsLeft = Math.floor(numberOfMonthsToRepay / 12)
  const monthsLeft = Math.floor(numberOfMonthsToRepay - yearsLeft * 12)

  return { yearsLeft, monthsLeft }
}

export const calculateItemPayoffValues = (
  price: number,
  monthlyPayment: number,
  startDate: Date,
  startingBalance: number = 0
) => {
  const now = performance.now()
  const principalValues = [{ balance: startingBalance, date: startDate }] // Initialize array with the initial principal and date
  let balance = startingBalance
  let currentDate = new Date(startDate) // Use a Date object to keep track of the current date
  let i = 0
  while (balance < price) {
    const principalPayment = monthlyPayment
    balance = balance + principalPayment
    currentDate.setMonth(currentDate.getMonth() + 1) // Increment the current date by one month
    principalValues.push({ balance, date: new Date(currentDate) }) // Add the remaining balance and date to the array
    i++
    if (i > 1500) {
      // Guard against infinite loops
      console.error(
        'Maximum iterations exceeded from calculateItemPayoffValues, returning shortened data set'
      )
      return principalValues
    }
  }
  const end = performance.now()
  const diff = end - now
  console.debug(`calculateItemPayoffValues: ${diff}ms`)
  return principalValues // Return the array of principal values and dates
}

export const calculatePrincipalValues = (
  principal: number,
  interestRate: number,
  monthlyPayment: number,
  startDate: Date
) => {
  const r = interestRate / 12 // Convert yearly interest rate to monthly
  const principalValues = [{ balance: -principal, date: startDate }] // Initialize array with the initial principal and date
  let balance = principal
  let currentDate = new Date(startDate) // Use a Date object to keep track of the current date
  let i = 0
  while (balance > 0) {
    const interest = balance * r // Calculate the interest for the current month
    const principalPayment = monthlyPayment - interest // Calculate the amount of principal paid for the current month
    balance -= principalPayment // Calculate the remaining balance after the current month's payment
    balance = Math.max(0, balance) // Ensure balance is non-negative
    currentDate.setMonth(currentDate.getMonth() + 1) // Increment the current date by one month
    principalValues.push({ balance: -balance, date: new Date(currentDate) }) // Add the remaining balance and date to the array
    i++
    if (i > 1500) {
      // Guard against infinite loops
      console.error(
        'Maximum iterations exceeded from calculatePrincipalValues, returning shortened data set'
      )
      return principalValues
    }
  }
  return principalValues // Return the array of principal values and dates
}

export const calculateBurndownValues = (
  principal: number,
  monthlyPayment: number,
  startDate: Date
) => {
  const now = performance.now()
  const principalValues = [{ balance: principal, date: startDate }] // Initialize array with the initial principal and date
  let balance = principal
  let currentDate = new Date(startDate) // Use a Date object to keep track of the current date
  let i = 0
  while (balance > 0) {
    const principalPayment = monthlyPayment
    balance -= principalPayment // Calculate the remaining balance after the current month's payment
    balance = Math.max(0, balance) // Ensure balance is non-negative
    currentDate.setMonth(currentDate.getMonth() + 1) // Increment the current date by one month
    principalValues.push({ balance: balance, date: new Date(currentDate) }) // Add the remaining balance and date to the array
    i++
    if (i > 1500) {
      // Guard against infinite loops
      console.error(
        'Maximum iterations exceeded from calculateBurndownValues, returning shortened data set'
      )
      return principalValues
    }
  }

  const end = performance.now()
  const diff = end - now
  console.debug(`calculateBurndownValues: ${diff}ms`)
  return principalValues // Return the array of principal values and dates
}
