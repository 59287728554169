import { TOP_CONTAINER_PADDING_FOR_NOTCH } from 'Components/DesignSystem/Layout/Containers/helpers'
import styled from 'styled-components/macro'

export const Content = styled.div`
  width: 100%;
  height: calc(100% - ${TOP_CONTAINER_PADDING_FOR_NOTCH}px);
  min-height: calc(100% - ${TOP_CONTAINER_PADDING_FOR_NOTCH}px);
  max-height: calc(100% - ${TOP_CONTAINER_PADDING_FOR_NOTCH}px);
  z-index: 7000;
  position: absolute;
  border-width: 0px;
  border-style: solid;
  border-bottom: none;
  border-color: ${(props) => props.theme.colors.second};

  background: linear-gradient(135deg, #000c15, #071c23);

  background: linear-gradient(135deg, #b6d8e3, #c8fadc);
  background: ${(props) => props.theme.colors.background};
  display: flex;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  flex-direction: column;
  align-items: center;
  bottom: 0;
`
