import { ContainerCutout } from 'Components/DesignSystem/Layout/Containers/Containers'
import { Icon, TextSpan } from 'Components/DesignSystem/Typography'
import { useData } from 'Providers/APIDataProvider'
import { useFinancialData } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import { numberToCurrency } from 'Utilities/currencyFormater'
import {
  getMonthlyBills,
  getMonthlyIncome,
  getMonthlySubscriptions,
} from 'Utilities/netWorth/netWorth'
import React from 'react'
import { useHistory } from 'react-router'
import { ColorType } from 'theme'
import { Banner } from './SpendingReductionNotificationBanner.components'

export const SpendingReductionNotificationBannerSize = 122
export const SpendingReductionNotificationBannerSizeInner = 106

interface Props {
  actionSentence: string
}
export const SpendingReductionNotificationBanner: React.FC<Props> = ({ actionSentence }) => {
  const { streams } = useData()
  const { flexibleSpendPerMonth } = useFinancialData()
  const { monthlyContribution } = useMysteryDay()

  const { push } = useHistory()

  const monthlyIncome = getMonthlyIncome(streams)
  const monthlyFixedExpenses = Math.abs(getMonthlyBills(streams) + getMonthlySubscriptions(streams))
  const monthlyVariableExpenses = flexibleSpendPerMonth
  const maxSavings = monthlyIncome - (monthlyFixedExpenses + monthlyVariableExpenses)

  const colorForContribution: ColorType =
    monthlyContribution > monthlyIncome - monthlyFixedExpenses
      ? 'mehred'
      : monthlyContribution > maxSavings
      ? 'yellow'
      : 'seconddark'

  const spendingReductionNeeded = monthlyContribution > maxSavings

  if (!spendingReductionNeeded) return null

  return (
    <ContainerCutout size={SpendingReductionNotificationBannerSizeInner} className="my-2">
      <Banner messageColor={colorForContribution}>
        <div className="d-flex flex-column align-items-start">
          <div className="d-flex flex-row justify-content-start">
            <div className="d-flex flex-row mb-2">
              <Icon className={`fas ${'fa-exclamation-triangle'} mr-1`} size="xs" />
              <TextSpan size="xs" className="my-0" weight={500}>
                Lower Monthly Cost of Living Needed:
              </TextSpan>
            </div>
            {/* <TextSpan weight={700} className="m-0 ml-2">
              -<CurrencyCounter value={Math.abs(maxSavings - monthlyContribution)} />
            </TextSpan> */}
          </div>

          <TextSpan className="my-0 d-inline" size="xxs">
            Your current{' '}
            <div
              className="d-inline"
              onClick={() => {
                push('/mystery')
              }}>
              <Icon className="fas fa-seedling" />
              <b>
                <u>Savings Contribution</u>
              </b>
            </div>{' '}
            requires you to reduce your total expenses by{' '}
            <TextSpan size="xs" weight={700} className="my-0">
              {numberToCurrency(Math.abs(maxSavings - monthlyContribution))}
            </TextSpan>
            . {actionSentence}
          </TextSpan>
        </div>
      </Banner>
    </ContainerCutout>
  )
}
