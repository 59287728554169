import { AccountMaskAndLogo } from 'Components/AccountMaskAndLogo'
import { AccountOverviewWidget } from 'Components/AccountOverviewWidget'
import {
  getAccountTypeFromTypeSubtypeTuple,
  getColorFromAccountType,
  getIconFromAccountType,
} from 'Providers/FinancialDataProvider/helpers'
import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import { useUIState } from 'Providers/UIStateProvider'
import React from 'react'
import {
  ContainerDiv,
  ScrollingHalf,
} from '../../../Components/DesignSystem/Layout/Containers/Containers'
import { Icon, TextSlim, TextSpan } from '../../../Components/DesignSystem/Typography'
import { useFinancialData } from '../../../Providers/FinancialDataProvider/FinancialDataProvider'
import { cleanCurrency, numberToCurrency } from '../../../Utilities/currencyFormater'
import { BackButton } from '../MysteryDayBackButton'
import { ForwardButton } from '../MysteryDayForwardButton'
import { SubPageProps } from '../MysteryPage'

import { NewAccountCTA } from 'Components/NewAccountCTA/NewAccountCTA'
import { useData } from 'Providers/APIDataProvider'

export const SavingsAccountBreakdown: React.FC = () => {
  const { linkedAccounts, mysteryMode, cushionSubMode, onboardingDone } = useMysteryDay()
  const { flatAccounts, flatManualAccounts } = useFinancialData()
  const { setAddManualAccountPageOpen } = useUIState()

  const isCoast = mysteryMode === 'buildCushion' && cushionSubMode === 'extendSavings'

  const showSubsetOfAccounts = isCoast && onboardingDone

  return (
    <div className="d-flex flex-column px-3 py-1">
      {flatAccounts
        .filter((fa) =>
          showSubsetOfAccounts ? linkedAccounts.some((la) => la?.accountId === fa.account_id) : true
        )
        .map((account) => {
          const accountType = getAccountTypeFromTypeSubtypeTuple([account.type, account.subtype])
          return (
            <div
              key={account.account_id}
              className="d-flex my-1 w-100 align-items-center justify-content-between">
              <div className="d-flex my-1 align-items-center justify-content-between">
                <AccountMaskAndLogo accountId={account.account_id} orientation="horizontal" />
                <Icon
                  className={`${getIconFromAccountType(accountType)} mx-3`}
                  size="sm"
                  color={getColorFromAccountType(accountType)}
                />
                <TextSpan size="xxs" color="texttransparent">
                  {account.name}
                </TextSpan>
              </div>
              <TextSpan weight={600} color={getColorFromAccountType(accountType)} size="xs">
                {numberToCurrency(
                  // This check here is because for credit cards, available balance is your full credit limit
                  // this is not a useful number, we want to show the current balance
                  // however, for debit / cash accounts, available balance is the amount you can spend
                  // after any potential pending transactions that haven't cleared yet, and is the more accurate number
                  accountType === 'Credit Card'
                    ? account.balances.current
                    : account.balances.available || account.balances.current
                )}
              </TextSpan>
            </div>
          )
        })}
      {flatManualAccounts.map((account) => {
        const accountType = account.type
        return (
          <div
            onClick={() => {
              setAddManualAccountPageOpen({
                name: account.name,
                type: account.type,
                account_id: account.account_id,
                amount: String(account.amount),
                _id: account._id,
                transactions: account.transactions,
              })
            }}
            key={account.account_id}
            className="d-flex my-1 w-100 align-items-center justify-content-between">
            <div className="d-flex my-1 align-items-center justify-content-between">
              <AccountMaskAndLogo accountId={account.account_id} orientation="horizontal" />
              <Icon
                className={`${getIconFromAccountType(accountType)} mx-3`}
                size="sm"
                color={getColorFromAccountType(accountType)}
              />
              <TextSpan
                size="xxs"
                color="texttransparent"
                style={{
                  textDecoration: 'underline',
                }}>
                {account.name}
              </TextSpan>
            </div>
            <TextSpan weight={600} color={getColorFromAccountType(accountType)} size="xs">
              {numberToCurrency(
                cleanCurrency(account.amount)
                // This check here is because for credit cards, available balance is your full credit limit
                // this is not a useful number, we want to show the current balance
                // however, for debit / cash accounts, available balance is the amount you can spend
                // after any potential pending transactions that haven't cleared yet, and is the more accurate number
              )}
            </TextSpan>
          </div>
        )
      })}
    </div>
  )
}

export const SavingsTotalPage: React.FC<SubPageProps> = () => {
  const { flatAccounts, flatManualAccounts, accountsData, liabilitiesData } = useFinancialData()

  const { connectingNewCard } = useData()

  const noAccounts = flatManualAccounts.length === 0 && flatAccounts.length === 0

  const loading =
    connectingNewCard || accountsData?.status === 'loading' || liabilitiesData.status === 'loading'

  return (
    <ContainerDiv className="justify-content-between align-items-center py-3">
      <ContainerDiv className="justify-content-start align-items-center">
        <div className="d-flex flex-row align-items-center">
          <TextSlim size="lg" weight={200} color="texttransparent">
            Accounts
          </TextSlim>
          <Icon
            className="fas fa-sync fa-spin ml-1"
            size="md"
            color="texttransparent"
            style={{
              opacity: loading ? 1 : 0,
            }}
          />
          {/* <img alt="" className="ml-2" style={{ maxHeight: '1.5rem' }} src={plaidLogo} /> */}
        </div>

        <TextSlim size="xs" className="px-4" color="textsupertransparent">
          To detect recurring purchases, give you daily spending signals, nudge you towards better
          habits, and track your progress, we need to link your live bank accounts for you.
        </TextSlim>

        {!flatAccounts.length && !flatManualAccounts.length ? <NewAccountCTA /> : null}
        <section className="w-100">
          {noAccounts ? (
            <div className="mt-5">{/* <CreditCardPageHeader /> */}</div>
          ) : (
            // HACK : Fixed Height
            <ScrollingHalf
              cutout={180}
              style={{
                boxShadow: 'inset hsl(243 19% 18% / .5) 0 0px 7px 5px',
                border: 'solid 1px #6362752e',
                opacity: loading ? 0.7 : 1,
                transition: 'opacity ease-in-out .3s',
              }}>
              <SavingsAccountBreakdown />
            </ScrollingHalf>
          )}
        </section>

        {flatAccounts.length || flatManualAccounts.length ? (
          <NewAccountCTA />
        ) : // <div
        //   onClick={() => {
        //     setAddCardPageOpen('')
        //   }}
        //   className="d-flex flex-column align-items-center justify-content-start px-3 py-0 my-2">
        //   <TextSpan className="my-0" color="text" weight={500} size="md">
        //     <Icon size="sm" color={'text'} className="fas fa-plus-circle mx-2 my-0" />
        //     link another
        //   </TextSpan>
        // </div>
        null}
      </ContainerDiv>

      <AccountOverviewWidget />

      <div className="d-flex justify-content-between align-items-center w-100 flex-column">
        <div className="d-flex justify-content-between align-items-center w-100">
          <BackButton />
          <ForwardButton
            disabled={noAccounts}
            title={'Next'}
            disabledPopupTitle={'Accounts Needed'}
            disabledPopupBody="Splurv's goal is to make money easier for you. We rely on connected bank accounts to do this."
          />
        </div>
      </div>
    </ContainerDiv>
  )
}
