import { useAuth0 } from '@auth0/auth0-react'
import { useIsAlex } from 'Components/AlexOnlyContent/AlexOnlyContent'
import { TextSpan } from 'Components/DesignSystem/Typography'
import React from 'react'
// @ts-ignore
import { myTheme } from 'theme'

interface Props {
  userDetailMode: 'name' | 'sub' | 'email'
}
const BadgeContent: React.FC<Props> = ({ userDetailMode }) => {
  const { user } = useAuth0()
  switch (userDetailMode) {
    case 'name':
      return user?.name
    case 'sub':
      return user?.sub
    case 'email':
      return user?.email
  }
}

export const UserBadge: React.FC = () => {
  const [userDetailMode, setUserDetailMode] = React.useState<'name' | 'sub' | 'email'>('name')

  const isAlex = useIsAlex()

  return (
    <TextSpan
      onClick={() => {
        if (!isAlex) return
        if (userDetailMode === 'name') {
          setUserDetailMode('sub')
        } else if (userDetailMode === 'sub') {
          setUserDetailMode('email')
        } else {
          setUserDetailMode('name')
        }
      }}
      size="xxs"
      className="p-2"
      weight={700}
      color="logoSecond"
      style={{
        background: myTheme.colors.logoPrimary,
        maxWidth: 'fit-content',
        borderRadius: '10px',
      }}>
      <BadgeContent userDetailMode={userDetailMode} />
    </TextSpan>
  )
}
