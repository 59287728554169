import color from 'color'
import styled from 'styled-components'

export const AdvancedTransactionRowDiv = styled.div<{ legend?: boolean }>`
  width: 100%;
  align-items: center;
  display: grid;
  /* border-bottom: ${(props) =>
    `solid 1px ${color(props.theme.colors.nearlyclear).toString()}`}; */
  margin: ${(props) => (props.legend ? '.5rem 0rem' : '0.5rem 0')};
  padding: ${(props) => (props.legend ? '1rem 0rem' : '0.5rem 0')};
  border-radius: ${(props) => (props.legend ? '8px' : '0')};
  background-color: ${(props) =>
    props.legend ? props.theme.colors.backgrounddark : 'transparent'};
  transition: background-color 0.5s ease-in-out;
  grid-template-columns: minmax(0, 0.3fr) minmax(0, 1.5fr) minmax(0, 0.45fr) minmax(0, 0.4fr);
  * {
    display: flex;
    justify-content: flex-start;
    padding: 0 0.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: flex-start;
    display: inline-block;
    display: flex;
    text-align: left;
  }
  *:first-child {
    justify-content: flex-start;
  }
  *:last-child {
    text-align: end;
  }
`
