import React from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useAsyncLocalStorageState } from 'Hooks/useAsyncLocalStorageState'
import { useUserConfig } from 'Providers/UserConfigProvider/UserConfigProvider'
import { useUserCustomSettings } from 'Providers/UserCustomSettingsProvider/UserCustomSettingsProvider'
import { differenceInDays } from 'date-fns'

export interface Subscription {
  userid: string

  // the Stripe generated customer id
  customerId: string

  // the Stripe generated subscription id
  subscriptionId: string

  // the email the customer entered into Stripe
  customerEmail: string

  // 'active' || 'inactive'
  status: 'active' | 'inactive'

  // unix time stamp
  periodEnd: number

  // unix time stamp
  periodStart: number

  // 'standard-monthly' | 'standard-annual'
  lookupKey: string

  // unix time stamp
  userCreatedDate: number

  // the Stripe price (in dollars) of the subscription
  price?: number

  // the Stripe interval (month, week, year) of the subscription
  interval?: string
}

interface SubscriptionStatusContextState {
  status: 'active' | 'inactive'
  daysOnApp: number
  trialDaysLeft: number
  getStatus: () => Promise<void>
  deleteSubscription: (params: { subscriptionId: string }) => Promise<void>
  subscription: Subscription | null
}

const defaultData: SubscriptionStatusContextState = {
  status: 'inactive',
  trialDaysLeft: 0,
  daysOnApp: 0,
  subscription: null,
  getStatus: () => Promise.resolve(),
  deleteSubscription: () => Promise.resolve(),
}

const SubscriptionStatusContext = React.createContext<SubscriptionStatusContextState>(defaultData)

export const useSubscriptionStatus = () => React.useContext(SubscriptionStatusContext)

export const SubscriptionStatusProvider: React.FC = ({ children }) => {
  const { hasSeenAppBefore } = useUserConfig()
  const { settings } = useUserCustomSettings()
  const { user } = useAuth0()

  const {
    issueRequest: getSubscriptionStatus,
    data: subscriptionStatus,
  } = useAsyncLocalStorageState<{ subscription: Subscription }, {}, {}>({
    method: 'GET',
    route: 'subscription',
  })

  const { issueRequest: deleteSubscription } = useAsyncLocalStorageState<
    any,
    { subscriptionId: string },
    {}
  >({
    method: 'DELETE',
    route: 'subscription',
  })

  React.useEffect(() => {
    if (hasSeenAppBefore && user && settings?.userid) {
      getSubscriptionStatus({})
    }
  }, [getSubscriptionStatus, hasSeenAppBefore, user, settings])

  console.log({ data: subscriptionStatus })
  const userStartDate = subscriptionStatus?.subscription.userCreatedDate
    ? new Date(subscriptionStatus.subscription.userCreatedDate)
    : new Date()
  // const userStartDate = new Date(subscriptionStatus?.subscription?.userCreatedDate || '')
  const today = new Date()

  const daysOnApp = differenceInDays(today, userStartDate)
  const trialDaysLeft = 30 - differenceInDays(today, userStartDate)

  return (
    <SubscriptionStatusContext.Provider
      value={{
        deleteSubscription: (params) => deleteSubscription({ queryParamsConfig: params }),
        getStatus: () => getSubscriptionStatus({}),
        status: subscriptionStatus?.subscription?.status || 'inactive',
        daysOnApp,
        trialDaysLeft,
        subscription: subscriptionStatus?.subscription || null,
        // trialDaysLeft: 100,
      }}>
      {children}
    </SubscriptionStatusContext.Provider>
  )
}
