import { CollapseCardControlled } from 'Components/CollapseCard/CollapseCardControlled'
import { useMysteryModeFinalDate } from 'Components/DesignSystem/Layout/Header/hooks'
import { dataParameterType } from 'Hooks/useLocalStorageSyncedState'
import { CashflowRecapContent } from 'Pages/MysteryPage/CashFlowRecap/CashFlowRecapContent'
import { useFinancialData } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import { add } from 'date-fns'
import { LabelText } from 'design-system/typography/Text/Text'
import React from 'react'
import { StyledVr } from '../../Components/DesignSystem/Typography'
import { useMysteryDay } from '../../Providers/MysteryDayProvider/MysteryDayProvider'
import { cleanCurrency, numberToCurrency } from '../../Utilities/currencyFormater'
import {
  humanReadifyStringDate,
  yearsMonthstoString,
} from '../../Utilities/dateUtilities/dateUtilities'

const DreamDayTitle = ({
  currentSavings,
  amountLeft,
  months,
  years,
}: {
  title: string
  amount: string
  currentSavings: number
  amountLeft: number
  date: string | null
  months: number
  years: number
}) => (
  <div className="d-flex align-items-center justify-content-between w-100">
    <StyledVr color={'cashgreen'} size="xl" className="mr-2" weight={1} style={{ opacity: 0.5 }} />
    <div className="d-flex flex-column align-items-center">
      <LabelText color="cashgreen">{numberToCurrency(currentSavings)}</LabelText>
      <LabelText color="cashgreen">saved</LabelText>
    </div>
    <StyledVr color={'megapurple'} size="xl" className="mx-2" weight={1} style={{ opacity: 0.5 }} />
    <div className="d-flex flex-column align-items-center">
      <LabelText color="megapurple">{numberToCurrency(amountLeft)}</LabelText>
      <LabelText color="megapurple">remaining</LabelText>
    </div>

    <StyledVr color={'megapurple'} size="xl" className="mx-2" weight={1} style={{ opacity: 0.5 }} />
    <div className="d-flex flex-column align-items-start flex-grow">
      <LabelText color="megapurple">{yearsMonthstoString({ years, months })}</LabelText>
      <LabelText color="megapurple">left</LabelText>
    </div>
  </div>
)

interface Props {
  setCashFlowRecapOpen: (v: dataParameterType<boolean>) => void
  cashFlowRecapOpen: boolean
}
export const DreamDaySummary: React.FC<Props> = ({ setCashFlowRecapOpen, cashFlowRecapOpen }) => {
  const { itemCost, itemName, mysteryMode } = useMysteryDay()

  let { yearsLeft, monthsLeft } = useMysteryModeFinalDate()
  const { currentSavings } = useFinancialData()

  const wontBePaidOff = monthsLeft === Infinity

  const sunnyDay = wontBePaidOff ? null : add(new Date(), { months: monthsLeft, years: yearsLeft })
  const humanReadableSunnyDay = wontBePaidOff ? null : humanReadifyStringDate(sunnyDay)

  return (
    <CollapseCardControlled
      isCollapsed={!cashFlowRecapOpen}
      setIsCollapsed={setCashFlowRecapOpen}
      body={<CashflowRecapContent mysteryMode={mysteryMode} />}
      header={
        <div className="d-flex flex-column align-items-start justify-content-between w-100">
          <div className="d-flex align-items-center justify-content-between flex-column pr-2 w-100">
            <div className="d-flex align-items-center justify-content-start flex-row pt-2 w-100">
              <div className="d-flex align-items-start justify-content-between flex-column w-100">
                <DreamDayTitle
                  date={humanReadableSunnyDay}
                  title={itemName}
                  years={yearsLeft}
                  months={monthsLeft}
                  amount={numberToCurrency(cleanCurrency(itemCost))}
                  amountLeft={cleanCurrency(itemCost) - currentSavings}
                  currentSavings={currentSavings}
                />
              </div>
            </div>
          </div>
        </div>
      }
    />
  )
}
