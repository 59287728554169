import { useUIState } from 'Providers/UIStateProvider'
import color from 'color'
import React from 'react'
import { ColorType, colorPalletes } from 'theme'
import { Icon, TextSlimTight } from '../Typography'

interface Props {
  bgColor: ColorType
  iconClassName?: string
  pillClassName?: string
  text: string
  onClick?: (e: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => void
}
export const Pill: React.FC<Props> = ({ bgColor, text, iconClassName, pillClassName, onClick }) => {
  const { activeTheme } = useUIState()
  return (
    <TextSlimTight
      onClick={onClick ? onClick : () => {}}
      className={pillClassName}
      size={onClick ? 'xxs' : 'xxxs'}
      style={{
        textAlign: 'left',
        justifyContent: 'flex-start',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        lineHeight: onClick ? '.75rem' : '.5rem',
        margin: '.25rem 0',
        padding: onClick ? '.5rem .35rem' : '.25rem .35rem',
        borderRadius: '4px',
        textTransform: 'uppercase',
        maxWidth: '50vw',
        color: color(colorPalletes[activeTheme][bgColor]).darken(0.7).toString(),
        backgroundColor: color(colorPalletes[activeTheme][bgColor]).toString(),
      }}
      weight={700}>
      {iconClassName && (
        <Icon
          size="xxxs"
          style={{
            color: color(colorPalletes[activeTheme][bgColor]).darken(0.6).toString(),
          }}
          className={`${iconClassName} ml-0 pl-0 mr-0 my-0`}
        />
      )}

      {text}
    </TextSlimTight>
  )
}
