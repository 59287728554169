import color from 'color'
import styled from 'styled-components'
import { ColorType } from 'theme'

interface MarkerProps {
  width: number
  offsetLeft?: number
  color: ColorType
  content: string
  shouldRoundBorders?: boolean
}
export const FixedExpensesMarkerOverlay = styled.div<MarkerProps>`
  position: absolute;

  left: 0;
  width: ${(props) => `${props.width}px`};
  height: 10px;
  pointer-events: none;

  border-radius: 5px;
  border-bottom-right-radius: ${(props) => (props.shouldRoundBorders ? '5px' : '0px')};
  border-top-right-radius: ${(props) => (props.shouldRoundBorders ? '5px' : '0px')};

  background: ${(props) => color(props.theme.colors[props.color]).alpha(0.3).toString()};
`

export const FlexibleSpendMarkerOverlay = styled.div<MarkerProps>`
  position: absolute;

  left: ${(props) => (props.offsetLeft ? `${props.offsetLeft}px` : '0px')};
  width: ${(props) => `${props.width}px`};

  height: 10px;
  pointer-events: none;

  border-radius: 0px;

  background: ${(props) => color(props.theme.colors[props.color]).alpha(0.3).toString()};
`

export const FixedExpensesMarkerTick = styled.div<MarkerProps>`
  position: absolute;
  display: flex;
  align-items: center;

  left: ${(props) => `${props.width}px`};
  width: 2px;
  height: 20px;
  pointer-events: none;

  background: ${(props) => props.theme.colors[props.color]};

  ::before {
    position: absolute;
    font-size: ${(props) => props.theme.fonts.sizes.xxxs};
    font-weight: ${(props) => props.theme.fonts.weights[800]};
    font-family: ${(props) => props.theme.fonts.family.secondary};

    content: ${(props) => `'${props.content}'`};
    color: ${(props) => props.theme.colors.mehred};
    top: -18px;
    transform: translateX(-50%);
  }

  ::after {
    position: absolute;
    font-size: ${(props) => props.theme.fonts.sizes.xxs};
    font-weight: ${(props) => props.theme.fonts.weights[800]};
    font-family: ${(props) => props.theme.fonts.family.secondary};

    content: 'Fixed';
    color: ${(props) => props.theme.colors.mehred};
    bottom: -18px;
    transform: translateX(-50%);
  }
`

export const FlexibleSpendMarkerTick = styled.div<MarkerProps>`
  position: absolute;
  display: flex;
  align-items: center;

  left: ${(props) => `${props.width}px`};
  width: 2px;
  height: 20px;
  pointer-events: none;

  background: ${(props) => props.theme.colors[props.color]};

  ::before {
    position: absolute;
    font-size: ${(props) => props.theme.fonts.sizes.xxxs};
    font-weight: ${(props) => props.theme.fonts.weights[800]};
    font-family: ${(props) => props.theme.fonts.family.secondary};

    content: ${(props) => `'${props.content}'`};
    color: ${(props) => props.theme.colors.orange};
    top: -30px;
    transform: translateX(-50%);
  }
  ::after {
    position: absolute;
    font-size: ${(props) => props.theme.fonts.sizes.xxs};
    font-weight: ${(props) => props.theme.fonts.weights[800]};
    font-family: ${(props) => props.theme.fonts.family.secondary};

    content: 'Variable';
    color: ${(props) => props.theme.colors.orange};
    bottom: -30px;
    pointer-events: all;
    transform: translateX(-50%);
  }
`
