import { TextSpan } from 'Components/DesignSystem/Typography'
import { SettingsItem } from 'Pages/SettingsPage/components'
import { useUIState } from 'Providers/UIStateProvider'
import React from 'react'

export const PlaidEnvToggle: React.FC = () => {
  const { userDrivenPlaidEnv, setUserDrivenPlaidEnv } = useUIState()
  return (
    <SettingsItem
      onClick={() => {
        if (userDrivenPlaidEnv === 'development') {
          setUserDrivenPlaidEnv('production')
          return
        }
        if (userDrivenPlaidEnv === 'production') {
          setUserDrivenPlaidEnv('sandbox')
        }
        if (userDrivenPlaidEnv === 'sandbox') {
          setUserDrivenPlaidEnv('development')
        }
      }}>
      <TextSpan
        color={
          userDrivenPlaidEnv === 'production'
            ? 'mehred'
            : userDrivenPlaidEnv === 'sandbox'
            ? 'orange'
            : 'softblue'
        }>
        Plaid Env: {userDrivenPlaidEnv}
      </TextSpan>
    </SettingsItem>
  )
}
