import { CurrencyCounter } from 'Components/AnimatedCounters/CurrencyCounter'
import { ContainerDiv } from 'Components/DesignSystem/Layout/Containers/Containers'
import { useMysteryModeFinalDate } from 'Components/DesignSystem/Layout/Header/hooks'
import { StyledVr, TextSpan } from 'Components/DesignSystem/Typography'
import { MonthlyExpenseSlider } from 'Pages/MysteryPage/MonthlyExpenseSlider/MonthlyExpenseSlider'
import { useData } from 'Providers/APIDataProvider'
import { getMonthlyIncome } from 'Utilities/netWorth/netWorth'
import { CaptionText, ItemText, SubtitleText } from 'design-system/typography/Text/Text'
import React from 'react'
import { useGetDashboardValue } from '../hooks'
import { RainyDayStatusIndicator } from './RainyDayStatusIndicator/RainyDayStatusIndicator'

// const RainyDayOverview: React.FC = () => {
//   const { setModalContent } = useUIState()
//   return (
//     <Card className={'px-4 py-2'}>
//       <div className="d-flex flex-row">
//         <TextBold>Rainy Day Overview</TextBold>
//         <Icon
//           onClick={() => {
//             setModalContent(null)
//           }}
//           style={{
//             position: 'absolute',
//             right: '1rem',
//           }}
//           size="xl"
//           color="textsupertransparent"
//           className="fas fa-times"
//         />
//       </div>
//       <BodyText>
//         Welcome to the most advanced dashboard that Splurv has to offer. The dashboard is broken
//         down into a few pieces. In the top half, you will see the status of your 6 month rainy day
//         fund model by the lights. They represent how many months of living expenses you currently
//         have saved up
//       </BodyText>
//     </Card>
//   )
// }

interface Props {}
export const RainyDayFundDashboard: React.FC<Props> = () => {
  // const { setModalContent } = useUIState()
  // const { hasSeenRainyDayOverview, setHasSeenRainyDayOverview } = useUserConfig()

  const { monthlyBurnRate, streams } = useData()
  const { currentSavings } = useGetDashboardValue()
  const { finalDateToShow } = useMysteryModeFinalDate()

  const monthlyIncome = getMonthlyIncome(streams)

  const baseRecommendedAmount = monthlyIncome - Math.abs(monthlyBurnRate)

  const monthsSavedUp = Math.floor(currentSavings / monthlyBurnRate)

  // const savingsLeftForLastMonth = currentSavings - monthsSavedUp * monthlyBurnRate
  // const percentageOfLastMonthSavedFor = savingsLeftForLastMonth / monthlyBurnRate
  // const daysLeft = Math.floor(DAYS_PER_MONTH * percentageOfLastMonthSavedFor)

  // useEffect(() => {
  //   if (!hasSeenRainyDayOverview) {
  //     setModalContent(<RainyDayOverview />)
  //     setHasSeenRainyDayOverview(true)
  //   }
  // }, [hasSeenRainyDayOverview, setHasSeenRainyDayOverview])
  return (
    <ContainerDiv className="align-items-center">
      <div className="d-flex flex-column align-items-center flex-row w-100 px-2">
        <SubtitleText>Rainy Day Fund </SubtitleText>
        <div className="d-flex flex-row w-100 align-items-center justify-content-around">
          <div className="d-flex flex-column">
            <TextSpan size="xxs" color="cashgreen" className="mx-0 my-0">
              Current Savings:
            </TextSpan>
            <ItemText>
              <TextSpan size="md" color="cashgreen" className="ml-1">
                <CurrencyCounter value={currentSavings} />
              </TextSpan>
            </ItemText>
          </div>
          <StyledVr className="mx-2" size="xl" weight={1} />
          <div className="d-flex flex-column">
            <TextSpan
              size="xxs"
              color={monthsSavedUp >= 6 ? 'cashgreen' : 'softblue'}
              className="mx-0 my-0">
              Months of Living Expenses Covered:
            </TextSpan>
            <ItemText className="mr-1">
              <TextSpan
                size="md"
                className=""
                color={monthsSavedUp >= 6 ? 'cashgreen' : 'softblue'}>
                {monthsSavedUp <= 6 ? monthsSavedUp : 6}
                {monthsSavedUp > 6 ? '+' : ''} month
                {monthsSavedUp === 0 || monthsSavedUp > 1 ? 's' : ''}
              </TextSpan>
            </ItemText>
            {/* <div
              className="d-flex flex-row justify-content-start flex-grow"
              style={{
                flex: 1,
              }}>
              <div className="d-flex flex-column align-items-start justify-content-start">
                <ItemText className="mr-1">
                  <TextSpan
                    size="xs"
                    className="my-0"
                    color={monthsSavedUp >= 6 ? 'cashgreen' : 'softblue'}>
                    {monthsSavedUp <= 6 ? monthsSavedUp : 6}
                    {monthsSavedUp > 6 ? '+' : ''} month
                    {monthsSavedUp === 0 || monthsSavedUp > 1 ? 's' : ''}
                  </TextSpan>
                </ItemText>
              </div>
              <TextSpan
                size="xxs"
                className="ml-0 my-0"
                color={monthsSavedUp >= 6 ? 'cashgreen' : 'softblue'}>
                worth of living expenses
              </TextSpan>
            </div> */}
          </div>
        </div>

        <RainyDayStatusIndicator mcol={monthlyBurnRate} savings={currentSavings} />

        <div className="d-flex flex-row w-100 justify-content-between">
          <div className="d-flex flex-column align-items-center">
            <CaptionText>Available to Save / month</CaptionText>
            <ItemText>
              <TextSpan color="cashgreen" weight={500} className="ml-1">
                <CurrencyCounter value={baseRecommendedAmount} />
              </TextSpan>
            </ItemText>
            <CaptionText>Rainy Day Fund Full On</CaptionText>
            <CaptionText>
              <TextSpan color="cashgreen">{finalDateToShow}</TextSpan>
            </CaptionText>
          </div>
          <StyledVr weight={1} className="mx-2" />
          <div className="d-flex flex-column align-items-center">
            <CaptionText>Monthly Cost of Living</CaptionText>
            <ItemText>
              <TextSpan color="softblue" weight={500} className="ml-1">
                <CurrencyCounter value={monthlyBurnRate} />
              </TextSpan>
            </ItemText>
            <CaptionText>Total Rainy Day Fund Needed</CaptionText>
            <ItemText>
              <TextSpan color="softblue" weight={500} className="ml-1">
                <CurrencyCounter value={monthlyBurnRate * 6} />
              </TextSpan>
            </ItemText>
          </div>
        </div>
        <MonthlyExpenseSlider />
      </div>
    </ContainerDiv>
  )
}
