import styled from 'styled-components/macro'
import { LinkType } from '../../../../Utilities/navigationTabUtilities'
import {
  getGridTemplateAreas,
  getGridTemplateRows,
  getPaddingForContent,
  TOP_CONTAINER_PADDING_FOR_FOOTER,
  TOP_CONTAINER_PADDING_FOR_NOTCH,
  TOTAL_VERTICAL_SPACE_OUTSIDE_CONTENT,
} from './helpers'

export const MainIntro = styled.main`
  position: absolute;
  top: 0;
  left: 0;

  height: 100vh;
  width: 100vw;
  min-height: 100vh;
  max-height: 100vh;

  padding-top: ${() => `${TOP_CONTAINER_PADDING_FOR_NOTCH}px`};
  /* padding-bottom: ${() => `${TOP_CONTAINER_PADDING_FOR_FOOTER}px`}; */

  /* background: ${(props) => props.theme.colors.text}; */
  background: linear-gradient(180deg, #b6d8e3, #c8fadc);
  transition: background 0.5s ease-in-out;
  color: ${(props) => props.theme.colors.text};

  display: flex;
  flex-direction: column;
`

export const Main = styled.main`
  position: absolute;
  top: 0;
  left: 0;

  height: 100vh;
  width: 100vw;
  min-height: 100vh;
  max-height: 100vh;

  /* NOTE: This is the actual top notch padding setting 
   * because this Main container is position absolute, the padding top on the body (in index.css)
   * doesnt do anything
    */
  padding-top: ${() => `${TOP_CONTAINER_PADDING_FOR_NOTCH}px`};

  background: ${(props) => props.theme.colors.background};
  transition: background 0.5s ease-in-out;
  color: ${(props) => props.theme.colors.text};

  display: grid;
  grid-template-rows: ${() => getGridTemplateRows()};
  grid-template-areas: ${() => getGridTemplateAreas()};
`

export const Content = styled.section<{ activeLink: LinkType }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 auto;
  width: 100%;
  overflow-y: hidden;

  grid-area: content;

  padding: ${(props) => getPaddingForContent(props.activeLink)};

  color: ${(props) => props.theme.colors.text};
`

interface Props {
  cutout?: number
}
/**
 * A lot of this component does is take up the full space on the device as a fixed min/max height so that:
 * - it sits between the header and footer of the app based on their size
 * - allows for an optional cutout to be passed in so that it can exist on a page that has other content but still allows it to scroll
 * ex: the StreamPage also has a fixed, StreamPage-specific footer that we want glued to the bottom of the page and have the container above it take up the full remainder
 */
export const ScrollingFull = styled.section<Props>`
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  max-height: ${(props) =>
    `calc(100vh - (${TOTAL_VERTICAL_SPACE_OUTSIDE_CONTENT}px) - ${props?.cutout || 0}px)`};
  min-height: ${(props) =>
    `calc(100vh - (${TOTAL_VERTICAL_SPACE_OUTSIDE_CONTENT}px) - ${props?.cutout || 0}px)`};
  height: ${(props) =>
    `calc(100vh - (${TOTAL_VERTICAL_SPACE_OUTSIDE_CONTENT}px) - ${props?.cutout || 0}px)`};
  width: 100%;
  display: flex;
  flex-direction: column;
`

/**
 * A lot of this component does is take up the full space on the device as a fixed min/max height so that:
 * - it sits between the header and footer of the app based on their size
 * - allows for an optional cutout to be passed in so that it can exist on a page that has other content but still allows it to scroll
 * ex: the StreamPage also has a fixed, StreamPage-specific footer that we want glued to the bottom of the page and have the container above it take up the full remainder
 */
export const ScrollingFullFluid = styled.section<Props>`
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  max-height: ${(props) =>
    `calc(100vh - (${TOTAL_VERTICAL_SPACE_OUTSIDE_CONTENT}px) - ${props?.cutout || 0}px)`};
  /* min-height: ${(props) =>
    `calc(100vh - (${TOTAL_VERTICAL_SPACE_OUTSIDE_CONTENT}px) - ${props?.cutout || 0}px)`}; */
  /* height: ${(props) =>
    `calc(100vh - (${TOTAL_VERTICAL_SPACE_OUTSIDE_CONTENT}px) - ${props?.cutout || 0}px)`}; */
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const ScrollingHalf = styled.section<Props>`
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  max-height: ${(props) =>
    `calc((100vh - (${TOTAL_VERTICAL_SPACE_OUTSIDE_CONTENT}px))/2 - ${props?.cutout || 0}px)`};
  min-height: ${(props) =>
    `calc((100vh - (${TOTAL_VERTICAL_SPACE_OUTSIDE_CONTENT}px))/2 - ${props?.cutout || 0}px)`};
  height: ${(props) =>
    `calc((100vh - (${TOTAL_VERTICAL_SPACE_OUTSIDE_CONTENT}px))/2 - ${props?.cutout || 0}px)`};
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const ScrollingDemoFull = styled.div<Props>`
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: ${(props) =>
    `calc(100vh - (${TOP_CONTAINER_PADDING_FOR_FOOTER + TOP_CONTAINER_PADDING_FOR_NOTCH}px) - ${
      props?.cutout || 0
    }px)`};
  min-height: ${(props) =>
    `calc(100vh - (${TOP_CONTAINER_PADDING_FOR_FOOTER + TOP_CONTAINER_PADDING_FOR_NOTCH}px) - ${
      props?.cutout || 0
    }px)`};
  height: ${(props) =>
    `calc(100vh - (${TOP_CONTAINER_PADDING_FOR_FOOTER + TOP_CONTAINER_PADDING_FOR_NOTCH}px) - ${
      props?.cutout || 0
    }px)`};
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const ContainerDiv = styled.div`
  height: 100%;
  width: 100%;
  color: ${(props) => props.theme.colors.text};
  padding: 0rem;
  display: flex;
  flex-direction: column;
`

export const ContainerCutout = styled.div<{ size?: number }>`
  width: 100%;
  min-height: ${(props) => (props.size ? `${props.size}px` : '5rem')};
  max-height: ${(props) => (props.size ? `${props.size}px` : '5rem')};
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
`
