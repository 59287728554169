import { WideButton } from 'design-system/buttons/WideButton/WideButton'
import React from 'react'
import { ContainerDiv } from '../../Components/DesignSystem/Layout/Containers/Containers'
import { TextBoldFancy, TextSlim } from '../../Components/DesignSystem/Typography'
import { useMysteryDay } from '../../Providers/MysteryDayProvider/MysteryDayProvider'
import { labelFromMysteryDayType } from '../../Utilities/utils'
import { SubPageProps } from './MysteryPage'

export const NotImplementedPage: React.FC<SubPageProps> = () => {
  const { setMysteryMode, setPage, mysteryMode } = useMysteryDay()

  return (
    <ContainerDiv className="justify-content-between align-items-center py-3">
      <TextSlim>
        Support for <b>{labelFromMysteryDayType(mysteryMode)}</b> is coming soon!
      </TextSlim>
      <TextSlim>In the meantime, feel free to still use the app and make it work for you!</TextSlim>

      <WideButton
        active={true}
        appearance="primary"
        bgColor="text"
        text=""
        textSize="small"
        width="fit"
        onClick={() => {
          setMysteryMode('default')
          setPage('mode-selection')
        }}>
        <TextBoldFancy size="xl" color="logoPrimary">
          Back
        </TextBoldFancy>
      </WideButton>
    </ContainerDiv>
  )
}
