import { useAuth0 } from '@auth0/auth0-react'
import { useAsyncLocalStorageState } from 'Hooks/useAsyncLocalStorageState'
import { useCategoryBudgetHelpers } from 'Pages/VariablePage/CategoryBudgetTable/helpers'
import { PLAID_ENV_TYPE } from 'api/plaid'
import React, { useCallback, useEffect, useState } from 'react'
import { SPLURV_LOCAL_STORAGE_PREFIX } from '../Hooks/useLocalStorageSync'
import { MoneyEvent, MoneyStream } from '../Utilities/interfaces'
import { getMonthlyBills, getMonthlySubscriptions } from '../Utilities/netWorth/netWorth'
import { useMysteryDay } from './MysteryDayProvider/MysteryDayProvider'
import { useUIState } from './UIStateProvider'
// import { testStreams } from '../utilities/testUtils'

// import sampleLiabilities from '../utilities/creditCardSpending/StubLiablitiesSlim.json'

export const DAYS_OUT = 1826

interface TopLevelState {
  /* API All the streams for a user */
  streams: MoneyStream[]

  /* DERIVED: the Income Stream with the name 'Paycheck' put on TopLevel state to prevent from finding it every time*/
  paycheckStream: MoneyStream | null

  /* DERIVED All the events (derived from streams) for a user */

  /* API The async request to GET /moneystreams that then populates state */
  getStreams: () => Promise<any>

  /* API The async request to POST /moneystreams that then populates state */
  saveStream: (stream: MoneyStream) => Promise<any>

  /* API The async request to DELETE /moneystreams that then populates state */
  deleteStream: (id: string) => Promise<void>

  /* API Is the UI currently fetching GET /moneystreams */
  fetchingStreams: boolean

  /* API Is the UI currently saving (POST) a stream*/
  savingStream: boolean

  /* API Is the UI currently deleting (DELETE) a stream*/
  deletingStream: boolean

  /* API Was there an error fetching streams */
  fetchingStreamsError: string | null

  connectNewCreditCard: (public_token: string, plaidEnv: PLAID_ENV_TYPE) => Promise<void>
  connectingNewCard: boolean
  connectNewCardError: string
  setConnectingNewCard: (connecting: boolean) => void

  monthlyBurnRate: number
}

const dd: TopLevelState = {
  paycheckStream: null,
  streams: [],
  // liabilities: sampleLiabilities.liabilities,

  fetchingStreams: false,
  savingStream: false,
  deletingStream: false,

  fetchingStreamsError: '',

  getStreams: () => Promise.resolve(),
  saveStream: (stream: MoneyStream) => Promise.resolve(),
  deleteStream: (id: string) => Promise.resolve(),

  connectNewCreditCard: (public_token: string) => Promise.resolve(),
  setConnectingNewCard: (connecting: boolean) => {},
  connectingNewCard: false,
  connectNewCardError: '',

  monthlyBurnRate: 0,
}

interface Props {
  children: React.ReactNode
}

export interface EventMapInterface {
  [index: string]: MoneyEvent[]
}

export const deleteAllLocalData = () => {
  for (let _x in localStorage) {
    if (!localStorage.hasOwnProperty(_x)) {
      continue
    }
    if (_x.includes(SPLURV_LOCAL_STORAGE_PREFIX)) {
      localStorage.removeItem(_x)
    }
  }

  window.location.reload()
}

// HELPERS
const DataContext = React.createContext<TopLevelState>(dd)

export const useData = () => React.useContext(DataContext)

/**
 * The first and original data provider in Splurv
 * This currently only manages getting streams, saving streams, connecting new credit cards, and a derived cash flow state
 * that is the monthly burn rate (which also takes into consideration flexible spend per month and MCOL adjustment from mystery provider)
 *
 * Part of the reason this Provider is so messy and verbose is that other providers that have API data
 * (like FinancialDataProvider) use our newer useAsyncFetch() hook that abstracts a lot of the user token and local storage cache work
 * @returns
 */
export const DataProvider = ({ children }: Props) => {
  /* GLOBAL PROVIDERS FROM ABOVE */
  const { setErrorPopupContent } = useUIState()

  const { getTotalOfTargetCategories } = useCategoryBudgetHelpers()

  const variableSpendTotalLimit = getTotalOfTargetCategories()

  const { livingCostPercentageAdjustment } = useMysteryDay()
  const { getAccessTokenSilently, user, isAuthenticated, loginWithRedirect } = useAuth0()

  /* LOCAL STATE */

  const [connectingNewCard, setConnectingNewCard] = useState<boolean>(dd.connectingNewCard) // 23
  const [connectNewCardError, setConnectNewCardError] = useState<string>(dd.connectNewCardError) // 24

  const connectNewCreditCard = useCallback(
    async (public_token: string, plaidEnv: PLAID_ENV_TYPE) => {
      setConnectingNewCard(true)
      try {
        let token
        try {
          token = await getAccessTokenSilently()
        } catch (err: any) {
          if (err.error === 'login_required') {
            loginWithRedirect({
              redirectUri: `${window.location.origin}${window.location.pathname}`,
            })
            return
          }
          if (err.error === 'consent_required') {
            loginWithRedirect({
              redirectUri: `${window.location.origin}${window.location.pathname}`,
            })
            return
          }
          throw err
        }
        const userid = user.sub

        // NOTE: *** PLAID CONNECTION POINT *** - exchange the public_token from Plaid Link for an access_token
        const getAccessTokenResponse = await fetch(
          `${
            process.env[`REACT_APP_${process.env.REACT_APP_APP_ENV}_API_URL`]
          }/get_access_token?userid=${userid}&userDrivenPlaidEnvironment=${plaidEnv}`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ public_token }),

            mode: 'cors',
            credentials: 'include',
          }
        )

        const getAccessTokenJson = await getAccessTokenResponse.json()

        if (getAccessTokenResponse.status !== 200) {
          console.error('ERROR!!!! ConnectingCreditCard')
          console.error(getAccessTokenJson)
          setConnectNewCardError(getAccessTokenResponse.statusText)
          setConnectingNewCard(false)
          setErrorPopupContent(
            `yikes, that's an error<>connect new credit card : ${getAccessTokenResponse.status} ${getAccessTokenResponse.statusText}`
          )
          return
        }
        if (getAccessTokenJson.error) {
          const err = getAccessTokenJson.error
          console.error('ERROR!!!! ConnectingCreditCard')
          console.error(err)
          setConnectingNewCard(false)
          setConnectNewCardError(getAccessTokenJson.error)
          setErrorPopupContent(
            `yikes, that's an error<>connect new credit card : ${getAccessTokenJson.error}`
          )
          return
        }

        setConnectingNewCard(false)
      } catch (err) {
        console.error('ERROR!!!! ConnectingCreditCard')
        console.error(err)
        setErrorPopupContent(`yikes, that's an error<>connect new credit card : ${err}`)
      }
    },
    [user, getAccessTokenSilently, setErrorPopupContent, loginWithRedirect]
  )

  const {
    // data: deleteStreamData,
    issueRequest: deleteStream,
    loading: deleteStreamLoading,
    // error: deleteStreamError,
  } = useAsyncLocalStorageState<{ streams: MoneyStream[] }, {}, { id: string }>({
    method: 'DELETE',
    route: 'moneystream',
  })

  const {
    // data: saveStreamData,
    issueRequest: saveStream,
    loading: saveStreamLoading,
    // error: saveStreamError,
  } = useAsyncLocalStorageState<{ streams: MoneyStream[] }, {}, { stream: MoneyStream }>({
    method: 'POST',
    route: 'moneystreams',
  })

  const {
    data: getStreamsData,
    issueRequest: getStreams,
    loading: getStreamsLoading,
    error: getStreamsError,
    idle: getStreamsIdle,
  } = useAsyncLocalStorageState<{ streams: MoneyStream[]; userInfo: any }, {}, {}>({
    method: 'GET',
    route: 'moneystreams',
  })

  useEffect(() => {
    if (isAuthenticated && user) {
      try {
        if (!getStreamsData?.streams?.length && !getStreamsLoading && getStreamsIdle) {
          getStreams({})
        }
      } catch (err) {
        console.error('ERROR')
        console.error(err)
      }
    }
  }, [
    user,
    isAuthenticated,
    getStreamsData?.streams,
    getStreams,
    getStreamsLoading,
    getStreamsIdle,
  ]) // 54

  // console.count('🎨 APIDataProvider')

  const streams = React.useMemo(() => getStreamsData?.streams || [], [getStreamsData?.streams])
  const value = React.useMemo(() => {
    return {
      connectNewCreditCard,
      setConnectingNewCard,
      connectingNewCard,
      connectNewCardError,
      deleteStream: async (id: string) => {
        await deleteStream({ bodyParamsConfig: { id } })
        await getStreams({})
      },
      deletingStream: getStreamsLoading || deleteStreamLoading,

      getStreams: () => getStreams({}),
      fetchingStreams: getStreamsLoading,
      streams,
      fetchingStreamsError: getStreamsError,

      // STREAMS -> DERIVED STATE
      paycheckStream:
        streams.filter((s) => s.type === 'income').find((s) => s.name === 'Paycheck') || null,

      saveStream: async (stream: MoneyStream) => {
        await saveStream({ bodyParamsConfig: { stream } })
        await getStreams({})
      },
      savingStream: saveStreamLoading || getStreamsLoading,

      monthlyBurnRate: Math.floor(
        Math.abs(
          Math.abs(getMonthlyBills(streams)) +
            Math.abs(getMonthlySubscriptions(streams)) +
            variableSpendTotalLimit
        ) * livingCostPercentageAdjustment
      ),
    }
  }, [
    streams,
    saveStreamLoading,
    getStreamsLoading,
    connectNewCreditCard,
    deleteStream,
    getStreams,
    saveStream,
    variableSpendTotalLimit,
    livingCostPercentageAdjustment,
    connectNewCardError,
    connectingNewCard,
    deleteStreamLoading,
    getStreamsError,
  ])

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>
}
