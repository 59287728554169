import { ReflectionCarousel } from 'Pages/SpendingPage/ReflectionReview/ReflectionCarousel/ReflectionCarousel'
import { CallbackAndValueState, useUIState } from 'Providers/UIStateProvider'
import { IconButton } from 'design-system/buttons/IconButton/IconButton'
import React from 'react'
import { ContainerCutout } from '../../Components/DesignSystem/Layout/Containers/Containers'
import { StyledHr, StyledVr } from '../../Components/DesignSystem/Typography'
import { useCategoryBudgetHelpers } from './CategoryBudgetTable/helpers'

const styledHrHeight = 9
const labelVerticalMargins = 16
export const variablePageHeaderSize = 60 + styledHrHeight + labelVerticalMargins

interface VariablePageHeaderProps {
  setAddCustomCategoryPageOpen: (open: false | CallbackAndValueState) => void
}
export const VariablePageHeader: React.FC<VariablePageHeaderProps> = ({
  setAddCustomCategoryPageOpen,
}) => {
  const { getUncategorizedData } = useCategoryBudgetHelpers()

  const { length } = getUncategorizedData()

  const { setMessagePopupContent } = useUIState()

  // React.useEffect(() => {
  //   if (length) {
  //     setModalContent(<ReflectionCarousel />)
  //   } else {
  //     setModalContent(null)
  //   }
  //   // eslint-disable-next-line
  // }, [length])

  const { setModalContent } = useUIState()
  return (
    <ContainerCutout size={variablePageHeaderSize}>
      <div className="d-flex align-items-center w-100 justify-content-between px-4">
        <IconButton
          disabled={!length}
          onDisabledClick={() =>
            setMessagePopupContent(
              'Reflections Completed<>You have no transactions to reflect on! To see your transactions, go to the Spending page and click on Transactions in the top'
            )
          }
          onClick={() => {
            setModalContent(<ReflectionCarousel />)
          }}
          title={`open ${length ? length : ''} reflections`}
          color={'logoPrimary'}
          orientation="vertical"
          iconClassName={'fas fa-rectangle-vertical-history'}
        />
        <StyledVr weight={1} size="xxl" />
        <IconButton
          onClick={() => setAddCustomCategoryPageOpen({ val: '', cb: () => {} })}
          title={'create envelope'}
          color={'yellow'}
          orientation="vertical"
          iconClassName={'fas fa-envelope-open-dollar'}
        />
      </div>

      <StyledHr weight={1} color="nearlyclear" className="my-1" />
    </ContainerCutout>
  )
}
