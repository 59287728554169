import { Icon, TextSlim, TextSpan } from 'Components/DesignSystem/Typography'
import { useData } from 'Providers/APIDataProvider'
import { useStreamModifyingState } from 'Providers/StreamModifyingProvider'
import { useUIState } from 'Providers/UIStateProvider'
import { numberToCurrency } from 'Utilities/currencyFormater'

import { FrequencyType, MoneyStream } from 'Utilities/interfaces'
import { nth } from 'Utilities/utils'
import React, { useEffect } from 'react'
import { colorPalletes } from 'theme'
import { ScrollingItemPickerDisplay } from './Helpers'

interface Props {
  fixedStreamName: string
  setFixedStreamName: React.Dispatch<React.SetStateAction<string>>

  transactionName: string
  transactionAmount: number
}
export const FixedStreamAssigner: React.FC<Props> = ({
  fixedStreamName,
  setFixedStreamName,
  transactionName,
  transactionAmount,
}) => {
  const { streams } = useData()
  const { activeTheme, setPopup, setModalContent } = useUIState()
  const { setStreamToEdit, setAddEditPageOpen } = useStreamModifyingState()

  const [containerHeight, setContainerHeight] = React.useState<number>(0)
  const ref = React.useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (ref.current) {
      const height = ref.current.clientHeight
      setContainerHeight(height)
    }
  }, [ref])

  const existingStreams = streams.filter((s) => s.enabled).map((s) => s.name)
  return (
    <>
      <div
        ref={ref}
        className="d-flex flex-column w-100 align-items-start justify-content-start position-relative h-100 ">
        <TextSpan color="textsupertransparent" size="sm" className="my-0" weight={200}>
          Assign Bill
          <TextSlim
            style={{
              backgroundColor: colorPalletes[activeTheme].mehred,
              borderRadius: '4px',
              whiteSpace: 'nowrap',
              opacity: fixedStreamName ? 1 : 0,
              maxWidth: '38vw',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
            className="my-1 ml-2 px-1 py-1 mx-1"
            color={'background'}
            size="xxxs"
            weight={700}>
            {fixedStreamName}
            <Icon className="fas fa-check-circle ml-1" color="background" style={{}} size="xxs" />
          </TextSlim>
        </TextSpan>

        <ScrollingItemPickerDisplay
          activeColor="mehred"
          containerHeight={containerHeight}
          title="Bill"
          onCreateNewItemClick={() => {
            setStreamToEdit(
              {
                amount: transactionAmount,
                name: transactionName,
                frequency: FrequencyType.monthly,
                day: 1,
                enabled: true,
                type: 'bill',
              } as MoneyStream,
              'add'
            )
            setAddEditPageOpen(true)
            setModalContent(null)
            setPopup(null)
          }}
          items={
            existingStreams?.map((streamName) => {
              const stream = streams.find((s) => s.name === fixedStreamName)
              const streamAmountForName = stream?.amount || 0
              const streamDateForName =
                stream?.frequency === FrequencyType.monthly ? stream.day : null
              return {
                activeColor: 'mehred',
                isActive: streamName === fixedStreamName,
                title: streamName,
                onClick: (streamName: string) => setFixedStreamName(streamName),
                metaData: (
                  <div className="d-flex align-items-center ml-0">
                    <TextSpan size="xxxs" color="textsupertransparent">
                      Predicted
                    </TextSpan>
                    <TextSpan size="xxxs" color="mehred" className="ml-1">
                      | {numberToCurrency(streamAmountForName)}
                    </TextSpan>
                    {streamDateForName ? (
                      <>
                        <TextSpan size="xxxs" color="textsupertransparent">
                          | {streamDateForName}
                          {nth(streamDateForName)} of month
                        </TextSpan>
                      </>
                    ) : null}
                  </div>
                ),
              }
            }) || []
          }
        />
      </div>
    </>
  )
}
