import { Icon, TextBold } from 'Components/DesignSystem/Typography'
import { useUIState } from 'Providers/UIStateProvider'
import React from 'react'

export const ThemeToggler: React.FC = () => {
  const { activeTheme, setActiveTheme } = useUIState()

  const toggleTheme = () =>
    activeTheme === 'newLight'
      ? setActiveTheme('newDark')
      : activeTheme === 'newDark'
      ? setActiveTheme('newLight')
      : null
  return (
    <div className="d-flex flex-row align-items-center" onClick={() => toggleTheme()}>
      <Icon className={`fas fa-${activeTheme === 'newDark' ? 'moon' : 'sun'}`} />
      <TextBold>{activeTheme === 'newDark' ? 'Dark Mode' : 'Light Mode'}</TextBold>
    </div>
  )
}
