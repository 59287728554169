import { useUIState } from 'Providers/UIStateProvider'
import color from 'color'
import React from 'react'
import { bankLogoForBank } from '../Pages/CreditCardPage/CardTetherSetupPage/CardTetherSetupPage'
import { getBankItemFromAccount } from '../Pages/CreditCardPage/dataHelpers'
import { generateBackgroundColor } from '../Pages/StreamPage/AddEditStream/BillResponsibilitySection/BillResponsibilitySection'
import { useFinancialData } from '../Providers/FinancialDataProvider/FinancialDataProvider'
import { FontSizeType, colorPalletes } from '../theme'
import { BankLogo } from './BankLogo'
import { TextSpan } from './DesignSystem/Typography'

interface AccountMaskAndLogoProps {
  accountId?: string
  /**
   * pixel size of logo
   */
  logoSize?: number
  /**
   * pixel size of logo
   */
  maskSize?: FontSizeType
  maskStyle?: React.CSSProperties
  orientation?: 'horizontal' | 'vertical'
}

interface AccountMaskAndLogoDisplayProps {
  orientation: 'horizontal' | 'vertial'
  bankLogoSrc: string
  bankLogoSize: number
  maskSize: FontSizeType
  mask: string
  institutionColor: string
}
export const AccountMaskAndLogoDisplay: React.FC<AccountMaskAndLogoDisplayProps> = ({
  orientation,
  bankLogoSrc,
  bankLogoSize = 24,
  maskSize = 'xs',
  institutionColor,
  mask,
}) => {
  const { activeTheme } = useUIState()
  const backgroundColor = institutionColor
    ? generateBackgroundColor(institutionColor, activeTheme === 'newDark' ? 'dark' : 'light')
    : generateBackgroundColor(
        colorPalletes[activeTheme].second,
        activeTheme === 'newDark' ? 'dark' : 'light'
      )

  return (
    <div
      className={`d-flex flex-${
        orientation === 'horizontal' ? 'row mr-2' : 'column'
      } align-items-center`}>
      <BankLogo
        logo={bankLogoSrc}
        institutionColor={institutionColor}
        size={bankLogoSize}
        institutionName={''}
      />
      <TextSpan
        size={maskSize}
        weight={700}
        className={`align-items-center justify-content-center ${
          orientation === 'horizontal' ? 'ml-1 my-0' : 'my-1'
        }`}
        color={'textsupertransparent'}
        style={{
          fontFamily: 'monospace',
          color: institutionColor ? institutionColor : 'inherit',
          backgroundColor,
          display: mask ? 'flex' : 'none !important',
          padding: '.125rem .25rem',
          borderRadius: '4px',
          textAlign: 'center',
          opacity: mask ? 1 : 0,
        }}>
        {mask}
      </TextSpan>
    </div>
  )
}

/**
 * Renders a color-themed mask background alongside the logo (if it exists) for a bank
 * given just an accountId
 * @param param0
 * @returns
 */
export const AccountMaskAndLogo: React.FC<AccountMaskAndLogoProps> = ({
  accountId,
  logoSize = 24,
  maskSize = 'xxs',
  maskStyle,
  orientation = 'vertical',
}) => {
  const { accountsData, flatAccounts } = useFinancialData()
  const { activeTheme } = useUIState()

  if (!accountId) {
    return (
      <div
        className={`d-flex flex-${
          orientation === 'horizontal' ? 'row mx-2' : 'column'
        } align-items-center`}>
        <BankLogo logo={undefined} institutionColor={'text'} size={logoSize} />
        <TextSpan
          size={maskSize}
          weight={700}
          className="my-1 d-flex align-items-center justify-content-center"
          color={'textsupertransparent'}
          style={{
            fontFamily: 'monospace',
            color: 'text',
            backgroundColor: generateBackgroundColor(
              colorPalletes[activeTheme].text,
              activeTheme === 'newDark' ? 'dark' : 'light'
            ),
            padding: '.125rem .25rem',
            borderRadius: '4px',
            textAlign: 'center',
            ...maskStyle,
          }}>
          manual
        </TextSpan>
      </div>
    )
  }

  const institutions = accountsData?.data?.institutions || []
  const accounts = accountsData?.data?.accounts
  const items = accountsData?.data?.items
  const accountForAccountId = flatAccounts?.find(
    (a) => a.account_id === accountId || a.name === accountId
  )
  const bankForAccount = getBankItemFromAccount(accounts, items, accountId)
  const logo = bankLogoForBank(bankForAccount, institutions)

  const institution = institutions.find(
    (i) => i.institution.institution_id === bankForAccount?.item?.institution_id
  )

  let institutionColor

  if (institution?.institution.primary_color) {
    institutionColor = color(institution?.institution.primary_color || '').toString()
  } else {
    institutionColor = colorPalletes[activeTheme].second
  }

  const backgroundColor = institutionColor
    ? generateBackgroundColor(institutionColor, activeTheme === 'newDark' ? 'dark' : 'light')
    : generateBackgroundColor(
        colorPalletes[activeTheme].second,
        activeTheme === 'newDark' ? 'dark' : 'light'
      )

  return (
    <div
      className={`d-flex flex-${
        orientation === 'horizontal' ? 'row mr-2' : 'column'
      } align-items-center`}>
      <BankLogo
        logo={logo}
        institutionColor={institutionColor}
        size={logoSize}
        institutionName={institution?.institution.name}
      />
      <TextSpan
        size={accountForAccountId?.mask ? maskSize : 'xxxxs'}
        weight={700}
        className={`align-items-center justify-content-center ${
          orientation === 'horizontal' ? 'ml-1 my-0' : 'my-1'
        }`}
        color={'textsupertransparent'}
        style={{
          fontFamily: 'monospace',
          color: institutionColor ? institutionColor : 'inherit',
          backgroundColor,
          display: accountForAccountId?.mask ? 'flex' : 'none !important',
          padding: '.125rem .25rem',
          borderRadius: '4px',
          textAlign: 'center',
          opacity: accountForAccountId?.mask ? 1 : 1,
          ...maskStyle,
        }}>
        {accountForAccountId?.mask || 'manual'}
        {/* <NumberCounter value={Number(accountForAccountId?.mask)} /> */}

        {/* {accountForAccountId?.mask} */}
      </TextSpan>
    </div>
  )
}
