import { eachDayOfInterval } from 'date-fns'
import format from 'date-fns/format'
import dayjs from 'dayjs'

// Comes from date-fns
const formatString = 'yyyy-MM-dd'
const averageDaysPerMonth = 365 / 12

export const monthsOutGivenDays = (days: number) => Math.floor(days / averageDaysPerMonth)
export const weeksOutGivenDays = (days: number) => Math.floor(days / 7)

export const enumerateDaysBetweenDates = (start: Date, end: Date) => {
  return eachDayOfInterval({ start, end })
}

/**
 *
 * @param dayOfMonthNeeded The day of the month (1-31) we want our returned date to be on
 * @param startDate The date AFTER which we want to return a date given the dayOfMonthNeeded
 * @returns dayJs.DayJs
 */
export const getNextDateOnDayOfMonthFollowing = (dayOfMonthNeeded: number, startDate: Date) => {
  const dayOfMonthOfStartDate = dayjs(startDate).date()
  // if we haven't yet passed the day of the month that I need:
  if (dayOfMonthOfStartDate <= dayOfMonthNeeded) {
    // then just give me this months's instance of that day
    return dayjs(startDate).date(dayOfMonthNeeded) // // Gets or sets the day of the month.
  } else {
    // otherwise, give me next months instance of that same day
    return dayjs(startDate).add(1, 'month').date(dayOfMonthNeeded)
  }
}

let today: Date
export const getToday = () => {
  if (!today) {
    const _today = new Date()
    _today.setHours(0)
    _today.setMinutes(0)
    _today.setSeconds(0)
    _today.setMilliseconds(0)
    today = _today
    return today
  } else {
    return today
  }
}

/**
 *
 * @param dayOfWeekNeeded The day of the week (0-6) we want our returned date to be on
 * @param startDate The date AFTER which we want to return a date given the dayOfWeekNeeded
 * @returns dayJs.DayJs
 */
export const getNextDateOnDayOfWeekFollowing = (dayOfWeekNeeded: number, startDate: Date) => {
  const dayOfWeekOfStartDate = dayjs(startDate).day()
  if (dayOfWeekOfStartDate <= dayOfWeekNeeded) {
    return dayjs(startDate).day(dayOfWeekNeeded)
  } else {
    return dayjs(startDate).add(1, 'week').day(dayOfWeekNeeded)
  }
}

/**
 * @param d Date (Mon Feb 20 2023 13:56:07 GMT-0700 (Mountain Standard Time)
 * @returns <string> 2023-02-20
 */
export const dateToString = (d: Date) => {
  // const incomingDate = d
  try {
    return format(d, formatString)
  } catch (err) {
    // TODO: Temporarily removing these since they spam since somewhere in our DayZoomIn component we have a date bug that leads to a ton of bad dates coming in here
    // console.warn('Bad date provided', d)
    // console.warn(err)
    // console.warn(incomingDate)
    return ''
  }
}

/**
 * TODO: Great Win Opportunity. Convert all the instances of dayjs() constructors with this function
 * @param s 2023-02-20
 * @returns <Date> Mon Feb 20 2023 00:00:00 GMT-0700 (Mountain Standard Time
 */
export const stringToDate = (s: string) => {
  // const incomingDate = d
  try {
    const today = new Date()
    // zero out the time
    today.setHours(0, 0, 0, 0)
    const [year, month, day] = s.split('-')

    if (!year || !month || !day) {
      console.warn(
        `stringToDate received bad date ${s}. Returning today (new Date()) to keep things rolling`
      )
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return today
    }

    if (Number(month) > 12) {
      console.warn(
        `stringToDate received date with bad month ${s}. Returning today (new Date()) to keep things rolling`
      )
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return today
    }
    if (Number(day) > 31) {
      console.warn(
        `stringToDate received date with bad day ${s}. Returning today (new Date()) to keep things rolling`
      )
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return today
    }
    /**
     * If a parameter you specify is outside of the expected range,
     * setFullYear() attempts to update the other parameters and the date information
     *  in the Date object accordingly. For example, if you specify 15 for monthValue,
     * the year is incremented by 1 (yearValue + 1), and 3 is used for the month.
     */
    today.setFullYear(Number(year), Number(month) - 1, Number(day))
    today.setHours(0, 0, 0, 0)
    return today
  } catch (err) {
    // TODO: Temporarily removing these since they spam since somewhere in our DayZoomIn component we have a date bug that leads to a ton of bad dates coming in here
    console.warn('Bad date provided', s)
    console.warn(err)
    console.warn(err)
    return new Date()
  }
}

export const humanReadifyStringDateConcise = (d: string | Date) => {
  let date
  if (typeof d === 'string') {
    date = dayjs(d).toDate()
  } else {
    date = d
  }
  try {
    return format(date, 'yyyy-MM-dd')
  } catch (err) {
    console.warn('Bad date provided', d)
    console.warn(err)
    console.warn(d)
    return ''
  }
}

export const humanReadifyStringDateWithTime = (d: string | Date | null) => {
  if (!d) return ''
  let date
  if (typeof d === 'string') {
    date = dayjs(d).toDate()
  } else {
    date = d
  }
  try {
    return format(date, "MMM dd, hh:mm:ss a'")
  } catch (err) {
    console.warn('Bad date provided', d)
    console.warn(err)
    console.warn(d)
    return ''
  }
}

export const humanReadifyStringDateShortSansYear = (d: Date | string | null) => {
  if (!d) return ''
  let date
  if (typeof d === 'string') {
    date = dayjs(d).toDate()
  } else {
    date = d
  }
  // const incomingDate = d
  try {
    return format(date, "MMM dd'")
  } catch (err) {
    console.warn('Bad date provided', d)
    console.warn(err)
    console.warn(d)
    return ''
  }
}

/**
 * March 13, 2023
 * @param d
 * @returns
 */
export const humanReadifyStringDate = (d?: string | Date | null) => {
  if (!d) return ''
  let date
  if (typeof d === 'string') {
    date = dayjs(d).toDate()
  } else {
    date = d
  }
  try {
    return format(date, 'MMM dd, yyyy')
  } catch (err) {
    console.warn('Bad date provided', d)
    console.warn(err)
    console.warn(d)
    return ''
  }
}

export const formatDate = (date: Date) => {
  // const month = date.getMonth() + 1
  // const day = date.getDate()
  // const year = date.getFullYear()
  // return `${year}-${month}-${day}`
  const MyDateString =
    date.getFullYear() +
    '-' +
    ('0' + (date.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + date.getDate()).slice(-2)

  return MyDateString
}

export const yearsMonthstoString = ({ years, months }: { years: number; months: number }) => {
  return `${years} yr${years > 0 ? 's' : ''} ${months} mo${months > 0 ? 's' : ''}`
}
