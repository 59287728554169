import { useAuth0 } from '@auth0/auth0-react'
import { Icon, TextBold, TextSpan } from 'Components/DesignSystem/Typography'
import { useGetAccessToken } from 'Hooks/useGetAccessToken'
import { deleteAllLocalData } from 'Providers/APIDataProvider'
import React from 'react'
import { SettingsItem } from '../components'

export const Logout: React.FC = () => {
  const { deleteLocalToken } = useGetAccessToken('SettingsPage')

  const { logout } = useAuth0()
  return (
    <SettingsItem
      allowedPostTrial
      onClick={() => {
        const answer = window.confirm('Are you sure you want to logout?')
        if (answer) {
          deleteAllLocalData()
          deleteLocalToken()
          logout({
            returnTo: `${window.location.protocol}//${window.location.host}`,
          })
        }
      }}>
      <div className="d-flex flex-column w-100 align-items-start">
        <div className="d-flex flex-row w-100 align-items-center">
          <Icon color="mehred" className="fas fa-sign-out" />
          <TextBold color="mehred">Logout</TextBold>
        </div>
        <TextSpan size="xxs" color="texttransparent">
          See ya later 🐊
        </TextSpan>
      </div>
    </SettingsItem>
  )
}
