import { TextBold, TextSlim, TextSpan } from 'Components/DesignSystem/Typography'
import React from 'react'
import { ColorType } from 'theme'

interface Props {
  className?: string
  color?: ColorType
}

export const BodyText: React.FC<Props> = ({ children, className }) => (
  <TextSlim color="textsupertransparent" size="xs" className={`${className} d-inline`}>
    {children}
  </TextSlim>
)

export const SubtleText: React.FC<Props> = ({ children, className }) => (
  <TextSlim color="texttransparent" size="xs" className={`${className} d-inline`}>
    {children}
  </TextSlim>
)

export const CaptionText: React.FC<Props> = ({ children, className }) => (
  <TextSpan color="textsupertransparent" size="xxs" className={`${className} d-inline`}>
    {children}
  </TextSpan>
)

export const TitleText: React.FC<Props> = ({ children, className }) => (
  <TextBold color="textsupertransparent" size="lg" className={`${className} d-inline`}>
    {children}
  </TextBold>
)

export const SubtitleText: React.FC<Props> = ({ children, className }) => (
  <TextSlim color="texttransparent" size="lg" className={`${className} d-inline`}>
    {children}
  </TextSlim>
)

export const ItemText: React.FC<Props> = ({ children, className }) => (
  <TextSpan
    color="textsupertransparent"
    weight={600}
    size="xs"
    className={`${className} d-inline my-0 py-0`}>
    {children}
  </TextSpan>
)

export const LabelText: React.FC<Props> = ({
  children,
  className,
  color = 'textsupertransparent',
}) => (
  <TextSpan color={color} weight={600} size="xs" className={`${className} d-inline my-0 py-0`}>
    {children}
  </TextSpan>
)
