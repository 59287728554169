import { CollapseCardControlled } from 'Components/CollapseCard/CollapseCardControlled'
import { useMysteryModeFinalDate } from 'Components/DesignSystem/Layout/Header/hooks'
import { dataParameterType } from 'Hooks/useLocalStorageSyncedState'
import { CashflowRecapContent } from 'Pages/MysteryPage/CashFlowRecap/CashFlowRecapContent'
import { yearsMonthstoString } from 'Utilities/dateUtilities/dateUtilities'
import { LabelText } from 'design-system/typography/Text/Text'
import React from 'react'
import { StyledVr, TextSlim, TextSpan } from '../../Components/DesignSystem/Typography'
import { useMysteryDay } from '../../Providers/MysteryDayProvider/MysteryDayProvider'
import { numberToCurrency } from '../../Utilities/currencyFormater'
import { colorFromMysteryDayType } from '../../Utilities/utils'
import { calculateTotalInterest } from './helpers'
import { useGetDashboardValue } from './hooks'

interface Props {
  setCashFlowRecapOpen: (v: dataParameterType<boolean>) => void
  cashFlowRecapOpen: boolean
}
export const DebtDaySummary: React.FC<Props> = ({ setCashFlowRecapOpen, cashFlowRecapOpen }) => {
  const {
    mysteryMode,
    cushionSubMode,
    debtInterest,
    historicalTrackedTransactions,
  } = useMysteryDay()

  const { currentDebtAmount } = useGetDashboardValue()

  const historicalTransactionsForLinkedAccount =
    historicalTrackedTransactions?.filter((t) => t.amount < 0) || []

  const debtPaidOffSoFar = historicalTransactionsForLinkedAccount.reduce(
    (acc, t) => t.amount + acc,
    0
  )

  let { yearsLeft, monthsLeft, finalDateToShow } = useMysteryModeFinalDate()

  const totalInterestPaid = calculateTotalInterest(
    currentDebtAmount,
    debtInterest / 100,
    yearsLeft * 12 + monthsLeft
  )

  return (
    <div className="d-flex flex-column">
      <CollapseCardControlled
        isCollapsed={!cashFlowRecapOpen}
        setIsCollapsed={setCashFlowRecapOpen}
        activeColor="yellow"
        passiveColor="yellow"
        header={
          <>
            <div className="d-flex align-items-center justify-content-between w-100">
              <StyledVr
                color={'cashgreen'}
                size="xl"
                className="mr-2"
                weight={1}
                style={{ opacity: 0.5 }}
              />
              <div className="d-flex flex-column align-items-center">
                <LabelText color="cashgreen">{numberToCurrency(debtPaidOffSoFar)}</LabelText>
                <LabelText color="cashgreen">paid off</LabelText>
              </div>
              <StyledVr
                color={'yellow'}
                size="xl"
                className="mx-2"
                weight={1}
                style={{ opacity: 0.5 }}
              />
              <div className="d-flex flex-column align-items-center">
                <LabelText color="yellow">{numberToCurrency(currentDebtAmount)}</LabelText>
                <LabelText color="yellow">remaining</LabelText>
              </div>

              <StyledVr
                color={'yellow'}
                size="xl"
                className="mx-2"
                weight={1}
                style={{ opacity: 0.5 }}
              />

              <div className="d-flex flex-column align-items-center">
                <LabelText color="yellow">
                  {yearsMonthstoString({ years: yearsLeft, months: monthsLeft })}
                </LabelText>
                <LabelText color="yellow">left</LabelText>
              </div>
            </div>
          </>
        }
        body={<CashflowRecapContent mysteryMode={mysteryMode} />}
      />
      <div className="d-flex align-items-center justify-content-between flex-column pr-2 pt-0 w-100 my-2">
        <div className="d-flex align-items-center justify-content-center flex-column pr-2 pl-1 pt-0 w-100">
          <TextSpan
            style={{
              opacity: monthsLeft === Infinity ? 0 : 1,
              transition: 'all ease-in-out .3s',
            }}
            size="xxs"
            className="my-0"
            weight={200}
            color={colorFromMysteryDayType(mysteryMode)}>
            {mysteryMode === 'getOutOfDebt'
              ? 'Debt free on:'
              : mysteryMode === 'buildCushion' && cushionSubMode === 'extendSavings'
              ? 'Savings run out on'
              : 'Goal Reached On'}
          </TextSpan>
          <TextSlim
            style={{
              opacity: monthsLeft === Infinity ? 0 : 1,
              transition: 'all ease-in-out .3s',
            }}
            className="ml-1 my-0"
            size="sm"
            weight={200}
            color="textsupertransparent">
            <TextSpan
              size="sm"
              className="my-0"
              weight={800}
              color={colorFromMysteryDayType(mysteryMode)}>
              <TextSpan
                size="md"
                className="my-0"
                weight={800}
                color={colorFromMysteryDayType(mysteryMode)}>
                {finalDateToShow || ''}
              </TextSpan>
            </TextSpan>
          </TextSlim>
        </div>
        <TextSlim
          style={{
            opacity: monthsLeft === Infinity ? 0 : 1,
            transition: 'all ease-in-out .3s',
          }}
          className="ml-1 my-0"
          size="xs"
          weight={200}
          color="textsupertransparent"></TextSlim>
        {mysteryMode !== 'getOutOfDebt' || !totalInterestPaid ? null : (
          <TextSpan size="xs" className="my-0 ml-1" weight={200} color="texttransparent">
            Total Interest Paid:
            <TextSpan color="yellow" className="ml-1" size="xs" weight={800}>
              {numberToCurrency(totalInterestPaid)}
            </TextSpan>
          </TextSpan>
        )}
      </div>
    </div>
  )
}
