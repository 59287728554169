import {
  SIMPLIFIED_ACCOUNT_TYPES,
  SimplifiedAccountTypes,
  getColorFromAccountType,
  getDisplayNameFromAccountType,
  getIconFromAccountType,
} from 'Providers/FinancialDataProvider/helpers'
import React from 'react'
import { useFinancialData } from '../Providers/FinancialDataProvider/FinancialDataProvider'
import { numberToCurrency } from '../Utilities/currencyFormater'
import { ContainerCutout } from './DesignSystem/Layout/Containers/Containers'
import { Icon, StyledVr, TextSpan } from './DesignSystem/Typography'

const AccountOverviewWidgetDisplay: React.FC<{
  currentCash: number
  currentDebt: number
  currentCredit: number
  currentSavings: number
}> = ({ currentCash, currentDebt, currentCredit, currentSavings }) => {
  const getAmountForType = (type: SimplifiedAccountTypes) => {
    switch (type) {
      case 'Cash':
        return currentCash
      case 'Debt':
        return currentDebt
      case 'Credit Card':
        return currentCredit
      case 'Savings':
        return currentSavings
      case 'Unknown':
        return 0
    }
  }
  return (
    <ContainerCutout
      size={accountOverviewWidgetSize}
      className="justify-content-start p-2 w-100"
      style={{
        // boxShadow: 'rgb(0 0 0 / 30%) 0px 0px 4px 2px',
        boxShadow: 'inset hsl(243 19% 18% / .5) 0 0px 7px 5px',
        borderRadius: '8px',
        border: 'solid 1px #6362752e',
        marginTop: `${accountOverviewWidgetMarginBottom}px`,
        marginBottom: `${accountOverviewWidgetMarginBottom}px`,
      }}>
      <div className="d-flex align-items-center w-100 justify-content-around">
        {SIMPLIFIED_ACCOUNT_TYPES.map((accountType, index) => {
          return (
            <div className="d-flex flex-row align-items-center" key={accountType}>
              <div
                key={accountType}
                className="d-flex flex-column align-items-center "
                style={{
                  padding: '0 .25rem',
                  flex: 1,
                }}>
                <TextSpan
                  className="mb-1"
                  color={getColorFromAccountType(accountType)}
                  weight={700}
                  size="xs">
                  {getDisplayNameFromAccountType(accountType)}{' '}
                </TextSpan>
                <div>
                  <div className="d-flex flex-column align-items-center ">
                    <Icon
                      className={`${getIconFromAccountType(accountType)}`}
                      color={getColorFromAccountType(accountType)}
                      size="md"
                    />
                    <TextSpan
                      className="my-1"
                      color={getColorFromAccountType(accountType)}
                      weight={700}
                      size="xs">
                      {numberToCurrency(getAmountForType(accountType), true, true)}
                    </TextSpan>
                  </div>
                </div>
              </div>
              {index !== 3 ? <StyledVr /> : null}
            </div>
          )
        })}
      </div>
    </ContainerCutout>
  )
}

export const accountOverviewWidgetSize = 90
export const accountOverviewWidgetMarginBottom = 12
export const AccountOverviewWidget = () => {
  const { currentCash, currentDebt, currentCredit, currentSavings } = useFinancialData()

  return (
    <AccountOverviewWidgetDisplay
      currentCash={currentCash}
      currentDebt={currentDebt}
      currentCredit={currentCredit}
      currentSavings={currentSavings}
    />
  )
}
