import { FIXED_ICON, VARIABLE_ICON, WIZARDRY_ICON } from 'Utilities/navigationTabUtilities'
import { WideButton } from 'design-system/buttons/WideButton/WideButton'
import { capitalize } from 'lodash'
import React, { useState } from 'react'
import { ContainerDiv } from '../../Components/DesignSystem/Layout/Containers/Containers'
import { Icon, TextSlim, TextSpan } from '../../Components/DesignSystem/Typography'
import { AccountPreviewSelection } from './AccountPreview/AccountPreview'
import { FixedWizardry } from './FixedWizardry/FixedWizardry'

interface Props {
  closePage: () => void
  mode: 'fixed'
}

export const PlaidWizardryPage: React.FC<Props> = ({ closePage, mode }) => {
  const [activelySelectedAccount, setActivelySelectedAccount] = useState<{
    itemId: string
    accountId: string
  } | null>(null)

  // const { linkedAccount } = useMysteryDay()

  return (
    <>
      <ContainerDiv className="justify-content-between pt-0 pb-5 w-100">
        <ContainerDiv className="justify-content-start py-2 w-100">
          <div className="d-flex flex-row w-100 align-items-center justify-content-between">
            <div className="d-flex flex-row align-items-center ">
              <Icon
                size="lg"
                color={'mehred'}
                className={`${mode === 'fixed' ? FIXED_ICON : VARIABLE_ICON}`}
              />
              <Icon size="sm" color={'texttransparent'} className={`fas fa-plus mx-1`} />
              <Icon
                style={
                  {
                    '--fa-beat-fade-opacity': 0.67,
                    '--fa-beat-scale': 1.07,
                  } as React.CSSProperties
                }
                size="lg"
                color="softblue"
                className={`${WIZARDRY_ICON}`}
              />
            </div>
            <TextSlim size="md" color="softblue">
              <TextSpan weight={700} color="mehred">
                {capitalize(mode)} Spend
              </TextSpan>{' '}
              Detection
            </TextSlim>
            <Icon
              className="fas fa-times"
              size="xl"
              onClick={() => {
                closePage()
              }}
            />
          </div>
          <>
            {/* <TextSpan color="textsupertransparent" size="md" weight={500} className="px-4">
                Detecting fixed expenses
              </TextSpan> */}
            <TextSpan color="textsupertransparent" size="xxs" className="my-4 d-inline px-4">
              Click through your accounts to see the fixed expenses that we detected. Click on the
              <Icon className="fas fa-circle-plus mx-1" color="softblue" /> to add it as a fixed
              expense. You can always edit these later.
            </TextSpan>
          </>
          <AccountPreviewSelection
            activelySelectedAccount={activelySelectedAccount}
            setActivelySelectedAccount={setActivelySelectedAccount}
          />

          <FixedWizardry
            activelySelectedAccount={activelySelectedAccount}
            setActivelySelectedAccount={setActivelySelectedAccount}
          />
        </ContainerDiv>
        <WideButton
          bgColor="softblue"
          appearance="secondary"
          width="full"
          active
          textSize="large"
          text="Done"
          disabled={!activelySelectedAccount}
          onClick={() => {
            closePage()
          }}
        />
      </ContainerDiv>
    </>
  )
}
