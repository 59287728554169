import { Icon, TextBold, TextPopupSmall, TextSpan } from 'Components/DesignSystem/Typography'
import { useUserConfig } from 'Providers/UserConfigProvider/UserConfigProvider'
import { numberToCurrency } from 'Utilities/currencyFormater'
import React from 'react'
import { SettingsItem } from '../components'

export const DemoModeToggle: React.FC = () => {
  const { demoMode, setDemoMode } = useUserConfig()
  return (
    <SettingsItem onClick={() => setDemoMode(!demoMode)}>
      <div className="d-flex flex-column w-100 align-items-start">
        <div className="d-flex flex-row w-100 align-items-center">
          <Icon
            color={demoMode ? 'orange' : 'softblue'}
            className={`fa-duotone fa-${!demoMode ? 'unlock' : 'user-secret'}`}
          />
          <TextBold color={demoMode ? 'orange' : 'softblue'}>
            {demoMode ? '"Show Friends" Mode' : '"Show Me Only" Mode'}
          </TextBold>
          <TextPopupSmall color={demoMode ? 'orange' : 'softblue'}>
            {demoMode ? `fake data: $000` : `real data: ${numberToCurrency(1234)}`}
          </TextPopupSmall>
        </div>
        <TextSpan size="xxs" color="texttransparent">
          {demoMode
            ? ' Hide all dollar amounts to show friends and stay private'
            : 'Display your real finances throughout app'}
        </TextSpan>
      </div>
    </SettingsItem>
  )
}
