import styled from 'styled-components/macro'

export const Content = styled.div`
  width: 100%;
  padding-top: 3rem;
  height: calc(30%);
  height: calc(75%);
  max-height: calc(75%);
  min-height: calc(75%);
  overflow-y: scroll;
  z-index: 7000;
  position: absolute;
  background: ${(props) => props.theme.colors.mehred};
  display: flex;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  flex-direction: column;
  align-items: center;
`
