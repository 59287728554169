import { CollapseCard } from 'Components/CollapseCard/CollapseCard'
import { Icon, TextSlim, TextSpan } from 'Components/DesignSystem/Typography'
import { EmptyStatePage } from 'Components/EmptyStatePage/EmptyStatePage'
import { FOOTER_ACTION_ROW_HEIGHT } from 'Components/FooterActionRow/FooterActionRow'
import { useLocalStorageSyncedState } from 'Hooks/useLocalStorageSyncedState'
import {
  SpendingReductionNotificationBanner,
  SpendingReductionNotificationBannerSize,
} from 'Pages/StreamPage/SpendingReductionNotificationBanner/SpendingReductionNotificationBanner'
import { useData } from 'Providers/APIDataProvider'
import { useFinancialData } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import { useUIState } from 'Providers/UIStateProvider'
import { useUserCustomSettings } from 'Providers/UserCustomSettingsProvider/UserCustomSettingsProvider'
import { numberToCurrency } from 'Utilities/currencyFormater'
import { VARIABLE_ICON } from 'Utilities/navigationTabUtilities'
import {
  getMonthlyBills,
  getMonthlyIncome,
  getMonthlySubscriptions,
} from 'Utilities/netWorth/netWorth'
import { Row } from 'design-system/table/Row/Row'
import React from 'react'
import { useHistory } from 'react-router'
import {
  ContainerDiv,
  ScrollingFull,
  ScrollingFullFluid,
} from '../../Components/DesignSystem/Layout/Containers/Containers'
import { AddCustomEnvelopePage } from './AddCustomEnvelopePage/AddCustomEnvelopePage'
import { CategoryBudgetTable } from './CategoryBudgetTable/CategoryBudgetTable'
import { useCategoryBudgetHelpers } from './CategoryBudgetTable/helpers'
import { VariablePageFooter } from './VariablePageFooter'
import { VariablePageHeader, variablePageHeaderSize } from './VariablePageHeader'
import { VariablePageSubHeader, variablePageSubHeaderSize } from './VariablePageSubHeader'

export const VariablePage: React.FC = () => {
  const { flexibleSpendPerMonth } = useFinancialData()

  const { onboardingDone } = useMysteryDay()

  const { settings } = useUserCustomSettings()

  const [sortingMode, setSortingMode] = useLocalStorageSyncedState<
    'envelope' | 'budget' | 'remainder' | 'null'
  >('null', 'variable-page-sort-mode')
  const [sortingOrder, setSortingOrder] = useLocalStorageSyncedState<number>(
    -1,
    'variable-page-sort-order'
  )

  const { getCategoryTotal } = useCategoryBudgetHelpers()

  const sortFunction = React.useCallback(
    (a: string, b: string) => {
      switch (sortingMode) {
        case 'budget': {
          const categoryLimitA = settings?.categoryMetadata?.[a]?.limit || 0

          const categoryLimitB = settings?.categoryMetadata?.[b]?.limit || 0

          return categoryLimitA > categoryLimitB ? sortingOrder : -sortingOrder
        }
        case 'envelope':
          return a > b ? sortingOrder : -sortingOrder

        case 'remainder': {
          const categorySpendA = getCategoryTotal(a)
          const categoryLimitA = settings?.categoryMetadata?.[a]?.limit || 0
          const deltaA = categoryLimitA - categorySpendA

          const categorySpendB = getCategoryTotal(b)
          const categoryLimitB = settings?.categoryMetadata?.[b]?.limit || 0
          const deltaB = categoryLimitB - categorySpendB

          return deltaA > deltaB ? sortingOrder : -sortingOrder
        }
        case 'null':
          return sortingOrder
      }
    },
    [sortingMode, sortingOrder, getCategoryTotal, settings]
  )

  const {
    setShowTransactionOverlay,
    setPresetTransactionFilterCategory,
    setPresetTransactionFilterReflectionNeeded,
    setPresetTransactionFilterType,
  } = useUIState()

  const { push } = useHistory()

  const { getAnticipatedTotalBudgeted, getIrregularTotalBudgeted } = useCategoryBudgetHelpers()

  const { streams } = useData()
  const { monthlyContribution } = useMysteryDay()

  const { setAddCustomEnvelopePageOpen } = useUIState()

  const monthlyIncome = getMonthlyIncome(streams)
  const monthlyFixedExpenses = Math.abs(getMonthlyBills(streams) + getMonthlySubscriptions(streams))
  const monthlyVariableExpenses = flexibleSpendPerMonth

  const maxSavings = monthlyIncome - (monthlyFixedExpenses + monthlyVariableExpenses)

  const spendingReductionNeeded = monthlyContribution > maxSavings

  const anticipatedCategoriesLength =
    Object.values(settings?.categoryMetadata || {}).filter((cm) => cm.kind === 'regular')?.length ||
    0
  const irregularCategoriesLength =
    Object.values(settings?.categoryMetadata || {}).filter((cm) => cm.kind === 'irregular')
      ?.length || 0

  if (!onboardingDone) {
    return (
      <EmptyStatePage
        subtitle={
          <TextSpan color="logoPrimary" size="lg" weight={200}>
            <Icon className={`${VARIABLE_ICON} mr-2`} color="logoPrimary" size="lg" />
            Variable Spending & Budgets
          </TextSpan>
        }
        content={
          <>
            <TextSlim size="md" color="texttransparent" weight={300}>
              Once you finish onboarding and link your accounts, this page will break down your
              variable spend and allow you to set limits for your categories.
            </TextSlim>
          </>
        }
        cta={
          <>
            <TextSpan
              onClick={() => {
                push('/mystery')
              }}
              className="mt-2"
              color="texttransparent"
              size="xs">
              <u>Finish onboarding</u>
            </TextSpan>
          </>
        }
      />
    )
  }

  return (
    <>
      <AddCustomEnvelopePage />
      <ContainerDiv>
        <VariablePageHeader setAddCustomCategoryPageOpen={setAddCustomEnvelopePageOpen} />

        <VariablePageSubHeader
          sortingOrder={sortingOrder}
          sortingStrategy={sortingMode}
          onEnvelopeClick={() => {
            switch (`${sortingOrder}-${sortingMode}`) {
              case `1-envelope`:
                setSortingOrder(-1)
                setSortingMode('null')
                break
              case `-1-envelope`:
                setSortingOrder(1)
                break
              default:
                setSortingMode('envelope')
                setSortingOrder(-1)
            }
          }}
          onBudgetClick={() => {
            switch (`${sortingOrder}-${sortingMode}`) {
              case `1-budget`:
                setSortingOrder(-1)
                setSortingMode('null')
                break
              case `-1-budget`:
                setSortingOrder(1)
                break
              default:
                setSortingMode('budget')
                setSortingOrder(-1)
            }
          }}
          onRemainderClick={() => {
            switch (`${sortingOrder}-${sortingMode}`) {
              case `1-remainder`:
                setSortingOrder(-1)
                setSortingMode('null')
                break
              case `-1-remainder`:
                setSortingOrder(1)
                break
              default:
                setSortingMode('remainder')
                setSortingOrder(-1)
            }
          }}
        />

        <ScrollingFull
          cutout={
            variablePageSubHeaderSize +
            variablePageHeaderSize +
            (spendingReductionNeeded ? SpendingReductionNotificationBannerSize : 0) +
            FOOTER_ACTION_ROW_HEIGHT
          }>
          <div className="d-flex flex-column align-items-center justify-content-start w-100">
            <CollapseCard
              persistCollapseState
              id="variable-page-anticipated"
              header={(toggle, isCollapsed) => (
                <Row
                  style={{
                    minHeight: '62px',
                    height: '62px',
                  }}
                  rowIcon="fas fa-envelope-open-dollar"
                  rowIconSize="lg"
                  rowIconColor="yellow"
                  rowBottomColor="yellow"
                  firstColumn={
                    <TextSpan color="textsupertransparent" weight={600} onClick={() => toggle()}>
                      anticipated ({anticipatedCategoriesLength})
                    </TextSpan>
                  }
                  secondColumn={
                    <TextSpan color={'yellow'}>
                      {numberToCurrency(getAnticipatedTotalBudgeted(), true, true)}
                    </TextSpan>
                  }
                  thirdColumn={
                    <TextSpan
                      size="xxs"
                      weight={700}
                      className="p-1"
                      color={'texttransparent'}
                      onClick={() => toggle()}>
                      <Icon
                        color={'yellow'}
                        style={{
                          transition: 'all ease-in-out 0.3s',
                          transform: `${isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)'}`,
                        }}
                        size="xl"
                        className={'far fa-chevron-circle-down'}
                      />
                    </TextSpan>
                  }
                  gridTemplateColumnString="minmax(0, 0.5fr) minmax(0, 1.75fr) minmax(0, 0.75fr) minmax(0, 1fr);"
                />
              )}
              body={
                <div className="d-flex flex-column align-items-center">
                  <ScrollingFullFluid
                    cutout={
                      spendingReductionNeeded
                        ? SpendingReductionNotificationBannerSize +
                          variablePageHeaderSize +
                          // 200 +
                          FOOTER_ACTION_ROW_HEIGHT
                        : variablePageHeaderSize + FOOTER_ACTION_ROW_HEIGHT
                    }>
                    <CategoryBudgetTable
                      sortFunction={sortFunction}
                      categoryType={'custom'}
                      categoryKind={'regular'}
                      onRowClick={(category: string) => {
                        setPresetTransactionFilterType('both')
                        setPresetTransactionFilterReflectionNeeded(false)
                        setPresetTransactionFilterCategory(category)
                        setShowTransactionOverlay(true)
                      }}
                      onLimitClick={(category: string) => {
                        setAddCustomEnvelopePageOpen({ val: category, cb: () => {} })
                      }}
                    />
                  </ScrollingFullFluid>
                </div>
              }
            />
          </div>
          <div className="d-flex flex-column align-items-center justify-content-start w-100">
            <CollapseCard
              header={(toggle, isCollapsed) => (
                <Row
                  style={{
                    minHeight: '62px',
                    height: '62px',
                  }}
                  rowIcon="fas fa-puzzle-piece"
                  rowIconSize="lg"
                  rowIconColor="textsupertransparent"
                  rowBottomColor="textsupertransparent"
                  firstColumn={
                    <TextSpan color="textsupertransparent" weight={600} onClick={() => toggle()}>
                      irregular ({irregularCategoriesLength})
                    </TextSpan>
                  }
                  secondColumn={
                    <TextSpan color={'textsupertransparent'}>
                      {numberToCurrency(getIrregularTotalBudgeted(), true, true)}
                    </TextSpan>
                  }
                  thirdColumn={
                    <TextSpan
                      size="xxs"
                      weight={700}
                      className="p-1"
                      color={'texttransparent'}
                      onClick={() => toggle()}>
                      <Icon
                        color={'texttransparent'}
                        style={{
                          transition: 'all ease-in-out 0.3s',
                          transform: `${isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)'}`,
                        }}
                        size="xl"
                        className={'far fa-chevron-circle-down'}
                      />
                    </TextSpan>
                  }
                  gridTemplateColumnString="minmax(0, 0.5fr) minmax(0, 1.75fr) minmax(0, 0.75fr) minmax(0, 1fr);"
                />
              )}
              body={
                <div className="d-flex flex-column align-items-center">
                  <ScrollingFullFluid
                    cutout={
                      spendingReductionNeeded
                        ? SpendingReductionNotificationBannerSize +
                          variablePageHeaderSize +
                          // 200 +
                          FOOTER_ACTION_ROW_HEIGHT
                        : variablePageHeaderSize + FOOTER_ACTION_ROW_HEIGHT
                    }>
                    <CategoryBudgetTable
                      categoryType={'custom'}
                      sortFunction={sortFunction}
                      categoryKind={'irregular'}
                      onRowClick={(category: string) => {
                        setPresetTransactionFilterType('both')
                        setPresetTransactionFilterReflectionNeeded(false)
                        setPresetTransactionFilterCategory(category)
                        setShowTransactionOverlay(true)
                      }}
                      onLimitClick={(category: string) => {
                        setAddCustomEnvelopePageOpen({ val: category, cb: () => {} })
                      }}
                    />
                  </ScrollingFullFluid>
                </div>
              }
            />
          </div>
        </ScrollingFull>

        <div className="d-flex flex-column">
          <SpendingReductionNotificationBanner actionSentence="Reduce your budget categories to reduce your Variable spending." />
          <div className="d-flex flex-row align-items-center">
            <VariablePageFooter />
          </div>
        </div>
      </ContainerDiv>
    </>
  )
}
