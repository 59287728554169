import { animated, useSpring } from '@react-spring/web'
import { Icon, TextSpan } from 'Components/DesignSystem/Typography'
import { useCategoryBudgetHelpers } from 'Pages/VariablePage/CategoryBudgetTable/helpers'
import { useFinancialData } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import { useUIState } from 'Providers/UIStateProvider'
import { useUserConfig } from 'Providers/UserConfigProvider/UserConfigProvider'
import { TransactionMetadata } from 'Providers/UserCustomSettingsProvider/UserCustomSettingsProvider'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { ReflectionCard } from '../ReflectionCard/ReflectionCard'
import { ReflectionCardIntro } from '../ReflectionCardIntro/ReflectionCardIntro'
import { CarouselContainer } from './ReflectionCarousel.components'

interface Props {}

const ReflectionCarouselTitle = styled.div`
  background-color: ${(props) => props.theme.colors.backgrounddark};
  padding: 0.5rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4), 0 10px 10px -10px rgba(50, 50, 73, 0.3);
  margin-bottom: 1rem;
`

export const ReflectionCarousel: React.FC<Props> = () => {
  const { getUncategorizedData } = useCategoryBudgetHelpers()
  const { transactions, length } = getUncategorizedData()
  const { accountsData } = useFinancialData()

  const { skipReflectionIntro } = useUserConfig()

  const accounts = accountsData?.data?.accounts || []

  const [currentIndex, _setCurrentIndex] = React.useState<number>(0)

  const [onIntroCard, setOnIntroCard] = React.useState<boolean>(!skipReflectionIntro)

  const { setModalContent } = useUIState()

  const setCurrentIndex = React.useCallback(
    (index: number) => {
      if (index > length - 1) {
        _setCurrentIndex(length - 1)
      } else if (index < 0) {
        _setCurrentIndex(0)
      } else {
        _setCurrentIndex(index)
      }
    },
    [length]
  )

  useEffect(() => {
    if (length === 0) setModalContent(null)
  }, [length, setModalContent])

  useEffect(() => {
    if (currentIndex >= length) {
      setCurrentIndex(length - 1)
    }
  }, [length, currentIndex, setCurrentIndex])

  const ref = React.useRef<HTMLSpanElement | null>(null)

  const [style, animate] = useSpring(() => ({ height: '0px', scale: 1 }), [])

  useEffect(() => {
    if (ref.current) {
      animate({
        height: (onIntroCard ? ref.current.offsetHeight : 0) + 'px',
        scale: onIntroCard ? 0.9 : 0.7,
      })
    }
  }, [animate, ref, onIntroCard])

  return (
    <CarouselContainer>
      <ReflectionCarouselTitle>
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center">
            <div className="d-flex flex-column align-items-center">
              <animated.div style={{ overflow: 'hidden', height: style.height }}>
                <TextSpan size="xs" color="textsupertransparent" className="my-0" ref={ref}>
                  Welcome to
                </TextSpan>
              </animated.div>

              <animated.div style={{ scale: style.scale }}>
                <TextSpan size="md" color="logoPrimary" className="my-0">
                  <Icon
                    size="lg"
                    color={'logoPrimary'}
                    className={'fas fa-rectangle-vertical-history mr-2'}
                  />
                  Transaction Reflection
                </TextSpan>
              </animated.div>
            </div>
          </div>
          <Icon
            onClick={() => {
              setModalContent(null)
            }}
            style={{
              position: 'absolute',
              right: '1rem',
            }}
            size="xl"
            color="textsupertransparent"
            className="fas fa-times"
          />
        </div>
      </ReflectionCarouselTitle>
      <>
        {onIntroCard ? (
          <ReflectionCardIntro style={undefined} onDismiss={() => setOnIntroCard(false)} />
        ) : (
          [transactions[currentIndex]]?.map((t, index) => {
            const account_id = t?.account_id
            const accountForTransaction = accounts
              ?.map((a) => a.accounts)
              ?.flat()
              ?.find((a) => a?.account_id === account_id)

            if (!t) return null

            console.log({ accountForTransaction })

            return (
              <ReflectionCard
                location="carousel"
                onBackButtonOnFirstCard={() => setOnIntroCard(true)}
                key={t?.transaction_id}
                a={accountForTransaction}
                t={t}
                currentIndex={currentIndex}
                totalTransactions={transactions.length}
                setCurrentIndex={setCurrentIndex}
                m={{} as TransactionMetadata}
                style={undefined}
                isFinalCard={index === length - 1 && length === 1}
              />
            )
          })
        )}
      </>
    </CarouselContainer>
  )
}
