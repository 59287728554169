import styled from 'styled-components/macro'

export const ManualAccountRowDiv = styled.div`
  width: 100%;
  padding: 0.75rem 0rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.nearlyclear};
  font-family: ${(props) => props.theme.fonts.family.secondary};
  font-weight: 400;
  font-size: 1rem;
  display: grid;
  align-items: center;

  grid-template-columns: minmax(0, 30px) minmax(0, 1fr) minmax(0, 1fr);
`
