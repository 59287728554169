import { IconButton } from 'design-system/buttons/IconButton/IconButton'
import React from 'react'
import { ContainerDiv } from '../../../Components/DesignSystem/Layout/Containers/Containers'
import {
  Icon,
  StyledHr,
  TextPopupSmall,
  TextSlim,
  TextSpan,
} from '../../../Components/DesignSystem/Typography'
import { useData } from '../../../Providers/APIDataProvider'
import { useMysteryDay } from '../../../Providers/MysteryDayProvider/MysteryDayProvider'
import { useStreamModifyingState } from '../../../Providers/StreamModifyingProvider'
import { useUIState } from '../../../Providers/UIStateProvider'
import { numberToCurrency } from '../../../Utilities/currencyFormater'
import { getMonthlyIncome } from '../../../Utilities/netWorth/netWorth'
import { StreamEntry } from '../../StreamPage/StreamEntry/StreamEntry'
import { EmptyStream } from '../../StreamPage/consts'
import { BackButton } from '../MysteryDayBackButton'
import { ForwardButton } from '../MysteryDayForwardButton'
import { SubPageProps } from '../MysteryPage'

export const PaycheckOrIncomePage: React.FC<SubPageProps> = () => {
  const { streams } = useData()

  const { setStreamToEdit } = useStreamModifyingState()

  const { mysteryMode } = useMysteryDay()

  const { setMessagePopupContent } = useUIState()

  const incomeStreams = streams
    .filter((s) => s.type === 'income')
    .sort((sa, sb) => (sa.amount > sb.amount ? 1 : -1))

  const monthlyIncome = getMonthlyIncome(streams)

  return (
    <ContainerDiv className="justify-content-between align-items-center py-3">
      <ContainerDiv className="justify-content-start align-items-center">
        <TextSlim size="lg" className="px-2" color="texttransparent">
          Monthly Income
        </TextSlim>

        <TextSlim size="xs" className="px-4" color="textsupertransparent">
          How much do you get paid?
        </TextSlim>

        {/* {incomeStreams.length ? (
          <TextSlim size="xs" className="px-4" color="texttransparent">
            To access later, see <ContextualLinkCTA to="/fixed" />
          </TextSlim>
        ) : null} */}

        <section className="w-100">
          {!incomeStreams.length ? (
            <div
              data-testid="no-streams-container"
              className="px-2 w-100 h-100 d-flex align-items-center justify-content-center flex-column">
              <StyledHr className="my-3" weight={1} color="textsupertransparent" />
              <IconButton
                color="cashgreen"
                iconClassName="fak fa-solid-sack-dollar-circle-plus"
                title="add income"
                onClick={() => {
                  setStreamToEdit(EmptyStream, 'add')
                }}
              />
            </div>
          ) : (
            <div
              style={{
                boxShadow: 'inset hsl(243 19% 18% / .5) 0 0px 7px 5px',
                border: 'solid 1px #6362752e',
                maxHeight: '200px',
                overflowY: 'scroll',
              }}
              className="px-2">
              {incomeStreams.map((stream) => {
                return (
                  <StreamEntry key={stream.name} stream={stream} onStreamEdit={setStreamToEdit} />
                )
              })}
            </div>
          )}
          {incomeStreams?.length ? (
            <div
              data-testid="no-streams-container"
              className="px-2 w-100 h-100 d-flex align-items-center justify-content-center flex-column">
              <div
                onClick={() => {
                  setStreamToEdit(EmptyStream, 'add')
                }}
                className="d-flex flex-column align-items-center justify-content-start mt-4">
                <TextSpan className="my-1" color="cashgreen" weight={500} size="md">
                  <Icon size="sm" color={'cashgreen'} className="fas fa-plus-circle mx-2" />
                  add another
                </TextSpan>
              </div>
            </div>
          ) : null}
        </section>
      </ContainerDiv>
      <div className="d-flex justify-content-between align-items-center w-100 flex-column">
        <TextSpan weight={700} size="sm" color="cashgreen" className="mb-3">
          <TextSpan color="texttransparent" size="sm" className="mr-2">
            Monthly Income =
          </TextSpan>{' '}
          {numberToCurrency(Math.abs(monthlyIncome), true)} / m
          <TextPopupSmall
            color="cashgreen"
            className="ml-2"
            onClick={() => {
              setMessagePopupContent(
                "Monthly Income<>We take your paycheck amount and your paycheck frequency and calculate your monthly income. If you get paid infrequently, we'll navigate the sporadic income with you and still help you feel in control."
              )
            }}>
            <Icon color="cashgreen" size="lg" className="fas fa-circle-info" />
          </TextPopupSmall>
        </TextSpan>

        <div className="d-flex justify-content-between align-items-center w-100">
          <BackButton />
          <ForwardButton
            // TODO: Need to think about how non-paycheck people will be handled here
            disabled={mysteryMode === 'buildCushion' ? false : !incomeStreams.length}
            title={mysteryMode === 'buildCushion' || mysteryMode === 'saveToQuit' ? 'Next' : 'Next'}
          />
        </div>
      </div>
    </ContainerDiv>
  )
}
