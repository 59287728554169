import { Icon, StyledVr, TextBold, TextSpan } from 'Components/DesignSystem/Typography'
import { useSubscriptionStatus } from 'Providers/SubscriptionStatusProvider/SubscriptionStatusProvider'
import { useUIState } from 'Providers/UIStateProvider'
import React from 'react'
import { useHistory } from 'react-router'
import { SettingsItem } from '../components'

export const OpenSubscriptionPage: React.FC = () => {
  const { setSettingsPageOpen } = useUIState()
  const { push } = useHistory()
  const { status, trialDaysLeft } = useSubscriptionStatus()
  return (
    <SettingsItem
      allowedPostTrial
      onClick={() => {
        push('/checkout')
        setSettingsPageOpen(false)
      }}>
      <div className="d-flex flex-column w-100 align-items-start">
        <div className="d-flex flex-row w-100 align-items-center">
          <Icon color={'cashgreen'} className={`fa-duotone fa-dollar-sign`} />
          <TextBold color={'cashgreen'}>
            Get Splurv
            {/* {centsMode ? 'Show Cents' : 'Round Dollars'} */}
          </TextBold>
        </div>
        <TextSpan size="xxs" color="texttransparent">
          Monthly & annual subscriptions
          <StyledVr className="mx-1" />
          {status === 'inactive' ? (
            <TextSpan color={trialDaysLeft < 0 ? 'mehred' : 'orange'} size="xxs">
              {trialDaysLeft > 0 ? `${trialDaysLeft} days left in trial` : `trial over`}
            </TextSpan>
          ) : (
            <TextSpan color="logoPrimary" size="xxxxs">
              premium
            </TextSpan>
          )}
        </TextSpan>
      </div>
    </SettingsItem>
  )
}
