import React, { useState } from 'react'
import { EmptyStream } from '../Pages/StreamPage/consts'
import { MoneyStream } from '../Utilities/interfaces'

// import { useFinancialData } from './FinancialDataProvider'

interface TopLevelState {
  addEditPageOpen: boolean
  setAddEditPageOpen: (bool: boolean) => void

  streamToEdit: MoneyStream | null
  setStreamToEdit: (stream: MoneyStream | null, mode: 'edit' | 'add') => void

  streamModifyMode: 'add' | 'edit'
  _setStreamModifyMode: (mode: 'add' | 'edit') => void
}

const defaultData: TopLevelState = {
  addEditPageOpen: false,
  setAddEditPageOpen: (bool: boolean) => {},

  streamToEdit: EmptyStream,
  setStreamToEdit: (stream: MoneyStream | null, mode: 'edit' | 'add') => {},

  streamModifyMode: 'add',
  _setStreamModifyMode: (mode: 'add' | 'edit') => {},
}

interface Props {
  children: React.ReactNode
}

const StreamModifyingContext = React.createContext<TopLevelState>(defaultData)

export const useStreamModifyingState = () => React.useContext(StreamModifyingContext)
/**
 * Allows us to pop open the add/edit stream page from anywhere in the app by lifting this state into a global provider
 * vs. being local to the StreamPage
 * @returns
 */
export const StreamModifyingProvider = ({ children }: Props) => {
  const [addEditPageOpen, setAddEditPageOpen] = useState<TopLevelState['addEditPageOpen']>(
    defaultData.addEditPageOpen
  )
  const [streamToEdit, _setStreamToEdit] = useState<TopLevelState['streamToEdit']>(
    defaultData.streamToEdit
  )
  const [streamModifyMode, _setStreamModifyMode] = useState<TopLevelState['streamModifyMode']>(
    defaultData.streamModifyMode
  )

  const setStreamToEdit = React.useCallback((stream: MoneyStream | null, mode: 'edit' | 'add') => {
    _setStreamToEdit(stream)
    _setStreamModifyMode(mode)
    setAddEditPageOpen(true)
  }, [])

  return (
    <StreamModifyingContext.Provider
      value={{
        addEditPageOpen,
        setAddEditPageOpen,

        streamToEdit,
        setStreamToEdit,

        streamModifyMode,
        _setStreamModifyMode,
      }}>
      {children}
    </StreamModifyingContext.Provider>
  )
}
