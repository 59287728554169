import color from 'color'
import styled from 'styled-components/macro'

const INDICATOR_SIZE = 30

interface Props {
  state: 'complete' | 'partial' | 'empty'
  percentageFilled: number
}

export const IndicatorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;

  progress {
    width: 100%;
    margin-bottom: 1rem;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 10px;
    background-color: ${(props) => props.theme.colors.nearlyclear};
  }
  progress[value]::-webkit-progress-bar {
    background-color: ${(props) => props.theme.colors.nearlyclear};
    border-radius: 10px;
    box-shadow: none;
    transition: all ease-in-out 0.3s;
  }

  progress[value]::-webkit-progress-value {
    background-color: ${(props) => props.theme.colors.cashgreen};
    border-radius: 10px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
    transition: all ease-in-out 0.3s;
  }
`

export const IndicatorHolder = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
  span.date {
    position: absolute;
    top: -1.5rem;
  }
  span.price {
    position: absolute;
    bottom: -1.5rem;
  }
`

export const Indicator = styled.div<Props>`
  width: ${() => `${INDICATOR_SIZE}px`};
  min-width: ${() => `${INDICATOR_SIZE}px`};
  height: ${() => `${INDICATOR_SIZE}px`};
  min-height: ${() => `${INDICATOR_SIZE}px`};
  transition: all ease-in-out 0.3s;
  background: ${(props) =>
    props.state === 'empty'
      ? color(props.theme.colors.texttransparent).alpha(0.1).toString()
      : props.state === 'partial'
      ? props.theme.colors.softblue
      : props.theme.colors.cashgreen};
  clip-path: ${(props) =>
    props.state === 'partial'
      ? `inset(0px ${(1 - props.percentageFilled) * INDICATOR_SIZE}px 0px 0px)`
      : ''};
  /* border: solid 1px ${(props) => props.theme.colors.cashgreen}; */
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  overflow: visible;

  span {
    position: absolute;
    bottom: -2.5rem;
  }
`

export const OuterIndicator = styled.div<Props>`
  width: ${() => `${INDICATOR_SIZE}px`};
  min-width: ${() => `${INDICATOR_SIZE}px`};
  height: ${() => `${INDICATOR_SIZE}px`};
  min-height: ${() => `${INDICATOR_SIZE}px`};
  transition: all ease-in-out 0.3s;
  border: solid 1px
    ${(props) =>
      props.state === 'complete'
        ? props.theme.colors.cashgreen
        : props.state === 'partial'
        ? props.theme.colors.softblue
        : props.theme.colors.texttransparent};
  background: ${(props) => color(props.theme.colors.softblue).alpha(0.1).toString()};
  z-index: 5;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
`
