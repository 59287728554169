import { Icon, StyledHr, TextSpan } from 'Components/DesignSystem/Typography'
import React from 'react'

import { WelcomeToSplurv } from 'Components/WelcomeToSplurv/WelcomeToSplurv'
import { IconButton } from 'design-system/buttons/IconButton/IconButton'
import { ToolSteps } from './ToolSteps'

interface DemoWalkthroughIntroProps {
  closePage: () => void
}
export const ToolOverviewPage: React.FC<DemoWalkthroughIntroProps> = ({ closePage }) => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-start w-100 h-100 px-2">
      <WelcomeToSplurv color="text" />
      <br /> <StyledHr className="my-0" color="second" weight={1} style={{ opacity: 0.3 }} />
      <TextSpan color="text" size="lg" style={{}}>
        The Tool
        <Icon className="fal fa-mobile ml-1" color="text" size="xl" />
      </TextSpan>
      <TextSpan color="text" className="px-4" size="xs">
        Every part of the app is designed around our system and in service of our mission.
      </TextSpan>
      <div className="d-flex flex-column w-100 align-items-start">
        <ToolSteps />
      </div>
      <div
        style={{ position: 'absolute', bottom: '2rem', left: 0 }}
        className="d-flex flex-row align-items-center justify-content-center w-100 px-4">
        <IconButton
          onClick={() => {
            closePage()
          }}
          title={'back'}
          color={'text'}
          iconClassName={'fas fa-chevron-right'}
          size="large"
        />
      </div>
    </div>
  )
}
