import React from 'react'
import { ColorType } from 'theme'
import { ProgressMeter, ProgressMeterContainer } from './ProgressWidget.components'

export const getNumberOfDaysTHisMonth = () => {
  const now = new Date()
  return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate()
}

interface Props {
  maxValue: number
  value: number
  progressColor: ColorType
  preComponent?: React.ReactNode
  postComponent?: React.ReactNode
  onClick?: () => void
  skipUnderline?: boolean
}
export const ProgressWidget: React.FC<Props> = ({
  maxValue,
  value,
  progressColor,
  preComponent,
  postComponent,
  onClick,
  skipUnderline,
}) => {
  return (
    <ProgressMeterContainer
      progressColor={progressColor}
      skipUnderline={skipUnderline}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        onClick && onClick()
      }}>
      {preComponent}
      <ProgressMeter progressColor={progressColor} max={maxValue} value={value} />
      {postComponent}
    </ProgressMeterContainer>
  )
}
