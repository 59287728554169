export const defaultCreditCardSpendData = {
  lastTwoStatements: {
    total: 0,
    transactionCount: 0,
    monthlyAverage: 0,
    transactions: [],
  },
  thisStatement: {
    total: 0,
    transactionCount: 0,
    monthlyAverage: 0,
    transactions: [],
  },
  alltime: {
    total: 0,
    transactionCount: 0,
    monthlyAverage: 0,
    transactions: [],
  },
  today: {
    total: 0,
    transactionCount: 0,
    monthlyAverage: 0,
    transactions: [],
  },
  thisWeek: {
    total: 0,
    transactionCount: 0,
    monthlyAverage: 0,
    transactions: [],
  },
  lastMonth: {
    total: 0,
    transactionCount: 0,
    monthlyAverage: 0,
    transactions: [],
  },
  thisMonth: {
    total: 0,
    transactionCount: 0,
    monthlyAverage: 0,
    transactions: [],
  },
  thisYear: {
    total: 0,
    transactionCount: 0,
    monthlyAverage: 0,
    transactions: [],
  },
  lastYear: {
    total: 0,
    transactionCount: 0,
    monthlyAverage: 0,
    transactions: [],
  },
  projectedStatement: 0,
  currentPaymentsMade: 0,
}

const DAYS_PER_YEAR = 365
const DAYS_PER_WEEK = 7
const MONTHS_PER_YEAR = 12
const WEEKS_PER_YEAR = DAYS_PER_YEAR / DAYS_PER_WEEK
export const WEEKS_PER_MONTH = WEEKS_PER_YEAR / MONTHS_PER_YEAR
export const DAYS_PER_MONTH = DAYS_PER_YEAR / MONTHS_PER_YEAR
