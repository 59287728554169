import { AccountMaskAndLogo } from 'Components/AccountMaskAndLogo'
import { ContainerDiv } from 'Components/DesignSystem/Layout/Containers/Containers'
import { Icon, StyledHr, StyledVr, TextSlim, TextSpan } from 'Components/DesignSystem/Typography'
import { TransitionFromAbove } from 'Components/Transitions/TransitionFromAbove'
import { AccountTrackingPage } from 'Pages/AccountTrackingPage/AccountTrackingPage'
import { useFinancialData } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import {
  SimplifiedAccountTypes,
  getAccountTypeFromTypeSubtypeTuple,
  getColorFromAccountType,
} from 'Providers/FinancialDataProvider/helpers'
import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import { useUIState } from 'Providers/UIStateProvider'
import { numberToCurrency } from 'Utilities/currencyFormater'
import { IconButton } from 'design-system/buttons/IconButton/IconButton'
import React from 'react'
import { ColorType, colorPalletes } from 'theme'
import { HistoricalTransactionsPopupContent } from './HistoricalTransactionsPopupContent'
import { useGetMultipleTrackedAccountDetails, useGetTrackedAccountDetails } from './hooks'

interface AccountTrackingCTAProps {
  type: SimplifiedAccountTypes
  updateMode: boolean
  size?: 'regular' | 'large'
  title?: string
  setupTitle?: string
}
export const AccountTrackingCTA: React.FC<AccountTrackingCTAProps> = ({
  type,
  updateMode,
  size = 'regular',
  title = 'Track with Account',
  setupTitle = 'Tracked Account',
}) => {
  const {
    linkedAccount,
    linkedAccounts,
    clearLinkedAccounts,
    setLinkedAccount,
    mysteryMode,
    saveUserOnboardingLinkedAccount,
    clearHistoricalTrackedTransactions,
  } = useMysteryDay()

  const trackAccountDetails = useGetTrackedAccountDetails()
  const trackMultipleAccountDetails = useGetMultipleTrackedAccountDetails()

  const { flatAccounts, flatManualAccounts } = useFinancialData()

  // const linkedAccountFull = flatAccounts.find((fa) => fa.account_id === linkedAccount?.accountId)

  const {
    pickExistingAccountPageOpen,
    setPickExistingAccountPageOpen,
    activeTheme,
    setPopup,
  } = useUIState()

  let accountType: SimplifiedAccountTypes
  // let icon: string = ''
  let color: ColorType = 'text'

  if (linkedAccount) {
    if (linkedAccount.type === 'manual') {
      const manualAccountThatsBeingTracked = flatManualAccounts.find(
        (fma) => fma.account_id === linkedAccount.accountId
      )
      if (manualAccountThatsBeingTracked) {
        accountType = manualAccountThatsBeingTracked.type
      } else {
        accountType = 'Unknown'
      }
    } else {
      const liveAccountThatsBeingTracked = flatAccounts.find(
        (fa) => fa.account_id === linkedAccount.accountId
      )
      if (liveAccountThatsBeingTracked) {
        accountType = getAccountTypeFromTypeSubtypeTuple([
          liveAccountThatsBeingTracked.type,
          liveAccountThatsBeingTracked.subtype,
        ]) as SimplifiedAccountTypes
      } else {
        accountType = 'Unknown'
      }
    }
    // icon = getIconFromAccountType(accountType)
    color = getColorFromAccountType(accountType)
  }

  // if (linkedAccounts.length) {
  //   return (
  //     <ContainerDiv className="justify-content-between align-items-center py-0 my-0">
  //       <TransitionFromAbove show={pickExistingAccountPageOpen !== false}>
  //         <AccountTrackingPage updateMode={updateMode} />
  //       </TransitionFromAbove>
  //       <div className="d-flex w-100 align-items-center justify-content-center flex-column mt-2">
  //         <div className="d-flex align-items-center">
  //           <Icon
  //             style={
  //               {
  //                 '--fa-beat-fade-opacity': 0.67,
  //                 '--fa-beat-scale': 1.27,
  //               } as React.CSSProperties
  //             }
  //             size="lg"
  //             color={typeColor}
  //             className="fa-duotone fa-bullseye fa-beat"
  //           />
  //           <TextSlim size="md" weight={800} className="px-2" color={typeColor}>
  //             {linkedAccounts.length} Live {typeName} Account
  //           </TextSlim>
  //         </div>

  //         {/* <TextSpan>{linkedAccountFull.name}</TextSpan> */}

  //         {/* <div className="d-flex align-items-center">
  //           <Icon size="sm" color={color} className={`${icon} mr-2`} />
  //           <TextSpan weight={600} color={color} size="md">
  //             {numberToCurrency(
  //               linkedAccountFull.balances.current || linkedAccountFull.balances.current
  //             )}
  //           </TextSpan>
  //         </div> */}
  //         <StyledHr color="nearlyclear" className="my-0" />

  //         <div className="d-flex flex-row align-items-center justify-content-around">
  //           <Icon
  //             onClick={async () => {
  //               if (updateMode) {
  //                 // @ts-ignore
  //                 await saveUserOnboardingLinkedAccount()
  //                 setLinkedAccount(null)
  //                 clearHistoricalTrackedTransactions()
  //               } else {
  //                 setLinkedAccount(null)
  //                 clearHistoricalTrackedTransactions()
  //               }
  //             }}
  //             size="xl"
  //             color={'mehred'}
  //             className={`fas fa-trash mr-4 my-2`}
  //           />
  //           <Icon
  //             onClick={() => {
  //               setPickExistingAccountPageOpen(mysteryMode)
  //             }}
  //             size="xl"
  //             color={typeColor}
  //             className={`fas fa-pen ml-4 my-2`}
  //           />
  //         </div>
  //       </div>
  //     </ContainerDiv>
  //   )
  // }

  if (linkedAccount && trackAccountDetails) {
    return (
      <ContainerDiv className="justify-content-between align-items-center py-0 my-0">
        <TransitionFromAbove show={pickExistingAccountPageOpen !== false}>
          <AccountTrackingPage updateMode={updateMode} />
        </TransitionFromAbove>
        <div className="d-flex w-100 align-items-center justify-content-center flex-column mt-2">
          <div className="d-flex align-items-center">
            <Icon
              style={
                {
                  '--fa-primary-color': colorPalletes[activeTheme][color],
                  '--fa-secondary-color': colorPalletes[activeTheme]['texttransparent'],
                  '--fa-primary-opacity': 0.7,
                  '--fa-secondary-opacity': 0.8,
                } as React.CSSProperties
              }
              className="fa-duotone fa-chart-line-up-down"
              color={color}
              size={'lg'}
            />

            <TextSlim size={'sm'} weight={300} className="px-2" color={'texttransparent'}>
              {setupTitle}
            </TextSlim>
          </div>

          <div className="d-flex align-items-center justify-content-center">{null}</div>
          <div className="d-flex flex-column align-items-center">
            <div className="d-flex flex-row align-items-center justify-content-around">
              <AccountMaskAndLogo
                orientation="horizontal"
                logoSize={20}
                maskSize="xxs"
                accountId={linkedAccount?.accountId}
              />
              <StyledVr weight={1} color="textsupertransparent" className="mx-1" size="sm" />
              <TextSpan size="xxs" color="textsupertransparent">
                {trackAccountDetails.name}
              </TextSpan>
              <StyledVr weight={1} color="textsupertransparent" className="mx-1" size="sm" />
              <TextSpan weight={600} color={color} size="xs">
                {numberToCurrency(trackAccountDetails.amount)}
              </TextSpan>
            </div>

            <StyledHr color="nearlyclear" className="my-0" />

            <div className="d-flex flex-row align-items-center justify-content-around my-2">
              <IconButton
                onClick={async () => {
                  if (updateMode) {
                    // HACK: We are sending undefined as part of this async POST API request
                    // to essentially clear the linkedAcocunt in the backend. Beause our fancy async local
                    // synced storage hoook generics dont allow for null, we need to add this ignore herrre
                    // @ts-ignore
                    await saveUserOnboardingLinkedAccount()
                    setLinkedAccount(null)
                    clearHistoricalTrackedTransactions()
                  } else {
                    setLinkedAccount(null)
                    clearHistoricalTrackedTransactions()
                  }
                }}
                size="small"
                color={'mehred'}
                title="delete"
                iconClassName={`fas fa-trash`}
                buttonClassName={`mr-2 my-2`}
              />
              <IconButton
                title="edit"
                onClick={() => {
                  setPickExistingAccountPageOpen(mysteryMode)
                }}
                size="small"
                color={'yellow'}
                iconClassName={`fas fa-pencil`}
                buttonClassName={`mx-2 my-2`}
              />
              <IconButton
                title="history"
                onClick={() => {
                  setPopup(<HistoricalTransactionsPopupContent />)
                }}
                size="small"
                color={'cashgreen'}
                iconClassName={`fas fa-eye`}
                buttonClassName={`ml-2 my-2`}
              />
            </div>
          </div>
        </div>
      </ContainerDiv>
    )
  }
  if (linkedAccounts.length && trackMultipleAccountDetails.length) {
    return (
      <ContainerDiv className="justify-content-between align-items-center py-0 my-0">
        <TransitionFromAbove show={pickExistingAccountPageOpen !== false}>
          <AccountTrackingPage updateMode={updateMode} />
        </TransitionFromAbove>
        <div className="d-flex w-100 align-items-center justify-content-center flex-column mt-2">
          <div className="d-flex align-items-center">
            <Icon
              style={
                {
                  '--fa-primary-color': colorPalletes[activeTheme][color],
                  '--fa-secondary-color': colorPalletes[activeTheme]['texttransparent'],
                  '--fa-primary-opacity': 0.7,
                  '--fa-secondary-opacity': 0.8,
                } as React.CSSProperties
              }
              className="fa-duotone fa-chart-line-up-down"
              color={color}
              size={'lg'}
            />

            <TextSlim size={'sm'} weight={300} className="px-2" color={'texttransparent'}>
              {setupTitle}
            </TextSlim>
          </div>

          <div className="d-flex align-items-center justify-content-center">{null}</div>
          <div className="d-flex flex-column align-items-center">
            {trackMultipleAccountDetails.map((tmad) => {
              if (!tmad) return null
              return (
                <div
                  key={tmad.name + tmad.amount + tmad.type}
                  className="d-flex flex-row align-items-center justify-content-around">
                  <AccountMaskAndLogo
                    orientation="horizontal"
                    logoSize={20}
                    maskSize="xxs"
                    accountId={tmad.accountId}
                  />
                  <StyledVr weight={1} color="textsupertransparent" className="mx-1" size="sm" />
                  <TextSpan size="xxs" color="textsupertransparent">
                    {tmad.name}
                  </TextSpan>
                  <StyledVr weight={1} color="textsupertransparent" className="mx-1" size="sm" />
                  <TextSpan weight={600} color={color} size="xs">
                    {numberToCurrency(tmad.amount)}
                  </TextSpan>
                </div>
              )
            })}

            <StyledHr color="nearlyclear" className="my-0" />

            <div className="d-flex flex-row align-items-center justify-content-around my-2">
              <IconButton
                onClick={async () => {
                  if (updateMode) {
                    // HACK: We are sending undefined as part of this async POST API request
                    // to essentially clear the linkedAcocunt in the backend. Beause our fancy async local
                    // synced storage hoook generics dont allow for null, we need to add this ignore herrre
                    // @ts-ignore
                    await saveUserOnboardingLinkedAccount()
                    setLinkedAccount(null)
                    clearLinkedAccounts()
                    clearHistoricalTrackedTransactions()
                  } else {
                    setLinkedAccount(null)
                    clearHistoricalTrackedTransactions()
                    clearLinkedAccounts()
                  }
                }}
                size="small"
                color={'mehred'}
                title="delete"
                iconClassName={`fas fa-trash`}
                buttonClassName={`mr-2 my-2`}
              />
              <IconButton
                title="edit"
                onClick={() => {
                  setPickExistingAccountPageOpen(mysteryMode)
                }}
                size="small"
                color={'yellow'}
                iconClassName={`fas fa-pencil`}
                buttonClassName={`mx-2 my-2`}
              />
              <IconButton
                title="history"
                onClick={() => {
                  setPopup(<HistoricalTransactionsPopupContent />)
                }}
                size="small"
                color={'cashgreen'}
                iconClassName={`fas fa-eye`}
                buttonClassName={`ml-2 my-2`}
              />
            </div>
          </div>
        </div>
      </ContainerDiv>
    )
  }
  return (
    <ContainerDiv className="justify-content-between align-items-center py-0 my-0">
      <TransitionFromAbove show={pickExistingAccountPageOpen !== false}>
        <AccountTrackingPage updateMode={updateMode} />
      </TransitionFromAbove>
      <div className="d-flex w-100 align-items-center justify-content-center flex-column mt-2">
        <div
          className="d-flex w-100 align-items-center justify-content-center flex-column mt-2"
          onClick={() => {
            // if (!existingAccountsExist) return
            setPickExistingAccountPageOpen(mysteryMode)
          }}>
          <Icon
            className="far fa-chart-line-up-down"
            color="texttransparent"
            size={size === 'large' ? 'xl' : 'md'}
          />
          <TextSlim
            size={size === 'regular' ? 'sm' : 'sm'}
            weight={300}
            className="px-2 mb-0"
            color={'texttransparent'}>
            {title}
          </TextSlim>
          {/* <TextSpan size={'xxs'} weight={300} className="" color={'textsupertransparent'}>
            (optional)
          </TextSpan> */}
        </div>
      </div>
    </ContainerDiv>
  )
}
