import color from 'color'
import styled from 'styled-components/macro'

export const AccountRowDiv = styled.div`
  width: 100%;
  padding: 0.75rem 0rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.nearlyclear};
  font-family: ${(props) => props.theme.fonts.family.secondary};
  font-weight: 400;
  font-size: 1rem;
  display: grid;
  align-items: center;

  min-height: 120px;
  max-height: 120px;
  grid-template-columns: minmax(0, 3fr) minmax(0, 0.5fr);
`

export const AccountRowOverlayDiv = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-betwee;
  background: ${(props) => props.theme.colors.background};
  border-bottom: ${(props) =>
    `solid 1px ${color(props.theme.colors.backgrounddark).alpha(0.1).toString()}`};

  margin: 0.25rem 0;
  margin: 0;
  padding: 0.5rem 0rem 0.5rem 0.25rem;

  min-height: 118px;
  max-height: 118px;
`
