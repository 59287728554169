import { CurrencyCounter } from 'Components/AnimatedCounters/CurrencyCounter'
import { ContainerDiv } from 'Components/DesignSystem/Layout/Containers/Containers'
import { StyledHr, TextSlim, TextSpan } from 'Components/DesignSystem/Typography'
import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import { numberToCurrency } from 'Utilities/currencyFormater'
import { humanReadifyStringDate, stringToDate } from 'Utilities/dateUtilities/dateUtilities'
import color from 'color'
import { BodyText } from 'design-system/typography/Text/Text'
import React from 'react'
import styled from 'styled-components'

const HistoricalTransactionDiv = styled.div<{ loading?: boolean }>`
  width: 100%;
  align-items: center;
  display: grid;
  border-bottom: ${(props) =>
    `solid 1px ${color(props.theme.colors.backgrounddark).alpha(0.1).toString()}`};
  margin: 0.25rem;
  padding: 0.25rem 0;
  background-color: ${(props) => (props.loading ? props.theme.colors.logoPrimary : 'transparent')};
  transition: background-color 0.5s ease-in-out;
  grid-template-columns: minmax(0, 2.25fr) minmax(0, 1fr) minmax(0, 1.25fr);
  * {
    display: flex;
    justify-content: flex-start;
    padding: 0 0.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: flex-start;
    display: inline-block;
    display: flex;
    /* white-space: nowrap; */
    text-align: left;
  }
  *:first-child {
    justify-content: flex-start;
  }
  *:last-child {
    text-align: end;
  }
`

interface Props {}
export const HistoricalTransactionsPopupContent: React.FC<Props> = () => {
  const { historicalTrackedTransactions, mysteryMode } = useMysteryDay()

  const transactionsPertinentToGoal = historicalTrackedTransactions.filter((t) =>
    mysteryMode === 'getOutOfDebt' ? t.amount < 0 : true
  )
  const firstTransactionDate = humanReadifyStringDate(
    stringToDate(historicalTrackedTransactions.slice(-1)[0].date)
  )
  const transactionSum = transactionsPertinentToGoal.reduce((acc, t) => acc + t.amount, 0)

  return (
    <ContainerDiv className="px-4 py-4">
      <TextSlim size="md" weight={200} color="textsupertransparent">
        Historical Progress Transactions
      </TextSlim>
      <BodyText>
        We have pulled back <b>{transactionsPertinentToGoal.length}</b> transactions that have
        contributed to your goal dating back to <br />
        <b>{firstTransactionDate}</b> with a total of:
        <TextSpan size="xs" weight={500} color="cashgreen" className="ml-1">
          <CurrencyCounter value={transactionSum} />
        </TextSpan>
      </BodyText>

      <StyledHr className="my-3" />
      <div
        className="d-flex flex-column align-items-center"
        style={{
          maxHeight: `calc('50%')`,
          overflowY: 'scroll',
        }}>
        {transactionsPertinentToGoal.map((htt, index) => {
          return (
            <HistoricalTransactionDiv key={htt.name + htt.date + htt.amount + index}>
              <TextSpan size="xxs" color="texttransparent" weight={200}>
                {htt.name}
              </TextSpan>
              <TextSpan weight={600} size="xxs" color={htt.amount > 0 ? 'mehred' : 'cashgreen'}>
                {numberToCurrency(htt.amount, true, true)}
              </TextSpan>
              <TextSpan size="xxs" color="textsupertransparent">
                {humanReadifyStringDate(htt.date)}
              </TextSpan>
            </HistoricalTransactionDiv>
          )
        })}
      </div>
    </ContainerDiv>
  )
}
