import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import React from 'react'
import { ColorType, FontSizeType } from 'theme'
import { TextSpan } from './DesignSystem/Typography'

interface Props {
  headerSize?: FontSizeType
  labelSize?: FontSizeType
  iconSize?: FontSizeType
  color?: ColorType
  orientation?: 'horizontal' | 'vertical'
  itemAlignment?: 'center' | 'start'
}
export const SavingsContributionBadge: React.FC<Props> = ({
  headerSize = 'xs',
  labelSize = 'xxs',
  iconSize = 'sm',
  color = 'cashgreen',
  itemAlignment = 'center',
  orientation = 'vertical',
}) => {
  const { mysteryMode } = useMysteryDay()
  return (
    <div
      className={`d-flex flex-${
        orientation === 'vertical' ? 'column' : 'row'
      } align-items-${itemAlignment} position-relative`}>
      <TextSpan
        size={labelSize}
        weight={400}
        color={color}
        style={{
          marginTop: orientation === 'vertical' ? '-.25rem' : 0,
          marginBottom: '0',
          marginLeft: orientation === 'horizontal' ? '.25rem' : 0,
        }}>
        {mysteryMode === 'getOutOfDebt' ? 'Debt' : 'Savings'} Contribution
      </TextSpan>
    </div>
  )
}
