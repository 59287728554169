import { TOP_CONTAINER_PADDING_FOR_NOTCH } from 'Components/DesignSystem/Layout/Containers/helpers'
import styled from 'styled-components/macro'

export const CheckoutContainer = styled.div`
  max-height: 80vh;
  min-height: 80vh;
  overflow-y: scroll;

  position: absolute;
  top: ${(props) => `${TOP_CONTAINER_PADDING_FOR_NOTCH}px`};
  min-width: 100vw;
`
export const StripeContainer = styled.div`
  max-height: 80vh;
  min-height: 80vh;
  overflow-y: scroll;
  z-index: 10;

  position: absolute;
  top: 0;
  min-width: 100vw;

  background: linear-gradient(-45deg, #fefff7, #fefff7, #d6d8cf, #767870);
  background-size: 400% 400%;
  animation: gradient 2s ease infinite;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`
