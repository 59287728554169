import React from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useAsyncLocalStorageState } from 'Hooks/useAsyncLocalStorageState'
import { useUserConfig } from 'Providers/UserConfigProvider/UserConfigProvider'

/**
 * API Interactions:
 * GET All Wishlist Items
 * GET 3 Wishlist Order Lists (small, medium, large)
 * POST New / Existing Wishlist Item (create, edit, mark as purchased)
 * DELETE Wishlist Item
 * POST Wishlist Order List
 */

export interface WishlistItem {
  name: string
  price: string
  reason: string
  date: string
  reminderDate: string
  purchasedDate: string
  resistedDate: string
  priority: number
  desire: number
  timeFactor: boolean
  newnessFactor: boolean
  _id?: string
}

type WishlistItemParam = Omit<WishlistItem, 'price'> & { price: number }

interface WishlistOrderLists {
  small: string[]
  medium: string[]
  large: string[]
}

interface UpdateWishlistOrderListParams {
  type: 'small' | 'medium' | 'large'
  newList: string[]
}

interface WishlistContextState {
  wishlistItems: WishlistItem[] | null
  refreshWishlistItems: () => Promise<void>

  wishlistOrderLists: WishlistOrderLists | null

  refreshWishlistOrderLists: () => Promise<void>
  updateWishlistOrderLists: (params: UpdateWishlistOrderListParams) => Promise<void>

  saveWishlistItem: (wishlistItem: WishlistItemParam) => Promise<void>
  deleteWishlistItem: (name: string) => Promise<void>

  loading: boolean

  getWishlistOrderLoading: boolean
  getWishlistItemsLoading: boolean
  updateWishlistOrderListsLoading: boolean
  saveWishlistItemLoading: boolean
  deleteWishlistItemLoading: boolean
}

const defaultData: WishlistContextState = {
  wishlistItems: [],
  wishlistOrderLists: {
    small: [],
    medium: [],
    large: [],
  },

  refreshWishlistItems: () => Promise.resolve(),
  refreshWishlistOrderLists: () => Promise.resolve(),
  updateWishlistOrderLists: () => Promise.resolve(),

  saveWishlistItem: () => Promise.resolve(),
  deleteWishlistItem: () => Promise.resolve(),

  loading: false,

  getWishlistOrderLoading: false,
  getWishlistItemsLoading: false,
  updateWishlistOrderListsLoading: false,
  saveWishlistItemLoading: false,
  deleteWishlistItemLoading: false,
}

const WishlistContext = React.createContext<WishlistContextState>(defaultData)

export const useWishlist = () => React.useContext(WishlistContext)

/**
 * This provider is responsible for all data related to Wishlist (items, order, saving, updating, etd)
 */
export const WishlistProvider: React.FC = ({ children }) => {
  const { hasSeenAppBefore } = useUserConfig()
  const { user } = useAuth0()

  const {
    data: wishlistItems,
    loading: getWishlistItemsLoading,
    issueRequest: getWishlistItems,
  } = useAsyncLocalStorageState<WishlistItem[], {}, {}>({
    method: 'GET',
    route: 'wishlistitems',
  })

  const {
    data: wishlistOrderLists,
    issueRequest: getWishlistOrderLists,
    loading: getWishlistOrderLoading,
  } = useAsyncLocalStorageState<WishlistOrderLists, {}, {}>({
    method: 'GET',
    route: 'wishlist_order_lists',
  })

  const {
    issueRequest: updateWishlistOrderLists,
    loading: updateWishlistOrderListsLoading,
  } = useAsyncLocalStorageState<WishlistOrderLists, {}, UpdateWishlistOrderListParams>({
    method: 'POST',
    route: 'wishlist_order_lists',
  })

  const {
    issueRequest: saveWishlistItem,
    loading: saveWishlistItemLoading,
  } = useAsyncLocalStorageState<WishlistItem, {}, WishlistItemParam>({
    method: 'POST',
    route: 'wishlistitems',
  })

  const {
    issueRequest: deleteWishlistItem,
    loading: deleteWishlistItemLoading,
  } = useAsyncLocalStorageState<WishlistItem, {}, { name: string }>({
    method: 'DELETE',
    route: 'wishlistitems',
  })

  /* Determine if we request async data on mount of this provider */
  React.useEffect(() => {
    if (hasSeenAppBefore && user) {
      getWishlistItems({})
      getWishlistOrderLists({})
    }
  }, [getWishlistItems, getWishlistOrderLists, hasSeenAppBefore, user])

  return (
    <WishlistContext.Provider
      value={{
        getWishlistOrderLoading,
        getWishlistItemsLoading,
        updateWishlistOrderListsLoading,
        saveWishlistItemLoading,
        deleteWishlistItemLoading,
        loading:
          getWishlistOrderLoading || getWishlistItemsLoading || updateWishlistOrderListsLoading,
        wishlistItems,
        wishlistOrderLists,
        updateWishlistOrderLists: (params) =>
          updateWishlistOrderLists({
            bodyParamsConfig: params,
          }),
        refreshWishlistOrderLists: () => getWishlistOrderLists({}),
        refreshWishlistItems: () => getWishlistItems({}),
        deleteWishlistItem: (name: string) => deleteWishlistItem({ bodyParamsConfig: { name } }),
        saveWishlistItem: (wishlistItem) =>
          saveWishlistItem({
            bodyParamsConfig: wishlistItem,
          }),
      }}>
      {children}
    </WishlistContext.Provider>
  )
}
