import { StyledHr, StyledVr, TextSpan } from 'Components/DesignSystem/Typography'
import { ToggleSwitchThreeway } from 'Components/Toggle/ToggleThreeWay'
import { MoneyInputContainer } from 'Pages/StreamPage/AddEditStream/AddEditStream.components'
import { useUIState } from 'Providers/UIStateProvider'
import {
  CalculationType,
  TransactionMetadata,
  UpdateTransactionMetadataParams,
  UserCustomSettings,
  useUserCustomSettings,
} from 'Providers/UserCustomSettingsProvider/UserCustomSettingsProvider'
import { numberToCurrency } from 'Utilities/currencyFormater'
import { humanReadifyStringDate } from 'Utilities/dateUtilities/dateUtilities'
import { IconButton } from 'design-system/buttons/IconButton/IconButton'
import { Transaction } from 'plaid'
import React from 'react'
import { FILTER_TYPE } from '../AdvancedTransactionView/helpers'
import { FixedStreamAssigner } from './Assigners/FixedStreamAssigner'
import { VariableCategoryPicker } from './Assigners/VariableCategoryPicker'

interface DecorateTransactionPopupProps {
  t: Transaction
  m: TransactionMetadata | undefined
  onSubmit: ({
    notes,
    regret,
    impulse,
    valueAlignment,
    recurring,
    ignoreFromCalculations,
    envelopeCategory,
    calculationType,
    fixedStreamName,
  }: Omit<UpdateTransactionMetadataParams, 'transactionId'>) => Promise<void>
}

export const getExistingCategories = (settings: UserCustomSettings | null, type: FILTER_TYPE) => {
  if (settings) {
    const tm = settings.transactionsMetadata
    if (tm) {
      const variableEnvelopes = Array.from(
        new Set(Object.values(tm).map((tm) => tm.envelopeCategory))
      ).filter((ec) => !!ec)
      const fixedBills = Array.from(
        new Set(Object.values(tm).map((tm) => tm.fixedStreamName))
      ).filter((ec) => !!ec)

      switch (type) {
        case 'fixed':
          return fixedBills.sort()
        case 'variable':
          return variableEnvelopes.sort()
        case 'both':
          return [...fixedBills, ...variableEnvelopes].sort()
      }

      return Array.from(new Set(Object.values(tm).map((tm) => tm.envelopeCategory))).filter(
        (ec) => !!ec
      )
    } else {
      return
    }
  } else {
    return []
  }
}

export const getCreatedCategories = (settings: UserCustomSettings | null) => {
  if (settings) {
    const cm = settings.categoryMetadata
    if (cm) {
      return Array.from(new Set(Object.keys(cm))).filter((ec) => !!ec)
    } else {
      return
    }
  } else {
    return []
  }
}

export const DecorateTransactionPopup: React.FC<DecorateTransactionPopupProps> = ({
  t,
  m,
  onSubmit,
}) => {
  const { setMessagePopupContent } = useUIState()

  const [notes, setNotes] = React.useState<string>(m?.notes || '')
  // eslint-disable-next-line
  const [valueAlignment, setValueAlignment] = React.useState<boolean | undefined>(
    m?.valueAlignment !== undefined ? m.valueAlignment : undefined
  )

  const [envelopeCategory, setEnvelopeCategory] = React.useState<string>(
    m?.envelopeCategory !== undefined ? m.envelopeCategory : ''
  )

  const [calculationType, setCalculationType] = React.useState<CalculationType>(
    m?.calculationType !== undefined ? m.calculationType : 'variable'
  )

  const [fixedStreamName, setFixedStreamName] = React.useState<string>(
    m?.fixedStreamName !== undefined ? m.fixedStreamName : ''
  )

  const valueStateFromCalculationType: 'center' | 'left' | 'right' =
    calculationType === 'fixed' ? 'right' : calculationType === 'variable' ? 'left' : 'center'

  const { updateTransactionMetadataLoading, getSettingsLoading } = useUserCustomSettings()

  const loading = updateTransactionMetadataLoading || getSettingsLoading

  return (
    <div className="d-flex flex-column w-100 h-100 align-items-start pt-4 px-4 justify-content-between">
      <div className="d-flex flex-column w-100 align-items-start ">
        <TextSpan color="text" size="sm" weight={700} className="align-self-start">
          Transaction Reflection
        </TextSpan>
        <StyledHr color="nearlyclear" weight={1} className="my-2" />
        <TextSpan
          size="xs"
          weight={200}
          color="textsupertransparent"
          className="align-self-start px-2">
          Categorize your transaction and reflect on less objective and more emotional aspects.
        </TextSpan>
        <div className="d-flex flex-column w-100 align-items-center justify-content-start mb-3 mt-2">
          <div className="d-flex flex-row w-100 align-items-center justify-content-start ">
            <TextSpan color="text" size="sm" weight={700}>
              {t.logo_url ? (
                <img
                  className="mr-1"
                  alt="institution-logo"
                  height={'20px'}
                  style={{
                    padding: 0,
                    borderRadius: '4px',
                  }}
                  src={t.logo_url}
                />
              ) : null}
              {t.name}
            </TextSpan>
          </div>
          <div className="d-flex flex-row w-100 align-items-center justify-content-start ">
            <TextSpan color="text" size="xs" weight={400}>
              {numberToCurrency(t.amount, true, false)}
            </TextSpan>
            <StyledVr color="text" className="mx-2" weight={1} />
            <TextSpan color="text" size="xs" weight={400}>
              {humanReadifyStringDate(t.date)}
            </TextSpan>
            <StyledVr color="text" className="mx-2" weight={1} />
            <TextSpan color="textsupertransparent" size="xxs" weight={400}>
              {humanReadifyStringDate(t.authorized_date)}
            </TextSpan>
          </div>
          <div className="d-flex flex-row w-100 align-items-center justify-content-start ">
            <TextSpan color="texttransparent" size="xs" weight={400} className="my-0">
              {t.category?.join(', ')}
            </TextSpan>
          </div>
          {/* <AlexOnlyContent>
          <div className="d-flex flex-row w-100 align-items-center justify-content-start ">
            <div className="d-flex flex-row align-items-center">
              <TextSpan size="xxs">
                <code style={{ whiteSpace: 'nowrap' }}>transaction_id: </code>{' '}
              </TextSpan>
              <TextSpan color="textsupertransparent" size="xxxxs" className="my-0">
                {t.transaction_id}
              </TextSpan>
            </div>
          </div>
          <div className="d-flex flex-row align-items-center">
            <TextSpan size="xxs">
              <code style={{ whiteSpace: 'nowrap' }}>pending_transaction_id: </code>{' '}
            </TextSpan>
            <TextSpan color="textsupertransparent" size="xxxxs" className="my-0">
              {t.pending_transaction_id}
            </TextSpan>
          </div>
        </AlexOnlyContent> */}
        </div>
        {/* <StyledHr color="text" weight={1} className="mt-2 mb-5" /> */}

        <div className="d-flex flex-row w-100 align-items-start">
          <TextSpan
            color="textsupertransparent"
            size="xs"
            className="mt-2"
            weight={700}
            style={{
              flex: 2,
            }}>
            What kind of spending was this?
          </TextSpan>
          <div style={{ flex: 1 }}>
            <ToggleSwitchThreeway
              label=""
              leftText={'Variable'}
              leftColor="yellow"
              centerText={'Skip'}
              id={''}
              rightText={'Fixed'}
              rightColor="mehred"
              valueState={valueStateFromCalculationType}
              setValueState={function (value: 'center' | 'left' | 'right'): void {
                switch (value) {
                  case 'left':
                    setCalculationType('skip')
                    return
                  case 'center':
                    setCalculationType('fixed')
                    return
                  case 'right':
                    setCalculationType('variable')
                    return
                }
              }}
            />
          </div>
        </div>

        <StyledHr
          className="my-1"
          color={
            calculationType === 'variable'
              ? 'yellow'
              : calculationType === 'fixed'
              ? 'mehred'
              : 'nearlyclear'
          }
          weight={1}
        />

        {calculationType === 'variable' ? (
          <VariableCategoryPicker
            envelopeCategory={envelopeCategory}
            t={t}
            setEnvelopeCategory={setEnvelopeCategory}
          />
        ) : null}

        {calculationType === 'fixed' ? (
          <FixedStreamAssigner
            fixedStreamName={fixedStreamName}
            transactionName={t.name}
            transactionAmount={t.amount}
            setFixedStreamName={setFixedStreamName}
          />
        ) : null}

        {calculationType === 'skip' ? (
          <TextSpan className="px-4" color="textsupertransparent">
            Skip a transaction if you would like to ignore it from spending calculations. You might
            do this if this transaction is a paycheck deposit, if it's a transfer of funds between
            accounts, or if you simply want to ignore it.
          </TextSpan>
        ) : null}

        <div className="d-flex flex-row align-items-center w-100 justify-content-end mt-0">
          <div className="d-flex flex-column align-items-start px-2 justify-content-start mt-2 w-100">
            {/* {calculationType === 'variable' ? (
              <BinarySelector
                setter={setValueAlignment}
                enabled={valueAlignment}
                title="Did this purchase bring you joy?"
                trueTitle="Absolutely"
                trueIcon="fas fa-face-smiling-hands"
                binaryOnly
                falseIcon="fas fa-face-diagonal-mouth"
                falseTitle="Not really"
                relevant={true}
              />
            ) : null} */}
          </div>
          {/* <StyledVr size="xxxxxl" /> */}
        </div>
      </div>

      <div className="d-flex flex-column w-100 align-items-center">
        <MoneyInputContainer
          icon="f02b"
          label="additional notes / comments"
          className="mt-4"
          color="text"
          fontSizeDefault="xxs"
          fontSizeActive="xs">
          <input
            data-testid="stream-name-input"
            value={notes}
            placeholder=""
            onChange={(e) => setNotes(e.currentTarget.value)}></input>
        </MoneyInputContainer>
        <StyledHr />
        <IconButton
          disabled={
            calculationType === 'variable'
              ? !envelopeCategory
              : calculationType === 'fixed'
              ? !fixedStreamName
              : false
          }
          onDisabledClick={() => {
            setMessagePopupContent(
              'Missing Category<>Please enter a category for this transaction!'
            )
          }}
          onClick={() => {
            onSubmit({
              notes,
              regret: false,
              valueAlignment,
              impulse: false,
              recurring: calculationType === 'fixed' || calculationType === 'skip' ? true : false,
              ignoreFromCalculations: calculationType === 'skip' ? true : false,
              calculationType,
              fixedStreamName,
              envelopeCategory,
            })
          }}
          title={loading ? 'Saving...' : 'Save'}
          buttonClassName="align-self-center mb-3 mt-2"
          color={'logoPrimary'}
          iconClassName={loading ? 'fas fa-sync fa-spin' : 'fas fa-check'}
        />
      </div>
    </div>
  )
}
