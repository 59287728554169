import { animated, config, useSpring } from '@react-spring/web'
import React from 'react'
import { numberToCurrency } from 'Utilities/currencyFormater'

interface Props {
  value: number
  style?: React.CSSProperties
}
export const CurrencyCounter: React.FC<Props> = ({ value, style }) => {
  const props = useSpring({
    val: value,
    from: { val: 0 },
    config: { ...config.gentle },
  })

  return (
    <animated.div style={style}>
      {props.val.to((val) => numberToCurrency(val, true, true))}
    </animated.div>
  )
}
