import { animated, config, useSpring } from '@react-spring/web'
import React from 'react'

import { nanoid } from 'nanoid'

import { useResizeObserver } from 'Hooks/useResizeObservers'

import { Icon } from 'Components/DesignSystem/Typography'
import { ColorType, myTheme } from 'theme'
import {
  CollapseBody,
  CollapseContainer,
  CollapseContent,
  CollapseHeader,
} from './CollapseCard.components'

interface CollapseCardControlledProps {
  /** Defaults to `complementary`, the implicit role created by an `<aside>`. */
  role?: CollapseCardRole
  /**
   * Content included in this alert's `<header>`. As such, the text value of the given content will
   * be part of the element's accessible name, and can be used along with the `role` to select the
   * element in the accessibility tree.
   */
  header: React.ReactNode | ((toggle: Function) => React.ReactNode)
  /**
   * Optional content that will be rendered adjacent to the header. Typically contains controls
   * that perform actions relevant to the card.
   */
  controls?: React.ReactNode
  /** The body content of the card. */
  body: React.ReactNode
  /** Adds a drop shadow. */
  elevated?: boolean
  className?: string
  style?: React.CSSProperties
  /** Bound to the outermost `<div>`. */
  ref?: React.ForwardedRef<HTMLDivElement>

  skipBottomBorder?: boolean

  beenVisited?: boolean

  isCollapsed: boolean
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>

  growFromBottom?: boolean
  activeColor?: ColorType
  passiveColor?: ColorType
}
type CollapseCardRole = 'complementary' | 'alert' | 'alertdialog'

/*
 TODO: 
 - potentially lift the collapsed state and setter into a prop so that consumer can control that 
 - performance: don't animate height but instead use transform to do magic
 - ensure this works within <AnimatedFlex/>
*/
const _CollapseCardControlled: React.ForwardRefRenderFunction<
  HTMLDivElement,
  CollapseCardControlledProps
> = (
  {
    role = 'complementary',
    header,
    controls,
    body,
    elevated,
    className,
    style,

    activeColor = 'texttransparent',
    passiveColor = 'texttransparent',
    growFromBottom,
    skipBottomBorder,
    isCollapsed,
    beenVisited = 'true',
    setIsCollapsed,
  },
  ref
) => {
  const idBase = React.useMemo(() => `CollapseCard_${nanoid(10)}`, [])
  const headerId = `${idBase}_header`
  const bodyId = `${idBase}_body`

  const [measurements, resizeRef] = useResizeObserver()

  const toggleCollapsed = () => {
    setIsCollapsed((prevState) => !prevState)
  }

  const toggleWrapperAnimatedStyle = useSpring({
    transform: isCollapsed
      ? growFromBottom
        ? 'rotate(180deg)'
        : 'rotate(0deg)'
      : growFromBottom
      ? 'rotate(0deg)'
      : 'rotate(-180deg)',
    config: () => (isCollapsed ? config.default : config.default),
  })

  const panelContentAnimatedStyle = useSpring({
    height: isCollapsed ? 0 : measurements?.block,
    config: () => (isCollapsed ? config.default : config.default),
  })

  return (
    <CollapseContainer
      role={role}
      aria-labelledby={headerId}
      aria-describedby={bodyId}
      ref={ref}
      style={style}>
      <CollapseContent
        style={{
          transformOrigin: growFromBottom ? '50% 100%' : '',
        }}
        isCollapsed={isCollapsed}
        skipBottomBorder={skipBottomBorder}>
        <CollapseHeader
          data-testid="collapse-header"
          onClick={typeof header === 'function' ? () => {} : toggleCollapsed}>
          <header id={headerId}>
            {typeof header === 'function' ? header(toggleCollapsed) : header}
          </header>

          <animated.div
            style={{
              ...toggleWrapperAnimatedStyle,
              maxHeight: myTheme.fonts.sizes.xl,
              marginLeft: '.5rem',
              marginRight: '.5rem',
              padding: '.5rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            {typeof header === 'function' ? null : (
              <Icon
                color={!isCollapsed ? activeColor : passiveColor}
                style={{
                  opacity: beenVisited ? 1 : 1,
                  transition: 'opacity 0.2s',
                  // textShadow: 'rgba(0, 0, 0, .5) 0 2px 2px',
                }}
                size="xl"
                className={'far fa-chevron-circle-down'}
              />
            )}
          </animated.div>
        </CollapseHeader>
        <CollapseBody
          id={bodyId}
          style={{
            ...panelContentAnimatedStyle,
            transformOrigin: growFromBottom ? '50% 100%' : '',
          }}>
          <div style={{ marginBottom: 0 }} ref={resizeRef}>
            {body}
          </div>
        </CollapseBody>
      </CollapseContent>
    </CollapseContainer>
  )
}
_CollapseCardControlled.displayName = 'CollapseCardControlled'

/**
 * A simple collapsing card combining an icon, a heading, a chevron, and a body (message or content).
 * Can be augmented with controls, customized with `className` and `style`, and given an `elevated`
 * state which is styled within the Design System theme.
 */
export const CollapseCardControlled = React.forwardRef(_CollapseCardControlled)
