import { Icon, StyledVr, TextSpan } from 'Components/DesignSystem/Typography'
import { SettingsItem } from 'Pages/SettingsPage/components'
import { useFinancialData } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import { getAccountTypeFromTypeSubtypeTuple } from 'Providers/FinancialDataProvider/helpers'
import { useRefreshAllTransactions } from 'Utilities/multipleTransactionsRefresh'
import React from 'react'

export const RefreshTransactions: React.FC = () => {
  const { accountsData } = useFinancialData()
  const itemIds =
    accountsData.data?.accounts
      ?.filter((ad) =>
        ad.accounts.some(
          (a) =>
            getAccountTypeFromTypeSubtypeTuple([a.type, a.subtype]) === 'Cash' ||
            getAccountTypeFromTypeSubtypeTuple([a.type, a.subtype]) === 'Credit Card'
        )
      )
      ?.filter((ad) => ad.item.institution_id !== 'ins_35')
      ?.map((ad) => ad.item.item_id) || []

  const banks =
    accountsData.data?.accounts
      ?.filter((ad) =>
        ad.accounts.some(
          (a) =>
            getAccountTypeFromTypeSubtypeTuple([a.type, a.subtype]) === 'Cash' ||
            getAccountTypeFromTypeSubtypeTuple([a.type, a.subtype]) === 'Credit Card'
        )
      )
      ?.filter((ad) => ad.item.institution_id !== 'ins_35')
      ?.map((ad) => ad.item.institution_id) || []

  const banksHydrated =
    accountsData.data?.institutions?.filter((i) => banks.includes(i.institution.institution_id)) ||
    []

  const { issueRequest, requestStatuses } = useRefreshAllTransactions({ itemIds })

  const liveTransactionsLoading = Object.values(requestStatuses).some(
    (rs) => rs.status === 'fetching'
  )

  return (
    <SettingsItem
      onClick={() => {
        issueRequest()
      }}>
      <div className="d-flex flex-column w-100">
        <TextSpan>
          <Icon className={`mr-2 fas fa-sync ${liveTransactionsLoading ? 'fa-spin' : ''}`} />
          Refresh Latest Transactions
        </TextSpan>
        <div
          className="d-flex align-items-center justify-content-start w-100"
          style={
            {
              overflowX: 'scroll',
              '-webkit-overflow-scrolling': 'touch',
            } as React.CSSProperties
          }>
          {banksHydrated.map((bh) => {
            return (
              <div className="d-flex flex-row">
                <TextSpan
                  className="mx-1"
                  size="xxs"
                  color="textsupertransparent"
                  style={{
                    whiteSpace: 'nowrap',
                  }}>
                  {' '}
                  {bh.institution.name}
                </TextSpan>
                <StyledVr />
              </div>
            )
          })}
        </div>
      </div>
    </SettingsItem>
  )
}
