import { CollapseCardControlled } from 'Components/CollapseCard/CollapseCardControlled'
import { ScrollingFull } from 'Components/DesignSystem/Layout/Containers/Containers'
import { Icon, TextSpan } from 'Components/DesignSystem/Typography'
import { SPLURV_LOCAL_STORAGE_PREFIX } from 'Hooks/useLocalStorageSync'
import React, { useEffect } from 'react'

import { useLocalStorageSyncedState } from 'Hooks/useLocalStorageSyncedState'
import { useUserConfig } from 'Providers/UserConfigProvider/UserConfigProvider'

import { WIZARDRY_ICON } from 'Utilities/navigationTabUtilities'
import { FontSizeType } from 'theme'
import { BankOfAmericaLogo, ChimeLogo, SoFiLogo, SpendingSignal } from './ToolStepHelpers'
import plaidLogo from './plaid-logo.png'

type PanelType = 'onboarding' | 'configuration' | 'spending' | 'wishlist'

export const ToolSteps: React.FC = () => {
  const [openPanel, setOpenPanel] = React.useState<PanelType | null>(null)

  const { setAppOverviewPageSeen } = useUserConfig()

  // const [panelsSeen, setPanelsSeen] = React.useState<PanelType[]>([])
  const [panelsSeen, setPanelsSeen] = useLocalStorageSyncedState<PanelType[]>(
    [],
    'app-page-panels-seen'
  )

  const allPanelsSeen = panelsSeen.length === 4

  useEffect(() => {
    if (allPanelsSeen) {
      setAppOverviewPageSeen(true)
    }
  }, [allPanelsSeen, setAppOverviewPageSeen])

  const numberLogoSize: FontSizeType = 'lg'
  const textSize: FontSizeType = 'md'

  return (
    <ScrollingFull cutout={250}>
      <div className="flex-column d-flex w-100 align-items-start mt-2">
        <CollapseCardControlled
          activeColor="text"
          beenVisited={panelsSeen.includes('onboarding')}
          passiveColor="text"
          isCollapsed={openPanel !== 'onboarding'}
          setIsCollapsed={() => {
            setOpenPanel((openPanel) => (openPanel === 'onboarding' ? null : 'onboarding'))
            setPanelsSeen((panelsSeen) => {
              const newArray: PanelType[] = [...panelsSeen, 'onboarding']
              const newSet = new Set(newArray)
              const finalArray = Array.from(newSet)
              localStorage.setItem(
                `${SPLURV_LOCAL_STORAGE_PREFIX}_method_panels_seen`,
                JSON.stringify(finalArray)
              )
              return finalArray
            })
          }}
          skipBottomBorder
          header={
            <div className="flex-row d-flex w-100 align-items-center my-0">
              <Icon className="fas fa-flag mr-2" size={numberLogoSize} color="text" />
              <TextSpan color="text" size={textSize}>
                Onboarding
              </TextSpan>
            </div>
          }
          body={
            <div className="flex-column d-flex w-100 align-items-center py-2 w-100">
              <TextSpan color="text" size="sm" className="mb-1 w-100" style={{ display: 'inline' }}>
                The beginning of your journey:
                <ul style={{ paddingLeft: '1rem' }} className="w-100">
                  <li className="my-2">
                    <img
                      src={plaidLogo}
                      style={{
                        maxHeight: '32px',
                        marginRight: '.5rem',
                      }}
                      alt="test"
                    />
                    Live account linking
                  </li>
                  <li className="my-2">
                    {' '}
                    <Icon color="mehred" size="lg" className="fas fa-flame mr-1" />
                    First MCOL estimate
                  </li>
                  <li className="my-2">
                    {' '}
                    <Icon color="softblue" size="lg" className={`${WIZARDRY_ICON} mr-1`} /> Variable
                    spend wizard analysis
                  </li>
                </ul>
              </TextSpan>
            </div>
          }
        />
        <CollapseCardControlled
          skipBottomBorder
          beenVisited={panelsSeen.includes('configuration')}
          isCollapsed={openPanel !== 'configuration'}
          setIsCollapsed={() => {
            setOpenPanel((openPanel) => (openPanel === 'configuration' ? null : 'configuration'))
            setPanelsSeen((panelsSeen) => {
              const newArray: PanelType[] = [...panelsSeen, 'configuration']
              const newSet = new Set(newArray)
              const finalArray = Array.from(newSet)
              localStorage.setItem(
                `${SPLURV_LOCAL_STORAGE_PREFIX}_method_panels_seen`,
                JSON.stringify(finalArray)
              )
              return finalArray
            })
          }}
          activeColor="text"
          passiveColor="text"
          header={
            <div className="flex-row d-flex w-100 align-items-center my-2">
              <Icon className="fas fa-line-chart mr-2" size={numberLogoSize} color="text" />
              <TextSpan color="text" size={textSize}>
                Time Dashboard
              </TextSpan>
            </div>
          }
          body={
            <div className="flex-column d-flex w-100 align-items-start py-2">
              <TextSpan color="text" size="sm" className="mb-1" style={{ display: 'inline' }}>
                Where you will see the big picture:
                <ul style={{ paddingLeft: '1rem' }}>
                  <li className="my-2">
                    {' '}
                    <Icon color="softblue" size="lg" className="fas fa-hourglass-clock mr-1" />
                    See how far away your goal is
                  </li>
                  <li className="my-2">
                    {' '}
                    <Icon color="seconddark" size="lg" className="far fa-sliders mr-1" />
                    Understand what you can do to get there faster
                  </li>
                </ul>
              </TextSpan>
            </div>
          }
        />
        <CollapseCardControlled
          activeColor="text"
          beenVisited={panelsSeen.includes('spending')}
          passiveColor="text"
          isCollapsed={openPanel !== 'spending'}
          setIsCollapsed={() => {
            setOpenPanel((openPanel) => (openPanel === 'spending' ? null : 'spending'))
            setPanelsSeen((panelsSeen) => {
              const newArray: PanelType[] = [...panelsSeen, 'spending']
              const newSet = new Set(newArray)
              const finalArray = Array.from(newSet)
              localStorage.setItem(
                `${SPLURV_LOCAL_STORAGE_PREFIX}_method_panels_seen`,
                JSON.stringify(finalArray)
              )
              return finalArray
            })
          }}
          skipBottomBorder
          header={
            <div className="flex-row d-flex w-100 align-items-center my-2">
              <Icon
                className="fas fa-light-emergency-on  mr-2"
                size={numberLogoSize}
                color="text"
              />
              <TextSpan color="text" size={textSize}>
                Spending Signals
              </TextSpan>
            </div>
          }
          body={
            <div className="flex-column d-flex w-100 align-items-start py-2">
              <TextSpan color="text" size="sm" className="mb-1" style={{ display: 'inline' }}>
                Nickname your accounts, setup limits, and understand exactly what you can and can't
                spend on at the start of every day.
              </TextSpan>
              <ul style={{ paddingLeft: '1rem' }}>
                <li className="my-3">
                  <SpendingSignal
                    balance={181}
                    nickname={'coffee & takeout'}
                    status={'onpace'}
                    limit={250}
                    bankLogo={<ChimeLogo />}
                  />
                </li>

                <li className="my-3">
                  <SpendingSignal
                    balance={60}
                    nickname={'groceries'}
                    status={'slowdown'}
                    limit={1000}
                    bankLogo={<SoFiLogo />}
                  />
                </li>
                <li className="my-3">
                  <SpendingSignal
                    balance={8}
                    nickname={'fun'}
                    status={'stop'}
                    limit={100}
                    bankLogo={<BankOfAmericaLogo />}
                  />
                </li>
              </ul>
            </div>
          }
        />

        <CollapseCardControlled
          skipBottomBorder
          beenVisited={panelsSeen.includes('wishlist')}
          passiveColor="text"
          activeColor="text"
          isCollapsed={openPanel !== 'wishlist'}
          setIsCollapsed={() => {
            setOpenPanel((openPanel) => (openPanel === 'wishlist' ? null : 'wishlist'))
            setPanelsSeen((panelsSeen) => {
              const newArray: PanelType[] = [...panelsSeen, 'wishlist']
              const newSet = new Set(newArray)
              const finalArray = Array.from(newSet)
              localStorage.setItem(
                `${SPLURV_LOCAL_STORAGE_PREFIX}_method_panels_seen`,
                JSON.stringify(finalArray)
              )
              return finalArray
            })
          }}
          header={
            <div className="flex-row d-flex w-100 align-items-center my-2">
              <Icon className="fas fa-gift mr-2" size={numberLogoSize} color="text" />
              <TextSpan color="text" size={textSize}>
                Impulse Resisting
              </TextSpan>
            </div>
          }
          body={
            <div className="flex-column d-flex w-100 align-items-start py-2">
              <TextSpan color="text" size="sm" className="mb-1 w-100" style={{ display: 'inline' }}>
                <ul style={{ paddingLeft: '1rem' }} className="w-100">
                  <li className="my-2">
                    {' '}
                    <Icon
                      color="texttransparent"
                      size="lg"
                      className="fa-kit fa-regular-gift-slash mr-1"
                    />
                    <Icon color="texttransparent" size="lg" className="far fa-arrow-right mx-1" />
                    <Icon color="megapurple" size="lg" className="far fa-trophy-star mr-1" />
                    Gamify interrupting consumerism habits through the Impulse Purchase Conquer List
                  </li>
                </ul>
              </TextSpan>
            </div>
          }
        />
      </div>
    </ScrollingFull>
  )
}
