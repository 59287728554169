import { IconButton } from 'design-system/buttons/IconButton/IconButton'
import { SlidingPage } from 'design-system/layout/SlidingPage/SlidingPage'
import React, { useCallback, useEffect } from 'react'
import { colorPalletes } from 'theme'
import { v4 as uuidv4 } from 'uuid'
import { Icon, StyledVr, TextSlim, TextSpan } from '../../Components/DesignSystem/Typography'
import { useFinancialData } from '../../Providers/FinancialDataProvider/FinancialDataProvider'
import {
  SIMPLIFIED_ACCOUNT_TYPES,
  SimplifiedAccountTypes,
  getColorFromAccountType,
  getDisplayNameFromAccountType,
  getIconFromAccountType,
} from '../../Providers/FinancialDataProvider/helpers'
import { useMysteryDay } from '../../Providers/MysteryDayProvider/MysteryDayProvider'
import { useUIState } from '../../Providers/UIStateProvider'
import { cleanCurrency, formatCurrency } from '../../Utilities/currencyFormater'
import { MoneyInputContainer } from '../StreamPage/AddEditStream/AddEditStream.components'
import { fireConfetti } from '../WishlistPage/Confetti'
import { AddManualTransactionPopup } from './AddManualTransactionPopup'
import { ManualTransactionRow } from './ManualTransactionRow'

export const AddManualAccountPage: React.FC = () => {
  const { setAddManualAccountPageOpen, addManualAccountPageOpen, setAddCardPageOpen } = useUIState()

  const { activeTheme, setPopup } = useUIState()

  const {
    saveManualAccountData,
    getManualAccounts,
    getManualAccountsLoading,
    deleteManualAccountData,
    deleteManualTransaction,
  } = useFinancialData()

  const loading = getManualAccountsLoading || saveManualAccountData?.res.status === 'loading'

  const { unlockPage } = useMysteryDay()

  const [name, setName] = React.useState<string>('')
  const [amount, _setAmount] = React.useState<string>('')
  const [type, setType] = React.useState<SimplifiedAccountTypes>('Savings')
  const existingAccount = addManualAccountPageOpen ? addManualAccountPageOpen.name !== '' : false

  const setAmount = useCallback(
    (amount: string) => {
      const formattedValue = formatCurrency(
        amount,
        10,
        type === 'Debt' || type === 'Credit Card' ? 'positive' : 'positive'
      )
      _setAmount(formattedValue)
    },
    [type]
  )

  const color = getColorFromAccountType(type)

  useEffect(() => {
    if (addManualAccountPageOpen && existingAccount) {
      setName(addManualAccountPageOpen.name)

      const formattedValue = formatCurrency(
        String(addManualAccountPageOpen.amount),
        10,
        addManualAccountPageOpen.type === 'Debt' || addManualAccountPageOpen.type === 'Credit Card'
          ? 'negative'
          : 'positive'
      )

      _setAmount(formattedValue)
      setType(addManualAccountPageOpen.type)
    }
  }, [addManualAccountPageOpen, setName, _setAmount, setType, existingAccount])

  const onSaveClick = async () => {
    if (name === '') {
      alert('Please enter a name')
      return
    }
    if (amount === '') {
      alert('Please enter an amount')
      return
    }
    await saveManualAccountData?.issueRequest({
      bodyParamsConfig: {
        name,
        amount: cleanCurrency(amount),
        type,
        account_id: `MANUAL_ACCOUNT_${name}_${uuidv4()}`,
        transactions:
          existingAccount && addManualAccountPageOpen
            ? addManualAccountPageOpen?.transactions || []
            : [],
        _id: addManualAccountPageOpen ? addManualAccountPageOpen?._id : undefined,
      },
    })
    await getManualAccounts({})
    setAddManualAccountPageOpen(false)
    setAddCardPageOpen(false)
    fireConfetti({
      colors: [colorPalletes[activeTheme][color]],
      duration: 0.3,
    })
    setTimeout(() => {
      unlockPage(['/creditcards'])
    }, 1000)
  }

  const title = `${existingAccount ? 'Edit' : 'Add'} Manual Account`
  const subtitle =
    'You can manually track bank accounts you do not wish to connect to through Plaid here'

  const primaryButton = {
    disabled: loading,
    title: `${
      existingAccount ? (loading ? 'updating...' : 'update') : loading ? 'saving...' : 'save'
    }`,
    icon: `${existingAccount ? 'fas fa-check' : 'fas fa-plus'} ${loading ? 'fa-beat-fade' : ''}`,
    onClick: () => onSaveClick(),
  }

  const secondaryButton = {
    title: 'cancel',
    icon: 'fas fa-times',
    onClick: () => setAddManualAccountPageOpen(false),
  }

  const deleteButton = {
    title: deleteManualAccountData?.res.status === 'loading' ? 'deleting...' : 'delete',
    icon: 'fas fa-trash',
    onClick: async () => {
      await deleteManualAccountData?.issueRequest({
        bodyParamsConfig: {
          name,
        },
      })
      await getManualAccounts({})
      setAddManualAccountPageOpen(false)
    },
  }

  return (
    <SlidingPage
      open={addManualAccountPageOpen !== false}
      title={title}
      subtitle={subtitle}
      content={
        <>
          <TextSlim className="my-1" color="texttransparent" size="md">
            Account Type
          </TextSlim>
          <div className="d-flex align-items-center w-100 justify-content-around pb-2 mb-5">
            {SIMPLIFIED_ACCOUNT_TYPES.map((accountType, index) => {
              return (
                <div
                  key={accountType}
                  className="d-flex flex-row justify-content-center align-items-center"
                  style={{
                    padding: '0 .25rem',
                    flex: 1,
                    opacity: type === accountType ? 1 : 0.5,
                  }}>
                  <div
                    onClick={() => setType(accountType)}
                    className="d-flex flex-column align-items-center ">
                    <TextSpan
                      className="mb-1"
                      color={getColorFromAccountType(accountType)}
                      weight={700}
                      size="xs">
                      {getDisplayNameFromAccountType(accountType)}{' '}
                    </TextSpan>
                    <div>
                      <div className="d-flex flex-column align-items-center ">
                        <Icon
                          className={`${getIconFromAccountType(accountType)}`}
                          color={getColorFromAccountType(accountType)}
                          size="md"
                        />
                      </div>
                    </div>
                  </div>
                  {index !== 3 ? <StyledVr className="mx-2" /> : null}
                </div>
              )
            })}
          </div>
          <MoneyInputContainer size="md" color={color} className="mb-5" icon="f02b" label={'Name'}>
            <input
              data-testid="stream-amount-input"
              inputMode="text"
              value={name}
              placeholder={'House, wedding, trip'}
              onChange={(e) => setName(e.currentTarget.value)}></input>
          </MoneyInputContainer>
          <MoneyInputContainer
            size="md"
            color={color}
            className="mb-5"
            icon="f155"
            label={type === 'Credit Card' ? 'Balance' : 'Amount'}>
            <input
              data-testid="stream-amount-input"
              inputMode="decimal"
              value={amount}
              placeholder={'5,000'}
              onChange={(e) => setAmount(e.currentTarget.value)}></input>
          </MoneyInputContainer>

          {addManualAccountPageOpen && existingAccount ? (
            <div className="justify-content-start align-items-center justify-content-between py-1">
              <div className="d-flex flex-row w-100 align-items-center">
                <TextSlim className="my-1" color="texttransparent" size="md">
                  Manual Transactions{' '}
                </TextSlim>
                <StyledVr className="mx-2" weight={1} color="logoSecondary" />
                <IconButton
                  title="Add"
                  size="medium"
                  iconClassName="fas fa-plus"
                  color="logoSecondary"
                  onClick={() => {
                    setPopup(<AddManualTransactionPopup account={addManualAccountPageOpen} />)
                  }}
                  orientation="horizontal"
                />
              </div>
              <div
                style={{
                  maxHeight: `calc('50%')`,
                  overflowY: 'scroll',
                }}>
                {addManualAccountPageOpen.transactions?.map((transaction) => {
                  return (
                    <ManualTransactionRow
                      transaction={transaction}
                      account={addManualAccountPageOpen}
                      deleteManualTransaction={async (id: string) => {
                        await deleteManualTransaction({
                          manualTransactionId: id,
                          accountId: addManualAccountPageOpen.account_id,
                        })
                        await getManualAccounts({})
                        setAddManualAccountPageOpen(false)
                      }}
                    />
                  )
                })}
              </div>
            </div>
          ) : null}
        </>
      }
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      deleteButton={existingAccount ? deleteButton : undefined}
    />
  )
}
