import { NewAccountCTA } from 'Components/NewAccountCTA/NewAccountCTA'
import { useUIState } from 'Providers/UIStateProvider'
import { IconButton } from 'design-system/buttons/IconButton/IconButton'
import React from 'react'
import { ContainerCutout } from '../../Components/DesignSystem/Layout/Containers/Containers'
import { StyledHr, TextSpan } from '../../Components/DesignSystem/Typography'

const styledHrHeight = 9
const labelVerticalMargins = 16
export const creditCardPageHeaderSize = 130 + styledHrHeight + labelVerticalMargins

export const CreditCardPageHeader: React.FC = () => {
  const { setSettingsPageOpen, setAccountsPageOpen } = useUIState()
  return (
    <ContainerCutout size={creditCardPageHeaderSize} className="w-100">
      <div className="d-flex flex-row w-100 align-items-start justify-content-between ">
        <TextSpan size="lg" color="textsupertransparent">
          Accounts
        </TextSpan>
        <IconButton
          color="textsupertransparent"
          size="medium"
          title="Back"
          iconClassName="fas fa-arrow-right"
          onClick={() => {
            setAccountsPageOpen(false)
            setSettingsPageOpen(true)
          }}
        />
      </div>
      <div className="d-flex flex-row w-100 align-items-center justify-content-around ">
        <NewAccountCTA />
        {/* <StyledVr weight={1} className="mx-4" size="xl" color="textsupertransparent" /> */}
      </div>
      <StyledHr weight={1} color="nearlyclear" className="my-1" />
    </ContainerCutout>
  )
}
