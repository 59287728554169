import { Icon, TextSlim } from 'Components/DesignSystem/Typography'
import { useUIState } from 'Providers/UIStateProvider'
import React from 'react'
import styled from 'styled-components/macro'
import { ColorType, colorPalletes } from 'theme'

interface NewItemPickProps {
  onClick: () => void
  activeColor: ColorType
  title: string
  slim?: boolean
}

export const NewItemAdder: React.FC<NewItemPickProps> = ({
  onClick,
  activeColor,
  title,
  slim = false,
}) => {
  const { activeTheme } = useUIState()
  return (
    <div className="d-flex align-items-center mt-1">
      <TextSlim
        onClick={() => {
          onClick()
        }}
        style={{
          backgroundColor: colorPalletes[activeTheme].backgrounddark,
          color: colorPalletes[activeTheme][activeColor],
          borderRadius: '4px',
          whiteSpace: 'nowrap',
          border: `solid 1px ${colorPalletes[activeTheme][activeColor]}`,
        }}
        className={slim ? 'my-1 px-1' : 'my-1 ml-1 px-3 py-1 mx-2'}
        color={activeColor}
        size={slim ? 'xxs' : 'xs'}
        weight={700}>
        Create New
        <Icon className="fas fa-plus-circle ml-1" color={activeColor} size={slim ? 'xxs' : 'xs'} />
      </TextSlim>
    </div>
  )
}

interface ItemToBePicked {
  title: string
  metaData: string | React.ReactElement
  activeColor: ColorType
  isActive: boolean
  onClick: (item: string) => void
  slim?: boolean
}

export const ItemPick: React.FC<ItemToBePicked> = ({
  onClick,
  activeColor,
  title,
  isActive,
  metaData,
  slim = false,
}) => {
  const { activeTheme } = useUIState()
  return (
    <div className="d-flex align-items-center">
      <TextSlim
        onClick={() => {
          onClick(title)
        }}
        style={{
          backgroundColor: isActive
            ? colorPalletes[activeTheme][activeColor]
            : colorPalletes[activeTheme].text,
          borderRadius: '4px',
          whiteSpace: 'nowrap',
          maxWidth: '40vw',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
        className={slim ? 'my-1 px-1' : 'my-1 ml-0 px-2 py-1 mr-2'}
        color={'background'}
        size={slim ? 'xxs' : 'xxs'}
        weight={700}>
        {title}
        <Icon
          className="fas fa-check-circle ml-1"
          color="background"
          size={slim ? 'xxs' : 'xs'}
          style={{
            opacity: isActive ? 1 : 0,
          }}
        />
      </TextSlim>
      {isActive ? metaData : null}
    </div>
  )
}

const ItemScrollingContainer = styled.div<{ slim?: boolean; containerHeight: number }>`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  overflow-y: scroll;
  max-height: ${(props) => (props.slim ? '80px' : `calc(${props.containerHeight}px - 72px)`)};
  -webkit-overflow-scrolling: touch;
`

interface ScrollingItemPickerDisplayProps {
  items: ItemToBePicked[]
  onCreateNewItemClick: () => void
  activeColor: ColorType
  title: string
  slim?: boolean
  containerHeight: number
}
export const ScrollingItemPickerDisplay: React.FC<ScrollingItemPickerDisplayProps> = ({
  items,
  activeColor,
  onCreateNewItemClick,
  title,
  slim = false,
  containerHeight,
}) => {
  return (
    <>
      <ItemScrollingContainer slim={slim} containerHeight={containerHeight}>
        {items
          ?.sort((itemA, itemB) => (itemA.title > itemB.title ? 1 : -1))
          ?.map((item) => {
            const { metaData, title, onClick, activeColor, isActive } = item
            return (
              <ItemPick
                slim={slim}
                activeColor={activeColor}
                isActive={isActive}
                metaData={metaData}
                onClick={onClick}
                title={title}
              />
            )
          })}
      </ItemScrollingContainer>
      <NewItemAdder
        onClick={() => onCreateNewItemClick()}
        activeColor={activeColor}
        title={title}
        slim={slim}
      />
    </>
  )
}
