import React from 'react'
import { SpringConfig, State, Transition } from 'react-spring/renderprops'
import { ColorType } from 'theme'
import {
  TransitionedPageContainerFixedHeight,
  TransitionedPageContainerInApp,
} from '../../Pages/StreamPage/AddEditStream/AddEditStream.components'
import { Icon } from '../DesignSystem/Typography'
import { TransitionedPageContainer } from './components'

type TransitionTypes = 'FROM_ABOVE' | 'FROM_BELOW' | 'FROM_RIGHT' | 'FROM_LEFT'

const TransitionTypeMap: { [index in TransitionTypes]: any } = {
  FROM_ABOVE: {
    from: { opacity: 1, transform: 'translate3d(0,-100%,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 1, transform: 'translate3d(0,-100%,0)' },
  },
  FROM_BELOW: {
    from: { opacity: 1, transform: 'translate3d(0,100%,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 1, transform: 'translate3d(0,100%,0)' },
  },
  FROM_LEFT: {
    from: { opacity: 1, transform: 'translate3d(-100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 1, transform: 'translate3d(-100%,0,0)' },
  },
  FROM_RIGHT: {
    from: { opacity: 1, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 1, transform: 'translate3d(100%,0,0)' },
  },
}

interface Props {
  show: boolean
  close?: () => void
  config?: SpringConfig | ((item: unknown, type: State) => SpringConfig) | undefined
  size?: 'fullscreen' | 'inapp' | 'fixed-height'
  maxContainerHeight?: number
  iconColor?: ColorType
  zIndex?: number
  position?: 'absolute' | 'fixed'
  customTransitionSettings?: any
  style?: React.CSSProperties
}

interface TransitionWrapperComponentProps extends Props {
  transitionConfig: TransitionTypes
}

const TransitionWrapperComponent: React.FC<TransitionWrapperComponentProps> = ({
  show,
  config,
  close,
  children,
  size = 'fullscreen',
  position = 'fixed',
  zIndex,
  iconColor,
  transitionConfig,
  customTransitionSettings,
  style = {},
  ...rest
}) => {
  const ComponentToWrap =
    size === 'fullscreen'
      ? TransitionedPageContainer
      : size === 'fixed-height'
      ? TransitionedPageContainerFixedHeight
      : TransitionedPageContainerInApp
  const transitionConfigOption = TransitionTypeMap[transitionConfig]
  return (
    <Transition
      items={show}
      config={config || { mass: 1, tension: 170, friction: 26, precision: 0.01, velocity: 0 }}
      {...transitionConfigOption}
      {...customTransitionSettings}>
      {(show) =>
        show &&
        ((props) => (
          <ComponentToWrap
            {...rest}
            style={{ ...props, zIndex: zIndex || 201, position: position, ...style }}>
            {children}

            {close ? (
              <Icon
                style={{
                  position: 'absolute',

                  textShadow: 'rgb(99 99 99) 0 0px 9px',
                  bottom: '4rem',
                  alignSelf: 'center',
                  zIndex: 5000,
                  width: '2rem',
                  height: '2rem',
                }}
                className="fas fa-chevron-circle-up"
                color={iconColor}
                size="xxxl"
                onClick={() => close()}
              />
            ) : null}
          </ComponentToWrap>
        ))
      }
    </Transition>
  )
}

export const TransitionFromRight: React.FC<Props> = (props) => {
  return <TransitionWrapperComponent {...props} transitionConfig="FROM_RIGHT" />
}

export const TransitionFromLeft: React.FC<Props> = (props) => {
  return <TransitionWrapperComponent {...props} transitionConfig="FROM_LEFT" />
}

export const TransitionFromAbove: React.FC<Props> = (props) => {
  return <TransitionWrapperComponent {...props} transitionConfig="FROM_ABOVE" />
}

export const TransitionFromBelow: React.FC<Props> = (props) => {
  return <TransitionWrapperComponent {...props} transitionConfig="FROM_BELOW" />
}
