import {
  MysteryDayType,
  MysteryPageType,
} from 'Providers/MysteryDayProvider/MysteryDayProvider.interfaces'

interface OnboardingDatum {
  page: MysteryPageType | ((mysteryMode: MysteryDayType) => MysteryPageType)
  previousPage: MysteryPageType | undefined | ((mysteryMode: MysteryDayType) => MysteryPageType)
  nextPage: MysteryPageType | undefined | ((mysteryMode: MysteryDayType) => MysteryPageType)
  index: number
  icon: string | ((mysteryMode: MysteryDayType) => string)
}

export const OnboardingLinkedList: OnboardingDatum[] = [
  {
    icon: (mysteryMode) => {
      switch (mysteryMode) {
        case 'getOrganized':
          return 'fas fa-regular-face-smile'
        case 'saveToQuit':
          return 'fas fa-rocket'
        case 'getOutOfDebt':
          return 'fas fa-sun'
        case 'buildCushion':
          return 'fas fa-clouds'
        case 'default':
          return 'fas fa-arrow-right-to-arc'
      }
    },
    page: 'mode-selection',
    index: 0,
    previousPage: undefined,
    nextPage: (mysteryMode) => {
      switch (mysteryMode) {
        case 'getOrganized':
          return 'cost-of-item'
        case 'saveToQuit':
          return 'desired-time-off'
        case 'getOutOfDebt':
          return 'debt-amount-input'
        case 'buildCushion':
          return 'desired-cushion'
        case 'default':
          return 'mode-selection'
      }
    },
  },
  {
    icon: (mysteryMode) => {
      switch (mysteryMode) {
        case 'getOrganized':
          return 'fas fa-party-horn'
        case 'saveToQuit':
          return 'fas fa-rocket'
        case 'getOutOfDebt':
          return 'fas fa-sun'
        case 'buildCushion':
          return 'fas fa-clouds'
        case 'default':
          return 'fas fa-arrow-right-to-arc'
      }
    },
    page: (mysteryMode) => {
      switch (mysteryMode) {
        case 'getOrganized':
          return 'cost-of-item'
        case 'saveToQuit':
          return 'desired-time-off'
        case 'getOutOfDebt':
          return 'debt-amount-input'
        case 'buildCushion':
          return 'desired-cushion'
        case 'default':
          return 'mode-selection'
      }
    },
    index: 1,
    previousPage: 'mode-selection',
    nextPage: 'paycheck-or-income',
  },
  {
    icon: 'fas fa-sack-dollar',
    page: 'paycheck-or-income',
    index: 2,
    previousPage: (mysteryMode) => {
      switch (mysteryMode) {
        case 'getOrganized':
          return 'cost-of-item'
        case 'saveToQuit':
          return 'desired-time-off'
        case 'getOutOfDebt':
          return 'debt-amount-input'
        case 'buildCushion':
          return 'desired-cushion'
        case 'default':
          return 'mode-selection'
      }
    },
    nextPage: 'savings-total',
  },
  {
    icon: 'fas fa-building-columns',
    page: 'savings-total',
    index: 3,
    previousPage: 'paycheck-or-income',
    nextPage: 'cost-of-living-fixed',
  },
  {
    icon: 'fas fa-flame',
    page: 'cost-of-living-fixed',
    index: 4,
    previousPage: 'savings-total',
    nextPage: 'cost-of-living-variable',
  },
  {
    icon: 'fas fa-flame',
    page: 'cost-of-living-variable',
    index: 5,
    previousPage: 'cost-of-living-fixed',
    nextPage: 'total-cost-of-living-complete',
  },
  {
    icon: 'fas fa-party-horn',
    page: 'total-cost-of-living-complete',
    index: 6,
    previousPage: 'cost-of-living-variable',
    nextPage: undefined,
  },
]
