import { Icon, StyledHr, TextSpan } from 'Components/DesignSystem/Typography'
import React from 'react'

import { WelcomeToSplurv } from 'Components/WelcomeToSplurv/WelcomeToSplurv'
import { IconButton } from 'design-system/buttons/IconButton/IconButton'
import { MethodSteps } from './MethodSteps'

interface DemoPageProps {
  closePage: () => void
}
interface Props extends DemoPageProps {}
export const SystemOverviewPage: React.FC<Props> = ({ closePage }) => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-start w-100 h-100 px-2">
      <WelcomeToSplurv color="text" />
      <br /> <StyledHr className="my-0" color="text" weight={1} style={{ opacity: 0.3 }} />
      <TextSpan color="text" size="lg" style={{}}>
        The System
        <Icon className="fal fa-cubes mx-1" color="text" size="xl" />
      </TextSpan>
      <TextSpan color="text" className="px-4" size="xs">
        We don't believe in forcing you into an app.
        <br /> If just our system is enough for you, great.
      </TextSpan>
      <div className="d-flex flex-column w-100 align-items-start">
        <MethodSteps />
      </div>
      <IconButton
        onClick={() => {
          closePage()
        }}
        title={'back'}
        color={'text'}
        iconClassName={'fas fa-chevron-left'}
        size="large"
      />
    </div>
  )
}
