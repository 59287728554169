import React from 'react'

import { CurrencyCounter } from 'Components/AnimatedCounters/CurrencyCounter'
import { BankLogo } from 'Components/BankLogo'
import { BaseSlider } from 'Components/BaseSlider/BaseSlider'
import { CollapseCard } from 'Components/CollapseCard/CollapseCard'
import { CollapseCardControlled } from 'Components/CollapseCard/CollapseCardControlled'
import { DataOrFeatureMissing } from 'Components/DataOrFeatureMissing/DataOrFeatureMissing'
import { ScrollingHalf } from 'Components/DesignSystem/Layout/Containers/Containers'
import {
  Icon,
  StyledHr,
  TextPopupSmall,
  TextSlim,
  TextSpan,
} from 'Components/DesignSystem/Typography'
import { AccountBase } from 'plaid'
import {
  ManualAccount,
  useFinancialData,
} from '../../../../Providers/FinancialDataProvider/FinancialDataProvider'
import {
  getCashAccounts,
  getSavingsAccounts,
} from '../../../../Providers/FinancialDataProvider/helpers'
import { useUIState } from '../../../../Providers/UIStateProvider'
import { SourceDestinationType } from '../../../../Utilities/interfaces'
import { bankLogoForBank } from '../../../CreditCardPage/CardTetherSetupPage/CardTetherSetupPage'
import { getBankItemFromAccount } from '../../../CreditCardPage/dataHelpers'
import {
  AccountAllocationSectionContainer,
  AllocatedAccountRow,
} from './AccountAllocationSectionComponents'

type TotalAllocationType = SourceDestinationType[]

interface AccountMaskAndLogoProps {
  accountId: string
}
const AccountMaskAndLogo: React.FC<AccountMaskAndLogoProps> = ({ accountId }) => {
  const { accountsData, flatAccounts } = useFinancialData()

  const insitutions = accountsData?.data?.institutions
  const accounts = accountsData?.data?.accounts
  const items = accountsData?.data?.items
  const accountForAccountId = flatAccounts?.find((a) => a.account_id === accountId)
  const bankForAccount = getBankItemFromAccount(accounts, items, accountId)
  const logo = bankLogoForBank(bankForAccount, insitutions)

  return (
    <div className="d-flex flex-column align-items-start">
      <BankLogo logo={logo} />
      <TextSpan
        size="xxs"
        weight={700}
        className="ml-0 pl-0 my-0"
        color={'textsupertransparent'}
        style={{ fontFamily: 'monospace' }}>
        {accountForAccountId?.mask}
      </TextSpan>
    </div>
  )
}

interface Props {
  originalAmount: number
  // A curried function that from the parent will link this amount to the correct account
  onAllocationSet: (account: string, amount: number) => void
  totalAllocations: TotalAllocationType
}

const getTotalAmountAllocated = (allocations: TotalAllocationType) => {
  return allocations.map((a) => a.portionAllocated).reduce((acc, curr) => acc + curr, 0)
}

interface AccountAllocationRowProps {
  account: AccountBase | ManualAccount
  allocationAmount: number
  originalAmount: number

  setAllocationAmount: (val: number) => void
  maxAllocationAmount: number

  leftToAllocate: number

  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const AccountAllocationRow: React.FC<AccountAllocationRowProps> = ({
  account,
  allocationAmount,
  setAllocationAmount,
  originalAmount,
  leftToAllocate,
  maxAllocationAmount,
  open,
  setOpen,
}) => {
  // const amount = (account as AccountBase).

  return (
    <CollapseCardControlled
      key={account.name}
      // skipBottomBorder
      isCollapsed={!open}
      setIsCollapsed={setOpen}
      body={
        <div className="pb-3 d-flex align-items-center flex-column">
          <BaseSlider
            xmax={maxAllocationAmount}
            xmin={0}
            unit={'$'}
            color="logoPrimary"
            numberOfIncrements={100}
            onChange={setAllocationAmount}
            value={allocationAmount}
          />
          <TextSpan
            onClick={() => {
              setAllocationAmount(leftToAllocate + allocationAmount)
            }}
            className="p-2"
            color="logoPrimary"
            weight={700}
            size="xs">
            <u>Set Remaining Left</u>
          </TextSpan>
        </div>
      }
      header={
        <AllocatedAccountRow key={account.name}>
          <Icon
            className={`fa${allocationAmount && allocationAmount > 0 ? 's' : 'r'} fa-circle mr-2`}
            color="logoPrimary"
            size="lg"
          />

          <AccountMaskAndLogo accountId={(account as AccountBase)?.account_id} />
          <TextSpan size="xxs">{account.name}</TextSpan>
          {/* <TextSpan color="logoPrimary">{numberToCurrency(allocationAmount)}</TextSpan> */}
          <TextSpan
            onClick={() => {
              if (!open) return
              const _value = prompt('Enter a specific allocation value')
              const value = Number(_value)
              if (value < 0) {
                setAllocationAmount(0)
                return
              }
              if (isNaN(value) || !value) {
                setAllocationAmount(allocationAmount)
                return
              }
              setAllocationAmount(value)
            }}
            color="logoPrimary"
            size="sm">
            <CurrencyCounter
              style={{ textDecoration: open ? 'underline' : 'inherit' }}
              value={allocationAmount}
            />
          </TextSpan>
        </AllocatedAccountRow>
      }
    />
  )
}

export const AccountAllocationSection: React.FC<Props> = ({
  onAllocationSet,
  originalAmount,
  totalAllocations,
}) => {
  const { flatAccounts, flatManualAccounts } = useFinancialData()
  const { setMessagePopupContent } = useUIState()

  const cashSavingsAccountsLive = [
    ...getCashAccounts(flatAccounts),
    ...getSavingsAccounts(flatAccounts),
  ]
  const cashSavingsAccountsManual = [
    ...flatManualAccounts.filter((fma) => fma.type === 'Cash'),
    ...flatManualAccounts.filter((fma) => fma.type === 'Savings'),
  ]

  const totalAmountAllocatedSoFar = getTotalAmountAllocated(totalAllocations)
  const leftToAllocate = originalAmount - totalAmountAllocatedSoFar

  const [openedAccount, setOpenedAccount] = React.useState<string | null>(null)

  const accountsForAllocation = [...cashSavingsAccountsLive, ...cashSavingsAccountsManual]
  const accountsAvailable = accountsForAllocation.length !== 0

  if (true) {
    return null
  }
  return (
    <CollapseCard
      header={
        <TextSpan
          tightness="superspaced"
          color="textsupertransparent"
          className="my-0"
          size="md"
          style={{
            textTransform: 'uppercase',
          }}>
          Account Allocation
          <TextPopupSmall
            color={'textsupertransparent'}
            className="ml-2 p-2"
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              setMessagePopupContent(
                'Allocation<>In this section, you can indicate to which accounts this income stream will get distributed to. This is useful to indicate and model your contributions to savings accounts, allocations for separate spending accounts, and to get a better understanding of what you can afford when money hits your account again.'
              )
            }}>
            <Icon color={'textsupertransparent'} size="lg" className="fas fa-circle-info" />
          </TextPopupSmall>
        </TextSpan>
      }
      body={
        <AccountAllocationSectionContainer>
          {/* NOTE: It would be dope to slide over the cashflow ggraph here  */}
          {/* <>
          <TransitionFromRight show={!!openedAccount} size="fixed-height" maxContainerHeight={300}>
          <ProjectionGraph viewRange={'6months'} activeMap={activeMap} />
          </TransitionFromRight>
        </> */}
          <div className="d-flex w-100 flex-column align-items-start justify-content-between">
            {accountsAvailable ? (
              <div
                className="ml-1 d-flex flex-row align-items-center"
                style={{
                  marginTop: '-.5rem',
                }}>
                <TextSlim
                  size="sm"
                  className="my-0"
                  color={leftToAllocate >= 0 ? 'logoPrimary' : 'mehred'}>
                  <CurrencyCounter value={leftToAllocate} />
                </TextSlim>
                {leftToAllocate === 0 ? (
                  <Icon color="logoPrimary" className="fas fa-check ml-1" size="lg" />
                ) : (
                  <TextSpan
                    size="xxs"
                    className="ml-1"
                    style={{
                      marginTop: '-.25rem',
                    }}
                    color="textsupertransparent">
                    Left
                  </TextSpan>
                )}
              </div>
            ) : null}
          </div>
          {accountsAvailable ? (
            <>
              <StyledHr weight={1} color="textsupertransparent" />
              <ScrollingHalf cutout={72}>
                {accountsForAllocation.map((afa) => {
                  const accountBeingUsedForAllocation = totalAllocations.find(
                    (a) => a.accountIdOrName === afa.name
                  )

                  const setAllocationAmount = (account: string) => {
                    return (amount: number) => {
                      onAllocationSet(account, amount)
                    }
                  }

                  const setOpen: React.Dispatch<React.SetStateAction<boolean>> = (value) => {
                    if (!openedAccount) {
                      setOpenedAccount(afa.name)
                    } else if (openedAccount === afa.name) {
                      setOpenedAccount(null)
                    } else {
                      setOpenedAccount(afa.name)
                    }
                  }

                  const totalAmountAllocatedSoFarOusideThisAccount = getTotalAmountAllocated(
                    totalAllocations.filter((ta) => ta.accountIdOrName !== afa.name)
                  )
                  const leftToAllocateWithinThisAccount =
                    originalAmount - totalAmountAllocatedSoFarOusideThisAccount

                  return (
                    <AccountAllocationRow
                      key={afa.name}
                      account={afa}
                      open={afa.name === openedAccount}
                      setOpen={setOpen}
                      allocationAmount={accountBeingUsedForAllocation?.portionAllocated || 0}
                      maxAllocationAmount={leftToAllocateWithinThisAccount}
                      leftToAllocate={leftToAllocate}
                      originalAmount={originalAmount}
                      setAllocationAmount={(amount) => setAllocationAmount(afa.name)(amount)}
                    />
                  )
                })}
              </ScrollingHalf>
            </>
          ) : (
            <DataOrFeatureMissing
              title="Accounts Needed"
              body="To setup income allocation, you must have at least one or more cash or savings accounts setup"
            />
          )}
        </AccountAllocationSectionContainer>
      }
    />
  )
}
