import { TextBold } from 'Components/DesignSystem/Typography'
import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import { useUIState } from 'Providers/UIStateProvider'
import { colorFromMysteryDayType } from 'Utilities/utils'
import React from 'react'

import color from 'color'
import { colorPalletes } from 'theme'

export const GoalBrandSplurvText: React.FC = () => {
  const { mysteryMode } = useMysteryDay()
  const { activeTheme } = useUIState()
  const modeColor = colorFromMysteryDayType(mysteryMode)
  return (
    <TextBold
      size="xl"
      color="second"
      className="mx-0 my-0"
      style={{
        background: `-webkit-linear-gradient(135deg, ${color(colorPalletes[activeTheme][modeColor])
          .darken(0.3)
          .toString()}, ${color(colorPalletes[activeTheme][modeColor]).lighten(0.3).toString()}`,
        WebkitBackgroundClip: 'text',
        backgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        transition: 'all ease-in-out .3s',
      }}>
      Splurv
    </TextBold>
  )
}
