import { useAuth0 } from '@auth0/auth0-react'
import { TextBold } from 'Components/DesignSystem/Typography'
import { useGetAccessToken } from 'Hooks/useGetAccessToken'
import { ConfirmationEntry } from 'Pages/SubscriptionPage/SubscriptionEntry'
import { fireConfetti } from 'Pages/WishlistPage/Confetti'
import { useSubscriptionStatus } from 'Providers/SubscriptionStatusProvider/SubscriptionStatusProvider'
import { useUIState } from 'Providers/UIStateProvider'
import { SlidingPage } from 'design-system/layout/SlidingPage/SlidingPage'
import React from 'react'
import { useLocation } from 'react-router'
import { ColorType, colorPalletes } from 'theme'

export const StripeReturnPage: React.FC = () => {
  const { pathname } = useLocation()
  const { setActiveTheme, activeTheme } = useUIState()

  const { user } = useAuth0()
  const { getAccessToken } = useGetAccessToken(`useAsyncFetch-stripe-session_status-GET}`)

  const { subscription, getStatus } = useSubscriptionStatus()

  React.useEffect(() => {
    async function getSessionStatus() {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      const sessionId = urlParams.get('session_id')
      const token = await getAccessToken()
      // Create a Checkout Session as soon as the page loads
      await fetch(
        `${
          process.env[`REACT_APP_${process.env.REACT_APP_APP_ENV}_API_URL`]
        }/stripe/session_status?session_id=${sessionId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then(async () => {
          await getStatus()
          fireConfetti({ colors: [colorPalletes[activeTheme].logoSecondary], duration: 0.4 })
        })
    }
    if (user && pathname === '/stripe-return') {
      getSessionStatus()
      setActiveTheme('newDark')
    }
    // eslint-disable-next-line
  }, [user, pathname, getAccessToken, activeTheme, setActiveTheme])

  const priceColor: ColorType = subscription?.interval === 'year' ? 'logoPrimary' : 'logoSecondary'

  return (
    <SlidingPage
      primaryButton={undefined}
      deleteButton={undefined}
      secondaryButton={undefined}
      open={pathname === '/stripe-return'}
      content={
        <div className="py-2 w-100 d-flex flex-column align-items-center">
          <TextBold size="xxl" color={priceColor} className="mb-4">
            Welcome!
          </TextBold>

          <ConfirmationEntry
            manageMode={false}
            nextRenewalDate={new Date((subscription?.periodEnd || 0) * 1000)}
            price={subscription?.price || 0}
            frequency={subscription?.interval || ''}
            priceColor={priceColor}
          />
        </div>
      }
    />
  )
}
