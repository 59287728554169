import { useAuth0 } from '@auth0/auth0-react'
import { useGetAccessToken } from 'Hooks/useGetAccessToken'
import { useFinancialData } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import { PlaidError, TransactionsGetResponse } from 'plaid'
import React from 'react'
import { fetchTimeout } from './fetchTimeout'

interface Params {
  /**
   * This is the list of itemIds we want to refresh
   * The length of this list also corresponds to the length of status items that will be returned
   */
  itemIds: string[]
}

export interface SuccessInterface<T> {
  status: 'success'
  error: null
  data: T
  itemId: string
}

interface PlaidErrorInterface {
  status: 'plaiderror'
  error: string
  data: { plaidErr: PlaidError }
  itemId: string
}
export interface RegularErrorInterface {
  status: 'error'
  error: string
  data: null
  itemId: string
}

interface FetchingInterface<T> {
  status: 'fetching'
  error: null
  data: T | null
  itemId: string
}

interface IdleInterface {
  status: 'idle'
  error: null
  data: null
  itemId: string
}
export type PossibleRequestInterfaces<T> =
  | SuccessInterface<T>
  | RegularErrorInterface
  | FetchingInterface<T>
  | IdleInterface
  | PlaidErrorInterface

export interface HookReturn<T> {
  requestStatuses: {
    [itemId: string]: PossibleRequestInterfaces<T>
  }
  issueRequest: () => Promise<any>
}

export const useRefreshAllTransactions = (params: Params): HookReturn<TransactionsGetResponse> => {
  const { user, loginWithRedirect } = useAuth0()
  const { getAccessToken } = useGetAccessToken('useRefreshAllTransaction')

  const { getTransactions } = useFinancialData()
  const { itemIds } = params

  const defaultRequestStatuses = itemIds.reduce((acc, itemId) => {
    acc[itemId] = {
      status: 'idle',
      error: null,
      data: null,
      itemId: itemId,
    }
    return acc
  }, {} as HookReturn<TransactionsGetResponse>['requestStatuses'])

  const [requestStatuses, setItems] = React.useState<
    HookReturn<TransactionsGetResponse>['requestStatuses']
  >(defaultRequestStatuses)

  const updateItemsStatus = (
    itemId: string,
    item: PossibleRequestInterfaces<TransactionsGetResponse>
  ) => {
    setItems((previousItems) => {
      const newItems = { ...previousItems }
      newItems[itemId] = item
      return newItems
    })
  }

  const issueRequest = async () => {
    let token: string | undefined
    try {
      token = await getAccessToken()
    } catch (err: any) {
      if (err.error === 'login_required') {
        loginWithRedirect({
          redirectUri: `${window.location.origin}${window.location.pathname}`,
        })
        return
      }
      if (err.error === 'consent_required') {
        loginWithRedirect({
          redirectUri: `${window.location.origin}${window.location.pathname}`,
        })
        return
      }
      throw err
    }
    const userid = user.sub
    const asyncRequests = itemIds.map((itemId, index) => {
      return async () => {
        const endpoint = process.env[`REACT_APP_${process.env.REACT_APP_APP_ENV}_API_URL`]
        const path = 'refresh_transactions'
        const url = `${endpoint}/${path}?userid=${userid}&itemId=${itemId}`
        updateItemsStatus(itemId, { status: 'fetching', data: null, error: null, itemId })
        return await fetchTimeout(url, 20000, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },

          mode: 'cors',
          credentials: 'include',
        })
          .then(async (res) => {
            if (res.status === 200) {
              const json = await res.json()
              setTimeout(() => {
                updateItemsStatus(itemId, {
                  status: 'success',
                  error: null,
                  data: json as TransactionsGetResponse,
                  itemId,
                })
              }, (index + 1) * 500)
            } else {
              const json = await res.json()
              updateItemsStatus(itemId, {
                status: 'error',
                error: res.statusText,
                data: json,
                itemId,
              })
            }
          })
          .catch((err) => {
            console.error(err)
            updateItemsStatus(itemId, { status: 'error', error: String(err), data: null, itemId })
          })
      }
    })

    const promisesToWaitFor = asyncRequests.map((asyncRequest) => asyncRequest())
    await Promise.allSettled(promisesToWaitFor)
    await getTransactions({})
  }
  return {
    issueRequest,
    requestStatuses,
  }
}
