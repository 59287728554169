import { ComplexityModeType, useUIState } from 'Providers/UIStateProvider'
import React from 'react'

/**
 *  A HOC  that wraps any content that only a certain complexity level can see
 * @param children the underlying component to render
 * @param minComlexityLevel the min complexity level that can see the content
 * @returns
 */
export const ComplexityLevelGate: React.FC<{ minComplexityLevel: ComplexityModeType }> = ({
  children,
  minComplexityLevel,
}) => {
  const { complexityMode } = useUIState()

  if (
    minComplexityLevel === 'complex' &&
    (complexityMode === 'complex' || complexityMode === 'advanced')
  ) {
    return <>{children}</>
  }
  if (minComplexityLevel === 'advanced' && complexityMode === 'advanced') {
    return <>{children}</>
  }

  if (minComplexityLevel === 'simple') {
    return <> {children}</>
  }
  return null
}
