import styled from 'styled-components'

export const AccountAllocationSectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const AllocatedAccountRow = styled.div`
  width: 100%;
  padding: 0.25rem 0rem;
  font-family: ${(props) => props.theme.fonts.family.secondary};
  font-weight: 400;
  font-size: 1rem;
  display: grid;
  grid-template-columns: minmax(0, 0.35fr) minmax(0, 0.5fr) minmax(0, 1.25fr) minmax(0, 0.85fr);
  align-items: center;
`
