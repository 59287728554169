import {
  ContainerDiv,
  ScrollingDemoFull,
} from 'Components/DesignSystem/Layout/Containers/Containers'
import { Icon, StyledVr, TextBold } from 'Components/DesignSystem/Typography'
import { useUIState } from 'Providers/UIStateProvider'
import { CaptionText } from 'design-system/typography/Text/Text'
import React from 'react'
import { AlexOnlySettings } from './SettingsItems/AlexOnlySettings/AlexOnlySettings'
import { CentsModeToggle } from './SettingsItems/CentsModeToggle'
import { ComplexityToggle } from './SettingsItems/ComplexityToggle'
import { DemoModeToggle } from './SettingsItems/DemoModeToggle'
import { FAQ } from './SettingsItems/FAQ'
import { Logout } from './SettingsItems/Logout'
import { OpenSubscriptionPage } from './SettingsItems/OpenSubscriptionPage'
import { PrivacyPolicy } from './SettingsItems/PrivacyPolicy'
import { ResetOnboarding } from './SettingsItems/ResetOnboarding'
import { StorageSizeCalculator } from './SettingsItems/StorageSizeCalculator'
import { UpdateAvailable } from './SettingsItems/UpdateAvailable'
import { ViewAccounts } from './SettingsItems/ViewAccounts'
import { WipeLocalData } from './SettingsItems/WipeLocalData'
import { SplurvLegalInfo } from './SplurvLegalInfo'
import { ThemeToggler } from './ThemeToggler'
import { UserBadge } from './UserBadge/UserBadge'

export const SettingsPage: React.FC = () => {
  const { setSettingsPageOpen } = useUIState()

  return (
    <ContainerDiv className="justify-content-between pb-5 w-100">
      <div className="d-flex w-100 align-items-center justify-content-between">
        <TextBold size="xl" className="my-0 mx-0" color="texttransparent">
          Settings
        </TextBold>

        <Icon
          className="fal fa-times align-self-end"
          size="xxl"
          color="textsupertransparent"
          onClick={() => setSettingsPageOpen(false)}
        />
      </div>
      <CaptionText>Resets, fancy options, and our original FAQ</CaptionText>

      <div className="d-flex w-100 align-items-center justify-content-start mb-3">
        <UserBadge />
        <StyledVr className="mx-3" color="text" />
        <ThemeToggler />
      </div>
      <ScrollingDemoFull cutout={250}>
        <OpenSubscriptionPage />
        <ViewAccounts />
        <DemoModeToggle />
        <CentsModeToggle />
        <ResetOnboarding />
        <FAQ />
        <ComplexityToggle />
        <UpdateAvailable />
        <PrivacyPolicy />
        <Logout />
        <WipeLocalData />
        <StorageSizeCalculator />

        <AlexOnlySettings />
      </ScrollingDemoFull>
      {/* TODO: Spice this up more, put our logo here, maybe even a copyright symbol if we learn how those work? */}
      <SplurvLegalInfo />
    </ContainerDiv>
  )
}
