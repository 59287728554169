import React from 'react'
import OneSignalReact from 'react-onesignal'

export const useGetNotificationsEnabledStatus = () => {
  const [isPushEnabled, setIsPushedEnabled] = React.useState<boolean>(false)

  React.useEffect(() => {
    OneSignalReact.isPushNotificationsEnabled((answer) => {
      setIsPushedEnabled(answer)
    })
  }, [])

  return { isPushEnabled }
}
