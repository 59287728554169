import { CurrencyCounter } from 'Components/AnimatedCounters/CurrencyCounter'
import { NumberCounter } from 'Components/AnimatedCounters/NumberCounter'
import { TextSpan } from 'Components/DesignSystem/Typography'
import { IconButton } from 'design-system/buttons/IconButton/IconButton'
import React from 'react'

interface Props {
  refreshTransactions: () => Promise<void>
  onClose: () => void
  refreshTransactionsLoading: boolean
  selectedTransactionsLength: number
  selectedTransactionsTotal: number
}
export const AdvancedTransactionViewHeader: React.FC<Props> = ({
  refreshTransactions,
  refreshTransactionsLoading,
  selectedTransactionsLength,
  selectedTransactionsTotal,
  onClose,
}) => {
  return (
    <div className="d-flex w-100 align-items-center justify-content-between">
      <IconButton
        onClick={() => refreshTransactions()}
        title="refresh"
        color="second"
        iconClassName={`fal fa-sync ${refreshTransactionsLoading ? 'fa-spin' : ''}`}
      />
      <div className="d-flex align-items-start flex-row">
        <div className="d-flex align-items-center flex-column mx-2">
          <TextSpan className="my-0" weight={300} size="xxs" color="textsupertransparent">
            <TextSpan className="my-0 mr-1" weight={700} size="xs">
              <NumberCounter value={selectedTransactionsLength} />
            </TextSpan>
            transactions
          </TextSpan>
          <TextSpan className="my-0" weight={300} size="xxs" color="text">
            <TextSpan className="my-0 mr-1" weight={700} size="sm">
              <CurrencyCounter value={selectedTransactionsTotal} />
            </TextSpan>
            total
          </TextSpan>
        </div>
      </div>
      <IconButton
        onClick={() => onClose()}
        title="close"
        color="textsupertransparent"
        iconClassName="fal fa-times"
      />
    </div>
  )
}
