import { TextSlimTight } from 'Components/DesignSystem/Typography'
import { useUIState } from 'Providers/UIStateProvider'
import color from 'color'
import React from 'react'
import { colorPalletes } from 'theme'
import { TIME_FRAME } from '../AdvancedTransactionView/helpers'

interface Props {
  timeFrameFilter: TIME_FRAME | undefined
}
export const TransactionDateRangeBadge: React.FC<Props> = ({ timeFrameFilter }) => {
  const { activeTheme } = useUIState()
  return (
    <TextSlimTight
      size="xxs"
      style={{
        textAlign: 'left',
        justifyContent: 'flex-start',
        whiteSpace: 'nowrap',
        lineHeight: '.75rem',
        margin: '.25rem 0',
        padding: '.25rem .5rem',
        borderRadius: '4px',
        opacity: timeFrameFilter === 'both' || !timeFrameFilter ? 0 : 1,
        textTransform: 'uppercase',
        color: color(
          colorPalletes[activeTheme][timeFrameFilter === 'thisMonth' ? 'orange' : 'logoPrimary']
        )
          .darken(0.6)
          .toString(),
        backgroundColor: color(
          colorPalletes[activeTheme][timeFrameFilter === 'thisMonth' ? 'orange' : 'logoPrimary']
        ).toString(),
      }}
      weight={700}>
      {timeFrameFilter === 'thisMonth' ? 'this month' : 'last month'}
    </TextSlimTight>
  )
}
