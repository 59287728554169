import { BodyText, SubtitleText } from 'design-system/typography/Text/Text'
import React from 'react'
import { ContainerDiv } from '../../../Components/DesignSystem/Layout/Containers/Containers'
import { TextSpan } from '../../../Components/DesignSystem/Typography'
import { useMysteryDay } from '../../../Providers/MysteryDayProvider/MysteryDayProvider'
import { cleanCurrency } from '../../../Utilities/currencyFormater'
import { MoneyInputContainer } from '../../StreamPage/AddEditStream/AddEditStream.components'
import { BackButton } from '../MysteryDayBackButton'
import { ForwardButton } from '../MysteryDayForwardButton'
import { SubPageProps } from '../MysteryPage'

const MAX_LENGTH = 15

export const ItemInputPage: React.FC<SubPageProps> = () => {
  const {
    itemCost,
    itemName,
    setItemName,
    setItemCost,
    itemExplanation,
    setItemExplanation,
  } = useMysteryDay()
  return (
    <ContainerDiv className="justify-content-between align-items-center py-3">
      <ContainerDiv className="justify-content-start align-items-center py-3">
        <SubtitleText className="px-2 mb-0">The Dream</SubtitleText>
        <BodyText className="px-4 my-2">
          We believe money habits most effectively change when the change is in service of something
          bigger we care deeply about. Tell us what you're saving for and we'll help you get there.
        </BodyText>
        <MoneyInputContainer
          size="md"
          color="megapurple"
          className="mb-5"
          inputAtLimit={itemName.length === MAX_LENGTH}
          icon="f02b"
          label={'Name'}>
          <input
            data-testid="stream-amount-input"
            inputMode="text"
            maxLength={MAX_LENGTH}
            value={itemName}
            placeholder={'House, wedding, trip'}
            onChange={(e) => setItemName(e.currentTarget.value)}></input>

          <TextSpan>
            {itemName.length}/{MAX_LENGTH}
          </TextSpan>
        </MoneyInputContainer>
        <MoneyInputContainer
          size="md"
          color="megapurple"
          className="mb-5"
          icon="f155"
          label={'Amount'}>
          <input
            data-testid="stream-amount-input"
            inputMode="decimal"
            value={itemCost}
            placeholder={'5,000'}
            onChange={(e) => setItemCost(e.currentTarget.value)}></input>
        </MoneyInputContainer>
        <MoneyInputContainer
          size="md"
          color="megapurple"
          className="mb-5"
          icon="f004"
          fontSizeDefault="xxs"
          label={'Why is this important?'}>
          <input
            data-testid="stream-amount-input"
            maxLength={45}
            value={itemExplanation}
            placeholder={'To see a smile on their face'}
            onChange={(e) => setItemExplanation(e.currentTarget.value)}></input>
          <TextSpan>
            {itemExplanation.length}/{45}
          </TextSpan>
        </MoneyInputContainer>
        {/* <AccountTrackingCTA updateMode={false} type="Savings" size="large" /> */}
      </ContainerDiv>

      <div className="d-flex justify-content-between align-items-center w-100">
        <BackButton />
        <ForwardButton
          disabledPopupTitle="Savings Goal Still Missing"
          disabledPopupBody="Please fill out the name and the amount of what you are saving towards to continue"
          disabled={cleanCurrency(itemCost) === 0}
          title={'Next'}
        />
      </div>
    </ContainerDiv>
  )
}
