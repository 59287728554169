import {
  AccountOverviewWidget,
  accountOverviewWidgetMarginBottom,
  accountOverviewWidgetSize,
} from 'Components/AccountOverviewWidget'
import {
  BanksInNeedOfLoginWidget,
  useBanksInNeedOfLogin,
} from 'Components/BanksInNeedOfLoginWidget'
import {
  ContainerDiv,
  ScrollingDemoFull,
} from 'Components/DesignSystem/Layout/Containers/Containers'
import { useFinancialData } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import {
  getAccountTypeFromTypeSubtypeTuple,
  getCashAccounts,
  getCreditCardAccounts,
  getDebtAccounts,
  getSavingsAccounts,
} from 'Providers/FinancialDataProvider/helpers'
import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import { useUserCustomSettings } from 'Providers/UserCustomSettingsProvider/UserCustomSettingsProvider'
import React from 'react'
import { AccountRow } from './AccountRow/AccountRow'
import { CreditCardEmptyStatePage } from './CreditCardEmptyStatePage'
import { CreditCardErrorPage } from './CreditCardErrorPage'
import { CreditCardPageHeader, creditCardPageHeaderSize } from './CreditCardPageHeader'
import { ManualAccountRow } from './ManualAccountRow/ManualAccountRow'
import { getBankItemFromAccount } from './dataHelpers'

export const CreditCardPage: React.FC = () => {
  const { accountsData, flatAccounts, flatManualAccounts } = useFinancialData()

  const { onboardingDone } = useMysteryDay()

  const banksInNeedOfLogin = useBanksInNeedOfLogin()
  const renderReloginWidget = banksInNeedOfLogin.length > 0

  const { settings } = useUserCustomSettings()

  // const [liveAccountsSortMode, setLiveAccountsSortMode] = useLocalStorageSyncedState<
  //   'bank' | 'type'
  // >('type', 'live-account-sort-mode')

  const [openedRow, setOpenedRow] = React.useState<string | false>(false)

  const items = accountsData?.data?.items || []
  const accounts = accountsData?.data?.accounts || []
  const institutions = accountsData?.data?.institutions || []

  if (accountsData?.status === 'error') return <CreditCardErrorPage />
  if (!onboardingDone) return <CreditCardEmptyStatePage />

  const liveSavingsAccounts = getSavingsAccounts(flatAccounts) || []
  const liveCashAccounts = getCashAccounts(flatAccounts) || []
  const liveDebtAccounts = getDebtAccounts(flatAccounts) || []
  const liveCreditAccounts = getCreditCardAccounts(flatAccounts) || []

  const liveAccountsDisplayed = [
    ...liveCashAccounts,
    ...liveCreditAccounts,
    ...liveSavingsAccounts,
    ...liveDebtAccounts,
  ]

  const cutout =
    creditCardPageHeaderSize +
    (renderReloginWidget
      ? (accountOverviewWidgetSize + accountOverviewWidgetMarginBottom * 2) * 2
      : accountOverviewWidgetSize + accountOverviewWidgetMarginBottom * 2)

  return (
    <ContainerDiv>
      <CreditCardPageHeader />
      {/* <BankSortSetter
        liveAccountsSortMode={liveAccountsSortMode}
        setLiveAccountsSortMode={setLiveAccountsSortMode}
      /> */}
      <ScrollingDemoFull cutout={cutout}>
        {liveAccountsDisplayed.length ? (
          <div className="d-flex flex-column">
            {liveAccountsDisplayed
              // .sort((ladA, ladB) => {
              //   if (liveAccountsSortMode === 'bank') {
              //     const bankA = getBankItemFromAccount(accounts, items, ladA.account_id)
              //     const bankB = getBankItemFromAccount(accounts, items, ladB.account_id)

              //     return (bankA?.item?.item_id || '') >= (bankB?.item?.item_id || '') ? 1 : -1
              //   } else {
              //     return 1
              //   }
              // })
              .map((lad) => {
                const bank = getBankItemFromAccount(accounts, items, lad.account_id)
                const account = flatAccounts.find((a) => a.account_id === lad.account_id)

                const accountMetadata = settings?.accountMetadata
                const foundAccount = accountMetadata?.find((am) => am.account_id === lad.account_id)

                const nickname = foundAccount?.name
                const spendingLimitPerMonth = foundAccount?.spendingLimitPerMonth
                const hidden = foundAccount?.hidden

                const institution = institutions.find(
                  (i) => i.institution.institution_id === bank?.item?.institution_id
                )
                const type = getAccountTypeFromTypeSubtypeTuple([lad.type, lad.subtype])
                const amount =
                  type === 'Credit Card'
                    ? lad.balances.current
                    : lad.balances.available || lad.balances.current
                return (
                  <AccountRow
                    key={account?.account_id}
                    openedRow={openedRow}
                    setOpenedRow={setOpenedRow}
                    itemId={bank?.item.item_id}
                    account={account}
                    accountId={lad.account_id}
                    institution={institution}
                    type={type}
                    nickname={nickname}
                    hidden={hidden}
                    spendingLimitPerMonth={spendingLimitPerMonth}
                    amount={Number(amount)}
                  />
                )
              })}
          </div>
        ) : null}
        {flatManualAccounts.length ? (
          <div className="d-flex flex-column">
            {flatManualAccounts.map((fma) => {
              return <ManualAccountRow manualAccount={fma} key={fma._id} />
            })}
          </div>
        ) : null}
      </ScrollingDemoFull>
      <BanksInNeedOfLoginWidget />
      <AccountOverviewWidget />
    </ContainerDiv>
  )
}
