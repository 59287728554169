import { Icon, TextBold, TextSpan } from 'Components/DesignSystem/Typography'
import { deleteAllLocalData } from 'Providers/APIDataProvider'
import React from 'react'
import { SettingsItem } from '../components'

export const WipeLocalData: React.FC = () => {
  return (
    <SettingsItem
      allowedPostTrial
      onClick={() => {
        const answer = window.confirm('Are you sure you want to clear local cache?')
        if (answer) deleteAllLocalData()
      }}>
      <div className="d-flex flex-column w-100 align-items-start">
        <div className="d-flex flex-row w-100 align-items-center">
          <Icon color="mehred" className="fas fa-trash" />
          <TextBold color="mehred">Clear Local Cache</TextBold>
        </div>
        <TextSpan size="xxs" color="texttransparent">
          Wipe all data from your app's cache
        </TextSpan>
      </div>
    </SettingsItem>
  )
}
