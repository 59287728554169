import { AccountMaskAndLogo } from 'Components/AccountMaskAndLogo'
import { TextSpan } from 'Components/DesignSystem/Typography'
import { dataParameterType } from 'Hooks/useLocalStorageSyncedState'
import { AccountBase } from 'plaid'
import React from 'react'
interface Props {
  accounts: AccountBase[]
  activeAccountIdFilter: string
  setActiveAccountIdFilter: (v: dataParameterType<string>) => void
}
export const ActiveTransactionAccountPicker: React.FC<Props> = ({
  accounts: flatAccounts,
  activeAccountIdFilter,
  setActiveAccountIdFilter,
}) => {
  return (
    <div className="d-flex w-100 align-items-center justify-content-between my-2">
      <TextSpan size="xxs" color="textsupertransparent" className="mr-2">
        Bank:
      </TextSpan>
      <div
        className="d-flex w-100 align-items-center justify-content-start"
        style={
          {
            overflowX: 'scroll',
            '-webkit-overflow-scrolling': 'touch',
          } as React.CSSProperties
        }>
        {flatAccounts?.map((a) => {
          return (
            <div
              key={a.account_id}
              style={{
                opacity: activeAccountIdFilter
                  ? activeAccountIdFilter === a.account_id
                    ? 1
                    : 0.5
                  : 1,
              }}
              className="mx-3"
              onClick={() =>
                setActiveAccountIdFilter((activeAccountIdFilter) =>
                  activeAccountIdFilter === a.account_id ? '' : a.account_id
                )
              }>
              <AccountMaskAndLogo accountId={a.account_id} logoSize={32} />
            </div>
          )
        })}
      </div>
    </div>
  )
}
