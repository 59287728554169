import { Icon, StyledVr, TextSpan } from 'Components/DesignSystem/Typography'
import React from 'react'

import { useData } from 'Providers/APIDataProvider'
import { useFinancialData } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import { MysteryDayType } from 'Providers/MysteryDayProvider/MysteryDayProvider.interfaces'
import { numberToCurrency } from 'Utilities/currencyFormater'
import { ACCOUNTS_ICON, EXPENSES_ICON, INCOME_ICON } from 'Utilities/navigationTabUtilities'
import {
  getMonthlyBills,
  getMonthlyIncome,
  getMonthlySubscriptions,
} from 'Utilities/netWorth/netWorth'

export const getModeIcon = (mysteryMode: MysteryDayType) => {
  switch (mysteryMode) {
    case 'getOrganized':
      return 'fas fa-party-horn'
    case 'saveToQuit':
      return 'fas fa-rocket'
    case 'getOutOfDebt':
      return 'fas fa-sun'
    case 'buildCushion':
      return 'fas fa-clouds'
    case 'default':
      return 'fas fa-arrow-right-to-arc'
  }
}

export const OnboardingOverview: React.FC = () => {
  const { page } = useMysteryDay()
  const { streams } = useData()
  const { flatAccounts, flatManualAccounts, flexibleSpendPerMonth } = useFinancialData()

  const totalAccountsLength = flatAccounts.length + flatManualAccounts.length

  const monthlyIncome = getMonthlyIncome(streams)
  const monthlyExpenses = getMonthlyBills(streams) + getMonthlySubscriptions(streams)
  return (
    <div className="d-flex flex-row align-items-center ml-0">
      <div
        className="d-flex flex-column align-items-center"
        style={{
          opacity: page === 'paycheck-or-income' ? 1 : 0.3,
          transition: 'all ease-in-out .3s',
        }}>
        <TextSpan color="cashgreen" className="my-0" size="xs">
          <Icon className={`${INCOME_ICON} `} color="cashgreen" size="sm" />
        </TextSpan>
        <TextSpan color="cashgreen" className="my-0" size="xxs" weight={700}>
          {numberToCurrency(Math.abs(monthlyIncome), true)}
        </TextSpan>
      </div>
      <StyledVr color="nearlyclear" className="mx-3" />
      <div
        className="d-flex flex-column align-items-center"
        style={{
          opacity: page === 'savings-total' ? 1 : 0.3,
          transition: 'all ease-in-out .3s',
        }}>
        <TextSpan color="text" className="my-0" size="xs">
          <Icon className={`${ACCOUNTS_ICON} `} color="text" size="sm" />
          {/* Income */}
        </TextSpan>
        <TextSpan color="text" className="my-0" size="xs" weight={700}>
          {totalAccountsLength}
        </TextSpan>
      </div>
      <StyledVr color="nearlyclear" className="mx-3" />
      <div
        className="d-flex flex-column align-items-center"
        style={{
          opacity: page === 'cost-of-living-fixed' ? 1 : 0.3,
          transition: 'all ease-in-out .3s',
        }}>
        <TextSpan color="mehred" className="my-0" size="xs" weight={800}>
          <Icon className={`${EXPENSES_ICON} mr-1`} color="mehred" size="sm" />F{/* Expenses */}
        </TextSpan>
        <TextSpan color="mehred" className="my-0" size="xxs" weight={700}>
          {numberToCurrency(Math.abs(monthlyExpenses), true)}
        </TextSpan>
      </div>
      <StyledVr color="nearlyclear" className="mx-3" />
      <div
        className="d-flex flex-column align-items-center"
        style={{
          opacity: page === 'cost-of-living-variable' ? 1 : 0.3,
          transition: 'all ease-in-out .3s',
        }}>
        <TextSpan color="yellow" className="my-0" size="xs" weight={800}>
          <Icon className={`${EXPENSES_ICON} mr-1 `} color="yellow" size="sm" />V{/* Expenses */}
        </TextSpan>
        <TextSpan color="yellow" className="my-0" size="xxs" weight={700}>
          {numberToCurrency(Math.abs(flexibleSpendPerMonth), true)}
        </TextSpan>
      </div>
      {/* <Icon className={`${icon} mr-2`} color={color} /> */}
    </div>
    // <div className="d-flex flex-column align-items-start mt-1">
    //   <TextBold
    //     size="xl"
    //     color="second"
    //     className="mx-0 my-0"
    //     style={
    //       {
    //         background: '-webkit-linear-gradient(135deg, #b6d8e3, #c8fadc)',
    //         WebkitBackgroundClip: 'text',
    //         WebkitTextFillColor: 'transparent',
    //       } as React.CSSProperties
    //     }>
    //     Splurv
    //   </TextBold>
    //   {mysteryMode !== 'default' ? (
    //     <TextSpan
    //       weight={200}
    //       className="my-0 mt-1"
    //       fontFamily="primary"
    //       color={color}
    //       size="sm"
    //       style={{
    //         // textTransform: 'uppercase',
    //         opacity: 0.6,
    //       }}>
    //       <Icon className={`${icon} mr-2`} color={color} />
    //       {title}
    //       {mysteryMode === 'buildCushion' && cushionSubMode ? (
    //         <div className="d-flex flex-row align-items-center">
    //           <Icon className={`fas fa-arrow-right mx-1`} color={color} />

    //           <TextSpan
    //             weight={200}
    //             className="my-0"
    //             fontFamily="primary"
    //             color={color}
    //             size="xxs"
    //             style={
    //               {
    //                 // textTransform: 'uppercase',
    //               }
    //             }>
    //             {cushionSubModeToTitle[cushionSubMode]}
    //           </TextSpan>
    //         </div>
    //       ) : mysteryMode === 'getOrganized' && itemName ? (
    //         <TextSpan
    //           weight={200}
    //           className="my-0 ml-1"
    //           color={'megapurple'}
    //           size="xxs"
    //           style={
    //             {
    //               // textTransform: 'uppercase',
    //             }
    //           }>
    //           - "{itemName}"
    //         </TextSpan>
    //       ) : null}
    //     </TextSpan>
    //   ) : null}
    // </div>
    // </div>
  )
}
