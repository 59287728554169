import { TextPopup, TextSpan } from 'Components/DesignSystem/Typography'
import { MoneyInputContainer } from 'Pages/StreamPage/AddEditStream/AddEditStream.components'
import { useUIState } from 'Providers/UIStateProvider'
import {
  CategoryKind,
  useUserCustomSettings,
} from 'Providers/UserCustomSettingsProvider/UserCustomSettingsProvider'
import { formatCurrency } from 'Utilities'
import { cleanCurrency } from 'Utilities/currencyFormater'
import { SlidingPage } from 'design-system/layout/SlidingPage/SlidingPage'
import React, { useCallback } from 'react'

export const AddCustomEnvelopePage: React.FC = () => {
  const {
    saveCategorySpendingLimit,
    getSettings,
    getSettingsLoading,
    saveCategorySpendingLimitLoading,
    settings,
    deleteCategory,
    deleteCategoryLoading,
  } = useUserCustomSettings()

  const loading = saveCategorySpendingLimitLoading || getSettingsLoading

  const { addCustomEnvelopePageOpen, setAddCustomEnvelopePageOpen } = useUIState()

  const [name, setName] = React.useState<string>('')
  const [amount, _setAmount] = React.useState<string>('')
  const [kind, setKind] = React.useState<CategoryKind>('regular')

  const existingCategory = addCustomEnvelopePageOpen && addCustomEnvelopePageOpen.val !== ''

  const setAmount = useCallback((amount: string) => {
    const formattedValue = formatCurrency(amount, 10, 'positive')
    _setAmount(formattedValue)
  }, [])

  React.useEffect(() => {
    if (addCustomEnvelopePageOpen) {
      const foundCategoryDatum = settings?.categoryMetadata?.[addCustomEnvelopePageOpen.val]
      if (foundCategoryDatum) {
        setName(addCustomEnvelopePageOpen.val)
        setAmount(String(foundCategoryDatum.limit))
        setKind(foundCategoryDatum.kind)
      } else {
        setName('')
        setAmount('')
      }
    }
  }, [addCustomEnvelopePageOpen, setAmount, settings?.categoryMetadata])

  const onSaveClick = async () => {
    if (name === '') {
      alert('Please enter a name')
      return
    }
    if (amount === '') {
      alert('Please enter an amount')
      return
    }
    await saveCategorySpendingLimit({ category: name, limit: cleanCurrency(amount), kind })
    await getSettings()
    setAddCustomEnvelopePageOpen(false)
    addCustomEnvelopePageOpen && addCustomEnvelopePageOpen.cb && addCustomEnvelopePageOpen.cb(name)
  }

  const title = `${
    addCustomEnvelopePageOpen && addCustomEnvelopePageOpen.val !== '' ? 'Edit' : 'Add'
  } Envelope`
  const subtitle = 'You can create a new envelope for your variable spend here'

  const primaryButton = {
    title: existingCategory ? (loading ? 'updating...' : 'update') : loading ? 'saving...' : 'save',
    icon: `${existingCategory ? 'fas fa-check' : 'fas fa-plus'} ${loading ? 'fa-spin' : ''}`,
    onClick: () => onSaveClick(),
  }

  const deleteButton = {
    title: deleteCategoryLoading ? 'deleting...' : 'delete',
    icon: 'fas fa-trash',
    onClick: async () => {
      if (addCustomEnvelopePageOpen) {
        await deleteCategory({
          category: addCustomEnvelopePageOpen.val,
        })
        await getSettings()
        setAddCustomEnvelopePageOpen(false)
        addCustomEnvelopePageOpen.cb()
      }
    },
  }
  const secondaryButton = {
    title: 'cancel',
    icon: 'fas fa-times',
    onClick: () => {
      setAddCustomEnvelopePageOpen(false)
      addCustomEnvelopePageOpen && addCustomEnvelopePageOpen.cb && addCustomEnvelopePageOpen.cb()
    },
  }

  return (
    <SlidingPage
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      deleteButton={existingCategory ? deleteButton : undefined}
      open={addCustomEnvelopePageOpen !== false}
      title={title}
      subtitle={subtitle}
      content={
        <>
          <MoneyInputContainer
            size="md"
            color={'text'}
            className="mb-5"
            icon="f02b"
            label={'Envelope Name'}>
            <input
              data-testid="stream-amount-input"
              inputMode="text"
              value={name}
              placeholder={'Groceries'}
              onChange={(e) => setName(e.currentTarget.value)}></input>
          </MoneyInputContainer>
          <MoneyInputContainer
            size="md"
            color={'text'}
            className="mb-5"
            icon="f155"
            label={'Envelope Amount'}>
            <input
              data-testid="stream-amount-input"
              inputMode="decimal"
              value={amount}
              placeholder={'200'}
              onChange={(e) => setAmount(e.currentTarget.value)}></input>
          </MoneyInputContainer>
          <div className="d-flex flex-column w-100">
            <TextSpan color="texttransparent" size="sm">
              What kind of envelope is this?
            </TextSpan>
            <div className="d-flex flex-row w-100">
              <TextPopup
                color={kind === 'regular' ? 'logoSecondary' : 'textsupertransparent'}
                style={{
                  opacity: kind === 'regular' ? 1 : 0.6,
                }}
                onClick={() => setKind('regular')}
                className="mr-3">
                Anticipated
              </TextPopup>
              <TextPopup
                color={kind === 'irregular' ? 'logoSecondary' : 'textsupertransparent'}
                style={{
                  opacity: kind === 'irregular' ? 1 : 0.6,
                }}
                onClick={() => setKind('irregular')}>
                Irregular
              </TextPopup>
            </div>
            <TextSpan color="textsupertransparent" size="xs">
              {kind === 'regular'
                ? 'An Anticipated variable spend envelope is something you expect to budget for each month and includes gas, groceries, and other consistently required things. Creating budgets for these items helps us make sure we can afford the essentials each month'
                : 'An Irregular variable spend envelope is something that might exist this month (ie: wedding gift, car maintenance) but is not consistent. Creating budgets for these items helps us plan ahead and anticipate things that are unique to this month.'}
            </TextSpan>
          </div>
        </>
      }
    />
  )
}
