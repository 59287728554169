import { Container } from 'Providers/ErrorBoundary/ErrorBoundary.components'
import React from 'react'
import { TextBold, TextSlim } from './DesignSystem/Typography'

interface State {
  hasError: boolean
  error: any
  errorInfo: any
  showError: boolean
}

export class GraphErrorBoundary extends React.Component<{}, State> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false, error: '', errorInfo: '', showError: false }
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.error('error', error)
    console.error('errorInfo', errorInfo)
    this.setState({ error, errorInfo })
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Container data-testid="error-boundary-container">
          <TextBold size="xl" color="second">
            Oh my - we goofed.{' '}
          </TextBold>
          <TextBold size="xl" color="second">
            <span aria-labelledby="emoji">🙈</span>
          </TextBold>
          <TextSlim size="sm" weight={400} color="second">
            Mistakes happen but this was a rough one. Our graphing layer crashed. Try increasing
            your monthly contribution if this happened
          </TextSlim>
          <TextSlim size="sm" weight={400} color="second">
            {' '}
            If you can tell us what steps lead to this, email <b>feedback@splurv.com</b> and we'd be
            happy to buy you a<span aria-labelledby="emoji"> ☕️</span>. Seriously, help us get
            better!
          </TextSlim>

          <TextSlim onClick={() => this.setState({ showError: !this.state.showError })}>
            Click to {this.state.showError ? 'hide' : 'show'} error
          </TextSlim>

          {this.state.showError ? (
            <div style={{ maxHeight: '50vh', overflowY: 'scroll' }}>
              <TextSlim>
                <code>{String(this.state.error)}</code>
                <br />
                <br />
                <code>{JSON.stringify(this.state.errorInfo)}</code>
              </TextSlim>
            </div>
          ) : null}
        </Container>
      )
    } else {
      return this.props.children
    }
  }
}
