// TODO:

/*


Frances is my biggest support and gaaave this good feedback ❤️

- Bring income right after debt input
- Bring MCOL before Variable & Fixed

*/
import { datadogRum } from '@datadog/browser-rum'
import { useGetTrackedAccountDetails } from 'Components/AccountTrackingCTA/hooks'
import { fireConfetti } from 'Pages/WishlistPage/Confetti'
import { useFinancialData } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import { MysteryPageType } from 'Providers/MysteryDayProvider/MysteryDayProvider.interfaces'
import { useUIState } from 'Providers/UIStateProvider'
import { cleanCurrency } from 'Utilities/currencyFormater'
import { colorFromMysteryDayType } from 'Utilities/utils'
import { WideButton } from 'design-system/buttons/WideButton/WideButton'
import React from 'react'
import { colorPalletes } from 'theme'
import { useMysteryDay } from '../../Providers/MysteryDayProvider/MysteryDayProvider'
import { OnboardingLinkedList } from './OnboardingLinkedList'

export const ForwardButton: React.FC<{
  title: string
  disabled?: boolean
  end?: boolean
  style?: React.CSSProperties
  disabledPopupTitle?: string
  disabledPopupBody?: string
}> = ({
  title,
  disabled = false,
  end = false,
  style,
  disabledPopupBody = 'Please fill out all information',
  disabledPopupTitle = 'Missing Information',
}) => {
  const { activeTheme, setMessagePopupContent } = useUIState()
  const {
    page,
    setPage,
    setOnboardingDone,
    mysteryMode,
    unlockPage,
    cushionSubMode,
    saveUserOnboardingSetup,
    linkedAccount,
    linkedAccounts,
    itemCost,
    itemName,
    itemExplanation,
    desiredTimeOff,
    debtAmount,
  } = useMysteryDay()

  const trackedLinkedAccount = useGetTrackedAccountDetails()

  const { flexibleSpendPerMonth } = useFinancialData()
  const color = colorFromMysteryDayType(mysteryMode)
  const onClick = async () => {
    if (disabled) {
      setMessagePopupContent(`${disabledPopupTitle}<>${disabledPopupBody}`)
      // alert('Please complete all pieces on this page')
      return
    }

    const currentPageLinkedListDatum = OnboardingLinkedList.find((datum) => {
      let pageToBeChecked: MysteryPageType
      if (typeof datum.page === 'function') {
        pageToBeChecked = datum.page(mysteryMode)
      } else {
        pageToBeChecked = datum.page
      }
      return pageToBeChecked === page
    })!

    const nextPageLinkedListDatum = currentPageLinkedListDatum.nextPage
    let nextPage: MysteryPageType | undefined

    if (typeof nextPageLinkedListDatum === 'function') {
      nextPage = nextPageLinkedListDatum(mysteryMode)
    } else {
      nextPage = nextPageLinkedListDatum
    }
    if (!nextPage) {
      await saveUserOnboardingSetup({
        completed: true,
        mode: mysteryMode,
        subMode: cushionSubMode,
        variableSpend: flexibleSpendPerMonth,
        linkedAccount,
        linkedAccounts,

        debtAmount: cleanCurrency(debtAmount) || trackedLinkedAccount?.amount || 0,
        desiredTimeOff,
        itemName,
        itemCost,
        itemExplanation,
      })
      fireConfetti({
        colors: [colorPalletes[activeTheme][colorFromMysteryDayType(mysteryMode) || 'second']],
      })
      unlockPage(['/wishlist'])
      setOnboardingDone(true)

      datadogRum.addAction('onboarding_complete', {
        onboarding: {
          mysteryMode,
          cushionSubMode,
        },
      })
      datadogRum.addRumGlobalContext('onboarding', {
        onboarding: {
          mysteryMode,
          cushionSubMode,
        },
      })
      return
    }
    setPage(nextPage)
  }

  return (
    <WideButton
      textSize="large"
      appearance="primary"
      width={page === 'mode-selection' ? 'full' : 'fit'}
      bgColor={disabled ? 'text' : color}
      active={true}
      onClick={onClick}
      icon={{
        className: page === 'mode-selection' ? 'fas fa-flag' : 'fas fa-arrow-right',
        position: 'right',
      }}
      text={title}
    />
  )
}
