import React from 'react'
import { StyledHr, TextSlim } from '../DesignSystem/Typography'

interface Props {
  subtitle: React.ReactNode | string
  content: React.ReactNode | string
  cta: React.ReactNode
}
export const EmptyStatePage: React.FC<Props> = ({ subtitle, content, cta }) => {
  return (
    <div className="d-flex flex-column align-items-center w-100 h-100 pt-3 justify-content-between">
      <div className="d-flex flex-column align-items-center w-100">
        <div className="d-flex flex-column align-items-center w-100">
          {typeof subtitle === 'string' ? (
            <TextSlim className="mb-0" size="xl" color="second" textAlign="center">
              {subtitle}
            </TextSlim>
          ) : (
            subtitle
          )}
          <StyledHr className="my-1" weight={1} color="nearlyclear" />
        </div>

        {typeof content === 'string' ? (
          <TextSlim size="md" color="texttransparent" weight={300} className="my-3 px-3">
            {content}
          </TextSlim>
        ) : (
          <div className="d-flex flex-column align-items-center w-100 mt-3 px-3">{content}</div>
        )}

        <br />
      </div>

      <div className="d-flex flex-column align-items-center w-100 px-3">
        <StyledHr className="mb-4" weight={1} color="nearlyclear" />
        {cta}
      </div>
    </div>
  )
}
