import React, { useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useGetAccessToken } from 'Hooks/useGetAccessToken'
import { useUIState } from 'Providers/UIStateProvider'
import { CheckoutContainer, StripeContainer } from './StripeCheckout.components'

const key = String(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
const stripePromise = loadStripe(key)

interface Props {
  paymentKey: string
}
export const StripeCheckoutContainer: React.FC<Props> = ({ paymentKey }) => {
  const { user } = useAuth0()
  const { setActiveTheme } = useUIState()
  const { getAccessToken } = useGetAccessToken(`useAsyncFetch-create-checkout-session-POST}`)

  const [clientSecret, setClientSecret] = React.useState('')

  useEffect(() => {
    setActiveTheme('newLight')
  }, [setActiveTheme])

  React.useEffect(() => {
    async function createCheckoutSession() {
      const token = await getAccessToken()
      const userid = user.sub
      // Create a Checkout Session as soon as the page loads
      fetch(
        `${
          process.env[`REACT_APP_${process.env.REACT_APP_APP_ENV}_API_URL`]
        }/stripe/create-checkout-session?userid=${userid}`,
        {
          method: 'POST',
          body: JSON.stringify({
            key: paymentKey,
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => setClientSecret(data.clientSecret))
    }
    if (user) {
      createCheckoutSession()
    }
  }, [user, paymentKey, getAccessToken])

  const options = { clientSecret }
  return (
    <CheckoutContainer>
      <StripeContainer id="checkout">
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
          <EmbeddedCheckout className="stripeIFrame" />
        </EmbeddedCheckoutProvider>
      </StripeContainer>
    </CheckoutContainer>
  )
}
