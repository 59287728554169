import { add, isBefore } from 'date-fns'
import isAfter from 'date-fns/isAfter'
import { formatDate } from '../dateUtilities/dateUtilities'
import { MoneyEvent } from '../interfaces'

interface valueForDayReturnInterface {
  finalValue: number
  dateToRunningTotalMap: { [index: string]: number }
  savingsMap: { [index: string]: number }
  min: number
  soonestMin: number
  soonestMinDate: Date
  soonestDipBelowZero: number
  soonestDipBelowZeroDate: Date
  max: number
  minDate: string
  maxDate: string
  lastMonthAverage: number
  firstMonthAverage: number
}

interface valueForDayCacheInterface {
  [index: string]: valueForDayReturnInterface
}

const valueForDayCache: valueForDayCacheInterface = {}

// TESTED

/**
 *  ROB CONVO
 *
 * "How much can I spend today?"
 *
 *
 *
 * Take home pay - monthly costs that are fixed - savings set aside / how many days left in the month
 * free spend / days left = how much can I spend today
 *
 *
 *
 * fixed costs - how to define?
 * @returns
 */
export const valueForDay = (
  daysOut: number,
  _eventMap: { [index: string]: MoneyEvent[] },
  initialValue: number,
  initialSavingsValue: number,
  dateRange: Date[],
  cacheHitKey?: string
): valueForDayReturnInterface => {
  const potentialCacheHitKey = cacheHitKey
  if (potentialCacheHitKey) {
    const potentialCacheHit = valueForDayCache[potentialCacheHitKey]

    if (potentialCacheHit) {
      return potentialCacheHit
    }
  }
  /* {'2021-01-13': 1000, '2021-01-14': 1500, '2021-01-15': 2000} */
  const dateToRunningTotalMap: { [index: string]: number } = {}
  const savingsMap: { [index: string]: number } = {}
  const today = new Date()
  let min = Infinity
  let soonestMin = Infinity
  let soonestDipBelowZero = Infinity
  let soonestDipBelowZeroDate = new Date()
  let minDate = formatDate(new Date())
  let soonestMinDate = new Date()
  let max = -Infinity
  let maxDate = formatDate(new Date())

  const eventMap = _eventMap

  let runningTotal = initialValue || 0
  let runningSavingsTotal = initialSavingsValue || 0

  const sixMonthsFromNow = add(new Date(), { months: 6 })

  for (let i = 0; i <= daysOut; i++) {
    const _date = dateRange[i]
    const date = formatDate(_date)
    if (eventMap[date]?.length) {
      const eventsForDay = eventMap[date]
      const savingsEventsForDay = eventsForDay.filter((e) => e.target === 'savings')
      const totalSavingsForDay = savingsEventsForDay.reduce(
        (acc, event) => acc + (Number(event?.amount) || 0),
        0
      )

      const totalForDay = eventsForDay
        .filter((e) => e.target === 'cash' || e.target === undefined)
        .reduce((acc, event) => acc + (Number(event?.amount) || 0), 0)

      const newTotal = runningTotal + totalForDay

      const newSavingsTotal = runningSavingsTotal + totalSavingsForDay
      runningSavingsTotal = newSavingsTotal
      savingsMap[date] = newSavingsTotal

      runningTotal = newTotal
      dateToRunningTotalMap[date] = newTotal

      if (newTotal < 0 && soonestDipBelowZero === Infinity) {
        soonestDipBelowZero = newTotal
        soonestDipBelowZeroDate = _date
      }

      if (newTotal > max && isAfter(_date, today)) {
        max = newTotal
        maxDate = date
      }
      if (newTotal <= min && isAfter(_date, today)) {
        min = newTotal
        minDate = date
      }

      if (newTotal < soonestMin && isBefore(_date, sixMonthsFromNow) && isAfter(_date, today)) {
        soonestMin = newTotal
        soonestMinDate = _date
      }
    }
    dateToRunningTotalMap[date] = runningTotal
    savingsMap[date] = runningSavingsTotal

    if (runningTotal > max) {
      max = runningTotal
      maxDate = date
    }
    if (runningTotal <= min) {
      min = runningTotal
      minDate = date
    }

    if (
      runningTotal < soonestMin &&
      isBefore(_date, sixMonthsFromNow) &&
      isAfter(_date, add(today, { days: 1 }))
    ) {
      soonestMin = runningTotal
      soonestMinDate = _date
    }
  }

  // TODO: We have gained a decent chunk of performance (~10-13%) by removing this
  const firstMonthAverage =
    Object.values(dateToRunningTotalMap)
      .slice(0, 60)
      .reduce((acc, prev) => acc + prev) / 60
  const lastMonthAverage =
    Object.values(dateToRunningTotalMap)
      .slice(-60)
      .reduce((acc, prev) => acc + prev) / 60

  const result = {
    finalValue: runningTotal,
    dateToRunningTotalMap,
    savingsMap,
    min,
    soonestDipBelowZero,
    soonestDipBelowZeroDate,
    soonestMin,
    soonestMinDate: soonestMinDate,
    max,
    minDate,
    maxDate,
    lastMonthAverage,
    firstMonthAverage,
  }

  if (potentialCacheHitKey) {
    valueForDayCache[potentialCacheHitKey] = result
  }

  return result
}
