import { TextSpan } from 'Components/DesignSystem/Typography'
import {
  CategoryKind,
  useUserCustomSettings,
} from 'Providers/UserCustomSettingsProvider/UserCustomSettingsProvider'
import { numberToCurrency } from 'Utilities/currencyFormater'
import { BudgetTableContainer } from './CategoryBudgetTable.components'
import { useCategoryBudgetHelpers } from './helpers'

import { Row } from 'design-system/table/Row/Row'
import React from 'react'

interface Props {
  categoryType: 'custom' | 'plaid'
  categoryKind: CategoryKind
  onRowClick: (category: string) => void
  onLimitClick: (category: string) => void
  sortFunction: (a: string, b: string) => number
}

export const CategoryBudgetTable: React.FC<Props> = ({
  categoryType,
  categoryKind,
  onRowClick,
  onLimitClick,
  sortFunction,
}) => {
  const { settings } = useUserCustomSettings()
  // const { transactions } = useFinancialData()

  const { getCategoryTotal, getPlaidCategoryTotal } = useCategoryBudgetHelpers()

  // const categoriesToShow = categoryType === 'custom' ? customCategories : plaidCategories
  const categoriesToShow = Object.keys(settings?.categoryMetadata || {}).filter(
    (ckey) => settings?.categoryMetadata?.[ckey].kind === categoryKind
  )

  return (
    <div className="d-flex flex-column w-100">
      <BudgetTableContainer>
        {categoriesToShow.sort(sortFunction).map((ec) => {
          const customCategoryTotal = getCategoryTotal(ec)
          const plaidCategoryTotal = getPlaidCategoryTotal(ec)

          const categoryLimit = settings?.categoryMetadata?.[ec]?.limit || 0

          const categoryTotal = categoryType === 'custom' ? customCategoryTotal : plaidCategoryTotal

          if (!categoryLimit) return null

          const delta = categoryLimit - categoryTotal

          return (
            <Row
              key={ec}
              rowIconSize="sm"
              rowIcon={`fas fa-${
                categoryKind === 'regular' ? 'envelope-open-dollar' : 'puzzle-piece'
              }`}
              gridTemplateColumnString="minmax(0, 0.3fr) minmax(0, 1.75fr) minmax(0, 0.75fr) minmax(0, 1fr);"
              rowIconColor={categoryKind === 'regular' ? 'yellow' : 'textsupertransparent'}
              firstColumn={<u onClick={() => onLimitClick(ec)}>{ec}</u>}
              secondColumn={
                <TextSpan color={categoryKind === 'irregular' ? 'textsupertransparent' : 'yellow'}>
                  {numberToCurrency(categoryLimit)}
                </TextSpan>
              }
              thirdColumn={
                <TextSpan color={delta > 0 ? 'cashgreen' : 'mehred'}>
                  {numberToCurrency(delta, true, true)}
                </TextSpan>
              }
            />
          )
        })}

        {categoriesToShow.length === 0 && categoryType === 'custom' ? (
          <TextSpan className="p-3" color="texttransparent" size="xs">
            It seems like you haven't created any{' '}
            {categoryKind === 'regular' ? 'Anticipated' : 'Irregular'} categories. You're probably
            here for the first time!
            <br />
            <br />
            Create a Category using the Create Category button above and then open the Transactions
            Panel, click on a transaction, and assign it to that category.
          </TextSpan>
        ) : null}
      </BudgetTableContainer>
    </div>
  )
}
