import { SpringValue } from '@react-spring/web'
import { Icon, TextSpan } from 'Components/DesignSystem/Typography'
import { numberToCurrency } from 'Utilities/currencyFormater'
import { differenceInDays } from 'date-fns'
import { ItemText } from 'design-system/typography/Text/Text'
import React from 'react'
import { ReactEventHandlers } from 'react-use-gesture/dist/types'
import { WishlistItem } from '../WishlistProvider/WishlistProvider'
import { ItemIcon } from './ItemIcon'
import { Item, ItemLeftFlex } from './WishlistItemComponent.components'
import { DaysLeftToWaitBadge, PurchasedOnBadge, ResistedOnBadge } from './helpers'

interface Props {
  item: WishlistItem
  dragHandler: ReactEventHandlers
  onClick: (item: WishlistItem) => void
  animatedColor: SpringValue
  animatedIndex: SpringValue
  loading: boolean
  inactive?: boolean
  style: any
}
export const WishlistItemComponent: React.FC<Props> = ({
  item,
  dragHandler,
  onClick,
  style,
  animatedColor,
  animatedIndex,
  loading,
  inactive,
}) => {
  const { purchasedDate, resistedDate, reminderDate } = item
  const daysLeftToWait = differenceInDays(new Date(reminderDate), new Date())

  return (
    <Item
      priority={item.priority}
      style={{
        ...style,
        opacity: daysLeftToWait > 0 && !inactive ? 0.6 : 1,
        color: inactive ? 'gray' : 'inherit',
      }}>
      <ItemLeftFlex priority={animatedIndex} onClick={() => onClick(item)}>
        <ItemIcon priority={item.priority} inactive={inactive} item={item} />
        <div className="d-flex flex-column align-items-start justify-content-start">
          {/* <TextSpan
            weight={400}
            size="xxs"
            className="my-0"
            style={{
              whiteSpace: 'nowrap',
              transition: 'none',
              maxWidth: '25ch',
              overflow: 'hidden',
            }}> */}

          <ItemText>{item.name.toLowerCase()}</ItemText>
          {/* </TextSpan> */}
          <div className="d-flex align-items-start justify-content-start">
            {purchasedDate ? (
              <PurchasedOnBadge purchasedDate={purchasedDate} />
            ) : resistedDate ? (
              <ResistedOnBadge resistedDate={resistedDate} />
            ) : (
              <>
                <DaysLeftToWaitBadge daysLeftToWait={daysLeftToWait} />
                {/* <DesireBadge desire={desire} /> */}
                {/* <TimeFactorBadge timeFactor={timeFactor} />
                <NewnessBadge newnessFactor={newnessFactor} /> */}
              </>
            )}
          </div>
        </div>
      </ItemLeftFlex>

      <div className="d-flex align-items-center justify-content-end">
        <TextSpan
          weight={500}
          size="xs"
          style={{
            transition: 'none',
          }}>
          {numberToCurrency(Number(item.price), true, false)}
        </TextSpan>

        <Icon
          className="fas fa-grip-dots ml-2"
          color="textsupertransparent"
          // className="fas fa-grip-lines ml-2"
          // className="fal fa-bars ml-2"
          size="xxxl"
          {...(inactive ? {} : dragHandler)}
          style={{
            touchAction: 'none',
            // color: 'inherit',
            opacity: inactive ? 0 : 1,
            // opacity: loading ? 0.5 : 1,
            transition: 'opacity ease-in-out .3s',
          }}
        />
      </div>

      {/* <ItemStatus
        style={{
          backgroundColor: 'transparent',
        }}
        priority={item.priority}
        days={daysAgo}
        $itemid={item._id || ''}>
        <div className="d-flex flex-row align-items-center ml-1 pr-3 mb-1 justify-content-around w-100">
          {Array.from({ length: 30 }, (_, i) => i + 1).map((item) => {
            return (
              <animated.div
                style={{
                  width: item < daysAgo ? '3px' : '2px',
                  height: item < daysAgo ? '3px' : '2px',
                  borderRadius: '50%',
                  opacity: item < daysAgo ? 1 : 0.3,
                  color: 'inherit',
                  backgroundColor: animatedColor,
                }}
              />
            )
          })}
        </div>
      </ItemStatus> */}
    </Item>
  )
}
