import { MCOLBadge } from 'Components/MCOLBadge'
import { ReflectionCarousel } from 'Pages/SpendingPage/ReflectionReview/ReflectionCarousel/ReflectionCarousel'
import { AddCustomEnvelopePage } from 'Pages/VariablePage/AddCustomEnvelopePage/AddCustomEnvelopePage'
import { useUserCustomSettings } from 'Providers/UserCustomSettingsProvider/UserCustomSettingsProvider'
import { VARIABLE_ICON } from 'Utilities/navigationTabUtilities'
import { IconButton } from 'design-system/buttons/IconButton/IconButton'
import React from 'react'
import { ContainerDiv } from '../../../Components/DesignSystem/Layout/Containers/Containers'
import { StyledHr, StyledVr, TextSlim, TextSpan } from '../../../Components/DesignSystem/Typography'
import { useFinancialData } from '../../../Providers/FinancialDataProvider/FinancialDataProvider'
import { useUIState } from '../../../Providers/UIStateProvider'
import { numberToCurrency } from '../../../Utilities/currencyFormater'
import { BackButton } from '../MysteryDayBackButton'
import { ForwardButton } from '../MysteryDayForwardButton'
import { SubPageProps } from '../MysteryPage'

export const CostOfLivingVariablePage: React.FC<SubPageProps> = () => {
  const { setMessagePopupContent } = useUIState()
  const {
    flexibleSpendPerMonth,
    // setFlexibleSpendPerMonth,
    flatAccounts,
    transactionsData,
    getTransactions,
    transactions,
  } = useFinancialData()

  const { settings } = useUserCustomSettings()

  // TODO: visualize in here the type of flexible spend calc that happened (manually entered vs. averaged)
  // and include a tooltip popup that explains how those numbers got derived(or explains to
  //  the user that connecting through plaid is more accurate)

  const forwardProgressDisabled = !flexibleSpendPerMonth

  const { setAddCustomEnvelopePageOpen } = useUIState()

  const categoriesToShow = Object.keys(settings?.categoryMetadata || {})
  const categoriesDetails = Object.values(settings?.categoryMetadata || {})

  const { setModalContent } = useUIState()

  React.useEffect(() => {
    if (transactionsData.status === 'idle' && transactions.length === 0) {
      getTransactions({})
    }
  }, [transactions.length, getTransactions, transactionsData.status])

  return (
    <ContainerDiv className="justify-content-between align-items-center py-3">
      <AddCustomEnvelopePage />
      {/* <TransitionFromAbove show={variableWizardryPageOpen}>
        <PlaidWizardryPage
          mode="variable"
          closePage={() => {
            setVariableWizardryPageOpen(false)
          }}
        />
      </TransitionFromAbove> */}
      <ContainerDiv className="justify-content-start align-items-center ">
        <div className="d-flex flex-row align-items-center position-relative">
          <MCOLBadge color="yellow" />
          <StyledVr color="texttransparent" className="mx-2" />
          {/* <Icon className="fas fa-arrow-right mx-2" size="sm" color="yellow" /> */}
          <TextSlim size="lg" weight={200} color="texttransparent">
            Variable
          </TextSlim>
        </div>

        <TextSlim size="xs" className="px-4" color="textsupertransparent">
          Gas, groceries, takeout, date nights, live music, gifts, hobbies, etc make up variable
          spending.
          <br />
          <br />
          The goal for now is to just get an estimate of your variable spend up until now. We will
          use this as a starting point before starting to recommend areas to reduce
        </TextSlim>

        {/* <StyledHr className="mt-2 mb-3" color="textsupertransparent" /> */}

        {categoriesToShow ? (
          <div
            style={{
              boxShadow: 'inset hsl(243 19% 18% / .5) 0 0px 7px 5px',
              border: 'solid 1px #6362752e',

              maxHeight: '120px',
              overflowY: 'scroll',
            }}
            className="px-2 w-100">
            {categoriesToShow.map((ec, index) => {
              const categoryDetail = categoriesDetails[index].kind

              const categoryLimit = settings?.categoryMetadata?.[ec]?.limit || 0

              if (!categoryLimit) return null

              return (
                <div
                  className="d-flex w-100 justify-content-between align-items-center py-1 w-100"
                  key={ec}
                  onClick={() => {
                    setAddCustomEnvelopePageOpen({ val: ec, cb: () => {} })
                  }}>
                  <TextSpan
                    color="texttransparent"
                    weight={700}
                    className="d-flex align-items-center"
                    size="xs"
                    style={{
                      whiteSpace: 'nowrap',
                    }}>
                    <u>{ec}</u>
                    <TextSpan
                      color="textsupertransparent"
                      weight={200}
                      className="d-flex align-items-center ml-2"
                      size="xs"
                      style={{
                        whiteSpace: 'nowrap',
                      }}>
                      ({categoryDetail === 'regular' ? 'anticipated' : 'irregular'})
                    </TextSpan>
                  </TextSpan>
                  <StyledHr className="px-2 mx-2" weight={1} color="nearlyclear" />

                  <TextSpan
                    color="yellow"
                    weight={700}
                    size="xs"
                    className="d-flex align-items-center">
                    {numberToCurrency(categoryLimit)}
                  </TextSpan>
                </div>
              )
            })}
          </div>
        ) : null}

        {flexibleSpendPerMonth ? (
          <>
            <StyledHr className="my-2" color="nearlyclear" weight={1} />
            <div
              data-testid="no-streams-container"
              className="px-2 w-100 d-flex align-items-center justify-content-around flex-row">
              <IconButton
                color="yellow"
                iconClassName={`${VARIABLE_ICON} mx-2`}
                title="add another"
                onClick={() => {
                  setAddCustomEnvelopePageOpen({ val: '', cb: () => {} })
                }}
              />
              <TextSpan color="textsupertransparent">or</TextSpan>
              <IconButton
                color="logoPrimary"
                disabled={!flatAccounts.length || !transactions.length}
                onDisabledClick={() => {
                  setMessagePopupContent(
                    'Live Account Needed<>We can do fancy magic for you and detect your fixed expenses, but to do that, we need you to connect your real checking and credit card accounts.<>If you want to stick with adding your data manually vs. connecting to Plaid, you can do your own analysis on your variable spending<>If you want fancy magic to happen, go back to the previous page and connect a live bank account'
                  )
                }}
                iconClassName={`fas fa-rectangle-vertical-history mx-2 fa-fade`}
                title="reflect on more"
                onClick={() => setModalContent(<ReflectionCarousel />)}
              />
            </div>
            <StyledHr className="my-1" color="nearlyclear" weight={1} />
          </>
        ) : (
          <>
            <StyledHr className="my-2" color="nearlyclear" weight={1} />
            <div
              data-testid="no-streams-container"
              className="px-2 w-100 d-flex align-items-center justify-content-around flex-row">
              <IconButton
                color="yellow"
                iconClassName="far fa-pencil mx-2"
                title={flexibleSpendPerMonth ? 'override value' : 'create first\n manual envelope'}
                onClick={() => {
                  setAddCustomEnvelopePageOpen({ val: '', cb: () => {} })
                }}
              />
              <TextSpan color="textsupertransparent">or</TextSpan>
              <IconButton
                color="logoPrimary"
                disabled={!flatAccounts.length || !transactions.length}
                onDisabledClick={() => {
                  setMessagePopupContent(
                    'Live Account Needed<>We can do fancy transaction reflections for you and start building out your envelopes, but to do that, we need you to connect your real checking and credit card accounts.<>If you want to stick with adding your data manually vs. connecting to Plaid, you can add your manual envelopes here one by one<>If you want fancy magic to happen, go back to the previous page and connect a live bank account'
                  )
                }}
                iconClassName={`fas fa-rectangle-vertical-history mx-2 fa-fade`}
                title={
                  flexibleSpendPerMonth ? 'recalculate' : 'create envelopes from live transactions'
                }
                onClick={() => setModalContent(<ReflectionCarousel />)}
              />
            </div>
            <StyledHr className="my-1" color="nearlyclear" weight={1} />
          </>
        )}
      </ContainerDiv>
      <div className="d-flex justify-content-between align-items-center w-100 flex-column">
        <TextSpan weight={700} size="lg" color="yellow">
          <TextSpan color="texttransparent" size="xs" className="mr-2">
            Variable Spending =
          </TextSpan>{' '}
          {numberToCurrency(Math.abs(flexibleSpendPerMonth), true)} / m
          {/* {flexibleSpendPerMonth ? (
            <TextPopupSmall
              color="yellow"
              className="ml-2 p-2"
              onClick={() => {
                setMessagePopupContent(
                  calculationMethod === 'manual'
                    ? 'Manual Entry<>Great! We can use this value for the rest of onboarding to get a rough sense of your spending.'
                    : `Historical Average<> We average your credit and debit card spending from the last two months to get this number. We also filter out certain types of transactions that are likely not variable spending (bank transfers, deposits, etc). Here are some details<>Spend This Month: ${numberToCurrency(
                        currentVariableExpenseAverage?.thisMonth?.total || 0
                      )}<>Spending Last Month: ${currentVariableExpenseAverage?.lastMonth.total}`
                )
              }}>
              <Icon color="yellow" size="lg" className="fas fa-circle-info" />
            </TextPopupSmall>
          ) : null} */}
        </TextSpan>

        <div className="d-flex justify-content-between align-items-center w-100">
          <BackButton />
          <ForwardButton disabled={forwardProgressDisabled} title={'Finish'} />
        </div>
      </div>
    </ContainerDiv>
  )
}
