import { ContainerCutout } from 'Components/DesignSystem/Layout/Containers/Containers'
import React from 'react'
import { LegendBudgetRow } from './LegendBudgetRow'

export const variablePageSubHeaderSize = 49
// export const variablePageSubHeaderSize = 46 + 51

interface VariablePageSubHeaderProps {
  onEnvelopeClick: () => void
  onBudgetClick: () => void
  onRemainderClick: () => void
  sortingOrder: number
  sortingStrategy: 'envelope' | 'budget' | 'remainder' | 'null'
}
export const VariablePageSubHeader: React.FC<VariablePageSubHeaderProps> = ({
  onEnvelopeClick,
  onBudgetClick,
  onRemainderClick,
  sortingOrder,
  sortingStrategy,
}) => {
  return (
    <ContainerCutout size={variablePageSubHeaderSize}>
      <LegendBudgetRow
        onEnvelopeClick={onEnvelopeClick}
        onBudgetClick={onBudgetClick}
        onRemainderClick={onRemainderClick}
        sortingOrder={sortingOrder}
        sortingStrategy={sortingStrategy}
      />
    </ContainerCutout>
  )
}
