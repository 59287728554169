import { Icon, TextSpan } from 'Components/DesignSystem/Typography'
import { numberToCurrency } from 'Utilities/currencyFormater'
import React from 'react'

// const statusToColor: {
//   [key in 'good' | 'warn' | 'bad']: ColorType
// } = {
//   good: 'logoPrimary',
//   warn: 'yellow',
//   bad: 'mehred',
// }

interface SpendingLimitTagProps {
  limit: number | undefined
  status?: 'good' | 'warn' | 'bad'
}
export const SpendingLimitTag: React.FC<SpendingLimitTagProps> = ({ limit, status = 'good' }) => {
  if (!limit) {
    return (
      <TextSpan color="texttransparent" size="xxs" className="mr-1 my-0">
        <Icon
          style={
            {
              '--fa-beat-fade-opacity': 0.67,
              '--fa-beat-scale': 1.27,
            } as React.CSSProperties
          }
          className="fa-solid fa-chart-mixed-up-circle-dollar mr-1 fa-beat-fade"
          color="texttransparent"
          size="xxs"
        />
        missing limit
      </TextSpan>
    )
  }
  return (
    <TextSpan className="mt-0 ml-0 mb-0" size="xxs" color={'text'} weight={500}>
      <Icon size="xxs" color={'text'} className={`fa-solid fa-chart-mixed-up-circle-dollar mr-1`} />
      {numberToCurrency(limit || 0)}
      <TextSpan size="xxs" color={'text'} className="ml-1 my-0" weight={200}>
        limit
      </TextSpan>
    </TextSpan>
  )
}
