import React, { useEffect } from 'react'
import { ContainerDiv } from '../../../Components/DesignSystem/Layout/Containers/Containers'
import { CustomLi, CustomUl, TextSlim } from '../../../Components/DesignSystem/Typography'
import { useData } from '../../../Providers/APIDataProvider'
import { useMysteryDay } from '../../../Providers/MysteryDayProvider/MysteryDayProvider'
import { getMonthlyIncome } from '../../../Utilities/netWorth/netWorth'
import { CashflowRecap } from '../CashFlowRecap/CashFlowRecap'
import { BackButton } from '../MysteryDayBackButton'
import { ForwardButton } from '../MysteryDayForwardButton'
import { SubPageProps } from '../MysteryPage'
import { BigWhyModule } from './BigWhyModule'

export const ContributionSettingPage: React.FC<SubPageProps> = () => {
  const { setMonthlyContribution, mysteryMode } = useMysteryDay()
  const { streams, monthlyBurnRate } = useData()

  const monthlyIncome = getMonthlyIncome(streams)

  const positiveCashFlow = monthlyIncome > monthlyBurnRate

  const recommendedContribution = monthlyIncome - monthlyBurnRate

  useEffect(() => {
    setMonthlyContribution(recommendedContribution < 0 ? 100 : recommendedContribution)
  }, [recommendedContribution, setMonthlyContribution])

  const NegativeCashFlowRecommendation = () => {
    return (
      <section>
        <TextSlim size="sm" className="px-2" color="texttransparent">
          It appears you are actually losing money even without paying towards your goal.
        </TextSlim>

        <TextSlim size="sm" className="px-2" color="texttransparent">
          You may continue to the dashboard to see how lowering your expenses will help you carve
          out enough to make payments towards your debt.
        </TextSlim>
      </section>
    )
  }

  const PositiveCashFlowRecommendation = () => {
    return (
      <section>
        <div className="d-flex w-100 flex-column justify-content-between align-items-center">
          <TextSlim size="sm" className="px-2" color="texttransparent">
            A good monthly payment needs a balance of{' '}
          </TextSlim>
          <CustomUl>
            <CustomLi>
              How <b>fast</b> do you want to meet your goal?
            </CustomLi>
            <CustomLi>
              How <b>feasible</b> is that monthly payment?
            </CustomLi>
          </CustomUl>
          <TextSlim size="sm" className="px-2" color="texttransparent">
            The dashboard will help you find this balance.
          </TextSlim>
        </div>
      </section>
    )
  }

  switch (mysteryMode) {
    case 'saveToQuit':
      return (
        <ContainerDiv className="justify-content-between align-items-center py-3">
          <ContainerDiv className="justify-content-start align-items-center py-3">
            <TextSlim size="lg" className="px-2 mb-1" color="texttransparent">
              Almost done!! Here's a recap
            </TextSlim>

            <CashflowRecap />

            <BigWhyModule />

            <TextSlim size="xs" className="px-3" color="texttransparent">
              To reset your onboarding and go through this flow again, open Settings from the top
              right and click 'Reset Onboarding'
            </TextSlim>
          </ContainerDiv>
          <div className="d-flex justify-content-between align-items-center w-100">
            <BackButton lite />

            <ForwardButton
              // TODO: Need to think about how non-paycheck people will be handled here
              end={true}
              // disabled={!bigWhy}
              title={'See Dashboard 🎉'}
            />
          </div>
        </ContainerDiv>
      )
    case 'buildCushion':
      return (
        <ContainerDiv className="justify-content-between align-items-center py-3">
          <ContainerDiv className="justify-content-start align-items-center py-3">
            <TextSlim size="lg" className="px-2 mb-1" color="texttransparent">
              Almost done!! Here's a recap
            </TextSlim>

            <CashflowRecap />

            <TextSlim className="mt-4 px-2">
              Once you enter the dashboard, you can adjust your monthly expense on the fly to see
              how reducing your standard of living will help bump out how long your savings will
              last
            </TextSlim>

            <BigWhyModule />

            <TextSlim size="xs" className="px-3" color="texttransparent">
              To reset your onboarding and go through this flow again, open Settings from the top
              right click 'Reset Onboarding'
            </TextSlim>
          </ContainerDiv>
          <div className="d-flex justify-content-between align-items-center w-100">
            <BackButton lite />

            <ForwardButton
              // TODO: Need to think about how non-paycheck people will be handled here
              end={true}
              // disabled={!bigWhy}
              title={'See Dashboard 🎉'}
            />
          </div>
        </ContainerDiv>
      )

    case 'getOutOfDebt':
    case 'getOrganized':
    default:
      return (
        <ContainerDiv className="justify-content-between align-items-center py-3">
          <ContainerDiv className="justify-content-start align-items-center py-3">
            <TextSlim size="lg" className="px-2 mb-1" color="texttransparent">
              Almost done!! Here's a recap
            </TextSlim>

            <CashflowRecap />

            {positiveCashFlow ? (
              <PositiveCashFlowRecommendation />
            ) : (
              <NegativeCashFlowRecommendation />
            )}

            <BigWhyModule />

            <TextSlim size="xs" className="px-3" color="texttransparent">
              To reset your onboarding and go through this flow again, open Settings from the top
              right and click 'Reset Onboarding'
            </TextSlim>
          </ContainerDiv>
          <div className="d-flex justify-content-between align-items-center w-100">
            <BackButton lite />

            <ForwardButton
              // TODO: Need to think about how non-paycheck people will be handled here
              // disabled={!bigWhy}
              end={true}
              title={'See Dashboard 🎉'}
            />
          </div>
        </ContainerDiv>
      )
  }
}
