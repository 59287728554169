import { useLocalStorageSyncedState } from 'Hooks/useLocalStorageSyncedState'
import React from 'react'

interface SplurvSnippetContextState {
  visitedSnippets: string[]
  visitSnippet: (snippet: string) => void
}

const SplurvSnippetContext = React.createContext<SplurvSnippetContextState>({
  visitedSnippets: [],
  visitSnippet: () => {},
})

export const useSplurvSnippets = () => React.useContext(SplurvSnippetContext)

export const SplurvSnippetProvider: React.FC = ({ children }) => {
  const [visitedSnippets, setVisitedSnippets] = useLocalStorageSyncedState<string[]>(
    [],
    'splurv-snippets-visited-snippets'
  )

  const visitSnippet = (snippet: string) => {
    setVisitedSnippets([...visitedSnippets, snippet])
  }

  return (
    <SplurvSnippetContext.Provider
      value={{
        visitedSnippets,
        visitSnippet,
      }}>
      {children}
    </SplurvSnippetContext.Provider>
  )
}
