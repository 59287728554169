import { Icon, TextSpan } from 'Components/DesignSystem/Typography'
import React from 'react'

import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import {
  MysteryDayType,
  cushionSubModeToTitle,
} from 'Providers/MysteryDayProvider/MysteryDayProvider.interfaces'
import { MysteryModeButtonTitlesFromMysteryDayType, colorFromMysteryDayType } from 'Utilities/utils'
import { GoalBrandSplurvText } from './GoalBrandSplurvText'

const getModeIcon = (mysteryMode: MysteryDayType) => {
  switch (mysteryMode) {
    case 'getOrganized':
      return 'fas fa-party-horn'
    case 'saveToQuit':
      return 'fas fa-rocket'
    case 'getOutOfDebt':
      return 'fas fa-sun'
    case 'buildCushion':
      return 'fas fa-clouds'
    case 'default':
      return 'fas fa-arrow-right-to-arc'
  }
}

export const LogoText: React.FC = () => {
  const { mysteryMode, cushionSubMode, itemName } = useMysteryDay()
  const { title } = MysteryModeButtonTitlesFromMysteryDayType(mysteryMode)

  const icon = getModeIcon(mysteryMode)
  const color = colorFromMysteryDayType(mysteryMode)
  return (
    <div className="d-flex flex-row align-items-start">
      {/* <img
        referrerPolicy="no-referrer"
        alt=""
        style={{ height: '56px' }}
        src={logo}
        className="mr-3"
      /> */}
      <div className="d-flex flex-column align-items-center mt-1">
        <GoalBrandSplurvText />
        <TextSpan
          weight={200}
          className="my-0 mt-1"
          fontFamily="primary"
          color={color}
          size="sm"
          style={{
            // textTransform: 'uppercase',
            opacity: mysteryMode === 'default' ? 0 : 0.6,
          }}>
          <Icon className={`${icon} mr-2`} color={color} />
          {title}
          {mysteryMode === 'buildCushion' && cushionSubMode ? (
            <div className="d-flex flex-row align-items-center">
              <Icon className={`fas fa-arrow-right mx-1`} color={color} />

              <TextSpan
                weight={200}
                className="my-0"
                fontFamily="primary"
                color={color}
                size="xxs"
                style={
                  {
                    // textTransform: 'uppercase',
                  }
                }>
                {cushionSubModeToTitle[cushionSubMode]}
              </TextSpan>
            </div>
          ) : mysteryMode === 'getOrganized' && itemName ? (
            <TextSpan
              weight={200}
              className="my-0 ml-1"
              color={'megapurple'}
              size="xxs"
              style={
                {
                  // textTransform: 'uppercase',
                }
              }>
              - "{itemName}"
            </TextSpan>
          ) : null}
        </TextSpan>
      </div>
    </div>
  )
}
