import React, { useEffect, useState } from 'react'

import { isMobile } from 'Utilities/utils'
import * as serviceWorkerRegistration from '../../serviceWorkerRegistration'

import { datadogRum } from '@datadog/browser-rum'
import { Icon } from 'Components/DesignSystem/Typography'
import { SPLURV_LOCAL_STORAGE_PREFIX } from 'Hooks/useLocalStorageSync'
import qs from 'qs'
import { ToastContainer, toast } from 'react-toastify'
import { AppLandingPage } from './components/AppLandingPage'

interface PWAProps {
  updateAvailable: boolean
  isOffline: boolean
  isCached: boolean
  showDownloadPrompt: boolean

  registration: ServiceWorkerRegistration | null
}

const pwaDebugPrefix = '💧 (PWAProvider) '
const pwaDebug = (msg: any) =>
  console.debug(`%c ${pwaDebugPrefix} ${msg}`, 'background: #222; color: #C7FDFF')

const PWAContext = React.createContext<PWAProps>({
  isOffline: false,
  updateAvailable: false,
  isCached: false,
  showDownloadPrompt: false,

  registration: null,
})
export const usePWA = () => React.useContext(PWAContext)

// const isProduction = true
const isProduction = process.env.NODE_ENV === 'production'

const allowSecretProductionAppAccess = localStorage.getItem(
  `${SPLURV_LOCAL_STORAGE_PREFIX}__allow-secret-production-app-access`
)

// const isProduction = true
/**
 * Responsible for managing the PWA state of the app. This will handle the logic for whether
 * to show our custom PWA CTA for adding to home screen or render the children aak the rest of the app.
 * @returns
 */
export const PWAProvider: React.FC = ({ children }) => {
  // eslint-disable-next-line
  const [updateAvailable, setUpdateAvailable] = useState(false)
  const [isOffline, setIsOffline] = useState(false)
  // eslint-disable-next-line
  const [isCached, setIsCached] = useState(false)
  const [showDownloadPrompt, setShowDownloadPrompt] = useState(false)
  const [isDesktop, setIsDesktop] = useState(false)

  const nativeAppBypass = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    .nativeAppBypass

  // const allowSecretProductionAppAccess = React.useMemo(
  //   () =>
  //     localStorage.getItem(`${SPLURV_LOCAL_STORAGE_PREFIX}__allow-secret-production-app-access`),
  //   []
  // )

  const [registration, setRegistration] = useState<ServiceWorkerRegistration | null>(null)

  useEffect(() => {
    const mobileDevice = isMobile()
    setIsDesktop(!mobileDevice)
    const standaloneDisplay = window.matchMedia('(display-mode: standalone)').matches

    datadogRum.addRumGlobalContext('pwaProvider', {
      standaloneDisplay: standaloneDisplay ? 'true' : 'false',
      mobileDevice: mobileDevice ? 'true' : 'false',
    })

    /**
     * UNCOMMENT ME TO TEST THE PWA PROMPT ON DEV AKA show the app
     */
    const isProduction = process.env.NODE_ENV === 'production'
    /**
     * UNCOMMENT ME TO TEST THE PWA PROMPT ON PROD
     */
    // const isProduction = true
    const isCypressWelcomeTest = (window as any).CYPRESS_PROD_MODE

    if (mobileDevice && !standaloneDisplay && !allowSecretProductionAppAccess) {
      setShowDownloadPrompt(true)
      if (!isProduction && !isCypressWelcomeTest) {
        setShowDownloadPrompt(false)
      }
    } else {
      setShowDownloadPrompt(false)
    }
  }, [])

  useEffect(() => {
    let timer: NodeJS.Timeout

    const handleOnlineOfflineEvents = () => {
      if (navigator.onLine) {
        setIsOffline(false)
      } else {
        setIsOffline(true)
      }
    }

    const handleServiceWorkerMessage = (e: MessageEvent) => {
      pwaDebug('message from service worker')
      datadogRum.addAction('pwa-debug-message', {
        'pwa-debug-message': {
          data: e.data,
        },
      })
      pwaDebug(JSON.stringify(e))
      if (e.data.type === 'INSTALL_COMPLETE') {
        pwaDebug('INSTALL_COMPLETE 🎉')
      }

      if (e.data.type === 'ACTIVATE') {
        pwaDebug('ACTIVATE 🎉')
      }
      if (e.data.type === 'PUSH') {
        pwaDebug('PUSH MESSAGE 🎉')
        pwaDebug(`${JSON.stringify(e.data)}`)
        datadogRum.addAction('pwa-debug', {
          'pwa-debug': {
            type: 'push',
            data: e.data,
          },
        })
      }
      if (e.data.type === 'OFFLINE') {
        return
      }
      if (e.data.type === 'ONLINE') {
        return
      }
      if (e.data.type === 'SKIP_WAITING') {
        return
      }
    }

    window.addEventListener('offline', handleOnlineOfflineEvents)
    window.addEventListener('online', handleOnlineOfflineEvents)
    window?.navigator?.serviceWorker?.addEventListener('message', handleServiceWorkerMessage)

    window?.navigator?.serviceWorker?.ready?.then((r) => {
      pwaDebug('window.navigator.serviceWorker.ready')
      setRegistration(r)
    })

    serviceWorkerRegistration?.register({
      onSuccess(registration) {
        pwaDebug('onSuccess')
        setIsCached(true)

        if (!showDownloadPrompt && window.matchMedia('(display-mode: standalone)').matches) {
          toast('App Downloaded - you can take me offline!', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            icon: () => <Icon className="fas fa-signal-bars-slash" color="second" />,
            type: 'info',
            progress: undefined,
            theme: 'dark',
          })
        }
      },

      onRegistrationSuccess: () => {
        pwaDebug('onRegistrationSuccess')
        setIsCached(true)
      },
      onError: () => {
        console.error(`${pwaDebugPrefix} onError`)
      },
      onUpdate: (r) => {
        pwaDebug('onUpdate -> sending message to skip waiting')
        // Take the service worker whose state is 'installed'
        // (meaning, it is downloaded from the internet and setup to be used)
        // and tell it to activate itself
        r.waiting?.postMessage({ type: 'SKIP_WAITING' })

        // Delay by a second to allow the SKIP_WAITING message to get propagated and have the new service worker
        // actually become activated
        timer = setTimeout(() => {
          setUpdateAvailable(true)
        }, 2000)
      },
      onFailedUpdate: () => {
        console.warn(`${pwaDebugPrefix} onFailedUpdate`)
      },
      onSuccessUpdate: () => {
        pwaDebug('onSuccessUpdate')
        setIsCached(true)
      },
    })

    return () => {
      window.removeEventListener('offline', handleOnlineOfflineEvents)
      window.removeEventListener('online', handleOnlineOfflineEvents)
      window?.navigator?.serviceWorker?.removeEventListener('message', handleServiceWorkerMessage)
      clearTimeout(timer)
    }
    // eslint-disable-next-line
  }, [])

  if (
    (showDownloadPrompt && !allowSecretProductionAppAccess && !nativeAppBypass) ||
    (isDesktop && isProduction && !allowSecretProductionAppAccess && !nativeAppBypass)
  ) {
    return (
      <PWAContext.Provider
        value={{ isOffline, updateAvailable, showDownloadPrompt, registration, isCached }}>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <AppLandingPage />
      </PWAContext.Provider>
    )
  } else {
    return (
      <PWAContext.Provider
        value={{ isOffline, updateAvailable, showDownloadPrompt, registration, isCached }}>
        {children}
      </PWAContext.Provider>
    )
  }
}

// return (
//   <PWAContext.Provider
//     value={{ isOffline, updateAvailable, showDownloadPrompt, registration, isCached }}>
//     {showDownloadPrompt ? (
//       <>
//         <ToastContainer
//           position="bottom-right"
//           autoClose={5000}
//           hideProgressBar
//           newestOnTop={false}
//           closeOnClick
//           rtl={false}
//           pauseOnFocusLoss
//           draggable
//           pauseOnHover
//           theme="colored"
//         />
//         {/* <UpdateAppButton /> */}
//         <AppLandingPage />
//       </>
//     ) : isDesktop && isProduction ? (
//       <>
//         <ToastContainer
//           position="bottom-right"
//           autoClose={5000}
//           hideProgressBar
//           newestOnTop={false}
//           closeOnClick
//           rtl={false}
//           pauseOnFocusLoss
//           draggable
//           pauseOnHover
//           theme="colored"
//         />
//         {/* <UpdateAppButton /> */}
//         <AppLandingPage />
//       </>
//     ) : (
//       children
//     )}
//   </PWAContext.Provider>
// )
