import { Icon, StyledHr, TextSpan } from 'Components/DesignSystem/Typography'
import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import { useUIState } from 'Providers/UIStateProvider'
import { BodyText } from 'design-system/typography/Text/Text'
import React from 'react'
import { colorPalletes } from 'theme'

interface Props {
  updateMode: boolean
}
export const AccountTrackingPageHeader: React.FC<Props> = ({ updateMode }) => {
  const { setPickExistingAccountPageOpen, activeTheme } = useUIState()
  const {
    mysteryMode,

    setLinkedAccount,
  } = useMysteryDay()

  const bodyFromMode =
    mysteryMode === 'getOutOfDebt'
      ? 'Connect your debt goal to an account so that as you make payments towards it, your progress will be updated. If you connect a live account, payments will be automatically tracked. If you connect a manual account, you will need to manually enter your payments.'
      : mysteryMode === 'getOrganized' ||
        mysteryMode === 'buildCushion' ||
        mysteryMode === 'saveToQuit'
      ? 'Connect your savings goal to an account so that as you make contributions towards it, your progress will be updated. If you connect a live account, contributions will be automatically tracked. If you connect a manual account, you will need to manually enter your contributions.'
      : 'Connect your emergency cash account so that as you make deposits, you can see your progress'
  return (
    <>
      <div className="d-flex flex-row align-items-center justify-content-between w-100">
        <div className="d-flex flex-row align-items-center justify-content-start w-100">
          <Icon
            size="xl"
            color="cashgreen"
            style={
              {
                '--fa-primary-color': colorPalletes[activeTheme].textsupertransparent,
                '--fa-secondary-color': colorPalletes[activeTheme].text,
                '--fa-secondary-opacity': 1,
              } as React.CSSProperties
            }
            className="fa-duotone fa-chart-line-up-down ml-2 px-2"
          />
          <TextSpan size="md" color="text" className="mr-1">
            Account
          </TextSpan>
          <TextSpan size="md" color="textsupertransparent">
            Tracking
          </TextSpan>
          {updateMode ? (
            <TextSpan size="xxxs" color="textsupertransparent" className="ml-1">
              (update)
            </TextSpan>
          ) : null}
        </div>
        <Icon
          className="fas fa-times ml-2"
          size="xl"
          onClick={() => {
            setPickExistingAccountPageOpen(false)
            setLinkedAccount(null)
          }}
        />
      </div>

      <BodyText className="my-2 px-4">{bodyFromMode}</BodyText>
      <StyledHr className="my-2" />
    </>
  )
}
