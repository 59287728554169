import { Icon, TextBold, TextSpan } from 'Components/DesignSystem/Typography'
import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import { useUIState } from 'Providers/UIStateProvider'
import React from 'react'
import { useHistory } from 'react-router'
import { SettingsItem } from '../components'

export const ResetOnboarding: React.FC = () => {
  const { setSettingsPageOpen } = useUIState()

  const { push } = useHistory()
  const {
    deleteUserOnboardingSetup,
    deleteUserOnboardingSetupLoading,
    resetOnboarding,
    getUserOnboardingSetupLoading,
  } = useMysteryDay()

  const onboardingResetLoading = deleteUserOnboardingSetupLoading || getUserOnboardingSetupLoading

  return (
    <SettingsItem
      onClick={async () => {
        const answer = window.confirm(
          'Are you sure you would like to reset onboarding? You will have to enter details of your goal again.'
        )
        if (answer) {
          resetOnboarding()
          await deleteUserOnboardingSetup()
          push('/mystery')
          setSettingsPageOpen(false)
        }
      }}>
      <div className="d-flex flex-column w-100 align-items-start">
        <div className="d-flex flex-row w-100 align-items-center">
          <Icon className={`fas fa-rotate-left ${onboardingResetLoading ? 'fa-spin' : ''}`} />
          <TextBold>{onboardingResetLoading ? 'Resetting....' : 'Reset Onboarding'}</TextBold>
        </div>
        <TextSpan size="xxs" color="texttransparent">
          Reset your dashboard page to a new goal
        </TextSpan>
      </div>
    </SettingsItem>
  )
}
