import { Icon, TextBold, TextSpan } from 'Components/DesignSystem/Typography'
import { useLocalStorageSyncedState } from 'Hooks/useLocalStorageSyncedState'
import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import { cushionSubModeToTitle } from 'Providers/MysteryDayProvider/MysteryDayProvider.interfaces'
import {
  MysteryModeButtonTitlesFromMysteryDayType,
  colorFromMysteryDayType,
  iconFromMysteryDayType,
} from 'Utilities/utils'
import React from 'react'
import { useMysteryModeFinalDate } from './hooks'

export const BigGoal: React.FC = () => {
  const { mysteryMode, cushionSubMode, itemName } = useMysteryDay()

  const { finalDateToShow, yearsLeft, monthsLeft } = useMysteryModeFinalDate()

  const [coastViewMode, setCoastViewMode] = useLocalStorageSyncedState<'simple' | 'complex'>(
    'simple',
    'coast-view-mode'
  )

  return (
    <div
      className="d-flex flex-column align-items-center"
      onClick={() =>
        setCoastViewMode((viewMode) => (viewMode === 'complex' ? 'simple' : 'complex'))
      }>
      <TextBold
        color={colorFromMysteryDayType(mysteryMode)}
        weight={200}
        className="my-0"
        size="md">
        <Icon
          size="sm"
          className={`${iconFromMysteryDayType(mysteryMode)} mr-1`}
          color={colorFromMysteryDayType(mysteryMode)}
        />
        {MysteryModeButtonTitlesFromMysteryDayType(mysteryMode).short}
        {mysteryMode === 'buildCushion' && cushionSubMode ? (
          <TextSpan weight={200} className="my-0 ml-1" color={'softblue'} size="xxs">
            - {cushionSubModeToTitle[cushionSubMode]}
          </TextSpan>
        ) : mysteryMode === 'getOrganized' && itemName ? (
          <TextSpan
            weight={200}
            className="my-0 ml-1"
            color={'megapurple'}
            size="xxs"
            style={
              {
                // textTransform: 'uppercase',
              }
            }>
            - {itemName}
          </TextSpan>
        ) : null}
      </TextBold>
      <TextSpan
        color={colorFromMysteryDayType(mysteryMode)}
        size="xs"
        style={{ marginTop: '-.25rem' }}
        weight={600}>
        {coastViewMode === 'simple' ? (
          <>{finalDateToShow}</>
        ) : (
          <TextSpan
            size="xs"
            className="my-0"
            weight={800}
            color={colorFromMysteryDayType(mysteryMode)}
            style={{ whiteSpace: 'nowrap' }}>
            <TextSpan
              size="xs"
              className="my-0"
              weight={800}
              color={colorFromMysteryDayType(mysteryMode)}>
              {yearsLeft} yrs
            </TextSpan>

            <TextSpan
              style={{
                opacity: monthsLeft === Infinity ? 0 : 1,
                transition: 'all ease-in-out .3s',
              }}
              className="mx-1 my-0"
              size="xs"
              weight={200}
              color="textsupertransparent">
              |
            </TextSpan>
            <TextSpan
              size="xs"
              className="my-0"
              weight={800}
              color={colorFromMysteryDayType(mysteryMode)}>
              {monthsLeft} months
            </TextSpan>
          </TextSpan>
        )}
      </TextSpan>
    </div>
  )
}
