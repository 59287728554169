import { animated } from '@react-spring/web'
import styled from 'styled-components'
import { ColorType } from 'theme'

export const CollapseContainer = styled.div`
  display: flex;
  max-width: calc(100vw - 2rem);
  width: 100%;
`

export const CollapseContent = styled.div<{
  isCollapsed: boolean
  skipBottomBorder?: boolean
  color?: ColorType
}>`
  flex: 1;
  width: 100%;
  min-width: 100%;
  width: 100%;
  transition: border-bottom ease-in-out 0.3s;

  border-bottom: ${(props) =>
    props.skipBottomBorder
      ? ''
      : ` solid 1px ${
          !props.isCollapsed
            ? props.theme.colors[props.color ? props.color : 'second']
            : props.theme.colors.nearlyclear
        }`};
`

export const CollapseHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  /* padding-bottom: 8px; */

  header {
    flex: 1;
  }
`

export const CollapseBody = styled(animated.div)`
  display: flex;
  width: 100%;

  flex-direction: column;
  overflow: hidden;
`
