import { ContainerDiv } from 'Components/DesignSystem/Layout/Containers/Containers'
import { TextSpan } from 'Components/DesignSystem/Typography'
import { MoneyInputContainer } from 'Pages/StreamPage/AddEditStream/AddEditStream.components'
import {
  getPlaceholderForFrequency,
  getTypeForFrequency,
} from 'Pages/StreamPage/AddEditStream/utils'
import {
  ManualAccount,
  useFinancialData,
} from 'Providers/FinancialDataProvider/FinancialDataProvider'
import { useUIState } from 'Providers/UIStateProvider'
import { formatCurrency } from 'Utilities'
import { cleanCurrency } from 'Utilities/currencyFormater'
import { FrequencyType } from 'Utilities/interfaces'
import { format } from 'date-fns'
import { IconButton } from 'design-system/buttons/IconButton/IconButton'
import React, { useCallback } from 'react'

interface Props {
  account: ManualAccount
}
export const AddManualTransactionPopup: React.FC<Props> = ({ account }) => {
  const {
    saveManualTransaction,
    saveManualTransactionLoading,
    getManualAccountsLoading,
    getManualAccounts,
  } = useFinancialData()

  const { setPopup, setAddManualAccountPageOpen } = useUIState()

  const [name, setName] = React.useState<string>('')
  const [amount, _setAmount] = React.useState<string>('')

  const setAmount = useCallback((amount: string) => {
    const formattedValue = formatCurrency(amount, 10, 'positive')
    _setAmount(formattedValue)
  }, [])

  const [date, setDate] = React.useState<string>('')

  return (
    <ContainerDiv
      className="p-2 pr-4"
      style={
        {
          // background: 'linear-gradient(135deg, #000c15, #071c23)',
        }
      }>
      <TextSpan size="xxs" color="textsupertransparent" weight={200} className="px-4 pt-2">
        Add any manual transactions to and from this account you would like to have show up on your
        historical Dashboard.
      </TextSpan>

      <div className="d-flex flex-column px-4 pt-4">
        <MoneyInputContainer
          size="md"
          color={'text'}
          className="mb-5"
          icon="f02b"
          label={'Transaction Name'}>
          <input
            data-testid="stream-amount-input"
            inputMode="text"
            value={name}
            placeholder={'House, wedding, trip'}
            onChange={(e) => setName(e.currentTarget.value)}></input>
        </MoneyInputContainer>
        <MoneyInputContainer size="md" color={'text'} className="mb-5" icon="f155" label={'Amount'}>
          <input
            data-testid="stream-amount-input"
            inputMode="decimal"
            value={amount}
            placeholder={'5,000'}
            onChange={(e) => setAmount(e.currentTarget.value)}></input>
        </MoneyInputContainer>

        <MoneyInputContainer icon="f073" className="w-100" label={'Date'}>
          <input
            placeholder={getPlaceholderForFrequency(FrequencyType.onetime)}
            data-testid="stream-date-input"
            inputMode={undefined}
            type={getTypeForFrequency(FrequencyType.onetime)}
            value={
              getTypeForFrequency(FrequencyType.onetime) === 'date' && date === ''
                ? format(new Date(), 'yyyy-MM-dd')
                : date
            }
            style={{ maxHeight: 'calc(100%)', minWidth: 'fit-content' }}
            onChange={(e) => setDate(e.currentTarget.value)}></input>
        </MoneyInputContainer>
      </div>

      <IconButton
        title="Add"
        color="logoSecondary"
        disabled={saveManualTransactionLoading || getManualAccountsLoading}
        iconClassName="fas fa-plus"
        onClick={async () => {
          await saveManualTransaction({
            manualTransaction: {
              amount: cleanCurrency(amount),
              date: date === '' ? format(new Date(), 'yyyy-MM-dd') : date,
              name,
              account_id: account?.account_id,
            },
          })
          await getManualAccounts({})
          setPopup(null)
          setAddManualAccountPageOpen(false)
        }}
      />
    </ContainerDiv>
  )
}
