import { Icon, StyledHr, TextSpan } from 'Components/DesignSystem/Typography'
import { FixedStreamAssigner } from 'Pages/SpendingPage/DecorateTransactionsPopup/Assigners/FixedStreamAssigner'
import { VariableCategoryPicker } from 'Pages/SpendingPage/DecorateTransactionsPopup/Assigners/VariableCategoryPicker'
import { MoneyInputContainer } from 'Pages/StreamPage/AddEditStream/AddEditStream.components'
import { fireConfetti } from 'Pages/WishlistPage/Confetti'
import { useUIState } from 'Providers/UIStateProvider'
import {
  CalculationType,
  TransactionMetadata,
  UpdateTransactionMetadataParams,
  useUserCustomSettings,
} from 'Providers/UserCustomSettingsProvider/UserCustomSettingsProvider'
import { IconButton } from 'design-system/buttons/IconButton/IconButton'
import { AccountBase, Transaction } from 'plaid'
import React, { useState } from 'react'
import { colorPalletes } from 'theme'
import { Card } from './ReflectionCard.components'
import { TransactionSpendingTypeAssigner } from './TransactionSpendingTypeAssigner'
import { TransactionSummary } from './TransactionSummary'

interface Props {
  a: AccountBase | undefined
  t: Transaction
  m: TransactionMetadata
  totalTransactions: number
  currentIndex: number
  setCurrentIndex: (index: number) => void
  style: any
  onBackButtonOnFirstCard: () => void
  location: 'carousel' | 'solo'
  isFinalCard?: boolean
}
export const ReflectionCard: React.FC<Props> = ({
  t,
  m,
  a,
  style,
  currentIndex,
  totalTransactions,
  setCurrentIndex,
  onBackButtonOnFirstCard,
  location = 'carousel',
  isFinalCard,
}) => {
  const { saveTransactionMetadata, getSettings, settings } = useUserCustomSettings()
  const { setPopup, activeTheme } = useUIState()

  const [overbudgetSave, setOverbudgetSave] = React.useState<boolean>(false)

  const [savingReflectionLoading, setSavingReflectionLoading] = useState<boolean>(false)

  const onSubmit = React.useCallback(
    async (val: Omit<UpdateTransactionMetadataParams, 'transactionId'>, isFinalCard?: boolean) => {
      setSavingReflectionLoading(true)
      setPopup(null)
      const {
        notes,
        regret,
        valueAlignment,
        impulse,
        recurring,
        ignoreFromCalculations,
        calculationType,
        fixedStreamName,
        envelopeCategory,
      } = val

      try {
        await saveTransactionMetadata({
          transactionId: t.transaction_id,
          notes,
          regret,
          impulse,
          recurring,
          valueAlignment,
          fixedStreamName,
          ignoreFromCalculations,
          calculationType,
          envelopeCategory,
        })

        if (isFinalCard) {
          fireConfetti({
            colors: [colorPalletes[activeTheme].logoPrimary],
            duration: 0.5,
            origin: {
              x: 0.5,
              y: 0.9,
            },
          })
        }
        await getSettings()

        setSavingReflectionLoading(false)
      } catch (err) {
        console.error('failed to save')
        setSavingReflectionLoading(false)
      }
    },
    [getSettings, saveTransactionMetadata, setPopup, t.transaction_id, activeTheme]
  )
  const { setMessagePopupContent } = useUIState()

  // eslint-disable-next-line
  const [notes, setNotes] = React.useState<string>(m?.notes || '')
  // eslint-disable-next-line
  const [valueAlignment, setValueAlignment] = React.useState<boolean | undefined>(
    m?.valueAlignment !== undefined ? m.valueAlignment : undefined
  )

  const [envelopeCategory, setEnvelopeCategory] = React.useState<string>(
    m?.envelopeCategory !== undefined ? m.envelopeCategory : ''
  )

  const [calculationType, setCalculationType] = React.useState<CalculationType>(
    m?.calculationType !== undefined ? m.calculationType : 'variable'
  )

  const [fixedStreamName, setFixedStreamName] = React.useState<string>(
    m?.fixedStreamName !== undefined ? m.fixedStreamName : ''
  )

  const accountMetadata = settings?.accountMetadata?.find((_a) => _a.account_id === a?.account_id)

  return (
    <Card style={style}>
      <div className="d-flex flex-column w-100 h-100 align-items-start pt-2 px-4 justify-content-between">
        <div className="d-flex flex-column w-100 align-items-start h-100 ">
          <TransactionSummary
            aM={accountMetadata}
            t={t}
            a={a}
            color={
              calculationType === 'variable'
                ? 'yellow'
                : calculationType === 'fixed'
                ? 'mehred'
                : 'texttransparent'
            }
          />
          <TransactionSpendingTypeAssigner
            t={t}
            calculationType={calculationType}
            setCalculationType={setCalculationType}
          />

          {calculationType === 'variable' ? (
            <VariableCategoryPicker
              envelopeCategory={envelopeCategory}
              t={t}
              setEnvelopeCategory={setEnvelopeCategory}
              setOverbudgetSave={setOverbudgetSave}
            />
          ) : null}

          {calculationType === 'fixed' ? (
            <FixedStreamAssigner
              fixedStreamName={fixedStreamName}
              transactionName={t.name}
              transactionAmount={t.amount}
              setFixedStreamName={setFixedStreamName}
            />
          ) : null}

          {calculationType === 'skip' ? (
            <div className="d-flex flex-column align-items-center">
              <TextSpan className="px-4" color="textsupertransparent">
                You might skip a transaction if
              </TextSpan>

              <TextSpan className="px-4" color="textsupertransparent">
                <ul className="pl-1">
                  <li>It's a paycheck deposit that is expected </li>
                  <li>It's a transfer between accounts</li>
                  <li>It's a Venmo you've accounted elsewhere</li>
                  <li>You simply want to ignore this from calculations</li>
                </ul>
              </TextSpan>
            </div>
          ) : null}
        </div>
        <MoneyInputContainer
          icon="f02b"
          label="additional notes / comments"
          className="mt-4"
          color="text"
          fontSizeDefault="xxs"
          fontSizeActive="xs">
          <input
            data-testid="stream-name-input"
            value={notes}
            placeholder=""
            onChange={(e) => setNotes(e.currentTarget.value)}></input>
        </MoneyInputContainer>

        <div className="d-flex flex-column w-100 align-items-center">
          <StyledHr />
          <div className="d-flex flex-row align-items-center justify-content-between w-100">
            {location === 'carousel' && (
              <Icon
                size="xl"
                onClick={() => {
                  if (currentIndex === 0) {
                    onBackButtonOnFirstCard()
                  } else {
                    setCurrentIndex(currentIndex - 1)
                  }
                }}
                className="fas fa-chevron-circle-left"
                color="text"
              />
            )}
            <div className="d-flex flex-column">
              <IconButton
                disabled={
                  calculationType === 'variable'
                    ? !envelopeCategory
                    : calculationType === 'fixed'
                    ? !fixedStreamName
                    : false
                }
                onDisabledClick={() => {
                  setMessagePopupContent(
                    'Missing Category<>Please enter a category for this transaction!'
                  )
                }}
                onClick={() => {
                  let answer = true
                  if (overbudgetSave) {
                    answer = false
                    answer = window.confirm(
                      'Just a heads up, you are assigning a transaction to an envelope that is out of money for the month. We strongly recommend reallocating your envelopes before doing so but if you would like to continue, you may'
                    )
                  }
                  if (!answer) return
                  onSubmit(
                    {
                      notes,
                      regret: false,
                      valueAlignment,
                      impulse: false,
                      recurring:
                        calculationType === 'fixed' || calculationType === 'skip' ? true : false,
                      ignoreFromCalculations: calculationType === 'skip' ? true : false,
                      calculationType,
                      fixedStreamName,
                      envelopeCategory,
                    },
                    isFinalCard
                  )
                }}
                title={savingReflectionLoading ? 'Saving...' : `Save`}
                buttonClassName="align-self-center mb-1 mt-2"
                color={overbudgetSave ? 'yellow' : 'logoPrimary'}
                iconClassName={savingReflectionLoading ? 'fas fa-sync fa-spin' : 'fas fa-check'}
              />
              {location === 'carousel' && (
                <TextSpan size="xxs" className="mb-3" color="textsupertransparent">
                  Transaction {currentIndex + 1} / {totalTransactions}
                </TextSpan>
              )}
            </div>

            {location === 'carousel' && (
              <Icon
                style={{
                  opacity: currentIndex === totalTransactions - 1 ? 0 : 1,
                }}
                size="xl"
                onClick={() => {
                  setCurrentIndex(currentIndex + 1)
                }}
                className="fas fa-chevron-circle-right"
                color="text"
              />
            )}
          </div>
        </div>
      </div>
    </Card>
  )
}
