import { MysteryDayType } from 'Providers/MysteryDayProvider/MysteryDayProvider.interfaces'
import { numberToCurrency } from 'Utilities/currencyFormater'
import { colorFromMysteryDayType } from 'Utilities/utils'
import {
  AnimationsSpec,
  Chart,
  ChartOptions,
  PluginOptionsByType,
  TimeScale,
  TimeSeriesScale,
  TransitionsSpec,
} from 'chart.js'
import { _DeepPartialObject } from 'chart.js/dist/types/utils'
import color from 'color'
import { isBefore } from 'date-fns'
import { enUS } from 'date-fns/locale'
import { ColorType, colorPalletes } from 'theme'

Chart.register(TimeScale)
Chart.register(TimeSeriesScale)

const animations: _DeepPartialObject<AnimationsSpec<'line'>> = {
  //   tension: {
  //     duration: 300,
  //     easing: 'linear',
  //     from: 1,
  //     to: 0,
  //     loop: false,
  //   },
}
const transitions: _DeepPartialObject<TransitionsSpec<'line'>> = {}

const historicalTransactionsExist = true
const plugins: _DeepPartialObject<PluginOptionsByType<'line'>> = {
  filler: {
    propagate: true,
  },
  annotation: {
    annotations: historicalTransactionsExist
      ? [
          // {
          //     type: 'point',
          //     xValue: lastDate.getTime(),
          //     yValue: 0,
          //     backgroundColor: color(colorPalletes[activeTheme].colors.yellow).alpha(0.8).toString(),
          // },
          // {
          //     type: 'line',
          //     drawTime: 'afterDatasetsDraw',
          //     // scaleID: 'x',
          //     xScaleID: 'x',
          //     yScaleID: 'y',
          //     xMin: new Date().getTime(),
          //     xMax: new Date().getTime(),
          //     value: new Date().getTime(),
          //     yMax: todaysBalance,
          //     // yMin: minValue,
          //     yMin(ctx, options) {
          //         return minYValue || 0
          //         // return getMinimumValueFromDataSet(data)
          //     },
          //     borderColor: color(colorPalletes[activeTheme].colors[pastColor]).alpha(1).toString(),
          //     borderWidth: 2,
          //     label: {
          //         content: 'today', // Set the label content
          //         enabled: true,
          //         borderRadius: 10,
          //         borderWidth: 2,
          //         borderColor: color(colorPalletes[activeTheme].colors[pastColor]).alpha(1).toString(),
          //         xPadding: 10,
          //         font: {
          //             size: 14,
          //             weight: '600',
          //             family: 'Rubik',
          //         },
          //         backgroundColor: '#51956C',
          //         position: 'start', // Adjust the label position as needed
          //         xAdjust: 10,
          //     },
          // },
        ]
      : [],
  },
  subtitle: {},
  title: {},
  tooltip: {},
  decimation: {},
  legend: {
    display: false,
  },
}

const pastColor: ColorType = 'cashgreen'

const getScales: (
  mode: MysteryDayType,
  activeTheme: keyof typeof colorPalletes
) => ChartOptions<'line'>['scales'] = (mode, activeTheme) => {
  const modeColor = colorFromMysteryDayType(mode)
  return {
    x: {
      adapters: {
        date: {
          locale: enUS,
        },
      },
      type: 'time',

      time: {
        unit: 'day',
        displayFormats: {
          day: 'MMM yy`',
        },
      },
      stacked: true,

      ticks: {
        source: 'data',

        autoSkip: true,
        autoSkipPadding: 15,

        maxRotation: 60,
        includeBounds: true,
        color: color(colorPalletes[activeTheme]['textsupertransparent']).toString(),
        font: {
          size: 10,
          weight: '300',
          family: 'Rubik',
        },
      },
      alignToPixels: false,

      border: {
        display: false,
        color: color(colorPalletes[activeTheme]['textsupertransparent']).toString(),
      },

      color: color(colorPalletes[activeTheme]['textsupertransparent']).toString(),
      font: {
        size: 10,
        weight: '300',
        family: 'Rubik',
      },

      grid: {
        display: true,
        drawTicks: true,
        tickWidth: 1,
        tickLength: 20,
        drawOnChartArea: true,

        color: (context) => {
          const tickDate = new Date(context.tick.value)
          const today = new Date()
          const isBeforeToday = isBefore(tickDate, today)

          if (isBeforeToday) {
            return color(colorPalletes[activeTheme][pastColor]).alpha(0.1).toString()
          } else {
            return color(colorPalletes[activeTheme][modeColor]).alpha(0.2).toString()
          }
        },
      },
    },

    y: {
      type: 'linear',
      // grace: '1%',
      stacked: false,
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value: any, index: any, values: any) {
          return numberToCurrency(value, true, true)
        },

        includeBounds: true,

        autoSkip: true,
        autoSkipPadding: 20,
        color: color(colorPalletes[activeTheme]['textsupertransparent']).toString(),
        font: {
          size: 10,
          weight: '300',
          family: 'Rubik',
        },
      },
      beginAtZero: true,
      border: {
        display: false,
      },
      // beginAtZero: true,
      grid: {
        display: true,
        //   tickColor: themeColor,
        drawTicks: true,
        tickWidth: 1,
      },
    },
  }
}

// export const options: ChartOptions<'line'> = {
//   maintainAspectRatio: false,
//   devicePixelRatio: 4,
//   responsive: true,
//   transitions,
//   animations,
//   scales,
//   plugins,
// }

export const getOptions: (
  mode: MysteryDayType,
  activeTheme: keyof typeof colorPalletes
) => ChartOptions<'line'> = (mode, activeTheme) => {
  return {
    maintainAspectRatio: false,
    devicePixelRatio: 4,
    responsive: true,
    transitions,
    animations,
    scales: getScales(mode, activeTheme),
    plugins,
  }
}
