import { AccountMaskAndLogo } from 'Components/AccountMaskAndLogo'
import { StyledHr, TextSpan } from 'Components/DesignSystem/Typography'
import { useGetInstitutionMetadata } from 'Pages/SpendingPage/hooks/useGetInstitutionMetadata'
import { getAmountForMixedAccount } from 'Providers/FinancialDataProvider/helpers'
import { numberToCurrency } from 'Utilities/currencyFormater'
import { AccountBase } from 'plaid'
import React from 'react'

interface Props {
  checkingAccount: AccountBase
  setChosenAccount: (account: string) => void
  setChosenAccountHydrated: (account: AccountBase | null) => void
  chosenAccount: string
}
export const CheckingAccountSelectionRow: React.FC<Props> = ({
  checkingAccount,
  setChosenAccount,
  setChosenAccountHydrated,
  chosenAccount,
}) => {
  const { institutionName } = useGetInstitutionMetadata(checkingAccount)
  return (
    <div
      key={checkingAccount.name}
      style={{
        opacity: (checkingAccount as AccountBase)?.account_id === chosenAccount ? 1 : 0.5,
      }}
      className="d-flex my-1 w-100 align-items-center justify-content-between flex-column"
      onClick={() => {
        if (chosenAccount === checkingAccount.account_id) {
          setChosenAccount('')
          setChosenAccountHydrated(null)
        } else {
          setChosenAccount((checkingAccount as AccountBase)?.account_id)
          setChosenAccountHydrated(checkingAccount)
        }
      }}>
      <div className="d-flex  w-100 align-items-center justify-content-start my-0">
        <TextSpan
          style={{
            whiteSpace: 'nowrap',
          }}
          className="mr-1"
          size="xxs"
          color={
            chosenAccount === checkingAccount.account_id ? 'cashgreen' : 'textsupertransparent'
          }>
          Checking Account
        </TextSpan>
        <StyledHr
          color={
            chosenAccount === checkingAccount.account_id ? 'cashgreen' : 'textsupertransparent'
          }
          weight={2}
          className="my-1"
        />
      </div>
      <div className="d-flex  w-100 align-items-center justify-content-between my-0">
        <div className="d-flex  w-100 align-items-center justify-content-start my-0">
          <AccountMaskAndLogo accountId={checkingAccount.account_id} />

          <div className="d-flex align-items-start flex-column ml-2">
            <div className="d-flex align-items-start flex-column">
              <TextSpan size="xs" className="my-0" weight={500} color="text">
                {institutionName}
              </TextSpan>
              <TextSpan color="textsupertransparent" size="xxs" weight={200} className=" my-0">
                {checkingAccount?.official_name || checkingAccount?.name}
              </TextSpan>
            </div>
          </div>
        </div>
        <TextSpan weight={600} color="cashgreen">
          {numberToCurrency(getAmountForMixedAccount(checkingAccount))}
        </TextSpan>
      </div>
      <StyledHr
        color={chosenAccount === checkingAccount.account_id ? 'cashgreen' : 'textsupertransparent'}
        weight={2}
        className="my-1"
      />
    </div>
  )
}
