import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect } from 'react'

import { Icon, StyledHr, StyledVr, TextBold, TextSpan } from 'Components/DesignSystem/Typography'
import {
  TransitionFromAbove,
  TransitionFromBelow,
  TransitionFromLeft,
  TransitionFromRight,
} from 'Components/Transitions/TransitionFromAbove'
import { WelcomeToSplurv } from 'Components/WelcomeToSplurv/WelcomeToSplurv'
import { useGetAccessToken } from 'Hooks/useGetAccessToken'
import { useUIState } from 'Providers/UIStateProvider'
import { useUserConfig } from 'Providers/UserConfigProvider/UserConfigProvider'
import { config } from 'react-spring/renderprops'
import { colorPalletes } from 'theme'
import { StyledButton } from './Button/Button'
import { FancyLoginStuff } from './FancyLoginStuff'
import { PreLoginPage } from './PreLoginPage'
import { SystemOverviewPage } from './SystemOverviewPage'
import { ToolOverviewPage } from './ToolOverviewPage'
import { slideDown, slideLeft, slideRight } from './Transitions'

/**
 * This is the first page the user sees when they first open the app
 * from the home screen.
 * @returns
 */
export const IntroPage: React.FC = () => {
  const { user } = useAuth0()

  const { getAccessToken } = useGetAccessToken('IntroPage -> loginUser')

  const {
    hasSeenAppBefore,
    showLoginPage,
    showToolPage,
    showSystemPage,
    setShowLoginPage,
    setShowToolPage,
    setShowSystemPage,
  } = useUserConfig()

  const { activeTheme } = useUIState()

  const previousPage = React.useRef<number>(0)

  // 1 = System
  // 2 = Tool
  // 3 = Login
  const [page, setPage] = React.useState<number>(0)

  useEffect(() => {
    if (page !== previousPage.current) {
      previousPage.current = page
    }
  }, [page])

  const [loadingToken, setLoadingToken] = React.useState<boolean>(true)

  useEffect(() => {
    const loginUser = async () => {
      try {
        try {
          await getAccessToken()
          setLoadingToken(false)
        } catch (err: any) {
          setLoadingToken(false)
          if (err.error === 'login_required') {
            console.error(err)
            return
          }
          if (err.error === 'consent_required') {
            console.error(err)
            return
          }
          throw err
        }
      } catch (err) {
        console.error('Error from getAccessTokenSilently')
        console.error(err)
      }
    }
    let timer: NodeJS.Timeout
    if (hasSeenAppBefore) {
      console.debug('<LoginSignupCTA> - user has seen app before')
      timer = setTimeout(() => {
        loginUser()
      }, 1000)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [user, hasSeenAppBefore, getAccessToken])

  if (hasSeenAppBefore) {
    return <FancyLoginStuff loadingToken={loadingToken} />
  } else {
    return (
      <>
        <TransitionFromAbove
          show={page === 0}
          customTransitionSettings={{
            from:
              previousPage.current === 0 && page === 0
                ? { opacity: 1, transform: 'translate3d(0%,0,0)' }
                : previousPage.current === 1 && page === 0
                ? slideLeft
                : previousPage.current === 3 && page === 0
                ? slideDown
                : previousPage.current === 2 && page === 0
                ? slideRight
                : { opacity: 1, transform: 'translate3d(100%,0,0)' },
            leave:
              page === 1
                ? slideLeft
                : page === 2
                ? slideRight
                : page === 3
                ? slideDown
                : { opacity: 0, transform: 'translate3d(0,-100%,0)' },
          }}
          style={{
            background: 'linear-gradient(180deg, #b6d8e3, #c8fadc)',
          }}>
          <div className="d-flex flex-column align-items-center justify-content-between w-100 h-100 px-2">
            <div className="d-flex flex-column align-items-center justify-content-start w-100 ">
              <WelcomeToSplurv />
              <TextSpan
                className="d-inline align-self-center text-center mt-3"
                color="logoSecond"
                size="sm"
                weight={200}>
                Bringing <b>time</b> and <b>purpose</b> <br />
                into personal finance.
              </TextSpan>
              <StyledHr className="my-2" color="logoSecond" weight={1} style={{ opacity: 0.3 }} />
            </div>

            <div className="d-flex flex-column align-items-center justify-content-start w-100 px-1 position-relative">
              <StyledButton
                onClick={() => {
                  setShowSystemPage(true)
                  setPage(1)
                }}
                size="sm"
                colorPrimary={'second'}
                style={{
                  background: colorPalletes[activeTheme].logoSecond,
                  color: 'text',
                  backgroundPosition: '40% 100%',
                  borderWidth: '0px',
                  borderColor: colorPalletes[activeTheme].logoPrimary,
                  borderRadius: '12px',
                }}
                className="mt-1 mb-4 px-3 py-3 w-100  d-flex align-items-center justify-content-start">
                <Icon
                  className="fal fa-cubes mr-1"
                  color="text"
                  size="xxl"
                  style={{ opacity: 0.5, minWidth: '46px' }}
                />
                <StyledVr className="ml-2 mr-3" color="text" style={{ opacity: 0.5 }} />
                <TextBold color="texttransparent" weight={200} size="lg">
                  The system
                </TextBold>
                <Icon
                  className="fas fa-arrow-right"
                  size="lg"
                  color="texttransparent"
                  style={{
                    position: 'absolute',
                    right: '1rem',
                  }}
                />
              </StyledButton>

              <StyledButton
                onClick={() => {
                  setShowToolPage(true)
                  setPage(2)
                }}
                size="sm"
                colorPrimary={'second'}
                style={{
                  background: colorPalletes[activeTheme].logoSecond,
                  color: colorPalletes[activeTheme].text,
                  backgroundPosition: '40% 100%',
                  borderWidth: '0px',
                  borderColor: colorPalletes[activeTheme].logoPrimary,
                  borderRadius: '12px',
                }}
                className="mt-1 mb-4 px-3 py-3 w-100 d-flex align-items-center justify-content-start">
                <Icon
                  className="fal fa-mobile mr-1"
                  color="text"
                  size="xxl"
                  style={{ opacity: 0.5, minWidth: '46px' }}
                />
                <StyledVr className="ml-2 mr-3" color="text" style={{ opacity: 0.5 }} />
                <TextBold color="texttransparent" weight={200} size="lg">
                  The Tool
                </TextBold>
                <Icon
                  className="fas fa-arrow-left"
                  size="lg"
                  color="texttransparent"
                  style={{
                    position: 'absolute',
                    right: '1rem',
                  }}
                />
              </StyledButton>

              <StyledHr className="my-4" color="logoSecond" weight={1} style={{ opacity: 0.3 }} />
              <StyledButton
                onClick={() => {
                  setShowLoginPage(true)
                  setPage(3)
                }}
                size="sm"
                colorPrimary={'second'}
                style={{
                  color: colorPalletes[activeTheme].logoSecond,
                  background: colorPalletes[activeTheme].text,
                  backgroundPosition: '40% 100%',
                  borderWidth: '2px',
                  borderColor: colorPalletes[activeTheme].logoSecond,
                  borderRadius: '12px',
                }}
                className="mt-1 mb-5 px-3 py-3 w-100 d-flex align-items-center justify-content-start">
                <Icon
                  className="fas fa-party-horn mr-1"
                  color="second"
                  size="xxl"
                  style={{ opacity: 1, minWidth: '46px' }}
                />
                <StyledVr className="ml-2 mr-1" color="logoSecond" style={{ opacity: 0.5 }} />
                <TextBold color="logoSecond" weight={700} size="lg" style={{}}>
                  just dive in
                </TextBold>
              </StyledButton>
            </div>
          </div>
        </TransitionFromAbove>

        <TransitionFromRight
          show={showSystemPage}
          style={
            {
              // background: 'linear-gradient(180deg,#c8fadc ,#b6d8e3 )',
            }
          }
          position="absolute"
          config={config.default}>
          <SystemOverviewPage
            closePage={() => {
              setShowSystemPage(false)
              setPage(0)
            }}
          />
        </TransitionFromRight>

        <TransitionFromLeft show={showToolPage} config={config.default} position="absolute">
          <ToolOverviewPage
            closePage={() => {
              setShowToolPage(false)
              setPage(0)
            }}
          />
        </TransitionFromLeft>

        <TransitionFromBelow show={showLoginPage} config={config.default} position="absolute">
          <PreLoginPage
            closePage={() => {
              setShowLoginPage(false)
              setPage(0)
            }}
          />
        </TransitionFromBelow>
      </>
    )
  }
}
