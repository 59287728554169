import { ContainerDiv } from 'Components/DesignSystem/Layout/Containers/Containers'
import { Icon, StyledHr, TextSpan } from 'Components/DesignSystem/Typography'
import { useAsyncFetch } from 'Hooks/useAsyncFetch'
import { useData } from 'Providers/APIDataProvider'
import { numberToCurrency } from 'Utilities/currencyFormater'
import {
  humanReadifyStringDateShortSansYear,
  stringToDate,
} from 'Utilities/dateUtilities/dateUtilities'
import { FrequencyType, MoneyStream } from 'Utilities/interfaces'
import { RecurringTransactionFrequency, TransactionsRecurringGetResponse } from 'plaid'
import React, { useEffect } from 'react'
import { ColorType } from 'theme'

interface VariableWizardryProps {
  activelySelectedAccount: {
    accountId: string
    itemId: string
  } | null
  setActivelySelectedAccount: React.Dispatch<
    React.SetStateAction<{
      accountId: string
      itemId: string
    } | null>
  >
}

const PlaidFrequencyMappingToSplurvStreamFrequency: {
  [key in RecurringTransactionFrequency]: FrequencyType
} = {
  MONTHLY: FrequencyType.monthly,
  WEEKLY: FrequencyType.weekly,
  BIWEEKLY: FrequencyType.biweekly,
  SEMI_MONTHLY: FrequencyType.semimonthly,
  UNKNOWN: FrequencyType.monthly,
}

export const FixedWizardry: React.FC<VariableWizardryProps> = ({
  activelySelectedAccount,
  setActivelySelectedAccount,
}) => {
  const { saveStream, savingStream, streams } = useData()

  const [streamBeingSaved, setStreamBeingSaved] = React.useState<string | null>(null)

  const [totalStreamsAdded, setTotalStreamsAdded] = React.useState<number>(0)

  const recurringTransactionsForAccount = useAsyncFetch<
    { data: TransactionsRecurringGetResponse },
    {
      itemId: string
      accountId: string
    },
    {}
  >({
    method: 'GET',
    route: 'recurring_transactions',
    // cachedData: dataForRecurringTransactions,
  })

  const { issueRequest } = recurringTransactionsForAccount

  const loading = recurringTransactionsForAccount.res.status === 'loading'
  const error = recurringTransactionsForAccount.res.status === 'error'

  const recurringOutflows = recurringTransactionsForAccount.res.data?.data.outflow_streams.filter(
    (os) => os.is_active && os.status === 'MATURE'
  )

  useEffect(() => {
    if (activelySelectedAccount) {
      try {
        issueRequest({
          queryParamsConfig: {
            accountId: activelySelectedAccount.accountId,
            itemId: activelySelectedAccount.itemId,
          },
          silentError: true,
        })
      } catch (err) {
        console.error(err)
      }
    }
  }, [activelySelectedAccount, issueRequest])

  return (
    <>
      <ContainerDiv className="justify-content-between pt-0 pb-5 w-100">
        <ContainerDiv
          className="justify-content-start py-2 w-100"
          style={{
            opacity: loading ? 0.5 : 1,
            transition: 'opacity .3s ease-in-out',
          }}>
          {error ? (
            <div className="d-flex align-items-center py-2">
              <Icon className="fas fa-exclamation-circle" color="yellow" size="md" />

              <div className="d-flex align-items-start flex-column">
                <TextSpan className="px-3 my-0" color="yellow" size="xs" weight={500}>
                  Oof 🙈
                </TextSpan>
                <TextSpan className="px-3 my-0" color="yellow" size="xxs">
                  We're having trouble fetching your recurring expenses. Sometimes Plaid takes a
                  little bit to collect transactions. Please try again later.
                </TextSpan>
              </div>
            </div>
          ) : (
            <TextSpan color="textsupertransparent" size="xs">
              {activelySelectedAccount ? (
                <div className="d-flex flex-row align-items-start flex-column">
                  <div className="d-flex flex-row align-items-center">
                    <TextSpan weight={500} size="lg" color="softblue" className="mr-1">
                      {recurringOutflows?.length}
                    </TextSpan>{' '}
                    active recurring expenses found:
                    <TextSpan
                      className="ml-2"
                      weight={700}
                      color="softblue"
                      style={{ opacity: loading ? 1 : 0 }}>
                      {' '}
                      Fetching...
                    </TextSpan>
                  </div>
                </div>
              ) : null}
            </TextSpan>
          )}

          <div
            className="d-flex flex-column px-2 py-1"
            style={{
              minHeight: '150px',
              maxHeight: '150px',
              height: '150px',
              overflow: 'auto',
              opacity: activelySelectedAccount ? 1 : 0,

              boxShadow: 'inset hsl(243 19% 18% / .5) 0 0px 7px 5px',
              border: 'solid 1px #6362752e',
            }}>
            {recurringOutflows?.map((outflow, index) => {
              const name = outflow.merchant_name || outflow.description
              const streamAlreadyExists = streams.some((stream) => stream.name === name)
              const colorIfAlreadyExists: ColorType = streamAlreadyExists
                ? 'cashgreen'
                : 'textsupertransparent'
              const stream: MoneyStream = {
                name,
                frequency: PlaidFrequencyMappingToSplurvStreamFrequency[outflow.frequency],
                amount: (outflow.average_amount.amount || 0) * -1,
                enabled: true,
                sources: [],
                destinations: [],
                isSubscription: false,
                upcomingEvents: [],
                transfer: false,
                type: 'bill',
                userid: '',
              } as MoneyStream

              if (stream.frequency === FrequencyType.monthly) {
                const day = Number(outflow.last_date.split('-')[2])
                stream.day = day
              }
              if (stream.frequency === FrequencyType.weekly) {
                const day = stringToDate(outflow.last_date).getDay()
                stream.day = day
              }
              return (
                <div
                  className="d-flex flex-column align-items-center w-100"
                  style={{
                    opacity: savingStream ? 0.5 : 1,
                    transition: 'opacity .3s ease-in-out',
                  }}>
                  <div className="d-flex flex-row align-items-center w-100 justify-content-between my-2">
                    <TextSpan
                      style={{ flex: 1, overflow: 'hidden', whiteSpace: 'pre' }}
                      size="xxs"
                      color={colorIfAlreadyExists}>
                      {name}
                    </TextSpan>
                    <TextSpan weight={800} color={colorIfAlreadyExists} size="xxs" className="mx-2">
                      {numberToCurrency(outflow.average_amount.amount || 0, true, true)}
                    </TextSpan>
                    <TextSpan size="xxs" color={colorIfAlreadyExists}>
                      {String(outflow.frequency).toLowerCase()}
                      <TextSpan className="ml-1" size="xxxxs">
                        ({humanReadifyStringDateShortSansYear(stringToDate(outflow.last_date))})
                      </TextSpan>
                    </TextSpan>

                    {streamAlreadyExists ? (
                      <Icon
                        className={`fas fa-circle-check ml-4`}
                        color={colorIfAlreadyExists}
                        size="xl"
                      />
                    ) : (
                      <Icon
                        className={`fas fa-circle-plus ml-3 ${
                          savingStream && streamBeingSaved === stream.name ? 'fa-spin' : ''
                        }`}
                        color="softblue"
                        size="xl"
                        onClick={() => {
                          setStreamBeingSaved(stream.name)
                          saveStream(stream)
                          setTotalStreamsAdded((total) => total + 1)
                        }}
                      />
                    )}
                  </div>
                  {index !== recurringOutflows.length - 1 && (
                    <StyledHr weight={1} color="nearlyclear" />
                  )}
                </div>
              )
            })}
          </div>
          <TextSpan color="textsupertransparent" size="xs">
            {totalStreamsAdded ? (
              <div className="d-flex flex-row align-items-start flex-column">
                <div className="d-flex flex-row align-items-center">
                  <TextSpan weight={500} size="lg" color="cashgreen" className="mr-1">
                    {totalStreamsAdded}
                  </TextSpan>{' '}
                  recurring expenses added
                </div>
              </div>
            ) : null}
          </TextSpan>
        </ContainerDiv>
      </ContainerDiv>
    </>
  )
}
