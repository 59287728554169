import { AccountTrackingCTA } from 'Components/AccountTrackingCTA/AccountTrackingCTA'
import { useFinancialData } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import { BodyText, ItemText, SubtitleText } from 'design-system/typography/Text/Text'
import React from 'react'
import { ContainerDiv } from '../../../Components/DesignSystem/Layout/Containers/Containers'
import { useMysteryDay } from '../../../Providers/MysteryDayProvider/MysteryDayProvider'
import { cleanCurrency } from '../../../Utilities/currencyFormater'
import { MoneyInputContainer } from '../../StreamPage/AddEditStream/AddEditStream.components'
import { InterestRateSlider } from '../InterestRateSlider'
import { BackButton } from '../MysteryDayBackButton'
import { ForwardButton } from '../MysteryDayForwardButton'
import { SubPageProps } from '../MysteryPage'

export const DebtInputPage: React.FC<SubPageProps> = () => {
  const { setDebtAmount, debtAmount, linkedAccount } = useMysteryDay()

  const { flatAccounts } = useFinancialData()

  const linkedAccountFull = flatAccounts.find((fa) => fa.account_id === linkedAccount?.accountId)

  return (
    <ContainerDiv className="justify-content-between align-items-center py-3">
      <ContainerDiv className="justify-content-center align-items-center py-3">
        <SubtitleText className="px-2 mb-0">Tell us about the debt</SubtitleText>
        <BodyText className="px-4 my-2">
          You can manually enter the details or connect the live credit card or car loan account .
          Linking a live account allows you to see your debt lower as you make your payments.
        </BodyText>
        <AccountTrackingCTA
          type="Debt"
          updateMode={false}
          size="large"
          setupTitle="Linked Debt Account"
          title={'Link Debt Account'}
        />
        {linkedAccountFull ? null : (
          <div
            className="d-flex flex-column w-100 align-items-center"
            style={{
              opacity: linkedAccountFull ? 0.3 : 1,
            }}>
            <ItemText className="mb-3">
              {/* <TextSlim size="sm" weight={800} className="px-2" color="textsupertransparent"> */}
              or manually enter details
              {/* </TextSlim> */}
            </ItemText>

            <MoneyInputContainer
              color="textsupertransparent"
              className="mb-5"
              icon="f155"
              label={'Amount'}>
              <input
                data-testid="stream-amount-input"
                inputMode="decimal"
                value={debtAmount}
                placeholder={'5,000'}
                onChange={(e) => setDebtAmount(e.currentTarget.value)}></input>
            </MoneyInputContainer>

            <InterestRateSlider slim />
          </div>
        )}
      </ContainerDiv>

      <div className="d-flex justify-content-between align-items-center w-100">
        <BackButton />
        <ForwardButton
          disabled={cleanCurrency(debtAmount) === 0 && !linkedAccount}
          title={'Next'}
        />
      </div>
    </ContainerDiv>
  )
}
