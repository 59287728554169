/**
 * This is a hook that will execute N API requests to the backend in parallel and keep a status
 * of each request. As each individual API call comes back, it will update the completed status
 * so that a dynamic UI progress bar can be displayed to the user.
 * @returns
 */

import { useAuth0 } from '@auth0/auth0-react'
import { useGetAccessToken } from 'Hooks/useGetAccessToken'
import { useLocalStorageSyncedState } from 'Hooks/useLocalStorageSyncedState'
import { useFinancialData } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import { AccountsGetResponse } from 'plaid'
import { fetchTimeout } from './fetchTimeout'
import {
  HookReturn,
  PossibleRequestInterfaces,
  RegularErrorInterface,
  SuccessInterface,
} from './multipleTransactionsRefresh'

/**
 * As of September 8th, 2023
 *
 * This hook is no longer being used across the code base as of the above date.
 * This is because as we anticipate needing to eventually move to Plaid's Production tier, allowing user-driven
 * calling of the live Accounts Balance API is no longer feasible. Plaid charges $.10 to get live account balances that are
 * only a few hours old. This is not a feasible cost for us to bear, and so we have moved to a model where we only
 * allow the regular /accounts call. Later on we can revisit what exposing live Acount Balance access to users will look like
 *
 * We are hoping as Splurv that a morning reminder of ones account balances will allow them to mentally track their finances for a few hours at a time
 * before updated transactions come in live (and free)
 */

interface Params {
  /**
   * This is the list of itemIds we want to refresh
   * The length of this list also corresponds to the length of status items that will be returned
   */
  itemIds: string[]
}

export function useRefreshAllAccounts(params: Params): HookReturn<AccountsGetResponse> {
  const { user, loginWithRedirect } = useAuth0()
  const { getAccessToken } = useGetAccessToken('useRefreshAllAccounts')

  const { getAccounts } = useFinancialData()

  const { itemIds } = params

  const defaultRequestStatuses = itemIds.reduce((acc, itemId) => {
    acc[itemId] = {
      status: 'idle',
      error: null,
      data: null,
      itemId: itemId,
    }
    return acc
  }, {} as HookReturn<AccountsGetResponse>['requestStatuses'])

  // const [requestStatuses, setItems] = React.useState<
  //   HookReturn<AccountsGetResponse>['requestStatuses']
  // >(defaultRequestStatuses)

  const [requestStatuses, setItems] = useLocalStorageSyncedState<
    HookReturn<AccountsGetResponse>['requestStatuses']
  >(defaultRequestStatuses, 'multiple-account-fetch-request-statuses')

  const updateItemsStatus = (
    itemId: string,
    item: PossibleRequestInterfaces<AccountsGetResponse>
  ) => {
    setItems((previousItems) => {
      const newItems = { ...previousItems }
      newItems[itemId] = item
      return newItems
    })
  }

  const issueRequest = async () => {
    let token: string | undefined
    try {
      token = await getAccessToken()
    } catch (err: any) {
      if (err.error === 'login_required') {
        loginWithRedirect({
          redirectUri: `${window.location.origin}${window.location.pathname}`,
        })
        return
      }
      if (err.error === 'consent_required') {
        loginWithRedirect({
          redirectUri: `${window.location.origin}${window.location.pathname}`,
        })
        return
      }
      throw err
    }
    const userid = user.sub
    const asyncRequests = itemIds.map((itemId, index) => {
      return async () => {
        const endpoint = process.env[`REACT_APP_${process.env.REACT_APP_APP_ENV}_API_URL`]
        const path = 'accounts_balance_get'
        const url = `${endpoint}/${path}?userid=${userid}&itemId=${itemId}`
        updateItemsStatus(itemId, { status: 'fetching', data: null, error: null, itemId })
        return await fetchTimeout(url, 25000, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },

          mode: 'cors',
          credentials: 'include',
        })
          .then(async (res) => {
            if (res.status === 200) {
              const json = await res.json()
              setTimeout(() => {
                updateItemsStatus(itemId, {
                  status: 'success',
                  error: null,
                  data: json as AccountsGetResponse,
                } as SuccessInterface<AccountsGetResponse>)
              }, (index + 1) * 500)
            } else {
              const json = await res.json()
              console.error(`<> (${itemId}) asyncRequest non-200-ed`)
              updateItemsStatus(itemId, {
                status: 'plaiderror',
                error: res.statusText,
                data: json,
                itemId,
              })
            }
          })
          .catch((err) => {
            console.error(err)
            updateItemsStatus(itemId, {
              status: 'error',
              error: String(err),
              data: null,
              itemId,
            } as RegularErrorInterface)
          })
      }
    })

    const promisesToWaitFor = asyncRequests.map((asyncRequest) => asyncRequest())
    await Promise.allSettled(promisesToWaitFor)
    await getAccounts({})
  }
  return {
    issueRequest,
    requestStatuses,
  }
}
