import React from 'react'
import { ContainerCutout } from '../../Components/DesignSystem/Layout/Containers/Containers'
import { MoneyStream } from '../../Utilities/interfaces'
import { LegendStreamEntry } from './StreamEntry/StreamEntry'
import { ScrollingModeTypes } from './StreamPage'

export const streamPageSubHeaderSize = 49

interface StreamPageSubHeaderProps {
  streams: MoneyStream[]
  sortingMode: ScrollingModeTypes
  setSortingMode: React.Dispatch<React.SetStateAction<ScrollingModeTypes>>
  sortingOrder: number
  setSortingOrder: React.Dispatch<React.SetStateAction<number>>
}
export const StreamPageSubHeader: React.FC<StreamPageSubHeaderProps> = ({
  streams,
  sortingMode,
  sortingOrder,
  setSortingMode,
  setSortingOrder,
}) => {
  if (streams.length === 0) {
    // NOTE: to maintain the height of the header when there are no streams
    return <div style={{ height: 49 }} />
  }
  return (
    <ContainerCutout size={streamPageSubHeaderSize}>
      <LegendStreamEntry
        sortingStrategy={sortingMode}
        sortingOrder={sortingOrder}
        onAmountClick={() => {
          switch (`${sortingOrder}-${sortingMode}`) {
            case `1-amount`:
              setSortingOrder(-1)
              setSortingMode('transfer')
              break
            case `-1-amount`:
              setSortingOrder(1)
              break
            default:
              setSortingMode('amount')
              setSortingOrder(-1)
          }
        }}
        onNameClick={() => {
          switch (`${sortingOrder}-${sortingMode}`) {
            case `1-name`:
              setSortingOrder(-1)
              setSortingMode('transfer')
              break
            case `-1-name`:
              setSortingOrder(1)
              break
            default:
              setSortingMode('name')
              setSortingOrder(-1)
          }
        }}
        onDateClick={() => {
          switch (`${sortingOrder}-${sortingMode}`) {
            case `1-date`:
              setSortingOrder(-1)
              setSortingMode('transfer')
              break
            case `-1-date`:
              setSortingOrder(1)
              break
            default:
              setSortingMode('date')
              setSortingOrder(-1)
          }
        }}
      />
    </ContainerCutout>
  )
}
