import { SortingArrow } from 'Pages/StreamPage/StreamEntry/StreamEntry'
import { StreamPart } from 'Pages/StreamPage/StreamEntry/components'
import { Row } from 'design-system/table/Row/Row'
import React from 'react'

interface LegendProps {
  onEnvelopeClick: () => void
  onBudgetClick: () => void
  onRemainderClick: () => void
  sortingOrder: number
  sortingStrategy: 'envelope' | 'budget' | 'remainder' | 'null'
}
export const LegendBudgetRow: React.FC<LegendProps> = ({
  onEnvelopeClick,
  onBudgetClick,
  onRemainderClick,
  sortingOrder,
  sortingStrategy,
}) => {
  return (
    <Row
      rowIcon={''}
      rowIconSize="sm"
      gridTemplateColumnString="minmax(0, 0.3fr) minmax(0, 1.75fr) minmax(0, 0.75fr) minmax(0, 1fr);"
      rowIconColor={'mehred'}
      firstColumn={
        <StreamPart
          sortingOrder={sortingOrder}
          enabled={sortingStrategy === 'envelope'}
          isLegend={true}
          isCreditCard={false}
          onClick={() => onEnvelopeClick()}>
          envelope
          <SortingArrow
            sortIsActive={sortingStrategy === 'envelope'}
            sortState={sortingOrder === 1 ? 'asc' : 'desc'}
          />
        </StreamPart>
      }
      secondColumn={
        <StreamPart
          style={{
            overflow: 'visible',
          }}
          sortingOrder={sortingOrder}
          enabled={sortingStrategy === 'budget'}
          isLegend={true}
          onClick={() => onBudgetClick()}>
          budget
          <SortingArrow
            sortIsActive={sortingStrategy === 'budget'}
            sortState={sortingOrder === 1 ? 'asc' : 'desc'}
          />
        </StreamPart>
      }
      thirdColumn={
        <StreamPart
          sortingOrder={sortingOrder}
          enabled={sortingStrategy === 'remainder'}
          isLegend={true}
          isCreditCard={false}
          onClick={() => onRemainderClick()}>
          left
          <SortingArrow
            sortIsActive={sortingStrategy === 'remainder'}
            sortState={sortingOrder === 1 ? 'asc' : 'desc'}
          />
        </StreamPart>
      }
    />
  )
}
