import { DataProvider } from 'Providers/APIDataProvider'
import { ErrorBoundary } from 'Providers/ErrorBoundary/ErrorBoundary'
import { FinancialDataProvider } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import { MysteryDayProvider } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import App from './App/App'
import './index.css'
// import reportWebVitals from "./reportWebVitals";
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { WishlistProvider } from 'Pages/WishlistPage/WishlistProvider/WishlistProvider'
import { Auth0ProviderWithHistory } from 'Providers/Auth0ProviderWithHistory'
import { PWAProvider } from 'Providers/PWAProvider/PWAProvider'
import { SplurvSnippetProvider } from 'Providers/SplurvSnippetProvider/SplurvSnippetProvider'
import { StreamModifyingProvider } from 'Providers/StreamModifyingProvider'
import { UIStateProvider } from 'Providers/UIStateProvider'
import { UserConfigProvider } from 'Providers/UserConfigProvider/UserConfigProvider'
import { UserCustomSettingsProvider } from 'Providers/UserCustomSettingsProvider/UserCustomSettingsProvider'
import { myTheme } from 'theme'
import './reset.css'

import generatedGitInfo from 'generatedGitInfo.json'
import { SubscriptionStatusProvider } from 'Providers/SubscriptionStatusProvider/SubscriptionStatusProvider'

if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.count = () => {}
  console.time = () => {}
  console.timeEnd = () => {}
  console.table = () => {}
}

const bootstrapDatadog = () => {
  datadogLogs.init({
    clientToken: 'pub74f71c91950d84edbd90bf7c14ebf0c4',
    site: 'datadoghq.com',
    version: generatedGitInfo.gitCommitHash,
    service: 'splurv-ui',
    env: process.env.REACT_APP_APP_ENV,
    forwardConsoleLogs: ['error'],
  })
  datadogRum.init({
    applicationId: '512e08d7-7cdf-4ab5-a40d-c8936471eb31',
    clientToken: 'pub74f71c91950d84edbd90bf7c14ebf0c4',
    site: 'datadoghq.com',
    env: process.env.REACT_APP_APP_ENV,
    service: 'splurv-ui',
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask',
    version: generatedGitInfo.gitCommitHash,
  })

  datadogRum.startSessionReplayRecording()

  datadogRum.addRumGlobalContext('viewport', {
    width: window.innerWidth,
    height: window.innerHeight,
  })
}

const main = () => {
  try {
    bootstrapDatadog()
  } catch (err) {
    alert('error bootstrapping datadog')
    console.log('error bootstrapping datadog', err)

    ReactDOM.render(
      <React.StrictMode>
        <h1 style={{ color: 'black' }}>something terrible has happened with datadog</h1>
      </React.StrictMode>,
      document.getElementById('root')
    )
  }
}

main()

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={myTheme}>
      <ErrorBoundary>
        <BrowserRouter>
          <Auth0ProviderWithHistory>
            <UIStateProvider>
              <PWAProvider>
                <UserConfigProvider>
                  <UserCustomSettingsProvider>
                    <SubscriptionStatusProvider>
                      <FinancialDataProvider>
                        <MysteryDayProvider>
                          <DataProvider>
                            <StreamModifyingProvider>
                              <WishlistProvider>
                                <SplurvSnippetProvider>
                                  <App />
                                </SplurvSnippetProvider>
                              </WishlistProvider>
                            </StreamModifyingProvider>
                          </DataProvider>
                        </MysteryDayProvider>
                      </FinancialDataProvider>
                    </SubscriptionStatusProvider>
                  </UserCustomSettingsProvider>
                </UserConfigProvider>
              </PWAProvider>
            </UIStateProvider>
          </Auth0ProviderWithHistory>
        </BrowserRouter>
      </ErrorBoundary>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
