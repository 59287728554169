import { add } from 'date-fns'
import React from 'react'
import { GraphErrorBoundary } from '../../../Components/GraphErrorBoundary'
import { useData } from '../../../Providers/APIDataProvider'
import { useFinancialData } from '../../../Providers/FinancialDataProvider/FinancialDataProvider'
import { useMysteryDay } from '../../../Providers/MysteryDayProvider/MysteryDayProvider'
import { cleanCurrency } from '../../../Utilities/currencyFormater'
import { stringToDate } from '../../../Utilities/dateUtilities/dateUtilities'
import { calculateBurndownValues, calculateItemPayoffValues } from '../helpers'
import { LaunchDayGraphComponent } from './LaunchDayGraphComponent'

export const LaunchDayProjectionGraph: React.FC = () => {
  const { monthlyContribution, startDate, itemCost, mysteryMode, desiredTimeOff } = useMysteryDay()

  const { currentSavings } = useFinancialData()

  const date = stringToDate(startDate)

  const { monthlyBurnRate } = useData()

  const totalRunwayNeeded = monthlyBurnRate * desiredTimeOff
  const remainingRunwayNeeded = totalRunwayNeeded - currentSavings
  const runwayFundValues = calculateItemPayoffValues(
    totalRunwayNeeded,
    monthlyContribution,
    date,
    currentSavings
  )

  const monthsUntilLaunchDay = Math.ceil((totalRunwayNeeded - currentSavings) / monthlyContribution)

  const launchDayDate = add(new Date(), { months: monthsUntilLaunchDay })

  const startingValueForBurnDown = runwayFundValues[runwayFundValues.length - 1].balance
  const startingDateFOrBurnDown = runwayFundValues[runwayFundValues.length - 1].date

  const savingsBurnDownValues = calculateBurndownValues(
    startingValueForBurnDown,
    monthlyBurnRate || 100,
    startingDateFOrBurnDown
  )

  const savingsBurnDownLabels = savingsBurnDownValues.map((ipv) => new Date(ipv.date))
  const savingsBurnDownData = savingsBurnDownValues.map((ipv) => ipv.balance)

  const runwayFundLabels = runwayFundValues.map((ipv) => ipv.date)
  const runwayFundData = runwayFundValues.map((ipv) => ipv.balance)

  const launchDayReached = remainingRunwayNeeded < 0

  const nullPlaceholdersForFutureData = Array.from({
    length: runwayFundValues.length,
  }).map((d) => null)

  return (
    <GraphErrorBoundary>
      <LaunchDayGraphComponent
        launchDayReached={launchDayReached}
        launchDayAmount={totalRunwayNeeded}
        launchDayDate={launchDayDate}
        coastingLabels={savingsBurnDownLabels}
        coastingData={[...nullPlaceholdersForFutureData, ...savingsBurnDownData]}
        launchDayLabels={runwayFundLabels}
        launchDayData={runwayFundData}
        goalAmount={cleanCurrency(itemCost)}
        mode={mysteryMode}
      />
    </GraphErrorBoundary>
  )
}
