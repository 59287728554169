import { ToggleSwitch } from 'Components/Toggle/Toggle'
import { useStreamModifyingState } from 'Providers/StreamModifyingProvider'
import { add, format, setDate } from 'date-fns'
import { SlidingPage, SlidingPageButton } from 'design-system/layout/SlidingPage/SlidingPage'
import { CaptionText } from 'design-system/typography/Text/Text'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { StyledHr, TextSpan } from '../../../Components/DesignSystem/Typography'
import { useData } from '../../../Providers/APIDataProvider'
import { cleanCurrency, formatCurrency } from '../../../Utilities/currencyFormater'
import {
  BiweeklyQuarterlySemiannuallyAnnuallyStream,
  FrequencyType,
  MoneyStream,
  OneTimeStream,
  SemimonthlyStream,
  SourceDestinationType,
  UpcomingEvent,
  WeeklyMonthlyStream,
} from '../../../Utilities/interfaces'
import { useGetMatchingTransactionsForStreamName } from '../StreamEntry/helpers'
import { AccountAllocationSection } from './AccountAllocationSection/AccountAllocationSection'
import { FancySelect, MoneyInputContainer } from './AddEditStream.components'
import { BillResponsibilitySection } from './BillResponsibilitySection/BillResponsibilitySection'
import { ExistingTransactionRow } from './ExistingTransactionRow'
import { UpcomingEvents } from './UpcomingEvents'
import {
  frequenciesWithFullDate,
  getInputLabelForFrequency,
  getPlaceholderForFrequency,
  getTypeForFrequency,
  isCompleteDate,
  isStringDayOfMonth,
  isStringDayOfWeek,
} from './utils'

export const AddEditPage: React.FC = () => {
  const { saveStream, deleteStream, savingStream, deletingStream } = useData()

  const {
    addEditPageOpen,
    streamToEdit,
    streamModifyMode,
    setAddEditPageOpen,
  } = useStreamModifyingState()

  const currentStream = streamToEdit as MoneyStream
  const onPageClose = React.useCallback(() => setAddEditPageOpen(false), [setAddEditPageOpen])
  const type = currentStream?.type || 'income'
  const mode = streamModifyMode
  const isPaycheck = false
  const isSubscription = currentStream?.isSubscription || false
  const isPurchase = false

  const _id = currentStream?._id

  const [name, setName] = useState<string>('')
  const [enabled, setEnabled] = useState<boolean>(true)
  const [amount, _setAmount] = useState<string>('$0')
  const [destinations, setDestinations] = useState<SourceDestinationType[]>([])
  const [sources, setSources] = useState<SourceDestinationType[]>([])
  const [savingsContribution, _setSavingsContribution] = useState<string>('$0')
  const [payDate, setPayDate] = useState<string>('') // This is the state value tracking the input. This will eventually turn into either a day of month/week or a complete date on a stream
  const [secondPayDate, setSecondPayDate] = useState<string>('') // This is the state value tracking the input. This will eventually turn into either a day of month/week or a complete date on a stream
  const [frequency, _setPayFrequency] = useState<FrequencyType>(FrequencyType.biweekly)
  const [upcomingEvents, setUpcomingEvents] = useState<UpcomingEvent[]>([])
  const [_isSubscription, _setIsSubscription] = useState<boolean>(false)

  const [startDate, setStartDate] = useState<string | undefined>('')
  const [endDate, setEndDate] = useState<string | undefined>('')

  const nameInputRef = useRef<HTMLInputElement>(null)

  const setPayFrequency = useCallback(
    (frequency: FrequencyType) => {
      _setPayFrequency(Number(frequency))
    },
    [_setPayFrequency]
  )

  const toggleActive = () => {
    setEnabled(!enabled)
  }

  const setAmount = useCallback(
    (amount: string) => {
      const formattedValue = formatCurrency(
        amount,
        10,
        type === 'bill' || type === 'purchase' ? 'negative' : 'positive'
      )
      _setAmount(formattedValue)
    },
    [type]
  )

  const setSavingsContribution = (amount: string) => {
    const formattedValue = formatCurrency(amount, 7, 'positive')
    _setSavingsContribution(formattedValue)
  }

  useEffect(() => {
    if (name === 'Credit Card') {
      setPayFrequency(FrequencyType.monthly)
    }
  }, [name, setPayFrequency])

  useEffect(() => {
    const {
      name,
      amount,
      frequency,
      enabled,
      upcomingEvents,
      startDate,
      endDate,
      sources,
      destinations,
      isSubscription,
    } = currentStream
    if (isPaycheck) {
      setName('Paycheck')
    } else {
      setName(name)
    }
    if (isPurchase) {
      setPayFrequency(FrequencyType.onetime)
    } else {
      setPayFrequency(frequency)
    }
    if (isSubscription !== undefined) {
      _setIsSubscription(isSubscription)
    }
    setAmount(String(amount))
    setEnabled(enabled)
    setUpcomingEvents(upcomingEvents || [])
    setEndDate(endDate || format(add(new Date(), { years: 5 }), 'yyyy-MM-dd'))
    setSources(sources || [])
    setDestinations(destinations || [])
    switch (currentStream.frequency) {
      case FrequencyType.semimonthly:
        const { mostRecentDate, secondMostRecentDate } = currentStream
        setPayDate(String(mostRecentDate) || format(new Date(), 'yyyy-MM-dd'))
        setSecondPayDate(
          String(secondMostRecentDate) || format(add(new Date(), { days: 15 }), 'yyyy-MM-dd')
        )
        break
      case FrequencyType.biweekly:
      case FrequencyType.quarterly:
      case FrequencyType.semiannually:
      case FrequencyType.anually:
        const { nextDate, savingsContribution } = currentStream
        const payDateToSet = String(nextDate) || format(new Date(), 'yyyy-MM-dd')
        setPayDate(payDateToSet)
        setSavingsContribution(String(savingsContribution))
        break
      case FrequencyType.monthly:
        const { day } = currentStream
        const currentDate = new Date()
        const updatedDate = setDate(currentDate, Number(day || currentDate.getDate()))
        setPayDate(String(day || ''))
        setStartDate(startDate || format(updatedDate, 'yyyy-MM-dd'))
        break
      case FrequencyType.weekly:
        const { day: dayOfWeek } = currentStream
        setPayDate(String(dayOfWeek || ''))
        break
      case FrequencyType.onetime:
        const { futureDate } = currentStream
        if (!futureDate) {
          setPayDate(format(add(new Date(), { days: 1 }), 'yyyy-MM-dd'))
        } else {
          setPayDate(String(futureDate))
        }
        break
    }
    if (isNaN(frequency) || frequency === undefined || frequency === null) {
      setPayFrequency(FrequencyType.onetime)
      setPayDate(format(add(new Date(), { days: 1 }), 'yyyy-MM-dd'))
    }
  }, [
    currentStream,
    setAmount,
    isPaycheck,
    isPurchase,
    setEnabled,
    setPayFrequency,
    setUpcomingEvents,
    setEndDate,
  ])

  const constructActiveStream = (): MoneyStream => {
    let stream: MoneyStream = {
      name,
      enabled,
      frequency,
      upcomingEvents,
      startDate,
      endDate,
      sources,
      destinations,
      transfer: currentStream.transfer || currentStream.name === 'Paycheck' || name === 'Paycheck',
      // transfer: currentStream.transfer,
      type,
      isSubscription: _isSubscription,
      amount: cleanCurrency(amount),
      _id,
    } as MoneyStream

    // Then, based on the frequency (monthly, bimonthly, onetime, etc) we assign different values for the date
    switch (frequency) {
      case FrequencyType.semimonthly:
        stream = {
          ...stream,
          mostRecentDate: payDate,
          secondMostRecentDate: secondPayDate,
          savingsContribution: cleanCurrency(savingsContribution),
        } as SemimonthlyStream
        break

      case FrequencyType.onetime:
        stream = { ...stream, futureDate: payDate } as OneTimeStream
        break
      case FrequencyType.biweekly:
      case FrequencyType.quarterly:
      case FrequencyType.semiannually:
      case FrequencyType.anually:
        stream = {
          ...stream,
          nextDate: payDate,
          savingsContribution: cleanCurrency(savingsContribution),
        } as BiweeklyQuarterlySemiannuallyAnnuallyStream
        break
      case FrequencyType.weekly:
      case FrequencyType.monthly:
        stream = { ...stream, day: Number(payDate) } as WeeklyMonthlyStream
        break
      default:
        stream = stream as MoneyStream
        break
    }

    return stream
  }

  const onSaveClick = async (createAnother = false) => {
    if (frequency === null || frequency === undefined || isNaN(frequency)) {
      alert('You have not selected a frequency')
      return
    }
    if (!name) {
      alert('You have not entered a name')
      return
    }
    if (!amount) {
      alert('You have not entered an amount')
      return
    }

    if (frequency === FrequencyType.weekly && !isStringDayOfWeek(payDate)) {
      alert('Day of week must be between 1 and 7')
      return
    }

    if (frequency === FrequencyType.monthly && !isStringDayOfMonth(payDate)) {
      alert('Day of month must be between 1 and 31')
      return
    }

    if (frequenciesWithFullDate.includes(frequency) && !isCompleteDate(payDate)) {
      alert(`Date must be in YYYY-MM-DD format, it's currently ${payDate}`)
      return
    }

    if (
      frequency === FrequencyType.semimonthly &&
      (!isCompleteDate(payDate) || !isCompleteDate(secondPayDate))
    ) {
      alert('Dates must be in YYYY-MM-DD format')
      return
    }

    const activeStream = constructActiveStream()
    await saveStream(activeStream).then(async () => {
      if (!createAnother) {
        onPageClose()
      } else {
        setName('')
        setAmount('')
        setPayDate('')
        nameInputRef.current?.focus()
      }
    })
  }

  // eslint-disable-next-line
  const activeStream = useMemo(() => constructActiveStream(), [
    name,
    enabled,
    frequency,
    upcomingEvents,
    startDate,
    endDate,
    sources,
    destinations,
    amount,
  ])

  const handleFrequencySelectChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    ev.preventDefault()

    if (
      (Number(ev.target.value) as FrequencyType) === FrequencyType.monthly ||
      (Number(ev.target.value) as FrequencyType) === FrequencyType.weekly
    ) {
      setPayDate('1')
      setPayFrequency(Number(ev.target.value))
      return
    }

    if ((Number(ev.target.value) as FrequencyType) === FrequencyType.semimonthly) {
      const payDateToSet = format(new Date(), 'yyyy-MM-dd')
      const secondPayDateToSet = format(add(new Date(), { days: 14 }), 'yyyy-MM-dd')
      setPayDate(payDateToSet)
      setSecondPayDate(secondPayDateToSet)
      setPayFrequency(Number(ev.target.value))
      return
    }
    const payDateToSet = format(new Date(), 'yyyy-MM-dd')
    setPayFrequency(Number(ev.target.value))
    setPayDate(payDateToSet)
  }

  const toggleUpcomingEventAsPaid = (date: string) => {
    const copy = [...upcomingEvents]
    const eventToUpdate = copy.find((upcomingEvent) => upcomingEvent?.date === date)
    if (eventToUpdate) {
      eventToUpdate.paidOff = !eventToUpdate.paidOff
      setUpcomingEvents(copy)
    } else {
      console.error('Could not find upcoming event to update')
      copy.push({ date: date, paidOff: true })
      setUpcomingEvents(copy)
    }
  }

  const {
    streamTransactionFromLastMonth,
    streamTransactionFromThisMonth,
  } = useGetMatchingTransactionsForStreamName(name)

  const title = isSubscription
    ? 'Add Subscription'
    : currentStream.transfer
    ? 'Transfer'
    : `${mode.toUpperCase()} ${type}`

  const subtitle =
    type === 'income'
      ? 'If adding a paycheck, add your post-tax, post-401k, take home, amount.'
      : 'Add details about your fixed bill here.'

  const primaryButton: SlidingPageButton = {
    title: currentStream?._id
      ? savingStream
        ? 'updating...'
        : 'update'
      : savingStream
      ? 'saving...'
      : 'save',
    icon: `${currentStream?._id ? 'fas fa-check' : 'fas fa-plus'} ${savingStream ? 'fa-spin' : ''}`,
    onClick: async () => {
      await onSaveClick(false)
    },
  }
  const secondaryButton: SlidingPageButton = {
    title: 'cancel',
    icon: 'fas fa-times',
    onClick: () => onPageClose(),
  }
  const deleteButton: SlidingPageButton | undefined = currentStream?._id
    ? {
        title: deletingStream ? 'deleting...' : 'delete',
        icon: 'fas fa-trash',
        onClick: async () => {
          const answer = window.confirm('Are you sure you would like to delete this ?')
          if (answer) {
            await deleteStream(_id as string)
            onPageClose()
          } else {
          }
        },
      }
    : undefined

  return (
    <SlidingPage
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      deleteButton={deleteButton}
      open={addEditPageOpen}
      title={title}
      subtitle={subtitle}
      content={
        <div className="w-100">
          {/* <div className="d-flex justify-content-between align-items-start"> */}
          <MoneyInputContainer icon="f02b" label="Name" style={{ minWidth: 'unset' }}>
            <input
              data-testid="stream-name-input"
              ref={nameInputRef}
              value={name}
              placeholder={
                type === 'purchase' ? 'Christmas Shopping' : type === 'income' ? 'Income' : 'Rent'
              }
              onChange={(e) => setName(e.currentTarget.value)}></input>
          </MoneyInputContainer>
          {/* </div> */}

          <MoneyInputContainer
            icon="f155"
            label={name === 'Paycheck' ? 'Net Paycheck Amount' : 'Amount'}>
            <input
              data-testid="stream-amount-input"
              inputMode="decimal"
              value={amount}
              placeholder={type === 'purchase' ? '100' : type === 'income' ? '500' : '1,200'}
              onChange={(e) => setAmount(e.currentTarget.value)}></input>
          </MoneyInputContainer>

          <form>
            <MoneyInputContainer icon="f1da" label="Frequency">
              <FancySelect
                disabled={name === 'Credit Card'}
                onChange={handleFrequencySelectChange}
                data-testid="stream-frequency-input"
                value={frequency}>
                <optgroup>
                  <>
                    {name === 'Credit Card' ? (
                      <option value={FrequencyType.monthly}>Monthly</option>
                    ) : name === 'Paycheck' ? (
                      <>
                        <option value={FrequencyType.weekly}>Every Week</option>
                        <option value={FrequencyType.biweekly}>Every 2 weeks</option>
                        <option value={FrequencyType.semimonthly}>Twice a month</option>
                        <option value={FrequencyType.monthly}>Every month</option>
                      </>
                    ) : (
                      <>
                        <option value={FrequencyType.weekly}>Every Week</option>
                        <option value={FrequencyType.biweekly}>Every 2 weeks</option>
                        <option value={FrequencyType.monthly}>Every month</option>
                        <option value={FrequencyType.quarterly}>Every 3 months</option>
                        <option value={FrequencyType.semimonthly}>Twice a month</option>
                        <option value={FrequencyType.semiannually}>Every 6 months</option>
                        <option value={FrequencyType.anually}>Ever year</option>
                        <option value={FrequencyType.onetime}>Once</option>
                      </>
                    )}
                  </>
                </optgroup>
              </FancySelect>
            </MoneyInputContainer>
          </form>
          <MoneyInputContainer
            icon="f073"
            className="w-100"
            label={getInputLabelForFrequency(frequency)}>
            <input
              placeholder={getPlaceholderForFrequency(frequency)}
              data-testid="stream-date-input"
              inputMode={
                frequency === FrequencyType.weekly || frequency === FrequencyType.monthly
                  ? 'numeric'
                  : undefined
              }
              type={getTypeForFrequency(frequency)}
              value={
                getTypeForFrequency(frequency) === 'date' && payDate === ''
                  ? format(new Date(), 'yyyy-MM-dd')
                  : payDate
              }
              style={{ maxHeight: 'calc(100%)', minWidth: 'fit-content' }}
              onChange={(e) => setPayDate(e.currentTarget.value)}></input>
          </MoneyInputContainer>

          {frequency === FrequencyType.semimonthly ? (
            <MoneyInputContainer
              icon="f073"
              className="w-100"
              label={'2nd most recent occurrence date'}>
              <input
                placeholder={getPlaceholderForFrequency(frequency)}
                data-testid="stream-date-input"
                type={getTypeForFrequency(frequency)}
                value={
                  getTypeForFrequency(frequency) === 'date' && secondPayDate === ''
                    ? format(add(new Date(), { days: 15 }), 'yyyy-MM-dd')
                    : secondPayDate
                }
                style={{ maxHeight: 'calc(100%)', minWidth: 'fit-content' }}
                onChange={(e) => setSecondPayDate(e.currentTarget.value)}></input>
            </MoneyInputContainer>
          ) : null}
          {/* <RangeDuration /> */}
          {mode === 'edit' ? (
            <UpcomingEvents
              payDate={payDate}
              activeStream={{ ...activeStream }}
              toggleUpcomingEventAsPaid={toggleUpcomingEventAsPaid}
            />
          ) : null}
          {type === 'bill' ? (
            <BillResponsibilitySection
              originalAmount={cleanCurrency(amount)}
              onAllocationSet={function (account: string, amount: number): void {
                if (type === 'bill') {
                  setSources((sources) => {
                    const copy = [...sources]
                    const existingSourceForAccount = copy.find((d) => d.accountIdOrName === account)
                    if (existingSourceForAccount) {
                      existingSourceForAccount.portionAllocated = amount
                      return copy
                    } else {
                      return [
                        {
                          accountIdOrName: account,
                          portionAllocated: amount,
                        },
                      ]
                    }
                  })
                }
              }}
              totalAllocations={sources}
            />
          ) : null}
          {type === 'income' ? (
            <AccountAllocationSection
              originalAmount={cleanCurrency(amount)}
              onAllocationSet={function (account: string, amount: number): void {
                if (type === 'income') {
                  setDestinations((destinations) => {
                    const copy = [...destinations]
                    const existingDestinationForAccount = copy.find(
                      (d) => d.accountIdOrName === account
                    )
                    if (existingDestinationForAccount) {
                      existingDestinationForAccount.portionAllocated = amount
                      return copy
                    } else {
                      return [
                        ...copy,
                        {
                          accountIdOrName: account,
                          portionAllocated: amount,
                        },
                      ]
                    }
                  })
                }
                // if (type === 'bill') {
                //   setSources((sources) => {
                //     const copy = [...sources]
                //     const existingSourceForAccount = copy.find((d) => d.accountIdOrName === account)
                //     if (existingSourceForAccount) {
                //       existingSourceForAccount.portionAllocated = amount
                //       return copy
                //     } else {
                //       return [
                //         {
                //           accountIdOrName: account,
                //           portionAllocated: amount,
                //         },
                //       ]
                //     }
                //   })
                // }
              }}
              totalAllocations={destinations}
            />
          ) : null}

          {currentStream?._id ? (
            <div className="d-flex flex-row w-100">
              <div className="d-flex flex-column ">
                <div className="d-flex align-items-center">
                  <ToggleSwitch
                    enabled={_isSubscription}
                    toggleEnabled={() => _setIsSubscription((is) => !is)}
                    enabledText={'subscription'}
                    disabledText={'bill'}
                    label={''}
                  />
                  <CaptionText className="ml-3">
                    {_isSubscription
                      ? 'A subscription is an online service and includes media subscriptions'
                      : 'A bill is a living expense and includes rent and car payments'}
                  </CaptionText>
                </div>
              </div>
            </div>
          ) : null}
          {currentStream?._id ? (
            <div className="d-flex flex-row w-100">
              <div className="d-flex flex-column ">
                <div className="d-flex align-items-center">
                  <ToggleSwitch
                    enabled={enabled}
                    toggleEnabled={() => toggleActive()}
                    enabledText={'enabled'}
                    disabledText={'disabled'}
                    label={''}
                  />
                  <CaptionText className="ml-3">
                    {enabled
                      ? 'An enabled bill will be counted towards spending'
                      : 'A disabled bill will NOT be counted towards spending'}
                  </CaptionText>
                </div>
              </div>
            </div>
          ) : null}

          {currentStream?._id ? (
            <div className="d-flex flex-column w-100">
              <TextSpan>Live Transactions That Match</TextSpan>
              <StyledHr weight={1} className="mb-2" color="nearlyclear" />
              <div className="d-flex flex-column">
                <ExistingTransactionRow timeFrame="lastMonth" t={streamTransactionFromLastMonth} />
                <ExistingTransactionRow timeFrame="thisMonth" t={streamTransactionFromThisMonth} />
              </div>
            </div>
          ) : null}
        </div>
      }
    />
  )
}
