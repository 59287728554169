import color from 'color'
import React, { useEffect } from 'react'

import { BankLogo } from '../../../../Components/BankLogo'
import { CollapseCard } from '../../../../Components/CollapseCard/CollapseCard'
import { DataOrFeatureMissing } from '../../../../Components/DataOrFeatureMissing/DataOrFeatureMissing'
import { Icon, TextPopupSmall, TextSlim } from '../../../../Components/DesignSystem/Typography'
import { useFinancialData } from '../../../../Providers/FinancialDataProvider/FinancialDataProvider'
import {
  getCashAccounts,
  getSavingsAccounts,
} from '../../../../Providers/FinancialDataProvider/helpers'
import { useUIState } from '../../../../Providers/UIStateProvider'
import { SourceDestinationType } from '../../../../Utilities/interfaces'
import { ActiveMapSelector } from '../../../CashFlowPage/Chart/Chart'
import { bankLogoForBank } from '../../../CreditCardPage/CardTetherSetupPage/CardTetherSetupPage'
import { getBankItemFromAccount } from '../../../CreditCardPage/dataHelpers'
import { AccountAllocationSectionContainer } from './BillResponsibilitySectionComponents'

type TotalAllocationType = SourceDestinationType[]

interface AccountMaskAndLogoProps {
  accountId?: string
  size?: number
}
export const BankAccountLogo: React.FC<AccountMaskAndLogoProps> = ({ accountId, size = 24 }) => {
  const { accountsData } = useFinancialData()

  if (!accountId) {
    return (
      <div className="d-flex flex-column align-items-start">
        <BankLogo logo={undefined} size={size} />
      </div>
    )
  }
  const insitutions = accountsData?.data?.institutions
  const accounts = accountsData?.data?.accounts
  const items = accountsData?.data?.items
  const bankForAccount = getBankItemFromAccount(accounts, items, accountId)
  const logo = bankLogoForBank(bankForAccount, insitutions)

  return (
    <div className="d-flex flex-column align-items-start">
      <BankLogo logo={logo} size={size} />
    </div>
  )
}

export const generateBackgroundColor = (colorString: string, mode: 'dark' | 'light') => {
  return mode === 'dark'
    ? color(colorString).saturate(-0.3).darken(0.5).alpha(0.8).toString()
    : color(colorString).saturate(-0.1).lighten(0.5).alpha(0.3).toString()
}

interface Props {
  originalAmount: number
  // A curried function that from the parent will link this amount to the correct account
  onAllocationSet: (account: string, amount: number) => void
  totalAllocations: TotalAllocationType
}

export const BillResponsibilitySection: React.FC<Props> = ({
  onAllocationSet,
  originalAmount,
  totalAllocations,
}) => {
  const { flatAccounts, flatManualAccounts } = useFinancialData()
  const { setMessagePopupContent } = useUIState()

  const cashSavingsAccountsLive = [
    ...getCashAccounts(flatAccounts),
    ...getSavingsAccounts(flatAccounts),
  ]
  const cashSavingsAccountsManual = [
    ...flatManualAccounts.filter((fma) => fma.type === 'Cash'),
    ...flatManualAccounts.filter((fma) => fma.type === 'Savings'),
  ]

  const currentlySelectedSourceAccount = totalAllocations?.[0]?.accountIdOrName || ''

  const accountsForAllocation = [...cashSavingsAccountsLive, ...cashSavingsAccountsManual]
  const accountsAvailable = accountsForAllocation.length !== 0

  const [activeAccountName, setActiveAccountName] = React.useState<string>(
    currentlySelectedSourceAccount
  )

  useEffect(() => {
    setActiveAccountName(currentlySelectedSourceAccount)
  }, [currentlySelectedSourceAccount])
  // const [activeAccount, setActiveAccount] = React.useState<string>(accountsForAllocation?.[0]?.name)

  if (true) {
    return null
  }

  return (
    <CollapseCard
      header={
        <TextSlim size="md" color="textsupertransparent">
          Source Account
          <TextPopupSmall
            color={'textsupertransparent'}
            className="ml-2 p-2"
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              setMessagePopupContent(
                'Responsibility<>In this section, you assign which one of your cash accounts is responsible for paying this off'
              )
            }}>
            <Icon color={'textsupertransparent'} size="lg" className="fas fa-circle-info" />
          </TextPopupSmall>
        </TextSlim>
      }
      body={
        <AccountAllocationSectionContainer>
          {accountsAvailable ? (
            <ActiveMapSelector
              activeAccount={activeAccountName}
              setActiveAccount={(v) => {
                setActiveAccountName(v)
                const activeAccount = accountsForAllocation.find((afa) => afa.name === v)
                onAllocationSet(v, activeAccount?.amount)
              }}
            />
          ) : (
            <DataOrFeatureMissing
              title="Accounts Needed"
              body="To setup income allocation, you must have at least one or more cash or savings accounts setup"
            />
          )}
        </AccountAllocationSectionContainer>
      }
      // body={
      //   <AccountAllocationSectionContainer>
      //     {accountsAvailable ? (
      //       <>
      //         <StyledHr weight={1} color="textsupertransparent" />
      //         <ScrollingHalf style={{ maxHeight: '140px', minHeight: '140px' }}>
      //           {accountsForAllocation.map((afa) => {
      //             const accountBeingUsedForAllocation = totalAllocations.find(
      //               (a) => a.accountIdOrName === afa.name
      //             )

      //             const setAllocationAmount = (account: string) => {
      //               return (amount: number) => {
      //                 onAllocationSet(account, amount)
      //               }
      //             }

      //             const setOpen: React.Dispatch<React.SetStateAction<boolean>> = (value) => {
      //               setSelectedAccount(afa.name)
      //             }

      //             return (
      //               <AccountAllocationRow
      //                 key={afa.name}
      //                 account={afa}
      //                 open={afa.name === selectedAccount}
      //                 setOpen={setOpen}
      //                 allocationAmount={accountBeingUsedForAllocation?.portionAllocated || 0}
      //                 maxAllocationAmount={leftToAllocate}
      //                 originalAmount={originalAmount}
      //                 setAllocationAmount={(amount) => setAllocationAmount(afa.name)(amount)}
      //               />
      //             )
      //           })}
      //         </ScrollingHalf>
      //       </>
      //     ) : (
      //       <DataOrFeatureMissing
      //         title="Accounts Needed"
      //         body="To setup income allocation, you must have at least one or more cash or savings accounts setup"
      //       />
      //     )}
      //   </AccountAllocationSectionContainer>
      // }
    />
  )
}
