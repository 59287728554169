import { Icon } from 'Components/DesignSystem/Typography'
import React, { useState } from 'react'
import { ColorType } from 'theme'
import { Button } from './WideButton.components'

interface Props {
  bgColor: ColorType
  appearance: 'primary' | 'secondary'
  textSize: 'small' | 'large'
  width: 'full' | 'fit'
  text: string
  icon?: {
    className: string
    position: 'left' | 'right'
  }
  active: boolean
}
export const WideButton: React.FC<Props & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  text,
  icon,
  children,
  ...props
}) => {
  const [pressed, setPressed] = useState<boolean>(false)
  return (
    <Button
      {...props}
      className={`${pressed ? 'pressed' : ''} ${props.className}`}
      onTouchStart={() => setPressed(true)}
      onTouchEnd={() => setPressed(false)}
      onTouchCancel={() => setPressed(false)}
      onMouseLeave={() => setPressed(false)}
      onMouseUp={() => setPressed(false)}
      onDragLeave={() => setPressed(false)}
      pressed={pressed}>
      {children ? (
        children
      ) : (
        <>
          {icon && icon.position === 'left' ? <Icon className={`${icon.className} left`} /> : null}
          <span>{text}</span>
          {icon && icon.position === 'right' ? (
            <Icon className={`${icon.className} right`} />
          ) : null}
        </>
      )}
    </Button>
  )
}
