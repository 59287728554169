import { MysteryDayType } from 'Providers/MysteryDayProvider/MysteryDayProvider.interfaces'
import { LinkType, labelFromLink } from 'Utilities/navigationTabUtilities'
import { NavLink, NavLinkProps } from 'react-router-dom'
import styled from 'styled-components/macro'

interface LinkButtonProps extends NavLinkProps {
  link: LinkType
  disabled: boolean
  mysterymode?: MysteryDayType
  $onboardingcomplete?: boolean
  $notifications: number
  $notificationIcon?: string
}
export const LinkButton = styled(NavLink)<LinkButtonProps>`
  width: ${(props) => `${props.theme.footer.buttonDiameter}rem`};
  height: ${(props) => `${props.theme.footer.buttonDiameter}rem`};
  min-width: ${(props) => `${props.theme.footer.buttonDiameter}rem`};
  min-height: ${(props) => `${props.theme.footer.buttonDiameter}rem`};
  max-width: ${(props) => `${props.theme.footer.buttonDiameter}rem`};
  max-height: ${(props) => `${props.theme.footer.buttonDiameter}rem`};

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  position: relative;
  /* border-radius: 50%; */

  /* background-color: ${(props) => props.theme.colors.nearlyclear}; */
  z-index: 2;

  color: ${(props) =>
    props.link === '/mystery'
      ? props.theme.colors.text
      : // ? color(props.theme.colors[colorFromMysteryDayType(props.mysterymode)]).alpha(0.9).toString()
        props.theme.colors.text};

  /* NEED TO KEEP */
  :visited,
  :hover {
    color: ${(props) =>
      props.link === '/mystery'
        ? props.theme.colors.text
        : // ? color(props.theme.colors[colorFromMysteryDayType(props.mysterymode)])
          //     .alpha(0.9)
          //     .toString()
          props.theme.colors.text};
    text-decoration: unset;
    transition: all ease-in-out 0.3s;
  }

  /* THE LABEL */
  ::after {
    content: ${(props) =>
      `'${labelFromLink(props.link, props.mysterymode, props.$onboardingcomplete)}'`};
    display: block;

    color: inherit;
    margin-top: 0.5rem;
    font-size: ${(props) => props.theme.fonts.sizes.xxs};
    font-family: ${(props) => props.theme.fonts.family.secondary};
    font-weight: 400;
    transition: all ease-in-out 0.3s;
  }

  /* THE BADGE */
  ::before {
    /* content: ${(props) => `'${props?.$notifications}'`}; */
    /* content: ''; */
    content: '${({ $notificationIcon }) => `\\${$notificationIcon}`}';
    font-weight: 800;

    font-family: 'Font Awesome 6 Pro';
    display: block;
    visibility: ${(props) => (props?.$notifications > 0 ? 'visible' : 'hidden')};

    color: ${(props) => props.theme.colors.text};
    height: 10px;
    width: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: ${(props) => props.theme.colors.logoPrimary};
    background: ${(props) => props.theme.colors.logoPrimary};
    background: transparent;
    margin-top: 0.5rem;
    top: -8px;
    right: 0px;
    position: absolute;
    font-size: ${(props) => props.theme.fonts.sizes.xxs};
    /* font-family: ${(props) => props.theme.fonts.family.secondary}; */
    transition: all ease-in-out 0.3s;
  }

  &.link-active {
    color: ${(props) =>
      props.link === '/mystery'
        ? // ? props.theme.colors[colorFromMysteryDayType(props.mysterymode)]
          props.theme.colors.logoPrimary
        : props.theme.colors.logoPrimary};

    ::after {
      color: inherit;
    }
  }
`

export const LinkIcon = styled.i`
  font-size: ${(props) => `calc(${props.theme.footer.buttonDiameter}rem / 2.25)`};
  transition: all ease-in-out 0.3s;
`

export const StyledFooterContainer = styled.div<{ introWalkThrough?: boolean }>`
  grid-area: footer;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  position: sticky;
  background: ${(props) =>
    props.introWalkThrough ? 'transparent' : props.theme.colors.background};
`

export const StyledFooter = styled.footer`
  position: relative;
  padding: 0 1rem;
  max-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    &:nth-of-type(4) {
      width: ${(props) => `${props.theme.footer.buttonDiameterSmall}rem`};
      height: ${(props) => `${props.theme.footer.buttonDiameterSmall}rem`};
      min-width: ${(props) => `${props.theme.footer.buttonDiameterSmall}rem`};
      min-height: ${(props) => `${props.theme.footer.buttonDiameterSmall}rem`};
      max-width: ${(props) => `${props.theme.footer.buttonDiameterSmall}rem`};
      max-height: ${(props) => `${props.theme.footer.buttonDiameterSmall}rem`};
      &:after {
        color: ${(props) => props.theme.colors.texttransparent};
        transition: all ease-in-out 0.3s;
      }
      i {
        font-size: ${(props) => `calc(${props.theme.footer.buttonDiameterSmall}rem / 2.25)`};
        color: ${(props) => props.theme.colors.texttransparent};
      }
      &.link-active {
        color: ${(props) => props.theme.colors.logoPrimary}!important;
        transition: all ease-in-out 0.3s;
        i {
          color: ${(props) => props.theme.colors.logoPrimary};
        }

        ::after {
          color: inherit;
        }
      }
    }
    &:nth-of-type(5) {
      width: ${(props) => `${props.theme.footer.buttonDiameterSmall}rem`};
      height: ${(props) => `${props.theme.footer.buttonDiameterSmall}rem`};
      min-width: ${(props) => `${props.theme.footer.buttonDiameterSmall}rem`};
      min-height: ${(props) => `${props.theme.footer.buttonDiameterSmall}rem`};
      max-width: ${(props) => `${props.theme.footer.buttonDiameterSmall}rem`};
      max-height: ${(props) => `${props.theme.footer.buttonDiameterSmall}rem`};

      &:after {
        color: ${(props) => props.theme.colors.texttransparent};
        transition: all ease-in-out 0.3s;
      }
      i {
        font-size: ${(props) => `calc(${props.theme.footer.buttonDiameterSmall}rem / 2.25)`};
        color: ${(props) => props.theme.colors.texttransparent};
      }
      &.link-active {
        color: ${(props) => props.theme.colors.logoPrimary}!important;
        transition: all ease-in-out 0.3s;
        i {
          color: ${(props) => props.theme.colors.logoPrimary};
        }

        ::after {
          color: inherit;
        }
      }
    }
  }
`
