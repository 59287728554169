import { NewAccountCTA } from 'Components/NewAccountCTA/NewAccountCTA'
import { useAsyncFetch } from 'Hooks/useAsyncFetch'
import { HybridRegularAndInvestmentTransaction } from 'Providers/MysteryDayProvider/MysteryDayProvider.interfaces'
import { colorFromMysteryDayType } from 'Utilities/utils'
import { WideButton } from 'design-system/buttons/WideButton/WideButton'
import React from 'react'
import { ColorType } from 'theme'
import { ContainerDiv } from '../../Components/DesignSystem/Layout/Containers/Containers'
import { useMysteryDay } from '../../Providers/MysteryDayProvider/MysteryDayProvider'
import { useUIState } from '../../Providers/UIStateProvider'
import { AccountTrackingAccountPicker } from './AccountTrackingAccountPicker'
import { AccountTrackingPageHeader } from './AccountTrackingPageHeader'

interface Props {
  updateMode: boolean
}
export const AccountTrackingPage: React.FC<Props> = ({ updateMode }) => {
  const { setPickExistingAccountPageOpen, pickExistingAccountPageOpen } = useUIState()

  const { linkedAccount, linkedAccounts, saveUserOnboardingLinkedAccount } = useMysteryDay()

  const transactionsForAccount = useAsyncFetch<
    { data: HybridRegularAndInvestmentTransaction[] },
    {
      itemId: string
      accountId: string
      monthsOfTransactions: number
      accountType: 'regular' | 'investment'
    },
    {}
  >({
    method: 'GET',
    route: 'transactions_for_tracked_account',
  })

  const loading = transactionsForAccount.res.status === 'loading'

  if (pickExistingAccountPageOpen === false) return null

  const typeColor: ColorType = colorFromMysteryDayType(pickExistingAccountPageOpen)

  return (
    <>
      <ContainerDiv className="justify-content-between pt-0 pb-5 w-100">
        <ContainerDiv className="justify-content-start w-100">
          <AccountTrackingPageHeader updateMode={updateMode} />

          <AccountTrackingAccountPicker mode={pickExistingAccountPageOpen} type="live" />
          <AccountTrackingAccountPicker mode={pickExistingAccountPageOpen} type="manual" />
          <NewAccountCTA
            onManualAccountAddClick={() => {
              setPickExistingAccountPageOpen(false)
            }}
          />
        </ContainerDiv>
        <WideButton
          bgColor={typeColor}
          className="w-100 mx-auto px-5 align-self-center"
          text="Connect"
          active={true}
          appearance="primary"
          textSize="large"
          width="full"
          disabled={(!linkedAccount?.accountId && !linkedAccounts.length) || loading}
          onClick={async () => {
            if (updateMode && linkedAccount) {
              await saveUserOnboardingLinkedAccount(linkedAccount)
              setPickExistingAccountPageOpen(false)
            } else {
              setPickExistingAccountPageOpen(false)
            }
            // setLinkedAccount((linkedAccount) => ({
            //   ...linkedAccount,
            //   // transactions,
            // }))
            // setDebtAmount(String(debtAmount))
          }}
        />
      </ContainerDiv>
    </>
  )
}
