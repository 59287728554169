import { CurrencyCounter } from 'Components/AnimatedCounters/CurrencyCounter'
import { NumberCounter } from 'Components/AnimatedCounters/NumberCounter'
import { Icon, StyledHr, StyledVr, TextSpan } from 'Components/DesignSystem/Typography'
import { MCOLBadge } from 'Components/MCOLBadge'
import { SplurvSnippet } from 'Components/SplurvSnippet/SplurvSnippet'
import { useData } from 'Providers/APIDataProvider'
import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import { useUIState } from 'Providers/UIStateProvider'
import { numberToCurrency } from 'Utilities/currencyFormater'
import color from 'color'
import React from 'react'
import Slider from 'react-input-slider'
import { ColorType, colorPalletes } from 'theme'
import { SliderPanelContainer } from '../components'
import {
  FixedExpensesMarkerOverlay,
  FixedExpensesMarkerTick,
  FlexibleSpendMarkerOverlay,
  FlexibleSpendMarkerTick,
} from './MonthlyExpenseSlider.components'
import { useGetSliderWidths, useSetupSlider } from './hooks'

interface Props {}
export const MonthlyExpenseSlider: React.FC<Props> = () => {
  const {
    livingCostPercentageAdjustment,
    setLivingCostPercentageAdjustment,
    mysteryMode,
    cushionSubMode,
  } = useMysteryDay()
  const { activeTheme } = useUIState()

  const { sliderContainerRef, sliderWidth } = useSetupSlider()

  // const [expenseSliderDragging, setExpenseSliderDragging] = React.useState<boolean>(false)

  const {
    fixedExpenseMarkerWidth,
    flexibleExpenseMarkerWidth,
    percentageOfExpensesThatAreFixed,
    percentageOfExpensesThatAreVariable,
    totalFixed,
    flexibleSpendPerMonth,
    bareBonesMonthlyBurnRate,

    maxPercentageRate,
    // maxCostOfLiving,
    // maxCostOfLivingPercentageAdjustment,
  } = useGetSliderWidths({ sliderContainerRef, sliderWidth })

  const xmin = 0
  const xmax = 2
  const numberOfIncrements = 200
  const xstep = xmax / numberOfIncrements

  const { monthlyBurnRate } = useData()

  const setLivingCostPercentage = React.useCallback(
    (value: number) => {
      if (value <= 0) {
        setLivingCostPercentageAdjustment(0.01)
      } else if (value > 2) {
        setLivingCostPercentageAdjustment(2)
      } else {
        setLivingCostPercentageAdjustment(value)
      }
    },
    [setLivingCostPercentageAdjustment]
  )

  // React.useEffect(() => {
  //   if (monthlyBurnRate > maxCostOfLiving && !expenseSliderDragging) {
  //     setLivingCostPercentage(maxCostOfLivingPercentageAdjustment)
  //   }
  // }, [
  //   maxCostOfLiving,
  //   livingCostPercentageAdjustment,
  //   setLivingCostPercentage,
  //   monthlyBurnRate,
  //   maxCostOfLivingPercentageAdjustment,
  //   expenseSliderDragging,
  // ])

  const onLivingCostPercentageChange = (e: { x: number; y: number }) => {
    setLivingCostPercentage(e.x)
  }

  const coastMode = mysteryMode === 'buildCushion' && cushionSubMode === 'extendSavings'
  const colorForContribution: ColorType =
    livingCostPercentageAdjustment >= maxPercentageRate && !coastMode
      ? 'mehred'
      : livingCostPercentageAdjustment < percentageOfExpensesThatAreFixed
      ? 'mehred'
      : livingCostPercentageAdjustment <
        percentageOfExpensesThatAreFixed + percentageOfExpensesThatAreVariable
      ? 'orange'
      : 'texttransparent'

  return (
    <SliderPanelContainer className="mt-2 mb-3">
      <div className="w-100 d-flex align-items-center flex-column justify-content-between my-1">
        <StyledHr color="nearlyclear" style={{ opacity: 0.5 }} weight={1} />
        <div className="d-flex flex-row align-items-center w-100 justify-content-center">
          <MCOLBadge orientation="horizontal" color={colorForContribution} extraText="Adjustment" />
          <SplurvSnippet
            color={colorForContribution}
            logoSize={28}
            popupContent={
              <>
                {monthlyBurnRate < totalFixed ? (
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <TextSpan color="texttransparent" size="xs">
                      Welcome to the MCOL Adjustment slider.
                    </TextSpan>
                    <TextSpan color="textsupertransparent" size="xs">
                      Use this slider to temporarily adjust your current overall cost of living in
                      order to free up hypothetical extra money each month to put towards savings.
                    </TextSpan>

                    <TextSpan color="textsupertransparent" size="xs">
                      If you like the adjusted increased savings contribution and how it brings your
                      goal closer, consider reducing your variable spend each month or lowering some
                      of your fixed expenses.
                    </TextSpan>
                    <StyledHr className="my-2" color="mehred" />
                    <TextSpan color="mehred" size="xxs">
                      You are currently setting your Monthly Cost of Living to be lower than the
                      total of your fixed bills. Keep in mind that to achieve this low of an MCOL,
                      you would need to reduce your variable spending to 0 and cut out some of your
                      fixed expenses.
                    </TextSpan>
                  </div>
                ) : monthlyBurnRate < bareBonesMonthlyBurnRate ? (
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <TextSpan color="texttransparent" size="xs">
                      Welcome to the MCOL Adjustment slider.
                    </TextSpan>
                    <TextSpan color="textsupertransparent" size="xs">
                      Use this slider to temporarily adjust your current overall cost of living in
                      order to free up hypothetical extra money each month to put towards savings.
                    </TextSpan>

                    <TextSpan color="textsupertransparent" size="xs">
                      If you like the adjusted increased savings contribution and how it brings your
                      goal closer, consider reducing your variable spend each month or lowering some
                      of your fixed expenses.
                    </TextSpan>
                    <StyledHr className="my-2" color="orange" />
                    <TextSpan color="orange" size="xs">
                      You are currently setting your Monthly Cost of Living to be lower than your
                      current variable spending. This is great! Good luck with the journey to
                      reducing your variable spend.
                    </TextSpan>

                    <TextSpan color="orange" size="xs" className="d-inline">
                      <Icon className="fas fa-fire-alt mr-1" size="md" color="orange" />
                      To get some ideas on where you can reduce your variable spending, check out
                      the <u>Variable Spending</u> section of the settings panel
                    </TextSpan>
                  </div>
                ) : (
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <TextSpan color="texttransparent" size="xs">
                      Welcome to the MCOL Adjustment slider.
                    </TextSpan>
                    <TextSpan color="textsupertransparent" size="xs">
                      Use this slider to temporarily adjust your current overall cost of living in
                      order to free up hypothetical extra money each month to put towards savings.
                    </TextSpan>

                    <TextSpan color="textsupertransparent" size="xs">
                      If you like the adjusted increased savings contribution and how it brings your
                      goal closer, consider reducing your variable spend each month or lowering some
                      of your fixed expenses.
                    </TextSpan>

                    <StyledHr className="my-2" color="textsupertransparent" />

                    <TextSpan color="texttransparent" size="xs" className="d-inline">
                      <Icon className="fas fa-fire-alt mr-1" size="md" color="texttransparent" />
                      To get some ideas on where you can reduce your variable spending, check out
                      the <u>Variable Spending</u> section of the settings panel
                    </TextSpan>
                  </div>
                )}
              </>
            }
            title="Cost of Living Adjustment"
            style={
              {
                // opacity:
                //   monthlyBurnRate < totalFixed ||
                //   livingCostPercentageAdjustment <
                //     percentageOfExpensesThatAreFixed + percentageOfExpensesThatAreVariable
                //     ? 1
                //     : 0,
                // transition: 'all ease-in-out .3s',
              }
            }
          />
        </div>
        <TextSpan className="mr-1 my-0" size={'xs'} weight={800} color={colorForContribution}>
          <NumberCounter value={Math.floor(livingCostPercentageAdjustment * 100)} />%
          <TextSpan className="ml-1 my-0" size="xxs" weight={200} color={'texttransparent'}>
            {' '}
            {'of current expenses'}
            <StyledVr size="xxxs" color="textsupertransparent" weight={1} className="mx-1" />
            <TextSpan className="ml-1 my-0" size={'xxs'} weight={800} color={colorForContribution}>
              <CurrencyCounter value={monthlyBurnRate} />
            </TextSpan>
            <TextSpan className="ml-1" size="xxs" color={'texttransparent'}>
              {' '}
              {'/mon'}
            </TextSpan>
          </TextSpan>
        </TextSpan>{' '}
      </div>
      <div className="w-100 d-flex align-items-center justify-content-between flex-row my-3 position-relative">
        <div
          className="d-flex align-items-center justify-content-center flex-column py-2 px-4"
          onClick={() => {
            const newAdjustmentPercentage = livingCostPercentageAdjustment - xstep
            setLivingCostPercentage(newAdjustmentPercentage)
          }}>
          <Icon className="fal fa-minus-circle" size="md" color="texttransparent" />
        </div>

        <div
          ref={sliderContainerRef}
          style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 'calc(100% - 6rem)',
          }}>
          <Slider
            x={livingCostPercentageAdjustment}
            onChange={onLivingCostPercentageChange}
            // onDragStart={() => {
            //   setExpenseSliderDragging(true)
            // }}
            // onDragEnd={() => {
            //   setExpenseSliderDragging(false)
            // }}
            xmin={xmin}
            xmax={xmax}
            xstep={xstep}
            axis="x"
            styles={{
              track: {
                transition: 'all ease-in-out .3s',
                backgroundColor: colorPalletes[activeTheme].nearlyclear,
                width: 'calc(100%)',
                height: 10,
              },
              active: {
                transition: 'background-color ease-in-out .3s',
                backgroundColor: colorPalletes[activeTheme].nearlyclear,
              },

              thumb: {
                width: 28,
                height: 28,
                opacity: 1,
                transition: 'all ease-in-out .3s',
                zIndex: 20,
                boxShadow: 'rgb(0 0 0 / 30%) 0px 0px 4px 2px',
                // backgroundColor: 'transparent',
                borderColor: colorPalletes[activeTheme][colorForContribution],
                borderWidth: '2px',
                borderStyle: 'solid',
                backgroundColor: color(colorPalletes[activeTheme][colorForContribution])
                  .alpha(0.5)
                  .toString(),
              },
            }}
          />

          <FixedExpensesMarkerOverlay
            shouldRoundBorders={fixedExpenseMarkerWidth >= sliderWidth}
            width={fixedExpenseMarkerWidth}
            color="mehred"
            content=""
          />
          <FixedExpensesMarkerTick
            shouldRoundBorders={fixedExpenseMarkerWidth >= sliderWidth}
            width={fixedExpenseMarkerWidth}
            onClick={() => {
              setLivingCostPercentage(percentageOfExpensesThatAreFixed)
            }}
            color="mehred"
            content={numberToCurrency(totalFixed)}
          />
          <FlexibleSpendMarkerOverlay
            shouldRoundBorders={false}
            width={flexibleExpenseMarkerWidth}
            offsetLeft={fixedExpenseMarkerWidth}
            color="orange"
            content=""
          />
          <FlexibleSpendMarkerTick
            shouldRoundBorders={false}
            width={fixedExpenseMarkerWidth + flexibleExpenseMarkerWidth}
            content={numberToCurrency(flexibleSpendPerMonth)}
            color="orange"
          />
        </div>

        <div
          className="d-flex align-items-center justify-content-center flex-column py-2 px-4"
          onClick={() => {
            const newAdjustmentPercentage = livingCostPercentageAdjustment + xstep
            setLivingCostPercentage(newAdjustmentPercentage)
          }}>
          <Icon className="fal fa-plus-circle" size="md" color="texttransparent" />
        </div>
        <div
          className="d-flex flex-row align-items-center"
          onClick={() => {
            setLivingCostPercentage(1)
          }}
          style={{
            position: 'absolute',
            right: '1rem',
            bottom: '-2rem',
            opacity: livingCostPercentageAdjustment === 1 ? 0 : 1,
            transition: 'opacity ease-in-out .3s',
          }}>
          <TextSpan size="xxs" weight={200} className="my-0" color="textsupertransparent">
            <u>Reset</u>
          </TextSpan>
        </div>
      </div>
    </SliderPanelContainer>
  )
}
