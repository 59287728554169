import { calculateMinimumMonthlyPayment } from 'Pages/DashboardPage/helpers'
import { useData } from 'Providers/APIDataProvider'
import { useFinancialData } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import { cleanCurrency } from 'Utilities/currencyFormater'
import {
  getMonthlyBills,
  getMonthlyIncome,
  getMonthlySubscriptions,
} from 'Utilities/netWorth/netWorth'
import React from 'react'
import { RELAXED_RECOMMENDED_AGGRESIVE_SPREAD } from '../Pages/TotalCostOfLivingComplete/TotalCostOfLivingComplete'
import { MAX_YEARS_FOR_REPAYING_DEBT } from './SalarySavingsSlider'

export const useSetupSlider = () => {
  const sliderContainerRef = React.useRef<HTMLDivElement>(null)
  // const thumbComponentRef = React.useRef<HTMLDivElement>(null)
  const [thumbComponentRef, setThumbComponentRef] = React.useState<HTMLDivElement | null>(null)

  const [sliderWidth, setSliderWidth] = React.useState(0)
  React.useEffect(() => {
    if (sliderContainerRef && sliderContainerRef.current) {
      // let divNode: HTMLDivElement | undefined | null
      // const titleNode = window.document.createElement('span')
      // titleNode.id = 'slider-pop-title-node'
      // titleNode.style.fontFamily = myTheme.fonts.family.secondary
      // titleNode.style.fontSize = myTheme.fonts.sizes.xxxs
      // const bodyNode = window.document.createElement('span')
      // bodyNode.style.fontFamily = myTheme.fonts.family.secondary
      // bodyNode.style.fontSize = myTheme.fonts.sizes.xxxs
      // bodyNode.style.color = colorPalletes[activeTheme]['textsupertransparent']
      // bodyNode.id = 'slider-pop-body-node'
      // if (!window.document.getElementById('slider-pop-div-node')) {
      //   divNode = window.document.createElement('div')
      //   divNode.id = 'slider-pop-div-node'
      //   divNode.style.position = 'absolute'
      //   divNode.style.top = '-30px'
      //   // divNode.style.height = '40px'
      //   divNode.style.minWidth = '200px'
      //   divNode.style.padding = '.25rem .5rem'
      //   divNode.style.border = 'solid 1px white'
      //   divNode.style.transition = 'all ease-in-out .3s'
      //   divNode.style.display = 'flex'
      //   divNode.style.flexDirection = 'column'
      //   divNode.appendChild(titleNode)
      //   divNode.appendChild(bodyNode)

      //   // divNode.innerHTML = 'test'
      // }
      // const divNode.
      const width = sliderContainerRef.current?.clientWidth || 0

      const styles = window.getComputedStyle(sliderContainerRef.current)
      const paddingWidth = parseFloat(styles.paddingLeft) + parseFloat(styles.paddingRight)
      const marginWidth = parseFloat(styles.marginLeft) + parseFloat(styles.marginRight)
      const paddingAndMargin = paddingWidth + marginWidth
      setSliderWidth(width - paddingAndMargin)

      // HACK: This nonsense is to force the zIndex of the ACTUAL thumb div component (the styles we set below are for the thumbs child)
      const children = sliderContainerRef.current?.children
      if (children) {
        for (let i = 0; i < children.length; i++) {
          const child = children[i] as HTMLElement

          if (/-Slider/.test(child.className)) {
            const childChildren = child.children
            if (childChildren) {
              const thumbComponent = childChildren[1] as HTMLDivElement
              thumbComponent.style.zIndex = '100'
              thumbComponent.style.padding = '20px'
              // thumbComponent.style.display = 'flex'
              // thumbComponent.style.flexDirection = 'column'
              // thumbComponent.style.alignItems = 'center'

              if (!thumbComponentRef) {
                // thumbComponent.appendChild(divNode!)
                setThumbComponentRef(thumbComponent)
              }
              // thumbComponent.style.position = 'relative'
            }
          }
        }
      }
    }
  }, [sliderContainerRef, thumbComponentRef])

  return { sliderContainerRef, sliderWidth, thumbComponentRef }
}

export const useGetSliderWidths = ({
  sliderContainerRef,
  sliderWidth,
}: {
  sliderContainerRef: React.RefObject<HTMLDivElement>
  sliderWidth: number
}) => {
  const { livingCostPercentageAdjustment, debtAmount, debtInterest } = useMysteryDay()
  const { flexibleSpendPerMonth } = useFinancialData()
  const { monthlyBurnRate, streams } = useData()

  const debtAmountClean = cleanCurrency(debtAmount)
  const minimumPaymentNeeded = calculateMinimumMonthlyPayment(
    debtAmountClean,
    debtInterest / 100,
    MAX_YEARS_FOR_REPAYING_DEBT
  )

  const monthlyIncome = getMonthlyIncome(streams)

  const monthlyFixedExpenses = Math.abs(getMonthlyBills(streams) + getMonthlySubscriptions(streams))
  const monthlyVariableExpenses = flexibleSpendPerMonth

  const originalMonthlyBurnRate = monthlyBurnRate / livingCostPercentageAdjustment
  const baseRecommendedAmount = monthlyIncome - Math.abs(originalMonthlyBurnRate)

  const relaxedRecommendedAmount =
    baseRecommendedAmount * (1 - RELAXED_RECOMMENDED_AGGRESIVE_SPREAD)

  const aggressiveRecommendedAmount =
    baseRecommendedAmount * (1 + RELAXED_RECOMMENDED_AGGRESIVE_SPREAD)

  const percentageOfIncomeThatIsRelaxedSavings = relaxedRecommendedAmount / monthlyIncome
  const percentageOfSavingsThatIsRecommendedSavings = baseRecommendedAmount / monthlyIncome
  const percentageOfIncomeThatIsAggressiveSavings = aggressiveRecommendedAmount / monthlyIncome

  const relaxedSavingsWidth = percentageOfIncomeThatIsRelaxedSavings * sliderWidth
  const recommendedSavingsWidth = percentageOfSavingsThatIsRecommendedSavings * sliderWidth
  const aggressiveSavingsWidth = percentageOfIncomeThatIsAggressiveSavings * sliderWidth

  const maxSavingsMarkerOffset = ((monthlyIncome - monthlyBurnRate) / monthlyIncome) * sliderWidth

  const maxSavingsPreVariableExpensesOffset =
    ((monthlyIncome - monthlyFixedExpenses) / monthlyIncome) * sliderWidth

  const maxSavingsOverlayWidth = (monthlyBurnRate / monthlyIncome) * sliderWidth

  const minSavingsOffset = (minimumPaymentNeeded / monthlyIncome) * sliderWidth

  return {
    relaxedSavingsWidth,
    recommendedSavingsWidth,
    aggressiveSavingsWidth,

    maxSavingsMarkerOffset:
      maxSavingsMarkerOffset > sliderWidth
        ? sliderWidth
        : maxSavingsMarkerOffset < 0
        ? 0
        : maxSavingsMarkerOffset,

    maxSavingsOverlayWidth:
      maxSavingsOverlayWidth > sliderWidth ? sliderWidth : maxSavingsOverlayWidth,

    maxSavings: monthlyIncome - monthlyBurnRate,
    maxSavingsJustFixed: monthlyIncome - monthlyVariableExpenses,
    maxSavingsPreVariableExpensesOffset,
    minSavingsOffset,
  }
}
