import {
  Chart,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  TimeSeriesScale,
} from 'chart.js'
import React from 'react'
import { Scatter } from 'react-chartjs-2'
import { ChartContainer } from './components'
import { dayRangeLookup, dayRangeSizeLookup, ViewRangeModes } from './helpers'

import 'chartjs-adapter-date-fns'
import color from 'color'
import { enUS } from 'date-fns/locale'
import { useUIState } from 'Providers/UIStateProvider'
import { colorPalletes, ColorType, myTheme } from 'theme'
import { ActiveMapType } from './Chart'

Chart.register(TimeScale)
Chart.register(TimeSeriesScale)
Chart.register(LinearScale)
Chart.register(PointElement)
Chart.register(LineController)
Chart.register(LineElement)
Chart.register(Legend)
Chart.register(Filler)

export const GRAPH_COLORS: ColorType[] = [
  'cashgreen',
  'megapurple',
  'mehred',
  'softblue',
  'orange',
  'second',
]

export const ProjectionGraph: React.FC<{
  viewRange: ViewRangeModes
  activeMap: ActiveMapType
}> = ({ activeMap, viewRange }) => {
  const { activeTheme } = useUIState()
  const allDays = dayRangeLookup[viewRange]
  return (
    <ChartContainer>
      <Scatter
        redraw={false}
        options={{
          animations: {
            tension: {
              duration: 300,
              easing: 'linear',
              from: 1,
              to: 0,
              loop: true,
            },
          },
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            legend: {
              display: false,
              labels: {
                color: colorPalletes[activeTheme]['softblue'],
                font: {
                  family: 'Rubik',
                },
              },
            },
          },
          scales: {
            x: {
              adapters: {
                date: {
                  locale: enUS,
                },
              },
              type: 'time',
              time: {
                unit: 'day',
                displayFormats: {
                  day: 'MMM dd',
                },
              },
              ticks: {
                source: 'data',

                autoSkip: true,
                autoSkipPadding: 7,
              },
              grid: {
                display: true,
              },
            },
            y: {
              ticks: {
                // Include a dollar sign in the ticks
                callback: function (value: any, index: any, values: any) {
                  return '$' + value
                },
              },
              beginAtZero: true,
              grid: {
                display: true,
              },
            },
          },
        }}
        data={{
          labels: allDays,

          datasets: [
            {
              label: activeMap.name,
              borderWidth: dayRangeSizeLookup['6months'],

              // This is the radius of each data point
              pointRadius: 0.25,

              borderColor: colorPalletes[activeTheme]['softblue'],
              backgroundColor: colorPalletes[activeTheme]['softblue'],
              //  This is the border color of each dot

              // This is the background color of each dot
              // pointBackgroundColor: colorPalletes[activeTheme].activeMap.color],
              // pointBorderColor: colorPalletes[activeTheme].activeMap.color],

              // // This is the background color of each dot
              pointBackgroundColor: (datum: any) =>
                (datum as any).raw < 0
                  ? myTheme['colors']['mehred']
                  : myTheme['colors']['logoPrimary'],
              pointBorderColor: (datum: any) =>
                (datum as any).raw < 0
                  ? myTheme['colors']['mehred']
                  : myTheme['colors']['logoPrimary'],

              type: 'scatter',
              showLine: true,

              stepped: true,

              segment: {
                borderColor: (datum: any) => {
                  if (datum?.p0?.$context?.raw < 0 || datum?.p1?.$context?.raw < 0) {
                    return myTheme['colors']['mehred']
                  } else {
                    return myTheme['colors']['logoPrimary']
                  }
                },
              } as any,
              fill: {
                target: 'origin',
                above: color(myTheme['colors']['logoPrimary']).alpha(0.3).toString(),
                below: color(myTheme['colors']['mehred']).alpha(0.3).toString(),
              } as any,

              data: Object.values(activeMap.map)
                .slice(0, allDays.length)
                .map((day) => day),
            },
          ],
        }}
      />
    </ChartContainer>
  )
}
