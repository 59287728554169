import color from 'color'
import styled from 'styled-components/macro'
import { ColorType } from 'theme'

interface ButtonProps {
  bgColor: ColorType
  appearance: 'primary' | 'secondary'
  width: 'full' | 'fit'
  textSize: 'small' | 'large'
  pressed: boolean
  active?: boolean
}
export const Button = styled.button<ButtonProps>`
  /* transition: all ease-in-out 0.3s; */
  border-radius: ${(props) => props.theme.buttons.borderRadius};

  padding: 0.375rem 0.625rem;
  min-height: 3rem;
  height: fit-content;
  width: ${(props) => (props.width === 'full' ? '100%' : 'fit-content')};

  /* the goofy and bold font */
  font-family: ${(props) => props.theme.fonts.family.primary};
  letter-spacing: 0.6px;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  position: relative;

  i.left {
    margin-right: 0.25rem;
  }
  i.right {
    margin-left: 0.5rem;
  }

  /* primary buttons get solid color bg, secondary buttons have transparent bg */
  background-color: ${(props) =>
    props.appearance === 'primary'
      ? props.disabled
        ? color(props.theme.colors.texttransparent).alpha(0.1).toString()
        : props.theme.colors[props.bgColor]
      : 'transparent'};

  border: ${(props) =>
    props.appearance === 'primary'
      ? `solid 1px transparent`
      : `solid 1px ${color(
          props.theme.colors[props.active ? props.bgColor : 'textsupertransparent']
        )
          // .darken(0.5)
          .toString()}`};

  span,
  i {
    font-size: ${(props) =>
      props.textSize === 'small' ? props.theme.fonts.sizes.sm : props.theme.fonts.sizes.md};
    transition: all ease-in-out 0.3s;
    color: ${(props) =>
      props.appearance === 'primary'
        ? props.disabled
          ? props.theme.colors.texttransparent
          : color(props.theme.colors[props.bgColor]).darken(0.7).toString()
        : props.active
        ? props.theme.colors[props.bgColor]
        : props.theme.colors['textsupertransparent']};
  }

  &.pressed {
    border: solid 1px ${(props) => props.theme.colors[props.bgColor]};
    transition: all ease-in-out 0.3s;
    box-shadow: rgb(0 0 0 / 100%) 0px 0px 6px 2px;
  }
`
