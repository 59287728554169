import { AccountMaskAndLogo } from 'Components/AccountMaskAndLogo'
import { Icon, TextSpan } from 'Components/DesignSystem/Typography'
import { getItemIdForAccountId } from 'Pages/CreditCardPage/dataHelpers'
import {
  ManualAccount,
  useFinancialData,
} from 'Providers/FinancialDataProvider/FinancialDataProvider'
import {
  getAccountTypeFromTypeSubtypeTuple,
  getAmountForMixedAccount,
  getColorFromAccountType,
} from 'Providers/FinancialDataProvider/helpers'
import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import { MysteryDayType } from 'Providers/MysteryDayProvider/MysteryDayProvider.interfaces'
import { numberToCurrency } from 'Utilities/currencyFormater'
import { AccountBase } from 'plaid'
import React from 'react'

interface AccountTrackingAccountPickerProps {
  mode: MysteryDayType
  type: 'live' | 'manual'
}
export const AccountTrackingAccountPicker: React.FC<AccountTrackingAccountPickerProps> = ({
  mode,
  type,
}) => {
  const { accountsData, flatAccounts, flatManualAccounts } = useFinancialData()

  const {
    linkedAccount,
    setLinkedAccount,

    linkedAccounts,
    addLinkedAccount,
    // removeLinkedAccount,
    // addTransactionsToLinkedAccount,
  } = useMysteryDay()

  // const transactionsForAccount = useAsyncFetch<
  //   { data: HybridRegularAndInvestmentTransaction[] },
  //   {
  //     itemId: string
  //     accountId: string
  //     monthsOfTransactions: number
  //     accountType: 'regular' | 'investment'
  //   },
  //   {}
  // >({
  //   method: 'GET',
  //   route: 'transactions_for_tracked_account',
  // })

  // NOTE: We need better plumbed support throughout the app for supporting dashboards where multiple savings accounts are being tracked
  //  TODO:
  const isMultipleAccountsCanBeSelected = false

  const accounts = accountsData?.data?.accounts || []

  const accountIsCorrectType = (a: AccountBase | ManualAccount) => {
    if (type === 'live') {
      if (mode === 'getOutOfDebt') {
        const accountType = getAccountTypeFromTypeSubtypeTuple([
          (a as AccountBase).type,
          (a as AccountBase).subtype,
        ])
        return accountType === 'Credit Card' || accountType === 'Debt'
      } else {
        const accountType = getAccountTypeFromTypeSubtypeTuple([
          (a as AccountBase).type,
          (a as AccountBase).subtype,
        ])
        return accountType === 'Savings' || accountType === 'Cash'
      }
    }
    if (type === 'manual') {
      if (mode === 'getOutOfDebt') {
        const accountType = (a as ManualAccount).type
        return accountType === 'Credit Card' || accountType === 'Debt'
      } else {
        const accountType = (a as ManualAccount).type
        return accountType === 'Savings' || accountType === 'Cash'
      }
    }
  }

  // const accountsToShow: (AccountBase | ManualAccount)[] =
  //   type === 'live'
  //     ? flatAccounts.filter((fa) => {
  //         if (mode === 'getOutOfDebt') {
  //           const accountType = getAccountTypeFromTypeSubtypeTuple([fa.type, fa.subtype])
  //           return accountType === 'Credit Card' || accountType === 'Debt'
  //         } else {
  //           const accountType = getAccountTypeFromTypeSubtypeTuple([fa.type, fa.subtype])
  //           return accountType === 'Savings' || accountType === 'Cash'
  //         }
  //       })
  //     : flatManualAccounts.filter((fma) => {
  //         if (mode === 'getOutOfDebt') {
  //           const accountType = fma.type
  //           return accountType === 'Credit Card' || accountType === 'Debt'
  //         } else {
  //           const accountType = fma.type
  //           return accountType === 'Savings' || accountType === 'Cash'
  //         }
  //       })

  const accountsToShow: (AccountBase | ManualAccount)[] =
    type === 'live' ? flatAccounts : flatManualAccounts

  // const loading = transactionsForAccount.res.status === 'loading'
  // const transactions = transactionsForAccount.res.data?.data || []

  // React.useEffect(() => {
  //   if (loading) return
  //   if (!transactions.length) return
  //   if (!mostRecentlySelectedAccountAndItem) return

  //   // addTransactionsToLinkedAccount(
  //   //   {
  //   //     accountId: mostRecentlySelectedAccountAndItem?.accountId,
  //   //     itemId: mostRecentlySelectedAccountAndItem?.itemId,
  //   //   },
  //   //   transactions
  //   // )
  //   // HACK: Revisit this - too scared right now to add deps for fear of infinite
  //   // loop at a time when the thing works
  //   // eslint-disable-next-line
  // }, [transactions, mostRecentlySelectedAccountAndItem, loading])

  return (
    <div className="d-flex flex-column align-items-start w-100 px-2">
      <TextSpan color="texttransparent" weight={300} size="sm" className="my-2">
        Select a{' '}
        <TextSpan className="mx-1 my-0" weight={700}>
          {type}
        </TextSpan>{' '}
        account to connect
      </TextSpan>

      <div
        className="d-flex flex-column px-1 py-2 w-100"
        style={{
          minHeight: '80px',
          maxHeight: `${3 * 48 - 16}px`,
          height: `${accountsToShow.length * 48 - 16}px`,
          // height: '120px',
          overflow: 'auto',

          boxShadow: 'inset hsl(243 19% 18% / .5) 0 0px 7px 5px',
          border: 'solid 1px #6362752e',
        }}>
        <div className="d-flex flex-column px-2">
          {/* {accountsToShow.length === 0 && accounts.length !== 0 && type === 'live' ? (
            <TextSpan color="mehred" size="xxs">
              You have {accounts.length} connected bank accounts but none of them are debt or a
              credit card. Connect a debt / credit card account to track it as your main goal,
              otherwise you will see your connected accounts later in onboarding.
            </TextSpan>
          ) : null} */}
          {accountsToShow.map((account) => {
            const accountType = (account as AccountBase)?.subtype
              ? getAccountTypeFromTypeSubtypeTuple([
                  (account as AccountBase).type,
                  (account as AccountBase).subtype,
                ])
              : (account as ManualAccount).type

            const itemId =
              type === 'manual'
                ? ''
                : getItemIdForAccountId(accounts, account.account_id) || 'no-item-id-found'
            const accountId = account.account_id

            const color = getColorFromAccountType(accountType)
            const accountChosen = isMultipleAccountsCanBeSelected
              ? linkedAccounts.some((la) => la && la.accountId === account.account_id)
              : account.account_id === linkedAccount?.accountId
            return (
              <div
                style={{
                  opacity: accountIsCorrectType(account) ? 1 : 0.3,
                }}
                onClick={() => {
                  if (!accountIsCorrectType(account)) {
                    if (mode === 'getOutOfDebt') {
                      alert(
                        'This is not a credit or debt account and thus cannot be used to track live progress for debt payoff. Either connect a bank that has the debt account you are trying to pay off, or manually enter your debt details.'
                      )
                      return
                    }
                    alert(
                      'This is not a savings or cash account and thus cannot be used to track live progress. Either connect a bank that has the savings account you are saving in, or manually enter your savings details. '
                    )
                    return
                  }
                  if (isMultipleAccountsCanBeSelected) {
                    if (accountChosen) {
                      setLinkedAccount(null)
                    } else {
                      addLinkedAccount({
                        accountId,
                        itemId,
                        type,
                      })
                    }
                  } else {
                    setLinkedAccount({
                      accountId,
                      itemId,
                      type,
                    })
                  }
                }}
                key={account.account_id}
                className="d-flex my-2 w-100 align-items-center justify-content-between pr-2">
                <div className="d-flex flex-row align-items-center justify-content-start">
                  <Icon
                    size="sm"
                    color={color}
                    className={`${accountChosen ? 'fas' : 'far'} fa-${
                      isMultipleAccountsCanBeSelected ? 'square' : 'circle'
                    } mr-3`}
                  />
                  <AccountMaskAndLogo accountId={account.account_id} orientation="horizontal" />
                  <TextSpan className="mx-2" size="xs">
                    {account.name}
                  </TextSpan>
                </div>

                <TextSpan weight={600} color={color} size="sm">
                  {numberToCurrency(getAmountForMixedAccount(account))}
                </TextSpan>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
