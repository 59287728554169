import { Pill } from 'Components/DesignSystem/Pill/Pill'
import React from 'react'
import { ColorType } from 'theme'

interface Props {
  category?: string
  className?: string
  bgColor?: ColorType
}

export const TransactionCategoryBadge: React.FC<Props> = ({
  category,
  className,
  bgColor = 'text',
}) => {
  if (!category) return null
  return (
    <Pill pillClassName={className} bgColor={bgColor} iconClassName="fas fa-tags" text={category} />
  )
}
