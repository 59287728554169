import { SlidingPage } from 'design-system/layout/SlidingPage/SlidingPage'
import React from 'react'
import { useHistory, useLocation } from 'react-router'
import { ThemeProvider } from 'styled-components'
import { colorPalletes, myTheme } from 'theme'
import { useUIState } from '../../Providers/UIStateProvider'
import { SplurvOptions } from './SplurvOptions'
import { StripeCheckoutContainer } from './StripeCheckoutContainer'

export const SubscriptionPage: React.FC = () => {
  const { subscriptionPageOpen, setSettingsPageOpen, setActiveTheme } = useUIState()

  const location = useLocation()
  const { push } = useHistory()

  const [stripeContentOpen, setStripeContentOpen] = React.useState<false | string>(false)

  const secondaryButton = {
    title: stripeContentOpen ? 'back to options' : 'close',
    icon: stripeContentOpen ? ' fas fa-arrow-left' : 'fas fa-times',
    onClick: () => {
      if (stripeContentOpen) {
        setStripeContentOpen(false)
        setActiveTheme('newDark')
      } else {
        push('/mystery')
        setSettingsPageOpen(true)
        if (subscriptionPageOpen) {
          subscriptionPageOpen.cb()
        }
      }
    },
  }

  return (
    <SlidingPage
      open={location.pathname === '/checkout'}
      content={
        stripeContentOpen ? (
          <ThemeProvider theme={{ ...myTheme, colors: colorPalletes['newLight'] }}>
            <StripeCheckoutContainer paymentKey={stripeContentOpen} />
          </ThemeProvider>
        ) : (
          <SplurvOptions setStripeContentOpen={setStripeContentOpen} />
        )
      }
      primaryButton={undefined}
      secondaryButton={secondaryButton}
      deleteButton={undefined}
    />
  )
}
