import { FIXED_ICON, VARIABLE_ICON } from 'Utilities/navigationTabUtilities'
import React from 'react'
import { ContainerDiv } from '../../../Components/DesignSystem/Layout/Containers/Containers'
import { Icon, StyledHr, TextSlim, TextSpan } from '../../../Components/DesignSystem/Typography'
import { BackButton } from '../MysteryDayBackButton'
import { ForwardButton } from '../MysteryDayForwardButton'
import { SubPageProps } from '../MysteryPage'

export const TotalCostOfLivingIntro: React.FC<SubPageProps> = () => {
  return (
    <ContainerDiv className="justify-content-between align-items-center py-3">
      <ContainerDiv className="justify-content-start align-items-center">
        <TextSlim size="lg" weight={200} color="texttransparent">
          MCOL Introduction
        </TextSlim>

        <div className="w-100 align-items-start d-flex flex-column justify-content-start">
          <TextSlim size="xs" className="px-4" color="textsupertransparent">
            Welcome to your <b>Monthly Cost of Living</b>. Your <b>MCOL</b> has a pretty direct
            impact - more so than your income - on your financial independence journey. There are
            more knobs to adjust your MCOL than anywhere else.
          </TextSlim>

          <div className="w-100 align-items-center d-flex flex-column my-2 px-2">
            <div className="w-100 align-items-center justify-content-between d-flex flex-row my-2 px-2">
              <TextSpan className="my-0" weight={700} size="md" color="mehred">
                <TextSpan className="ml-2" size="md" color="mehred">
                  <Icon className={`${FIXED_ICON} mr-2`} color="mehred" size="md" />
                  Fixed
                </TextSpan>
              </TextSpan>
              <Icon className="fas fa-plus" color="textsupertransparent" size="lg" />
              <TextSpan className="my-0" weight={700} size="md" color="mehred">
                <TextSpan className="ml-2" size="md" color="mehred">
                  <Icon className={`${VARIABLE_ICON} mr-2`} color="mehred" size="md" />
                  Variable
                </TextSpan>
              </TextSpan>
              <Icon className="fas fa-equals" size="lg" color="textsupertransparent" />
            </div>
            <StyledHr className="mb-1 mt-0" weight={1} color="textsupertransparent" />
            <TextSlim className="my-0 align-self-center" weight={700} size="lg" color="mehred">
              <Icon className="fas fa-flame mr-2" color="mehred" size="md" />
              MCOL
            </TextSlim>
          </div>
          <TextSlim size="sm" className="px-2" color="texttransparent">
            We'll start organizing your Fixed expenses and then will move on to your Variable
            expense analysis.
          </TextSlim>
        </div>
      </ContainerDiv>

      <div className="d-flex justify-content-between align-items-center w-100">
        <BackButton />
        <ForwardButton title={'Next'} />
      </div>
    </ContainerDiv>
  )
}
