import { VerticalStackedMoneyDisplay } from 'Pages/MysteryPage/CashFlowRecap/CashFlowRecap.components'
import { DAYS_OUT, useData } from 'Providers/APIDataProvider'
import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import { useUserCustomSettings } from 'Providers/UserCustomSettingsProvider/UserCustomSettingsProvider'
import { getToday } from 'Utilities/dateUtilities/dateUtilities'
import { streamsToEvents } from 'Utilities/streamsToEvents/streamsToEvents'
import { AccountBase } from 'plaid'
import React from 'react'
import { colorPalletes } from 'theme'
import { BankLogo } from '../../Components/BankLogo'
import { DataOrFeatureMissing } from '../../Components/DataOrFeatureMissing/DataOrFeatureMissing'
import { Icon, StyledVr, TextPopupSmall, TextSpan } from '../../Components/DesignSystem/Typography'
import { useFinancialData } from '../../Providers/FinancialDataProvider/FinancialDataProvider'
import {
  getCashAccounts,
  getCreditCardAccounts,
} from '../../Providers/FinancialDataProvider/helpers'
import { useUIState } from '../../Providers/UIStateProvider'
import { getTransactionSpendingTotal } from '../../Utilities/creditCardSpending/creditCardSpending'
import { bankLogoForBank } from '../CreditCardPage/CardTetherSetupPage/CardTetherSetupPage'
import { getBankItemFromAccount } from '../CreditCardPage/dataHelpers'
import { SpendingAccountRow } from './SpendingAccountRow'
import { LoadingButton } from './SpendingPage.components'

interface MonthlyOverviewProps {
  thisMonthSpending: number
  lastMonthSpending: number
  activelySelectedAccount?: AccountBase
  bankLogoForActivelySelectedBank?: string | null
}
const MonthlyOverviewRow: React.FC<MonthlyOverviewProps> = ({
  thisMonthSpending,
  lastMonthSpending,
  activelySelectedAccount,
  bankLogoForActivelySelectedBank,
}) => {
  const { activeTheme } = useUIState()
  return (
    <div
      className="d-flex align-items-start w-100 my-1"
      style={{ borderBottom: `solid 1px ${colorPalletes[activeTheme].nearlyclear}` }}>
      <div className="d-flex align-items-start flex-column w-100">
        <div className="d-flex w-100 align-items-center justify-content-between">
          <VerticalStackedMoneyDisplay
            title="This Month"
            amount={thisMonthSpending}
            color="second"
          />

          <StyledVr />
          <VerticalStackedMoneyDisplay
            title="Last Month"
            amount={lastMonthSpending}
            color="second"
          />

          {activelySelectedAccount ? (
            <div className="d-flex align-items">
              <BankLogo logo={bankLogoForActivelySelectedBank} size={32} />
              <TextSpan
                size="xxs"
                className="ml-2 mr-1 my-0"
                color="texttransparent"
                style={{ fontFamily: 'monospace' }}
                weight={500}>
                {activelySelectedAccount?.mask}
              </TextSpan>
            </div>
          ) : (
            <TextSpan weight={700} size="xxs">
              all accounts
            </TextSpan>
          )}
        </div>
      </div>
    </div>
  )
}

interface Props {
  activelySelectedAccountId: string
  setActivelySelectedAccountId: (id: string) => void
}
export const SpendingAccountsOverview: React.FC<Props> = ({
  activelySelectedAccountId,
  setActivelySelectedAccountId,
}) => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const {
    accountsData,
    getAccounts,
    flatAccounts,
    transactions,
    currentVariableExpenseAverage,
    flatManualAccounts,
    flexibleSpendPerMonth,
  } = useFinancialData()

  const { livingCostPercentageAdjustment } = useMysteryDay()
  const { setMessagePopupContent } = useUIState()
  const { settings } = useUserCustomSettings()

  const { streams } = useData()

  const events = streamsToEvents(
    streams,
    DAYS_OUT,
    getToday(),
    [],
    flexibleSpendPerMonth,
    livingCostPercentageAdjustment
  ).events

  const { error, status } = accountsData

  const skippedAccountsFromCashCalcuation =
    settings?.accountMetadata?.filter((datum) => datum.hidden).map((datum) => datum.account_id) ||
    []

  const banks = accountsData.data?.items || []
  const accounts = accountsData?.data?.accounts || []
  const institutions = accountsData?.data?.institutions || []

  const cashAccounts = getCashAccounts(flatAccounts).filter(
    (account) => !skippedAccountsFromCashCalcuation.includes(account.account_id)
  )

  const creditCardAccounts = getCreditCardAccounts(flatAccounts).filter(
    (account) => !skippedAccountsFromCashCalcuation.includes(account.account_id)
  )

  const spendingAccounts = [...cashAccounts, ...creditCardAccounts]

  const loading = status === 'loading'

  if (error) {
    return (
      <>
        <LoadingButton loading={loading} operation={getAccounts} />
        <TextPopupSmall
          color="mehred"
          className="mb-5 mx-1 p-3"
          onClick={() => {
            setMessagePopupContent(`<>Error Fetching Accounts'<>${JSON.stringify(error, null, 2)}`)
          }}>
          Error Fetching Transactions{' '}
          <Icon color="mehred" className="fas fa-exclamation-triangle" />
        </TextPopupSmall>
      </>
    )
  }

  if ((!banks || !accounts || !institutions) && !flatManualAccounts.length) {
    return (
      <div
        className="d-flex flex-column align-items-center"
        style={{
          opacity: accountsData?.status === 'loading' ? 0.5 : 1,
        }}>
        <DataOrFeatureMissing
          title={'Connected Bank Account Required'}
          body="To access live spending analytics, ontrack status, and historical transactions, you must connect a bank account."
          cta={<LoadingButton loading={loading} operation={getAccounts} />}
        />
      </div>
    )
  }

  const bankForActivelySelectedAccount = getBankItemFromAccount(
    accounts,
    banks,
    activelySelectedAccountId
  )
  const bankLogoForActivelySelectedBank = bankLogoForBank(
    bankForActivelySelectedAccount,
    institutions
  )
  const accountsToShow = spendingAccounts

  const transactionsForAccount = transactions?.filter(
    (t) => t.account_id === activelySelectedAccountId
  )
  const activelySelectedAccount = accountsToShow.find(
    (a) => a.account_id === activelySelectedAccountId
  )

  const spendingForAccount = getTransactionSpendingTotal(transactionsForAccount)

  const thisMonthSpendingForAccount = spendingForAccount.thisMonth.total
  const lastMonthSpendingForAccount = spendingForAccount.lastMonth.total

  const thisMonthSpendingTotal = currentVariableExpenseAverage?.thisMonth?.total || 0
  const lastMonthSpendingTotal = currentVariableExpenseAverage?.lastMonth?.total || 0

  return (
    <div
      style={{
        justifyContent: 'space-between',
        minWidth: '100%',
      }}
      className="mb-2 mt-1 pb-2">
      <div>
        <>
          <div
            className="d-flex flex-column justify-content-between w-100"
            style={{
              transition: 'opacity 0.5s ease-in-out',
              // opacity: loading ? 0.3 : 1,
            }}>
            <div
              className={`d-flex flex-column justify-content-${
                activelySelectedAccountId ? 'between' : 'start'
              } w-100 h-100`}>
              <div className="d-flex flex-column align-items-start justify-content-start w-100"></div>

              {accountsToShow
                .filter((a) =>
                  activelySelectedAccountId ? activelySelectedAccountId === a.account_id : true
                )
                .map((account, index) => {
                  // return null
                  return (
                    <SpendingAccountRow
                      key={account.account_id}
                      index={index}
                      events={events}
                      account={account}
                      activelySelectedAccountId={activelySelectedAccountId}
                      setActivelySelectedAccountId={setActivelySelectedAccountId}
                    />
                  )
                })}

              {activelySelectedAccountId ? (
                <div>
                  <MonthlyOverviewRow
                    lastMonthSpending={lastMonthSpendingForAccount}
                    thisMonthSpending={thisMonthSpendingForAccount}
                    activelySelectedAccount={activelySelectedAccount}
                    bankLogoForActivelySelectedBank={bankLogoForActivelySelectedBank}
                  />
                  <MonthlyOverviewRow
                    lastMonthSpending={lastMonthSpendingTotal}
                    thisMonthSpending={thisMonthSpendingTotal}
                  />
                  <TextSpan
                    color="textsupertransparent"
                    size="md"
                    className="mt-3"
                    onClick={() => {
                      setActivelySelectedAccountId('')
                    }}>
                    <u>Unselect account</u>
                  </TextSpan>
                </div>
              ) : null}
            </div>
          </div>
        </>
      </div>
    </div>
  )
}
