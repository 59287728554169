import { Icon } from 'Components/DesignSystem/Typography'
import { useUIState } from 'Providers/UIStateProvider'
import { differenceInDays } from 'date-fns'
import React from 'react'
import { colorPalletes } from 'theme'
import { WishlistItem } from '../WishlistProvider/WishlistProvider'

interface ItemIconProps {
  priority: number
  inactive?: boolean
  item: WishlistItem
}

export const ItemIcon: React.FC<ItemIconProps> = ({ priority, inactive, item }) => {
  const { activeTheme } = useUIState()

  const daysLeftToWait = differenceInDays(new Date(item.reminderDate), new Date())
  if (item.purchasedDate) {
    return (
      <Icon
        size="sm"
        color="orange"
        style={{
          transition: 'none',
          // marginRight: '-0.5rem',
        }}
        className={`fa-duotone fa-shopping-cart mr-2`}
      />
    )
  }
  if (item.resistedDate) {
    return (
      <Icon
        size="sm"
        color="megapurple"
        style={{
          transition: 'none',
          // marginRight: '-0.5rem',
        }}
        className={`fa-duotone fa-medal mr-2`}
      />
    )
  }

  return (
    <Icon
      size="md"
      color="texttransparent"
      style={
        {
          transition: 'none',

          '--fa-secondary-color':
            daysLeftToWait > 0
              ? colorPalletes[activeTheme].mehred
              : colorPalletes[activeTheme].cashgreen,

          '--fa-secondary-opacity': 1,
        } as React.CSSProperties
      }
      // marginRight: '-0.5rem',
      className={`fa-duotone fa-traffic-light-${daysLeftToWait > 0 ? 'stop' : 'go'} mr-2`}
    />
  )
}
