import { TextSpan } from 'Components/DesignSystem/Typography'
import color from 'color'
import styled from 'styled-components/macro'
import { ColorType } from 'theme'

export const StreamDiv = styled.div<{ borderColor?: ColorType }>`
  width: 100%;
  padding: 0.5rem 0rem;
  height: 56px;
  min-height: 56px;
  border-bottom: ${(props) =>
    `1px solid ${color(props.theme.colors[props.borderColor || 'nearlyclear']).toString()}`};
  font-family: ${(props) => props.theme.fonts.family.secondary};
  font-weight: 400;
  font-size: 1rem;
  display: grid;
  grid-template-columns: minmax(0, 0.5fr) minmax(0, 1.75fr) minmax(0, 0.75fr) minmax(0, 1fr);
`

interface StreamAmountPartProps {
  isPositive?: boolean
}
export const StreamAmountPart = styled.span<StreamAmountPartProps>`
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.fonts.sizes.xs};
  justify-content: center;
  /* border-right: ${(props) => `1px solid ${color(props.theme.colors.background).darken(0.1)}`}; */
  color: ${(props) =>
    props.isPositive ? props.theme.colors.cashgreen : props.theme.colors.mehred};
`

export const StreamPart = styled(TextSpan)<{
  isCreditCard?: boolean
  isLegend?: boolean
  sortingOrder?: number
  enabled?: boolean
}>`
  padding: 0 0.25rem;
  font-weight: ${(props) => props.theme.fonts.weights[600]};
  color: ${(props) =>
    props.isLegend
      ? props.enabled
        ? props.sortingOrder === -1
          ? props.theme.colors.mehred
          : props.theme.colors.cashgreen
        : props.theme.colors.textsupertransparent
      : props.isCreditCard
      ? props.theme.colors.mehred
      : props.theme.colors.texttransparent};
  text-align: ${(props) => (props.isLegend ? 'center' : 'start')};
  overflow: hidden;
  margin-top: 0;
  margin-bottom: 0;
  max-width: 100%;
  text-overflow: ellipsis;
  /* display: flex; */
  align-items: center;
  font-size: ${(props) => props.theme.fonts.sizes.xs};
  white-space: nowrap;
  justify-content: center;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
`

export const StreamDatePart = styled.span`
  font-size: 0.75rem;
  display: block;
  text-align: center;
  align-items: center;
  justify-content: center;
  /* border-right: ${(props) => `1px solid ${color(props.theme.colors.background).darken(0.1)}`}; */
  padding: 0 0.25rem;
`
