import { Icon, TextSlim, TextSpan } from 'Components/DesignSystem/Typography'
import { ReflectionCarousel } from 'Pages/SpendingPage/ReflectionReview/ReflectionCarousel/ReflectionCarousel'
import { useCategoryBudgetHelpers } from 'Pages/VariablePage/CategoryBudgetTable/helpers'
import { useUIState } from 'Providers/UIStateProvider'
import { useUserCustomSettings } from 'Providers/UserCustomSettingsProvider/UserCustomSettingsProvider'
import { numberToCurrency } from 'Utilities/currencyFormater'
import { Transaction } from 'plaid'
import React, { useEffect } from 'react'
import { colorPalletes } from 'theme'
import { getCreatedCategories } from '../DecorateTransactionsPopup'
import { ScrollingItemPickerDisplay } from './Helpers'

interface Props {
  envelopeCategory: string
  setEnvelopeCategory: React.Dispatch<React.SetStateAction<string>>
  t: Transaction
  setOverbudgetSave?: (bool: boolean) => void
}
export const VariableCategoryPicker: React.FC<Props> = ({
  envelopeCategory,
  setEnvelopeCategory,
  setOverbudgetSave,
  t,
}) => {
  const { settings } = useUserCustomSettings()

  const { activeTheme, setAddCustomEnvelopePageOpen, setModalContent } = useUIState()

  const { getCategoryTotal } = useCategoryBudgetHelpers()

  const existingCategories = getCreatedCategories(settings)

  const [containerHeight, setContainerHeight] = React.useState<number>(0)
  const ref = React.useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (ref.current) {
      const height = ref.current.clientHeight
      setContainerHeight(height)
    }
  }, [ref])

  useEffect(() => {
    if (setOverbudgetSave) {
      const overBudget = existingCategories?.some((ec) => {
        const budgetLimitForCategory = settings?.categoryMetadata?.[ec]?.limit || 0
        const thisMonthSpendForCategory = getCategoryTotal(ec)
        const left = budgetLimitForCategory - thisMonthSpendForCategory
        const remainingAfterThisItem = left - t.amount
        const overBudget = remainingAfterThisItem < 0
        return overBudget && ec === envelopeCategory
      })
      if (overBudget) {
        setOverbudgetSave(true)
      } else {
        setOverbudgetSave(false)
      }
    }
  }, [
    existingCategories,
    setOverbudgetSave,
    envelopeCategory,
    getCategoryTotal,
    settings,
    t.amount,
  ])

  return (
    <div
      ref={ref}
      className="d-flex flex-column w-100 align-items-start justify-content-start pl-0 h-100">
      <TextSpan color="textsupertransparent" size="sm" className="my-0" weight={200} style={{}}>
        Assign Envelope
        <TextSlim
          style={{
            backgroundColor: colorPalletes[activeTheme].yellow,
            borderRadius: '4px',
            whiteSpace: 'nowrap',
            opacity: envelopeCategory ? 1 : 0,
            maxWidth: '35vw',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
          className="my-1 ml-2 px-1 py-1 mx-1"
          color={'background'}
          size="xxxs"
          weight={700}>
          {envelopeCategory}
          <Icon className="fas fa-check-circle ml-1" color="background" style={{}} size="xxs" />
        </TextSlim>
      </TextSpan>
      <ScrollingItemPickerDisplay
        containerHeight={containerHeight}
        activeColor="yellow"
        title="Envelope"
        onCreateNewItemClick={() => {
          setModalContent(null)
          setAddCustomEnvelopePageOpen({
            val: '',
            cb: function (name: string) {
              setModalContent(<ReflectionCarousel />)
              setEnvelopeCategory(name)
            },
          })
        }}
        items={
          existingCategories?.map((ec) => {
            const budgetLimitForCategory = settings?.categoryMetadata?.[ec]?.limit || 0
            const thisMonthSpendForCategory = getCategoryTotal(ec)
            const left = budgetLimitForCategory - thisMonthSpendForCategory
            const remainingAfterThisItem = left - t.amount
            const overBudget = remainingAfterThisItem < 0
            return {
              activeColor: 'yellow',
              isActive: ec === envelopeCategory,
              title: ec,
              onClick: (ec) => setEnvelopeCategory(ec),
              metaData: (
                <div className="d-flex align-items-center ml-0">
                  <TextSpan size="xxs" color="textsupertransparent">
                    <Icon
                      color="textsupertransparent"
                      size="xs"
                      className="fas fa-envelope-open-dollar mr-1"
                    />{' '}
                    = {numberToCurrency(budgetLimitForCategory)}
                  </TextSpan>
                  <TextSpan size="xxs" color={overBudget ? 'yellow' : 'cashgreen'} className="ml-1">
                    | {numberToCurrency(Math.round(remainingAfterThisItem))}{' '}
                    {overBudget ? `over ` : 'left'}
                  </TextSpan>
                  {overBudget ? (
                    <Icon className="fas fa-exclamation-triangle ml-1" color="yellow" />
                  ) : null}
                </div>
              ),
            }
          }) || []
        }
      />
    </div>
  )
}
