import { MCOLBadge } from 'Components/MCOLBadge'
import { useFinancialData } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import { CaptionText } from 'design-system/typography/Text/Text'
import React from 'react'
import { Icon, TextSlim, TextSpan } from '../../../../Components/DesignSystem/Typography'
import { useData } from '../../../../Providers/APIDataProvider'
import { numberToCurrency } from '../../../../Utilities/currencyFormater'
import { getMonthlyIncome } from '../../../../Utilities/netWorth/netWorth'
import { SubPageProps } from '../../MysteryPage'

export const RELAXED_RECOMMENDED_AGGRESIVE_SPREAD = 0.35

export const RecapPanel: React.FC<SubPageProps> = () => {
  const { streams, monthlyBurnRate } = useData()

  const { currentSavings } = useFinancialData()

  const { setMonthlyContribution, mysteryMode, desiredTimeOff, cushionSubMode } = useMysteryDay()

  const yearsOff = Math.floor(desiredTimeOff / 12)
  const monthsOff = desiredTimeOff - yearsOff * 12

  const totalRunwayNeeded = monthlyBurnRate * desiredTimeOff

  const monthlyIncome = getMonthlyIncome(streams)

  const baseRecommendedAmount = monthlyIncome - Math.abs(monthlyBurnRate)

  React.useEffect(() => {
    setMonthlyContribution(baseRecommendedAmount)
  }, [baseRecommendedAmount, setMonthlyContribution])

  const totalCost = monthlyBurnRate

  const coastMode = mysteryMode === 'buildCushion' && cushionSubMode === 'extendSavings'
  return (
    <>
      <TextSpan size="md" weight={200} className="my-0" color="textsupertransparent">
        Recap
      </TextSpan>

      <CaptionText className="my-2 px-4">
        If something doesn't look right, go back and edit.
      </CaptionText>
      <div className="w-100 align-items-center d-flex w-100 my-0">
        {mysteryMode === 'buildCushion' && cushionSubMode === 'extendSavings' ? null : (
          <div className="w-100 align-items-center d-flex flex-column  my-0">
            <TextSpan size="sm" className="mr-2 my-0" weight={800} color="cashgreen" style={{}}>
              <Icon className="fas fa-sack-dollar mr-1" size="sm" color="cashgreen" />
              Income
            </TextSpan>
            <TextSlim className="my-0 align-self-center" weight={700} size="xs" color="cashgreen">
              {numberToCurrency(monthlyIncome, true, true)} / m
            </TextSlim>
          </div>
        )}

        <div className="w-100 align-items-center d-flex flex-column  my-0">
          <MCOLBadge itemAlignment="center" headerSize="sm" iconSize="sm" labelSize="xxxs" />
          <TextSlim className="my-0 align-self-center" weight={700} size="sm" color="mehred">
            {numberToCurrency(totalCost * -1, true, true)} / m
          </TextSlim>
        </div>

        {mysteryMode === 'saveToQuit' ? (
          <div className="w-100 align-items-center d-flex flex-column  my-0">
            <TextSpan
              size="sm"
              className="mr-2 my-0 text-center"
              weight={800}
              color="orange"
              style={{}}>
              <Icon className="fas fa-rocket mr-2" size="sm" color="orange" />
              Time Off
            </TextSpan>
            <TextSlim
              className="my-0 align-self-center text-center"
              weight={200}
              size="xxs"
              color="orange">
              <TextSpan weight={700} className="my-0">
                {yearsOff}
              </TextSpan>{' '}
              year{yearsOff > 1 ? 's' : ''}
              {monthsOff ? (
                <>
                  ,{' '}
                  <TextSpan className="my-0" weight={700}>
                    {monthsOff}
                  </TextSpan>{' '}
                  month {monthsOff > 1 ? 's' : ''}
                </>
              ) : null}
            </TextSlim>
            <TextSlim
              className="my-0 align-self-center text-center"
              weight={700}
              size="xxs"
              color="orange">
              {numberToCurrency(totalRunwayNeeded)} needed
            </TextSlim>
          </div>
        ) : coastMode || (mysteryMode === 'buildCushion' && cushionSubMode === 'emergencyFund') ? (
          <div className="w-100 align-items-center d-flex flex-column  my-0">
            <TextSpan
              size="sm"
              className="mr-2 my-0 text-center"
              weight={800}
              color="softblue"
              style={{}}>
              <Icon className="fas fa-piggy-bank mr-2" size="sm" color="softblue" />
              Savings
            </TextSpan>
            <TextSlim
              className="my-0 align-self-center text-center"
              weight={700}
              size="xs"
              color="softblue">
              {numberToCurrency(currentSavings)} left
            </TextSlim>
          </div>
        ) : null}
      </div>
    </>
  )
}
