import { WishlistItem } from '../WishlistProvider/WishlistProvider'
import { ItemSizeFilter } from './WishlistDraggableList'

export const filterFunction = (activeItemSizeFilter: ItemSizeFilter) => {
  switch (activeItemSizeFilter) {
    case 'medium':
      return (item: WishlistItem) => Number(item.price) <= 750 && Number(item.price) >= 100
    case 'small':
      return (item: WishlistItem) => Number(item.price) < 100
    case 'large':
      return (item: WishlistItem) => Number(item.price) > 750
  }
}
