import React from 'react'
import { Icon, TextSlimTight } from '../../../Components/DesignSystem/Typography'
import { AdvancedTransactionRowDiv } from './components'

interface LegendPackedSlimTransactionRowProps {
  onNameClick: () => void
  onAmountClick: () => void
  onDateClick: () => void
  onBankClick: () => void
  sortMode: 'name' | 'amount' | 'date' | 'category' | 'bank'
  sortOrder: number
}

export const LegendPackedSlimTransactionRow: React.FC<LegendPackedSlimTransactionRowProps> = ({
  onNameClick,
  onAmountClick,
  onDateClick,
  sortMode,
  onBankClick,
  sortOrder,
}) => {
  return (
    <AdvancedTransactionRowDiv legend={true}>
      <TextSlimTight
        onClick={onBankClick}
        tightness="tight"
        size="xxxxs"
        className="d-flex align-items-center"
        weight={500}
        color={sortMode === 'bank' ? (sortOrder === 1 ? 'logoPrimary' : 'mehred') : 'text'}
        style={{ textAlign: 'left' }}>
        <Icon
          className="fas fa-building-columns"
          size="xs"
          color={sortMode === 'bank' ? (sortOrder === 1 ? 'logoPrimary' : 'mehred') : 'text'}
        />
      </TextSlimTight>
      <TextSlimTight
        onClick={onNameClick}
        tightness="tight"
        size="xs"
        className="d-flex align-items-center"
        weight={500}
        color={sortMode === 'name' ? (sortOrder === 1 ? 'logoPrimary' : 'mehred') : 'text'}
        style={{ textAlign: 'left' }}>
        Name{' '}
        <Icon
          className="fas fa-triangle"
          size="xxxs"
          color={sortMode === 'name' ? (sortOrder === 1 ? 'logoPrimary' : 'mehred') : 'text'}
          style={{
            transform: `rotate(${sortOrder === 1 ? '0deg' : '180deg'})`,
            opacity: sortMode === 'name' ? 1 : 0,
          }}
        />
      </TextSlimTight>
      <TextSlimTight
        onClick={onAmountClick}
        size="xs"
        className="d-flex align-items-center"
        weight={500}
        color={sortMode === 'amount' ? (sortOrder === 1 ? 'logoPrimary' : 'mehred') : 'text'}>
        $
        <Icon
          className="fas fa-triangle"
          size="xxxs"
          color={sortMode === 'amount' ? (sortOrder === 1 ? 'logoPrimary' : 'mehred') : 'text'}
          style={{
            transform: `rotate(${sortOrder === 1 ? '0deg' : '180deg'})`,
            opacity: sortMode === 'amount' ? 1 : 0,
          }}
        />
      </TextSlimTight>
      <TextSlimTight
        onClick={onDateClick}
        size="xxs"
        weight={500}
        className="d-flex align-items-center"
        color={sortMode === 'date' ? (sortOrder === 1 ? 'logoPrimary' : 'mehred') : 'text'}>
        Date
        <Icon
          className="fas fa-triangle"
          size="xxs"
          color={sortMode === 'date' ? (sortOrder === 1 ? 'logoPrimary' : 'mehred') : 'text'}
          style={{
            transform: `rotate(${sortOrder === 1 ? '0deg' : '180deg'})`,
            opacity: sortMode === 'date' ? 1 : 0,
          }}
        />
      </TextSlimTight>
    </AdvancedTransactionRowDiv>
  )
}
