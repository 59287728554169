import { CollapseCardControlled } from 'Components/CollapseCard/CollapseCardControlled'
import { useMysteryModeFinalDate } from 'Components/DesignSystem/Layout/Header/hooks'
import { dataParameterType } from 'Hooks/useLocalStorageSyncedState'
import { CashflowRecapContent } from 'Pages/MysteryPage/CashFlowRecap/CashFlowRecapContent'
import { useFinancialData } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import { add } from 'date-fns'
import { LabelText } from 'design-system/typography/Text/Text'
import React, { useEffect } from 'react'
import { colorPalletes } from 'theme'
import {
  Icon,
  StyledVr,
  TextPopupSmall,
  TextSlim,
  TextSpan,
} from '../../Components/DesignSystem/Typography'
import { useData } from '../../Providers/APIDataProvider'
import { useMysteryDay } from '../../Providers/MysteryDayProvider/MysteryDayProvider'
import { useUIState } from '../../Providers/UIStateProvider'
import { numberToCurrency } from '../../Utilities/currencyFormater'
import { humanReadifyStringDate } from '../../Utilities/dateUtilities/dateUtilities'
import { iconFromMysteryDayType } from '../../Utilities/utils'
import { fireConfetti } from '../WishlistPage/Confetti'

const LaunchDayCompleted: React.FC = () => {
  const { activeTheme } = useUIState()
  useEffect(() => {
    fireConfetti({ colors: [colorPalletes[activeTheme].logoPrimary], duration: 0.5 })
  }, [activeTheme])

  const { setMessagePopupContent } = useUIState()
  return (
    <TextSlim
      style={{
        transition: 'all ease-in-out .3s',
        minHeight: '43px',
      }}
      className="my-0"
      color="softblue"
      size="md"
      weight={200}>
      <Icon
        className={`${iconFromMysteryDayType('buildCushion')} mx-1`}
        color={'softblue'}
        size="lg"
      />
      Ready to Coast!
      <TextPopupSmall
        color={'softblue'}
        className="ml-2 px-2 py-1"
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          setMessagePopupContent(
            "WHOA!<> You have saved up enough to take your sabbatical! You have saved up an amount that will be enough to live off of given your adjusted expenses. That's pretty neat. Reset onboarding and say you're on Cushion Mode to get an experience more tailored to extending that timeline and curbing your spend rate.<><> If you are just playing with the cost of living slider and got here, now you see that affording time off has more to do with our expenses than our income."
          )
        }}>
        <Icon color={'softblue'} size="md" className="fas fa-circle-info" />
      </TextPopupSmall>
    </TextSlim>
  )
}

const LaunchDayTitle = ({
  amount,
  completed,
  date,
  months,
  yearsLeft,
  monthsLeft,
  impossible,
}: {
  amount: string
  date: string | null
  months: number
  monthsLeft: number
  completed: boolean
  yearsLeft: number
  impossible: boolean
}) => (
  <div className="d-flex align-items-center justify-content-between w-100">
    <div className="d-flex flex-row align-items-center">
      <div className="d-flex flex-column align-items-center">
        <LabelText color="orange">{amount}</LabelText>
        <LabelText color="orange">needed</LabelText>
      </div>
      <TextSpan
        style={{} as React.CSSProperties}
        color={'texttransparent'}
        weight={100}
        className="mx-2"
        size="xs">
        for
      </TextSpan>

      <div className="d-flex flex-column align-items-center">
        <LabelText color="softblue">{months}</LabelText>
        <LabelText color="softblue">months off</LabelText>
      </div>
    </div>
    <StyledVr
      color={'texttransparent'}
      size="xl"
      className="mx-2"
      weight={1}
      style={{ opacity: 0.5 }}
    />
    <div style={{ flex: 1 }} className="d-flex flex-row justify-content-start align-items-center">
      <div className="d-flex flex-column align-items-start">
        <div className="d-flex flex-row align-items-center">
          <LabelText color="texttransparent">
            {yearsLeft} year{yearsLeft > 1 || yearsLeft === 0 ? 's' : ''}
          </LabelText>
        </div>
        <div className="d-flex flex-row align-items-center">
          <LabelText color="texttransparent">
            {monthsLeft} month{monthsLeft > 1 || monthsLeft === 0 ? 's' : ''}
          </LabelText>
        </div>
      </div>
    </div>
  </div>
)

interface Props {
  setCashFlowRecapOpen: (v: dataParameterType<boolean>) => void
  cashFlowRecapOpen: boolean
}
export const LaunchDaySummary: React.FC<Props> = ({ setCashFlowRecapOpen, cashFlowRecapOpen }) => {
  const { monthlyContribution, desiredTimeOff, mysteryMode } = useMysteryDay()

  const { currentSavings } = useFinancialData()

  const { monthlyBurnRate } = useData()

  const totalRunwayNeeded = monthlyBurnRate * desiredTimeOff

  const remainingRunwayNeeded = totalRunwayNeeded - currentSavings

  const launchDayReached = remainingRunwayNeeded < 0

  let { yearsLeft, monthsLeft } = useMysteryModeFinalDate()

  // if (mysteryMode === 'coastOnSavings') {
  //   const totalMonthsLeft = Math.floor(currentSavings / totalCost)
  //   yearsLeft = Math.floor(totalMonthsLeft / 12)
  //   monthsLeft = totalMonthsLeft - yearsLeft * 12
  // }

  const wontBePaidOff = monthsLeft === Infinity

  const sunnyDay = wontBePaidOff ? null : add(new Date(), { months: monthsLeft, years: yearsLeft })
  const humanReadableSunnyDay = wontBePaidOff ? null : humanReadifyStringDate(sunnyDay)

  return (
    <CollapseCardControlled
      isCollapsed={!cashFlowRecapOpen}
      setIsCollapsed={setCashFlowRecapOpen}
      header={
        <div className="d-flex flex-column align-items-start justify-content-between w-100">
          <div className="d-flex align-items-center justify-content-between flex-column pr-2 w-100">
            <div className="d-flex align-items-center justify-content-start flex-row pt-2 w-100">
              <div className="d-flex align-items-start justify-content-between flex-column w-100">
                {launchDayReached ? (
                  <LaunchDayCompleted />
                ) : (
                  <LaunchDayTitle
                    amount={numberToCurrency(totalRunwayNeeded)}
                    date={humanReadableSunnyDay}
                    yearsLeft={yearsLeft}
                    monthsLeft={monthsLeft}
                    months={desiredTimeOff}
                    completed={launchDayReached}
                    impossible={monthlyContribution === 0}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      }
      body={<CashflowRecapContent mysteryMode={mysteryMode} />}
    />
  )
}
