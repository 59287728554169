import color from 'color'
import styled from 'styled-components'

export const ManualTransactionRowDiv = styled.div<{ loading?: boolean }>`
  width: 100%;
  align-items: center;
  display: grid;
  border-bottom: ${(props) =>
    `solid 1px ${color(props.theme.colors.backgrounddark).alpha(0.1).toString()}`};
  margin: 0.25rem;
  padding: 0.25rem 0;
  background-color: ${(props) => (props.loading ? props.theme.colors.logoPrimary : 'transparent')};
  transition: background-color 0.5s ease-in-out;
  grid-template-columns: minmax(0, 2.25fr) minmax(0, 1fr) minmax(0, 1.25fr) minmax(0, 30px);
  * {
    display: flex;
    justify-content: flex-start;
    padding: 0 0.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: flex-start;
    display: inline-block;
    display: flex;
    /* white-space: nowrap; */
    text-align: left;
  }
  *:first-child {
    justify-content: flex-start;
  }
  *:last-child {
    text-align: end;
  }
`
