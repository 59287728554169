import { Icon, TextPopupSmall, TextSlim, TextSpan } from 'Components/DesignSystem/Typography'
import { numberToCurrency } from 'Utilities/currencyFormater'
import React from 'react'
import { ColorType } from 'theme'
import { RELAXED_RECOMMENDED_AGGRESIVE_SPREAD } from './RecapPanel'

interface Props {
  selection: 'low' | 'medium' | 'high'
  amount: number
  date: string | null
  title: string
  onClick: () => void
  active: boolean
  activeColor: ColorType
  goalWord: string
}
export const ContributionSelection: React.FC<Props> = ({
  selection,
  amount,
  date,
  title,
  onClick,
  active,
  goalWord,
  activeColor,
}) => {
  const color: ColorType = active ? activeColor : 'textsupertransparent'
  return (
    <div
      onClick={onClick}
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ opacity: active ? 1 : 0.7 }}>
      <TextSlim className="my-0 align-self-center" weight={700} size="xs" color={color}>
        {selection === 'medium' ? (
          <TextPopupSmall className="" color={color}>
            {'Recommended'}
            <Icon className="fas fa-check ml-1" color={color} />
          </TextPopupSmall>
        ) : (
          <>
            {selection === 'high' ? '+' : '-'}
            {RELAXED_RECOMMENDED_AGGRESIVE_SPREAD * 100}%
          </>
        )}
      </TextSlim>
      {selection === 'medium' ? null : (
        <TextPopupSmall size="xxs" weight={400} color={color}>
          {title}

          <Icon
            className={`fas fa-${selection === 'low' ? 'umbrella-beach' : 'bolt-lightning'} ml-1`}
            color={color}
            size="xs"
          />
        </TextPopupSmall>
      )}
      <TextSlim
        className="my-0 align-self-center"
        weight={700}
        size={selection === 'medium' ? 'md' : 'xs'}
        color={color}>
        {numberToCurrency(amount, true)} / m
      </TextSlim>
      <TextSlim
        className="my-0 align-self-center text-center"
        weight={300}
        size="xxs"
        color={color}>
        {goalWord} <br />
        <TextSpan className="my-0" weight={700}>
          {date}
        </TextSpan>
      </TextSlim>
    </div>
  )
}
