import { ContainerDiv } from 'Components/DesignSystem/Layout/Containers/Containers'
import { TextSpan } from 'Components/DesignSystem/Typography'
import React from 'react'

const questionsAndAnswers: { question: string; answer: string }[] = [
  {
    question: 'Why are you not on the app store?',
    answer: `We don't believe in app stores. Not exactly. But Apple and Google do take 30% of revenue generated on their platforms and we wanted to try something different that's also hopefully easier for you.`,
  },
  {
    question: 'Why are there not more questions here?',
    answer: `Because I'm slowly rolling this out and you happen to be a very early tester of the app that's getting early versions`,
  },
]

export const FAQPopup: React.FC = () => {
  return (
    <ContainerDiv className="p-4">
      <TextSpan>FAQ: Feisty Answers to Questions</TextSpan>
      <ul style={{ paddingLeft: '20px' }}>
        {questionsAndAnswers.map(({ question, answer }) => {
          return (
            <li key={question}>
              <TextSpan color="text" weight={600} size="sm">
                {question}
              </TextSpan>
              <TextSpan color="texttransparent" weight={200} size="xs">
                {answer}
              </TextSpan>
            </li>
          )
        })}
      </ul>
    </ContainerDiv>
  )
}
