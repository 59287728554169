import color from 'color'
import styled from 'styled-components/macro'
import { ColorType } from 'theme'

interface MarkerProps {
  width: number
  offsetLeft?: number
  color: ColorType
  content: string
  shouldRoundBorders?: boolean
  icon: string
}

export const SavingsRangeMarkerTick = styled.div<MarkerProps>`
  position: absolute;
  display: flex;
  align-items: center;

  left: ${(props) => `${props.width}px`};
  width: 2px;
  height: 20px;
  pointer-events: none;

  background: ${(props) => props.theme.colors[props.color]};

  ::before {
    position: absolute;
    font-size: ${(props) => props.theme.fonts.sizes.xxxs};
    font-weight: ${(props) => props.theme.fonts.weights[800]};
    font-family: ${(props) => props.theme.fonts.family.secondary};

    content: ${(props) => `'${props.content}'`};
    color: ${(props) => props.theme.colors.mehred};
    top: -18px;
    transform: translateX(-50%);
  }
`

export const SlidingMessageBoxContainer = styled.div`
  display: flex;
  position: relative;
  padding: 0 calc(48px);
  width: 100%;
  align-items: center;
  margin: 0.5rem 0.25rem;
  background: ${(props) => props.theme.colors.seconddark};
  justify-content: flex-start;
`
interface SlidingMessageBoxProps {
  left: number
  messageBoxAtLowerLimit: boolean
  bottomIcon: string
  messageColor: ColorType
  shouldShow: boolean
}
export const SlidingMessageBox = styled.div<SlidingMessageBoxProps>`
  padding: 0.25rem 0.5rem;
  border: solid 1px ${(props) => props.theme.colors[props.messageColor]};
  width: 100%;
  border-radius: 4px;
  opacity: ${(props) => (props.shouldShow ? 1 : 0)};
  transition: color ease-in-out 0.3s, background-color ease-in-out 0.3s,
    border-color ease-in-out 0.3s, opacity ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => color(props.theme.colors[props.messageColor]).darken(0.65).toString()};
  background-color: ${(props) => color(props.theme.colors[props.messageColor]).toString()};
  position: absolute;
  left: 0;
  span {
    transition: none;
  }
  i {
    color: ${(props) => color(props.theme.colors[props.messageColor]).darken(0.65).toString()};
    transition: all ease-in-out 0.3s;
  }
`

export const SimpleMessageBox = styled.div<{ shouldShow: boolean }>`
  margin: 0;
  padding: 0.25rem 1rem;
  border: solid 1px ${(props) => props.theme.colors['seconddark']};
  border-radius: 4px;
  opacity: ${(props) => (props.shouldShow ? 1 : 0)};
  transition: color ease-in-out 0.3s, background-color ease-in-out 0.3s,
    border-color ease-in-out 0.3s, opacity ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => color(props.theme.colors['seconddark']).darken(0.8).toString()};
  background-color: ${(props) => color(props.theme.colors['seconddark']).toString()};
  position: absolute;
  left: 0;
  span {
    transition: none;
  }
  i {
    color: ${(props) => color(props.theme.colors['seconddark']).darken(0.8).toString()};
    transition: all ease-in-out 0.3s;
  }
`
