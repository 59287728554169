/* eslint-disable */
import isEqual from 'fast-deep-equal'
import React from 'react'
import { throttle } from 'throttle-debounce'

const RESIZE_OBSERVER_DEBOUNCE = 100
const RESIZE_OBSERVER_PRECISION = 1

interface UseResizeObserversOptions {
  /**
   * Indicate which of
   * a given element's [boxes](https://developer.mozilla.org/en-US/docs/Learn/CSS/Building_blocks/The_box_model#parts_of_a_box)
   * you wish to measure.
   *
   * @default 'border-box'
   */
  box?: 'margin-box' | 'border-box' | 'content-box'
  /**
   * Indicate which [dimensions](https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserverEntry/borderBoxSize#value)
   * of a given element's measured box you wish to observe.
   *
   * Note that the element's entire box is always returned. The underlying `ResizeObserver` always
   * watches `both` dimensions. However, this hook will only rerender React for changes in the
   * specified dimension, reducing JavaScript execution.
   *
   * @default 'both'
   */
  dimension?: 'block' | 'inline' | 'both'
}

type ObserverKey = string | number | symbol

interface Measurement {
  inline: number
  block: number
}

type RealtimeUpdateHandler<K extends ObserverKey> = (
  key: K,
  newMeasurement: Measurement | null
) => void

export const useResizeObserver = (
  options?: UseResizeObserversOptions,
  realtimeUpdateHandler?: RealtimeUpdateHandler<0>
) => {
  const { measurements, observerRef } = useResizeObservers<0>(realtimeUpdateHandler)
  const refFn = observerRef(0, options)
  return [measurements[0] || null, refFn] as const
}

const useResizeObservers = <K extends ObserverKey>(
  realtimeUpdateHandler?: RealtimeUpdateHandler<K>
) => {
  // The premise of the state management:
  // - store measurements in a ref, and also in state
  // - update the ref measurements in real time, continuously, as every resize fires (100+/s)
  // - only update state using syncMeasurementsDebounce(), which happens much less often

  // This way, we can receive batches of resize updates from the browser in any order and keep our
  // entire set of measurements up to date in memory, but only rerender React on a debounce.

  // This is necessary, not merely elegant; a ResizeObserver observing multiple elements receives
  // events from multiple targets, so merely taking the first/last event and every DEBOUNCE event
  // in-between does not result in a complete picture (you'd need to distinguish between every
  // target and debounce them individually).

  // Instead of trying to debounce individually, we just make the actual handler for resize events
  // zero-cost (update memory and schedule a setTimeout, essentially) and only do the expensive job
  // of updating everything when the timeout hits.

  const previousWatchedMeasurementsRef = React.useRef<Partial<Record<K, Partial<Measurement>>>>({})
  const currentWatchedMeasurementsRef = React.useRef<Partial<Record<K, Partial<Measurement>>>>({})
  const currentActualMeasurementsRef = React.useRef<Partial<Record<K, Measurement>>>({})
  const [measurements, setMeasurements] = React.useState<Partial<Record<K, Measurement>>>({})

  const syncMeasurementsDebounce = React.useMemo(
    () =>
      throttle(RESIZE_OBSERVER_DEBOUNCE, () => {
        if (
          !isEqual(previousWatchedMeasurementsRef.current, currentWatchedMeasurementsRef.current)
        ) {
          // Clone, because the current refs are mutated for performance reasons
          previousWatchedMeasurementsRef.current = { ...currentWatchedMeasurementsRef.current }
          setMeasurements({ ...currentActualMeasurementsRef.current })
        }
      }),
    []
  )

  const setMeasurement = React.useCallback(
    (
      key: K,
      newMeasurement: Measurement | null,
      dimension: UseResizeObserversOptions['dimension']
    ) => {
      // Mutate for performance reasons
      if (!newMeasurement) {
        delete currentActualMeasurementsRef.current[key]
        delete currentWatchedMeasurementsRef.current[key]
      } else {
        currentActualMeasurementsRef.current[key] = newMeasurement
        if (dimension === 'block') {
          currentWatchedMeasurementsRef.current[key] = { block: newMeasurement.block }
        } else if (dimension === 'inline') {
          currentWatchedMeasurementsRef.current[key] = { inline: newMeasurement.inline }
        } else {
          currentWatchedMeasurementsRef.current[key] = newMeasurement
        }
      }
      syncMeasurementsDebounce()
    },
    []
  )

  // Used to store observer options and refFn setter implementations with reference integrity
  const configurationRefs = React.useMemo(() => new Map<K, ObserverConfiguration>(), [])
  // Used to look up the key of an element when a resize has been detected so we can determine
  // which keyed measurement to update
  const elementKeys = React.useMemo(() => new WeakMap<Element, K>(), [])
  // Used to store elements by key so we can clean up when React removes an element
  const elementRefs = React.useMemo(() => new Map<K, Element>(), [])

  const observer = React.useMemo(
    () =>
      new ResizeObserver((entries: ResizeObserverEntry[]) => {
        entries.forEach((entry) => {
          const key = elementKeys.get(entry.target)
          if (key === undefined) {
            console.error(
              'useResizeObservers: could not find a key in the WeakMap for this element:',
              entry.target
            )
          } else {
            const configuration = configurationRefs.get(key)
            if (!configuration) {
              console.error('useResizeObservers: could not find a configuration for this key:', key)
            } else {
              const newMeasurement = getBox(entry, configuration.options.box)
              setMeasurement(key, newMeasurement, configuration.options.dimension)
              realtimeUpdateHandler?.(key, newMeasurement)
            }
          }
        })
      }),
    [realtimeUpdateHandler]
  )

  // Ref function factory. The user requests a ref by key, and we return a setter that will update
  // our internal maps and attach resize observation.
  // Internally we are careful to only generate new ref setters if the options have changed.
  // This prevents React from setting and un-setting the ref constantly and triggering forced
  // updates.
  const observerRef = React.useCallback(
    (key: K, { box = 'border-box', dimension = 'both' }: UseResizeObserversOptions = {}) => {
      const options = { box, dimension }
      if (!configurationRefs.has(key) || !isEqual(configurationRefs.get(key)?.options, options)) {
        configurationRefs.set(key, {
          options,
          refFn: (element: Element | null) => {
            const current = elementRefs.get(key)
            if (element !== current) {
              current && observer.unobserve(current)
              if (element) {
                elementRefs.set(key, element)
                elementKeys.set(element, key)
                observer.observe(element, {
                  box: options.box === 'content-box' ? 'content-box' : 'border-box',
                })
                setMeasurement(
                  key,
                  getBox(
                    { contentRect: element.getBoundingClientRect(), target: element },
                    options.box
                  ),
                  options.dimension
                )
              } else {
                setMeasurement(key, null, options.dimension)
                elementRefs.delete(key)
              }
            }
          },
        })
      }
      return configurationRefs.get(key)!.refFn
    },
    []
  )

  return {
    measurements,
    observerRef,
  }
}

interface ObserverConfiguration {
  options: UseResizeObserversOptions
  refFn: (element: Element | null) => void
}

interface SizeData {
  contentBoxSize?: readonly ResizeObserverSize[]
  borderBoxSize?: readonly ResizeObserverSize[]
  contentRect: DOMRectReadOnly
  target: Element
}

const getBox = (entry: SizeData, box: UseResizeObserversOptions['box']) => {
  const measurement =
    box === 'margin-box'
      ? getMarginBox(entry)
      : box === 'border-box'
      ? getBorderBox(entry)
      : box === 'content-box'
      ? getContentBox(entry)
      : (null as never)
  const inline = roundToPrecision(measurement.inline)
  const block = roundToPrecision(measurement.block)
  return { inline, block }
}

const getMarginBox = (entry: SizeData) => {
  const borderBox = getBorderBox(entry)
  const styles = window.getComputedStyle(entry.target)
  const margin = widthAndHeightToInlineAndBlock(
    {
      width: parseFloat(styles.marginLeft) + parseFloat(styles.marginRight),
      height: parseFloat(styles.marginTop) + parseFloat(styles.marginBottom),
    },
    styles.writingMode
  )
  return {
    inline: borderBox.inline + margin.inline,
    block: borderBox.block + margin.block,
  }
}

const getBorderBox = ({ borderBoxSize, contentRect, target }: SizeData) => {
  if (borderBoxSize?.length) {
    return { inline: borderBoxSize[0].inlineSize, block: borderBoxSize[0].blockSize }
  } else {
    const styles = window.getComputedStyle(target)
    const paddingWidth = parseFloat(styles.paddingLeft) + parseFloat(styles.paddingRight)
    const paddingHeight = parseFloat(styles.paddingTop) + parseFloat(styles.paddingBottom)
    const borderWidth = parseFloat(styles.borderLeft) + parseFloat(styles.borderRight)
    const borderHeight = parseFloat(styles.borderTop) + parseFloat(styles.borderBottom)
    return widthAndHeightToInlineAndBlock(
      {
        width: contentRect.width + paddingWidth + borderWidth,
        height: contentRect.height + paddingHeight + borderHeight,
      },
      styles.writingMode
    )
  }
}

const getContentBox = ({ contentBoxSize, contentRect, target }: SizeData) => {
  if (contentBoxSize?.length) {
    return { inline: contentBoxSize[0].inlineSize, block: contentBoxSize[0].blockSize }
  } else {
    const styles = window.getComputedStyle(target)
    return widthAndHeightToInlineAndBlock(contentRect, styles.writingMode)
  }
}

const RESIZE_OBSERVER_ROUND = RESIZE_OBSERVER_PRECISION * 10
const roundToPrecision = (value: number) =>
  Math.floor(Math.round(value * RESIZE_OBSERVER_ROUND) / RESIZE_OBSERVER_ROUND)

const widthAndHeightToInlineAndBlock = (
  { width, height }: { width: number; height: number },
  writingMode: CSSStyleDeclaration['writingMode']
) => ({
  inline: writingMode === 'horizontal-tb' ? width : height,
  block: writingMode === 'horizontal-tb' ? height : width,
})
