import { IconButton } from 'design-system/buttons/IconButton/IconButton'
import React from 'react'
import { ContainerCutout } from '../../Components/DesignSystem/Layout/Containers/Containers'
import { StyledHr, StyledVr } from '../../Components/DesignSystem/Typography'
import { MoneyStream } from '../../Utilities/interfaces'
import { EmptyBill, EmptyStream, EmptySubscription } from './consts'

const styledHrHeight = 9
const labelVerticalMargins = 16
export const streamPageHeaderSize = 60 + styledHrHeight + labelVerticalMargins

interface StreamPageHeaderProps {
  setStreamToEdit: (stream: MoneyStream | null, mode: 'edit' | 'add', isPaycheck: boolean) => void
}
export const StreamPageHeader: React.FC<StreamPageHeaderProps> = ({ setStreamToEdit }) => {
  return (
    <ContainerCutout size={streamPageHeaderSize}>
      <div className="d-flex align-items-center w-100 justify-content-between px-3">
        <IconButton
          onClick={() => setStreamToEdit(EmptyStream, 'add', false)}
          title="add income"
          color="cashgreen"
          iconClassName="fak fa-solid-sack-dollar-circle-plus"
        />
        <StyledVr weight={1} size="xxl" />
        <IconButton
          onClick={() => setStreamToEdit(EmptyBill, 'add', false)}
          title="add bill"
          color="mehred"
          iconClassName="far fa-money-check-edit-alt"
        />

        <StyledVr weight={1} size="xxl" />
        <IconButton
          onClick={() => setStreamToEdit(EmptySubscription, 'add', false)}
          title="add subscription"
          color="mehred"
          iconClassName="fak fa-solid-repeat-circle-dollar "
        />
      </div>

      <StyledHr weight={1} color="nearlyclear" className="my-1" />
    </ContainerCutout>
  )
}
