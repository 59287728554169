import { TextSpan } from 'Components/DesignSystem/Typography'
import { MysteryDayType } from 'Providers/MysteryDayProvider/MysteryDayProvider.interfaces'
import { BodyText } from 'design-system/typography/Text/Text'
import React from 'react'
import { ColorType } from 'theme'

export const colorFromMysteryDayType = (mysteryLinkType?: MysteryDayType): ColorType => {
  if (!mysteryLinkType) {
    return 'texttransparent'
  }
  switch (mysteryLinkType) {
    // Mystery Day = Sunny Day of no more debt
    case 'getOutOfDebt':
      return 'yellow'

    case 'saveToQuit':
      return 'orange'

    // Mystery Day = Happy Day, how long until you acquire your item
    case 'getOrganized':
      return 'megapurple'

    // Mystery Day = Breathing Room, how much cushion you have before running out of money
    case 'buildCushion':
      return 'softblue'

    // Mystery Day = not yet determined by user, keep as mystery
    case 'default':
      return 'text'
  }
}

export const backgroundFromMysteryDayType = (mysteryDayType: MysteryDayType) => {
  const opacity = 0.2
  switch (mysteryDayType) {
    case 'getOutOfDebt':
      return `linear-gradient(135deg, rgba(144, 144, 32, ${opacity}), rgba(117, 118, 0, ${opacity}))`
    case 'buildCushion':
      return `linear-gradient(135deg, rgba(100, 190, 179, ${opacity}), rgba(154, 246, 234, ${opacity}))`
    case 'getOrganized':
      return `linear-gradient(135deg, rgba(176, 65, 185, ${opacity}), rgba(236, 121, 243, ${opacity}))`
    case 'saveToQuit':
      return `linear-gradient(135deg, rgba(255, 100, 92, ${opacity}), rgba(255, 157, 143, ${opacity}))`
    case 'default':
      return `rgba(0,0,0,${opacity})`
  }
}

export const iconFromMysteryDayType = (
  mysteryLinkType?: MysteryDayType,
  isActive?: boolean,
  onboardingComplete: boolean = true
) => {
  if (onboardingComplete) {
    return 'fas fa-seedling'
    // return 'fas fa-chart-simple'
  } else {
    if (isActive) {
      return 'far fa-arrow-right-to-arc'
    }

    return 'far fa-arrow-right-to-arc fa-beat-fade'
  }
}

export const MysteryModeButtonTitlesFromMysteryDayType = (
  mysteryDayType: MysteryDayType
): {
  name: string
  title: string
  subtitle: string
  short: string
  intro: string | React.ReactElement
  map: string
  end: string
} => {
  switch (mysteryDayType) {
    case 'getOutOfDebt':
      return {
        name: 'Sunny Day',
        title: 'remove debt',
        subtitle: `Live without shackles`,
        short: 'Sunny',
        map: 'debt',
        end: 'be free',
        intro:
          'This mode will bring attention to how much your debt is costing you in terms of lost time, provide you a reasonable monthly contribution, and show you the path out.',
      }
    case 'saveToQuit':
      return {
        name: 'Launch Day',
        title: 'Take Sabbatical',
        subtitle: 'Quit and set sail',
        short: 'Launch',
        end: 'set sail',
        map: 'launch',
        intro:
          'This mode helps you save for time off and shows you the lifestyle decisions you can make to get there faster. Leave the soul-draining job earlier than you thought possible.',
      }
    case 'getOrganized':
      return {
        name: 'Dream Big',
        title: 'Dream big',
        subtitle: 'Finally make that dream happen',
        short: 'Dream',
        map: 'dream',
        end: 'smile big',
        intro:
          'This mode will focus your savings towards that house down payment, the big trip, or the special day. Pick the thing that is important to you and we will help you get there.',
      }
    case 'buildCushion':
      return {
        name: 'Build Cushion',
        title: 'build cushion',
        subtitle: 'Have peace of mind',
        short: 'Cushion',
        map: 'cushion',
        end: 'breathe easy',
        intro: `This mode will build up your financial resilience. Build an emergency fund, build up a car repair fund, or understand how long your current savings will last you.`,
      }
    case 'default':
      return {
        name: 'Dashboard',
        title: 'Onboarding',
        subtitle: 'Welcome!',
        short: 'Onboard',
        map: 'goal',
        end: '',
        // intro: '',
        intro: (
          <div className="d-flex flex-column">
            <BodyText className="d-inline my-0">
              This page will become your main dashboard.{' '}
              <TextSpan weight={500} color="texttransparent">
                Pick a goal from the above four
              </TextSpan>{' '}
              to learn more. Once you find the mode that fits you best, click Start to begin and
              we'll walk you through setup
            </BodyText>
          </div>
        ),
      }
  }
}

export const labelFromMysteryDayType = (mysteryLinkType?: MysteryDayType) => {
  if (!mysteryLinkType) {
    return 'missing'
  }
  switch (mysteryLinkType) {
    case 'getOutOfDebt':
      return 'sunny day'

    // Mystery Day = Blast Off Day for quiting job
    case 'saveToQuit':
      return 'launch day'

    // Mystery Day = Happy Day, how long until you acquire your item
    case 'getOrganized':
      return 'splurge day'

    // Mystery Day = Breathing Room, how much cushion you have before running out of money
    case 'buildCushion':
      return 'cushion'

    // Mystery Day = not yet determined by user, keep as mystery
    case 'default':
      return 'onboard'
  }
}

export const titleFromMysteryDayType = (mysteryLinkType?: MysteryDayType) => {
  if (!mysteryLinkType) {
    return 'onboard'
  }
  switch (mysteryLinkType) {
    case 'getOutOfDebt':
      return 'Sunny Day'

    // Mystery Day = Blast Off Day for quiting job
    case 'saveToQuit':
      return 'Launch Day'

    // Mystery Day = Happy Day, how long until you acquire your item
    case 'getOrganized':
      return 'Smile Day'

    // Mystery Day = Breathing Room, how much cushion you have before running out of money
    case 'buildCushion':
      return 'Financial Cushion'

    // Mystery Day = not yet determined by user, keep as mystery
    case 'default':
      return 'Onboarding'
  }
}

export const nth = function (d: number) {
  if (d > 3 && d < 21) return 'th'
  switch (d % 10) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}

export const isMobile = () => {
  let check = false
  ;(function (a) {
    if (
      // eslint-disable-next-line
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      // eslint-disable-next-line
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true
  })(navigator.userAgent || navigator.vendor)
  return check
}
