import { useIsAlex } from 'Components/AlexOnlyContent/AlexOnlyContent'
import { ActionItem, FooterActionRow } from 'Components/FooterActionRow/FooterActionRow'
import { useData } from 'Providers/APIDataProvider'
import { useUIState } from 'Providers/UIStateProvider'
import { numberToCurrency } from 'Utilities/currencyFormater'
import { FrequencyType } from 'Utilities/interfaces'
import {
  getMonthlyBills,
  getMonthlyIncome,
  getMonthlySubscriptions,
} from 'Utilities/netWorth/netWorth'
import React from 'react'
import styled from 'styled-components'
import { useGetTotalBillsPaidThisMonth } from './StreamEntry/helpers'

const styledHrHeight = 9
const labelVerticalMargins = 16
export const streamPageHeaderSize = 60 + styledHrHeight + labelVerticalMargins

export const StreamPageFooter: React.FC = () => {
  const { streams } = useData()

  const isAlex = useIsAlex()
  const { complexityMode, setCalendarPageOpen } = useUIState()

  const monthlyIncome = getMonthlyIncome(streams)
  const monthlyBills = getMonthlyBills(streams)
  const monthlySubscriptions = getMonthlySubscriptions(streams)

  const monthlyOnlyTotal =
    getMonthlyBills(streams.filter((s) => s.frequency === FrequencyType.monthly)) +
    getMonthlySubscriptions(streams.filter((s) => s.frequency === FrequencyType.monthly))

  const { totalPaid } = useGetTotalBillsPaidThisMonth()

  const footerItems: ActionItem[] = [
    {
      color: 'cashgreen',
      title: 'Income',
      type: 'simple',
      iconClassName: 'far fa-sack-dollar',
      onClick: () => {},
      subtitle: numberToCurrency(monthlyIncome, true, false),
    },
    {
      color: 'mehred',
      title: isAlex && complexityMode === 'advanced' ? 'Total' : 'Bills + Subscriptions',
      type: 'simple',
      iconClassName: 'fak fa-regular-money-check-dollar-circle-minus',
      onClick: () => {},
      subtitle: numberToCurrency(monthlyBills + monthlySubscriptions, true, false),
    },
  ]

  if (isAlex && complexityMode === 'advanced') {
    footerItems.push({
      color: 'mehred',
      title: `Monthly: ${numberToCurrency(monthlyOnlyTotal)}`,
      type: 'content',
      // iconContent: <ProgressIndicator />,
      iconContent: <BillsProgress monthlyBills={monthlyOnlyTotal} monthlyBillsPaid={totalPaid} />,
      onClick: () => setCalendarPageOpen(true),
      subtitle: `Left: ${numberToCurrency(monthlyOnlyTotal - totalPaid)}`,
    })
  }

  return <FooterActionRow items={footerItems} />
}

interface BillsProgressProps {
  monthlyBills: number
  monthlyBillsPaid: number
}
export const BillsProgress: React.FC<BillsProgressProps> = ({ monthlyBills, monthlyBillsPaid }) => {
  return (
    <CustomProgressContainer>
      <progress max={Math.abs(monthlyBills)} value={Math.abs(monthlyBillsPaid)} />
    </CustomProgressContainer>
  )
}

const CustomProgressContainer = styled.div`
  /* display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem; */
  padding: 0 0.5rem;

  progress {
    max-width: 100px;
    margin: 0 0.5rem;
    -webkit-appearance: none;
    appearance: none;
    height: 10px;
    border-radius: 10px;
    background-color: ${(props) => props.theme.colors.nearlyclear};
  }
  progress[value]::-webkit-progress-bar {
    background-color: ${(props) => props.theme.colors.nearlyclear};
    border-radius: 10px;
    box-shadow: none;
    transition: all ease-in-out 0.3s;
  }

  progress[value]::-webkit-progress-value {
    background-color: ${(props) => props.theme.colors.mehred};
    border-radius: 10px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
    transition: all ease-in-out 0.3s;
  }
`
