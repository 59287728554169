import { AlexOnlyContent } from 'Components/AlexOnlyContent/AlexOnlyContent'
import { AppExternalMetadata } from 'Pages/SettingsPage/AppExternalMetadata'
import React from 'react'
import { GetAllData } from './GetAllData'
import { PlaidEnvToggle } from './PlaidEnvToggle'
import { RefreshAccountBalances } from './RefreshAccountBalances'
import { RefreshTransactions } from './RefreshTransactions'
import { TokenManagement } from './TokenManagement'
import { UserLoggedInStatus } from './UserLoggedInStatus'

export const AlexOnlySettings: React.FC = () => {
  return (
    <AlexOnlyContent>
      <AppExternalMetadata />
      <RefreshAccountBalances />
      <RefreshTransactions />
      <PlaidEnvToggle />
      <TokenManagement />
      <UserLoggedInStatus />
      <GetAllData />
    </AlexOnlyContent>
  )
}
