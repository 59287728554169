import { TOP_CONTAINER_PADDING_FOR_NOTCH } from 'Components/DesignSystem/Layout/Containers/helpers'
import styled from 'styled-components/macro'

export const AppDarkOverlay = styled.div<{ active: boolean }>`
  background-color: rgba(0, 0, 0, 0.7);
  height: 100vh;
  position: absolute;
  margin-top: -3rem;
  opacity: ${(props) => (props.active ? 1 : 0)};
  transition: all ease-in-out 0.3s;
  width: 100vw;
  pointer-events: none;
  z-index: 210;
`

export const LocalHostNotchBanner = styled.div`
  position: fixed;
  top: 0;
  height: ${TOP_CONTAINER_PADDING_FOR_NOTCH}px;
  width: 100vw;
  z-index: 1000;
  background: ${(props) => props.theme.colors.backgrounddark}; ;
`
