import { getBankItemFromAccount } from 'Pages/CreditCardPage/dataHelpers'
import { useFinancialData } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import { useUIState } from 'Providers/UIStateProvider'
import color from 'color'
import { AccountBase } from 'plaid'
import { colorPalletes } from 'theme'

export const useGetInstitutionMetadata = (a: AccountBase | string) => {
  let accountId: string
  const { flatAccounts, accountsData } = useFinancialData()
  const { activeTheme } = useUIState()

  /* In this case, we have an account_id passed in, we should look up the account */
  if (typeof a === 'string') {
    const _account = flatAccounts.find((account) => account.account_id === a)
    if (!_account) {
      console.error("Couldn't find account with id: ", a)
      return {
        institutionColor: colorPalletes[activeTheme].text,
        institutionName: 'Unknown',
      }
      // throw new Error(`Couldn't find account with id: ${a}`)
    }
    accountId = a
  } else {
    accountId = a.account_id
  }

  const institutions = accountsData?.data?.institutions || []
  const accounts = accountsData?.data?.accounts
  const items = accountsData?.data?.items
  const bankForAccount = getBankItemFromAccount(accounts, items, accountId)

  const institution = institutions.find(
    (i) => i.institution.institution_id === bankForAccount?.item?.institution_id
  )

  let institutionColor

  if (institution?.institution.primary_color) {
    institutionColor = color(institution?.institution.primary_color || '').toString()
  } else {
    institutionColor = colorPalletes[activeTheme].second
  }
  return {
    institutionColor,
    institutionName: institution?.institution.name,
  }
}
