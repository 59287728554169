import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import React from 'react'
import { ContainerDiv } from '../../../../Components/DesignSystem/Layout/Containers/Containers'
import { useData } from '../../../../Providers/APIDataProvider'
import { getMonthlyIncome } from '../../../../Utilities/netWorth/netWorth'
import { BackButton } from '../../MysteryDayBackButton'
import { ForwardButton } from '../../MysteryDayForwardButton'
import { SubPageProps } from '../../MysteryPage'
import { ContributionRangePanel } from './ContributionRangePanel'
import { RecapPanel } from './RecapPanel'

export const RELAXED_RECOMMENDED_AGGRESIVE_SPREAD = 0.35

export const TotalCostOfLivingComplete: React.FC<SubPageProps> = () => {
  const { streams, monthlyBurnRate } = useData()

  const {
    setMonthlyContribution,
    mysteryMode,
    cushionSubMode,
    saveUserOnboardingSetupLoading,
  } = useMysteryDay()

  const monthlyIncome = getMonthlyIncome(streams)

  const baseRecommendedAmount = monthlyIncome - Math.abs(monthlyBurnRate)

  const notFeasible =
    baseRecommendedAmount < 0 &&
    mysteryMode !== 'buildCushion' &&
    cushionSubMode !== 'extendSavings'

  React.useEffect(() => {
    setMonthlyContribution(baseRecommendedAmount)
  }, [baseRecommendedAmount, setMonthlyContribution])

  return (
    <ContainerDiv
      className="justify-content-between align-items-center py-3"
      style={{
        marginTop: '-2rem',
      }}>
      <ContainerDiv className="justify-content-start align-items-center">
        <RecapPanel />
        <ContributionRangePanel />
      </ContainerDiv>

      <div className="d-flex justify-content-between align-items-center w-100">
        <BackButton />
        <ForwardButton
          disabledPopupTitle="Not Feasible"
          disabledPopupBody="Your Monthly Cost of Living is higher than your Income. Without disposable income, we cannot provide an effective Dashboard."
          disabled={notFeasible || saveUserOnboardingSetupLoading}
          title={saveUserOnboardingSetupLoading ? 'Saving...' : 'Complete 🎉'}
          end
        />
      </div>
    </ContainerDiv>
  )
}
