import { Icon, TextSpan } from '../../../Components/DesignSystem/Typography'
import {
  getColorFromAccountType,
  getIconFromAccountType,
} from '../../../Providers/FinancialDataProvider/helpers'

import { cleanCurrency, numberToCurrency } from 'Utilities/currencyFormater'
import React from 'react'

import { ManualAccount } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import { useUIState } from 'Providers/UIStateProvider'
import { ManualAccountRowDiv } from './ManualAccountRow.components'

interface ManualAccountRowProps {
  manualAccount: ManualAccount
}

export const ManualAccountRow: React.FC<ManualAccountRowProps> = ({ manualAccount }) => {
  const { setAddManualAccountPageOpen } = useUIState()
  const { type, name, amount, transactions } = manualAccount
  return (
    <>
      <div
        className="d-flex w-100"
        style={{ position: 'relative' }}
        onClick={() => {
          setAddManualAccountPageOpen(manualAccount)
        }}>
        <ManualAccountRowDiv data-testid={`stream-${'subscriptions'}-entry-row`}>
          <div className="d-flex flex-row align-items-start">
            <Icon className={getIconFromAccountType(type)} color={getColorFromAccountType(type)} />
          </div>
          <div className="d-flex align-items-start flex-column ml-2">
            <TextSpan
              size="xxs"
              className="my-0 text-nowrap"
              weight={500}
              color="text"
              style={{
                textDecoration: 'underline',
              }}>
              {name}
            </TextSpan>
            <div className="d-flex align-items-center flex-row">
              <TextSpan color={getColorFromAccountType(type)} weight={700} size="xs">
                {transactions?.length || 0}
              </TextSpan>
              <TextSpan color="textsupertransparent" size="xxs" className="ml-1">
                transactions
              </TextSpan>
            </div>
          </div>

          <div className="d-flex align-items-end flex-row justify-content-end">
            {/* <TextSpan size="xxs" className="my-0 mr-1" weight={200} color="textsupertransparent">
              {type === 'Credit Card' ? 'Current' : 'Available'} Balance:
            </TextSpan> */}
            <TextSpan size="xxs" color={'text'} className="my-0">
              {numberToCurrency(cleanCurrency(amount), true, true)}
            </TextSpan>
          </div>
        </ManualAccountRowDiv>
      </div>
    </>
  )
}
