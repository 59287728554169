import { useLocalStorageSyncedState } from 'Hooks/useLocalStorageSyncedState'
import { FILTER_TYPE } from 'Pages/SpendingPage/AdvancedTransactionView/helpers'
import { PLAID_ENV_TYPE } from 'api/plaid'
import React, { useCallback, useState } from 'react'
import { SPLURV_LOCAL_STORAGE_PREFIX } from '../Hooks/useLocalStorageSync'
import { CreditCardGroupingEnum } from '../Utilities/creditCardSpending/interfaces'
import { Account } from '../Utilities/interfaces'
import { ColorType, colorPalletes } from '../theme'
import { ManualAccount } from './FinancialDataProvider/FinancialDataProvider'
import { MysteryDayType } from './MysteryDayProvider/MysteryDayProvider.interfaces'

// import { useFinancialData } from './FinancialDataProvider'

type TrajectoryMode = 'flat' | 'calendar' | 'chart'
type SectionType = 'list' | 'today' | 'reoccuring'

type ExistingAccountOpenStatus = false | MysteryDayType

export type ComplexityModeType = 'simple' | 'complex' | 'advanced'

interface TopLevelState {
  messagePopupContent: string
  messagePopupColors: {
    fgColor: ColorType
    bgColor: ColorType
  }
  popup: React.ReactElement | null
  setPopup: (c: React.ReactElement | null) => void

  setMessagePopupContent: (msg: string) => void
  setMessagePopupColors: ({ fgColor, bgColor }: { fgColor: ColorType; bgColor: ColorType }) => void

  setModalContent: (c: React.ReactElement | null) => void
  modalContent: React.ReactElement | null

  errorPopupContent: string
  setErrorPopupContent: (msg: string) => void

  userDrivenPlaidEnv: PLAID_ENV_TYPE
  setUserDrivenPlaidEnv: (env: PLAID_ENV_TYPE) => void

  advancedMode: boolean
  setAdvancedMode: (enabled: boolean) => void
  creditCardAverageMethod: CreditCardGroupingEnum
  setCreditCardAverageMethod: (method: CreditCardGroupingEnum) => void

  activeTheme: keyof typeof colorPalletes
  setActiveTheme: (theme: keyof typeof colorPalletes) => void

  addManualAccountPageOpen: false | ManualAccount
  setAddManualAccountPageOpen: (item: false | ManualAccount) => void

  settingsPageOpen: boolean
  setSettingsPageOpen: (val: boolean) => void

  calendarPageOpen: boolean
  setCalendarPageOpen: (val: boolean) => void

  cashFlowPageOpen: boolean
  setCashFlowPageOpen: (val: boolean) => void

  addCardPageOpen: false | { item_id: string; logo: string; name: string; color: string } | ''
  // HACK:
  // this is a mess but here's how it works
  // - false = page is closed
  // - { item_id: string; logo: string } = page is open and we have an item_id which means update mode for a bank item
  // - '' = page is open and we don't have an item_id which means new card mode
  setAddCardPageOpen: (
    item: false | { item_id: string; logo: string; name: string; color: string } | ''
  ) => void
  activeCard: Account | null
  setActiveCard: (card: Account) => void

  accountsPageOpen: boolean
  setAccountsPageOpen: (open: boolean) => void

  trajectoryMode: TrajectoryMode
  setTrajectoryMode: (mode: TrajectoryMode) => void

  creditCardAnalysisPage: SectionType
  setCreditCardAnalysisPage: (page: SectionType) => void

  currentBalancePageOpen: boolean
  setCurrentBalancePageOpen: (open: boolean) => void

  pickExistingAccountPageOpen: ExistingAccountOpenStatus
  setPickExistingAccountPageOpen: (open: ExistingAccountOpenStatus) => void

  complexityMode: ComplexityModeType
  setComplexityMode: (mode: ComplexityModeType) => void

  showTransactionsOverlay: boolean
  setShowTransactionOverlay: (bool: boolean) => void

  presetTransactionFilterCategory: string
  setPresetTransactionFilterCategory: (category: string) => void

  presetTransactionFilterType: FILTER_TYPE
  setPresetTransactionFilterType: (filterType: FILTER_TYPE) => void

  presetTransactionFilterReflectionNeeded: boolean
  setPresetTransactionFilterReflectionNeeded: (needed: boolean) => void

  addCustomEnvelopePageOpen: false | CallbackAndValueState
  setAddCustomEnvelopePageOpen: (open: false | CallbackAndValueState) => void

  subscriptionPageOpen: false | CallbackAndValueState
  setSubscriptionPageOpen: (open: false | CallbackAndValueState) => void
}

export interface CallbackAndValueState {
  val: string
  cb: Function
}

export const defaultData: TopLevelState = {
  messagePopupContent: '',

  messagePopupColors: { fgColor: 'text', bgColor: 'seconddark' },
  popup: null,
  setPopup: () => {},
  setMessagePopupContent: (msg: string) => {},
  setMessagePopupColors: ({ fgColor, bgColor }) => {},

  setModalContent: (c: React.ReactElement | null) => {},
  modalContent: null,

  addManualAccountPageOpen: false,
  setAddManualAccountPageOpen: (item: false | ManualAccount) => {},

  userDrivenPlaidEnv: 'development',
  setUserDrivenPlaidEnv: (env: PLAID_ENV_TYPE) => {},

  settingsPageOpen: false,
  setSettingsPageOpen: (val: boolean) => {},

  accountsPageOpen: false,
  setAccountsPageOpen: (open: boolean) => {},

  calendarPageOpen: false,
  setCalendarPageOpen: (val: boolean) => {},

  cashFlowPageOpen: false,
  setCashFlowPageOpen: (val: boolean) => {},

  errorPopupContent: '',
  setErrorPopupContent: (msg: string) => {},
  advancedMode: true,
  setAdvancedMode: (enabled: boolean) => {},
  creditCardAverageMethod: CreditCardGroupingEnum.thisMonth,
  setCreditCardAverageMethod: (method: CreditCardGroupingEnum) => {},
  activeTheme: 'newDark',
  setActiveTheme: (theme) => {},
  addCardPageOpen: false,
  setAddCardPageOpen: (item: false | { item_id: string; logo: string } | '') => {},

  activeCard: null,
  setActiveCard: (card: Account) => {},

  trajectoryMode: 'chart',
  setTrajectoryMode: () => {},

  creditCardAnalysisPage: 'today',
  setCreditCardAnalysisPage: (page: SectionType) => {},

  currentBalancePageOpen: false,
  setCurrentBalancePageOpen: (open: boolean) => {},

  pickExistingAccountPageOpen: false,
  setPickExistingAccountPageOpen: (open: ExistingAccountOpenStatus) => {},

  complexityMode: 'simple',
  setComplexityMode: (mode: ComplexityModeType) => {},

  showTransactionsOverlay: false,
  setShowTransactionOverlay: (bool: boolean) => {},

  presetTransactionFilterCategory: '',
  setPresetTransactionFilterCategory: (category: string) => {},

  presetTransactionFilterType: 'both',
  setPresetTransactionFilterType: (filterType: FILTER_TYPE) => {},

  presetTransactionFilterReflectionNeeded: false,
  setPresetTransactionFilterReflectionNeeded: (needed: boolean) => {},

  addCustomEnvelopePageOpen: false,
  setAddCustomEnvelopePageOpen: (open: false | CallbackAndValueState) => {},

  subscriptionPageOpen: false,
  setSubscriptionPageOpen: (open: false | CallbackAndValueState) => {},
}

interface Props {
  children: React.ReactNode
}

const UIStateContext = React.createContext<TopLevelState>(defaultData)

export const useUIState = () => React.useContext(UIStateContext)

/**
 * This provider keeps store of any UI state that is mostly ephemeral (pages that are open, UI popups, add card page open/close, settings page open, etc)
 * NOTE: We could likely get rid of a lot of this state as it is not used anymore
 * @param
 * @returns
 */
export const UIStateProvider = ({ children }: Props) => {
  // const { creditCards } = useFinancialData()

  const localStorageTheme = localStorage.getItem(`${SPLURV_LOCAL_STORAGE_PREFIX}_ACTIVE_THEME`)
  if (localStorageTheme && localStorageTheme !== 'undefined') {
    defaultData.activeTheme = JSON.parse(localStorageTheme)
  }

  const localStorageActiveCard = localStorage.getItem(`${SPLURV_LOCAL_STORAGE_PREFIX}_ACTIVE_CARD`)
  if (localStorageActiveCard && localStorageActiveCard !== 'undefined') {
    // defaultData.activeCard = JSON.parse(localStorageActiveCard)
  }
  const [creditCardAnalysisPage, _setCreditCardAnalysisPage] = useState<SectionType>(
    defaultData.creditCardAnalysisPage
  )

  const [activeTheme, _setActiveTheme] = useState<keyof typeof colorPalletes>(
    defaultData.activeTheme
  )

  const setActiveTheme = (activeTheme: keyof typeof colorPalletes) => {
    _setActiveTheme(activeTheme)
    localStorage.setItem(`${SPLURV_LOCAL_STORAGE_PREFIX}_ACTIVE_THEME`, JSON.stringify(activeTheme))
  }

  const [creditCardAverageMethod, setCreditCardAverageMethod] = useState<CreditCardGroupingEnum>(
    defaultData.creditCardAverageMethod
  )

  const [activeCard, _setActiveCard] = useState<Account | null>(defaultData.activeCard)

  const setActiveCard = useCallback(
    (activeCard: Account) => {
      _setActiveCard(activeCard)
      localStorage.setItem(`${SPLURV_LOCAL_STORAGE_PREFIX}_ACTIVE_CARD`, JSON.stringify(activeCard))
    },
    [_setActiveCard]
  )

  const [addCardPageOpen, setAddCardPageOpen] = useState<
    false | { item_id: string; logo: string; name: string; color: string } | ''
  >(defaultData.addCardPageOpen)

  const [addManualAccountPageOpen, setAddManualAccountPageOpen] = useState<false | ManualAccount>(
    defaultData.addManualAccountPageOpen
  )

  const [settingsPageOpen, setSettingsPageOpen] = useLocalStorageSyncedState<boolean>(
    false,
    'user-config-settings-page-open'
  )

  const [accountsPageOpen, setAccountsPageOpen] = useLocalStorageSyncedState<boolean>(
    false,
    'accounts-page-open'
  )

  const [userDrivenPlaidEnv, setUserDrivenPlaidEnv] = useLocalStorageSyncedState<PLAID_ENV_TYPE>(
    'development',
    'user-config-plaid-env'
  )

  const [calendarPageOpen, setCalendarPageOpen] = useLocalStorageSyncedState<boolean>(
    false,
    'user-config-calendar-page-open'
  )

  const [cashFlowPageOpen, setCashFlowPageOpen] = useLocalStorageSyncedState<boolean>(
    false,
    'user-config-cashflow-page-open'
  )

  const [showTransactionsOverlay, setShowTransactionOverlay] = useLocalStorageSyncedState<boolean>(
    false,
    'show-transactions-overlay'
  )

  const [addCustomEnvelopePageOpen, setAddCustomEnvelopePageOpen] = useLocalStorageSyncedState<
    false | CallbackAndValueState
  >(false, 'add-custom-envelope-page-open')

  const [subscriptionPageOpen, setSubscriptionPageOpen] = useLocalStorageSyncedState<
    false | CallbackAndValueState
  >(false, 'subscription-page-open')

  const [
    presetTransactionFilterCategory,
    setPresetTransactionFilterCategory,
  ] = useLocalStorageSyncedState<string>('', 'preset-transaction-filter-category')

  const [
    presetTransactionFilterType,
    setPresetTransactionFilterType,
  ] = useLocalStorageSyncedState<FILTER_TYPE>('both', 'preset-transaction-filter-type')

  const [
    presetTransactionFilterReflectionNeeded,
    setPresetTransactionFilterTypeReflectionNeeded,
  ] = useLocalStorageSyncedState<boolean>(false, 'preset-transaction-filter-reflection-needed')

  const [complexityMode, setComplexityMode] = useLocalStorageSyncedState<
    TopLevelState['complexityMode']
  >('simple', 'user-config-complexity-mode')

  const [advancedMode, setAdvancedMode] = useState<boolean>(defaultData.advancedMode)

  const [trajectoryMode, setTrajectoryMode] = useState<TrajectoryMode>(defaultData.trajectoryMode)

  const [currentBalancePageOpen, setCurrentBalancePageOpen] = useState<boolean>(
    defaultData.currentBalancePageOpen
  )

  const setCreditCardAnalysisPage = (page: SectionType) => {
    _setCreditCardAnalysisPage(page)
  }

  const [messagePopupContent, setMessagePopupContent] = useState<string>(
    defaultData.messagePopupContent
  )

  const [popup, setPopup] = useState<React.ReactElement | null>(defaultData.popup)
  const [modalContent, setModalContent] = useState<React.ReactElement | null>(
    defaultData.modalContent
  )

  const [messagePopupColors, setMessagePopupColors] = useState<{
    fgColor: ColorType
    bgColor: ColorType
  }>(defaultData.messagePopupColors)
  const [errorPopupContent, setErrorPopupContent] = useState<string>(defaultData.errorPopupContent)

  const [
    pickExistingAccountPageOpen,
    setPickExistingAccountPageOpen,
  ] = React.useState<ExistingAccountOpenStatus>(defaultData.pickExistingAccountPageOpen)

  return (
    <UIStateContext.Provider
      value={{
        /*--  VALUES --*/
        // Component Popup that slides from bottom
        popup,
        setPopup,
        // Modal Popup that appears from side
        modalContent,
        setModalContent,
        // Message Popup that slides from bottom
        messagePopupColors,
        messagePopupContent,
        // Error Message
        errorPopupContent,
        // Advanced Mode
        advancedMode,
        // Active Theme
        activeTheme,
        // Active Card
        activeCard,
        // Credit Card Average Method
        creditCardAverageMethod,
        // Trajectory Mode
        trajectoryMode,
        // Add Card Page Open
        addCardPageOpen,
        // Credit Card Analysis Page
        creditCardAnalysisPage,

        addManualAccountPageOpen,
        // Current Balance Page Open
        currentBalancePageOpen,

        settingsPageOpen,
        setSettingsPageOpen,

        accountsPageOpen,
        setAccountsPageOpen,

        calendarPageOpen,

        userDrivenPlaidEnv,
        setUserDrivenPlaidEnv,
        setCalendarPageOpen,

        cashFlowPageOpen,
        setCashFlowPageOpen,
        /*--  SETTERS --*/
        // Add Manual Account Page
        setAddManualAccountPageOpen,
        // Message Popup
        setMessagePopupColors,
        setMessagePopupContent,
        // Error Message
        setErrorPopupContent,
        // Advanced Mode
        setAdvancedMode,
        // Active Theme
        setActiveTheme,
        // Active Card
        setActiveCard,
        // Credit Card Average Method
        setCreditCardAverageMethod,
        // Trajectory Mode
        setTrajectoryMode,
        // Add Card Page Open
        setAddCardPageOpen,
        // Credit Card Analysis Page
        setCreditCardAnalysisPage,
        // Current Balance Page Open
        setCurrentBalancePageOpen,

        pickExistingAccountPageOpen,
        setPickExistingAccountPageOpen,

        complexityMode,
        setComplexityMode,

        showTransactionsOverlay,
        setShowTransactionOverlay,

        presetTransactionFilterCategory,
        setPresetTransactionFilterCategory,

        presetTransactionFilterType,
        setPresetTransactionFilterType,

        presetTransactionFilterReflectionNeeded,
        setPresetTransactionFilterReflectionNeeded: setPresetTransactionFilterTypeReflectionNeeded,

        addCustomEnvelopePageOpen,
        setAddCustomEnvelopePageOpen,

        subscriptionPageOpen,
        setSubscriptionPageOpen,
      }}>
      {children}
    </UIStateContext.Provider>
  )
}
