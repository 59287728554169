import { StyledVr } from 'Components/DesignSystem/Typography'
import React from 'react'
import styled from 'styled-components'

import { UserAvatar } from 'Components/UserAvatar'
import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import { MysteryPageType } from 'Providers/MysteryDayProvider/MysteryDayProvider.interfaces'
import { BigGoal } from './BigGoal'
import { LogoText } from './LogoText'
import { OnboardingOverview } from './OnboardingOverview'

import logo from 'Logos/splurv_logo_transparent.png'

const StreamPageHeaderDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`

const mainOnboardingPages: MysteryPageType[] = [
  'cost-of-living-variable',
  'cost-of-living-fixed',
  'paycheck-or-income',
  'savings-total',
]

export const HeaderContent: React.FC = () => {
  const { onboardingDone, page } = useMysteryDay()

  const intoMainOnboarding = mainOnboardingPages.includes(page)

  return (
    <StreamPageHeaderDiv style={{ minHeight: '86px' }}>
      <div className="d-flex align-items-center justify-content-center w-100 flex-column">
        {onboardingDone ? (
          <div className="d-flex flex-row align-items-center mt-2 justify-content-between w-100">
            <BigGoal />
            {/* <MCOL /> */}
            <StyledVr color="nearlyclear" className="mx-2" size="xl" weight={1} />
            <UserAvatar />
          </div>
        ) : (
          <div className={`d-flex flex-row align-items-center mt-2 justify-content-between w-100`}>
            <div className="position-relative">
              <img
                referrerPolicy="no-referrer"
                alt=""
                style={{
                  // height: '56px',
                  height: intoMainOnboarding ? '32px' : '56px',
                  transition: 'all ease-in-out .3s',
                  // opacity: intoMainOnboarding ? 0 : 1,
                }}
                src={logo}
                className=""
              />
            </div>
            {intoMainOnboarding ? <OnboardingOverview /> : <LogoText />}
            {/* </div> */}
            <UserAvatar size={intoMainOnboarding ? 24 : undefined} />
          </div>
        )}
      </div>
    </StreamPageHeaderDiv>
  )
}
