import { Icon, TextSpan } from 'Components/DesignSystem/Typography'
import React from 'react'
import { ColorType, FontSizeType } from 'theme'

interface Props {
  onClick: React.MouseEventHandler<HTMLDivElement>
  onDisabledClick?: React.MouseEventHandler<HTMLDivElement>
  disabled?: boolean
  title: string
  color: ColorType
  iconClassName: string
  buttonClassName?: string
  orientation?: 'horizontal' | 'vertical'
  size?: 'small' | 'medium' | 'large'
}

const IconButtonSizeToDetailedSizes: {
  [key in 'small' | 'medium' | 'large']: { textSize: FontSizeType; iconSize: FontSizeType }
} = {
  small: {
    iconSize: 'md',
    textSize: 'xxs',
  },
  medium: {
    iconSize: 'xl',
    textSize: 'xs',
  },
  large: {
    iconSize: 'xxl',
    textSize: 'md',
  },
}

export const IconButton: React.FC<Props> = ({
  onClick,
  disabled,
  title,
  color,
  onDisabledClick,
  iconClassName,
  buttonClassName,
  orientation = 'vertical',
  size = 'medium',
}) => {
  const [buttonPressed, setButtonPressed] = React.useState<boolean>(false)
  const { iconSize, textSize } = IconButtonSizeToDetailedSizes[size]
  return (
    <div
      onTouchStart={() => setButtonPressed(true)}
      onTouchEnd={() => setButtonPressed(false)}
      onTouchCancel={() => setButtonPressed(false)}
      onDragLeave={() => setButtonPressed(false)}
      onClick={disabled ? onDisabledClick : onClick}
      className={`d-flex flex-${
        orientation === 'vertical' ? 'column' : 'row'
      } align-items-center justify-content-start ${buttonClassName}`}
      style={{
        // flex: 1,
        borderRadius: '8px',
        transition: 'opacity ease-in-out .3s',
        opacity: buttonPressed ? 0.5 : 1,
        cursor: 'pointer',
      }}>
      <Icon
        size={iconSize}
        color={disabled ? 'textsupertransparent' : color}
        className={`${iconClassName} mx-2`}
      />
      <TextSpan
        className={`my-1 text-center`}
        color={disabled ? 'textsupertransparent' : color}
        weight={600}
        size={textSize}>
        {title.toLowerCase()}
      </TextSpan>
    </div>
  )
}
