import { TextBold } from 'Components/DesignSystem/Typography'
import React from 'react'

import splurvLogo from 'Logos/splurv_logo_transparent.png'
import splurvLogoWhite from 'Logos/splurv_logo_transparent_white_good.png'
import { ColorType } from 'theme'

interface Props {
  color?: ColorType
}
export const WelcomeToSplurv: React.FC<Props> = ({ color = 'logoSecond' }) => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center w-100 ">
      <img
        alt=""
        style={{
          transition: 'all ease-in-out 0.3s',
          width: '30vw',
        }}
        src={color === 'text' ? splurvLogoWhite : splurvLogo}
        className=""
      />
      <TextBold
        data-testid="welcome-to-splurv-login-cta"
        color={color}
        size="xl"
        style={{ textAlign: 'center', lineHeight: '2rem' }}>
        Welcome to Splurv
      </TextBold>
    </div>
  )
}
