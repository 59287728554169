import { AccountsGetResponse, ItemGetResponse } from 'plaid'

export const getAccountsForBankItem = (accounts: AccountsGetResponse[], bankItemId: string) => {
  return (
    accounts
      ?.filter((account) => account.item.item_id === bankItemId)
      .map((a) => a.accounts)
      ?.flat() || []
  )
}

export const getBankItemFromAccount = (
  accounts: AccountsGetResponse[] | undefined,
  banks: ItemGetResponse[] | undefined,
  accountId: string
) => {
  const itemIdForAccount = accounts?.find((accountsResponse) =>
    accountsResponse.accounts.find((a) => a.account_id === accountId || a.name === accountId)
  )?.item.item_id
  const bank = banks?.find((bank) => bank?.item?.item_id === itemIdForAccount)
  return bank
}

export const getItemIdForAccountId = (
  accounts: AccountsGetResponse[] | undefined,
  accountId: string
) => {
  const itemIdForAccount = accounts?.find((accountsResponse) =>
    accountsResponse.accounts.find((a) => a.account_id === accountId || a.name === accountId)
  )?.item.item_id

  return itemIdForAccount
}
