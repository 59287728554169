import { useFinancialData } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import { cleanCurrency } from 'Utilities/currencyFormater'

export const useGetDashboardValue = () => {
  const { debtAmount, linkedAccount, linkedAccounts } = useMysteryDay()

  const { flatAccounts, currentSavings } = useFinancialData()

  const linkedAccountHydrated = flatAccounts.find(
    (fa) => fa.account_id === linkedAccount?.accountId
  )

  const linkedAccountsHydrated = linkedAccounts.map((la) =>
    flatAccounts.find((fa) => fa.account_id === la?.accountId)
  )

  const currentBalance = linkedAccountHydrated
    ? linkedAccountHydrated.balances.current || 0
    : linkedAccountsHydrated.reduce((acc, la) => acc + (la?.balances?.current || 0), 0)

  const currentDebtAmount = currentBalance * -1 || cleanCurrency(debtAmount)
  return { currentDebtAmount, currentSavings }
}
