import styled from 'styled-components/macro'
import { ColorType } from 'theme'

interface Props {
  bgColor?: ColorType
  fgColor?: ColorType
}
export const Content = styled.div<Props>`
  width: 100%;
  height: calc(75%);
  max-height: calc(75%);
  min-height: calc(75%);
  z-index: 10010;
  position: absolute;
  background: ${(props) =>
    props.bgColor ? props.theme.colors[props.bgColor] : props.theme.colors.seconddark};
  color: ${(props) =>
    props.fgColor ? props.theme.colors[props.fgColor] : props.theme.colors.text};
  border-width: 2px;
  border-style: solid;
  border-color: ${(props) =>
    props.fgColor ? props.theme.colors[props.fgColor] : props.theme.colors.text};
  border-bottom: none;
  display: flex;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  flex-direction: column;
  align-items: center;
`
