import { useUIState } from 'Providers/UIStateProvider'
import color from 'color'
import React from 'react'
import Slider from 'react-input-slider'
import { colorPalletes } from 'theme'
import { Icon, StyledVr, TextSpan } from '../../../Components/DesignSystem/Typography'
import { useMysteryDay } from '../../../Providers/MysteryDayProvider/MysteryDayProvider'
import { SliderPanelContainer } from '../components'
import { useSetupSlider } from './hooks'

interface Props {
  slim?: boolean
}
export const DesiredTimeOffSlider: React.FC<Props> = ({ slim = false }) => {
  const { activeTheme } = useUIState()
  const { desiredTimeOff, setDesiredTimeOff } = useMysteryDay()

  const { sliderContainerRef } = useSetupSlider()

  // 60 months = 5 years
  const xmax = 60
  const numberOfIncrements = 60
  const xstep = 1

  const onContributionChange = (e: { x: number; y: number }) => {
    if (e.x < 0) {
      setDesiredTimeOff(1)
    } else {
      setDesiredTimeOff(e.x)
    }
  }

  const yearsOff = Math.floor(desiredTimeOff / 12)
  const monthsOff = desiredTimeOff - yearsOff * 12

  return (
    <SliderPanelContainer>
      <TextSpan className="mr-1 my-0" size={slim ? 'xl' : 'md'} weight={800} color={'orange'}>
        {yearsOff}
        <TextSpan className="ml-1" size="xs" color={'texttransparent'}>
          {' '}
          {`year${yearsOff > 1 || yearsOff === 0 ? 's' : ''}`}
        </TextSpan>

        <StyledVr color="orange" weight={1} className="mx-1" size="sm" />

        {`${monthsOff}`}
        <TextSpan className="ml-1" size="xs" color={'texttransparent'}>
          {`month${monthsOff > 1 || monthsOff === 0 ? 's' : ' '}`}
        </TextSpan>
      </TextSpan>{' '}
      <div className="w-100 d-flex align-items-center justify-content-between flex-row">
        <Icon
          className="fal fa-minus-circle mr-4"
          size="xxl"
          color="texttransparent"
          onClick={() => {
            const newTimeOffValue =
              desiredTimeOff - Math.floor(Number(xmax || 1) / numberOfIncrements)
            if (newTimeOffValue < 0) {
              setDesiredTimeOff(0)
            } else {
              setDesiredTimeOff(newTimeOffValue)
            }
          }}
        />

        <div
          ref={sliderContainerRef}
          style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 'calc(100% - 6rem)',
          }}>
          <Slider
            x={desiredTimeOff}
            onChange={onContributionChange}
            xmin={0}
            xmax={xmax}
            xstep={xstep}
            axis="x"
            styles={{
              track: {
                transition: 'all ease-in-out .3s',
                backgroundColor: colorPalletes[activeTheme].nearlyclear,
                width: 'calc(100%)',
                height: 10,
              },
              active: {
                transition: 'background-color ease-in-out .3s',
                backgroundColor: colorPalletes[activeTheme].orange,
              },

              thumb: {
                width: 28,
                height: 28,
                opacity: 1,
                transition: 'all ease-in-out .3s',
                zIndex: 20,
                boxShadow: 'rgb(0 0 0 / 30%) 0px 0px 4px 2px',

                borderColor: colorPalletes[activeTheme]['orange'],
                borderWidth: '2px',
                borderStyle: 'solid',
                backgroundColor: color(colorPalletes[activeTheme]['orange']).alpha(0.6).toString(),
              },
            }}
          />
        </div>

        <Icon
          className="fal fa-plus-circle ml-4"
          size="xxl"
          color="texttransparent"
          onClick={() => {
            const newTimeOffValue =
              desiredTimeOff + Math.floor(Number(xmax || 1) / numberOfIncrements)
            if (newTimeOffValue > xmax) {
              setDesiredTimeOff(xmax)
            } else {
              setDesiredTimeOff(newTimeOffValue)
            }
          }}
        />
      </div>
      <div
        style={{ position: 'relative' }}
        className="w-100 d-flex align-items-start justify-content-between px-5 ">
        <TextSpan
          style={{ alignSelf: 'flex-start', paddingLeft: '0rem' }}
          color="texttransparent"
          weight={600}
          className="mt-0"
          size="xxs">
          0 months
        </TextSpan>
        <TextSpan color="texttransparent" className="mt-0" weight={600} size="xxs">
          {xmax} months
        </TextSpan>
      </div>
    </SliderPanelContainer>
  )
}
