import { transactionTimeFilterFunction } from 'Pages/SpendingPage/AdvancedTransactionView/helpers'
import { useData } from 'Providers/APIDataProvider'
import { useFinancialData } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import { useUserCustomSettings } from 'Providers/UserCustomSettingsProvider/UserCustomSettingsProvider'
import { FrequencyType } from 'Utilities/interfaces'
import { useMemo } from 'react'

export const useGetTotalBillsPaidThisMonth = () => {
  const { streams } = useData()
  const { transactions } = useFinancialData()
  const { settings } = useUserCustomSettings()

  const thisMonthTransactionIds = transactions
    .filter((t) => transactionTimeFilterFunction(t, 'thisMonth'))
    .map((t) => t.transaction_id)

  const thisMonthPendingTransactionIds = transactions
    .filter((t) => transactionTimeFilterFunction(t, 'thisMonth'))
    .map((t) => t.pending_transaction_id)

  const thisMonthTransactionMetadata = Object.entries(settings?.transactionsMetadata || {}).filter(
    ([t_id, t_md]) =>
      thisMonthTransactionIds.includes(t_id) || thisMonthPendingTransactionIds.includes(t_id)
  )

  const totalPaid = streams
    .filter((s) => s.frequency === FrequencyType.monthly)
    .reduce((total, stream) => {
      const transactionIdFromThisMonth = thisMonthTransactionMetadata.find(
        ([t_id, t_md]) => t_md.fixedStreamName.toLowerCase() === stream.name.toLowerCase()
      )?.[0]
      if (transactionIdFromThisMonth) {
        total = total + stream.amount
      }

      return total
    }, 0)

  return { totalPaid }
}
export const useGetMatchingTransactionsForStreamName = (streamName: string) => {
  const { transactions } = useFinancialData()

  const {
    lastMonthTransactionMetadata,
    thisMonthTransactionMetadata,
  } = useGetTransactionMetadataForTimeFrame()

  const transactionIdFromLastMonth = lastMonthTransactionMetadata.find(
    ([t_id, t_md]) => t_md.fixedStreamName.toLowerCase() === streamName.toLowerCase()
  )?.[0]

  const transactionIdFromThisMonth = thisMonthTransactionMetadata.find(
    ([t_id, t_md]) => t_md.fixedStreamName.toLowerCase() === streamName.toLowerCase()
  )?.[0]

  const streamTransactionFromLastMonth = transactions.find(
    (t) =>
      t.transaction_id === transactionIdFromLastMonth ||
      t.pending_transaction_id === transactionIdFromLastMonth
  )
  const streamTransactionFromThisMonth = transactions.find(
    (t) =>
      t.transaction_id === transactionIdFromThisMonth ||
      t.pending_transaction_id === transactionIdFromThisMonth
  )

  return {
    streamTransactionFromLastMonth,
    streamTransactionFromThisMonth,
  }
}

export const useGetTransactionMetadataForTimeFrame = () => {
  const { settings } = useUserCustomSettings()
  const { transactions } = useFinancialData()

  return useMemo(() => {
    const thisMonthTransactionIds = transactions
      .filter((t) => transactionTimeFilterFunction(t, 'thisMonth'))
      .map((t) => t.transaction_id)

    const thisMonthPendingTransactionIds = transactions
      .filter((t) => transactionTimeFilterFunction(t, 'thisMonth'))
      .map((t) => t.pending_transaction_id)

    const lastMonthTransactionIds = transactions
      .filter((t) => transactionTimeFilterFunction(t, 'lastMonth'))
      .map((t) => t.transaction_id)

    const lastMonthPendingTransactionIds = transactions
      .filter((t) => transactionTimeFilterFunction(t, 'lastMonth'))
      .map((t) => t.pending_transaction_id)

    const thisMonthTransactionMetadata = Object.entries(
      settings?.transactionsMetadata || {}
    ).filter(
      ([t_id, t_md]) =>
        thisMonthTransactionIds.includes(t_id) || thisMonthPendingTransactionIds.includes(t_id)
    )

    const lastMonthTransactionMetadata = Object.entries(
      settings?.transactionsMetadata || {}
    ).filter(
      ([t_id, t_md]) =>
        lastMonthTransactionIds.includes(t_id) || lastMonthPendingTransactionIds.includes(t_id)
    )

    return {
      thisMonthTransactionMetadata,
      lastMonthTransactionMetadata,
      thisMonthTransactionIds,
      lastMonthTransactionIds,
    }
  }, [settings, transactions])
}
