import { DataOrFeatureMissing } from 'Components/DataOrFeatureMissing/DataOrFeatureMissing'
import { Icon, TextSpan } from 'Components/DesignSystem/Typography'
import { DAYS_OUT, useData } from 'Providers/APIDataProvider'
import { useFinancialData } from 'Providers/FinancialDataProvider/FinancialDataProvider'
import { useMysteryDay } from 'Providers/MysteryDayProvider/MysteryDayProvider'
import { useUIState } from 'Providers/UIStateProvider'
import { numberToCurrency } from 'Utilities/currencyFormater'
import { dateToString, enumerateDaysBetweenDates } from 'Utilities/dateUtilities/dateUtilities'
import { valueForDay } from 'Utilities/eventsToRunningBalance/eventsToRunningBalance'
import { MoneyEvent } from 'Utilities/interfaces'
import { streamsToEvents } from 'Utilities/streamsToEvents/streamsToEvents'
import { add, endOfMonth, format, startOfMonth } from 'date-fns'
import isAfter from 'date-fns/isAfter'
import dayjs from 'dayjs'
import React from 'react'
import { PieChart } from 'react-minimal-pie-chart'
import { colorPalletes } from 'theme'
import {
  CalendarCircle,
  CalendarSquare,
  ContentDiv,
  CustomMoneySackIcon,
  DayOfWeekLabel,
  PieCircleContainer,
} from './Calendar.components'

const MapOfIcons: { [index: string]: string } = {
  salary: 'fad fa-sack-dollar',
  Salary: 'fad fa-sack-dollar',
  paycheck: 'fad fa-sack-dollar',
  Paycheck: 'fad fa-sack-dollar',
  utilities: 'fad fa-plug',
  internet: 'fad fa-plug',
  Xcel: 'far fa-plug',
  rent: 'fad fa-home',
  mortgage: 'fad fa-home',
  Mortgage: 'fad fa-home',
  'New Apartment': 'fad fa-building',
  Verizon: 'fad fa-phone',
  Rent: 'fad fa-home',
  Car: 'fad fa-car-side',
  HOA: 'fad fa-laptop-house',
  Subscriptions: 'fas fa-repeat',
}

const CalendarCircleContent: React.FC<{
  eventsForDay: MoneyEvent[]
  valueForDay: number
  date: Date
}> = ({ eventsForDay, valueForDay, date }) => {
  const numberOfEvents = eventsForDay.length
  const { activeTheme } = useUIState()

  const PieContent = () => {
    if (eventsForDay.length === 1) {
      const event = eventsForDay[0]
      // If we happen to have a pre-defined icon for the event name, render that
      if (MapOfIcons[event.name]) {
        return (
          <div key={dateToString(date)} className="piecontent d-flex flex-row align-items-center">
            <Icon
              color={Number(event.amount) > 0 ? 'cashgreen' : 'mehred'}
              className={MapOfIcons[eventsForDay[0].name]}
            />
          </div>
        )
      }
    }

    if (
      eventsForDay.length === 2 &&
      eventsForDay
        .map((e) => e.name)
        .join('-')
        .includes('Paycheck')
    ) {
      return (
        <div key={dateToString(date)} className="piecontent d-flex flex-row align-items-center">
          <CustomMoneySackIcon className={'fad fa-sack-dollar'} />
        </div>
      )
    }
    return null
  }
  // If we have no events for the day, show the running total
  if (numberOfEvents === 0) {
    return (
      <TextSpan weight={600} size="xxxs" color="texttransparent" className="font-italic">
        ${valueForDay.toLocaleString().split('.')[0]}
      </TextSpan>
    )
  }

  // If we have only one event for that day...

  // if we have multiple events for that day, represent them with multiple dots

  return (
    <PieCircleContainer>
      <PieChart
        lineWidth={15}
        paddingAngle={numberOfEvents > 1 ? 15 : 0}
        radius={48}
        data={eventsForDay.map((event) => ({
          title: event.name,
          value: event.amount === 0 ? 1 : Math.abs(Number(event.amount)),
          color:
            Number(event.amount) > 0
              ? event.name === 'Savings from Paycheck'
                ? colorPalletes[activeTheme].second
                : colorPalletes[activeTheme].cashgreen
              : colorPalletes[activeTheme].mehred,
        }))}></PieChart>

      <PieContent />
    </PieCircleContainer>
  )
}

interface Props {
  monthYear: Date
}
export const Calendar: React.FC<Props> = ({ monthYear }) => {
  const { currentCash, flexibleSpendPerMonth } = useFinancialData()
  const { streams } = useData()
  const { livingCostPercentageAdjustment } = useMysteryDay()

  /* NEW */
  const startDate = new Date()
  // FIXME: This needs to pull from the newly derived current balance from accounts in the other provider
  const startBalance = currentCash
  // const startBalance = newBalance?.grandTotal || 0

  const events = streamsToEvents(
    streams,
    DAYS_OUT,
    startDate,
    [],
    flexibleSpendPerMonth,
    livingCostPercentageAdjustment
  )

  const dateRange = enumerateDaysBetweenDates(
    new Date(startDate),
    add(new Date(startDate), { days: DAYS_OUT })
  )
  const eventMap = events.mapOfEvents
  const {
    dateToRunningTotalMap: map,
    max,
    min,
    minDate,
    savingsMap,
    maxDate,
    soonestMinDate,
    soonestDipBelowZero,
    soonestDipBelowZeroDate,
    soonestMin,
    firstMonthAverage,
    lastMonthAverage,
  } = valueForDay(DAYS_OUT, events.mapOfEvents, startBalance, 0, dateRange, events.cacheHitKey)

  const runningBalanceMap = {
    max,
    min,
    map,
    savingsMap,
    soonestMin,
    soonestMinDate,
    soonestDipBelowZero,
    soonestDipBelowZeroDate,
    minDate,
    maxDate,
    firstMonthAverage,
    lastMonthAverage,
  }

  /* NEW */

  const dayOfWeekOfFirstDayOfMonth = dayjs(monthYear, 'MMM YYYY').startOf('month').day()

  const arrayOfDays = enumerateDaysBetweenDates(startOfMonth(monthYear), endOfMonth(monthYear))

  if (Object.keys(eventMap)?.length === 0) {
    return (
      <DataOrFeatureMissing
        title={'No Future Events'}
        body="Someething happened and we haven't collected any future events."
      />
    )
  }

  return (
    <div className="d-flex flex-column align-items-start w-100 h-100">
      {/* <DayZoomIn activeDay={activeDay} onPageClose={() => setActiveDay('')} /> */}
      <ContentDiv startDayOfWeek={dayOfWeekOfFirstDayOfMonth} className="mb-5 cal">
        <DayOfWeekLabel isWeekend={true}>s</DayOfWeekLabel>
        <DayOfWeekLabel>m</DayOfWeekLabel>
        <DayOfWeekLabel>t</DayOfWeekLabel>
        <DayOfWeekLabel>w</DayOfWeekLabel>
        <DayOfWeekLabel>t</DayOfWeekLabel>
        <DayOfWeekLabel>f</DayOfWeekLabel>
        <DayOfWeekLabel isWeekend={true}>s</DayOfWeekLabel>
      </ContentDiv>
      <ContentDiv className="cal" startDayOfWeek={dayOfWeekOfFirstDayOfMonth}>
        {arrayOfDays.map((date) => {
          const dayOfMonth = dayjs(date).date()
          const valueForDay = runningBalanceMap.map[dateToString(date)] || 0

          const eventsForDay = eventMap[format(date, 'yyyy-MM-dd')] || []

          const delta = Number(
            eventsForDay.length === 1
              ? eventsForDay[0].amount
              : eventsForDay
                  .filter((e) => e.name !== 'Savings from Paycheck')
                  .reduce((acc, curr) => acc + Number(curr.amount), 0)
          )

          const ExtraInfoAroundCircle = () => {
            if (eventsForDay.length) {
              return (
                <>
                  <TextSpan data-testid="delta-for-day" size="xxxs" className={`m-0 delta`}>
                    {numberToCurrency(delta, true, true)}
                  </TextSpan>
                  <TextSpan
                    data-testid="value-for-day"
                    size="xxxs"
                    color="text"
                    className="total m-0">
                    ${valueForDay.toLocaleString().split('.')[0]}
                  </TextSpan>
                </>
              )
            }
            return null
          }

          return (
            <CalendarSquare
              data-testid={`calendar-square-month-${date.getMonth()}-day-${dayOfMonth}`}
              isPast={!isAfter(dayjs(date).add(1, 'day').toDate(), new Date())}
              isNegativeBalance={valueForDay < 0}
              isNegativeDelta={delta <= 0}
              key={dayOfMonth}
              hasEvents={eventsForDay.length > 0}
              onClick={() => {}}>
              <div className="innerChild">
                <CalendarCircle
                  day={dayOfMonth}
                  isMultiple={eventsForDay.length > 0}
                  isToday={dateToString(date) === format(new Date(), 'yyyy-MM-dd')}
                  isPast={!isAfter(dayjs(date).add(1, 'day').toDate(), new Date())}
                  status={valueForDay > 0 ? 'good' : valueForDay < 0 ? 'bad' : 'neutral'}>
                  <CalendarCircleContent
                    valueForDay={valueForDay}
                    eventsForDay={eventsForDay}
                    date={date}
                  />
                </CalendarCircle>

                <ExtraInfoAroundCircle />
              </div>
            </CalendarSquare>
          )
        })}
      </ContentDiv>
    </div>
  )
}
