import React from 'react'
import { Icon, TextBold, TextSlim, TextSpan } from '../../Components/DesignSystem/Typography'
import { MoneyStream } from '../../Utilities/interfaces'
import {
  getMonthlyBills,
  getMonthlyIncome,
  getMonthlySubscriptions,
} from '../../Utilities/netWorth/netWorth'
import { CollapsibleStreamEntry } from './StreamEntry/CollapsibleStreamEntry'
import { StreamEntry } from './StreamEntry/StreamEntry'
import { EmptyBill, EmptyStream, EmptySubscription } from './consts'

interface StreamPageContentProps {
  setStreamToEdit: (stream: MoneyStream | null, mode: 'edit' | 'add', isPaycheck: boolean) => void
  streams: MoneyStream[]
  sortFunction: (a: MoneyStream, b: MoneyStream) => number
  getStreams: Function
  loadingStreams: boolean
}
const _StreamPageContent: React.FC<StreamPageContentProps> = ({
  setStreamToEdit,
  streams,
  sortFunction,
  getStreams,
  loadingStreams,
}) => {
  const subscriptions = React.useMemo(() => streams.filter((s) => s.isSubscription), [streams])
  const subscriptionsCount = subscriptions.length
  const monthlySubscriptions = getMonthlySubscriptions(streams)

  const bills = React.useMemo(() => streams.filter((s) => !s.isSubscription && s.type === 'bill'), [
    streams,
  ])
  const billsCount = bills.length
  const monthlyBills = getMonthlyBills(streams)

  const incomes = React.useMemo(
    () => streams.filter((s) => !s.isSubscription && s.type === 'income'),
    [streams]
  )

  const incomeCount = incomes.length
  const monthlyIncome = getMonthlyIncome(streams)

  if (streams.length === 0) {
    return (
      <div
        data-testid="no-streams-container"
        className="w-100 h-100 d-flex align-items-center justify-content-center flex-column">
        <TextBold size="xl" color="texttransparent">
          No Fixed Data
        </TextBold>
        <div
          data-testid="no-streams-container"
          className="d-flex align-items-start justify-content-center flex-column mt-2">
          <TextSlim size="sm" className="my-2" color="texttransparent" weight={400}>
            Click{' '}
            <Icon
              onClick={() => {
                setStreamToEdit(EmptyStream, 'add', false)
              }}
              size="md"
              color={'cashgreen'}
              className="fak fa-solid-sack-dollar-circle-plus "
            />{' '}
            above to add your first income
          </TextSlim>
          <TextSlim size="sm" className="my-2" color="texttransparent" weight={400}>
            Click{' '}
            <Icon
              onClick={() => {
                setStreamToEdit(EmptyBill, 'add', false)
              }}
              size="md"
              color={'mehred'}
              className="far fa-money-check-edit-alt"
            />{' '}
            above to add your first bill
          </TextSlim>
          <TextSlim size="sm" className="my-2" color="texttransparent" weight={400}>
            Click{' '}
            <Icon
              onClick={() => {
                setStreamToEdit(EmptySubscription, 'add', false)
              }}
              size="md"
              color={'mehred'}
              className="fak fa-solid-repeat-circle-dollar"
            />{' '}
            above to add your first subscription
          </TextSlim>
        </div>

        <TextSpan
          onClick={() => {
            getStreams()
          }}
          className="mt-2"
          color="texttransparent"
          size="xs">
          {loadingStreams ? (
            'Loading...'
          ) : (
            <>
              Or, <u className="ml-1">check for and refresh existing fixed</u>{' '}
            </>
          )}
        </TextSpan>
      </div>
    )
  }
  return (
    <section>
      <CollapsibleStreamEntry
        title="Income"
        color="cashgreen"
        colorAlpha="cashgreen"
        icon="far fa-sack-dollar"
        count={incomeCount}
        total={monthlyIncome}
        content={incomes.sort(sortFunction).map((stream) => {
          return (
            <StreamEntry
              isIndented
              key={stream.name}
              stream={stream}
              onStreamEdit={setStreamToEdit}
            />
          )
        })}
      />
      <CollapsibleStreamEntry
        title="Bills"
        color="mehred"
        colorAlpha="mehred"
        icon="fak fa-regular-money-check-dollar-circle-minus"
        count={billsCount}
        total={monthlyBills}
        content={bills.sort(sortFunction).map((stream) => {
          return (
            <StreamEntry
              isIndented
              key={stream.name}
              stream={stream}
              onStreamEdit={setStreamToEdit}
            />
          )
        })}
      />
      <CollapsibleStreamEntry
        title="Subscriptions"
        color="mehred"
        colorAlpha="mehred"
        icon="fak fa-solid-repeat-circle-dollar"
        count={subscriptionsCount}
        total={monthlySubscriptions}
        content={subscriptions.sort(sortFunction).map((stream) => {
          return (
            <StreamEntry
              isIndented
              key={stream.name}
              stream={stream}
              onStreamEdit={setStreamToEdit}
            />
          )
        })}
      />
    </section>
  )
}

export const StreamPageContent = React.memo(_StreamPageContent)
