import color from 'color'
import styled from 'styled-components/macro'
import { ColorType } from 'theme'

interface BannerProps {
  messageColor: ColorType
}
export const Banner = styled.div<BannerProps>`
  padding: 0.25rem 0.5rem;
  border: solid 1px ${(props) => props.theme.colors[props.messageColor]};
  width: 100%;
  border-radius: 4px;
  transition: color ease-in-out 0.3s, background-color ease-in-out 0.3s,
    border-color ease-in-out 0.3s, opacity ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => color(props.theme.colors[props.messageColor]).darken(0.65).toString()};
  background-color: ${(props) => color(props.theme.colors[props.messageColor]).toString()};
  span {
    transition: none;
  }
  i {
    color: ${(props) => color(props.theme.colors[props.messageColor]).darken(0.65).toString()};
    transition: all ease-in-out 0.3s;
  }
`
